export const topArticlesNumberOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const booleanOperators = [
  { label: 'AND', value: 'and' },
  { label: 'OR', value: 'or' },
];

export const operatorOptions = [
  { label: 'Is', value: 'is' },
  { label: 'Is not', value: 'is not' },
];

export const operatorOptionsForAvailableValues = [
  ...operatorOptions,
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'does not contain' },
];

export const operatorOptionsForStringOnly = [
  { label: 'Is substring', value: 'is substring' },
  { label: 'Is not substring', value: 'is not substring' },
];

export const emptyOperatorOptions = [
  { label: 'Is Empty', value: 'is empty' },
  { label: 'Is Not Empty', value: 'is not empty' },
];

export const VANILLA_FORUMS_DISCUSSION = 'Vanilla Forums Discussion';
export const USER_SEGMENT = 'User Segment';
export const CATEGORY = 'Category';
