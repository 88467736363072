import { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IconPhotoX } from '@tabler/icons-react';

const ArticleImage = ({ src }: { src: string }) => {
  const [hasError, setHasError] = useState(false);
  const { palette } = useTheme();

  const handleImageError = () => {
    setHasError(true);
  };

  if (hasError) {
    return (
      <Box
        alignItems='center'
        bgcolor={palette.colors.slate[100]}
        borderRadius={2}
        display='flex'
        flexDirection='column'
        gap={1}
        height='92px'
        justifyContent='center'
        my={2}
      >
        <IconPhotoX
          color={palette.colors.grey[600]}
          size={32}
          strokeWidth={1.5}
        />
        <Typography color={palette.colors.grey[700]} variant='font14'>
          Unable to retrieve image
        </Typography>
      </Box>
    );
  }

  return (
    <img
      alt='article-image'
      onError={handleImageError}
      src={src}
      style={{ maxWidth: '100%' }}
    />
  );
};

export default ArticleImage;
