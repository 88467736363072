import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import Box from '@mui/material/Box';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import { FieldNameToIdx } from '../constants';
import {
  formatContextVariableForDisplay,
  formatContextVariableForPersistence,
} from '../helpers';
import MarkAsNotDeflected from './MarkAsNotDeflected';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  Label,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import TicketBrand from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketBrand';
import TicketCustomFields from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketCustomFields';
import TicketTitle from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketTitle';
import TagInputWithContextVariables from 'src/pages/workflow-builder-edit/tag-input-with-context-variables/TagInputWithContextVariables';
import {
  ZendeskCommonCustomization,
  ZendeskCustomFieldAndValue,
} from 'src/types/workflowBuilderAPITypes';

interface TicketCreationOnlyProps {
  customizationData: ZendeskCommonCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  isFromCanvas: boolean;
  setCustomizationData: Dispatch<SetStateAction<ZendeskCommonCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const TicketCreationOnly: FC<
  React.PropsWithChildren<TicketCreationOnlyProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  isFromCanvas,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const handleBlurOnTicketTags = () => {
    const arrCopy = [...isFieldTouchedArr];
    arrCopy[FieldNameToIdx.TICKET_TAGS] = true;
    setIsFieldTouchedArr(arrCopy);
  };

  const handleTagAddition = (tag: string) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        ticket_creation_settings: {
          ...handoffData.ticket_creation_settings,
          ticket_creation_config: {
            ...customizationData.ticket_creation_settings
              ?.ticket_creation_config,
            ticket_tags: [
              ...handoffData.ticket_creation_settings?.ticket_creation_config
                ?.ticket_tags,
              formatContextVariableForPersistence(contextVariables, tag),
            ],
          },
        },
      };
    });
  };

  const handleTagDeletion = (idx: number) => {
    setCustomizationData(handoffData => {
      const ticketTags =
        handoffData.ticket_creation_settings?.ticket_creation_config
          ?.ticket_tags || [];
      return {
        ...handoffData,
        ticket_creation_settings: {
          ...handoffData.ticket_creation_settings,
          ticket_creation_config: {
            ...customizationData.ticket_creation_settings
              ?.ticket_creation_config,
            ticket_tags: ticketTags
              .slice(0, idx)
              .concat(ticketTags.slice(idx + 1, ticketTags.length)),
          },
        },
      };
    });
  };

  const handleUpdateCustomFieldsAndValues = (
    updatedVal: ZendeskCustomFieldAndValue[],
  ) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        ticket_creation_settings: {
          ...handoffData.ticket_creation_settings,
          ticket_creation_config: {
            ...customizationData.ticket_creation_settings
              ?.ticket_creation_config,
            custom_fields_and_values: updatedVal,
          },
        },
      };
    });
  };

  const tags = (
    customizationData?.ticket_creation_settings?.ticket_creation_config
      ?.ticket_tags || []
  ).map(tag => formatContextVariableForDisplay(contextVariables, tag));

  const available_groups_options = useMemo(
    () =>
      customizationData.ticket_creation_settings.ticket_creation_config.available_groups.map(
        group => ({
          label: group.name,
          value: group.group_id.toString(),
        }),
      ),
    [
      customizationData.ticket_creation_settings.ticket_creation_config
        .available_groups,
    ],
  );

  return (
    <>
      <TicketTitle
        contextVariables={isFromCanvas ? contextVariables : []}
        customizationData={customizationData}
        errorMessages={errorMessages}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
      <Spacer height='28px' />
      <MarkAsNotDeflected
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='28px' />
      <TicketBrand
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='28px' />
      <TagInputWithContextVariables
        contextVariables={isFromCanvas ? contextVariables : []}
        errorMessages={errorMessages}
        errorTag={FieldNameToIdx.TICKET_TAGS}
        handleBlur={handleBlurOnTicketTags}
        handleTagAddition={handleTagAddition}
        handleTagDeletion={handleTagDeletion}
        label='Ticket Tag'
        tags={tags}
      />
      <Spacer height='28px' />
      <Label>Ticket Status</Label>
      <Box sx={{ backgroundColor: theme.palette.colors.white }}>
        <SelectDropdown
          id='ticket-status-select'
          inputProps={{ 'aria-label': 'Ticket Status' }}
          onChange={e => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    status: e.target.value,
                  },
                },
              };
            });
          }}
          onClear={() => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    status: '',
                  },
                },
              };
            });
          }}
          options={
            customizationData.ticket_creation_settings.ticket_creation_config
              .available_statuses
          }
          placeholder='Select a status'
          value={
            customizationData.ticket_creation_settings.ticket_creation_config
              .status || ''
          }
        />
      </Box>
      <Spacer height='28px' />
      <Label>Ticket Group</Label>
      <Box sx={{ backgroundColor: theme.palette.colors.white }}>
        <SelectDropdown
          id='ticket-group-select'
          inputProps={{ 'aria-label': 'Ticket Group' }}
          onChange={e => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    group_id: parseInt(e.target.value as string),
                  },
                },
              };
            });
          }}
          onClear={() => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    group_id: null,
                  },
                },
              };
            });
          }}
          options={available_groups_options}
          placeholder='Select a group'
          value={
            customizationData.ticket_creation_settings.ticket_creation_config.group_id?.toString() ||
            ''
          }
        />
      </Box>
      <Spacer height='28px' />
      <Label>Ticket Type</Label>
      <Box sx={{ backgroundColor: theme.palette.colors.white }}>
        <SelectDropdown
          id='ticket-type-select'
          inputProps={{ 'aria-label': 'Ticket Type' }}
          onChange={e => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    type: e.target.value,
                  },
                },
              };
            });
          }}
          onClear={() => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    type: '',
                  },
                },
              };
            });
          }}
          options={
            customizationData.ticket_creation_settings.ticket_creation_config
              .available_types
          }
          placeholder='Select a ticket type'
          value={
            customizationData.ticket_creation_settings.ticket_creation_config
              .type || ''
          }
        />
      </Box>
      <Spacer height='28px' />
      <Label>Ticket Priority</Label>
      <Box sx={{ backgroundColor: theme.palette.colors.white }}>
        <SelectDropdown
          id='ticket-priority-select'
          inputProps={{ 'aria-label': 'Ticket Priority' }}
          onChange={e => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    priority: e.target.value,
                  },
                },
              };
            });
          }}
          onClear={() => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  ticket_creation_config: {
                    ...customizationData.ticket_creation_settings
                      ?.ticket_creation_config,
                    priority: '',
                  },
                },
              };
            });
          }}
          options={
            customizationData.ticket_creation_settings.ticket_creation_config
              .available_priorities
          }
          placeholder='Select a priority'
          value={
            customizationData.ticket_creation_settings.ticket_creation_config
              .priority || ''
          }
        />
      </Box>{' '}
      <Spacer height='28px' />
      <TicketCustomFields
        customFieldsAndValues={
          customizationData.ticket_creation_settings?.ticket_creation_config
            .custom_fields_and_values
        }
        setCustomFieldsAndValues={handleUpdateCustomFieldsAndValues}
        ticketCustomFields={
          customizationData.ticket_creation_settings?.ticket_creation_config
            .ticket_custom_fields
        }
      />
    </>
  );
};

export default TicketCreationOnly;
