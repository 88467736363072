import { XYCoord } from 'react-dnd';

import { ActionBuilderActionTypes } from '../enums';
import { CompositeConditionsConfig } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { AttachmentAnalysisUploadStepFields } from 'src/slices/canvas-workflow-builder/types/dataTypes';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Action } from 'src/types/actionBuilderApiTypes';
import {
  ButtonsStepFields,
  CsatTriggerPointStepFields,
  EmbedStepFields,
  FormStepFields,
} from 'src/types/workflowBuilderAPITypes';

export const isOptionsStepFields = (
  fields: CanvasWorkflowBuilderState['stepFields'],
): fields is ButtonsStepFields => {
  return 'options' in (fields ?? {});
};

export const isFormStepFields = (
  fields: CanvasWorkflowBuilderState['stepFields'],
): fields is FormStepFields => {
  return 'form_fields' in (fields ?? {});
};

export const isCsatTriggerPointStepFields = (
  fields: CanvasWorkflowBuilderState['stepFields'],
): fields is CsatTriggerPointStepFields => {
  return 'delay' in (fields ?? {});
};

export const isEmbedStepFields = (
  fields: CanvasWorkflowBuilderState['stepFields'],
): fields is EmbedStepFields => {
  return 'embed_url' in (fields ?? {});
};

export const isAttachmentUploadAnalysisStepFields = (
  fields: CanvasWorkflowBuilderState['stepFields'],
): fields is AttachmentAnalysisUploadStepFields => {
  return 'num_files' in (fields ?? {});
};

export const isArticleSuggestion = (actionType: Action['action_type']) =>
  actionType === ActionBuilderActionTypes.ARTICLE_SUGGESTION ||
  actionType === ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR ||
  actionType === ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2;

export const isSetContextVariableAction = (actionType: Action['action_type']) =>
  actionType === ActionBuilderActionTypes.SET_CONTEXT_VARIABLE;

export const isDynamicCardAction = (actionType: Action['action_type']) =>
  actionType === ActionBuilderActionTypes.DYNAMIC_CARD;

export const isEndInteractiveEmailChatAction = (
  actionType: Action['action_type'],
) => actionType === ActionBuilderActionTypes.END_INTERACTIVE_EMAIL_CHAT;

export const isConditionStepFields = (
  fields: CanvasWorkflowBuilderState['stepFields'],
): fields is CompositeConditionsConfig => {
  return 'conditionName' in (fields ?? {});
};

export const actionTooltips: Partial<Record<Action['action_type'], string>> = {
  salesforce_case_creation:
    'This action creates a Salesforce Case. Note to separately add forms to collect all information needed.',
  salesforce_chat_handoff:
    'This action launches a Salesforce Chat. Note to separately add forms to collect all information needed.',
  salesforce_handoff:
    'This action contains all interactions (forms, confirmations) required in a chat and case handoff experience.',
  zendesk_chat_handoff_v2:
    'This action launches a Zendesk Chat. Note to separately add forms to collect all information needed.',
  zendesk_handoff:
    'This action contains all interactions (forms, confirmations) required in a chat and ticket handoff experience.',
  zendesk_ticket_creation:
    'This action creates a Zendesk Ticket. Note to separately add forms to collect all information needed.',
};

export const actionTypesNotTreatedAsActions: Array<Action['action_type']> = [
  ActionBuilderActionTypes.ARTICLE_SUGGESTION,
  ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
];

export const nonSqueezingActions: Array<Action['action_type']> = [
  ActionBuilderActionTypes.ZENDESK_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_HANDOFF,
  ActionBuilderActionTypes.CATCH_ALL_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF,
  ActionBuilderActionTypes.KUSTOMER_HANDOFF,
  ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST,
  ActionBuilderActionTypes.INTERCOM_HANDOFF,
];

export const formRequiredActions: Array<Action['action_type']> = [
  ActionBuilderActionTypes.HYPERLINK_REDIRECT,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2,
  ActionBuilderActionTypes.ZENDESK_TICKET_CREATION,
  ActionBuilderActionTypes.FRONT_TICKET_CREATION,
  ActionBuilderActionTypes.ZAMMAD_TICKET_CREATION,
  ActionBuilderActionTypes.SALESFORCE_CASE_CREATION,
  ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF,
  ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF,
  ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF,
  ActionBuilderActionTypes.TALKDESK_CHAT_HANDOFF,
  ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF,
  ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING,
  ActionBuilderActionTypes.ZENDESK_ATTACHMENT_UPLOAD,
  ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF,
  ActionBuilderActionTypes.SET_CONTEXT_VARIABLE,
  ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF,
  ActionBuilderActionTypes.SUNCO_LIVE_CHAT,
  ActionBuilderActionTypes.DYNAMIC_CARD,
  ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF,
  ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION,
  ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION,
  ActionBuilderActionTypes.INTERCOM_TICKET_CREATION,
  ActionBuilderActionTypes.INTERCOM_LIVE_CHAT,
];

export const handleSwapWhenHovering = ({
  clientOffset,
  dragIndex,
  hoverBoundingRect,
  hoverIndex,
  swap,
}: {
  clientOffset: XYCoord;
  dragIndex: number;
  hoverBoundingRect: DOMRect;
  hoverIndex: number;
  swap: (dragIndex: number, hoverIndex: number) => void;
}) => {
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return;
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return;
  }

  swap(dragIndex, hoverIndex);

  return hoverIndex;
};

export const isDropValid = (result: unknown) => {
  if (typeof result !== 'object' || result === null) {
    return true;
  }

  if ('failed' in result && result?.failed === true) {
    return false;
  }

  return true;
};

export const dynamicCvValueMessageBuilder = (cvNames: string) => {
  return `Collect ${cvNames} via an API action`;
};
