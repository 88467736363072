import { format } from 'date-fns';

/**
 * the timestamps in this file are all "Seconds from epoch time". i.e. 1740773322.4450352
 * Make sure to pass in the right format of timestamps when using these functions
 */

export const getTranscriptTime = (startTime: number, timestamp: number) => {
  const seconds = Math.round(timestamp - startTime);

  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substring(14, 19);
};

export const formatTimestampToDate = (timestamp: number) => {
  const date = new Date(0);
  date.setSeconds(timestamp);

  return format(date, 'MMM dd, yyyy h:mm:ss aaa');
};

export const getDuration = (startTimestamp: number, endTimestamp: number) => {
  return Math.round((endTimestamp - startTimestamp) * 100) / 100;
};
