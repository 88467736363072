import { useCallback, useEffect, useMemo, useState } from 'react';
import { MRT_ExpandedState, MRT_Updater } from 'material-react-table';
import { useNavigate } from 'react-router';
import { Menu, styled, SxProps } from '@mui/material';
import { useTheme } from '@mui/material';
import { VisibilityState } from '@tanstack/react-table';

import {
  getDefaultMRTOptions,
  InfiniteTable,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverHistogramChart from '../components/HistogramFilter';
import { SearchType } from '../types';
import CallToActionButton from './CallToActionButton';
import { ColumnHeaderItemProps } from './ColumnHeaderItem';
import { ColumnItem } from './constants';
import {
  AllTopicsTableV3Filter,
  countAllTopics,
  createColumnVisibility,
  createRows,
  filterByGroup,
  filterByHistogram,
  filterBySearchText,
  generateColumns,
  getActionsColumnSize,
} from './helpers';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import {
  DiscoverAllTopicsResponse,
  DiscoverTopic,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';
import { DiscoverBodyFilter } from 'src/services/discover/types';
import {
  deriveTopicName,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

type AllTopicsTableV3Props = {
  backendFriendlyFilterQuery: DiscoverBodyFilter;
  columns: ColumnItem[];
  data?: DiscoverAllTopicsResponse;
  filters: AllTopicsTableV3Filter;
  histogram: ColumnHeaderItemProps['histogram'];
  isLoading: boolean;
  isSentimentAvailable: boolean;
  isTopicGrouped: boolean;
  metricFromMetricsParam: string | null;
  onColumnVisibilityChange: (updater: MRT_Updater<VisibilityState>) => void;
  searchType: SearchType;
  TopToolbarCustomActions: JSX.Element;
};

const AllTopicsTableV3 = ({
  backendFriendlyFilterQuery,
  columns,
  data,
  filters,
  histogram,
  isLoading,
  isSentimentAvailable,
  isTopicGrouped,
  metricFromMetricsParam,
  onColumnVisibilityChange,
  searchType,
  TopToolbarCustomActions,
}: AllTopicsTableV3Props) => {
  const { data: metadata } = useGetMetadataQuery();
  const theme = useTheme();
  const navigate = useNavigate();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<MRT_ExpandedState>({});
  const [sortedBy, setSortedBy] = useState<string>('');
  const handleOnCloseHistogramFilter = () => {
    setAnchorElement(null);
  };
  const [invalidSort, setInvalidSort] = useState(false);

  const trackingEventMetadata = useMemo(() => {
    return {
      'metrics-selected': filters.metricFilters,
      page: 'All Topics',
      'time-period-selected': filters.timeFilter.key,
    };
  }, [filters.metricFilters, filters.timeFilter.key]);

  useEffect(() => {
    if (filters.searchText) {
      setExpanded(true);
    }
  }, [filters.searchText, setExpanded]);

  const isHistogramFilterOpen = Boolean(anchorElement);

  const handleNavigateToTopic = useCallback(
    (topic: { displayName: string; id: string; name: string }) => {
      navigate(
        overrideDiscoverSearchParams(
          replaceIdInRoute(linkWithSearch, topic.id),
          {
            name: topic.displayName || topic.name,
            search: filters.searchText,
            source: 'all-topics',
            'topic-search-type': searchType,
            'topic-ticket-search':
              searchType === 'ticket' ? filters.searchText : '',
          },
        ),
      );
    },
    [navigate, linkWithSearch, searchType, filters.searchText],
  );

  const onClickArticleButton = useCallback(
    (topic: DiscoverTopic) => {
      navigate(
        overrideDiscoverSearchParams(
          replaceIdInRoute(linkWithSearch, topic.topic_id ?? ''),
          {
            name: deriveTopicName(topic.topic_display_name, topic.topic_name),
            source: 'all-topics',
            tab: 'Generated articles',
          },
        ),
      );
    },
    [navigate, linkWithSearch],
  );

  const filteredData = useMemo(
    () =>
      filterByHistogram(
        filterBySearchText(
          filterByGroup(data?.breakdown, filters.groupFilters),
          filters.searchText,
          isTopicGrouped,
          searchType,
        ),
        histogram.histogramFilters,
        isTopicGrouped,
      ),
    [data, filters, isTopicGrouped, histogram, searchType],
  );

  const rows = useMemo(
    () =>
      createRows({
        data: filteredData,
        dataType: metadata?.topic_metric_data_types,
        invalidSort,
        isTopicGrouped,
        onClickArticleButton,
        onNavigateToTopic: handleNavigateToTopic,
        searchText: filters.searchText,
        sortedBy,
      }),
    [
      filteredData,
      metadata,
      filters.searchText,
      onClickArticleButton,
      handleNavigateToTopic,
      invalidSort,
      sortedBy,
      isTopicGrouped,
    ],
  );

  const tableColumns = useMemo(
    () =>
      generateColumns({
        columns,
        filteredData,
        histogram: histogram,
        isSentimentAvailable,
        setAnchorElement,
        sortDirection: sortedBy,
        topicCount: countAllTopics(filteredData, isTopicGrouped),
      }),
    [
      columns,
      filteredData,
      histogram,
      isSentimentAvailable,
      sortedBy,
      isTopicGrouped,
    ],
  );

  const isExpanded =
    Boolean(Object.entries(expanded).length) || expanded === true;
  const defaultMrt = getDefaultMRTOptions({ theme });
  const enableRowActions = !isTopicGrouped || isExpanded;
  const initialSortMetric = metricFromMetricsParam || 'volume';

  return (
    <TableWrapper>
      <Menu
        anchorEl={anchorElement}
        onClose={handleOnCloseHistogramFilter}
        open={isHistogramFilterOpen}
        sx={{
          '& .MuiMenu-list': {
            paddingTop: '8px',
          },
        }}
      >
        {data && (
          <DiscoverHistogramChart
            data={data}
            handleOnClose={handleOnCloseHistogramFilter}
            histogramFilters={histogram.histogramFilters}
            selectedHistogramFilter={histogram.selectedHistogramFilter}
            setHistogramFilters={histogram.setHistogramFilters}
          />
        )}
      </Menu>
      <InfiniteTable
        hasNextPage={false}
        initialSorting={[{ desc: true, id: initialSortMetric }]}
        isError={false}
        isLoadingFirstPage={isLoading}
        isLoadingNextPage={isLoading}
        onLoadMore={() => undefined}
        onSortCallback={(id, direction) => {
          if (
            !filters.metricFilters.includes(
              id as DiscoverTopicAggregatedMetricType,
            )
          ) {
            setInvalidSort(true);
          } else {
            setInvalidSort(false);
          }
          setSortedBy(direction);
        }}
        stickyHeaderHeight={40}
        tableOptions={{
          columns: tableColumns,
          data: rows,
          displayColumnDefOptions: {
            'mrt-row-actions': {
              grow: 1,
              header: 'Actions',
              Header: <Typography variant='font14'>Actions</Typography>,
              muiTableBodyCellProps: {
                align: 'right',
              },
              size: getActionsColumnSize(rows, isTopicGrouped),
            },
            'mrt-row-expand': {
              size: 54,
            },
          },
          enableBottomToolbar: false,
          enableColumnActions: false,
          enableColumnFilters: false,
          enableExpanding: isTopicGrouped,
          enableFullScreenToggle: false,
          enableGlobalFilter: false,
          enableRowActions,
          enableSorting: true,
          enableSortingRemoval: false,
          enableTopToolbar: true,
          initialState: {
            columnPinning: {
              left: ['mrt-row-expand', 'name'],
              right: ['mrt-row-actions'],
            },
            columnVisibility: createColumnVisibility(
              filters.metricFilters,
              filters.showPercentChanged,
            ),
          },
          manualSorting: false,
          muiExpandButtonProps: rows => {
            if (rows.row.depth > 0) {
              return {
                sx: {
                  display: 'none',
                },
              };
            }
            return {
              sx: (defaultMrt.muiExpandButtonProps as { sx: SxProps }).sx,
            };
          },
          muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
              handleNavigateToTopic({
                displayName: row.original.topic.topic_display_name,
                id: row.original.topic.topic_id,
                name: row.original.topic.topic_name,
              });
            },
            sx: {
              cursor: 'pointer',
            },
          }),
          muiTableContainerProps: {
            sx: {
              height: '100%',
            },
          },
          muiTablePaperProps: {
            sx: {
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
            },
          },
          onColumnVisibilityChange,
          onExpandedChange: setExpanded,
          renderRowActions: ({ row }) => {
            const isParentTopic = row.depth === 0 && isTopicGrouped;
            return (
              <CallToActionButton
                backendFriendlyFilterQuery={backendFriendlyFilterQuery}
                dateRange={filters.timeFilter.value}
                isParentTopic={isParentTopic}
                keywordSearch={
                  searchType === 'ticket' ? filters.searchText : ''
                }
                topic={row.original.topic}
                trackingEventMetadata={trackingEventMetadata}
              />
            );
          },
          renderTopToolbarCustomActions: () => TopToolbarCustomActions,
          state: {
            columnVisibility: createColumnVisibility(
              filters.metricFilters,
              filters.showPercentChanged,
            ),
            expanded,
          },
        }}
      />
    </TableWrapper>
  );
};

const TableWrapper = styled('div')`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
`;

export default AllTopicsTableV3;
