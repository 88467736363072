import { useCallback, useMemo, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  DateRangeFilterButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import OverallCards from '../common/OverallCards';
import TabHeader from '../common/TabHeader';
import { INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  stringifyDateRange,
} from '../helpers';
import { useGetFilterCount } from '../hooks/useGetFilterCount';
import { useGetIntentsForFilter } from '../hooks/useGetIntentsForFilter';
import { InsightSortDirection } from '../types';
import {
  initialInsightTopicParams,
  InsightTopicSortColumns,
} from './constants';
import { extractChildItems } from './helper';
import TopicsTable from './TopicsTable';
import { InsightTopicData, InsightTopicDataColumnKeys } from './types';
import partition from 'lodash/fp/partition';
import GlobalFilterDrawer from 'src/components/global-filter-drawer';
import {
  listCVObjectDeserialize,
  listCVObjectSerialize,
} from 'src/components/global-filter-drawer/helper';
import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';
import {
  useEmitTrackingEventCallback,
  useGetIntentsQueryWithProduct,
  useGetWorkflowTags,
  useStateParams,
} from 'src/hooks/hooks';
import {
  DEFLECTION_INSIGHT_FILTER_OPTIONS,
  getInsightsFilterOptions,
  NA,
} from 'src/pages/intent-conversation-analytics/constants';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import {
  useGetChatsSavingsQuery,
  useGetTopicsTableQuery,
} from 'src/services/insights';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  listDeserialize,
  listSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { CommonIntentWorkflowType, ExportableTableType } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

const SolveInsightsTopics = () => {
  const { palette } = useTheme();
  // State params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
      initialState: 'DESC',
      paramsName: 'topic_sort_direction',
      serialize: (state: InsightSortDirection) => state.toString(),
    });
  const [sortColumn, setSortColumn] = useStateParams<InsightTopicSortColumns>({
    deserialize: (param: string) => param as InsightTopicSortColumns,
    initialState: initialInsightTopicParams.topicColumnSort,
    paramsName: 'topics_sort_column',
    serialize: (state: InsightTopicSortColumns) => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightTopicParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [isTopicGrouped, setIsTopicGrouped] = useStateParams<boolean>({
    deserialize: (value: string) => value === 'true',
    initialState: true,
    paramsName: 'is_grouped',
    serialize: (value: boolean) => value.toString(),
    validator: (param: string) => param === 'true' || param === 'false',
  });
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_topic`,
    serialize: listSerialize,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_topic`,
    serialize: listCVObjectSerialize,
  });
  const workflowTags = useGetWorkflowTags();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: allSavingCardData, isLoading: isAllSavingCardDataLoading } =
    useGetChatsSavingsQuery({
      end_timestamp: timestamps.end_timestamp,
      start_timestamp: timestamps.start_timestamp,
      topics_only: false,
    });

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [visibleColumns, setVisibleColumns] = useState<
    InsightTopicDataColumnKeys[]
  >(initialInsightTopicParams.topicColumns);

  const [showOnlyFilter, setShowOnlyFilter] = useState<string[]>([]);
  const { data } = useGetIntentsQueryWithProduct();
  const { intents = [] } = data ?? {};

  const channel: ConversationChannel = 'widget';
  const intentsForFilter = useGetIntentsForFilter({
    channel,
    intentsData: data,
  });
  const [channels, setChannels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });

  const tableRequestParams = useMemo(
    () => ({
      channels: buildQueryFromMultiSelected(channels)?.channels,
      cv_filters: cvFilters,
      data_export_type: ExportableTableType.INSIGHT_TOPICS_TABLE,
      end: timestamps.end_timestamp,
      start: timestamps.start_timestamp,
      ...buildQueryFromMultiSelected(multiSelected),
    }),
    [timestamps, channels, multiSelected, cvFilters],
  );

  const {
    data: topicTableData,
    isFetching: isTopicTableFetching,
    isLoading: isTopicTableLoading,
  } = useGetTopicsTableQuery({
    ...tableRequestParams,
  });

  const isLoading =
    isTopicTableLoading || isTopicTableFetching || isAllSavingCardDataLoading;

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(
    initialInsightTopicParams.date,
  );

  const areFiltersUsed = Boolean(
    timeFilter.key !== initialTimeFilter.key ||
      showOnlyFilter.length ||
      multiSelected.length ||
      cvFilters.length ||
      visibleColumns.toString() !==
        initialInsightTopicParams.topicColumns.toString() ||
      sortColumn !== initialInsightTopicParams.topicColumnSort ||
      (channels.length && channels.length !== 3),
  );

  const costPerTicket =
    allSavingCardData?.misc.cost_per_ticket.toString() ?? NA;

  const filterCount = useGetFilterCount({
    channels,
    cvFilters,
    multiSelected,
    workflowTags,
  });

  const treatedTableData = useMemo(() => {
    if (isLoading) {
      const fillerTopic: InsightTopicData = {
        all_workflows: [],
        automation_urls: [],
        chats_count: 0,
        cluster_id: '',
        cluster_name: '',
        csat: 0,
        deflected_chats_count: 0,
        deflected_rate: 0,
        display_name: '',
        engagement_rate: 0,
        insight_subtopics: [],
        non_deflected_chats_count: 0,
        potential_saving: 0,
        realized_saving: 0,
        related_articles: [],
        type: 'workflow',
        workflows: [],
      };
      // use empty data to render loading state
      return Array(10).fill(fillerTopic) as InsightTopicData[];
    }

    if (topicTableData?.data.length) {
      const data = isTopicGrouped
        ? topicTableData?.data
        : extractChildItems(topicTableData?.data);

      // Sort the sub topics
      const sortedTopicTableData = data.map(item => {
        const sortedInsightSubTopics = [...item.insight_subtopics].sort(
          (a, b) => (a.chats_count > b.chats_count ? -1 : 1),
        );
        return { ...item, insight_subtopics: sortedInsightSubTopics };
      });

      // move Others subtopic to be at the bottom of the subtopics
      const organizedTableData = sortedTopicTableData.map(topic => {
        const subtopics = topic.insight_subtopics;
        const [othersSubtopic, normalSubtopics] = partition(
          subtopic => subtopic.cluster_name.endsWith(' - Others'),
          subtopics,
        );

        return {
          ...topic,
          insight_subtopics: normalSubtopics.concat(othersSubtopic),
        };
      });

      return organizedTableData;
    }
    return [];
  }, [isLoading, topicTableData, isTopicGrouped]);

  const getTotalChatsFromTable = treatedTableData.reduce((acc, val) => {
    return acc + val.chats_count;
  }, 0);
  // Get all conversation count
  const getTotalChatsFromSavingCard = allSavingCardData?.misc.chats ?? 0;
  const percentCoverage =
    getTotalChatsFromSavingCard === 0
      ? NA
      : numbersToStringWithCommas({
          number: getTotalChatsFromTable / getTotalChatsFromSavingCard,
          style: 'percent',
        });

  const fallbackWorkflowId = intents.find(
    intent => intent.intent_definition_id === CommonIntentWorkflowType.FALLBACK,
  )?.intent_workflow_id;

  const isGapsOnlySelected = multiSelected.includes(
    `workflow_id.${fallbackWorkflowId}`,
  );

  const getTopicsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const countAllTopics = () => {
    if (!topicTableData) {
      return 0;
    }

    if (!isTopicGrouped) {
      return treatedTableData.length;
    }

    return treatedTableData.reduce((prev, current) => {
      return prev + current.insight_subtopics.length;
    }, 0);
  };

  const filterOptions = useMemo(() => {
    const extraFilters = [];

    extraFilters.push({
      label: 'All workflows',
      options: intentsForFilter,
      value: 'workflow_id',
    });

    if (isDeflectionInsightsEnabled) {
      extraFilters.push(...DEFLECTION_INSIGHT_FILTER_OPTIONS);
    }

    return getInsightsFilterOptions({
      extraFilters,
    });
  }, [intentsForFilter, isDeflectionInsightsEnabled]);

  const handleResetAllFilter = useCallback(() => {
    setVisibleColumns(initialInsightTopicParams.topicColumns);
    setShowOnlyFilter([]);
    setDateRange(initialInsightTopicParams.date, () =>
      setSortColumn(initialInsightTopicParams.topicColumnSort, () =>
        setSortDirection('DESC', () =>
          setMultiSelected([], () => setChannels([], () => setCvFilters([]))),
        ),
      ),
    );
  }, [
    setCvFilters,
    setMultiSelected,
    setChannels,
    setSortDirection,
    setSortColumn,
    setVisibleColumns,
    setDateRange,
  ]);

  return (
    <Box
      bgcolor={palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      height='100%'
      overflow='auto'
      padding='24px 40px'
    >
      <TabHeader
        dateRange={stringifyDateRange(dateRange)}
        scope='main'
        tabName='Topics'
      />
      <OverallCards dateRange={dateRange} tab='topic' />
      <Typography
        color={palette.colors.grey[600]}
        variant='font16'
      >{`Topics created for ${percentCoverage} of Chats. Chats need to contain sufficient dialogue length to be classified as a Topic category.`}</Typography>
      <Box>
        <TopicsTable
          costPerTicket={costPerTicket}
          data={treatedTableData}
          emitTrackingEventCallback={emitTrackingEventCallback}
          filters={
            <Box display='flex' gap='8px' paddingTop='4px' width='100%'>
              <DateRangeFilterButton
                explicitLabel={true}
                initialValue={initialInsightTopicParams.date}
                onChange={setDateRange}
                options={datePickerRangeOptionsRevamp}
                value={dateRange}
              />
              <Button
                onClick={() => setIsDrawerOpen(true)}
                size='medium'
                startIcon={<TuneIcon />}
                variant='secondary'
              >
                <Typography noWrap variant='font14Medium'>
                  {filterCount ? `All filters (${filterCount})` : 'All filters'}
                </Typography>
              </Button>
              <Box display='flex'>
                <Checkbox
                  checked={isTopicGrouped}
                  label='Group Topics'
                  onChange={event => {
                    setIsTopicGrouped(event.target.checked);
                  }}
                  padding='0px 8px'
                />
                {Boolean(fallbackWorkflowId) && (
                  <Tooltip tooltipContent="Chats trigger ‘Fallback’ when they can't answer a question due to insufficient knowledge articles or workflow gaps">
                    <Checkbox
                      checked={isGapsOnlySelected}
                      label='Gaps only'
                      onChange={event => {
                        const isChecked = event.target.checked;
                        if (isChecked) {
                          setMultiSelected([
                            ...multiSelected,
                            `workflow_id.${fallbackWorkflowId}`,
                          ]);
                        } else {
                          const updatedMultiSelected = multiSelected.filter(
                            item =>
                              item !== `workflow_id.${fallbackWorkflowId}`,
                          );
                          setMultiSelected(updatedMultiSelected);
                        }
                      }}
                      padding='4px 8px'
                    />
                  </Tooltip>
                )}
              </Box>

              {areFiltersUsed && (
                <Button
                  onClick={handleResetAllFilter}
                  size='medium'
                  variant='ghost'
                >
                  <Typography noWrap variant='font14Medium'>
                    Reset filters
                  </Typography>
                </Button>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <InsightDownloadCsv
                  filename='topics.csv'
                  requestData={{
                    ...tableRequestParams,
                  }}
                  url={getTopicsTableDownloadUrl()}
                />
              </Box>
            </Box>
          }
          isLoading={isTopicTableLoading}
          isTopicGrouped={isTopicGrouped}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          totalTopicsCount={countAllTopics()}
        />
        {isDrawerOpen && (
          <GlobalFilterDrawer
            filterType='topic'
            handleReset={handleResetAllFilter}
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            otherFiltersOptions={filterOptions}
            tab='topic'
          />
        )}
      </Box>
    </Box>
  );
};

export default SolveInsightsTopics;
