import {
  Icon,
  IconListNumbers,
  IconMicrophone,
  IconSettings,
  IconVocabulary,
} from '@tabler/icons-react';

import { EmailAutomationPageContainer } from '../email-automation-page/EmailAutomationPageContainer';
import { EmailSettingsPage } from '../email-settings-page/EmailSettingsPage';
import { EmailInstructionalNotePage } from '../EmailInstructionalNotePage';
import { EmailToneOfVoicePage } from '../EmailToneOfVoicePage';

interface SolveEmailConfigTab {
  component: JSX.Element;
  Icon: Icon;
  id: string;
  isFullWidth?: boolean;
  label: string;
  title?: string;
}

export const GENERAL_EMAIL_SETTINGS_TAB = {
  component: <EmailSettingsPage />,
  Icon: IconSettings,
  id: 'general',
  isFullWidth: true,
  label: 'General settings',
};

export const EMAIL_TABS: SolveEmailConfigTab[] = [
  {
    component: <EmailAutomationPageContainer />,
    Icon: IconListNumbers,
    id: 'automations',
    isFullWidth: true,
    label: 'Automation rules',
  },
];

export const INSTRUCTIONAL_NOTE_TABS: SolveEmailConfigTab[] = [
  {
    component: <EmailToneOfVoicePage />,
    Icon: IconMicrophone,
    id: 'tone',
    isFullWidth: true,
    label: 'Tone',
  },
  {
    component: <EmailInstructionalNotePage />,
    Icon: IconVocabulary,
    id: 'instruction',
    isFullWidth: true,
    label: 'Instruction',
  },
];
