import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { Spacer } from '../ActionStep';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectHandoffCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ZammadTicketCreationCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface ZammadTicketCreationCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const ZammadTicketCreationCustomizationPanel: FC<
  ZammadTicketCreationCustomizationPanelProps
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultZammadCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<ZammadTicketCreationCustomizationSettings>;
  const defaultZammadTicketCreationCustomizationSettings = useMemo(() => {
    return {
      custom_fields: {},
      customer_email_variable: '',
      group_id: 0,
      priority_id: 0,
      severity: '',
      state_id: 0,
      ticket_hierarchy: '',
      ticket_id_output_variable: '',
      title_variable: '',
      ...defaultZammadCustomizationSettings,
    };
  }, [defaultZammadCustomizationSettings]);
  const zammadCustomization = addingAction
    ? defaultZammadTicketCreationCustomizationSettings
    : (canvasState.actionSettings
        ?.configuration_fields as ZammadTicketCreationCustomizationSettings);
  const isLoadingCustomization = canvasState.isLoadingCustomization;
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const [customizationData, setCustomizationData] =
    useState<ZammadTicketCreationCustomizationSettings>(zammadCustomization);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.ZAMMAD_TICKET_CREATION,
        ),
      );
    }
  }, [dispatch, addingAction]);

  useEffect(() => {
    if (!isLoadingCustomization) {
      const merged = addingAction
        ? defaultZammadTicketCreationCustomizationSettings
        : zammadCustomization;
      setCustomizationData(merged);
    }
  }, [
    isLoadingCustomization,
    addingAction,
    defaultZammadTicketCreationCustomizationSettings,
    zammadCustomization,
  ]);

  const isPristine = isEqual(customizationData, zammadCustomization);

  useEffect(() => {
    setAreActionSettingsUnsaved(!isPristine);
  }, [isPristine, setAreActionSettingsUnsaved]);

  const { priorityOptions, severityOptions, stateOptions } = useMemo(() => {
    if (!zammadCustomization) {
      return {
        priorityOptions: [],
        severityOptions: [],
        stateOptions: [],
      };
    }

    const ticket_states = zammadCustomization.zammad_ticket_states || [];
    const ticket_priorities =
      zammadCustomization.zammad_ticket_priorities || [];
    const severities = zammadCustomization.zammad_severities || [];

    const stateOptions = ticket_states.map(state => ({
      label: state.name,
      value: state.id.toString(),
    }));
    const priorityOptions = ticket_priorities.map(priority => ({
      label: priority.name,
      value: priority.id.toString(),
    }));
    const severityOptions = severities.map(severity => ({
      label: severity.name,
      value: severity.value,
    }));
    return { priorityOptions, severityOptions, stateOptions };
  }, [zammadCustomization]);

  if (isLoadingCustomization || !customizationData) {
    return <LoadingSkeleton />;
  }
  const groups = zammadCustomization.zammad_groups || [];
  const ticket_hierarchies =
    zammadCustomization.zammad_ticket_hierarchies || [];

  const ticketConfig = customizationData || {};
  const disableSave =
    hasWorkflowConflict ||
    !ticketConfig.group_id ||
    !ticketConfig.title_variable ||
    !ticketConfig.customer_email_variable;

  const handleSave = () => {
    const finalData = {
      ...customizationData,
    };
    if (addingAction) {
      dispatch(
        addAction({
          ...addingAction,
          data: {
            ...addingAction.data,
            configuration_fields: finalData,
          },
        }),
      );
    } else {
      dispatch(
        updateActionSettings({
          action_type: canvasState.actionSettings.action_type as string,
          configuration_fields: finalData,
        }),
      );
      dispatchActionEditedTrackingEvent({
        actionType: canvasState.actionSettings.action_type as string,
      });
    }
    setActionPanelVisibilityParameters('hidden');
    setAreActionSettingsUnsaved(false);
    dispatch(setCustomizableActionId(''));
    onDismiss();
  };

  const groupOptions = groups.map(group => ({
    label: group.name,
    value: group.id.toString(),
  }));
  const ticketHierarchiesOptions = ticket_hierarchies.map(ticket_hierarchy => ({
    label: ticket_hierarchy.name,
    value: ticket_hierarchy.value,
  }));

  return (
    <>
      <TopSection>
        <Title>Zammad Ticket Creation</Title>
        <Spacer height='16px' />
        <SelectDropdown
          id='group-dropdown'
          label='Group'
          onChange={e => {
            setCustomizationData(prev => ({
              ...prev,
              group_id: parseInt(e.target.value, 10),
            }));
          }}
          options={groupOptions}
          placeholder='Select a group...'
          value={ticketConfig.group_id.toString() || ''}
        />
        <Spacer height='16px' />
        <ContextVariableAutocomplete
          label='Ticket Title'
          onChange={val => {
            setCustomizationData(prev => ({
              ...prev,
              title_variable: val,
            }));
          }}
          placeholder='Enter ticket title'
          value={customizationData.title_variable || ''}
        />
        <Spacer height='16px' />
        <ContextVariableSelectDropdown
          isClearable
          label='Contact Email'
          onChange={val => {
            setCustomizationData(prev => ({
              ...prev,
              customer_email_variable: val,
            }));
          }}
          value={ticketConfig.customer_email_variable || ''}
        />
        <Spacer height='16px' />
        <ContextVariableSelectDropdown
          isClearable
          label='Ticket ID Output Variable'
          onChange={val => {
            setCustomizationData(prev => ({
              ...prev,
              ticket_id_output_variable: val,
            }));
          }}
          value={ticketConfig.ticket_id_output_variable || ''}
        />
        <Spacer height='16px' />
        <SelectDropdown
          id='state-dropdown'
          label='State'
          onChange={e => {
            setCustomizationData(prev => ({
              ...prev,
              state_id: parseInt(e.target.value, 10),
            }));
          }}
          options={stateOptions}
          placeholder='Select a state...'
          value={ticketConfig.state_id?.toString() || ''}
        />
        <Spacer height='16px' />
        <SelectDropdown
          id='priority-dropdown'
          label='Priority'
          onChange={e => {
            setCustomizationData(prev => ({
              ...prev,
              priority_id: parseInt(e.target.value, 10),
            }));
          }}
          options={priorityOptions}
          placeholder='Select a priority...'
          value={ticketConfig.priority_id?.toString() || ''}
        />
        <Spacer height='16px' />
        <SelectDropdown
          id='severity-dropdown'
          label='Severity'
          onChange={e => {
            setCustomizationData(prev => ({
              ...prev,
              severity: e.target.value,
            }));
          }}
          options={severityOptions}
          placeholder='Select a severity...'
          value={ticketConfig.severity || ''}
        />
        <Spacer height='16px' />
        <SelectDropdown
          id='ticket-hierarchy-dropdown'
          label='Ticket Hierarchy'
          onChange={e => {
            setCustomizationData(prev => ({
              ...prev,
              ticket_hierarchy: e.target.value,
            }));
          }}
          options={ticketHierarchiesOptions}
          placeholder='Select a Ticket Hierarchy...'
          value={ticketConfig.ticket_hierarchy || ''}
        />
      </TopSection>
      <SaveContainer hasTopBorder>
        <SecondaryButton onClick={onDiscard}>Cancel</SecondaryButton>
        <StyledButton disabled={disableSave} onClick={handleSave}>
          Save
        </StyledButton>
      </SaveContainer>
    </>
  );
};

export default ZammadTicketCreationCustomizationPanel;

const TopSection = styled('div')`
  padding: 16px;
`;

const Title = styled('h2')`
  margin: 0;
`;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-top: ${({ hasTopBorder }) =>
    hasTopBorder ? `1px solid ${theme.palette.colors.slate[200]}` : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
