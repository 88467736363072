import { useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { IconArrowDown, IconArrowUp, IconPlus } from '@tabler/icons-react';

import {
  Button,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import {
  setIsEditing,
  setIsUpdateError,
} from '../../../slices/connectors/connectorsSlice';
import ConnectionsCard from './ConnectionsCard';
import {
  useConnectors,
  useExistingNames,
  useIntegrationSlug,
  useTrackingEventOnSearchQueryChange,
} from './hooks';
import IntegrationDrawer from './integration-drawer';
import IntegrationsEmptyState from './IntegrationsEmptyState';
import IntegrationsLoadingState from './IntegrationsLoadingState';
import { ConnectionSection } from './styles';
import SettingsPageLayout from 'src/components/settings-page-layout';

export default function ConnectionsPage({
  multiConnectorsEnabled,
}: {
  multiConnectorsEnabled: boolean;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchQuery, setSearchQuery] = useState('');
  const integrationSlug = useIntegrationSlug();
  const [sortColumn, setSortColumn] = useState<
    'displayName' | 'lastSync' | 'status'
  >('displayName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const {
    availableConnectors,
    createdConnectorDefinitions,
    createdConnectors,
    filteredCreatedConnectors,
    isLoading,
  } = useConnectors(searchQuery);

  useTrackingEventOnSearchQueryChange(searchQuery);

  const existingNames = useExistingNames(createdConnectors);

  const selectedConnectorDefinition = useMemo(() => {
    const availableConnectorsForDetailView = availableConnectors;
    return [
      ...createdConnectorDefinitions,
      ...availableConnectorsForDetailView,
    ].find(connector => connector.slug === integrationSlug);
  }, [availableConnectors, createdConnectorDefinitions, integrationSlug]);

  const searchParams = new URLSearchParams(window.location.search);
  const connectorIdFromURL = searchParams.get('connectorId');

  const selectedConnector = useMemo(() => {
    return createdConnectors.find(
      connector => connector.connector_id === connectorIdFromURL,
    );
  }, [connectorIdFromURL, createdConnectors]);

  const isEmpty = useMemo(() => {
    return !isLoading && !createdConnectors.length;
  }, [createdConnectors, isLoading]);

  const sortedConnectors = useMemo(() => {
    return [...filteredCreatedConnectors].sort((a, b) => {
      if (sortColumn === 'displayName') {
        const nameA = (
          a.display_name
            ? String(a.display_name)
            : a.connector_definition.name
            ? String(a.connector_definition.name)
            : ''
        ).toLowerCase();
        const nameB = (
          b.display_name
            ? String(b.display_name)
            : b.connector_definition.name
            ? String(b.connector_definition.name)
            : ''
        ).toLowerCase();
        return sortDirection === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      } else if (sortColumn === 'lastSync') {
        const mA = a.last_processed_at
          ? moment(a.last_processed_at)
          : moment('1900-01-01');
        const mB = b.last_processed_at
          ? moment(b.last_processed_at)
          : moment('1900-01-01');

        return sortDirection === 'asc' ? mA.diff(mB) : mB.diff(mA);
      } else if (sortColumn === 'status') {
        const statusA = a.is_active;
        const statusB = b.is_active;
        if (statusA === statusB) {
          return 0;
        }
        return sortDirection === 'asc' ? (statusA ? 1 : -1) : statusA ? -1 : 1;
      }
      return 0;
    });
  }, [filteredCreatedConnectors, sortColumn, sortDirection]);

  const handleSort = (column: 'displayName' | 'lastSync' | 'status') => {
    if (sortColumn === column) {
      setSortDirection(prevDirection =>
        prevDirection === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const ColumnLabels = () => {
    const renderArrow = () =>
      sortDirection === 'asc' ? (
        <IconArrowUp size={16} />
      ) : (
        <IconArrowDown size={16} />
      );

    return (
      <Grid alignItems='center' container mb={3.5} spacing={2}>
        <Grid
          alignItems='center'
          display='flex'
          item
          onClick={() => handleSort('displayName')}
          style={{ cursor: 'pointer' }}
          xs={6}
        >
          <Typography variant='font14Bold'>Display Name</Typography>
          {renderArrow()}
        </Grid>

        <Grid
          display='flex'
          item
          justifyContent='left'
          onClick={() => handleSort('lastSync')}
          style={{ cursor: 'pointer' }}
          xs={3}
        >
          <Typography variant='font14Bold'>Last Sync</Typography>
          {renderArrow()}
        </Grid>

        <Grid
          alignItems='center'
          display='flex'
          item
          justifyContent='flex-end'
          onClick={() => handleSort('status')}
          style={{ cursor: 'pointer' }}
          xs={3}
        >
          <Typography variant='font14Bold'>Connect Status</Typography>
          {renderArrow()}
        </Grid>
      </Grid>
    );
  };

  return (
    <SettingsPageLayout>
      {isLoading && <IntegrationsLoadingState />}
      {isEmpty && (
        <Box>
          <Box display='flex' justifyContent='flex-end' mb={4}>
            <Button
              onClick={() => {
                navigate('/integrations?integrations-tab=1');
              }}
              startIcon={<IconPlus />}
              variant='main'
            >
              Connect new integration
            </Button>
          </Box>
          <IntegrationsEmptyState />
        </Box>
      )}
      {!isLoading && !!createdConnectors.length && (
        <Box>
          <Box alignItems='center' display='flex' mb={4}>
            <Box display='flex' flexGrow={1} gap={1}>
              <Box width='300px'>
                <SearchBar
                  onChange={({ target }) => setSearchQuery(target.value)}
                  onClear={() => setSearchQuery('')}
                  placeholder='Search'
                  size='small'
                  value={searchQuery}
                />
              </Box>
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <Button
                onClick={() => {
                  navigate('/integrations?integrations-tab=1');
                }}
                startIcon={<IconPlus />}
                variant='main'
              >
                Connect new integration
              </Button>
            </Box>
          </Box>

          <ColumnLabels />

          <ConnectionSection>
            {sortedConnectors.map((connector, index) => {
              const { connector_definition, connector_id, is_active } =
                connector;
              return (
                <ConnectionsCard
                  connectorDefinition={connector_definition}
                  connectorId={connector_id}
                  displayName={connector?.display_name}
                  isInActiveSection
                  key={`${connector_definition.slug}-${index}`}
                  lastSyncDate={connector.last_processed_at}
                  status={is_active ? 'active' : 'warning'}
                />
              );
            })}
          </ConnectionSection>
        </Box>
      )}
      <IntegrationDrawer
        connectorDefinition={selectedConnectorDefinition}
        existingNames={existingNames}
        isCollectionsPage={false}
        multiConnectorsEnabled={multiConnectorsEnabled}
        onClose={() => {
          dispatch(setIsUpdateError(false));
          dispatch(setIsEditing(false));
          navigate(pathname);
        }}
        selectedConnector={selectedConnector}
      />
    </SettingsPageLayout>
  );
}
