import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Box } from '@mui/material';

import { useGetIsTemplateIntentWorkflow } from '../workflow-builder-edit/hooks';
import { ApiPreviewWidget } from './api-preview/apiPreviewWidget';
import { EmailPreviewSection } from './interactive-email-preview/EmailPreviewSection';
import { RTVIProvider } from './voice-preview/RTVIProvider';
import { VoicePreviewSection } from './voice-preview/VoicePreviewSection';
import { WorkflowPreviewWidget } from './widget-preview/workflowPreviewWidget';
import { useGetColorMode } from './hooks';
import { PreviewRightSection } from './PreviewRightSection';
import { useGetDefaultIntentFilters } from './utils';
import { WorkflowPreviewHeader } from './WorkflowPreviewHeader';
import autonomousPreviewBackground from 'src/assets/images/autonomous-preview-background.jpg';
import {
  initializePreviewLogs,
  selectEmailPreviewStage,
  setPreviewColorMode,
  setPreviewIntentFilters,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';

export const WorkflowPreviewPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const colorMode = useGetColorMode();
  const { channel } = useParams<'channel'>();
  const { defaultIntentFilters, isLoading: isDefaultIntentFiltersLoading } =
    useGetDefaultIntentFilters();
  const isTemplate = useGetIsTemplateIntentWorkflow();
  const emailPreviewStage = useSelector(selectEmailPreviewStage);

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const previewFromMode = params.get('mode');

  const isIntentFilterInitialized = useRef<boolean>(false);

  useEffect(() => {
    if (channel === 'api' || channel === 'voice') {
      dispatch(setPreviewColorMode('dark'));
      return;
    } else if (channel === 'widget') {
      if (previewFromMode === 'autoflow') {
        dispatch(setPreviewColorMode('dark'));
        return;
      } else {
        dispatch(setPreviewColorMode('light'));
        return;
      }
    }

    dispatch(setPreviewColorMode('light'));
  }, [dispatch, channel, previewFromMode]);

  useEffect(() => {
    if (
      (isIntentFilterInitialized.current || isDefaultIntentFiltersLoading) &&
      !isTemplate
    ) {
      return;
    }

    dispatch(initializePreviewLogs());

    // set intent filter based on previewFromIntentId
    if (previewFromIntentId) {
      const newIntentFilters = [...defaultIntentFilters].map(filter =>
        filter.intentId === previewFromIntentId
          ? {
              ...filter,
              isAutoflow:
                previewFromMode === 'landing-page' || !previewFromMode
                  ? filter.isAutoflow
                  : previewFromMode === 'autoflow',
              isChecked: true,
              isDraft: true,
            }
          : filter,
      );

      dispatch(setPreviewIntentFilters(newIntentFilters));
    } else {
      dispatch(setPreviewIntentFilters([...defaultIntentFilters]));
    }

    isIntentFilterInitialized.current = true;
  }, [
    dispatch,
    previewFromIntentId,
    previewFromMode,
    defaultIntentFilters,
    isTemplate,
    isDefaultIntentFiltersLoading,
  ]);

  const getPreviewWidget = (channel: string | undefined) => {
    switch (channel) {
      case 'api':
        return <ApiPreviewWidget />;
      case 'email':
        return <EmailPreviewSection />;
      case 'voice':
        return <VoicePreviewSection />;
      default:
        return <WorkflowPreviewWidget />;
    }
  };

  const getShouldShowRightPanel = () => {
    if (channel === 'email') {
      return emailPreviewStage === 'interactive-widget';
    }

    if (channel === 'voice') {
      return false;
    }

    return true;
  };

  return (
    <RTVIProvider>
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        sx={
          colorMode === 'dark'
            ? {
                backgroundImage: `url(${autonomousPreviewBackground})`,
                backgroundSize: 'cover',
                display: 'flex',
                flex: 1,
                height: '100%',
              }
            : {}
        }
      >
        <WorkflowPreviewHeader />
        <Box
          display='flex'
          height='calc(100% - 65px)'
          sx={{
            background: channel === 'voice' ? 'rgba(0,0,0,0.7)' : null,
          }}
        >
          {getPreviewWidget(channel)}
          {getShouldShowRightPanel() && <PreviewRightSection />}
        </Box>
      </Box>
    </RTVIProvider>
  );
};
