import { ROUTE_LOOKER_ANALYTICS } from '../routes/routeDefinitions';
import { NavDataItem } from './types';
import iconAnalytics from 'src/assets/images/icon-analytics.svg';
import { Routes, Tabs } from 'src/utils/enums';

export const NEW_BADGE_LABEL = 'NEW';
export const INTERNAL_BADGE_LABEL = 'Internal';
export const BETA_BADGE_LABEL = 'Beta';
export const PRE_BETA_BADGE_LABEL = 'Pre-Beta';

export const LOOKER_TAB: NavDataItem = {
  icon: iconAnalytics,
  name: Tabs.LOOKER_ANALYTICS,
  route: ROUTE_LOOKER_ANALYTICS,
};

export const BASE_LOOKER_PATH = Routes.LOOKER_ANALYTICS.split(':')[0];
