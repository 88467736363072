import { useMemo } from 'react';
import { Box } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightCard from '../../solve-insights/common/InsightCard';
import { DONUT_SIZE } from '../../solve-insights/constants';
import { numbersToStringWithCommas } from '../../solve-insights/helpers';
import DonutGraph from 'src/components/solve-insights-charts/donut-graph.tsx/DonutGraph';
import { DonutGraphDataType } from 'src/services/insights/types';

interface PieChartCardProps {
  centerSubText: string;
  centerText: string;
  colors: string[];
  dateRange: DateRange;
  donutData: DonutGraphDataType;
  isLoading: boolean;
  title: string;
  tooltip: string;
}

const PieChartCard = ({
  centerSubText,
  centerText,
  colors,
  donutData,
  isLoading,
  title,
  tooltip,
}: PieChartCardProps) => {
  const showComparison = true;

  const donutValues = useMemo(() => donutData.values, [donutData]);

  return (
    <InsightCard
      isFetching={isLoading}
      isLoading={isLoading}
      title={title}
      tooltip={
        <Tooltip maxWidth={320} tooltipContent={tooltip}>
          <IconInfoCircle color='currentColor' height={16} width={16} />
        </Tooltip>
      }
    >
      <Box alignItems='center' display='flex'>
        <Box display='flex' flex={1} flexDirection='column' gap='16px'>
          {donutValues?.map((donutValue, index) => (
            <Box display='flex' flexDirection='column' gap='2px' key={index}>
              <Typography variant='font16Bold'>
                {numbersToStringWithCommas({
                  number: donutValue.value,
                })}
              </Typography>
              <Box alignItems='center' display='flex' gap='4px'>
                <Box bgcolor={colors[index]} height='12px' width='12px' />
                <Typography variant='font14'>
                  {donutValue.label.toLowerCase()}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box display='flex' flex={showComparison ? 0 : 1}>
          <DonutGraph
            centerSubText={centerSubText}
            centerText={centerText}
            colors={colors}
            containerProps={{
              style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
            }}
            data={donutData}
            disableTooltip
          />
        </Box>
      </Box>
    </InsightCard>
  );
};

export default PieChartCard;
