import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';

const useOrgConfig = () => {
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const {
    article_generation_version,
    is_ticket_keyword_search_enabled,
    taxonomy_version: taxonomyVersion = null,
  } = orgConfigData ?? {};

  return {
    article_generation_version,
    is_ticket_keyword_search_enabled,
    taxonomyVersion,
  };
};

export default useOrgConfig;
