import Box from '@mui/material/Box';

import {
  MultiSelectFilter,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import { FilterOption } from './types';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';

export interface AnalyticsFilterProps {
  filters: string[];
  handleFilterUpdate: (filters: string[]) => void;
  isLoading: boolean;
  options: FilterOption[] | null;
  page: 'allTopics' | 'topicDetails' | 'dashboard';
}

const NUMBER_OF_ITEMS_TO_SHOW = 18;
const HEIGHT_OF_ITEM = 28; //in px
const MAX_HEIGHT_FILTERS = NUMBER_OF_ITEMS_TO_SHOW * HEIGHT_OF_ITEM;

const AnalyticsFilter = ({
  filters,
  handleFilterUpdate,
  isLoading,
  options,
  page,
}: AnalyticsFilterProps) => {
  if (isLoading) {
    return (
      <Box width={100}>
        <Skeleton />
      </Box>
    );
  }
  if (!options) {
    return null;
  }
  return (
    <MultiSelectFilter
      icon={<img src={filterIcon} />}
      isMenuSearchable
      isVirtualized
      maxHeight={MAX_HEIGHT_FILTERS}
      menuTitle='Filter by'
      onChange={handleFilterUpdate}
      options={options}
      placeholder='Filter by'
      value={filters}
      variant='secondary'
      xAlign={page === 'topicDetails' ? 'right' : 'left'}
    />
  );
};

export default AnalyticsFilter;
