import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { cleanStr } from '../utils/cleanStr';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  ModuleName,
  Permission,
  Routes,
  SolveConfigTrackingEventTypes,
  Tabs,
} from '../utils/enums';
import { sendTrackingEvents } from '../utils/sendTrackingEvent';
import {
  constructTimeRangeQueryParamsRevamped,
  last30DaysTimeRange,
} from '../utils/timeRangeHelpers';
import { getMacroControlsData } from 'src/actions/macroControls';
import {
  setMacroControlsViewMode,
  setPageNumber,
  setSolveView,
  setTimeFrameFilter,
  setTimeRange,
} from 'src/actions/pageSettings/pageSettings';
import { sendTrackingEvent } from 'src/actions/search';
import {
  getSolveChannelsAPI,
  setChannel,
  setFilterType,
  setIsSolveWorkflows,
} from 'src/actions/solveData';
import { channelsList } from 'src/actions/workflow-builder/builder-landing-page-helpers/builderLandingPageHelpers';
import {
  getWorkflowBuilderLandingData,
  setIsOverlayVisible,
  setIsPreviewLoaded,
  setWorkflowBuilderChannel,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import { mutationObserverCallback } from 'src/components/app/layout/mutationObserverCallback';
import {
  TrackingEventScope,
  TrackingEventType,
} from 'src/components/app/types';
import { filterDict } from 'src/pages/solve-analytics/filterDict';
import { BuilderView } from 'src/pages/workflow-builder-edit/types';
import { selectCustomizableActionId } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  selectMacroControlsViewMode,
  selectPageNumber,
  selectSolveViewMode,
  selectTimeFrameFilter,
  selectTimeRange,
} from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { CustomRangeModifier } from 'src/reducers/pageSettingsReducer/types';
import { selectChannel, selectChannels } from 'src/reducers/solveReducer';
import {
  selectCurrentTab,
  selectDashboardApplicationsByRole,
  selectIsSolveLiteEnabled,
  selectUser,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { emitTrackingEventApi } from 'src/services/api';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetPublishedWidgetConfigurationsQuery } from 'src/services/solve-config/solveConfigApi';
import {
  useGetIntentsQuery,
  useGetWorkflowTagsQuery,
  useGetWorkflowTagsV2Query,
  useInitWorkflowBuilderSettingsQuery,
} from 'src/services/workflow-builder-metrics';
import { SolveWidgetProduct } from 'src/types/types';
import {
  ConversationChannel,
  SolveEmailTrackingApplication,
  SolveEmailTrackingEventsType,
} from 'src/types/workflowBuilderAPITypes';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';

const useConstructQueryParams = (): void => {
  const range: CustomRangeModifier = useSelector(selectTimeRange);
  const { from, to } = range;
  const timeframe = useSelector(selectTimeFrameFilter);
  const channel = useSelector(selectChannel);
  const page = useSelector(selectPageNumber);
  const view = useSelector(selectSolveViewMode);
  const startTime = from ? moment(from).unix() : 0;
  const endTime = to ? moment(to).unix() : 9999999999;
  const macroTableView = useSelector(selectMacroControlsViewMode);

  const pathName =
    location.pathname === '/' ? '/solve-analytics' : window.location.pathname;

  useEffect(() => {
    if (view === 'breakdowns') {
      window.history.replaceState(
        null,
        '',
        `${pathName}?view=breakdowns${encodeURIComponent(
          `&start=${startTime}&end=${endTime}&channel=${channel}&page=${page}`,
        )}`,
      );
    }
    if (view === 'overview') {
      window.history.replaceState(
        null,
        '',
        `${pathName}?view=overview${encodeURIComponent(
          `&start=${startTime}&end=${endTime}&channel=${channel}&timeframe=${timeframe}`,
        )}`,
      );
    }
    if (view === 'Macro Controls') {
      window.history.replaceState(
        null,
        '',
        `${pathName}?view=macro controls${encodeURIComponent(
          `&status=${macroTableView} `,
        )}`,
      );
    }
  }, [
    range,
    timeframe,
    channel,
    page,
    view,
    macroTableView,
    pathName,
    startTime,
    endTime,
  ]);
};

const useLoadSolve = (): void => {
  const dispatch = useDispatch();
  useEffect((): void => {
    // @ts-expect-error Assume that the type is correct
    const queryStr: {
      channel: string;
      end: number;
      page: number;
      start: number;
      status: string;
      timeframe: string;
      view: string;
    } = queryString.parse(
      decodeURIComponent(location.search.replace(/\%20/g, '_')),
    );
    const { channel, end, page, start, status, timeframe, view } = queryStr;

    const isSolveAnalyticsView = view === 'overview' || view === 'breakdowns';

    const emptyChannel = !channel || channel?.toLowerCase() === 'undefined';
    dispatch(setIsSolveWorkflows(false));
    dispatch(setFilterType({ key: 'all', name: 'All Macros & Articles' }));
    if (
      Object.entries(queryStr).length === 0 ||
      (isSolveAnalyticsView && emptyChannel)
    ) {
      dispatch(setSolveView('overview'));
      dispatch(getSolveChannelsAPI());
    }
    if (isSolveAnalyticsView) {
      if (view === 'overview') {
        dispatch(setTimeFrameFilter(timeframe));
      }
      if (view === 'breakdowns') {
        dispatch(setPageNumber(page));
      }
      dispatch(setChannel(channel));
      dispatch(setSolveView(view));
      dispatch(
        setTimeRange({
          from: new Date(start * 1000),
          to: new Date(end * 1000),
        }),
      );
      dispatch(getSolveChannelsAPI());
    }
    if (view === 'macro_controls') {
      dispatch(setSolveView('Macro Controls'));
      dispatch(setMacroControlsViewMode(cleanStr(status).trim()));
      dispatch(getMacroControlsData());
    }
  }, [dispatch]);
};

const useSendInitialTrackingEvent = (): void => {
  const dispatch = useDispatch();
  const channels = useSelector(selectChannels);
  const channel = useSelector(selectChannel);
  const viewMode = useSelector(selectSolveViewMode);
  const timeRange = useSelector(selectTimeRange);
  const timeframeFilter = useSelector(selectTimeFrameFilter);
  const application = Permission.SOLVE;
  const eventType = 'initial_load';
  const data = useMemo(
    () => ({
      channel,
      view_mode: viewMode,
    }),
    [channel, viewMode],
  );

  useEffect(() => {
    if (channels.length) {
      sendTrackingEvents(
        dispatch,
        eventType,
        timeRange,
        application,
        viewMode === 'overview'
          ? { ...data, timeframe_filter: timeframeFilter }
          : { ...data, filter_type: filterDict[0].name },
      );
    }
  }, [
    application,
    channels,
    data,
    dispatch,
    timeRange,
    timeframeFilter,
    viewMode,
  ]);
};

export const useSetDefaultTimeRangeQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: featureFlagsResponse } = useGetFeatureFlagsQuery();

  const locationHasQueryParams =
    location.search !== '?' && location.search.length > 0;

  useEffect(() => {
    if (!locationHasQueryParams && featureFlagsResponse) {
      const isSolveWidgetEnabled = featureFlagsResponse.feature_flags.includes(
        'solve_widget_enabled',
      );
      const queryParams = constructTimeRangeQueryParamsRevamped(
        last30DaysTimeRange,
        isSolveWidgetEnabled ? 'widget' : 'email',
      );

      navigate(`?${queryParams}`);
    }
  }, [navigate, locationHasQueryParams, location, featureFlagsResponse]);
};

export const useLoadWorkflowBuilderFromURL = (): void => {
  const dispatch = useDispatch();
  const routerLocation = useLocation();

  const { isSuccess: isInitWorkflowBuilderSettingsSuccess } =
    useInitWorkflowBuilderSettingsQuery();

  const pathName = routerLocation.pathname || '';
  const isLandingPage = pathName === Routes.WORKFLOW_BUILDER;
  const isEditWorkflowsPage = pathName === Routes.WORKFLOW_BUILDER_EDIT;

  useEffect(() => {
    if (!isInitWorkflowBuilderSettingsSuccess) {
      return;
    }

    // @ts-expect-error Assume that the type is correct
    const queryStr: {
      channel: string;
      end: number;
      start: number;
      workflowId: string;
    } = queryString.parse(
      decodeURIComponent(location.search.replace(/\%20/g, '_')),
    );

    const getWorkflowBuilderData = async () => {
      await dispatch(getWorkflowBuilderLandingData());
    };

    const { channel, end, start } = queryStr;

    if (isLandingPage) {
      dispatch(
        setWorkflowBuilderChannel(
          channelsList.includes(channel) ? channel : 'widget',
        ),
      );
      if (start && end) {
        dispatch(
          setTimeRange({
            from: new Date(start * 1000),
            to: new Date(end * 1000),
          }),
        );
      }
      getWorkflowBuilderData();
    }
  }, [
    dispatch,
    isLandingPage,
    isEditWorkflowsPage,
    isInitWorkflowBuilderSettingsSuccess,
  ]);
};

export const useFlamethrowerTrackingEventAction = (
  application: FlamethrowerTrackingApplications,
): ((
  eventType: FlamethrowerTrackingEventTypes,
  params?: Record<string, string | number | string[]>,
) => void) => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const { auth_time: authTime, email } = user?.user ?? {};

  return useCallback(
    (
      eventType: FlamethrowerTrackingEventTypes,
      params?: Record<string, string | number | string[]>,
    ) => {
      dispatch(
        sendTrackingEvent(ModuleName.FLAMETHROWER, {
          application,
          event_type: eventType,
          session_id: authTime,
          user_email: email,
          user_role: userRole,
          ...params,
        }),
      );
    },
    [dispatch, application, authTime, userRole, email],
  );
};

export const useSendSolveEmailTrackigEvents = (
  application: SolveEmailTrackingApplication,
): ((
  eventType: SolveEmailTrackingEventsType,
  params?: Record<string, string | number>,
) => void) => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const { auth_time: authTime, email } = user?.user ?? {};

  return useCallback(
    (
      eventType: SolveEmailTrackingEventsType,
      params?: Record<string, string | number | string[]>,
    ) => {
      dispatch(
        sendTrackingEvent(ModuleName.EMAIL_BUILDER, {
          application,
          event_type: eventType,
          session_id: authTime,
          user_email: email,
          user_role: userRole,
          ...params,
        }),
      );
    },
    [dispatch, application, authTime, userRole, email],
  );
};

export const useSolveConfigTrackingEventAction = (): ((
  eventType: SolveConfigTrackingEventTypes,
  params?: Record<string, string | number | string[]>,
) => void) => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const dispatch = useDispatch();

  const { auth_time: authTime, email } = user?.user ?? {};
  const moduleName = isSolveLiteEnabled
    ? ModuleName.SOLVE_LITE
    : ModuleName.SOLVE_CONFIG;

  return useCallback(
    (
      eventType: SolveConfigTrackingEventTypes,
      params?: Record<string, string | number | string[]>,
    ) => {
      dispatch(
        sendTrackingEvent(moduleName, {
          event_type: eventType,
          session_id: authTime,
          user_email: email,
          user_role: userRole,
          ...params,
        }),
      );
    },
    [dispatch, authTime, userRole, email, moduleName],
  );
};

export const useTrackCanvasWorkflowActionEditedEvent = (): ((
  params?: Record<string, string | number | string[]>,
) => void) => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const actionId = useSelector(selectCustomizableActionId);
  const dispatch = useDispatch();
  const { auth_time: authTime, email } = user?.user ?? {};

  return useCallback(
    (params?: Record<string, string | number | string[]>) => {
      dispatch(
        sendTrackingEvent(ModuleName.FLAMETHROWER, {
          actionId: actionId,
          application: FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
          event_type: FlamethrowerTrackingEventTypes.WORKFLOW_ACTION_EDITED,
          session_id: authTime,
          user_email: email,
          user_role: userRole,
          ...params,
        }),
      );
    },
    [dispatch, actionId, authTime, userRole, email],
  );
};

// Will hide the NPS survey when loaded async outside of workflow builder tabs
const useMutationObserver = () => {
  useEffect(() => {
    const targetNode = document.getElementsByTagName('body')[0];

    // Options for the observer (which mutations to observe)
    const config = { childList: true };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(mutationObserverCallback);

    // Start observing the target node for configured mutations
    targetNode && observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  }, []);
};

const useHandleNPSSurveyVisibility = () => {
  const currentTab = useSelector(selectCurrentTab);

  useEffect(() => {
    const npsSurveyDiv = document.getElementById('npsIframeContainer');

    const workflowBuilerTabs = [
      Tabs.WORKFLOW_BUILDER,
      Tabs.WORKFLOW_BUILDER_EDIT,
    ];

    const shouldDisplayNpsSurvey =
      // @ts-expect-error legacy code with untyped state
      currentTab && workflowBuilerTabs.includes(currentTab);

    if (npsSurveyDiv) {
      npsSurveyDiv.style.display = shouldDisplayNpsSurvey ? 'block' : 'none';
    }
  }, [currentTab]);
};

const useNpsSurvey = () => {
  useMutationObserver();
  useHandleNPSSurveyVisibility();
};

const useListenPreviewPostMessageEvents = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleMessage = (e: MessageEvent): void => {
      if (e.data.event === 'solveWidgetClosed') {
        dispatch(setIsOverlayVisible(false));
      }

      if (e.data.event === 'solveWidgetLoaded') {
        dispatch(setIsPreviewLoaded(true));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);
};

export interface TrackingEventAdditionalParams extends Record<string, unknown> {
  model_name?: string;
  scope?: TrackingEventScope;
}

const useEmitTrackingEventCallback = () => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const { auth_time: sessionId } = user?.user ?? {};

  return useCallback(
    (
      eventType: TrackingEventType,
      additionalParams?: TrackingEventAdditionalParams,
    ) => {
      emitTrackingEventApi({
        eventType,
        sessionId,
        userRole,
        ...additionalParams,
      });
    },
    [sessionId, userRole],
  );
};

const useEmitTrackingEvent = (
  eventType: TrackingEventType,
  additionalParams?: TrackingEventAdditionalParams,
) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const hasMounted = useRef(false);
  const { model_name, scope } = additionalParams ?? {};

  useEffect(() => {
    if (hasMounted.current) {
      return;
    }
    emitTrackingEventCallback(eventType, { model_name, scope });

    hasMounted.current = true;
  }, [eventType, emitTrackingEventCallback, model_name, scope]);
};

const useStateParams = <T>({
  deserialize,
  initialState,
  paramsName,
  serialize,
  validator,
}: {
  deserialize: (serializedState: string) => T;
  initialState: T;
  paramsName: string;
  serialize: (state: T) => string;
  validator?: (param: string) => boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const existingValue = params.get(paramsName);
  const parameterPassesValidation =
    existingValue && validator ? validator(existingValue) : true;
  const [state, setState] = useState<T>(
    existingValue && parameterPassesValidation
      ? deserialize(existingValue)
      : initialState,
  );

  const onChange = useCallback(
    (state: T, batchUpdater?: () => URLSearchParams) => {
      setState(state);

      if (batchUpdater) {
        const prevParams = batchUpdater();
        prevParams.set(paramsName, serialize(state));
        navigate({
          pathname: location.pathname,
          search: prevParams.toString(),
        });
        return prevParams;
      }

      const searchParams = new URLSearchParams(location.search);
      searchParams.set(paramsName, serialize(state));
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
      return searchParams;
    },
    [location.pathname, location.search, paramsName, navigate, serialize],
  );

  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    if (existingValue !== null) {
      setState(oldState => {
        const deserializedExistingValue = deserialize(existingValue);
        if (
          deserializedExistingValue !== oldState &&
          parameterPassesValidation
        ) {
          return deserializedExistingValue;
        }
        if (!parameterPassesValidation) {
          onChange(oldState);
        }
        return oldState;
      });
    }
  }, [
    existingValue,
    deserialize,
    paramsName,
    parameterPassesValidation,
    onChange,
  ]);

  return [state, onChange] as const;
};

const useGetSolveWidgetProduct = (): SolveWidgetProduct => {
  const [query] = useSearchParams();

  if (query.get('view')) {
    const view = query.get('view') as BuilderView;
    return CHANNEL_TO_PRODUCT_MAP[view === 'email' ? 'widget' : view];
  }
  if (query.get('channel')) {
    const channel = query.get('channel') as ConversationChannel;
    return CHANNEL_TO_PRODUCT_MAP[channel === 'email' ? 'widget' : channel];
  }
  return 'workflow_builder';
};

const useGetIntentsQueryWithProduct = (
  params?: {
    end_timestamp?: number | null;
    include_inquiry_counts?: boolean;
    start_timestamp?: number | null;
  },
  options?: {
    productOverride?: SolveWidgetProduct;
    refetchOnMountOrArgChange?: boolean;
    skip?: boolean;
  },
) => {
  const product = useGetSolveWidgetProduct();
  return useGetIntentsQuery(
    { ...params, product: options?.productOverride ?? product },
    options,
  );
};

export const useIsFeatureFlagEnabled = (featureFlag: string): boolean => {
  const { data: featureFlagsResponse } = useGetFeatureFlagsQuery();
  return Boolean(featureFlagsResponse?.feature_flags.includes(featureFlag));
};

export const useHasPermissionToApplication = (
  application: Permission,
): boolean => {
  // Given an application, check if the user has permission to access it
  const dashboardApplications = useSelector(selectDashboardApplicationsByRole);
  return Boolean(
    application in dashboardApplications && dashboardApplications[application],
  );
};

const useGetBrandColor = (index: number) => {
  const { palette } = useTheme();
  const colors = [
    palette.colors.blue,
    palette.colors.green,
    palette.colors.purple,
    palette.colors.red,
    palette.colors.yellow,
  ];
  const indexInColorsArray = index % colors.length;

  return colors[indexInColorsArray][300];
};

const useGetWorkflowTags = () => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');

  const { data: workflowTagsV1 = [] } = useGetWorkflowTagsQuery();
  const { data: workflowTagsV2 = [] } = useGetWorkflowTagsV2Query();

  if (isMultibrandEnabled) {
    return workflowTagsV2;
  }

  return workflowTagsV1;
};

const useIsWorkflowUsedByWidgetConfiguration = () => {
  const { data } = useGetPublishedWidgetConfigurationsQuery();
  const publishedWidgetConfigs = data?.solve_widget_configurations;

  const quickFeedbackWorkflowIds = useMemo(() => {
    const result: string[] = [];

    if (!publishedWidgetConfigs) {
      return result;
    }

    publishedWidgetConfigs.forEach(widgetConfig => {
      [
        widgetConfig.quick_feedback_config.negative_routing_intent_workflow_id,
        widgetConfig.csat_config.negative_rating_routing_intent,
      ].forEach(id => id && result.push(id));
    });

    return result;
  }, [publishedWidgetConfigs]);

  const sentimentAnalysisWorkflowIds = useMemo(() => {
    const result: string[] = [];

    if (!publishedWidgetConfigs) {
      return result;
    }

    publishedWidgetConfigs.forEach(widgetConfig => {
      [
        widgetConfig.sentiment_analysis_config
          .negative_sentiment_routing_intent_workflow_id,
      ]
        .filter(Boolean)
        .forEach(id => id && result.push(id));
    });

    return result;
  }, [publishedWidgetConfigs]);

  const isWorkflowIdInUse = useCallback(
    (intentWorkflowId: string | null) => {
      if (!intentWorkflowId) {
        return false;
      }
      return [
        ...quickFeedbackWorkflowIds,
        ...sentimentAnalysisWorkflowIds,
      ].includes(intentWorkflowId);
    },
    [quickFeedbackWorkflowIds, sentimentAnalysisWorkflowIds],
  );

  return isWorkflowIdInUse;
};

export {
  useConstructQueryParams,
  useEmitTrackingEvent,
  useEmitTrackingEventCallback,
  useGetBrandColor,
  useGetIntentsQueryWithProduct,
  useGetSolveWidgetProduct,
  useGetWorkflowTags,
  useIsWorkflowUsedByWidgetConfiguration,
  useListenPreviewPostMessageEvents,
  useLoadSolve,
  useNpsSurvey,
  useSendInitialTrackingEvent,
  useStateParams,
};
