import React, { memo } from 'react';
import { Box, styled } from '@mui/material';

import { DateRange } from '../../discover-dashboard-page/types';
import { ChatsSavingsCard } from '../common/ChatsSavingsCard';
import { CsatCard } from '../common/CsatCard';
import EngagementCard from '../common/EngagmentCard';
import FeedbackCard from '../common/FeedbackCard';
import RelevanceCard from '../common/RelevanceCard';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';

interface ChatComparisonCardsProps {
  dateRange: DateRange;
}

const ChatComparisonCards: React.FC<
  React.PropsWithChildren<ChatComparisonCardsProps>
> = ({ dateRange }) => {
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  return (
    <Box
      columnGap='22px'
      display='grid'
      gridTemplateColumns='1fr 1fr'
      rowGap='24px'
    >
      <ChatsSavingsCard dateRange={dateRange} tab='overview' />
      <CsatCard dateRange={dateRange} tab='overview' />
      {isDeflectionInsightsEnabled && (
        <RelevanceCard dateRange={dateRange} tab='overview' />
      )}
      <FeedbackCard dateRange={dateRange} tab='overview' />
      {isDeflectionInsightsEnabled && (
        <EngagementCard dateRange={dateRange} tab='overview' />
      )}
    </Box>
  );
};

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  width: 50%;
  padding: 24px;
  gap: 24px;
`;

const MemoizedChatComparisonCards = memo(ChatComparisonCards);

export default MemoizedChatComparisonCards;
