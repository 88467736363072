import { ComponentProps } from 'react';

import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import { getReadableNoResponseString } from 'src/components/dashboard-pages/solve-insights/helpers';
import { InsightsTab } from 'src/components/dashboard-pages/solve-insights/types';

export const ALL_INTENTS = 'all_intents';
export const ALLTIME_START = '0';
export const ALLTIME_END = '9999999999';
export const NA = 'n/a';
export const INTENT_ASSIGNMENT_TOOLTIP =
  'The last intent detected for this Chat. If the intent detection was made on a free form user query, you will have the option below to reassign this query to a new or existing intent.';
export const SMALL_CELL_WIDTH = 161;
export const STICKY_HEADER_HEIGHT = 80;
export const TOTAL_STICKY_HEADERS_HEIGHT = 229;
export const TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES = 309;
export const USER_ENGAGEMENT_COPY = {
  no: 'No',
  yes: 'Yes',
};
const RELEVANCE_COPY = {
  irrelevant: 'Irrelevant',
  relevant: 'Relevant',
  somewhatRelevant: 'Somewhat relevant',
};
export const CHANNEL_COPY = {
  api: 'API',
  slack: 'Slack',
  widget: 'Widget',
};
const QUERY_COPY = {
  with: 'With user query',
  without: 'Without user query',
};
const WORKFLOW_TYPES_COPY = {
  autoflows: 'AutoFlows',
  classic: 'Classic',
};
const CSAT_RATINGS = {
  five: '5',
  four: '4',
  notApplicable: 'N/A',
  one: '1',
  three: '3',
  two: '2',
};
const YES_NO = {
  false: 'No',
  true: 'Yes',
};

export const DEFLECTION_INSIGHT_FILTER_OPTIONS = [
  {
    label: 'Relevance',
    options: [
      { label: RELEVANCE_COPY.relevant, value: 'relevance_ratings.relevant' },
      {
        label: RELEVANCE_COPY.somewhatRelevant,
        value: 'relevance_ratings.somewhat relevant',
      },
      {
        label: RELEVANCE_COPY.irrelevant,
        value: 'relevance_ratings.irrelevant',
      },
    ],
    value: 'relevance_ratings',
  },
  {
    label: 'User engagement',
    options: [
      { label: USER_ENGAGEMENT_COPY.yes, value: 'dropoff_ratings.false' },
      { label: USER_ENGAGEMENT_COPY.no, value: 'dropoff_ratings.true' },
    ],
    value: 'dropoff_ratings',
  },
];

export const CSAT_RATINGS_KEY = 'csat_ratings';
export const getInsightsFilterOptions = ({
  extraFilters,
  hideWorkflowTypes,
}: {
  extraFilters: ComponentProps<typeof MultiSelectFilter>['options'];
  hideWorkflowTypes?: boolean;
}) => {
  const options = [
    {
      label: 'All workflow types',
      options: [
        {
          label: WORKFLOW_TYPES_COPY.autoflows,
          value: 'autonomous_agent_used.true',
        },
        {
          label: WORKFLOW_TYPES_COPY.classic,
          value: 'autonomous_agent_used.false',
        },
      ],
      value: 'autonomous_agent_used',
    },
    ...extraFilters,
    {
      label: 'CSAT',
      options: [
        { label: CSAT_RATINGS.five, value: `${CSAT_RATINGS_KEY}.5` },
        { label: CSAT_RATINGS.four, value: `${CSAT_RATINGS_KEY}.4` },
        { label: CSAT_RATINGS.three, value: `${CSAT_RATINGS_KEY}.3` },
        { label: CSAT_RATINGS.two, value: `${CSAT_RATINGS_KEY}.2` },
        { label: CSAT_RATINGS.one, value: `${CSAT_RATINGS_KEY}.1` },
        {
          label: CSAT_RATINGS.notApplicable,
          value: `${CSAT_RATINGS_KEY}.null`,
        },
      ],
      value: 'csat_exists',
    },
    {
      label: 'Query',
      options: [
        { label: QUERY_COPY.with, value: 'query_exists.true' },
        { label: QUERY_COPY.without, value: 'query_exists.false' },
      ],
      value: 'query_exists',
    },
    {
      label: 'Deflection',
      options: [
        { label: YES_NO.true, value: 'deflection.true' },
        { label: YES_NO.false, value: 'deflection.false' },
      ],
      value: 'deflection',
    },
  ];

  const handleFilterOptions = () => {
    if (hideWorkflowTypes) {
      return options.filter(option => option.value !== 'autonomous_agent_used');
    }

    return options;
  };

  return handleFilterOptions();
};

export const getVoiceInsightsFilterOptions = () => {
  return [
    {
      label: 'Sentiment',
      options: [
        { label: 'Positive', value: 'voice_sentiment.positive' },
        {
          label: 'Neutral',
          value: 'voice_sentiment.neutral',
        },
        {
          label: 'Negative',
          value: 'voice_sentiment.negative',
        },
      ],
      value: 'sentiment',
    },
  ];
};

export const getEmailInsightsFilterOptions = ({
  extraFilters,
  type,
}: {
  extraFilters: ComponentProps<typeof MultiSelectFilter>['options'];
  type: InsightsTab;
}) => {
  const executionStatusOptions = [
    'language_not_enabled',
    'no_response',
    'started',
    'ticket_field_not_found',
    'no_matching_transition',
    'execution_error',
    'zero_multilngual_static_articles',
    'no_plausible_response',
    'workflow_not_found',
    'zero_dynamic_articles',
    'no_paraphrased_answer',
    'zendesk_api_error',
    'user_rate_limited_exceeded',
  ].map(key => ({
    label: getReadableNoResponseString(key),
    value: `unanswered_cause.${key}`,
  }));

  const skipReasonOptions = [
    'responded_to_by_agent',
    'non_email_channel',
    'user_email_not_found',
    'no_intent_predicted',
    'user_rate_limited_exceeded',
    'non_english',
    'email_automation',
    'from_widget_handoff',
    'blocked_email',
    'ticket_closed',
    'out_of_office_reply',
  ].map(key => ({
    label: getReadableNoResponseString(key),
    value: `skip_reason.${key}`,
  }));

  if (type === 'article') {
    return [...extraFilters];
  }
  if (type === 'chat') {
    return [
      {
        label: 'Conversation types',
        options: [
          { label: 'Email', value: 'conversation_type.email' },
          {
            label: 'Email autoflow',
            value: 'conversation_type.email_autoflow',
          },
          {
            label: 'Interactive email',
            value: 'conversation_type.interactive_email',
          },
        ],
        value: 'conversation_type',
      },
      {
        label: 'Deflected',
        options: [
          { label: YES_NO.true, value: 'deflection.true' },
          { label: YES_NO.false, value: 'deflection.false' },
        ],
        value: 'deflection',
      },
      {
        label: 'Eligible',
        options: [
          { label: YES_NO.true, value: 'eligible.true' },
          { label: YES_NO.false, value: 'eligible.false' },
        ],
        value: 'eligible',
      },
      {
        label: 'Email sent',
        options: [
          { label: YES_NO.true, value: 'email_sent.true' },
          { label: YES_NO.false, value: 'email_sent.false' },
        ],
        value: 'email_sent',
      },
      // Dynamic options
      ...extraFilters,
      {
        label: 'No response reason',
        options: [...executionStatusOptions, ...skipReasonOptions],
        value: 'unanswered_cause',
      },
      {
        label: 'User feedback',
        options: [
          { label: 'positive', value: 'feedback.positive' },
          { label: 'negative', value: 'feedback.negative' },
          { label: 'N/A', value: 'feedback.N/A' },
        ],
        value: 'feedback',
      },
    ];
  }
  return [];
};

export const FROM_NUMBER = 'from_number';
export const TO_NUMBER = 'to_number';
