import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  Chip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Status } from 'src/components/card/types';
import { ConnectorDefinition } from 'src/services/apiInterfaces';
import { DEFAULT_CONNECTOR_AVATAR } from 'src/utils/connectorUtils';

interface IntegrationsCardProps {
  connectorDefinition: ConnectorDefinition;
  connectorId: string;
  displayName: string | undefined;
  isInActiveSection?: boolean;
  lastSyncDate: string | null;
  status: Status;
}

export default function ConnectionsCard({
  connectorDefinition,
  connectorId,
  displayName,
  lastSyncDate,
  status,
}: IntegrationsCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { avatar, name, slug } = connectorDefinition;

  return (
    <Box
      border={`1px solid ${theme.palette.colors.slate[200]}`}
      borderRadius='8px'
      key={slug}
      mb={1.5}
      onClick={() => {
        navigate(
          `?integrationSlug=${connectorDefinition.slug}&connectorId=${connectorId}`,
        );
      }}
      px={3}
      py={2.5}
      sx={{
        '&:hover': {
          border: `1px solid ${theme.palette.colors.grey[800]}`,
          boxShadow:
            '0px 0px 1px rgba(0, 12, 32, 0.04), 0px 2px 6px rgba(3, 17, 38, 0.107135)',
        },
        cursor: 'pointer',
      }}
    >
      <Grid alignItems='center' container spacing={2}>
        <Grid alignItems='center' display='flex' item xs={6}>
          <Box alignItems='center' display='flex' mr={2}>
            <img
              alt={name}
              src={avatar || DEFAULT_CONNECTOR_AVATAR}
              style={{ borderRadius: '50%', height: 40, width: 40 }}
            />
          </Box>
          <Box>
            <Typography variant='font14Bold'>{displayName ?? name}</Typography>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            {lastSyncDate
              ? moment(lastSyncDate).format('MMM D, YYYY, h:mm a')
              : 'N/A'}
          </Typography>
        </Grid>

        <Grid display='flex' item justifyContent='flex-end' xs={3}>
          <Chip
            label={status === 'active' ? 'In use' : 'Not used'}
            startAdornment={
              <Box
                bgcolor={
                  status === 'active'
                    ? theme.palette.colors.green[500]
                    : theme.palette.colors.grey[400]
                }
                borderRadius='50%'
                height='12px'
                width='12px'
              />
            }
            sx={{
              borderColor: theme.palette.colors.slate[200],
              color: theme.palette.colors.grey[700],
              fontSize: 12,
              fontWeight: 500,
              height: '26px',
            }}
            variant='outlined'
          />
        </Grid>
      </Grid>
    </Box>
  );
}
