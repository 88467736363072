import 'react-phone-input-2/lib/style.css';

import { styled, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

export const VoiceConfigSection = ({
  children,
  title,
  tooltip,
}: {
  children: React.ReactNode;
  title: string;
  tooltip?: string;
}) => {
  const { palette } = useTheme();

  return (
    <Section>
      <Line>
        <Typography variant='font16Bold'>{title}</Typography>
        {tooltip && (
          <Tooltip placement='top' tooltipContent={tooltip}>
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        )}
      </Line>

      {children}
    </Section>
  );
};

const Line = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
