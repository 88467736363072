import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { TablerIconsProps } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

export interface RightSidebarProps<T> {
  navigationItems: {
    Icon: (props: TablerIconsProps) => JSX.Element;
    name: string;
    tab: T;
  }[];
  onTabChange: (tab: T) => void;
  selectedTab: T;
}

const RightSidebar = <T extends string>({
  navigationItems,
  onTabChange,
  selectedTab,
}: RightSidebarProps<T>) => {
  const { palette } = useTheme();

  return (
    <Box
      bgcolor='white'
      display='flex'
      flexDirection='column'
      gap={1.25}
      px={1}
      py={2.5}
      sx={{
        overflowY: 'auto',
      }}
      zIndex={1}
    >
      {navigationItems.map(navItem => {
        const variant = selectedTab === navItem.tab ? 'secondary' : 'ghost';
        const { Icon, name, tab } = navItem;
        const isActive = selectedTab === tab;

        return (
          <Box
            alignItems='center'
            display='flex'
            flexDirection='column'
            gap={0.5}
            key={navItem.tab}
            sx={{ button: { height: '40px', width: '40px' } }}
          >
            <Button
              color={palette.colors.grey[700]}
              onClick={() => onTabChange(navItem.tab)}
              variant={variant}
            >
              <Icon
                color={
                  isActive
                    ? palette.colors.purple[500]
                    : palette.colors.grey[700]
                }
                size={24}
              />
            </Button>
            <Typography color={palette.colors.grey[700]} variant='font11'>
              {name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default RightSidebar;
