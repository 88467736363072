import React, { FC, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Box, useTheme } from '@mui/material';
import { IconDotsVertical } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  SelectDropdown,
  Skeleton,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import MappingSelectDropdown from './mapping-select-dropdown/MappingSelectDropdown';
import { CV_LENGTH_VALIDATION_ERROR } from './constants';
import CVConfigurationModal from './CVConfigurationModal';
import DefaultValueInput from './DefaultValueInput';
import { useGetCvCustomFields } from './helpers';
import { useIsCvHelpdeskFieldsAvailable } from './hooks';
import isEqual from 'lodash/isEqual';
import listIcon from 'src/assets/images/list-icon.svg';
import trashIcon from 'src/assets/images/trash.svg';
import Checkbox from 'src/components/checkbox';
import { ListOption } from 'src/components/reusable-components/autocomplete-dropdown/types';
import * as Styled from 'src/pages/action-builder/context-variables-settings/styles';
import { UpdateFieldMappingParams } from 'src/pages/workflow-builder-config/context-variables/ContextVariablesPage';
import {
  CreateTicketFieldMappingRequest,
  TicketCustomField,
} from 'src/pages/workflow-builder-edit/types';
import {
  useGetFeatureFlagsQuery,
  useGetHelpdeskQuery,
} from 'src/services/dashboard-api';
import { useGetTriageModelsQuery } from 'src/services/triage/triageApi';
import { EntityType, UsageByEntityType } from 'src/types/actionBuilderApiTypes';
import { flattenCvs } from 'src/utils/actionBuilder/helpers';
import {
  ActionBuilderSettingsVariablesDict,
  ContextVariableConfigurationDict,
} from 'src/utils/actionBuilder/types';
import { ContextVariableTypeKeys, ContextVariableTypes } from 'src/utils/enums';

interface GlobalCVRowProps {
  configurationFields?: ContextVariableConfigurationDict;
  customTicketFields: Array<TicketCustomField>;
  defaultValue: unknown;
  deleteTicketFieldMapping: (id: string) => void;
  displayName: string;
  errMsg: string;
  id: string;
  isPersistedCVWorkflowContext: boolean;
  isWorkflowContext: boolean;
  listTypeOptions?: ListOption[];
  onFieldRemoval: (rowIdx: number) => void;
  rowIdx: number;
  setDynamicListOptionsViewerIndex: (index: number) => void;
  setSelectedListOptionPickerIndex: (index: number) => void;
  shouldShowSkeletons: boolean;
  ticketFieldMappings: CreateTicketFieldMappingRequest[];
  type: keyof typeof ContextVariableTypes;
  updateTicketFieldMappings: (mapping: UpdateFieldMappingParams) => void;
  updateVariables: (
    value: string | ListOption[] | boolean | ContextVariableConfigurationDict,
    idx: number,
    key: keyof ActionBuilderSettingsVariablesDict,
  ) => void;
  usageByEntity: UsageByEntityType | null;
}

const getContextVariableTypesOptions = (featureFlags: string[]) => {
  return Object.keys(ContextVariableTypes)
    .map(type => ({
      disabled: type === 'DYNAMIC_LIST',
      label: ContextVariableTypes[type as keyof typeof ContextVariableTypes],
      value: type,
    }))
    .filter(
      type =>
        type.label !== ContextVariableTypes.MULTIOPTIONS &&
        (type.label !== ContextVariableTypes.FLAT_LIST ||
          featureFlags.includes('attachment_analysis_upload_step')),
    );
};

const GlobalCVRow: FC<React.PropsWithChildren<GlobalCVRowProps>> = ({
  configurationFields,
  customTicketFields,
  defaultValue,
  deleteTicketFieldMapping,
  displayName,
  errMsg,
  id,
  isPersistedCVWorkflowContext,
  isWorkflowContext,
  listTypeOptions,
  onFieldRemoval,
  rowIdx,
  setDynamicListOptionsViewerIndex,
  setSelectedListOptionPickerIndex,
  shouldShowSkeletons,
  ticketFieldMappings,
  type,
  updateTicketFieldMappings,
  updateVariables,
  usageByEntity,
}) => {
  const { palette } = useTheme();
  const { data: helpdeskData } = useGetHelpdeskQuery();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const isTriageMappingEnabled = featureFlags.includes('triage_model_mapping');
  const shouldShowHelpdeskMapping = useIsCvHelpdeskFieldsAvailable();

  const ticketMappingData = useGetCvCustomFields(id, ticketFieldMappings);

  const { data: triageModels = [] } = useGetTriageModelsQuery(undefined, {
    skip: !isSolveEmailEnabled || !isTriageMappingEnabled,
  });

  const [configAnchorEl, setConfigAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const inputColRef = useRef<HTMLDivElement>(null);
  const hasCvValidationError = errMsg === CV_LENGTH_VALIDATION_ERROR;
  const isUsedByWidgetWorkflow = !!usageByEntity?.widget_workflow;

  const usageByEntityList = Object.keys(
    usageByEntity || [],
  ) as Array<EntityType>;

  const dynamicListSubItemsLength = useMemo(() => {
    if (!configurationFields?.dynamicListConfig?.context_variables?.length) {
      return 0;
    }

    return flattenCvs(configurationFields?.dynamicListConfig?.context_variables)
      .length;
  }, [configurationFields?.dynamicListConfig?.context_variables]);

  /** Disable the edition of the CV if it's used by any entity */
  const shouldDisableEdition =
    usageByEntityList.some(entity => usageByEntity?.[entity]) ||
    shouldShowSkeletons;

  /**  The delete button should be disabled in two cases:
   *    1. It's used by any entity (we don't take in consideration ticket_field_mapping for this case)
   *    2. The value for all entities is false, except for ticket_field_mapping
   * */
  const isDeleteButtonDisabled =
    usageByEntityList.some(entity => {
      const isTicketFieldMappingEntity = entity === 'ticket_field_mapping';

      return isTicketFieldMappingEntity
        ? !usageByEntity?.[entity]
        : usageByEntity?.[entity];
    }) || shouldShowSkeletons;

  /**LIST AND MULTI_SELECT_LIST should be treated the same */
  const isListType =
    type === ContextVariableTypeKeys.LIST ||
    type === ContextVariableTypeKeys.MULTI_SELECT_LIST;

  const isDynamicListType = type === ContextVariableTypeKeys.DYNAMIC_LIST;

  return (
    <>
      <Styled.Cell width='100%'>
        <Box
          alignItems='center'
          display='flex'
          gap={1.5}
          mt='10px'
          ref={inputColRef}
          width='100%'
        >
          <Box flex={1}>
            <TextField
              aria-label={`Display name for context variable ${rowIdx + 1}`}
              disabled={shouldDisableEdition}
              error={!hasCvValidationError && errMsg}
              name='Display Name'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateVariables(e.target.value, rowIdx, 'displayName')
              }
              placeholder='Enter display name'
              type='text'
              value={displayName}
            />
          </Box>
          {isListType && (
            <Styled.EmbeddedButton
              disabled={!displayName}
              onClick={() => {
                setSelectedListOptionPickerIndex(rowIdx);
              }}
            >
              <ReactSVG src={listIcon} />
              <span>{listTypeOptions?.length || 'Add Items'}</span>
            </Styled.EmbeddedButton>
          )}
          {isDynamicListType && (
            <Styled.EmbeddedButton
              onClick={() => setDynamicListOptionsViewerIndex(rowIdx)}
            >
              <ReactSVG src={listIcon} />
              <span>{dynamicListSubItemsLength}</span>
            </Styled.EmbeddedButton>
          )}
        </Box>
      </Styled.Cell>
      <Styled.DropdownCell>
        {shouldShowSkeletons ? (
          <Skeleton width='100%' />
        ) : (
          <>
            <SelectDropdown
              aria-label={`Type for ${displayName}`}
              disabled={shouldDisableEdition}
              id={`cv-type-selector-${rowIdx}`}
              onChange={e => {
                updateVariables(e.target.value ?? '', rowIdx, 'type');
                if (shouldShowHelpdeskMapping) {
                  deleteTicketFieldMapping(id);
                }
              }}
              options={getContextVariableTypesOptions(featureFlags)}
              value={type}
            />
            <Box pl={1}>
              {type === 'SHORT_TEXT' && (
                <IconButton
                  aria-label='CV configuration'
                  hoverBackgroundColor={palette.colors.purple[200]}
                  id={`cv-configuration-button-${rowIdx}`}
                  onClick={e => setConfigAnchorEl(e.currentTarget)}
                  variant='ghost'
                >
                  <IconDotsVertical
                    color={palette.colors.grey[800]}
                    size={17.5}
                  />
                </IconButton>
              )}
            </Box>
          </>
        )}
      </Styled.DropdownCell>
      {shouldShowHelpdeskMapping && helpdeskData && (
        <Styled.DropdownCell>
          {shouldShowSkeletons ? (
            <Skeleton width='100%' />
          ) : (
            <MappingSelectDropdown
              contextVariableType={type}
              customTicketFields={customTicketFields}
              deleteTicketFieldMapping={() => deleteTicketFieldMapping(id)}
              helpdesk={helpdeskData.helpdesk}
              rowIndex={rowIdx}
              ticketMappingData={ticketMappingData}
              triageModels={triageModels}
              updateTicketFieldMappings={selectedValue =>
                updateTicketFieldMappings({
                  context_variable_id: id,
                  selectedValue,
                })
              }
            />
          )}
        </Styled.DropdownCell>
      )}

      <Styled.Cell>
        <Styled.Spacer width='28px' />
        <Checkbox
          ariaLabel={`Pre-defined ${displayName}`}
          checked={isWorkflowContext}
          disabledFlyoutMessage='Unable to deselect this context variable since it is currently used in workflows'
          isDisabled={isUsedByWidgetWorkflow && isPersistedCVWorkflowContext}
          onChange={() =>
            updateVariables(!isWorkflowContext, rowIdx, 'isWorkflowContext')
          }
        />
      </Styled.Cell>
      <Styled.Cell>
        {shouldShowSkeletons ? (
          <Skeleton width='100%' />
        ) : (
          <DefaultValueInput
            configurationFields={configurationFields}
            isWorkflowContext={isWorkflowContext}
            listTypeOptions={listTypeOptions}
            onChageError={isError =>
              updateVariables(isError, rowIdx, 'isValueError')
            }
            onChange={value =>
              updateVariables(value as string, rowIdx, 'defaultValue')
            }
            type={type}
            value={defaultValue}
          />
        )}
      </Styled.Cell>
      <Styled.Cell width='6%'>
        <Tooltip
          tooltipContent={
            isDeleteButtonDisabled
              ? 'Unable to delete this context variable since it is currently in use'
              : ''
          }
        >
          <Button
            aria-label='Remove cv'
            disabled={isDeleteButtonDisabled}
            onClick={() => {
              onFieldRemoval(rowIdx);

              if (shouldShowHelpdeskMapping) {
                deleteTicketFieldMapping(id);
              }
            }}
            startIcon={
              <Styled.ImgFill
                isDisabled={isDeleteButtonDisabled}
                src={trashIcon}
              />
            }
            variant='ghost'
          />
        </Tooltip>
      </Styled.Cell>
      <CVConfigurationModal
        configAnchorEl={configAnchorEl}
        configurationFields={configurationFields}
        errMsg={errMsg}
        rowIdx={rowIdx}
        setConfigAnchorEl={setConfigAnchorEl}
        updateVariables={updateVariables}
      />
    </>
  );
};

const customComparator = (
  prevProps: GlobalCVRowProps,
  nextProps: GlobalCVRowProps,
) => {
  return isEqual(prevProps, nextProps);
};

const MemoizedGlobalCVRow = React.memo(GlobalCVRow, customComparator);

export default MemoizedGlobalCVRow;
