import { Box, Grid, useTheme } from '@mui/material';

import { Skeleton } from '@forethought-technologies/forethought-elements';

export default function ConnectionCardSkeleton() {
  const theme = useTheme();

  return (
    <Box
      border={`1px solid ${theme.palette.colors.slate[200]}`}
      borderRadius='8px'
      mb={1.5}
      px={3}
      py={2.5}
    >
      <Grid alignItems='center' container spacing={2}>
        <Grid alignItems='center' display='flex' item xs={6}>
          <Skeleton height={40} variant='circular' width={40} />
          <Box ml={2} width='60%'>
            <Skeleton height={20} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Skeleton height={20} width='100%' />
        </Grid>
        <Grid display='flex' item justifyContent='flex-end' xs={3}>
          <Skeleton height={26} width='50%' />
        </Grid>
      </Grid>
    </Box>
  );
}
