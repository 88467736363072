import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import ArticleList from './ArticleList';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface TableArticleListProps {
  rowData?: KnowledgeGapArticleRecommendation;
}

const TargetArticle = ({ rowData }: TableArticleListProps) => {
  const { palette } = useTheme();
  if (!rowData?.updated_article_link) {
    return (
      <Typography color={palette.colors.grey[500]} variant='font14'>
        {NA}
      </Typography>
    );
  }
  const article = {
    article_id: rowData?.updated_article_id ?? null,
    link: rowData?.updated_article_link ?? null,
    title: rowData?.updated_article_title ?? null,
  };
  return <ArticleList articles={[article]} />;
};

export default TargetArticle;
