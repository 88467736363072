import { Box } from '@mui/material';

import { AvgResponseTimeCard } from './AvgResponseTimeCard';
import { SentimentCard } from './SentimentCard';
import { TotalConversationSavingCard } from './TotalConversationSavingCard';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { DateRange } from 'src/types/types';

export const OverallCards = ({ dateRange }: { dateRange: DateRange }) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isAvgResponseTimeCardEnabled = featureFlags.includes(
    'avg_response_time_card',
  );

  return (
    <Box
      display='flex'
      gap={3}
      justifyContent='space-between'
      minHeight='280px'
      pb={1}
      sx={{
        overflowX: 'scroll',
      }}
    >
      <TotalConversationSavingCard dateRange={dateRange} />
      <SentimentCard dateRange={dateRange} />
      {isAvgResponseTimeCardEnabled && (
        <AvgResponseTimeCard dateRange={dateRange} />
      )}
    </Box>
  );
};
