import { styled } from '@mui/material';
import { useRTVIClientTransportState } from '@pipecat-ai/client-react';

import logo from 'src/assets/images/forethought-logo-v2.svg';

export const NeonCircle = () => {
  const transportState = useRTVIClientTransportState();
  const isIdle = ['error', 'disconnected', 'disconnecting'].includes(
    transportState,
  );

  return (
    <Container isIdle={isIdle}>
      <GlowEffect />
      <NeonLight />
      <MainCircle>
        <LogoWrapper>
          <img src={logo} />
        </LogoWrapper>
      </MainCircle>
    </Container>
  );
};

const Container = styled('div')<{
  isIdle: boolean;
}>`
  position: relative;
  width: 300px;
  height: 300px;

  animation: ${props =>
    !props.isIdle ? 'float 1.5s ease-in-out infinite alternate' : null};

  filter: drop-shadow(0 0 50px rgba(128, 0, 255, 0.6));

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-40px);
    }
  }
`;

const MainCircle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.9) 40%,
    rgba(0, 0, 0, 1) 70%
  );

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: gem-glow 4s ease-in-out infinite alternate;

  @keyframes gem-glow {
    0% {
      box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.1),
        inset 0 0 50px rgba(138, 43, 226, 0.3), 0 0 50px rgba(128, 0, 255, 0.5);
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      box-shadow: inset 0 0 40px rgba(255, 255, 255, 0.2),
        inset 0 0 70px rgba(138, 43, 226, 0.4), 0 0 70px rgba(128, 0, 255, 0.7);
      transform: translate(-50%, -50%) scale(1.02);
    }
  }
`;

const NeonLight = styled('div')`
  position: absolute;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  overflow: hidden;
  animation: rotate 5s linear infinite;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: conic-gradient(
      from 0deg,
      #2b3bfb 20%,
      #cb18f5 30%,
      #ffffff 45%,
      #8cd8ff 65%,
      #dc5e62 80%,
      #f9ebff 100%
    );
    animation: glow 3s ease-in-out infinite alternate;
    opacity: 0.9;
    filter: blur(20px);
  }

  ::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    background: #000;
    border-radius: 50%;
    z-index: 1;
    filter: blur(5px);
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes glow {
    0% {
      filter: blur(20px) brightness(1.2);
    }
    100% {
      filter: blur(30px) brightness(1.5);
    }
  }
`;

const GlowEffect = styled('div')`
  position: absolute;
  width: 270px;
  height: 270px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(161, 88, 228, 0.5) 0%,
    rgba(240, 73, 240, 0.4) 30%,
    transparent 70%
  );
  animation: glow-pulse 3s ease-in-out infinite;
  z-index: 0;

  @keyframes glow-pulse {
    0%,
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;

const LogoWrapper = styled('div')`
  filter: drop-shadow(0 0 10px rgb(255, 255, 255, 0.8));
`;
