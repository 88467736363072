import React, { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { dateRangeToTimestamp } from '../helpers';
import { OverviewOvertimePeriod, Scope } from '../types';
import {
  renderLineGraphUtils,
  replaceChatsWithConversations,
} from 'src/components/solve-insights-charts/line-graph/lineGraphUtils';
import { NA } from 'src/constants/solve';
import { useGetOverviewOvertimeChartQuery } from 'src/services/insights';
import {
  LineGraphLabelType,
  OverviewOvertimeGraphResponse,
} from 'src/services/insights/types';

interface OverviewLineChartProps {
  dateRange: DateRange;
  periodicalFilter: OverviewOvertimePeriod;
  scope: Scope;
}

const defaultVisibleSeries = [
  'Deflections',
  'Deflected rate',
  'Avg. CSAT',
] as const satisfies LineGraphLabelType[];

const OverviewLineChart: React.FC<
  React.PropsWithChildren<OverviewLineChartProps>
> = ({ dateRange, periodicalFilter, scope }) => {
  const { palette } = useTheme();
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  const {
    data: lineData,
    isFetching,
    isLoading,
  } = useGetOverviewOvertimeChartQuery({
    end: backendReadyTimestamps.end_timestamp,
    period: periodicalFilter,
    start: backendReadyTimestamps.start_timestamp,
  });

  const [visibleSeries, setVisibleSeries] =
    useState<LineGraphLabelType[]>(defaultVisibleSeries);

  const handleToggleVisibility = useCallback((label: LineGraphLabelType) => {
    setVisibleSeries(prev =>
      prev.includes(label)
        ? prev.filter(existingLabel => existingLabel !== label)
        : [...prev, label],
    );
  }, []);

  // replace labels
  const data: OverviewOvertimeGraphResponse =
    replaceChatsWithConversations(lineData);

  const transformedData = useMemo(
    () =>
      data && data.line && data.line.x_axis.length > 0
        ? {
            ...data,
            line: {
              ...data.line,
              values: data.line.values.map((value, index) => {
                /**
                 * All series come in pairs:
                 * `[Chats, Chats, Deflections, Deflections, ...]`
                 *
                 * The first one in a pair is the current period,
                 * the second one is the previous period (the comparison data).
                 */
                const isComparisonData = index % 2 !== 0;

                return {
                  ...value,
                  dashStyle: isComparisonData ? 'ShortDash' : undefined,
                  linkedTo: isComparisonData ? ':previous' : undefined,
                  showInLegend: !isComparisonData,
                  tooltip_values: isComparisonData
                    ? [
                        {
                          category: data.line.values[index - 1].category,
                          extended_labels:
                            data.line.values[index - 1].extended_labels,
                          index: index - 1,
                          tooltip_values:
                            data.line.values[index - 1].tooltip_values,
                        },
                        {
                          category: value.category,
                          extended_labels:
                            data.line.values[index].extended_labels,
                          index,
                          tooltip_values: value.tooltip_values,
                        },
                      ]
                    : [
                        {
                          category: value.category,
                          extended_labels:
                            data.line.values[index].extended_labels,
                          index,
                          tooltip_values: value.tooltip_values,
                        },
                        {
                          category: data.line.values[index + 1].category,
                          extended_labels:
                            data.line.values[index + 1].extended_labels,
                          index: index + 1,
                          tooltip_values:
                            data.line.values[index + 1].tooltip_values,
                        },
                      ],
                  visible: visibleSeries.includes(value.label),
                } as const;
              }),
            },
          }
        : undefined,
    [data, visibleSeries],
  );

  if (isLoading || isFetching) {
    return <Skeleton height='300px' width='100%' />;
  }

  if (!transformedData) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  return renderLineGraphUtils(
    palette,
    transformedData,
    scope,
    handleToggleVisibility,
  );
};

export default OverviewLineChart;
