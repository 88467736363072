import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitConfigForNonRTE } from '../hooks/useDebouncedSubmitConfig';
import { useGetIntents } from '../hooks/useGetIntents';
import ConnectIntegration from './ConnectIntegration';
import ConnectionList from './ConnectionList';
import KnowledgeBase from './KnowledgeBase';
import KnowledgeRetrieval from './KnowledgeRetrieval';
import { KnowledgeConfigurationPromptTextField } from 'src/components/knowledge-configuration-prompt-text-field';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetIrReportQuery } from 'src/services/ir-report/irReportApi';
import { updateOnboardingFlags } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import {
  selectWidgetConfiguration,
  setKnowledgeConfigurationPrompt,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

const KnowledgeTabContent = () => {
  const dispatch = useAppDispatch();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { data: irReportData, isLoading: loadingIrReportData } =
    useGetIrReportQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);
  const isSolveKnowledgeConfigRevamp = featureFlags.includes(
    'knowledge_article_workflow',
  );
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: false });

  const isOnboardingExperienceEnabled = featureFlags.includes(
    'onboarding_experience',
  );
  const isIRStreamEnabled = featureFlags.includes('ir_stream');
  const isInstructionalNoteUIEnabled = featureFlags.includes(
    'instructional_notes_for_kr',
  );
  const isMultibrandEnabled = featureFlags.includes('multibrand_enabled');

  useEffect(() => {
    if (isOnboardingExperienceEnabled) {
      dispatch(updateOnboardingFlags({ is_knowledge_visited: true }));
    }
  }, [dispatch, isOnboardingExperienceEnabled]);

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      {isSolveKnowledgeConfigRevamp && !isSolveLiteEnabled ? (
        <>
          <ConnectIntegration />
          <BorderLine />
          <KnowledgeRetrieval intents={intents} />
          <BorderLine />
          {isIRStreamEnabled &&
            isInstructionalNoteUIEnabled &&
            !isMultibrandEnabled && (
              <>
                <KnowledgeConfigurationPrompt />
                <BorderLine />
              </>
            )}
          {!loadingIrReportData && irReportData && <IRreport />}
          <ConnectionList type='data' />
        </>
      ) : (
        <KnowledgeBase />
      )}
    </Box>
  );
};

export const BorderLine = styled('div')`
  margin-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const IRreport = () => {
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography variant='font16Medium'>
        Information Retrieval Report
      </Typography>
      <Typography color='#727583' variant='font12'>
        Click below to view the completed Knowledge Retrieval Report.
      </Typography>
      <Link to='ir-report'>
        <Typography variant='font14Bold'>
          See helpfulness and coverage report
        </Typography>
      </Link>
      <BorderLine />
    </Box>
  );
};

const KnowledgeConfigurationPrompt = () => {
  const dispatch = useAppDispatch();
  const {
    knowledge_configuration_prompt: knowledgeConfigurationPrompt,
    ...rest
  } = useSelector(selectWidgetConfiguration);
  const debouncedSubmitConfig = useDebouncedSubmitConfigForNonRTE();
  const { data } = useGetFeatureFlagsQuery();

  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box alignItems='center' display='inline-flex'>
        <Typography variant='font16Medium'>
          Instructional note for Knowledge Retrieval (optional)
        </Typography>
      </Box>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Summarize key points for guiding the bot in creating more personalized
        conversations
      </Typography>
      <KnowledgeConfigurationPromptTextField
        onChange={(value: string) => {
          dispatch(
            setKnowledgeConfigurationPrompt({
              isPlaybook: false,
              shouldUseLongerNoteLength: Boolean(
                data?.feature_flags.includes('allow_longer_instructional_note'),
              ),
              value,
            }),
          );
          debouncedSubmitConfig(
            {
              ...rest,
              knowledge_configuration_prompt: value,
            },
            'knowledge_configuration_prompt',
            value,
          );
        }}
        value={knowledgeConfigurationPrompt}
      />
    </Box>
  );
};

export default KnowledgeTabContent;
