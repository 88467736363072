import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  CanvasResponse,
  DecommissionWorkflowResponse,
  DeflectionConfig,
  Intent,
  PreviewTokenReponse,
  SlackInstallationResponse,
  SolveApiKeyResponse,
} from 'src/types/workflowBuilderAPITypes';

const workflowBuilderCanvasApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    deleteIntent: builder.mutation<
      DecommissionWorkflowResponse,
      { intentDefinitionId: string }
    >({
      invalidatesTags: [
        'Actions',
        'ActionUsages',
        'Intents',
        'HandoffConfigurations',
      ],
      query: ({ intentDefinitionId }) => ({
        method: 'DELETE',
        url: `${SOLVE_V2_BASE_URL}/workflow-builder/intent/${intentDefinitionId}`,
      }),
    }),
    getIntent: builder.query<Intent, { intentDefinitionId: string }>({
      query: ({ intentDefinitionId }) =>
        `${SOLVE_V2_BASE_URL}/workflow-builder/intent/${intentDefinitionId}`,
    }),
    getPreviewToken: builder.query<PreviewTokenReponse, void>({
      query: () => ({
        url: SOLVE_V2_BASE_URL + '/workflow-builder/preview-token',
      }),
    }),
    getSlackInstallation: builder.query<SlackInstallationResponse, void>({
      query: () => ({ url: SOLVE_V2_BASE_URL + '/slack/installation' }),
    }),
    getSolveApiToken: builder.query<SolveApiKeyResponse, void>({
      providesTags: ['SolveApiToken'],
      query: () => ({
        url: SOLVE_V2_BASE_URL + '/workflow-builder/solve-api-token',
      }),
    }),
    getWorkflowsForIntent: builder.query<
      {
        api_workflow_id: string;
        has_live_interactive_email_component: boolean;
        interactive_email_workflow_id: string | null;
        playbook_workflow_id: string | null;
        slack_workflow_id: string | null;
        voice_workflow_id: string | null;
        widget_workflow_id: string;
      },
      { intentDefinitionId: string }
    >({
      query: ({ intentDefinitionId }) =>
        `${SOLVE_V2_BASE_URL}/workflow-builder/intent/${intentDefinitionId}/workflows`,
    }),
    refreshSolveApiToken: builder.mutation<SolveApiKeyResponse, void>({
      invalidatesTags: ['SolveApiToken'],
      query: () => ({
        method: 'POST',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/solve-api-token',
      }),
    }),
    requestSolveApiUpgrade: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/solve-api-upgrade-request',
      }),
    }),
    updateSolveApiConfig: builder.mutation<
      DeflectionConfig,
      { config: DeflectionConfig }
    >({
      invalidatesTags: ['SolveApiConfig'],
      query: ({ config }) => ({
        body: config,
        method: 'PUT',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/solve-api-config',
      }),
    }),
    updateWorkflowTags: builder.mutation<
      CanvasResponse,
      { intentWorkflowId: string; tags: string[] }
    >({
      invalidatesTags: ['WorkflowTags', 'WidgetBreakdownMetrics', 'Brands'],
      query: ({ intentWorkflowId, tags }) => ({
        body: { workflow_tags: tags },
        method: 'POST',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/workflow-tags`,
      }),
    }),
  }),
});

export const {
  useDeleteIntentMutation,
  useGetIntentQuery,
  useGetPreviewTokenQuery,
  useGetSlackInstallationQuery,
  useGetSolveApiTokenQuery,
  useGetWorkflowsForIntentQuery,
  useLazyGetWorkflowsForIntentQuery,
  useRefreshSolveApiTokenMutation,
  useRequestSolveApiUpgradeMutation,
  useUpdateSolveApiConfigMutation,
  useUpdateWorkflowTagsMutation,
} = workflowBuilderCanvasApi;
