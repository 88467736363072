import { Automation, Connector } from 'src/services/apiInterfaces';

export const automationTemplatePreviewOutput = {
  external_text_field:
    "<p>Hello Jane Doe&nbsp;</p>\n<p>I've found your &nbsp;shipment information. It was shipped on 12/15/2022 &nbsp;with Shipping Co . It is currently Shipped to customer .</p>\n<p>You can find more detailed status and expected arrival date by visiting &nbsp;Shipping Co &nbsp;website entering &nbsp;Tracking Number: XYZ12345 .</p>\n<p>Thank you Jane Doe &nbsp;and have a great day!</p",
  internal_text_field:
    '<p><strong>For CX Agent reference only!</strong></p>\n<p>Below is the current shipment information:</p>\n<ul>\n  <li>Shipping Co&nbsp;</li>\n  <li>12/15/2022&nbsp;</li>\n  <li>Shipped to customer&nbsp;</li>\n  <li>XYZ12345&nbsp;</li>\n</ul>',
};

export const automationTemplateCreatedConnectors: Connector[] = [
  {
    connector_definition: {
      avatar: 'https://d3v-max.zendesk.com/system/logos/360000334031/came.png',
      connector_fields_schema: null,
      connector_source: 'THIRD_PARTY_TRAY',
      connector_types: ['action', 'data'],
      description:
        'Stand alone, web-based corporate wiki developed by Atlassian',
      integration_type: 'confluence',
      is_helpdesk: false,
      name: 'Acme Co',
      oauth_credential_schema: null,
      overview:
        'Create, collaborate, and organize all your workin one place. Confluence is a team workspace where knowledge and collaboration meet. Dynamic pages give your team a place to create, capture, and collaborate on any project or idea. Spaces help your team structure, organize, and share work, so every team member has visibility into institutional knowledge and access to the information they need to do their best work.',
      setup_instructions:
        '<p>  In order to connect with Confluence you will need to have your Username,  Password, and Domain name.</p>',
      setup_type: 'THIRD_PARTY_TRAY_WIZARD',
      slug: 'tray_confluence',
    },
    connector_fields: {},
    connector_id: 'automation_template_connector_id',
    connector_types: ['action', 'data'],
    is_active: true,
    last_modified_at: '2022-06-29T14:31:44.228000',
    last_modified_by: 'Forethought Admin',
    last_processed_at: null,
    reindex_interval_sec: 1800,
  },
  {
    connector_definition: {
      avatar: 'https://d3v-max.zendesk.com/system/logos/360000334031/came.png',
      connector_fields_schema: null,
      connector_source: 'THIRD_PARTY_TRAY',
      connector_types: ['action', 'data'],
      description:
        'Stand alone, web-based corporate wiki developed by Atlassian',
      integration_type: 'confluence',
      is_helpdesk: false,
      name: 'ABC Inc.',
      oauth_credential_schema: null,
      overview:
        'Create, collaborate, and organize all your workin one place. Confluence is a team workspace where knowledge and collaboration meet. Dynamic pages give your team a place to create, capture, and collaborate on any project or idea. Spaces help your team structure, organize, and share work, so every team member has visibility into institutional knowledge and access to the information they need to do their best work.',
      setup_instructions:
        '<p>  In order to connect with Confluence you will need to have your Username,  Password, and Domain name.</p>',
      setup_type: 'THIRD_PARTY_TRAY_WIZARD',
      slug: 'tray_confluence',
    },
    connector_fields: {},
    connector_id: 'automation_template_connector_i2',
    connector_types: ['action', 'data'],
    is_active: true,
    last_modified_at: '2022-06-29T14:31:44.228000',
    last_modified_by: 'Forethought Admin',
    last_processed_at: null,
    reindex_interval_sec: 1800,
  },
];

export const automationTemplateConnector: Required<Connector> = {
  actions: [
    {
      action_id: 'get_shipment_status',
      description: 'Get shipment status.',
      has_dynamic_output: false,
      input_schema: {
        $schema:
          'https://api.tray.io/core/v1/connectors/operations/input-schema#',
        additionalProperties: false,
        properties: {
          order_id: {
            title: 'Order ID',
            type: 'string',
          },
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          customer_email: {
            title: 'Customer Email',
            type: 'string',
          },
        },
        required: ['customer_email', 'order_id'],
        type: 'object',
      },
      output_schema: {
        $schema:
          'https://api.tray.io/core/v1/connectors/operations/output-schema#',
        properties: {
          'Carrier Name': {
            type: 'string',
          },
          'Shipment Date': {
            type: 'string',
          },
          'Shipment Status': {
            type: 'string',
          },
          'Tracking Number': {
            type: 'string',
          },
        },
        type: 'object',
      },
      title: 'Get Shipment Status',
    },
    {
      action_id: 'cancel_order',
      description: 'Cancel order.',
      has_dynamic_output: false,
      input_schema: {
        $schema:
          'https://api.tray.io/core/v1/connectors/operations/input-schema#',
        additionalProperties: false,
        properties: {
          customer_email: {
            description: 'The Recurly site ID',
            title: 'Site',
            type: 'string',
          },
          order_id: {
            description:
              'The unique account ID. If you\'re using the "Account Code", prefix the id with "code-". E.g. "code-{$.step.connector-1.id}"',
            title: 'Account ID',
            type: 'string',
          },
        },
        required: ['customer_email', 'order_id'],
        type: 'object',
      },
      output_schema: {
        $schema:
          'https://api.tray.io/core/v1/connectors/operations/output-schema#',
        properties: {
          vat_number: {
            type: 'string',
          },
        },
        type: 'object',
      },
      title: 'Cancel order',
    },
  ],
  connector_definition: {
    avatar: 'https://d3v-max.zendesk.com/system/logos/360000334031/came.png',
    connector_fields_schema: null,
    connector_source: 'THIRD_PARTY_TRAY',
    connector_types: ['action', 'data'],
    description: 'Stand alone, web-based corporate wiki developed by Atlassian',
    integration_type: 'confluence',
    is_helpdesk: false,
    name: 'Confluence',
    oauth_credential_schema: null,
    overview:
      'Create, collaborate, and organize all your workin one place. Confluence is a team workspace where knowledge and collaboration meet. Dynamic pages give your team a place to create, capture, and collaborate on any project or idea. Spaces help your team structure, organize, and share work, so every team member has visibility into institutional knowledge and access to the information they need to do their best work.',
    setup_instructions:
      '<p>  In order to connect with Confluence you will need to have your Username,  Password, and Domain name.</p>',
    setup_type: 'THIRD_PARTY_TRAY_WIZARD',
    slug: 'tray_confluence',
  },
  connector_fields: {},
  connector_id: 'automation_template_connector_id',
  connector_settings: {},
  connector_type: '',
  connector_types: [],
  credentials: {},
  display_name: '',
  is_active: false,
  last_modified_at: null,
  last_modified_by: null,
  last_processed_at: null,
  reindex_interval_sec: 0,
};

export const automationTemplate: Automation = {
  action_list: [
    {
      action_id: 'get_shipment_status',
      connector_id: 'automation_template_connector_id',
    },
  ],
  actions_input_formatter: {
    get_shipment_status: [
      {
        is_input_field: true,
        order_id: '$order_id',
        type: 'integer',
      },
      {
        customer_email: '$customer_email',
        is_input_field: false,
        type: 'string',
      },
      {
        is_input_field: false,
        sort: 'id',
        type: 'string',
      },
    ],
  },
  actions_output_formatter: {
    get_shipment_status: [
      {
        'Carrier Name': '$carrier_name',
        use_as_input: false,
      },
      {
        'Shipment Date': '$shipment_date',
        use_as_input: false,
      },
      {
        'Shipment Status': '$shipment_status',
        use_as_input: false,
      },
      {
        'Tracking Number': '$tracking_number',
        use_as_input: false,
      },
    ],
  },
  automation_id: 'automation_template_automation_id',
  created_on: '2022-08-18 13:43:34',
  description: 'Example of a shipment status look-up Automation',
  emblem_urls: [
    'https://d3v-max.zendesk.com/system/logos/360000334031/came.png',
  ],
  external_text_field:
    "<p>Hello $customer_name&nbsp;</p>\n<p>I've found your &nbsp;shipment information. It was shipped on $shipment_date &nbsp;with $carrier_name . It is currently $shipment_status .</p>\n<p>You can find more detailed status and expected arrival date by visiting &nbsp;$carrier_name &nbsp;website entering &nbsp;Tracking Number: $tracking_number .</p>\n<p>Thank you $customer_name &nbsp;and have a great day!</p",
  input_description: 'Please input the following requested information',
  input_fields: [
    {
      context_variable: '$order_id',
      input_text: 'Order ID',
    },
    {
      context_variable: '$customer_email',
      input_text: 'Customer Email',
    },
    {
      context_variable: '$customer_name',
      input_text: 'Customer Name',
    },
  ],
  internal_text_field:
    '<p><strong>For CX Agent reference only!</strong></p>\n<p>Bellow is the current shipment information:</p>\n<ul>\n  <li>$carrier_name&nbsp;</li>\n  <li>$shipment_date&nbsp;</li>\n  <li>$shipment_status&nbsp;</li>\n  <li>$tracking_number&nbsp;</li>\n</ul>',
  org_id: 313,
  published: true,
  title: '(Example) Shipment Look-up',
};

export const assistAutomations: { automations: Array<Automation> } = {
  automations: [
    {
      action_list: [
        { '234523452345': 'connector_1' },
        { kqwekqjweq: 'connector_2' },
      ],
      actions_input_formatter: {
        '5f8eec5bb34df220e8184035': [
          { input_field_1: '$order_id', is_input_field: true, type: 'string' },
          {
            input_field_2: 'hard_code_value',
            is_input_field: false,
            type: 'string',
          },
        ],
      },
      actions_output_formatter: {
        '5f8eec5bb34df220e8184035': [
          { output_field_1: '$context_variable1', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable1',
            use_as_input: false,
          },
        ],
      },
      automation_id: 'automation_id1',
      created_on: '2020-10-20T06:55:39.769Z',
      description:
        'Review order status and process order refunds for all users',
      emblem_urls: [
        'https://d3tnn7lar6ozas.cloudfront.net/4fbb773e-e40a-48d7-9df1-148bc716243b.png',
      ],
      external_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order address is: $address</p>',
      input_description: 'here is the description',
      input_fields: [
        { context_variable: '$order_id', input_text: 'enter your order id' },
      ],
      internal_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order is: $orderID</p>',
      org_id: 1,
      published: true,
      title: 'Order Workflow',
    },
    {
      action_list: [
        { '234523452345': 'connector_1' },
        { kqwekqjweq: 'connector_2' },
      ],
      actions_input_formatter: {
        '5f8eec5bb34df220e8184035': [
          { input_field_1: '$order_id', is_input_field: true, type: 'string' },
          {
            input_field_2: 'hard_code_value',
            is_input_field: false,
            type: 'string',
          },
        ],
      },
      actions_output_formatter: {
        '5f8eec5bb34df220e8184035': [
          { output_field_1: '$context_variable1', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable1',
            use_as_input: false,
          },
        ],
      },
      automation_id: 'automation_id2',
      created_on: '2020-10-20T06:55:39.769Z',
      description:
        'Update user information and edit account details. Full console reference. ',
      emblem_urls: [
        'https://d3tnn7lar6ozas.cloudfront.net/24977431-7762-4907-9f4f-c10dc3540c29.png',
      ],
      external_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order address is: $address</p>',
      input_description: 'here is the description',
      input_fields: [
        { context_variable: '$order_id', input_text: 'enter your order id' },
      ],
      internal_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order is: $orderID</p>',
      org_id: 2,
      published: false,
      title: 'Info Update',
    },
    {
      action_list: [
        { '234523452345': 'connector_1' },
        { kqwekqjweq: 'connector_2' },
      ],
      actions_input_formatter: {
        '5f8eec5bb34df220e8184035': [
          { input_field_1: '$order_id', is_input_field: true, type: 'string' },
          {
            input_field_2: 'hard_code_value',
            is_input_field: false,
            type: 'string',
          },
        ],
      },
      actions_output_formatter: {
        '5f8eec5bb34df220e8184035': [
          { output_field_1: '$context_variable1', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable1',
            use_as_input: false,
          },
        ],
      },
      automation_id: 'automation_id3',
      created_on: '2020-10-20T06:55:39.769Z',
      description:
        'View a detailed breakdown of a users order history, includes shipping details',
      emblem_urls: [
        'https://d3tnn7lar6ozas.cloudfront.net/ea7f005b-3197-43d9-aae7-e7b99b57c259.png',
      ],
      external_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order address is: $address</p>',
      input_description: 'here is the description',
      input_fields: [
        { context_variable: '$order_id', input_text: 'enter your order id' },
      ],
      internal_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order is: $orderID</p>',
      org_id: 3,
      published: true,
      title: 'Find All User Orders',
    },
    {
      action_list: [
        { '234523452345': 'connector_1' },
        { kqwekqjweq: 'connector_2' },
      ],
      actions_input_formatter: {
        '5f8eec5bb34df220e8184035': [
          { input_field_1: '$order_id', is_input_field: true, type: 'string' },
          {
            input_field_2: 'hard_code_value',
            is_input_field: false,
            type: 'string',
          },
        ],
      },
      actions_output_formatter: {
        '5f8eec5bb34df220e8184035': [
          { output_field_1: '$context_variable1', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable1',
            use_as_input: false,
          },
        ],
      },
      automation_id: 'automation_id4',
      created_on: '2020-10-20T06:55:39.769Z',
      description:
        'Here is another interestig description. This one is longer and it takes all the space we allow for the text, which is max 3 lines of text',
      emblem_urls: [
        'https://d3tnn7lar6ozas.cloudfront.net/0949fa97-0ecc-400d-a444-6b8f0920f8fe.png',
      ],
      external_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order address is: $address</p>',
      input_description: 'here is the description',
      input_fields: [
        { context_variable: '$order_id', input_text: 'enter your order id' },
      ],
      internal_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order is: $orderID</p>',
      org_id: 4,
      published: true,
      title: 'Automation, Class Oreder',
    },
    {
      action_list: [
        { '234523452345': 'connector_1' },
        { kqwekqjweq: 'connector_2' },
      ],
      actions_input_formatter: {
        '5f8eec5bb34df220e8184035': [
          { input_field_1: '$order_id', is_input_field: true, type: 'string' },
          {
            input_field_2: 'hard_code_value',
            is_input_field: false,
            type: 'string',
          },
        ],
      },
      actions_output_formatter: {
        '5f8eec5bb34df220e8184032': [
          { output_field_1: '$context_variable2', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable2',
            use_as_input: false,
          },
        ],
        '5f8eec5bb34df220e8184035': [
          { output_field_1: '$context_variable1', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable1',
            use_as_input: false,
          },
        ],
      },
      automation_id: 'automation_id5',
      created_on: '2020-10-20T06:55:39.769Z',
      description:
        'Dropbox can do many things for you, give it a try and let me know. ',
      emblem_urls: [
        'https://d3tnn7lar6ozas.cloudfront.net/b8f6490f-247a-4c20-b368-010b0d7b6852.png',
      ],
      external_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order address is: $address</p>',
      input_description: 'here is the description',
      input_fields: [
        { context_variable: '$order_id', input_text: 'enter your order id' },
      ],
      internal_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order is: $orderID</p>',
      org_id: 5,
      published: true,
      title: 'External Accounting (Private)',
    },
    {
      action_list: [
        { '234523452345': 'connector_1' },
        { kqwekqjweq: 'connector_2' },
      ],
      actions_input_formatter: {
        '5f8eec5bb34df220e8184035': [
          { input_field_1: '$order_id', is_input_field: true, type: 'string' },
          {
            input_field_2: 'hard_code_value',
            is_input_field: false,
            type: 'string',
          },
        ],
      },
      actions_output_formatter: {
        '5f8eec5bb34df220e8184035': [
          { output_field_1: '$context_variable1', use_as_input: true },
          {
            output_field_2: '$text_field_context_variable1',
            use_as_input: false,
          },
        ],
      },
      automation_id: 'automation_id6',
      created_on: '2020-10-20T06:55:39.769Z',
      description:
        'Dropbox can do many things for you, give it a try and let me know. ',
      emblem_urls: [
        'https://d3tnn7lar6ozas.cloudfront.net/7af5fdd6-ae6c-4a1e-a283-4fbc71bf9c6b.png',
      ],
      external_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order address is: $address</p>',
      input_description: 'here is the description',
      input_fields: [
        { context_variable: '$order_id', input_text: 'enter your order id' },
      ],
      internal_text_field:
        '<p>Hi there $CUSTOMER_NAME, your order is: $orderID</p>',
      org_id: 6,
      published: true,
      title: 'External Accounting (Private)',
    },
  ],
};
