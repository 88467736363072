import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { quickFeedbackOneToTwoOptions } from '../constants';
import ftLogo from 'src/assets/images/ft-bg-logo.png';
import playbookIcon from 'src/assets/images/playbook-icon.svg';
import zendeskLogoSrc from 'src/assets/images/zendesk-article-logo.svg';
import { selectPlaybookConfiguration } from 'src/slices/solve-config/solveConfigSlice';

const QuickFeedbackPreview = () => {
  const { palette } = useTheme();
  const playbookConfiguration = useSelector(selectPlaybookConfiguration);

  const {
    hover_label_bad: hoverLabelBad,
    hover_label_good: hoverLabelGood,
    is_enabled: isEnabled,
    style,
  } = playbookConfiguration.quick_feedback_config || {};
  const chosenStyleOptions = quickFeedbackOneToTwoOptions.find(
    option => option.value === style,
  );

  return (
    <Box alignSelf='center' display='flex' flexDirection='column' width='393px'>
      <Box
        bgcolor={palette.colors.slate[100]}
        borderRadius='25px'
        position='relative'
        px={2}
        py='14px'
      >
        <Typography variant='font16'>
          Wait for 3 minutes and try again if the account is temporarily locked.
          If prompted, follow the security verification steps.
        </Typography>
        <Box
          borderRadius='100%'
          bottom={0}
          component='img'
          left={-24}
          overflow='hidden'
          position='absolute'
          src={ftLogo}
          width='32px'
        />
      </Box>

      {isEnabled && (
        <Box display='flex' gap={2} mt={2}>
          <Box
            aria-label={hoverLabelGood}
            sx={{ cursor: 'pointer' }}
            width='24px'
          >
            <Tooltip placement='bottom' tooltipContent={hoverLabelGood || ''}>
              <IconWrapper>
                <ReactSVG
                  src={chosenStyleOptions?.positiveIcons?.default || ''}
                  style={{ height: '24px', width: '24px' }}
                />
              </IconWrapper>
            </Tooltip>
          </Box>
          <Box
            aria-label={hoverLabelBad}
            sx={{ cursor: 'pointer' }}
            width='24px'
          >
            <Tooltip placement='bottom' tooltipContent={hoverLabelBad || ''}>
              <IconWrapper>
                <ReactSVG
                  src={chosenStyleOptions?.negativeIcons?.default || ''}
                  style={{ height: '24px', width: '24px' }}
                />
              </IconWrapper>
            </Tooltip>
          </Box>
        </Box>
      )}

      <Box alignSelf='flex-start' mt={2}>
        <Typography variant='font14Bold'>Sources</Typography>
      </Box>
      <SourceBox>
        <Box alignItems='center' display='flex' gap={1}>
          <ReactSVG
            src={playbookIcon}
            style={{ height: '16px', marginTop: '-4px', width: '16px' }}
          />
          <Typography variant='font14Bold'>Workflow</Typography>
        </Box>
        <Typography variant='font14'>Account Locked/Suspended</Typography>
      </SourceBox>

      <SourceBox>
        <Box alignItems='center' display='flex' gap={1}>
          <ReactSVG
            src={zendeskLogoSrc}
            style={{ height: '16px', marginTop: '-4px', width: '16px' }}
          />
          <Typography variant='font14Bold'>
            Troubleshooting Checklist
          </Typography>
        </Box>
        <Typography variant='font14'>Access & Permission Issues</Typography>
      </SourceBox>
    </Box>
  );
};

const SourceBox = styled(Box)`
  padding: 10px 16px;
  border: 1px solid ${props => props.theme.palette.colors.slate[100]};
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.palette.colors.slate[100]};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export default QuickFeedbackPreview;
