import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { LaunchButtonWithProactivePrompt } from '../launch-button/LaunchButtonWithProactivePrompt';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';

interface MinimizedWidgetProps {
  children?: ReactNode;
}

const MinimizedWidget = ({ children }: MinimizedWidgetProps) => {
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const channel = params.get('channel');
  const tab = params.get('tab');

  const {
    font,
    launch_style: launchStyle,
    placement,
  } = useSelector(selectWidgetConfiguration);

  const justifyContent = (() => {
    if (tab === 'theme') {
      if (launchStyle === 'floating_bar') {
        return 'center';
      }

      switch (placement) {
        case 'bottom_left':
          return 'flex-start';
        default:
          return 'flex-end';
      }
    }

    return 'center';
  })();
  const launchButtonAlignment = (() => {
    switch (placement) {
      case 'top':
        return 'flex-start';
      case 'middle':
        return 'center';
      default:
        return 'flex-end';
    }
  })();

  // simulate widget opening
  useEffect(() => {
    if (tab === 'theme') {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }

    return () => {
      setLoading(false);
    };
  }, [tab, launchStyle, placement]);

  return (
    <Container background={channel ? 'white' : undefined} placement={placement}>
      <ContentContainer font={font} placement={placement}>
        {/* Content */}
        <Box
          display='flex'
          height='100%'
          justifyContent={justifyContent}
          sx={{ transition: 'all 0.5s ease-in' }}
        >
          {loading ? (
            <Box
              sx={{
                alignSelf: launchButtonAlignment,
                display: 'flex',
                maxWidth: launchStyle === 'floating_bar' ? '640px' : undefined,
                transition: 'all 0.5s ease-in-out',
                width: '100%',
              }}
            >
              <LaunchButtonWithProactivePrompt />
            </Box>
          ) : (
            children
          )}
        </Box>
        {/* TODO - create bubble that is customizable */}
      </ContentContainer>
    </Container>
  );
};

const Container = styled('div')<{
  background?: string;
  placement: WidgetConfiguration['placement'];
}>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  height: 100%;
  width: 100%;
  overflow: hidden;
  ${props => props.background && `background: ${props.background};`}

  ${props =>
    props.placement === 'bottom_left'
      ? `
        border-right: none;
        border-radius: 10px 0px 0px 10px;
  `
      : `
        border-left: none;
        border-radius: 0px 10px 10px 0px;
      `}
  ${props =>
    props.placement === 'bottom_left'
      ? 'margin-right: -32px;'
      : 'margin-left: -32px;'};
`;

const ContentContainer = styled('div')<{
  font: string;
  placement: WidgetConfiguration['placement'];
}>`
  height: 100%;
  overflow: auto;
  background: linear-gradient(
    331.81deg,
    rgba(255, 255, 255, 0.79) 34.78%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: ${props =>
    props.placement === 'bottom_left'
      ? '0px 0px 0px 10px'
      : '0px 0px 10px 0px'};
  padding: 24px;
  font-family: ${props => props.font} !important;

  *,
  ::before,
  ::after {
    font-family: inherit !important;
  }
`;

export default MinimizedWidget;
