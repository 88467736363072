import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EmailPreviewState,
  IntentFilter,
  isSolveApiResponse,
  PreviewLogProps,
  PreviewLogType,
  SolveApiRequest,
  WorkflowPreviewSliceState,
} from './types';
import {
  getPreviewLogs,
  initializeConversation,
  updateConversation,
} from 'src/actions/api-preview/apiPreviewAction';
import type { RootState } from 'src/store/rootReducer';

const initialState: WorkflowPreviewSliceState = {
  apiPreview: {
    conversation_id: null,
    history: [],
    isLoading: false,
    isStream: false,
  },
  channel: 'widget',
  colorMode: 'light',
  conversationId: null,
  emailPreview: {
    emailConversationId: null,
    responseEmail: '',
    stage: 'email-form',
    token: null,
  },
  intentFilters: [],
  language: 'en',
  previewLogs: [],
  voicePreview: {
    previewPhoneNumber: null,
  },
  workflowCvs: [],
  workflowTag: null,
};

const workflowPreviewSlice = createSlice({
  extraReducers(builder) {
    // initialize conversation
    builder.addCase(initializeConversation.pending, state => {
      state.apiPreview.isLoading = true;
    });
    builder.addCase(initializeConversation.fulfilled, (state, { payload }) => {
      if (payload) {
        state.conversationId = payload.conversation_id;
        state.apiPreview.conversation_id = payload.conversation_id;
        state.apiPreview.history.push(payload);
      }

      state.apiPreview.isLoading = false;
    });
    builder.addCase(initializeConversation.rejected, (state, { payload }) => {
      if (payload) {
        state.apiPreview.history.push(payload);
      }

      state.apiPreview.isLoading = false;
    });

    // update conversation
    builder.addCase(updateConversation.pending, state => {
      state.apiPreview.isLoading = true;
    });
    builder.addCase(updateConversation.fulfilled, (state, { payload }) => {
      if (payload) {
        state.apiPreview.history.push(payload);
      }

      state.apiPreview.isLoading = false;
    });
    builder.addCase(updateConversation.rejected, (state, { payload }) => {
      if (payload) {
        state.apiPreview.history.push(payload);
      }

      state.apiPreview.isLoading = false;
    });
    builder.addCase(getPreviewLogs.fulfilled, (state, { payload }) => {
      state.previewLogs = state.previewLogs.concat(payload);
    });
  },
  initialState,
  name: 'workflowPreview',
  reducers: {
    addPreviewLogs(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<PreviewLogProps[]>,
    ) {
      state.previewLogs = state.previewLogs.concat(action.payload);
    },
    clearPreviewLogs(state: WorkflowPreviewSliceState) {
      state.previewLogs = [];
    },
    initializeApiPreview(state: WorkflowPreviewSliceState) {
      state.apiPreview = {
        conversation_id: null,
        history: [],
        isLoading: false,
        isStream: state.apiPreview.isStream,
      };
      state.conversationId = null;
      state.previewLogs = [];
    },
    initializeEmailPreview(state: WorkflowPreviewSliceState) {
      state.emailPreview = {
        emailConversationId: null,
        responseEmail: '',
        stage: 'email-form',
        token: null,
      };
    },
    initializePreviewLogs(state: WorkflowPreviewSliceState) {
      state.previewLogs = [
        {
          language_code: state.language,
          log_type: PreviewLogType.LANGUAGE_CHANGE,
        },
      ];
      state.conversationId = null;
    },
    pushApiPreviewRequest(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<SolveApiRequest>,
    ) {
      // omit intent filters for preview and solve_api_token because they're internal only
      const request = action.payload;
      const requestToSave = {
        context_variables: request.context_variables,
        query: request.query,
        stream: request.stream,
      };
      state.apiPreview.history.push(requestToSave);
    },
    receiveApiPreviewChunk(state, action: PayloadAction<unknown>) {
      const { requestId, value } = action.payload as {
        requestId: number;
        value: unknown;
      };
      if (!isSolveApiResponse(value)) {
        return;
      }

      state.conversationId = value.conversation_id;
      state.apiPreview.conversation_id = value.conversation_id;
      state.apiPreview.history.push({ ...value, requestId });
    },
    restartApiPreviw(state: WorkflowPreviewSliceState) {
      state.apiPreview = {
        ...state.apiPreview,
        history: [],
      };
      state.previewLogs = [];
    },
    setEmailPreviewResponseEmail(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<string>,
    ) {
      state.emailPreview.responseEmail = action.payload;
    },
    setEmailPreviewStage(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<WorkflowPreviewSliceState['emailPreview']['stage']>,
    ) {
      state.emailPreview.stage = action.payload;
    },
    setEmailPreviewState(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<EmailPreviewState>,
    ) {
      state.emailPreview = action.payload;
    },
    setEmailPreviewToken(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<string>,
    ) {
      state.emailPreview.token = action.payload;
    },
    setPreviewColorMode(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<'dark' | 'light'>,
    ) {
      state.colorMode = action.payload;
    },
    setPreviewConversationId(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<string>,
    ) {
      state.conversationId = action.payload;
      state.apiPreview.conversation_id = action.payload;
    },
    setPreviewIntentFilters(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<IntentFilter[]>,
    ) {
      state.intentFilters = action.payload;
    },
    setPreviewIsStream(state, action: PayloadAction<boolean>) {
      state.apiPreview.isStream = action.payload;
    },
    setPreviewLanguage(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<string>,
    ) {
      state.language = action.payload;
      state.previewLogs.push({
        language_code: action.payload,
        log_type: PreviewLogType.LANGUAGE_CHANGE,
      });
    },
    setPreviewWorkflowCvs(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<{ name: string; value: string }[]>,
    ) {
      state.workflowCvs = action.payload;
    },
    setPreviewWorkflowTag(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<string>,
    ) {
      state.workflowTag = action.payload;
    },
    setVoicePreviewPhoneNumber(
      state: WorkflowPreviewSliceState,
      action: PayloadAction<string>,
    ) {
      state.voicePreview.previewPhoneNumber = action.payload;
    },
  },
});

export const selectPreviewLanguage = (
  state: RootState,
): WorkflowPreviewSliceState['language'] => {
  return state.workflowPreview.language;
};

export const selectPreviewWorkflowTag = (
  state: RootState,
): WorkflowPreviewSliceState['workflowTag'] => {
  return state.workflowPreview.workflowTag;
};

export const selectPreviewIntentFilters = (
  state: RootState,
): WorkflowPreviewSliceState['intentFilters'] => {
  return state.workflowPreview.intentFilters;
};

export const selectPreviewConversationId = (
  state: RootState,
): WorkflowPreviewSliceState['conversationId'] => {
  return state.workflowPreview.conversationId;
};

export const selectPreviewWorkflowCvs = (
  state: RootState,
): WorkflowPreviewSliceState['workflowCvs'] => {
  return state.workflowPreview.workflowCvs;
};

export const selectPreviewLogs = (
  state: RootState,
): WorkflowPreviewSliceState['previewLogs'] => {
  return state.workflowPreview.previewLogs;
};

export const selectPreviewColorMode = (
  state: RootState,
): WorkflowPreviewSliceState['colorMode'] => {
  return state.workflowPreview.colorMode;
};

export const selectApiPreviewState = (
  state: RootState,
): WorkflowPreviewSliceState['apiPreview'] => {
  return state.workflowPreview.apiPreview;
};

export const selectEmailPreviewStage = (
  state: RootState,
): WorkflowPreviewSliceState['emailPreview']['stage'] => {
  return state.workflowPreview.emailPreview.stage;
};

export const selectEmailPreviewResponseEmail = (
  state: RootState,
): WorkflowPreviewSliceState['emailPreview']['responseEmail'] => {
  return state.workflowPreview.emailPreview.responseEmail;
};

export const selectEmailPreviewToken = (
  state: RootState,
): WorkflowPreviewSliceState['emailPreview']['token'] => {
  return state.workflowPreview.emailPreview.token;
};

export const selectIntentNameForIntentId =
  (intentId: string | null) =>
  (state: RootState): string => {
    if (!intentId) {
      return '';
    }
    const foundFilter = state.workflowPreview.intentFilters.find(
      filter => filter.intentId === intentId,
    );

    return foundFilter?.intentName || '';
  };

export const selectPreviewPhoneNumber = (
  state: RootState,
): WorkflowPreviewSliceState['voicePreview']['previewPhoneNumber'] => {
  return state.workflowPreview.voicePreview.previewPhoneNumber;
};

export default workflowPreviewSlice.reducer;

export const {
  addPreviewLogs,
  clearPreviewLogs,
  initializeApiPreview,
  initializeEmailPreview,
  initializePreviewLogs,
  pushApiPreviewRequest,
  receiveApiPreviewChunk,
  restartApiPreviw,
  setEmailPreviewResponseEmail,
  setEmailPreviewStage,
  setEmailPreviewState,
  setEmailPreviewToken,
  setPreviewColorMode,
  setPreviewConversationId,
  setPreviewIntentFilters,
  setPreviewIsStream,
  setPreviewLanguage,
  setPreviewWorkflowCvs,
  setPreviewWorkflowTag,
  setVoicePreviewPhoneNumber,
} = workflowPreviewSlice.actions;
