import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateConnectorDisplayName } from 'src/slices/connectors/thunks';

interface UseSaveDisplayNameProps {
  connectorId: string;
  initialDisplayName: string;
}

export function useSaveDisplayName({
  connectorId,
  initialDisplayName,
}: UseSaveDisplayNameProps) {
  const dispatch = useDispatch();
  const [currentConnectorId, setCurrentConnectorId] = useState(connectorId);
  const [originalDisplayName, setOriginalDisplayName] =
    useState(initialDisplayName);
  const [currentDisplayName, setCurrentDisplayName] =
    useState(initialDisplayName);

  const handleSaveDisplayName = useCallback(() => {
    if (!currentConnectorId) {
      return;
    }
    if (originalDisplayName !== currentDisplayName) {
      dispatch(
        updateConnectorDisplayName({
          connectorId: currentConnectorId,
          displayName: currentDisplayName,
        }),
      );
      setOriginalDisplayName(currentDisplayName);
    }
  }, [originalDisplayName, currentDisplayName, currentConnectorId, dispatch]);

  const resetDisplayName = useCallback(() => {
    setCurrentDisplayName(originalDisplayName);
  }, [originalDisplayName]);

  const updateConnectorId = useCallback((newId: string) => {
    setCurrentConnectorId(newId);
  }, []);

  return {
    currentDisplayName,
    handleSaveDisplayName,
    resetDisplayName,
    setCurrentDisplayName,
    updateConnectorId,
  };
}
