import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  IconBolt,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconPennant,
} from '@tabler/icons-react';
import { IconDownload } from '@tabler/icons-react';

import { IconButton } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../dashboard-pages/discover-dashboard-page/types';
import StyledMenu from './components/StyledMenu';
import StyledMenuItem from './components/StyledMenuItem';
import {
  ArticleMenuButtonVariants,
  TicketMenuButtonVariants,
  TopicMenuButtonVariants,
} from './types';
import { usePatchTopicMutation } from 'src/hooks/discover/usePatchTopicMutation';
import useExportCSV from 'src/hooks/useExportCSV';
import {
  DiscoverTopic,
  PatchTopicRequest,
} from 'src/reducers/discoverReducer/types';
import { DiscoverBodyFilter } from 'src/services/discover/types';
import { createEndDate, createStartDate } from 'src/services/triage/helpers';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface BaseProps {
  buttonSize?: 'medium' | 'large';
  onClickCallback?: React.MouseEventHandler<HTMLButtonElement>;
  onCloseCallback?: () => void;
}

interface PatchTopicProps extends BaseProps {
  backendFriendlyFilterQuery?: never;
  dateRange?: never;
  dismissArticleCallback?: never;
  keywordSearch?: never;
  setReportedTicketId?: never;
  topic?: DiscoverTopic | null;
  variant: TopicMenuButtonVariants;
}

interface ReportTicketProps extends BaseProps {
  backendFriendlyFilterQuery?: never;
  dateRange?: never;
  dismissArticleCallback?: never;
  keywordSearch?: never;
  setReportedTicketId: () => void;
  topic?: never;
  variant: TicketMenuButtonVariants;
}

interface ArticleFeedbackProps extends BaseProps {
  backendFriendlyFilterQuery?: never;
  dateRange?: never;
  dismissArticleCallback: () => void;
  keywordSearch?: never;
  setReportedTicketId?: never;
  topic?: never;
  variant: ArticleMenuButtonVariants;
}

interface ExportCSVProps extends BaseProps {
  backendFriendlyFilterQuery: DiscoverBodyFilter | undefined;
  dateRange: DateRange;
  dismissArticleCallback?: never;
  keywordSearch?: string;
  setReportedTicketId?: never;
  topic?: DiscoverTopic | null;
  variant: TopicMenuButtonVariants;
}

type DiscoverMenuButtonProps =
  | PatchTopicProps
  | ReportTicketProps
  | ArticleFeedbackProps
  | ExportCSVProps;

const DiscoverMenuButton = ({
  backendFriendlyFilterQuery,
  buttonSize = 'medium',
  dateRange,
  dismissArticleCallback,
  isParentTopic,
  keywordSearch,
  onClickCallback,
  onCloseCallback,
  setReportedTicketId,
  topic,
  variant,
}: DiscoverMenuButtonProps & { isParentTopic?: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { loading, patchTopicMutation } = usePatchTopicMutation();
  const { palette } = useTheme();

  const getIconColor = () => {
    if (variant === 'all_topics') {
      return palette.primary.main;
    }

    if (variant === 'ticket_card' || variant === 'article_card') {
      return palette.colors.grey[800];
    }
  };

  const handleClose = () => {
    setAnchorEl(null);

    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  let ariaLabel = 'Update topic';
  switch (variant) {
    case 'ticket_card':
    case 'ticket_modal':
      ariaLabel = 'Report ticket';
      break;
    case 'article_card':
      ariaLabel = 'Dismiss article';
      break;
    default:
      break;
  }

  return (
    <>
      <Box
        sx={
          variant === 'topic_detail'
            ? {
                '&:hover': {
                  '* > svg': {
                    color: palette.colors.purple[500],
                  },
                },
                '* > svg': {
                  color: palette.colors.grey[500],
                },
              }
            : undefined
        }
      >
        <IconButton
          aria-label={ariaLabel}
          disabled={loading}
          hoverBackgroundColor={
            variant === 'all_topics' ? palette.colors.purple[200] : undefined
          }
          id='discover-menu-button'
          onClick={e => {
            e.stopPropagation();

            setAnchorEl(e.currentTarget);
            if (onClickCallback) {
              onClickCallback(e);
            }
          }}
          size={buttonSize}
          variant='ghost'
        >
          <IconDotsVertical color={getIconColor()} size={17.5} />
        </IconButton>
      </Box>
      {topic && !isParentTopic && (
        <PatchTopicMenu
          anchorEl={anchorEl}
          backendFriendlyFilterQuery={backendFriendlyFilterQuery}
          dateRange={dateRange}
          keywordSearch={keywordSearch}
          onClose={handleClose}
          patchTopicMutation={patchTopicMutation}
          topic={topic}
          variant={variant}
        />
      )}
      {setReportedTicketId && !isParentTopic && (
        <ReportTicketMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          setReportedTicketId={setReportedTicketId}
          variant={variant}
        />
      )}
      {dismissArticleCallback && !isParentTopic && (
        <ArticleMenu
          anchorEl={anchorEl}
          dismissArticleCallback={dismissArticleCallback}
          onClose={handleClose}
        />
      )}
      {isParentTopic && (
        <ExportMenu
          anchorEl={anchorEl}
          backendFriendlyFilterQuery={backendFriendlyFilterQuery}
          dateRange={dateRange}
          isParentTopic
          keywordSearch={keywordSearch}
          onClose={handleClose}
          topic={topic}
        />
      )}
    </>
  );
};

interface BaseMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

interface PatchTopicMenuProps extends BaseMenuProps {
  backendFriendlyFilterQuery: DiscoverBodyFilter;
  dateRange?: DateRange;
  keywordSearch?: string;
  patchTopicMutation: (topicId: string, body: PatchTopicRequest) => void;
  topic: DiscoverTopic;
  variant: TopicMenuButtonVariants;
}

const PatchTopicMenu = ({
  anchorEl,
  backendFriendlyFilterQuery,
  dateRange,
  keywordSearch,
  onClose,
  patchTopicMutation,
  topic,
  variant,
}: PatchTopicMenuProps) => {
  const dispatch = useAppDispatch();

  const menuItems = useMemo(() => {
    const { is_hidden: isHidden, topic_id: topicId } = topic;

    const generateMenuItems = () => {
      const menuItems = [];
      if (isHidden) {
        menuItems.push({
          icon: <IconEye size={17.5} />,
          label: 'Unhide topic',
          onClick: () => patchTopicMutation(topicId, { is_hidden: false }),
        });
      } else {
        menuItems.push({
          icon: <IconEyeOff size={17.5} />,
          label: 'Hide topic',
          onClick: () => patchTopicMutation(topicId, { is_hidden: true }),
        });
      }

      if (variant === 'topic_detail' && topic?.is_automated === false) {
        menuItems.push({
          icon: <IconBolt size={17.5} />,
          label: 'Automate',
          onClick: () =>
            dispatch(
              setGlobalDiscoverOptions({
                topicToAutomate: topic,
              }),
            ),
        });
      }
      return menuItems;
    };

    return generateMenuItems();
  }, [dispatch, patchTopicMutation, topic, variant]);

  return (
    <StyledMenu anchorEl={anchorEl} onClose={onClose} variant={variant}>
      {menuItems.map(menuItem => (
        <StyledMenuItem
          key={menuItem.label}
          menuItem={menuItem}
          onClose={onClose}
        />
      ))}
      {(variant === 'topic_detail' || variant === 'all_topics') && (
        <ExportMenu
          anchorEl={null}
          backendFriendlyFilterQuery={backendFriendlyFilterQuery}
          dateRange={dateRange}
          keywordSearch={keywordSearch || ''}
          onClose={onClose}
          onTopicDetails={variant === 'topic_detail'}
          topic={topic}
        />
      )}
    </StyledMenu>
  );
};

interface ReportTicketMenuProps extends BaseMenuProps {
  setReportedTicketId: () => void;
  variant: TicketMenuButtonVariants;
}

const ReportTicketMenu = ({
  anchorEl,
  onClose,
  setReportedTicketId,
  variant,
}: ReportTicketMenuProps) => {
  return (
    <StyledMenu anchorEl={anchorEl} onClose={onClose} variant={variant}>
      <StyledMenuItem
        menuItem={{
          icon: <IconPennant size={17.5} />,
          label: 'Report ticket',
          onClick: setReportedTicketId,
        }}
        onClose={onClose}
      />
    </StyledMenu>
  );
};

interface ArticleMenuProps extends BaseMenuProps {
  dismissArticleCallback: () => void;
}

const ArticleMenu = ({
  anchorEl,
  dismissArticleCallback,
  onClose,
}: ArticleMenuProps) => {
  return (
    <StyledMenu anchorEl={anchorEl} onClose={onClose} variant='ticket_card'>
      <StyledMenuItem
        menuItem={{
          icon: <IconPennant size={17.5} />,
          label: 'Dismiss article',
          onClick: dismissArticleCallback,
        }}
        onClose={onClose}
      />
    </StyledMenu>
  );
};

type ExportMenuProps = BaseMenuProps & {
  backendFriendlyFilterQuery: DiscoverBodyFilter;
  dateRange?: DateRange;
  isParentTopic?: boolean;
  keywordSearch?: string;
  onTopicDetails?: boolean;
  topic?: DiscoverTopic | null;
};

const ExportMenu = ({
  anchorEl,
  backendFriendlyFilterQuery,
  dateRange,
  isParentTopic,
  keywordSearch,
  onClose,
  onTopicDetails,
  topic,
}: ExportMenuProps) => {
  const requestData = {
    end_date: createEndDate((dateRange?.to as Date)?.valueOf()),
    filters: backendFriendlyFilterQuery,
    keyword_search: keywordSearch,
    start_date: createStartDate((dateRange?.from as Date)?.valueOf()),
    topic_id: topic?.topic_id,
  };
  const { isLoading, onDownload } = useExportCSV({
    exportType: 'discover_topic_tickets_table',
    requestData,
  });

  if (isParentTopic) {
    return (
      <StyledMenu anchorEl={anchorEl} onClose={onClose} variant='all_topics'>
        <StyledMenuItem
          menuItem={{
            icon: <IconDownload size={17.5} />,
            label: 'Export to CSV',
            onClick: () => {
              if (isLoading) {
                return;
              }
              onDownload();
            },
          }}
          onClose={onClose}
        />
      </StyledMenu>
    );
  }

  return (
    <StyledMenuItem
      menuItem={{
        icon: <IconDownload size={17.5} />,
        label: 'Export to CSV',
        onClick: () => {
          if (isLoading) {
            return;
          }
          onDownload();
        },
      }}
      onClose={onTopicDetails ? () => undefined : onClose}
    />
  );
};

export default DiscoverMenuButton;
