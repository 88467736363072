import { useSelector } from 'react-redux';

import { VoiceLHSMockContent } from '../VoiceMockContent';
import { selectVoiceConfiguration } from 'src/slices/solve-config/solveConfigSlice';

export const VoiceTabMockContent = () => {
  const voiceConfiguration = useSelector(selectVoiceConfiguration);

  const { greeting_message: greetingMessage } = voiceConfiguration;

  return (
    <VoiceLHSMockContent
      centerText={greetingMessage}
      headerText='Configure the AI voice and greeting message using the menu on the right'
    />
  );
};
