import { useCallback, useMemo, useState } from 'react';
import { alpha, Box, Drawer, Typography, useTheme } from '@mui/material';
import { IconTag } from '@tabler/icons-react';

import {
  Button,
  DateRangeFilterButton,
  elevations,
  IconButton,
  MultiSelectFilter,
  SearchBar,
  SearchWithDropdown,
} from '@forethought-technologies/forethought-elements';
import {
  initialInsightParams,
  INTENT_FILTER_PARAM_NAME,
} from '../dashboard-pages/solve-insights/constants';
import { isUiQueryError } from '../dashboard-pages/solve-insights/helpers';
import {
  InsightsTab,
  ProductMode,
  SearchQueryMode,
} from '../dashboard-pages/solve-insights/types';
import WithHelperText from '../with-helper-text/WithHelperText';
import ContextVariableFilterBuilder from './ContextVariableFilterBuilder';
import FilterLabelContainer from './FilterLabelContainer';
import { listCVObjectDeserialize, listCVObjectSerialize } from './helper';
import ListOptionParent from './ListOptionParent';
import { ContextVariableFilterItemValue } from './types';
import { Option } from 'node_modules/@forethought-technologies/forethought-elements/dist/Components/MultiSelectFilter/types';
import xIcon from 'src/assets/images/close-icon.svg';
import chatIcon from 'src/assets/images/icon-message.svg';
import { useGetWorkflowTags, useStateParams } from 'src/hooks/hooks';
import { CHANNEL_COPY } from 'src/pages/intent-conversation-analytics/constants';
import useGetTopicOptions from 'src/pages/intent-conversation-analytics/hooks/useGetTopicOptions';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface GlobalFilterDrawerProps {
  filterType: InsightsTab;
  handleReset: () => void;
  isOpen: boolean;
  onClose: () => void;
  otherFiltersOptions: Option[];
  product?: ProductMode;
  searchOptions?: { text: string; value: string }[];
  setUiQuery?: (value: string) => void;
  tab?: InsightsTab;
  uiQuery?: string;
}

const GlobalFilterDrawer = ({
  filterType,
  handleReset,
  isOpen,
  onClose,
  otherFiltersOptions,
  product = 'widget',
  searchOptions,
  setUiQuery,
  tab,
  uiQuery = '',
}: GlobalFilterDrawerProps) => {
  const { palette } = useTheme();
  const workflowTags = useGetWorkflowTags();

  const [expandedValues, setExpandedValues] = useState<string[]>([]);
  const isEmail = product === 'email';
  const isVoice = product === 'voice';

  // state params
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [searchQueryMode, setSearchQueryMode] = useStateParams<SearchQueryMode>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => SearchQueryMode,
      initialState: 'conversation_id',
      paramsName: 'chats_search_mode',
      serialize: genericSerializeAndDeserialize,
    },
  );
  const [channels, setChannels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_${tab}`,
    serialize: listSerialize,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_${tab}`,
    serialize: listCVObjectSerialize,
  });
  // Ui state management
  const initialTags = multiSelected.filter(item =>
    item.includes('workflow_tags'),
  );
  const [uiDateRange, setUiDateRange] = useState(dateRange);
  const [uiMultiSelected, setUiMultiSelected] = useState(multiSelected);
  const [uiChannels, setUiChannels] = useState(channels);
  const [uiTags, setUiTags] = useState(initialTags);
  const [uiSearchQuery, setUiSearchQuery] = useState(uiQuery);
  const [uiSearchQueryMode, setUiSearchQueryMode] = useState(searchQueryMode);
  const [uiCvFilters, setUICvFilters] =
    useState<ContextVariableFilterItemValue[]>(cvFilters);

  const topicOptions = useGetTopicOptions({
    dateRange: uiDateRange,
    skip: isEmail || isVoice,
  });
  otherFiltersOptions = [...otherFiltersOptions, ...topicOptions];

  const cleanMultiSelected = useMemo(() => {
    return uiMultiSelected.filter(item => !item.includes('workflow_tags'));
  }, [uiMultiSelected]);

  const uiQueryError = isUiQueryError({
    searchQueryMode: uiSearchQueryMode,
    uiQuery: uiSearchQuery,
  });

  const handleDisableApply = useCallback(() => {
    return uiCvFilters.some(
      filter =>
        filter.expression.operator !== 'empty' &&
        filter.expression.values?.length === 0,
    );
  }, [uiCvFilters]);

  const isDisabled = handleDisableApply();

  const commonFilters = useMemo(() => {
    return (
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='space-between'
      >
        <Box display='flex' flexDirection='column' gap='24px' padding='0px 1px'>
          <FilterLabelContainer label='Date'>
            <DateRangeFilterButton
              explicitLabel={true}
              initialValue={initialInsightParams.date}
              onChange={value => setUiDateRange(value)}
              options={datePickerRangeOptionsRevamp}
              size='medium'
              value={uiDateRange}
            />
          </FilterLabelContainer>
          {!isEmail && !isVoice && (
            <FilterLabelContainer label='Channel'>
              <MultiSelectFilter
                fullWidth
                icon={<img src={chatIcon} />}
                maxHeight={425}
                onChange={value => setUiChannels(value)}
                options={[
                  {
                    label: CHANNEL_COPY.widget,
                    value: 'channels.widget',
                  },
                  { label: CHANNEL_COPY.api, value: 'channels.api' },
                  { label: CHANNEL_COPY.slack, value: 'channels.slack' },
                ]}
                placeholder='All channels'
                value={uiChannels}
                variant='secondary'
              />
            </FilterLabelContainer>
          )}
          {!isEmail && !isVoice && (
            <FilterLabelContainer label='Tag'>
              <MultiSelectFilter
                fullWidth
                icon={<IconTag size={20} />}
                maxHeight={425}
                onChange={value => setUiTags(value)}
                options={[
                  ...workflowTags.map(tag => ({
                    label: tag,
                    value: `workflow_tags.${tag}`,
                  })),
                  { label: 'N/A', value: 'workflow_tags.null' },
                ]}
                placeholder='All tags'
                value={uiTags}
                variant='secondary'
              />
            </FilterLabelContainer>
          )}
          {!isVoice && (
            <ContextVariableFilterBuilder
              filters={uiCvFilters}
              onChange={setUICvFilters}
              product={product}
              uiDateRange={uiDateRange}
            />
          )}
          <ListOptionParent
            expandedValues={expandedValues}
            onChange={value => setUiMultiSelected(value)}
            options={otherFiltersOptions}
            setExpandedValues={setExpandedValues}
            value={cleanMultiSelected}
          />
        </Box>
        <Box
          bgcolor='white'
          bottom='0'
          display='flex'
          gap='8px'
          marginTop='auto'
          padding='8px 0px'
          position='sticky'
        >
          <Button
            onClick={() => {
              handleReset();
              onClose();
            }}
            size='medium'
            variant='ghost'
          >
            <Typography noWrap variant='font14Medium'>
              Reset
            </Typography>
          </Button>
          <Button
            disabled={isDisabled}
            fullWidth
            onClick={() => {
              const combinedMultiSelected = [...cleanMultiSelected, ...uiTags];
              setUiQuery && setUiQuery(uiSearchQuery);

              setDateRange(uiDateRange, () =>
                setMultiSelected(combinedMultiSelected, () =>
                  setChannels(uiChannels, () =>
                    setSearchQueryMode(uiSearchQueryMode, () =>
                      setCvFilters(uiCvFilters),
                    ),
                  ),
                ),
              );
              onClose();
            }}
            size='medium'
            variant='main'
          >
            <Typography noWrap variant='font14Medium'>
              Apply
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }, [
    setCvFilters,
    uiCvFilters,
    expandedValues,
    handleReset,
    onClose,
    otherFiltersOptions,
    setChannels,
    setDateRange,
    setMultiSelected,
    setSearchQueryMode,
    setUiQuery,
    uiChannels,
    uiDateRange,
    cleanMultiSelected,
    uiSearchQuery,
    uiSearchQueryMode,
    uiTags,
    workflowTags,
    isDisabled,
    isEmail,
    isVoice,
    product,
  ]);

  const searchBar = useMemo(() => {
    if (filterType === 'article') {
      return (
        <Box width='360px'>
          <SearchBar
            onChange={e => setUiSearchQuery(e.target.value)}
            placeholder='Search article'
            size='small'
            value={uiSearchQuery}
          />
        </Box>
      );
    }

    if (searchOptions) {
      return (
        <Box width='360px'>
          <WithHelperText helperText={uiQueryError}>
            <SearchWithDropdown
              aria-label='Chat ID filter'
              fullWidth
              onChange={e => setUiSearchQuery(e.target.value)}
              onClear={() => setUiSearchQuery('')}
              options={[...searchOptions]}
              placeholder={`Enter ${searchOptions
                .find(option => option.value === uiSearchQueryMode)
                ?.text.toLowerCase()}`}
              selectChange={value => {
                setUiSearchQueryMode(value as SearchQueryMode);
                setUiSearchQuery('');
              }}
              selectedOption={uiSearchQueryMode}
              value={uiSearchQuery}
            />
          </WithHelperText>
        </Box>
      );
    }

    if (filterType === 'chat') {
      return (
        <Box width='360px'>
          <SearchBar
            onChange={e => setUiSearchQuery(e.target.value)}
            placeholder='Search by Conversation ID'
            size='small'
            value={uiSearchQuery}
          />
        </Box>
      );
    }

    return null;
  }, [
    filterType,
    searchOptions,
    uiQueryError,
    uiSearchQuery,
    uiSearchQueryMode,
  ]);

  return (
    <Drawer
      anchor='right'
      ModalProps={{
        BackdropProps: {
          sx: {
            bgcolor: alpha(palette.colors.slate[800], 0.2),
          },
        },
      }}
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: elevations.z5,
          width: '400px',
        },
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
        height='100%'
        padding='24px'
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography variant='font18Bold'>All filters</Typography>
          <IconButton
            aria-label='rename topic'
            onClick={onClose}
            variant='ghost'
          >
            <img src={xIcon} />
          </IconButton>
        </Box>
        {searchBar}
        {commonFilters}
      </Box>
    </Drawer>
  );
};

export default GlobalFilterDrawer;
