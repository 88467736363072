import ReactMarkdown from 'react-markdown';
import { Box, useTheme } from '@mui/material';
interface HighlightedArticleProps {
  content: string;
}

const HighlightedArticle = ({ content }: HighlightedArticleProps) => {
  const { palette } = useTheme();

  return (
    <Box bgcolor={palette.colors.yellow[100]} borderRadius='4px' padding='10px'>
      <ReactMarkdown>{content}</ReactMarkdown>
    </Box>
  );
};

export default HighlightedArticle;
