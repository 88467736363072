import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { IconArrowNarrowLeft } from '@tabler/icons-react';

import {
  DateRangeFilterButton,
  FilterButton,
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ArticlesLineChart from '../solve-insights/articles/ArticlesLineChart';
import ArticlesTable from '../solve-insights/articles/ArticlesTable';
import EmailArticlesTable from '../solve-insights/articles/EmailArticlesTable';
import InsightArticleDrawer from '../solve-insights/articles/InsightArticleDrawer';
import ChatsTable from '../solve-insights/chats/ChatsTable';
import EmailChatsTable from '../solve-insights/chats/EmailChatsTable';
import TabHeader from '../solve-insights/common/TabHeader';
import {
  EMAIL,
  initialInsightParams,
  WIDGET,
} from '../solve-insights/constants';
import { dateRangeToTimestamp } from '../solve-insights/helpers';
import { InsightPeriod, ProductMode } from '../solve-insights/types';
import DetailHeader from './DetailHeader';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { periodicalFilterOptions } from 'src/constants/discover';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import {
  useGetArticleDetailHeaderQuery,
  useGetEmailFeedbackQuery,
  useGetQuickFeedbackQuery,
} from 'src/services/insights';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  genericParameterValidator,
  genericSerializeAndDeserialize,
  isTimePeriodValid,
  listDeserialize,
  listSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

const SolveInsightsArticleDetail = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { articleId = '' } = useParams<'articleId'>();

  // state params
  const [productMode] = useStateParams<ProductMode>({
    deserialize: item => (item !== 'email' ? WIDGET : EMAIL),
    initialState: WIDGET,
    paramsName: 'product_mode',
    serialize: item => item,
  });
  const [activeArticleTab, setActiveArticleTab] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'tableTab',
    serialize: state => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [periodicalFilter, setPeriodicalFilter] = useStateParams<InsightPeriod>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => InsightPeriod,
      initialState: initialInsightParams.period,
      paramsName: 'period',
      serialize: genericSerializeAndDeserialize,
      validator: genericParameterValidator(periodicalFilterOptions),
    },
  );
  const [channels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });
  // end state params

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  // header detail data
  const isEmail = productMode === 'email';
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  const timeFilter = dateRangeToTimeFilter(dateRange);

  const { data: articleHeaderData, isFetching: isArticleHeaderFetching } =
    useGetArticleDetailHeaderQuery({
      article_id: articleId,
      channels: buildQueryFromMultiSelected(channels)?.channels,
      end: backendReadyTimestamps.end_timestamp,
      product: productMode,
      start: backendReadyTimestamps.start_timestamp,
    });
  const {
    data: quickFeedbackHeaderData,
    isFetching: isQuickFeedbackHeaderFetching,
  } = useGetQuickFeedbackQuery(
    {
      article_id: articleId,
      end_timestamp: backendReadyTimestamps.end_timestamp,
      is_topics_page: false,
      is_workflow_page: false,
      start_timestamp: backendReadyTimestamps.start_timestamp,
    },
    {
      skip: isEmail,
    },
  );
  const { data: userFeedbackData, isFetching: isUserFeedbackFetching } =
    useGetEmailFeedbackQuery(
      {
        article_id: articleId,
        end: backendReadyTimestamps.end_timestamp,
        start: backendReadyTimestamps.start_timestamp,
        tab: 'article',
      },
      { skip: !isEmail },
    );
  const feedbackData = quickFeedbackHeaderData ?? userFeedbackData;

  const isArticleDataLoading =
    isArticleHeaderFetching ||
    isQuickFeedbackHeaderFetching ||
    isUserFeedbackFetching;
  // end header detail data

  // scroll logic
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const scrollToTop = useCallback(
    (yOffset: number, smooth = false) => {
      if (!containerRef.current) {
        return;
      }
      if (containerRef.current.scrollTop > yOffset) {
        containerRef.current.scrollTo({
          behavior: smooth ? 'smooth' : 'auto',
          top: yOffset,
        });
        // TODO - when tab navigation is added this should be updated
        emitTrackingEventCallback('insight-scroll-to-top-clicked', {
          scope: 'detail',
          tab: 'article',
        });
      }
    },
    [emitTrackingEventCallback],
  );
  // end scroll logic
  const articleName = articleHeaderData?.title ? articleHeaderData.title : NA;

  useEffect(() => {
    // TODO - when tab navigation is added this should be updated
    emitTrackingEventCallback('insight-detail-initial-page', {
      scope: 'detail',
      tab: 'article',
    });
  }, [emitTrackingEventCallback]);

  const selectedTable = useMemo(() => {
    if (productMode === 'email') {
      if (activeArticleTab === 0) {
        return (
          <EmailChatsTable
            dateRange={dateRange}
            scope='main'
            setDateRange={setDateRange}
          />
        );
      }
      if (activeArticleTab === 1) {
        return (
          <EmailArticlesTable
            dateRange={dateRange}
            scope='main'
            setDateRange={setDateRange}
          />
        );
      }
    }
    if (productMode === 'widget') {
      if (activeArticleTab === 0) {
        return (
          <ChatsTable
            dateRange={dateRange}
            isDetail={true}
            period={periodicalFilter}
            scope='detail'
            scrollTop={scrollTop}
            scrollToTop={scrollToTop}
            setDateRange={setDateRange}
            setPeriodicalFilter={setPeriodicalFilter}
            view='articles'
          />
        );
      }
      if (activeArticleTab === 1) {
        return (
          <ArticlesTable
            dateRange={dateRange}
            period={periodicalFilter}
            scope='detail'
            setDateRange={setDateRange}
            setPeriodicalFilter={setPeriodicalFilter}
          />
        );
      }
    }
  }, [
    productMode,
    activeArticleTab,
    dateRange,
    periodicalFilter,
    scrollTop,
    scrollToTop,
    setDateRange,
    setPeriodicalFilter,
  ]);

  return (
    <>
      <NavbarV2
        currentTabOverride={
          <Box
            display='flex'
            onClick={() => {
              navigate({
                pathname: Routes.SOLVE_INSIGHTS_MAIN,
                search: `?tab=${tab}&product_mode=${productMode}`,
              });
              emitTrackingEventCallback('insight-detail-back-button-clicked', {
                scope: 'detail',
                tab: 'article',
              });
            }}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <IconArrowNarrowLeft
              color={palette.colors.purple[500]}
              size='20px'
            />
            <Typography color={palette.colors.purple[500]} variant='font14Bold'>
              All Articles
            </Typography>
          </Box>
        }
      />
      <Box
        bgcolor={palette.colors.white}
        flex='1'
        height='100%'
        onScroll={e => {
          setScrollTop(e.currentTarget.scrollTop);
        }}
        overflow='auto'
        ref={containerRef}
        width='100%'
      >
        <Box
          bgcolor={palette.colors.white}
          display='flex'
          flexDirection='column'
          gap='24px'
          px='40px'
          py='24px'
          zIndex={5}
        >
          <TabHeader
            article={articleHeaderData}
            isLoading={isArticleDataLoading}
            scope='detail'
            tabName={articleName}
          />
          <Box
            alignItems='center'
            display='flex'
            gap={1}
            justifyContent='start'
          >
            <DateRangeFilterButton
              explicitLabel={true}
              initialValue={initialInsightParams.date}
              onChange={value => {
                setDateRange(value);
                // TODO - when tab navigation is added this should be updated
                const timeStamp = dateRangeToTimestamp(value);
                emitTrackingEventCallback('insight-date-change', {
                  from: timeStamp.start_timestamp,
                  scope: 'detail',
                  tab: 'article',
                  to: timeStamp.end_timestamp,
                });
              }}
              options={datePickerRangeOptionsRevamp}
              value={dateRange}
            />
            <FilterButton
              aria-label='time period filter'
              disabledTooltipMessage='Time period not valid for selected time range'
              initialValue={initialInsightParams.period}
              onChange={value => {
                setPeriodicalFilter(value as InsightPeriod);
                emitTrackingEventCallback('insight-period-change', {
                  scope: 'detail',
                  tab: 'article',
                  value,
                });
              }}
              options={periodicalFilterOptions.map(option => {
                const isDisabled = !isTimePeriodValid(option.value, timeFilter);
                return { ...option, disabled: isDisabled };
              })}
              startAdornment={<FilterButtonIcon dropdownType='time-period' />}
              value={periodicalFilter}
            />
          </Box>
          <DetailHeader
            data={articleHeaderData}
            feedbackData={feedbackData}
            isLoading={isArticleDataLoading}
            productMode={productMode}
          />
          <Box>
            <ArticlesLineChart
              articleId={articleId}
              channels={channels}
              dateRange={dateRange}
              periodicalFilter={periodicalFilter}
              productMode={productMode}
              scope='detail'
            />
          </Box>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: palette.colors.white,
                display: 'flex',
                gap: 1,
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 5,
              }}
            >
              <Tabs
                a11yProps={index => ({
                  'aria-controls': `tabpanel-${index}`,
                  id: `tab-${index}`,
                })}
                aria-label='tabs to pick data table below'
                onChange={(_, tab) => {
                  const tabValue = tab === 0 ? 'chat' : 'article';
                  setActiveArticleTab(tab);
                  emitTrackingEventCallback('insight-table-tab-change', {
                    scope: 'detail',
                    tab: 'article',
                    value: tabValue,
                  });
                  const currentSearchParams = new URLSearchParams(search);
                  // Clear table query when switching tabs
                  const newSearchParams = new URLSearchParams();
                  // Save params that are not tab related
                  const productMode =
                    currentSearchParams.get('product_mode') || 'widget';
                  const pageTab = currentSearchParams.get('tab');
                  newSearchParams.set('product_mode', productMode);
                  if (pageTab) {
                    newSearchParams.set('tab', pageTab);
                  }
                  setSearchParams(newSearchParams);
                }}
                tabs={['Conversations', 'Related articles']}
                typographyVariant='font18'
                value={activeArticleTab}
              />
            </Box>
            <Box paddingTop='16px'>
              <Typography color={palette.colors.grey[600]} variant='font16'>
                {activeArticleTab === 0
                  ? `Conversations surfaced the article “${articleName}”`
                  : 'Articles surfaced alongside the article in chats.'}
              </Typography>
            </Box>
            {selectedTable}
            <InsightArticleDrawer scope='detail' />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SolveInsightsArticleDetail;
