import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';

interface WithHelperTextProps {
  children: React.ReactNode;
  helperText: string | null;
}

export default function WithHelperText({
  children,
  helperText,
}: WithHelperTextProps) {
  const hasError = Boolean(helperText);
  return (
    <FormControl error={hasError} fullWidth>
      {children}
      {helperText && (
        <FormHelperText error={hasError}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
