import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  CANNOT_INSERT_UPDATE_ACTIVATE_ENTITY,
  CONTEXT_VARIABLE_TYPE_MISMATCH,
  FIELD_INTEGRITY_EXCEPTION,
  FRESHCHAT_WIDGET_NOT_FOUND_ERROR,
  getHowToFixHandoffMessage,
  INSUFFICIENT_ACCESS_ON_CROSS_REFERENCE_ENTITY,
  INVALID_CROSS_REFERENCE_KEY,
  KUSTOMER_WIDGET_NOT_FOUND_ERROR,
  MALFORMED_ID,
  MISSING_CONTEXT_VARIABLE,
  NO_VALID_CONDITION,
  SALESFORCE_CHAT_BUTTON_NOT_FOUND_ERROR,
  SALESFORCE_MESSAGING_WIDGET_NOT_FOUND_ERROR,
  SALESFORCE_WIDGET_NOT_FOUND_ERROR,
  SNAPENGAGE_WIDGET_NOT_FOUND_ERROR,
  ZENDESK_MESSAGING_WIDGET_NOT_FOUND_ERROR,
  ZENDESK_REQUESTER_EMAIL_INVALID,
  ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS,
  ZENDESK_REQUESTER_EMAIL_SUSPENDED,
  ZENDESK_VALUE_INVALID_FORMAT,
  ZENDESK_WIDGET_NOT_FOUND_ERROR,
} from '../utils';
import { HowToFixSection } from './HowToFixSection';
import { MissingContextVariableFix } from './MissingContextVariableFix';
import { MissMatchContextVariableFix } from './MissMatchContextVariableFix';
import { NoValidConditionFix } from './NoValidConditionFix';
import { PlainTextFix } from './PlainTextFix';
import {
  BaseErrorPayload,
  ErrorQueryEvent,
  MissingContextVariablePayload,
  MissMatchContextVariableTypePayload,
} from 'src/types/ErrorEventTypes';

export const RecommendedFix = ({ error }: { error: ErrorQueryEvent }) => {
  const { palette } = useTheme();

  const getRecommendedFix = () => {
    switch (error.error_message) {
      case MISSING_CONTEXT_VARIABLE:
        return (
          <MissingContextVariableFix
            channel={error.channel}
            payload={error.error_payload as MissingContextVariablePayload}
          />
        );
      case CONTEXT_VARIABLE_TYPE_MISMATCH:
        return (
          <MissMatchContextVariableFix
            payload={error.error_payload as MissMatchContextVariableTypePayload}
          />
        );
      case NO_VALID_CONDITION:
        return (
          <NoValidConditionFix
            channel={error.channel}
            payload={error.error_payload as MissingContextVariablePayload}
          />
        );
      case ZENDESK_REQUESTER_EMAIL_INVALID:
        return (
          <PlainTextFix text='1. Use a context variable of type EMAIL to ensure the collected email adheres to the correct format.' />
        );
      case ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS:
        return (
          <PlainTextFix text="1. Add a condition step to check if the requester's email is already used as a support address." />
        );
      case ZENDESK_REQUESTER_EMAIL_SUSPENDED:
        return (
          <PlainTextFix text='1. Add a condition step to verify the validity of the requester email before sending requests.' />
        );
      case ZENDESK_VALUE_INVALID_FORMAT:
        return (
          <PlainTextFix text='1. Use a context variable of the correct type in the form step to ensure the collected CV is in the right format.' />
        );
      case CANNOT_INSERT_UPDATE_ACTIVATE_ENTITY:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
          >
            <Typography variant='font16'>
              1. Ensure that the data being submitted or set in the Case
              Creation Configuration complies with all active validation rules
              on the Case object.
            </Typography>
            <Typography variant='font16'>
              2. Check for any Apex triggers on Salesforce on the Case object
              that might be causing exceptions. Review the trigger logic to
              handle edge cases gracefully.
            </Typography>
            <Typography variant='font16'>
              3. Identify any Process Builders or Workflows associated with the
              Case object. Ensure they are not causing unintended errors during
              record operations.
            </Typography>
            <Typography variant='font16'>
              4. Verify that the integration user has the necessary permissions
              for all fields involved in the operation.
            </Typography>
          </HowToFixSection>
        );
      case INSUFFICIENT_ACCESS_ON_CROSS_REFERENCE_ENTITY:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
          >
            <Typography variant='font16'>
              1. Ensure that the integration user has the necessary permissions
              for both the Case object and any related objects involved in the
              operation.
            </Typography>
            <Typography variant='font16'>
              2. On Salesforce review the organization-wide defaults and sharing
              rules to ensure the integration user has access to the related
              records.
            </Typography>
            <Typography variant='font16'>
              3. Ensure that the integration user owns the record or has been
              granted access.
            </Typography>
          </HowToFixSection>
        );
      case MALFORMED_ID:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
          >
            <Typography variant='font16'>
              1. Ensure that all IDs provided correspond to valid Salesforce
              record IDs.
            </Typography>
            <Typography variant='font16'>
              2. Verify that the IDs reference existing records in Salesforce.
            </Typography>
            <Typography variant='font16'>
              3. Ensure that all of the required fields are set with a proper
              value in Case Creation Configuration.​
            </Typography>
          </HowToFixSection>
        );
      case INVALID_CROSS_REFERENCE_KEY:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
          >
            <Typography variant='font16'>
              1. Ensure that all lookup fields contain valid and existing record
              IDs.
            </Typography>
            <Typography variant='font16'>
              2. Confirm that the integration user has access to the records
              referenced by foreign keys.
            </Typography>
            <Typography variant='font16'>
              3. Ensure that the record types being referenced are valid for the
              integrated user profile.
            </Typography>
          </HowToFixSection>
        );
      case FIELD_INTEGRITY_EXCEPTION:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
          >
            <Typography variant='font16'>
              1. Ensure that any picklist fields are set to one of the
              predefined values.
            </Typography>
            <Typography variant='font16'>
              2. Confirm that the data being entered matches the field data type
              (e.g., date fields receive date values).
            </Typography>
            <Typography variant='font16'>
              3. Ensure that any dependent fields have valid values based on
              their controlling fields.
            </Typography>
          </HowToFixSection>
        );
      case SALESFORCE_MESSAGING_WIDGET_NOT_FOUND_ERROR:
      case SALESFORCE_CHAT_BUTTON_NOT_FOUND_ERROR:
      case SALESFORCE_WIDGET_NOT_FOUND_ERROR:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
            showDeepLink={false}
          >
            <Typography variant='font16'>
              {`1. ${getHowToFixHandoffMessage('Salesforce widget')}`}
            </Typography>
            <Typography variant='font16'>
              2. Go to Salesforce Setup and review your Embedded Service
              Deployment settings. Ensure the API version and deployment ID
              match those used in your implementation.
            </Typography>
          </HowToFixSection>
        );
      case ZENDESK_MESSAGING_WIDGET_NOT_FOUND_ERROR:
      case ZENDESK_WIDGET_NOT_FOUND_ERROR:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
            showDeepLink={false}
          >
            <Typography variant='font16'>
              {`1. ${getHowToFixHandoffMessage('Zendesk widget')}`}
            </Typography>
            <Typography variant='font16'>
              2. Go to the Zendesk Admin section and review your Chat Widget
              settings.
            </Typography>
          </HowToFixSection>
        );

      case FRESHCHAT_WIDGET_NOT_FOUND_ERROR:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
            showDeepLink={false}
          >
            <Typography variant='font16'>
              {`1. ${getHowToFixHandoffMessage('Freshchat widget')}`}
            </Typography>
            <Typography variant='font16'>
              2. Log in to your Freshchat account and ensure your widget is
              properly configured. Look for the widget token and verify it
              matches the one used on your website.
            </Typography>
          </HowToFixSection>
        );
      case KUSTOMER_WIDGET_NOT_FOUND_ERROR:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
            showDeepLink={false}
          >
            <Typography variant='font16'>
              {`1. ${getHowToFixHandoffMessage('Kustomer widget')}`}
            </Typography>
            <Typography variant='font16'>
              2. Check your Kustomer account to confirm that the widget is
              correctly configured. Ensure that the organizationId matches the
              one used on your website.
            </Typography>
          </HowToFixSection>
        );
      case SNAPENGAGE_WIDGET_NOT_FOUND_ERROR:
        return (
          <HowToFixSection
            channel={error.channel}
            error_payload={error.error_payload as BaseErrorPayload}
            showDeepLink={false}
          >
            <Typography variant='font16'>
              {`1. ${getHowToFixHandoffMessage('SnapEngage widget')}`}
            </Typography>
            <Typography variant='font16'>
              2. Log in to your SnapEngage account and ensure your widget is
              properly configured.
            </Typography>
          </HowToFixSection>
        );
      default:
        return null;
    }
  };

  const CurrentErrorFix = getRecommendedFix();

  return (
    CurrentErrorFix && (
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography variant='font11'>How to fix</Typography>
        <Box
          border={`1px solid ${palette.colors.slate[200]}`}
          borderRadius='8px'
          display='flex'
          flexDirection='column'
          gap='16px'
          padding='16px'
        >
          {CurrentErrorFix}
        </Box>
      </Box>
    )
  );
};
