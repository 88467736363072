import { css, styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { StyledButton } from 'src/components/styled-button/StyledButton';
import { ActiveStateIcon } from 'src/pages/workflow-builder/intent-form/IntentForm';
import { textMediumStyle } from 'src/styles/styledMixin';

export const ContextVariablesSettingsContainer = styled('div')<{
  height: string;
}>`
  display: flex;
  flex-direction: column;
  height: ${props => props.height};
`;

export const FieldHeadings = styled('div')<{
  includesExtraColumn: boolean;
}>`
  display: grid;
  gap: 10px;
  border-bottom: 1px solid ${theme.palette.colors.grey[300]};

  grid-template-columns: ${({ includesExtraColumn }) => {
    return includesExtraColumn
      ? '25% 15% 18% 15% 17% 5%'
      : '25% 15% 20% 15% 20%';
  }};
`;

export const FieldsContainer = styled('div')`
  position: relative;
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
`;

export const FieldItemContainer = styled('div')<{
  includesExtraColumn: boolean;
  isUniversal: boolean;
}>`
  display: grid;
  align-items: center;
  gap: 10px;

  grid-template-columns: ${({ includesExtraColumn }) => {
    return includesExtraColumn
      ? '25% 15% 18% 15% 17% 5%'
      : '25% 15% 20% 15% 20%';
  }};

  ${({ isUniversal }) =>
    isUniversal &&
    css`
      border-bottom: 1px solid ${theme.palette.colors.grey[300]};
      padding: 10px 0;
    `};

  &:last-child {
    margin-bottom: 10px;
  }
`;

export const ErrorMessage = styled('span')`
  ${textMediumStyle({ fontSize: '12px' })}
  color: ${Colors.ui.semantic.destructive.default};
  text-transform: capitalize;
  margin: -7px 0 0 0;
  white-space: nowrap;
`;

export const ImgFill = styled(ActiveStateIcon)`
  [height] {
    height: 25px;
  }

  [width] {
    width: 25px;
  }
`;

export const Cell = styled('div')<{
  flexGrow?: number;
  isDisabled?: boolean;
  width?: string;
}>`
  ${textMediumStyle({ color: Colors.ui.text.quaternary, fontSize: '15px' })}
  flex: ${props =>
    props.width ? `0 0 ${props.width}` : `${props.flexGrow} 1 ${props.width}`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DropdownCell = styled(Cell)`
  align-self: flex-start;
  margin-top: 10px;
`;

export const Spacer = styled('div')<{
  height?: string;
  width?: string;
}>`
  height: ${props => props.height || 0};
  width: ${props => props.width || 0};
`;

export const InputWrapper = styled('div')`
  background-color: ${Colors.ui.backgrounds.default};
  border: 1px solid ${theme.palette.colors.grey[300]};
  margin: 10px 10px 10px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:focus {
    border-color: ${theme.palette.colors.purple[500]};
  }

  &:hover:not(:disabled) {
    border-color: ${theme.palette.colors.grey[600]};
  }
`;

export const Button = styled(StyledButton)`
  border: 1px solid transparent;
  margin: 10px 0;
  padding: 7px 8px;
  color: ${theme.palette.colors.purple[500]};
  border-radius: var(--border-radius-button);
  cursor: pointer;
  position: relative;
  top: -5px;
  display: flex;
  background-color: transparent;
  width: 145px;
  align-self: flex-end;

  &:hover {
    background-color: ${Colors.ui.backgrounds.purple};
  }
  &:active {
    background-color: ${Colors.ui.backgrounds.purple};
    border: 1px solid ${theme.palette.colors.purple[600]};
  }
`;

export const AddIcon = styled('img')`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const EmbeddedButton = styled(StyledButton)`
  margin: 0 8px 0 auto;
  background: ${theme.palette.colors.purple[100]};
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: ${theme.palette.colors.purple[500]};
  padding: 4px 8px;
  font-weight: 600;

  &:hover {
    background: ${theme.palette.colors.purple[200]};
  }

  &:disabled {
    background: ${theme.palette.colors.slate[100]};
    color: ${theme.palette.colors.grey[400]};

    svg {
      [fill] {
        fill: ${theme.palette.colors.grey[400]};
      }
    }
  }

  & svg {
    vertical-align: middle;
    width: 16px;
    margin-right: 2px;

    [fill] {
      fill: ${theme.palette.colors.purple[500]};
    }
  }
`;
