import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { IconX } from '@tabler/icons-react';

import {
  Checkbox,
  TextField,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ContextMention from '../context-mention-input';
import {
  Label,
  Row,
  Spacer,
  StyledInput,
} from '../handoff-configuration/styles';
import { stringContextVariableFilterFn } from './helpers';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { SnapEngageChatHandoffActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SnapEngageChatHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as SnapEngageChatHandoffActionSettings;
  const initialCustomizationData: SnapEngageChatHandoffActionSettings =
    addingAction
      ? {
          bot_alias_list: null,
          custom_fields_and_values: [],
          include_transcript: true,
          visitor_email_variable: '',
          visitor_name_variable: '',
          visitor_question_variable: '',
        }
      : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<SnapEngageChatHandoffActionSettings>(initialCustomizationData);
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);
  const botAliasList = customizationData?.bot_alias_list || [];
  const customFieldsAndValues =
    customizationData?.custom_fields_and_values || [];

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  const updateCustomField = (
    idx: number,
    key: 'custom_field_name' | 'custom_field_value',
    value: string,
  ) => {
    const updatedCustomFields = customFieldsAndValues.map(
      (field, currentIndex) =>
        currentIndex === idx ? { ...field, [key]: value } : field,
    );

    setCustomizationData(handoffData => ({
      ...handoffData,
      custom_fields_and_values: updatedCustomFields,
    }));
  };

  const removeCustomField = (idx: number) => {
    const updatedCustomFields = customFieldsAndValues.filter(
      (_, currentIndex) => currentIndex !== idx,
    );

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        custom_fields_and_values: updatedCustomFields,
      };
    });
  };

  const addCustomField = () => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        custom_fields_and_values: [
          ...customFieldsAndValues,
          {
            custom_field_name: '',
            custom_field_value: '',
          },
        ],
      };
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>SnapEngage Chat Handoff</Title>
        <Spacer height='20px' />
        <Section>
          <Checkbox
            checked={customizationData.include_transcript}
            label='Send transcript to agent'
            onChange={() => {
              setCustomizationData({
                ...customizationData,
                include_transcript: !customizationData.include_transcript,
              });
            }}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Name Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                visitor_name_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.visitor_name_variable || ''}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Email Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                visitor_email_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.visitor_email_variable || ''}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Question Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                visitor_question_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.visitor_question_variable || ''}
          />
        </Section>
        <Section sx={{ gap: 1 }}>
          <Typography variant='font14Bold'>Custom Fields</Typography>
          {customFieldsAndValues.map((customField, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.colors.white,
                    flex: '1 1 50%',
                  }}
                >
                  <TextField
                    aria-label={`${index}-custom-field-name`}
                    autoComplete='off'
                    onChange={({ target }) => {
                      updateCustomField(
                        index,
                        'custom_field_name',
                        target.value,
                      );
                    }}
                    placeholder='Enter field name'
                    value={customField.custom_field_name.toString()}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: theme.palette.colors.white,
                    flex: '1 1 50%',
                  }}
                >
                  <ContextMention
                    filterFn={stringContextVariableFilterFn}
                    onChange={value => {
                      updateCustomField(index, 'custom_field_value', value);
                    }}
                    placeholder='Enter field value'
                    value={customField.custom_field_value.toString()}
                  />
                </Box>
                <Spacer width='8px' />
                <IconX
                  cursor='pointer'
                  onClick={() => removeCustomField(index)}
                />
              </Box>
            </Box>
          ))}
          <Add isDisabled={false} onClick={addCustomField}>
            + Add field
          </Add>
        </Section>
        <Section>
          <Tooltip tooltipContent="List of bot aliases so we don't send transcript messages to bots">
            <Label>Bot Alias List</Label>
          </Tooltip>
          {botAliasList.map((alias, idx) => {
            return (
              <Box key={`snapengage-alias-${idx}`}>
                <Row>
                  <StyledInput
                    onChange={e => {
                      const newValue = e.target.value;

                      setCustomizationData(data => {
                        const newAliasList = [...botAliasList];
                        newAliasList.splice(idx, 1, newValue);

                        return {
                          ...data,
                          bot_alias_list: newAliasList,
                        };
                      });
                    }}
                    placeholder='Enter Bot Alias'
                    type='string'
                    value={alias}
                    width='inherit'
                  />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      height: '42px',
                      justifyContent: 'flex-end',
                      width: '30px',
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        setCustomizationData(data => {
                          const newAliasList = [...botAliasList];
                          newAliasList.splice(idx, 1);

                          if (newAliasList.length === 0) {
                            return {
                              ...data,
                              bot_alias_list: null,
                            };
                          }

                          return {
                            ...data,
                            bot_alias_list: newAliasList,
                          };
                        });
                      }}
                      role='button'
                      src={xIcon}
                    />
                  </Box>
                </Row>
                {idx !== botAliasList.length - 1 && <Spacer height='10px' />}
              </Box>
            );
          })}
        </Section>
        <Add
          isDisabled={false}
          onClick={() => {
            setCustomizationData(data => {
              return {
                ...data,
                bot_alias_list: [...botAliasList, ''],
              };
            });
          }}
        >
          + Add Bot Alias
        </Add>
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={() => {
            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type: ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default SnapEngageChatHandoffCustomizationPanel;

const Title = styled('h1')`
  font-size: var(--font-size-m);
  text-align: left;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  cursor: default;
  margin: 0 10px;
`;

const Section = styled('section')`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const Add = styled('a')<{ isDisabled: boolean }>(({ isDisabled }) => ({
  color: isDisabled
    ? theme.palette.colors.grey[300]
    : theme.palette.colors.purple[500],
  cursor: 'pointer',
  fontSize: '16px',
  fontStyle: isDisabled ? 'italic' : 'normal',
  lineHeight: '16px',
  marginTop: '16px',
  maxWidth: '100%',
  pointerEvents: isDisabled ? 'none' : 'auto',
  textAlign: 'left',
  width: '100%',
}));
