import { useMemo } from 'react';

import {
  NONE_RTE_INPUT_DEBOUNCE_DELAY,
  RTE_INPUT_DEBOUNCE_DELAY,
} from '../constants';
import { useGetSelectedBrand } from '../hooks';
import debounce from 'lodash/fp/debounce';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import {
  updatePlaybookConfiguration,
  updateVoiceConfiguration,
  updateWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import {
  PlaybookConfiguration,
  VoiceConfiguration,
  WidgetConfiguration,
} from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';
import {
  SolveConfigTrackingEventTypes,
  SolveConfigurationTypes,
} from 'src/utils/enums';

interface DebouncedSubmitOptions {
  isPlaybook?: boolean;
}

const useDebouncedSubmitConfig = (
  delay: number,
  { isPlaybook }: DebouncedSubmitOptions = {},
) => {
  const dispatch = useAppDispatch();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const selectedBrand = useGetSelectedBrand();

  const debouncedSubmitConfig = useMemo(
    () =>
      debounce(
        delay,
        async (
          config: WidgetConfiguration | PlaybookConfiguration,
          type: string,
          value: string,
          callback?: () => void,
        ) => {
          if (isPlaybook) {
            await dispatch(
              updatePlaybookConfiguration({ playbookConfiguration: config }),
            );
          } else if ('launch_style' in config) {
            // launch_style is only present in WidgetConfiguration
            await dispatch(
              updateWidgetConfiguration({
                brandId: selectedBrand?.brand_id,
                isMultibrandEnabled,
                widgetConfiguration: config,
              }),
            );
          } else {
            throw new Error(
              `Invalid configuration type isPlaybook=${isPlaybook}`,
            );
          }

          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: type,
              value: value,
            },
          );
          callback?.();
        },
      ),
    [
      delay,
      dispatch,
      selectedBrand?.brand_id,
      isMultibrandEnabled,
      dispatchTrackingAction,
      isPlaybook,
    ],
  );

  return debouncedSubmitConfig;
};

export const useDebouncedSubmitConfigForRTE = (
  options: DebouncedSubmitOptions = {},
) => {
  return useDebouncedSubmitConfig(RTE_INPUT_DEBOUNCE_DELAY, options);
};

export const useDebouncedSubmitConfigForNonRTE = (
  options: DebouncedSubmitOptions = {},
) => {
  return useDebouncedSubmitConfig(NONE_RTE_INPUT_DEBOUNCE_DELAY, options);
};

const useDebouncedSubmitVoiceConfig = (delay: number) => {
  const dispatch = useAppDispatch();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const debouncedSubmitConfig = useMemo(
    () =>
      debounce(
        delay,
        async (
          config: VoiceConfiguration,
          type: string,
          value: string,
          callback?: () => void,
        ) => {
          await dispatch(
            updateVoiceConfiguration({
              updatedConfigurations: config,
            }),
          );
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.VOICE_CONFIGURATION_PUBLISHED,
            {
              configuration_type: SolveConfigurationTypes.VOICE,
              type: type,
              value: value,
            },
          );
          callback?.();
        },
      ),
    [delay, dispatch, dispatchTrackingAction],
  );

  return debouncedSubmitConfig;
};

export const useDebouncedSubmitVoiceConfigForNonRTE = () => {
  return useDebouncedSubmitVoiceConfig(NONE_RTE_INPUT_DEBOUNCE_DELAY);
};
