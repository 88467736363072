import { DateRange } from '../discover-dashboard-page/types';
import { InsightWorkflowSortColumns } from './workflows/constants';
import {
  Channel,
  InsightPeriod,
  InsightSortDirection,
  OverviewOvertimePeriod,
} from './types';
import {
  InsightArticle,
  InsightArticleSortColumns,
  InsightConversation,
  InsightConversationSortColumns,
  InsightEmailArticle,
  InsightEmailArticleSortColumns,
  InsightEmailConversation,
  InsightEmailConversationSortColumns,
  InsightWorkflow,
} from 'src/services/insights/types';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

export const initialInsightParams: {
  articleColumns: (keyof InsightArticle)[];
  articleColumnSort: InsightArticleSortColumns;
  articleColumnSortDirection: InsightSortDirection;
  channels: Channel[];
  chatColumns: (keyof InsightConversation)[];
  chatColumnSort: InsightConversationSortColumns;
  chatColumnSortDirection: InsightSortDirection;
  date: DateRange;
  emailArticleColumns: (keyof InsightEmailArticle)[];
  emailArticleColumnSort: InsightEmailArticleSortColumns;
  emailArticleColumnSortDirection: InsightSortDirection;
  emailChatColumns: (keyof InsightEmailConversation)[];
  emailChatColumnSort: InsightEmailConversationSortColumns;
  emailChatColumnSortDirection: InsightSortDirection;
  period: InsightPeriod;
  workflowColumnSort: InsightWorkflowSortColumns;
} = {
  articleColumns: [
    'title',
    'chats_count',
    'deflected_chats_count',
    'times_surfaced',
    'csat',
    'workflows',
    'related_articles',
  ],
  articleColumnSort: 'times_surfaced',
  articleColumnSortDirection: 'DESC',
  channels: ['widget', 'slack', 'api'],
  chatColumns: [
    'timestamp',
    'chat',
    'user_query',
    'detected_intents',
    'articles',
    'is_deflected',
    'csat',
  ],
  chatColumnSort: 'timestamp',
  chatColumnSortDirection: 'DESC',
  date: last30DaysTimeRange,
  emailArticleColumns: [
    'chats_count',
    'deflected_count',
    'times_surfaced',
    'user_feedback_positive',
  ],
  emailArticleColumnSort: 'times_surfaced',
  emailArticleColumnSortDirection: 'DESC',
  emailChatColumns: [
    'timestamp',
    'ticket_id',
    'ticket_body',
    'is_deflected',
    'is_eligible',
    'response_sent',
    'skip_reason',
    'detected_intents',
    'articles',
    'user_feedback',
  ],
  emailChatColumnSort: 'timestamp',
  emailChatColumnSortDirection: 'DESC',
  period: 'weekly',
  workflowColumnSort: 'chat_count',
};

export const INSIGHTS_DATE_FORMAT = 'MMM dd, yyyy';

export const TABLE_COLUMN_TO_LABEL_MAP: {
  article: Record<
    keyof Omit<
      InsightArticle,
      | 'content'
      | 'user_feedback_negative'
      | 'user_feedback_unanswered'
      | 'click_rate'
      | 'source_link'
      | 'id'
      | 'is_public'
      | 'clicks'
      | 'deflected_rate'
      | 'last_updated_at'
      | 'interactive_email_count'
    >,
    string
  >;
  conversation: Record<
    keyof Omit<
      InsightConversation,
      | 'conversation_id'
      | 'dropoff_reasoning'
      | 'relevance_reasoning'
      | 'not_useful_count'
      | 'unanswered_count'
    >,
    string
  >;
  email_article: Record<
    keyof Omit<
      InsightEmailArticle,
      | 'user_feedback_negative'
      | 'user_feedback_unanswered'
      | 'id'
      | 'source_link'
    >,
    string
  >;
  email_conversation: Record<
    keyof Omit<
      InsightEmailConversation,
      | 'conversation_id'
      | 'email_workflow_execution_status'
      | 'interactive_email_deflection_reason'
      | 'not_useful_count'
      | 'unanswered_count'
      | 'ticket_title'
      | 'is_interactive_email'
      | 'is_autoflow'
    >,
    string
  >;
  workflow: Record<
    keyof Omit<
      InsightWorkflow,
      | 'cost_per_ticket'
      | 'intent_definition_id'
      | 'intent_workflow_id'
      | 'is_handoff'
      | 'is_loading'
    >,
    string
  >;
} = {
  article: {
    channels: 'Surfaced channels',
    chats_count: 'Conversations',
    // content: 'Article',
    csat: 'Avg. CSAT',
    deflected_chats_count: 'Deflections',
    dropoff_breakdown: 'User engagement',
    dropoff_rate: 'Engagement rate',
    related_articles: 'Related articles',
    relevance_breakdown: 'Relevance',
    times_surfaced: 'Surfaced & clicks',
    title: 'Title',
    user_feedback_positive: 'User feedback',
    workflows: 'Top 3 surfaced workflows',
  },
  conversation: {
    articles: 'Surfaced articles',
    autonomous_agent_used: '',
    channel: 'Channel',
    chat: 'Conversation',
    csat: 'CSAT',
    detected_intents: 'Detected workflows',
    detected_topic: 'Detected topic',
    dropoff_rating: 'User engagement',
    intent_count: 'Intent count',
    is_deflected: 'Deflected',
    relevance_rating: 'Relevance',
    timestamp: 'Timestamp',
    useful_count: 'Quick feedback',
    user_queries: 'User query',
    user_query: 'User query',
    voice_call_duration: 'Call duration',
    voice_sentiment: 'Sentiment',
    workflow_tag: 'Tags',
  },
  email_article: {
    channels: 'Surfaced channels',
    chats_count: 'Conversations',
    content: 'Article',
    deflected_count: 'Deflections',
    interactive_email_count: 'Engaged with interactive email',
    related_articles: 'Related articles',
    times_surfaced: 'Surfaced',
    title: 'Title',
    user_feedback_positive: 'User feedback',
    workflows: 'Top surfaced workflows',
  },
  email_conversation: {
    articles: 'Surfaced articles',
    detected_intents: 'Detected workflow',
    is_deflected: 'Deflected',
    is_eligible: 'Eligible',
    response_sent: 'Email sent',
    skip_reason: 'No response reason',
    ticket_body: 'Incoming email',
    ticket_id: 'Ticket ID',
    timestamp: 'Timestamp',
    user_feedback: 'User feedback',
  },
  workflow: {
    avg_csat: 'Avg. CSAT',
    channel: 'Channel',
    chats: 'Conversations',
    deflected_count: 'Deflections',
    dropoff_breakdown: 'User engagement',
    engagement_rate: 'Engagement rate',
    feedback_breakdown: 'Quick feedback',
    intent_title: 'Workflow',
    is_active: 'Activate',
    is_autoflow: 'Type',
    non_deflected_count: 'Non-deflections',
    potential_savings: 'Potential saving',
    realized_savings: 'Realized saving',
    relevance_breakdown: 'Relevance',
    surfaced_articles: 'Surfaced articles',
  },
};

export const TABLE_COLUMNS: {
  articles: { label: string; value: keyof InsightArticle }[];
  conversations: { label: string; value: keyof InsightConversation }[];
  workflows: { label: string; value: keyof InsightWorkflow }[];
} = {
  articles: [
    { label: TABLE_COLUMN_TO_LABEL_MAP.article.title, value: 'title' },
    // { label: TABLE_COLUMN_TO_LABEL_MAP.article.content, value: 'content' },
    // {
    //   label: TABLE_COLUMN_TO_LABEL_MAP.article.user_feedback_positive,
    //   value: 'user_feedback_positive',
    // },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.article.times_surfaced,
      value: 'times_surfaced',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.article.deflected_chats_count,
      value: 'deflected_chats_count',
    },
    { label: TABLE_COLUMN_TO_LABEL_MAP.article.csat, value: 'csat' },
    { label: TABLE_COLUMN_TO_LABEL_MAP.article.workflows, value: 'workflows' },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.article.related_articles,
      value: 'related_articles',
    },
  ],
  conversations: [
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.conversation.timestamp,
      value: 'timestamp',
    },
    { label: TABLE_COLUMN_TO_LABEL_MAP.conversation.chat, value: 'chat' },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.conversation.user_query,
      value: 'user_query',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_intents,
      value: 'detected_intents',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.conversation.articles,
      value: 'articles',
    },
    // {
    //   label: TABLE_COLUMN_TO_LABEL_MAP.conversation.intent_count,
    //   value: 'intent_count',
    // },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.conversation.is_deflected,
      value: 'is_deflected',
    },
    { label: TABLE_COLUMN_TO_LABEL_MAP.conversation.csat, value: 'csat' },
  ],
  workflows: [
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.is_autoflow,
      value: 'is_autoflow',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.avg_csat,
      value: 'avg_csat',
    },
    { label: TABLE_COLUMN_TO_LABEL_MAP.workflow.chats, value: 'chats' },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.deflected_count,
      value: 'deflected_count',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.dropoff_breakdown,
      value: 'dropoff_breakdown',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.engagement_rate,
      value: 'engagement_rate',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.feedback_breakdown,
      value: 'feedback_breakdown',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.intent_title,
      value: 'intent_title',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.potential_savings,
      value: 'potential_savings',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.realized_savings,
      value: 'realized_savings',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.relevance_breakdown,
      value: 'relevance_breakdown',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.surfaced_articles,
      value: 'surfaced_articles',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.workflow.is_active,
      value: 'is_active',
    },
  ],
};

export const SEARCH_QUERY_FILTER_DEBOUNCE_DELAY = 500;

export const STICKY_HEADER_HEIGHT = 51;

export const DONUT_SIZE = 200;

export const INSIGHT_TOOLTIP_COPY = {
  article_clicks:
    'Clicks: Number of times the user clicked on hyperlinks to access knowledge articles that surfaced. It indicates the user’s interest in learning more beyond the response.\n\n\n%: Ratio of clicks to the total number of times the knowledge article surfaced.',
  article_deflected_chats:
    'Number of chats deflected where this article surfaced.',
  article_performance_article_surfaced:
    'Total articles count increases as articles appear during a conversation session, even if the same article is shown multiple times.',
  article_surfaced:
    'The number of times the chatbot offered articles from the knowledge base in response to a user’s query. If multiple articles are used in a single conversation session, each instance will be counted separately.',
  article_user_feedback:
    'Direct feedback received from users about the article’s usefulness when it surfaced.',
  articles_avg_csat:
    'The average Customer Satisfaction (CSAT) scores from conversations.',
  articles_deflected_chats:
    'Number of chats deflected where this article surfaced.',
  articles_related_articles:
    'Additional articles that are linked to when this article is surfaced.',
  articles_surfaced_and_click:
    'Surfaced: The number of times the chatbot offered articles from the knowledge base in response to a user’s query. If multiple articles are used in a single conversation session, each instance will be counted separately.',
  articles_top_workflow_topics:
    'The workflows or topics that initiated the use of this article.',
  articles_user_feedback:
    'Direct feedback received from users about the article’s usefulness when it surfaced.',
  chats_detected_topic:
    'Topics that have been detected in the selected conversation.',
  chats_feedback:
    'Direct feedback received from users about the article’s usefulness when it surfaced.',
  chats_interactions:
    'Count of user interactions with the chatbot (inquiries, replies, and clicks on bot responses).',
  chats_surfaced_articles:
    'List of articles surfaced in selected conversation.',
  chats_workflows:
    'Workflows that have been detected in the selected conversation.',
  engagement:
    'User engagement uses AI to determine whether the user remained engaged through the conversation or dropped off mid-way through the conversation for deflected chats.Ratings may take up to 3 hours to be available after the conversation ends. Non-deflected chats do not receive a rating.',
  interactive_email:
    'The number and percentage of conversations where users clicked on the embedded interactive email widget button in email responses.',
  relevance:
    'Relevance Rating uses AI to evaluate how well responses address the inquiry in deflected chats. Ratings may take up to 3 hours to be available after the conversation ends. Non-deflected chats do not receive a rating.',
  topics_article_surfaced:
    'The number of times knowledge articles are surfaced in chats where the conversation topics were detected.',
  topics_csat:
    'The average CSAT scores of chats in which the conversation topics were detected.',
  topics_full_resolution_time:
    'Time your agents could have saved every year by preventing all the projected non-deflections.',
};

export const CHAT_TRENDS_TOOLTIP_COPY = {
  chat: '',
  csat: 'Average CSAT of all chats.',
  deflection_rate: '',
  engagement: 'engagement',
  ka_surfaced: 'Total number of knowledge articles surfaced.',
  quick_feedback:
    'Direct feedback received from users about the article’s usefulness when it surfaced.',
  relevance: 'relevance',
};

export const FEEDBACK_TOOLTIP_COPY = {
  quick_feedback:
    'Direct feedback received from users about the article’s usefulness when it surfaced.',
  user_feedback: 'Direct feedback from users on the sent email response.',
};

export const CHAT_COMPARISON_PERIOD_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
] as const satisfies { label: string; value: OverviewOvertimePeriod }[];

export const EMAIL_NO_RESPONSE_LABEL_MAPPER = {
  email_automation: 'Email automation',
  execution_error: 'Execution error',
  finished: 'Finished',
  from_widget_handoff: 'From widget handoff',
  language_not_enabled: 'Language not enabled',
  no_intent_predicted: 'No intent predicted',
  no_paraphrased_answer: 'Paraphrased answer generation failed',
  no_plausible_response: 'No plausible response',
  no_response: 'No response',
  non_email_channel: 'Non-email channel',
  out_of_office_reply: 'Out of office reply',
  responded_to_by_agent: 'Responded to by agent',
  started: 'Started',
  ticket_closed: 'Ticket closed',
  user_email_not_found: 'User email not found',
  user_rate_limited_exceeded: 'Email response limit exceeded',
  workflow_not_found: 'Workflow not found',
  zendesk_api_error: 'Zendesk API error',
  zero_dynamic_articles: 'No help article found',
  zero_multilngual_static_articles: 'Article language mismatch',
};

export const WIDGET = 'widget';
export const EMAIL = 'email';
export const VOICE = 'voice';
export const INTENT_FILTER_PARAM_NAME = 'intent_filters';
