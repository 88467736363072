import cloneDeep from 'lodash/fp/cloneDeep';
import { TopicSortFilter, TopicTimeFilter } from 'src/components/app/types';
import { MAX_TICKET_BODY_CHARS } from 'src/constants/discover';
import {
  DiscoverArticle,
  DiscoverMetricChart,
  DiscoverTicket,
  DiscoverTicketMetric,
  DiscoverTicketValue,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicTicketsMetricType,
} from 'src/reducers/discoverReducer/types';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { convertToMMDDYYYY } from 'src/utils/dateUtils';
import {
  getDisplayableTimeFromSecondsAsString,
  isSentimentTicketValue,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

export class TicketDataSorter {
  data: DiscoverTicket[];
  constructor(data: DiscoverTicket[]) {
    this.data = cloneDeep(data);
  }

  handleTicketDateSort = (sortFilterValue: TopicSortFilter) => {
    if (sortFilterValue === 'newest') {
      this.data = this.data.sort(
        (a, b) =>
          new Date(b.date_created).valueOf() -
          new Date(a.date_created).valueOf(),
      );
    }

    if (sortFilterValue === 'oldest') {
      this.data = this.data.sort(
        (a, b) =>
          new Date(a.date_created).valueOf() -
          new Date(b.date_created).valueOf(),
      );
    }

    return this;
  };

  handleTicketMetricSort = (
    sortFilterValue: TopicSortFilter,
    metricFilter:
      | DiscoverTopicAggregatedMetricType
      | DiscoverTopicTicketsMetricType,
  ) => {
    if (sortFilterValue === 'highest') {
      this.handleTicketMetricSortHighest(metricFilter);
    }

    if (sortFilterValue === 'lowest') {
      this.handleTicketMetricSortLowest(metricFilter);
    }

    return this;
  };

  handleTicketMetricSortHighest = (
    metricFilter:
      | DiscoverTopicAggregatedMetricType
      | DiscoverTopicTicketsMetricType,
  ) => {
    this.data.sort((a, b) => {
      const foundMetricA = a.metrics.find(({ name }) => name === metricFilter);
      const foundMetricB = b.metrics.find(({ name }) => name === metricFilter);

      return Number(foundMetricB?.value) - Number(foundMetricA?.value);
    });
  };

  handleTicketMetricSortLowest = (
    metricFilter:
      | DiscoverTopicAggregatedMetricType
      | DiscoverTopicTicketsMetricType,
  ) => {
    this.data.sort((a, b) => {
      const foundMetricA = a.metrics.find(({ name }) => name === metricFilter);
      const foundMetricB = b.metrics.find(({ name }) => name === metricFilter);

      return Number(foundMetricA?.value) - Number(foundMetricB?.value);
    });
  };
}

export const formatDateForTicketCard = (
  timestamp: number,
  hideTime = false,
) => {
  const date = new Date(timestamp);
  const dateString = date
    .toString()
    .split(' ')
    .slice(1, 4)
    .map((item, idx) => (idx === 1 ? item + ',' : item))
    .join(' ');
  const timeString = !hideTime ? date.toLocaleTimeString() : '';
  return `${dateString} ${timeString}`;
};

export const formatTicketBodyForTicketDetailModal = (
  body: string,
  shouldShowFullBody: boolean,
) => {
  if (shouldShowFullBody) {
    return body;
  }

  return body.slice(0, MAX_TICKET_BODY_CHARS) + '...';
};

export const isTicketBodyOverMaxLength = (body: string) =>
  body.length > MAX_TICKET_BODY_CHARS;

export const getFirstSentenceAndRest = (body: string) => {
  const [firstSentence, ...rest] = body.split('.');

  return [`${firstSentence}.`, rest.join('.')] as const;
};

export const getMetricFromMetrics = (
  metricType: (
    | DiscoverTopicTicketsMetricType
    | DiscoverTopicAggregatedMetricType
  )[],
  metrics?: DiscoverTicketMetric[],
) => {
  const map = new Map<
    DiscoverTopicTicketsMetricType | DiscoverTopicAggregatedMetricType,
    DiscoverTicketMetric | undefined
  >();

  metricType.forEach(type => {
    map.set(
      type,
      metrics?.find(({ name }) => name === type),
    );
  });

  return map;
};

export const getTopicMetricDevianceMessage = (
  deviance: number | null | undefined,
) => {
  if (!deviance) {
    return '';
  }

  const devianceValue = Number(deviance.toFixed(1));

  return devianceValue > 0
    ? `${devianceValue}x more than topic average`
    : `${Math.abs(devianceValue)}x less than topic average`;
};

const formatLabelForSentence = (label: string) =>
  label.split(' ').slice(1).join(' ');

export const getTopicMetricCardTimeMessage = (
  timeFilter: TopicTimeFilter,
  chartData: DiscoverMetricChart | null,
) => {
  if (chartData?.previous_line?.length) {
    // if there is previous data, use comparison type
    if (timeFilter.isCustom) {
      const prevStartDate = convertToMMDDYYYY(
        new Date(chartData.previous_line[0].start_date),
      );
      const prevEndDate = convertToMMDDYYYY(
        new Date(
          chartData.previous_line[chartData.previous_line.length - 1].end_date,
        ),
      );
      return `compared to ${prevStartDate} - ${prevEndDate}`;
    }

    return `compared to previous ${formatLabelForSentence(timeFilter.label)}`;
  }

  return getEmptyTopicMetricCardTimeMessage(timeFilter);
};

export const getEmptyTopicMetricCardTimeMessage = (
  timeFilter: TopicTimeFilter,
) => {
  if (timeFilter.isCustom) {
    return 'No tickets from previous custom time range';
  }

  return `No tickets from previous ${formatLabelForSentence(timeFilter.label)}`;
};

export const getTopicNameFromUrl = (searchParams: string) => {
  const params = new URLSearchParams(searchParams);
  const name = params.get('name');

  return name ?? '';
};

export const getTicketCardMetric = (
  metricValue: DiscoverTicketValue,
  metricFilter: DiscoverTopicAggregatedMetricType,
) => {
  if (isSentimentTicketValue(metricValue)) {
    // sentiment not supported in ticket cards net
    return '';
  }

  if (metricValue === null) {
    return 'N/A';
  }

  if (metricFilter === 'number_of_agent_replies') {
    return metricValue;
  }

  if (metricFilter === 'first_contact_resolution') {
    return metricValue ? 'Yes' : 'No';
  }

  return getDisplayableTimeFromSecondsAsString(Number(metricValue ?? 0));
};

export const handleCreateLinkPath = (source: string | null) => {
  if (source === 'automation' || source === 'knowledge gap') {
    return Routes.DISCOVER_AUTOMATION;
  }

  if (source === 'dashboard') {
    return Routes.DISCOVER_DASHBOARD;
  }

  return Routes.DISCOVER_TOPICS;
};

export const handleCreateLinkLabel = (source: string | null) => {
  if (
    source === 'automation' ||
    source === 'dashboard' ||
    source === 'knowledge gap'
  ) {
    return capitalizeFirstLetter(source);
  }

  return 'All Topics';
};

const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const copyArticleToClipboard = async (
  article: Pick<DiscoverArticle, 'generated_title' | 'generated_body'>,
) => {
  const formattedArticle = `${article.generated_title}\n\n${article.generated_body}`;

  await copyToClipboard(formattedArticle);
};

export const checkIfBrandIsSupported = (
  isEnabled: boolean,
  data: DiscoverArticle[],
) => {
  if (!isEnabled || !data.length) {
    return false;
  }

  return data.some(article => Boolean(article.api_data_filter));
};
