import { useMemo } from 'react';
import { useTheme } from '@mui/material';

import { DateRange } from '../../discover-dashboard-page/types';
import { dateRangeToTimestamp } from '../../solve-insights/helpers';
import {
  AVG_RESPONSE_TIME_FAST,
  AVG_RESPONSE_TIME_SLOW,
  MID_RESPONSE_TIME_COLOR,
} from './constants';
import PieChartCard from './PieChartCard';
import { useGetWidgetAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';

export const AvgResponseTimeCard = ({
  dateRange,
}: {
  dateRange: DateRange;
}) => {
  const { palette } = useTheme();

  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: data, isLoading } = useGetWidgetAggregateMetricsQuery({
    channel: 'voice',
    from: timestamps.start_timestamp.toString(),
    to: timestamps.end_timestamp.toString(),
  });
  const colors = [
    palette.colors.green[500],
    MID_RESPONSE_TIME_COLOR,
    palette.colors.red[500],
  ];

  const lowResponseTime = data?.voice_aggregate?.low_response_time || 0;
  const midResponseTime = data?.voice_aggregate?.mid_response_time || 0;
  const highResponseTime = data?.voice_aggregate?.high_response_time || 0;
  const avgResponseTime = data?.voice_aggregate?.average_response_time
    ? Math.round(data?.voice_aggregate?.average_response_time * 100) / 100
    : 0;

  const donutData = useMemo(
    () => ({
      values: [
        {
          extended_label: null,
          extended_labels: [],
          label: `less than ${AVG_RESPONSE_TIME_FAST} sec`,
          value: lowResponseTime,
        },
        {
          extended_label: null,
          extended_labels: [],
          label: `${AVG_RESPONSE_TIME_FAST} - ${AVG_RESPONSE_TIME_SLOW} sec`,
          value: midResponseTime,
        },
        {
          extended_label: null,
          extended_labels: [],
          label: `greater than ${AVG_RESPONSE_TIME_SLOW} sec`,
          value: highResponseTime,
        },
      ],
    }),
    [lowResponseTime, midResponseTime, highResponseTime],
  );

  return (
    <PieChartCard
      centerSubText='avg. time'
      centerText={avgResponseTime.toString() + 's'}
      colors={colors}
      dateRange={dateRange}
      donutData={donutData}
      isLoading={isLoading}
      title='Avg. AI response time'
      tooltip='Average time it takes for the bot to respond to user query'
    />
  );
};
