import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  type MRT_TableOptions,
  MRT_VisibilityState,
} from 'material-react-table';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  Order,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams, INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetCurrentTab } from '../hooks/useGetCurrentTab';
import { useGetFilterCount } from '../hooks/useGetFilterCount';
import { useGetIntentsForFilter } from '../hooks/useGetIntentsForFilter';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { InsightPeriod, InsightSortDirection, Scope } from '../types';
import { buildArticleColumns } from './helpers';
import GlobalFilterDrawer from 'src/components/global-filter-drawer';
import {
  listCVObjectDeserialize,
  listCVObjectSerialize,
} from 'src/components/global-filter-drawer/helper';
import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';
import {
  useEmitTrackingEventCallback,
  useGetIntentsQueryWithProduct,
  useGetWorkflowTags,
  useStateParams,
} from 'src/hooks/hooks';
import {
  DEFLECTION_INSIGHT_FILTER_OPTIONS,
  getInsightsFilterOptions,
  TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES,
} from 'src/pages/intent-conversation-analytics/constants';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetArticleTableQuery } from 'src/services/insights';
import {
  InsightArticle,
  InsightArticleSortColumns,
} from 'src/services/insights/types';
import { setSelectedArticleId } from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
} from 'src/utils/discover/helpers';
import { ExportableTableType, Routes } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface ArticlesTableProps {
  dateRange: DateRange;
  handleFilterReset?: () => void;
  period?: InsightPeriod;
  scope: Scope;
  setDateRange: (dateRange: DateRange) => void;
  setPeriodicalFilter?: (period: InsightPeriod) => void;
}

const ArticlesTable = ({
  dateRange,
  handleFilterReset,
  period,
  scope,
  setDateRange,
  setPeriodicalFilter,
}: ArticlesTableProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowTags = useGetWorkflowTags();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const getCSATColor = useGetCSATColor();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  const { articleId = undefined } = useParams<'articleId'>();
  const { topicId = undefined } = useParams<'topicId'>();
  const { workflowId = undefined } = useParams<'workflowId'>();
  const tab = searchParams.get('tab') ?? undefined;
  const tableTab = searchParams.get('tableTab') ?? undefined;

  const isParentTopic = location.pathname.includes('parent');
  const [page, setPage] = useState(1);
  const { palette } = useTheme();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;

  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightArticleSortColumns,
    [],
  );

  const currentTab = useGetCurrentTab({
    articleId,
    isParentTopic,
    tab,
    tableTab,
    topicId,
    workflowId,
  });

  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: 'DESC',
      paramsName: 'article_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: '',
    paramsName: 'articles_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortColumn, setSortColumn] = useStateParams<InsightArticleSortColumns>(
    {
      deserialize: deserializeSortColumn,
      initialState: initialInsightParams.articleColumnSort,
      paramsName: 'articles_sort_column',
      serialize: String,
    },
  );
  const [channels, setChannels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_${currentTab}`,
    serialize: listSerialize,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_${currentTab}`,
    serialize: listCVObjectSerialize,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const timeFilter = dateRangeToTimeFilter(dateRange);

  // end state params
  const { setUiQuery, uiQuery } = useGetSearchQuery({
    scope,
    searchQuery,
    setSearchQuery,
    tab: currentTab,
    type: 'article',
  });

  const replaceId = (path: string, id: string) => {
    return path.replace(':articleId', id);
  };

  const handleSetSelectedArticleId = useCallback(
    (articleId: string) => dispatch(setSelectedArticleId(articleId)),
    [dispatch],
  );

  const columns = useMemo(
    () =>
      buildArticleColumns(
        palette,
        getCSATColor,
        handleSetSelectedArticleId,
        emitTrackingEventCallback,
        scope,
        navigate,
        currentTab,
        shouldShowQuickFeedbackAnalytics,
        isDeflectionInsightsEnabled,
      ),
    [
      palette,
      getCSATColor,
      handleSetSelectedArticleId,
      emitTrackingEventCallback,
      navigate,
      scope,
      currentTab,
      shouldShowQuickFeedbackAnalytics,
      isDeflectionInsightsEnabled,
    ],
  );

  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const filterCount = useGetFilterCount({
    channels,
    cvFilters,
    multiSelected,
    workflowTags,
  });

  const tableRequestParams = useMemo(
    () => ({
      article_id: articleId,
      channels: buildQueryFromMultiSelected(channels)?.channels,
      cv_filters: cvFilters,
      data_export_type: ExportableTableType.INSIGHT_ARTICLES_TABLE,
      end: backendReadyTimestamps.end_timestamp,
      is_parent_topic: isParentTopic,
      page,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      start: backendReadyTimestamps.start_timestamp,
      topic_id: topicId,
      user_query: searchQuery,
      workflow_id: workflowId,
      ...buildQueryFromMultiSelected(multiSelected),
    }),
    [
      cvFilters,
      articleId,
      backendReadyTimestamps,
      isParentTopic,
      page,
      sortColumn,
      sortDirection,
      topicId,
      workflowId,
      searchQuery,
      channels,
      multiSelected,
    ],
  );

  const {
    data: articleTableData,
    isError: isArticleTableError,
    isFetching: isArticleTableFetching,
    isLoading: isArticleTableLoading,
  } = useGetArticleTableQuery({
    ...tableRequestParams,
  });

  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);
  const showLoadingSkeleton =
    isArticleTableLoading || (page === 1 && isArticleTableFetching);

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      multiSelected.length ||
      cvFilters.length ||
      (period && period !== initialInsightParams.period) ||
      (channels.length && channels.length !== 3),
  );

  const getArticleTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const { data: intentsData } = useGetIntentsQueryWithProduct();

  // TODO: get this from query param once channels are implemented:
  const channel: ConversationChannel = 'widget';

  const intentsForFilter = useGetIntentsForFilter({ channel, intentsData });

  const filterOptions = useMemo(() => {
    const extraFilters = [];

    if (tab !== 'workflow') {
      extraFilters.push({
        label: 'All workflows',
        options: intentsForFilter,
        value: 'workflow_id',
      });
    }

    if (isDeflectionInsightsEnabled) {
      extraFilters.push(...DEFLECTION_INSIGHT_FILTER_OPTIONS);
    }

    return getInsightsFilterOptions({
      extraFilters,
    });
  }, [intentsForFilter, isDeflectionInsightsEnabled, tab]);

  const tableDataPage = articleTableData?.metadata.page ?? 0;

  const handleLoadMore = useCallback(() => {
    if (tableDataPage === page) {
      const nextPage = page + 1;
      setPage(nextPage);
      emitTrackingEventCallback('insight-table-load-change', {
        currentPage: nextPage,
        scope,
        tab: currentTab,
        table: 'article',
      });
    }
  }, [currentTab, emitTrackingEventCallback, page, scope, tableDataPage]);

  const handleResetAllFilter = useCallback(() => {
    setUiQuery('');
    setPeriodicalFilter && setPeriodicalFilter(initialInsightParams.period);
    setDateRange(initialInsightParams.date);
    setSortColumn(initialInsightParams.articleColumnSort, () =>
      setMultiSelected([], () => setCvFilters([], () => setChannels([]))),
    );
    handleFilterReset && handleFilterReset();

    emitTrackingEventCallback('insight-reset-filter', {
      scope,
      tab: currentTab,
      table: 'article',
    });
  }, [
    setPeriodicalFilter,
    handleFilterReset,
    emitTrackingEventCallback,
    scope,
    setUiQuery,
    setSortColumn,
    setDateRange,
    setChannels,
    currentTab,
    setMultiSelected,
    setCvFilters,
  ]);

  const handleSortCallback = useCallback(
    (property: keyof InsightArticle, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(
        property === 'user_feedback_positive'
          ? 'quick_feedback'
          : (property as InsightArticleSortColumns),
      );
      emitTrackingEventCallback('insight-table-sort-change', {
        column: property,
        direction: order,
        scope,
        tab: currentTab,
        table: 'article',
      });
    },
    [
      currentTab,
      emitTrackingEventCallback,
      scope,
      setSortColumn,
      setSortDirection,
    ],
  );

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    Object.fromEntries(
      initialInsightParams.articleColumns.map(column => [column, true]),
    ),
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    // Skip first render, because we need to track CHANGES only:
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    emitTrackingEventCallback('insight-visible-columns-change', {
      scope,
      tab: currentTab,
      table: 'article',
      value: Object.entries(columnVisibility)
        .filter(([, isVisible]) => isVisible)
        .map(([column]) => column),
    });
  }, [columnVisibility, currentTab, emitTrackingEventCallback, scope]);

  const tableOptions = useMemo<MRT_TableOptions<InsightArticle>>(
    () => ({
      columns: columns,
      data: articleTableData?.data ?? [],
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['title'],
        },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          navigate({
            pathname: replaceId(
              Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL,
              row.original.id,
            ),
            search: `?tab=${tab}&channels=${channels.join(',')}`,
          });
          emitTrackingEventCallback('insight-table-row-clicked', {
            id: row.original.id,
            scope,
            tab: currentTab,
            table: 'article',
          });
          // Re-fetch for the page - have tracking event
          emitTrackingEventCallback('insight-detail-initial-page', {
            scope: 'detail',
            tab: currentTab,
          });
        },
        sx: {
          cursor: 'pointer',
        },
      }),
      onColumnVisibilityChange: setColumnVisibility,
      renderTopToolbarCustomActions: () => (
        <Box display='flex' gap='8px' paddingTop='4px' width='100%'>
          <Box key={1} width='200px'>
            <SearchBar
              onChange={e => setUiQuery(e.target.value)}
              placeholder='Search article'
              size='small'
              value={uiQuery}
            />
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => {
              setDateRange(value);
              const timeStamp = dateRangeToTimestamp(value);
              emitTrackingEventCallback('insight-date-change', {
                from: timeStamp.start_timestamp,
                scope,
                tab: currentTab,
                table: 'article',
                to: timeStamp.end_timestamp,
              });
            }}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <Button
            onClick={() => setIsDrawerOpen(true)}
            size='medium'
            startIcon={<TuneIcon />}
            variant='secondary'
          >
            <Typography noWrap variant='font14Medium'>
              {filterCount ? `All filters (${filterCount})` : 'All filters'}
            </Typography>
          </Button>
          {areFiltersUsed && (
            <Button
              onClick={handleResetAllFilter}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='articles.csv'
              requestData={{
                ...tableRequestParams,
              }}
              url={getArticleTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
      state: {
        columnVisibility,
      },
    }),
    [
      articleTableData?.data,
      columns,
      columnVisibility,
      navigate,
      tab,
      emitTrackingEventCallback,
      scope,
      currentTab,
      uiQuery,
      dateRange,
      areFiltersUsed,
      getArticleTableDownloadUrl,
      setUiQuery,
      setDateRange,
      tableRequestParams,
      channels,
      filterCount,
      handleResetAllFilter,
    ],
  );

  const handleScrollToTopClick = useCallback(() => {
    emitTrackingEventCallback('insight-scroll-to-top-clicked', {
      scope: 'main',
      tab: 'article',
    });
  }, [emitTrackingEventCallback]);

  return (
    <>
      <InfiniteTable
        hasNextPage={typeof articleTableData?.metadata.next_page === 'number'}
        initialSorting={initialSorting}
        isError={isArticleTableError}
        isLoadingFirstPage={showLoadingSkeleton}
        isLoadingNextPage={isArticleTableFetching}
        onLoadMore={handleLoadMore}
        onScrollToTopClick={handleScrollToTopClick}
        onSortCallback={handleSortCallback}
        stickyHeaderHeight={TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES}
        tableOptions={tableOptions}
      />
      {isDrawerOpen && (
        <GlobalFilterDrawer
          filterType='article'
          handleReset={handleResetAllFilter}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          otherFiltersOptions={filterOptions}
          setUiQuery={setUiQuery}
          tab={currentTab}
          uiQuery={uiQuery}
        />
      )}
    </>
  );
};

export default ArticlesTable;
