import { useState } from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { IconButton } from '@forethought-technologies/forethought-elements';
import { formatContextVariables } from './utils';
import { Conversation } from 'src/services/apiInterfaces';

const CV_TRUNCATE_LENGTH = 200;

export const ContextVariables = ({
  contextVariables,
}: {
  contextVariables: Conversation['context_variables'];
}) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(true);
  if (!contextVariables) {
    return null;
  }

  const formattedCvs = formatContextVariables(contextVariables);
  const truncatedCvs =
    formattedCvs.length > CV_TRUNCATE_LENGTH
      ? formattedCvs.substring(0, CV_TRUNCATE_LENGTH - 3) + '...'
      : formattedCvs;

  return (
    <ContextVariableContainer>
      <Typography color={theme.palette.colors.grey[600]} variant='font12'>
        Context Variables
      </Typography>
      <Box alignItems='flex-start' display='flex' gap='8px'>
        <Typography
          sx={{
            wordBreak: 'break-word',
          }}
          variant='font12'
        >
          {isCollapsed ? truncatedCvs : formattedCvs}
        </Typography>

        {formattedCvs.length > CV_TRUNCATE_LENGTH && (
          <Box>
            <IconButton
              aria-label='collapse context variables'
              onClick={() => setIsCollapsed(prev => !prev)}
              variant='ghost'
            >
              {isCollapsed ? (
                <IconChevronDown size={20} />
              ) : (
                <IconChevronUp size={20} />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
    </ContextVariableContainer>
  );
};

const ContextVariableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.colors.blue[100]};
  }
`;
