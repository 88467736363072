import { theme } from '@forethought-technologies/forethought-elements';
import { numberWithCommas } from '../analyticsUtils';
import { capitalizeFirstLetter } from '../capitalizeFirstLetter';
import { formatNToPercentage } from '../formatToPercentage';
import cloneDeep from 'lodash/fp/cloneDeep';
import { processValuesToFilters } from 'src/components/analytic-filter/helper';
import { FilterOption } from 'src/components/analytic-filter/types';
import {
  DiscoverTooltipVariants,
  GroupedMultiSelectFilterOptions,
  TopicPeriodicalFilter,
  TopicSortFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import {
  DiscoverTopicTableData,
  HistogramFilters,
  MetricMultiFilterValue,
  MetricTablePossibilities,
  MetricUnit,
} from 'src/components/dashboard-pages/discover-all-topics-page/types';
import { DateRange } from 'src/components/dashboard-pages/discover-dashboard-page/types';
import DevianceTooltipContent from 'src/components/discover-tooltip/DevianceTooltipContent';
import PercentChangeTooltipContent from 'src/components/discover-tooltip/PercentChangeTooltipContent';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import {
  DATE_RANGE_SEPARATOR,
  datePickerRangeOptions,
  DISCOVER_SHARED_PARAM_NAMES,
  groupedMultiSelectFilterOptions,
  MAX_TOPIC_NAME_LENGTH,
  METRIC_FILTERS_SEPARATOR,
  metricFilterOptions,
  metricUnits,
  NUMBER_OF_BUCKETS,
  timeFilterOptions,
  UNFORMATTED_LABEL_SEPARATOR,
} from 'src/constants/discover';
import {
  ArticleThumb,
  DiscoverAllTopicsResponse,
  DiscoverArticle,
  DiscoverArticleRecommendationValue,
  DiscoverMetricDataType,
  DiscoverTicketCostInfo,
  DiscoverTicketSentimentValue,
  DiscoverTicketValue,
  DiscoverTopic,
  DiscoverTopicActionableValue,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicMetric,
  DiscoverTopicMetrics,
  DiscoverTopicMetricsTreated,
  DiscoverTopicSentimentValue,
  DiscoverTopicValue,
} from 'src/reducers/discoverReducer/types';
import { Filters } from 'src/services/apiInterfaces';
import { DiscoverErrorResponse } from 'src/types/DiscoverTypes';
import {
  convertToMMDDYYYY,
  convertToYYYYMMDD,
  getDaysBetweenTwoDates,
  isValidTimeStamp,
} from 'src/utils/dateUtils';

export const isSentimentValue = (
  value: DiscoverTopicValue,
): value is DiscoverTopicSentimentValue => {
  return value !== null && typeof value !== 'number';
};

export const isSentimentTicketValue = (
  value: DiscoverTicketValue,
): value is DiscoverTicketSentimentValue =>
  value !== null && typeof value !== 'number' && typeof value !== 'boolean';

export const getSentimentEmojiVariantFromValue = (value: number) => {
  const ranges = Array(3)
    .fill(undefined)
    .map((_, index) => (index / 3) * 100);

  if (value > ranges[2]) {
    return 'positive';
  }

  if (value > ranges[1]) {
    return 'neutral';
  }

  return 'negative';
};

export const normalizeMetric = (
  metric?: DiscoverTopicMetric | null,
): Record<MetricUnit, number | null> => {
  const value = metric?.value ?? null;

  if (isSentimentValue(value)) {
    return {
      value: value.starting_sentiment,
      value_changed: value.ending_sentiment,
      value_deviance: value.negative_change,
    };
  }

  return {
    value,
    value_changed: metric?.value_changed ?? null,
    value_deviance: metric?.value_deviance ?? null,
  };
};

const formatMetricsForMultiColumn = (
  values: Record<MetricUnit, number | null>,
  metric?: DiscoverTopicAggregatedMetricType | null,
) =>
  metric
    ? metricUnits.map(
        unit =>
          [
            `${metric}${METRIC_FILTERS_SEPARATOR}${unit}`,
            values[unit],
          ] as const,
      )
    : [];

export const numberFormatter = (num: number) => {
  const MILLION = 1000000;
  const THOUSAND = 1000;

  if (num >= THOUSAND && num < MILLION) {
    return `${Math.round(num / THOUSAND)}K`;
  }

  if (num >= MILLION) {
    return `${Math.round(num / MILLION)}M`;
  }

  return num;
};

export const deriveTopicNameFromTopic = (
  topic?: {
    topic_display_name?: string | null;
    topic_name: string;
  } | null,
) => (topic ? deriveTopicName(topic.topic_display_name, topic.topic_name) : '');

export const deriveTopicName = (
  displayName: string | null | undefined,
  name: string,
) => {
  const topicName = displayName ?? capitalizeFirstLetter(name);
  return handleRenderTopicName(topicName);
};

export const genericSerializeAndDeserialize = (str: string) => str;

export const dateRangeSerialize = (dateRange: DateRange) =>
  `${dateRange.from?.valueOf()}_${dateRange.to?.valueOf()}`;

export const dateRangeDeserialize = (str: string): DateRange => {
  const timeStamps = str.split(DATE_RANGE_SEPARATOR);
  return {
    from: new Date(Number(timeStamps[0])),
    to: new Date(Number(timeStamps[1])),
  };
};

export const queryFilterSerialize = (filters: string[]): string => {
  return JSON.stringify(filters);
};

export const queryFilterDeserialize = (str: string): string[] => {
  return JSON.parse(str);
};

export const getPercentChangeColor = (
  percentChange: number,
  metricName?: DiscoverTopicAggregatedMetricType | 'sentiment_changed',
) => {
  const isFirstContactResolution =
    metricName === 'first_contact_resolution' ||
    metricName === 'sentiment_changed';
  const green = theme.palette.colors.green[500];
  const red = theme.palette.colors.red[500];
  if (percentChange > 10) {
    return isFirstContactResolution ? green : red;
  }

  if (percentChange < -10) {
    return isFirstContactResolution ? red : green;
  }

  return theme.palette.colors.grey[600];
};

export const getPercentChangeFontVariant = (percentChange: number) => {
  if (percentChange < -10 || percentChange > 10) {
    return 'font14Bold';
  }

  return 'font14';
};

// Returns a '+' or '' depending on the number passed in
export const getNumberIndicator = (num: number | null) => {
  if (typeof num !== 'number') {
    return '';
  }
  return num > 0 ? '+' : '';
};

// return "3650" (seconds) as 1.01 hrs or edge cases to returning minutes if 0
export const getDisplayableTimeFromSecondsAsString = (
  num = 0,
  decimals = 1,
) => {
  if (!isFinite(num)) {
    return '';
  }
  if (num === 0) {
    return `${(0).toFixed(decimals)} hrs`;
  }
  const hours = getHoursFromSeconds(num, decimals);
  if (Number(hours) === 0) {
    return `${getMinutesFromSeconds(num)} mins`;
  }

  if (decimals === 1) {
    // Remove zero after point (12.0 -> 12)
    return `${Number(hours)} hrs`;
  }

  return `${hours} hrs`;
};

export const evaluateGroupFilter = (
  topic: DiscoverTopic | null,
  groupFilter: GroupedMultiSelectFilterOptions[],
) => {
  if (!topic) {
    return false;
  }
  return groupFilter.some(filter => {
    if (filter === 'hidden') {
      return topic.is_hidden;
    }

    if (filter === 'article-generated') {
      return Boolean(topic.generated_article_count);
    }

    if (filter === 'new-topics') {
      return topic.is_new_topic;
    }

    if (filter === 'bookmarked') {
      return topic.is_bookmarked;
    }

    if (filter === 'automated') {
      return topic.is_automated;
    }

    if (filter === 'recommended') {
      return topic.is_recommended && !topic.is_automated;
    }
  });
};

export class TableDataFilter {
  data: DiscoverTopicMetrics[];
  taxonomyVersion: 'V1' | 'V2';
  constructor(data: DiscoverTopicMetrics[], taxonomyVersion: 'V1' | 'V2') {
    this.data = data;
    this.taxonomyVersion = taxonomyVersion;
  }

  filterByKeyword = (searchText: string) => {
    if (!searchText.trim()) {
      return this;
    }
    if (this.taxonomyVersion === 'V1') {
      this.data = this.data.filter(({ topic }) => {
        return topic?.topic_display_name
          ? topic?.topic_display_name?.toLowerCase().includes(searchText)
          : topic?.topic_name.toLowerCase().includes(searchText) || '';
      });
    } else {
      this.data = this.data.filter(({ breakdown, topic }, index) => {
        const topicName = topic?.topic_display_name
          ? topic?.topic_display_name?.toLowerCase()
          : topic?.topic_name.toLowerCase() || '';
        if (topicName.includes(searchText)) {
          return true;
        }
        // filter out child topics that don't have the text
        // only if the parent topic doesn't have the text
        this.data[index].breakdown = breakdown?.filter(childTopic =>
          childTopic.topic?.topic_name.toLowerCase().includes(searchText),
        );
        // if all children are filtered out, filter out the parent
        const parentHasChildren = this.data[index].breakdown?.length !== 0;
        return parentHasChildren;
      });
    }

    return this;
  };

  filterByGroupFilters = (groupFilters: GroupedMultiSelectFilterOptions[]) => {
    if (this.taxonomyVersion === 'V1') {
      if (groupFilters.length) {
        this.data = this.data.filter(({ topic }) =>
          evaluateGroupFilter(topic, groupFilters),
        );
      }
    } else {
      if (groupFilters.length) {
        this.data = this.data.filter(({ breakdown }, index) => {
          this.data[index].breakdown = breakdown?.filter(childTopic =>
            evaluateGroupFilter(childTopic.topic, groupFilters),
          );
          return this.data[index].breakdown?.length !== 0;
        });
      }
    }

    return this;
  };

  filterByHidden = (shouldShowAll: boolean) => {
    if (this.taxonomyVersion === 'V1') {
      if (!shouldShowAll) {
        this.data = this.data.filter(({ topic }) => !topic?.is_hidden);
      }
    } else {
      if (!shouldShowAll) {
        this.data = this.data.filter(({ breakdown, topic }, index) => {
          this.data[index].breakdown = breakdown?.filter(childTopic => {
            return !childTopic.topic?.is_hidden;
          });
          return !topic?.is_hidden && this.data[index].breakdown?.length !== 0;
        });
      }
    }

    return this;
  };

  filterNullMongoTopic = () => {
    this.data = this.data.filter(({ topic }) => topic !== null);
    return this;
  };

  filterByHistogramFilters = (histogramFilters: HistogramFilters) => {
    if (histogramFilters) {
      for (const metricFilter in histogramFilters) {
        const histogramFilter =
          histogramFilters[metricFilter as DiscoverTopicAggregatedMetricType];
        for (const comparisonType in histogramFilter) {
          this.data = this.data.filter(({ metrics }) =>
            metrics.some(item => {
              const normalizedItem = normalizeMetric(item);
              const value = item
                ? normalizedItem[comparisonType as MetricUnit] ?? 0
                : 0;
              const metricValue =
                comparisonType === 'value'
                  ? getMetricValueFromType(metricFilter, value)
                  : value;
              const range = histogramFilter[comparisonType as MetricUnit];
              return (
                Array.isArray(range) &&
                range.length === 2 &&
                item?.name === metricFilter &&
                range[0] <= Number(metricValue) &&
                Number(metricValue) <= range[1]
              );
            }),
          );
        }
      }
    }

    return this;
  };

  arrangeForTableV2 = (): DiscoverTopicMetricsTreated[] =>
    this.data.map(rowData => {
      const volume = rowData.metrics.find(metric => metric?.name === 'volume');
      const breakdownSorted = [...(rowData.breakdown ?? [])].sort((a, b) => {
        // move - Others topics to the bottom of subtopics
        if (a.topic?.topic_name?.endsWith('- Others')) {
          return 1;
        }

        if (b.topic?.topic_name?.endsWith('- Others')) {
          return -1;
        }

        // sort by volume
        const volumeA =
          a.metrics?.find(metric => metric?.name === 'volume')?.value ?? 0;
        const volumeB =
          b.metrics?.find(metric => metric?.name === 'volume')?.value ?? 0;

        if (isSentimentValue(volumeA) || isSentimentValue(volumeB)) {
          return 0;
        }

        return volumeB - volumeA;
      });

      return {
        ...rowData,
        breakdown: breakdownSorted.map(subTopic => {
          const volume = subTopic.metrics.find(
            metric => metric?.name === 'volume',
          );

          return {
            // type assertion needed because recursive type
            ...(subTopic as DiscoverTopicMetricsTreated),
            percentOfTotal: volume?.percent_of_total ?? null,
          };
        }),
        id: rowData.topic?.topic_id || '',
        name: rowData.topic?.topic_name || '',
        percentOfTotal: volume?.percent_of_total ?? null,
      };
    });

  arrangeForTable = (): DiscoverTopicTableData[] =>
    this.data.map(({ metrics, name, topic }) => {
      const volume = metrics.find(metric => metric?.name === 'volume');
      const formattedData = Object.fromEntries(
        metrics.flatMap(metric =>
          formatMetricsForMultiColumn(normalizeMetric(metric), metric?.name),
        ),
      ) as MetricTablePossibilities;

      return {
        ...formattedData,
        id: topic?.topic_id || '',
        name: topic?.topic_name || name || '',
        percentOfTotal: volume?.percent_of_total ?? null,
        topic,
      };
    });

  arrangeForBookmarkedTable = () =>
    this.data
      .map(datum => {
        const mappedValues: Partial<
          Record<
            | DiscoverTopicAggregatedMetricType
            | `${DiscoverTopicAggregatedMetricType}valueChanged`,
            number | null
          >
        > = {};
        datum.metrics.forEach(metric => {
          if (!metric) {
            return;
          }
          const { value } = normalizeMetric(metric);
          mappedValues[metric.name] = value;
          mappedValues[`${metric.name}valueChanged`] = metric?.value_changed;
        });

        return {
          id: datum?.topic?.topic_id ?? '',
          metricData: { ...mappedValues },
          name: deriveTopicNameFromTopic(datum?.topic),
          topic: datum?.topic,
        };
      })
      .sort(
        (topicOne, topicTwo) =>
          (topicTwo.metricData.volume || 0) - (topicOne.metricData.volume || 0),
      );
}

export const getPercentageOfReturnedTickets = (
  data?: DiscoverAllTopicsResponse,
) => {
  if (!data) {
    return '0%';
  }

  const getTicketVolumeByTopic = (topic: DiscoverTopicMetrics) =>
    topic.metrics.find(metric => metric?.name === 'volume')?.value ?? 0;

  const totalTicketVolume = getTicketVolumeByTopic(data.aggregate);

  if (isSentimentValue(totalTicketVolume)) {
    return '';
  }

  const returnedTicketVolume = data.breakdown.reduce((acc, topic) => {
    const ticketVolumeOnTopic = getTicketVolumeByTopic(topic);
    if (isSentimentValue(ticketVolumeOnTopic)) {
      return 0;
    }

    return acc + ticketVolumeOnTopic;
  }, 0);

  return (returnedTicketVolume / totalTicketVolume).toLocaleString('en', {
    style: 'percent',
  });
};

export const getTooltipValueByMetricType = (
  metricType: DiscoverTooltipVariants,
) => {
  switch (metricType) {
    case 'volume':
      return 'Total number of tickets';
    case 'first_resolution_time':
      return 'Average time taken to resolve a ticket for the first time';
    case 'full_resolution_time':
      return 'Average time taken to resolve a ticket for the last time';
    case 'number_of_agent_replies':
      return 'Average number of times an agent has responded to a ticket';
    case 'reply_time':
      return 'Average time taken for an agent to respond to a ticket';
    case 'first_contact_resolution':
      return 'Average percent of time a ticket is resolved after the first contact (agent reply, widget, etc.)';
    case 'top_movers':
      return 'Topics and associated metrics that have changed at least 100% from the previous time period. Topics must also have had a minimum number of tickets in the previous time period.';
    case 'most_common_topics':
      return 'Topics with the highest volume over the selected time period';
    case 'value_deviance':
      return DevianceTooltipContent;
    case 'value_changed':
      return PercentChangeTooltipContent;
    case 'starting_sentiment':
      return 'Ticket sentiment when the ticket was created.\n\n 0 means negative sentiment\n 50 means neutral sentiment\n 100 means positive sentiment';
    case 'ending_sentiment':
      return 'Ticket sentiment on the last reply from the user before it was resolved/closed. This is same as starting sentiment when there is only one user query and no replies.\n\n 0 means negative sentiment\n 50 means neutral sentiment\n 100 means positive sentiment';
    case 'negative_change':
      return 'Number of tickets which ended with lower sentiment from where it started';
    case 'positive_change':
      return 'Number of tickets which ended with higher sentiment from where it started';
    case 'neutral_change':
      return 'Number of tickets which ended with no sentiment change from where it started';
    case 'sentiment_changed':
      return 'Ticket sentiment difference from when the ticket was created to when the ticket was resolved/closed';
    case 'sentiment':
      return 'Sentiment when the ticket is created';
    default:
      console.error(`Tooltip value for ${metricType} not supported`);
      return '';
  }
};

export const getMetricValueFromType = (metricType: string, value: number) => {
  if (
    metricType === 'first_resolution_time' ||
    metricType === 'full_resolution_time' ||
    metricType === 'reply_time'
  ) {
    return getHoursFromSeconds(value);
  }
  return value;
};

export const getHoursFromSeconds = (seconds: number, decimals = 1) =>
  (seconds / 3600).toFixed(decimals);

const getMinutesFromSeconds = (seconds: number, decimals = 1) =>
  (seconds / 60).toFixed(decimals);

export const getDisplayableDataByMetricType = ({
  dataType,
  forChart,
  forTable,
  forTooltip,
  metric,
  value,
}: {
  dataType: DiscoverMetricDataType | 'unknown';
  forChart?: boolean;
  forTable?: boolean;
  forTooltip?: boolean;
  metric: DiscoverTopicAggregatedMetricType;
  value: number | null;
}) => {
  const generateValue = (value: number) => {
    switch (dataType) {
      case 'float':
      case 'boolean':
      case 'dict':
        return value;
      case 'integer':
        return Math.round(value);
      case 'percentage':
        return `${value}%`;
      case 'seconds':
        return forTable
          ? getHoursFromSeconds(value)
          : getDisplayableTimeFromSecondsAsString(value);
      default:
        console.error(`Received unknown data type ${dataType}`);
        return value;
    }
  };

  const generatePostString = () => {
    switch (metric) {
      case 'volume':
        return forChart || forTooltip ? ' tickets' : '';
      case 'number_of_agent_replies':
        return forChart || forTooltip ? ' replies' : '';
      default:
        return '';
    }
  };

  const generatePreString = () => {
    switch (metric) {
      case 'first_contact_resolution':
        return forTable ? getNumberIndicator(value) : '';
      default:
        return '';
    }
  };

  if (typeof value !== 'number') {
    return forTooltip ? 'Not available' : 'N/A';
  }

  return generatePreString() + generateValue(value) + generatePostString();
};

export const getAdditionalDataByMetricType = ({
  name,
  percent_of_total,
}: DiscoverTopicMetric) => {
  if (name === 'volume' && percent_of_total !== null) {
    const percentOfTotal = percent_of_total.toFixed(2);
    return `(${percentOfTotal}% of total tickets)`;
  }
  return '';
};

export const getDisplayableValueChanged = (
  valueChanged: number | null,
  forTable?: boolean,
) => {
  if (typeof valueChanged !== 'number') {
    return '';
  }
  return `${(valueChanged ?? 0).toFixed(forTable ? 2 : 1)}%`;
};

export const getDisplayableValueDeviance = (deviance: number | null) => {
  if (typeof deviance !== 'number') {
    return '';
  }
  return `${Math.abs(deviance).toFixed(1)}x`;
};

export const getMetricLabel = (value: DiscoverTopicAggregatedMetricType) => {
  return metricFilterOptions.find(option => option.value === value)?.label;
};

export const getTimeLabel = (value: TopicTimeFilter) => {
  return timeFilterOptions.find(option => option.value === value.key)?.label;
};

export const isTimePeriodValid = (
  periodicFilter: TopicPeriodicalFilter,
  timeFilter: TopicTimeFilter,
) => {
  if (timeFilter.key === 'last_7_days' || timeFilter.key === 'last_14_days') {
    return periodicFilter !== 'monthly';
  }

  if (timeFilter.isCustom) {
    const days = getDaysBetweenTwoDates(
      timeFilter.value.from,
      timeFilter.value.to,
    );
    switch (periodicFilter) {
      case 'daily':
        return true;
      case 'weekly':
        return days >= 7;
      case 'monthly':
        return days >= 28;
      default:
        console.error(
          `Value for ${periodicFilter} not supported, timeFilter: ${JSON.stringify(
            timeFilter,
          )}`,
        );
        return false;
    }
  }

  return true;
};

export const dateRangeToTimeFilter = (
  dateRange: DateRange,
): TopicTimeFilter => {
  const { from, to } = dateRange;
  const result = datePickerRangeOptions.find(option => {
    // ignoring times when comparing dates
    if (
      option.value.from.toDateString() === from?.toDateString() &&
      option.value.to.toDateString() === to?.toDateString()
    ) {
      return option;
    }
  });

  if (result) {
    return {
      isCustom: false,
      key: result.key,
      label: result.label,
      value: result.value,
    };
  }
  const startDate = from ?? new Date();
  const endDate = to ?? new Date();
  return {
    isCustom: true,
    key: `${from}_${to}`,
    label: `${convertToMMDDYYYY(startDate)} - ${convertToMMDDYYYY(endDate)}`,
    value: {
      from: startDate,
      to: endDate,
    },
  };
};

export function comparatorFunction<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = a[orderBy] ?? Number.MIN_SAFE_INTEGER;
  const bValue = b[orderBy] ?? Number.MIN_SAFE_INTEGER;

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }

  return 0;
}

export const isTypedError = (
  error: unknown,
): error is DiscoverErrorResponse => {
  return (error as DiscoverErrorResponse)?.error_type !== undefined;
};

export const generateHistogramData = (
  data: DiscoverTopicMetrics[],
  metricType: string | null,
  type: MetricUnit | null,
) => {
  // get min and max
  let min = Number.MAX_SAFE_INTEGER;
  let max = Number.MIN_SAFE_INTEGER;
  data.forEach(topic => {
    topic.metrics.forEach(metric => {
      if (metric?.name === metricType && type) {
        const value = normalizeMetric(metric)[type];
        const metricValue = Number(
          type === 'value'
            ? getMetricValueFromType(metricType, value ?? 0)
            : value,
        );
        min = Math.min(min, metricValue);
        max = Math.max(max, metricValue);
      }
    });
  });
  // create buckets
  const bucketRange = (max - min) / NUMBER_OF_BUCKETS;
  let start = min;
  const range = [...Array(NUMBER_OF_BUCKETS)].map((_, idx) => {
    start = idx === 0 ? start : start + bucketRange;
    const end = start + bucketRange;
    return {
      doc_count: 0,
      from: start,
      to: end,
    };
  });
  // put topic in buckets
  data.forEach(topic => {
    topic.metrics.forEach(metric => {
      if (metric?.name === metricType && type) {
        const value = normalizeMetric(metric)[type];
        const metricValue = Number(
          type === 'value'
            ? getMetricValueFromType(metricType, value ?? 0)
            : value,
        );
        range.forEach(rangeItem => {
          if (rangeItem.from <= metricValue && metricValue <= rangeItem.to) {
            rangeItem.doc_count += 1;
          }
        });
      }
    });
  });

  return {
    max: range[range.length - 1].to,
    min,
    range,
  };
};

type Keys = keyof typeof DISCOVER_SHARED_PARAM_NAMES;
type ValuesTypes = (typeof DISCOVER_SHARED_PARAM_NAMES)[Keys];

export const overrideDiscoverSearchParams = (
  fullPath: string,
  overrides: Partial<Record<ValuesTypes, string>> = {},
) => {
  const [path, search] = fullPath.split('?');

  const mergedParams = new URLSearchParams({
    ...Object.fromEntries(new URLSearchParams(search)),
    ...overrides,
  }).toString();

  return `${path}?${mergedParams}`;
};

export const replaceIdInRoute = (path: string, topicId: string) =>
  path.replace(':topicId', topicId);

/** Query parameter validators */

export const genericParameterValidator =
  (options: readonly { label: string; value: string }[]) =>
  (parameter: string) => {
    return options.some(option => option.value === parameter);
  };

export const queryFilterParameterValidator = () => (parameter: string) => {
  // Validate filter by trying to decode and parse the object as json, then transofmr
  // into a selection object. if this fails, invalidate the param.
  try {
    const selections = JSON.parse(parameter);
    processValuesToFilters(selections, []);
  } catch {
    return false;
  }
  return true;
};

export const timeFilterParameterValidator =
  (options: readonly { label: string; value: string }[]) =>
  (parameter: string) => {
    const customTimestamps = parameter.split('_');
    const hasInvalidCustomDates = customTimestamps.some(
      timestamp => !isValidTimeStamp(timestamp),
    );
    return (
      options.some(option => option.value === parameter) ||
      !hasInvalidCustomDates
    );
  };

export const groupFiltersValidator = () => (parameter: string) => {
  const values = listDeserialize<string>(parameter);

  return values.every(value =>
    Boolean(
      groupedMultiSelectFilterOptions.find(option => option.value === value),
    ),
  );
};

export const metricFiltersValidatorV2 = () => (parameter: string) => {
  try {
    const filters =
      listDeserialize<DiscoverTopicAggregatedMetricType>(parameter);
    return filters.every(filter =>
      metricFilterOptions.find(option => option.value === filter),
    );
  } catch {
    return false;
  }
};

export const metricFiltersValidator = () => (parameter: string) => {
  try {
    const filterLabelsMap = getMetricsFilterLabelsMap();
    const filters = listDeserialize<MetricMultiFilterValue>(parameter);
    return filters.every(filter => filter in filterLabelsMap);
  } catch {
    return false;
  }
};

/** End query parameter validators */
export const addKeywordSearch = (params: string, keyword?: string) => {
  if (!keyword) {
    return params;
  }
  return `${params}&keyword_search=${keyword}`;
};

export const constructQueryParamWithTimeFilter = (
  timeFilter: TopicTimeFilter,
  keywordSearch?: string,
) => {
  const params = new URLSearchParams();

  if (timeFilter.isCustom) {
    params.append('start_date', convertToYYYYMMDD(timeFilter.value.from));
    params.append('end_date', convertToYYYYMMDD(timeFilter.value.to));
  } else {
    params.append('time_range', timeFilter.key);
  }
  if (keywordSearch) {
    params.append('keyword_search', keywordSearch);
  }
  return params.toString();
};

export const a11yDiscoverTopicDetailTabProps = (index: number) => {
  return {
    'aria-controls': `topic-detail-tabpanel-${index}`,
    id: `topic-detail-tab-${index}`,
  };
};

export const a11yDiscoverTopicDetailSubTabProps = (index: number) => {
  return {
    'aria-controls': `topic-detail-subtabpanel-${index}`,
    id: `topic-detail-subtab-${index}`,
  };
};
export const a11yDiscoverTopicDetailSubTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `topic-detail-subtab-${index}`,
    id: `topic-detail-subtabpanel-${index}`,
  };
};

const NA = 'N/A';

export const getDiscoverBannerValues = (
  values?: DiscoverTopicActionableValue | null,
  returnEmpty?: boolean,
) => {
  if (!values) {
    return {
      costValue: returnEmpty ? '' : NA,
      fullResolutionTimeValue: returnEmpty ? '' : NA,
      shortCostValue: returnEmpty ? '' : NA,
      shortVolumeValue: returnEmpty ? '' : NA,
      volumeValue: returnEmpty ? '' : NA,
    };
  }
  return {
    costValue: values.cost ? `$${numberWithCommas(values.cost)}` : NA,
    fullResolutionTimeValue: values.full_resolution_time
      ? getDisplayableTimeFromSecondsAsString(values.full_resolution_time)
      : NA,
    shortCostValue: values.cost ? `$${numberFormatter(values.cost)}` : NA,
    shortVolumeValue: values.volume ? `${numberFormatter(values.volume)}` : NA,
    volumeValue: values.volume ? `${numberWithCommas(values.volume)}` : NA,
  };
};

export const getKnowledgeGapBannerValues = (
  hasScrolled: boolean,
  values?: DiscoverArticleRecommendationValue | null,
  isV2?: boolean,
) => {
  const articlesTargetSuffix = isV2 ? ' content blocks' : ' articles';
  const articlesSuffix = hasScrolled ? articlesTargetSuffix : '';
  const ticketsSuffix = hasScrolled ? ' tickets' : '';

  return {
    articlesGenerated: values?.articles_generated
      ? `${numberWithCommas(values.articles_generated)}${articlesSuffix}`
      : NA,
    lackingArticleCoverage:
      values?.tickets_lacking_article_generation_percentage
        ? formatNToPercentage(
            values.tickets_lacking_article_generation_percentage,
          )
        : NA,
    numberOfTicketsCovered: values?.number_of_tickets_covered
      ? `${numberWithCommas(values.number_of_tickets_covered)}${ticketsSuffix}`
      : NA,
  };
};

export const getCostReductionCopy = (
  ticketCostInfo?: DiscoverTicketCostInfo | null,
): { description: string; policyTooltip?: string; tooltip?: string } => {
  const costPerTicket = ticketCostInfo?.cost_per_ticket
    ? ticketCostInfo.cost_per_ticket
    : 15;
  const costReductionDescription = DISCOVER_CARD_TEXT.costDescription.replace(
    '15',
    costPerTicket.toFixed(0),
  );
  const policyCostReductionTooltip =
    DISCOVER_CARD_TEXT.policyCostTooltip.replace(
      '15',
      costPerTicket.toFixed(0),
    );
  const costReductionTooltip = ticketCostInfo?.is_default_cost
    ? DISCOVER_CARD_TEXT.defaultCostTooltip
    : undefined;
  return {
    description: costReductionDescription,
    policyTooltip: policyCostReductionTooltip,
    tooltip: costReductionTooltip,
  };
};

export const handleRenderTopicName = (name: string) => {
  if (name.length >= MAX_TOPIC_NAME_LENGTH) {
    return name.substring(0, MAX_TOPIC_NAME_LENGTH) + '...';
  }

  return name;
};

export const listDeserialize = <T extends string>(target: string): T[] =>
  target.split(',').filter(item => item.trim() !== '') as T[];
export const listSerialize = <T extends string>(target: T[]) =>
  target.join(',');

export const deriveTableMetricFilters = (
  availableMetricFilterOptions: {
    label: string;
    value: DiscoverTopicAggregatedMetricType;
  }[],
) => {
  return availableMetricFilterOptions.map(option => ({
    ...option,
    options: (option.value === 'sentiment'
      ? ['Starting', 'Ending', 'Drop in']
      : ['Value', 'Percent change']
    ).map((prefix, index) => ({
      label: prefix,
      value:
        `${option.value}${METRIC_FILTERS_SEPARATOR}${metricUnits[index]}` as const,
    })),
  }));
};

export const getMetricsFilterLabelsMap = (): Record<
  MetricMultiFilterValue,
  string
> => {
  const tableFilters = deriveTableMetricFilters(metricFilterOptions);

  const valueLabelTupleArray = tableFilters.flatMap(option =>
    option.options.map(({ label, value }) => {
      return [
        value,
        label === 'Value'
          ? (`${option.label}${UNFORMATTED_LABEL_SEPARATOR}` as const)
          : (`${label}${UNFORMATTED_LABEL_SEPARATOR}${option.label}` as const),
      ];
    }),
  );

  return Object.fromEntries(valueLabelTupleArray);
};

export const shouldShowDataAnalyticFilter = (
  options: FilterOption[] | null,
) => {
  return options === null || Boolean(options.length);
};

export const dataFilterQueryToStringRepresentation = (filters: Filters[]) => {
  // returns filters formatted as a list of parent level filters and their selected options
  // example: "priority - high, priority - low, group - a, group - b"
  return filters
    .map(({ field, values }) =>
      values.map(value => `${field.field_name} - ${value}`).join(', '),
    )
    .join(', ');
};

const thumbSort = (thumbA: ArticleThumb | null, thumbB: ArticleThumb | null) =>
  thumbA === thumbB ? 0 : thumbA === 'up' || thumbB === 'down' ? -1 : 1;

export class ArticleDataSorter {
  data: DiscoverArticle[];
  constructor(data: DiscoverArticle[]) {
    this.data = cloneDeep(data);
  }

  handleFilterState = () => {
    this.data = this.data.filter(article => article.state !== 'dismissed');
    return this;
  };

  handleThumbSort = () => {
    this.data = this.data.sort((a, b) =>
      thumbSort(a.feedback.thumb, b.feedback.thumb),
    );
    return this;
  };

  handleArticleDateSort = (sortFilterValue: TopicSortFilter) => {
    if (sortFilterValue === 'newest') {
      this.data = this.data.sort(
        (a, b) =>
          new Date(b.article_generated_at).valueOf() -
          new Date(a.article_generated_at).valueOf(),
      );
    }

    if (sortFilterValue === 'oldest') {
      this.data = this.data.sort(
        (a, b) =>
          new Date(a.article_generated_at).valueOf() -
          new Date(b.article_generated_at).valueOf(),
      );
    }

    return this;
  };
}

export const createMetricParam = (
  metricFiltersV2: DiscoverTopicAggregatedMetricType[],
  newMetricFilter: string,
) => {
  if (!metricFiltersV2) {
    return newMetricFilter;
  }
  return [
    ...new Set(
      metricFiltersV2.concat(
        newMetricFilter as DiscoverTopicAggregatedMetricType,
      ),
    ),
  ].join(',');
};
