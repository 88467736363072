import React, { useMemo } from 'react';
import { FC } from 'react';
import { Box } from '@mui/material';

import {
  IconButton,
  SelectDropdown,
} from '@forethought-technologies/forethought-elements';
import ContextMention from '../../context-mention-input';
import {
  booleanOperators,
  CATEGORY,
  emptyOperatorOptions,
  operatorOptions,
  operatorOptionsForAvailableValues,
  VANILLA_FORUMS_DISCUSSION,
} from '../article-suggestion-settings-panel/constants';
import { PurpleIcon } from './ArticleSourceComponent';
import removeIcon from 'src/assets/images/close.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  ArticleField,
  ArticleSuggestionFilterQuery,
} from 'src/types/workflowBuilderAPITypes';

interface FilterQueryComponentProp {
  areErrorsVisible: boolean;
  articleSourceIndex: number;
  availableArticleFields: ArticleField[];
  availableArticleFieldsOption: {
    label: string;
    value: string;
  }[];
  docType: string;
  filterQuery: ArticleSuggestionFilterQuery;
  filterQueryIndex: number;
  handleDeleteFilterQuery: (filterQueryIndex: number) => void;
  handleUpdateFilterQuery: (
    filterQueryIndex: number,
    updatedQuery: ArticleSuggestionFilterQuery,
  ) => void;
  handleUpdateFilterQueryBooleanOperator: (booleanOperator: string) => void;
  isBooleanOperatorColumnVisible: boolean;
}

export const FilterQueryComponent: FC<
  React.PropsWithChildren<FilterQueryComponentProp>
> = ({
  areErrorsVisible,
  articleSourceIndex,
  availableArticleFields,
  availableArticleFieldsOption,
  docType,
  filterQuery,
  filterQueryIndex,
  handleDeleteFilterQuery,
  handleUpdateFilterQuery,
  handleUpdateFilterQueryBooleanOperator,
  isBooleanOperatorColumnVisible,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const availableFieldValues = useMemo(() => {
    return (
      availableArticleFields.find(
        articleField => articleField.field_name === filterQuery.field,
      )?.available_field_values || []
    );
  }, [availableArticleFields, filterQuery.field]);

  const availableFieldValuesOption = useMemo(() => {
    return availableFieldValues.map(valuePair => ({
      category: filterQuery.field || '',
      label: valuePair.name,
      value: valuePair.value.toString(),
    }));
  }, [availableFieldValues, filterQuery.field]);

  const hasFieldValues = availableFieldValues.length !== 0;

  const handleFormatQueryValue = (value: string) => {
    /**
     * There is a case to convert value to int
     * Category - For Vanilla Forums Discussions
     */
    const isNumberValue = !isNaN(Number(value));
    const isVanillaForumsDiscussionDocType =
      docType === VANILLA_FORUMS_DISCUSSION;
    const isFieldValueTypeAInteger =
      filterQuery.field === CATEGORY && isVanillaForumsDiscussionDocType;
    if (isNumberValue && isFieldValueTypeAInteger) {
      return Number(value);
    }

    return value;
  };

  const handleUpdateQueryField = (
    filterQueryIndex: number,
    fieldVal: string,
  ) => {
    handleUpdateFilterQuery(filterQueryIndex, {
      ...filterQuery,
      field: fieldVal,
      operator: operatorOptions[0].value,
      value: { name: '', value: '' },
    });
  };

  const handleUpdateQueryValue = (
    filterQueryIndex: number,
    valuePair: { name: string; value: string | number },
  ) => {
    handleUpdateFilterQuery(filterQueryIndex, {
      ...filterQuery,
      value: valuePair,
    });
  };

  const handleUpdateQueryOperator = (
    filterQueryIndex: number,
    operator: string,
  ) => {
    handleUpdateFilterQuery(filterQueryIndex, {
      ...filterQuery,
      operator: operator,
      ...(['is empty', 'is not empty'].includes(operator)
        ? { value: null }
        : {}),
    });
  };

  const isStringField =
    availableArticleFields?.find(
      field => field.field_name === filterQuery.field,
    )?.field_type === 'text';

  return (
    <Box display='flex' gap={1}>
      {isBooleanOperatorColumnVisible && (
        <Box minWidth={90}>
          {filterQueryIndex > 0 && (
            <SelectDropdown
              error={
                areErrorsVisible &&
                !filterQuery.boolean_operator &&
                'Choose a boolean operator'
              }
              id={`articleSource${articleSourceIndex + 1}FilterQuery${
                filterQueryIndex + 1
              }BooleanOperator`}
              onChange={e => {
                handleUpdateFilterQueryBooleanOperator(e.target.value);
              }}
              options={booleanOperators}
              value={filterQuery.boolean_operator || ''}
            />
          )}
        </Box>
      )}
      <SelectDropdown
        error={areErrorsVisible && !filterQuery.field && 'Choose a field'}
        id={`articleSource${articleSourceIndex + 1}FilterQuery${
          filterQueryIndex + 1
        }Field`}
        onChange={e => handleUpdateQueryField(filterQueryIndex, e.target.value)}
        options={availableArticleFieldsOption}
        placeholder='Article Field...'
        value={filterQuery.field || ''}
      />
      <SelectDropdown
        error={areErrorsVisible && !filterQuery.operator && 'Choose a operator'}
        id={`articleSource${articleSourceIndex + 1}FilterQuery${
          filterQueryIndex + 1
        }Operator`}
        onChange={e =>
          handleUpdateQueryOperator(filterQueryIndex, e.target.value)
        }
        options={[
          ...(hasFieldValues
            ? operatorOptions
            : operatorOptionsForAvailableValues),
          ...(isStringField ? emptyOperatorOptions : []),
        ]}
        value={filterQuery.operator || ''}
      />
      {!['is empty', 'is not empty'].includes(filterQuery.operator) &&
        (hasFieldValues ? (
          <Box minWidth='250px'>
            <ContextVariableSelectDropdown
              additionalOptions={availableFieldValuesOption}
              aria-label='Filter value'
              error={
                areErrorsVisible &&
                (filterQuery.value === null ||
                  filterQuery.value.value.toString() === '') &&
                'Choose a value'
              }
              id={`articleSource${articleSourceIndex + 1}FilterQuery${
                filterQueryIndex + 1
              }DropdownValue`}
              label={undefined}
              onChange={value => {
                const selectedLabel =
                  availableFieldValuesOption.find(item => item.value === value)
                    ?.label || '';

                const selectedCv =
                  contextVariables.find(
                    cv => `{{${cv.context_variable_id}}}` === value,
                  )?.context_variable_name || '';

                const labelName = selectedLabel || selectedCv;
                const isNumberValue = !isNaN(Number(value));
                const formattedValuePair = {
                  name: labelName,
                  value: isNumberValue ? Number(value) : value,
                };
                handleUpdateQueryValue(filterQueryIndex, formattedValuePair);
              }}
              placeholder='Filter value...'
              shouldIncludeSystemContextVariables
              shouldProvideCVIdFormatting
              value={
                filterQuery.value === null
                  ? ''
                  : filterQuery.value.value.toString()
              }
            />
          </Box>
        ) : (
          <Box minWidth='250px'>
            <ContextMention
              aria-label={`article source ${
                articleSourceIndex + 1
              }, filter query ${filterQueryIndex + 1}, text value`}
              errorMessage={
                areErrorsVisible &&
                (filterQuery.value?.value === undefined ||
                  filterQuery.value.value.toString() === '')
                  ? 'Enter a value'
                  : ''
              }
              isMatchingNewStyle={true}
              onChange={value => {
                handleUpdateQueryValue(filterQueryIndex, {
                  name: '',
                  value: handleFormatQueryValue(value),
                });
              }}
              value={
                /**
                 * Note - backend casts string false/true to boolean type on response
                 */
                filterQuery.value?.value !== undefined
                  ? filterQuery.value?.value.toString()
                  : ''
              }
            />
          </Box>
        ))}

      <IconButton
        aria-label={`delete article source ${
          articleSourceIndex + 1
        }, filter query ${filterQueryIndex + 1}`}
        onClick={() => handleDeleteFilterQuery(filterQueryIndex)}
        variant='ghost'
      >
        <PurpleIcon src={removeIcon} />
      </IconButton>
    </Box>
  );
};
