import { useState } from 'react';
import { Box } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import Spinner from 'src/components/spinner';
import { usePrompt } from 'src/hooks/usePrompt';
import { InstructionTabForm } from 'src/pages/solve-config/instruction-tab-content/InstructionTabForm';
import {
  useGetEmailSettingsQuery,
  useUpdateEmailSettingsMutation,
} from 'src/services/email-settings/emailSettingsApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export function EmailInstructionalNotePage() {
  const { data, isLoading } = useGetEmailSettingsQuery();

  return (
    <Box display='flex' flexDirection='column' gap={3} maxWidth={900} p={4}>
      <Typography variant='font24'>Instructional note</Typography>
      {isLoading ? (
        <Spinner />
      ) : (
        <InnerForm
          initialValue={data?.knowledge_configuration_prompt || ''}
          lastModifiedDate={data?.modified_date}
        />
      )}
    </Box>
  );
}

function InnerForm({
  initialValue,
  lastModifiedDate,
}: {
  initialValue: string;
  lastModifiedDate?: string | null;
}) {
  const [value, setValue] = useState(initialValue);
  const dispatch = useAppDispatch();
  const [updateEmailSettings, { isLoading }] = useUpdateEmailSettingsMutation();

  const isDirty = value !== initialValue;

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave?',
    isDirty,
  );

  return (
    <>
      <InstructionTabForm
        description={
          <Typography variant='font14'>
            The <b>instructional note for Knowledge Retrieval</b> helps the AI
            deliver engaging, relevant, and personalized responses that address
            users&apos; unique needs, guiding Solve Email to create meaningful,
            tailored interactions.
          </Typography>
        }
        onChange={setValue}
        value={value}
      />
      <Button
        disabled={!isDirty}
        fullWidth
        isLoading={isLoading}
        onClick={async () => {
          try {
            await updateEmailSettings({
              knowledge_configuration_prompt: value,
              last_modified_date: lastModifiedDate ?? null,
            }).unwrap();

            dispatch(
              setGlobalToastOptions({
                autoHideDuration: 3000,
                title: 'Instructional Note updated',
                variant: 'main',
              }),
            );
          } catch {
            dispatch(
              setGlobalToastOptions({
                subtitle:
                  'This can happen if the email settings were updated by another user. Please refresh the page and try again.',
                title: 'Something went wrong updating email settings',
                variant: 'danger',
              }),
            );
          }
        }}
        size='large'
        variant='main'
      >
        Save
      </Button>
    </>
  );
}
