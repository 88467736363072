import { useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';

export const DefaultValueInputTooltip = () => {
  const { palette } = useTheme();

  return (
    <Tooltip tooltipContent="The default CV value can be configured when the 'Pre-defined' box is checked.  Default  CV value are for passing data, not data validation. They can be passed through the widget embed script to share data. Use them carefully to maintain code quality.">
      <IconInfoCircle color={palette.colors.grey[500]} size={14} />
    </Tooltip>
  );
};
