import 'react-phone-input-2/lib/style.css';

import { useMemo, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { isPhoneNumberValid } from 'src/utils/validators';

const DefaultPhoneNumberInput = ({
  onChageError,
  onChange,
  value,
}: {
  onChageError?: (isError: boolean) => void;
  onChange: (val: string) => void;
  value: string;
}) => {
  const theme = useTheme();
  const [isEditingDefaultHandoffNumber, setIsEditingDefaultHandoffNumber] =
    useState(false);

  const defaultHandoffNumber = value;

  const isDefaultHandoffNumberValid = useMemo(() => {
    return !defaultHandoffNumber || isPhoneNumberValid(defaultHandoffNumber);
  }, [defaultHandoffNumber]);

  const palette = theme.palette;

  const handlePhoneChange = (val: string) => {
    onChange(val);
    if (onChageError) {
      onChageError(!isPhoneNumberValid(val));
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap='4px'>
      <PhoneInput
        country='us'
        enableSearch
        inputProps={{
          'aria-invalid': isDefaultHandoffNumberValid ? 'false' : 'true',
          'aria-label': 'enter default handoff number',
        }}
        inputStyle={{
          border: `1px solid ${
            isEditingDefaultHandoffNumber
              ? palette.colors.purple[500]
              : isDefaultHandoffNumberValid
              ? palette.colors.grey[500]
              : palette.colors.red[500]
          }`,
          borderRadius: '4px',
          color: palette.text.primary,
          fontSize: '14px',
          height: '40px',
          marginLeft: '40px',
          padding: '10px 12px',
          width: '-webkit-fill-available',
        }}
        onBlur={() => setIsEditingDefaultHandoffNumber(false)}
        onChange={handlePhoneChange}
        onFocus={() => setIsEditingDefaultHandoffNumber(true)}
        specialLabel=''
        value={defaultHandoffNumber || ''}
      />
      {!isDefaultHandoffNumberValid && (
        <Typography color={palette.error.main} variant='font12'>
          Invalid phone number. Leave this field empty or enter a valid phone
          number.
        </Typography>
      )}
    </Box>
  );
};

export default DefaultPhoneNumberInput;
