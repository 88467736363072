import React, {
  FocusEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Box,
  toggleButtonClasses,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  useTheme,
} from '@mui/material';
import { ToggleButton } from '@mui/material';
import { styled } from '@mui/system';
import { IconChartBar, IconChartLine, IconPencil } from '@tabler/icons-react';

import {
  Button,
  DateRangeFilterButton,
  FilterButton,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverBookmarkIcon from '../../discover-bookmark-icon/DiscoverBookmarkIcon';
import FilterButtonIcon from '../../discover-filter-button-icon/FilterButtonIcon';
import DiscoverMenuButton from '../../discover-menu-button/DiscoverMenuButton';
import DiscoverLastUpdatedAt from '../common/discover-last-updated-at';
import { DateRange } from '../discover-dashboard-page/types';
import { useGenerateBackLinkFromSource } from './hooks/useGenerateBackLinkFromSource';
import CloseRoundIcon from 'src/assets/images/close-round-icon.svg';
import hiddenWatchIcon from 'src/assets/images/hidden-eye-icon.svg';
import AnalyticsFilter from 'src/components/analytic-filter';
import { FilterOption } from 'src/components/analytic-filter/types';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import {
  datePickerRangeOptions,
  initialPeriodicalDropdownValue,
  MAX_TOPIC_NAME_LENGTH,
  periodicalFilterOptions,
} from 'src/constants/discover';
import useOrgConfig from 'src/hooks/discover/useOrgConfig';
import { usePatchTopicMutation } from 'src/hooks/discover/usePatchTopicMutation';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTopicDetailsResponse } from 'src/reducers/discoverReducer/types';
import { Filters } from 'src/services/apiInterfaces';
import { DiscoverBodyFilter } from 'src/services/discover/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import {
  handleRenderTopicName,
  isTimePeriodValid,
  shouldShowDataAnalyticFilter,
} from 'src/utils/discover/helpers';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

interface TopicDetailHeaderProps {
  analyticFilterOptions: FilterOption[] | null;
  chartType: string;
  dataFilterQuery: Filters[];
  handleDateRangeChange: (dateRange: DateRange) => void;
  handlePeriodicalChange: (
    state: TopicPeriodicalFilter,
    batchUpdater?: (() => URLSearchParams) | undefined,
  ) => URLSearchParams;
  isFilterLoading: boolean;
  isLargerScreen: boolean;
  isNewTopic: boolean;
  loading: boolean;
  periodicalFilter: TopicPeriodicalFilter;
  queryFilters: string[];
  setChartType: (chartType: string) => void;
  setQueryFilters: (selections: string[]) => void;
  timeFilter: TopicTimeFilter;
  topicDetailMetricData: DiscoverTopicDetailsResponse | null;
  topicId: string;
  topicName: string;
}

const TopicDetailHeader: React.FC<
  React.PropsWithChildren<TopicDetailHeaderProps>
> = ({
  analyticFilterOptions,
  chartType,
  dataFilterQuery,
  handleDateRangeChange,
  handlePeriodicalChange,
  isFilterLoading,
  isLargerScreen,
  isNewTopic,
  loading,
  periodicalFilter,
  queryFilters,
  setChartType,
  setQueryFilters,
  timeFilter,
  topicDetailMetricData,
  topicId,
  topicName,
}) => {
  const theme = useTheme();
  const { patchTopicMutation } = usePatchTopicMutation();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { taxonomyVersion } = useOrgConfig();

  const displayName = topicDetailMetricData?.topic?.topic_display_name;

  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState('');
  const clearTopicRenameFieldLabel = 'clear rename topic field';
  const topicNameError = name ? false : 'A topic name is required';
  const backendFriendlyFilterQuery: DiscoverBodyFilter = useMemo(() => {
    return dataFilterQuery?.length ? { filters: dataFilterQuery } : undefined;
  }, [dataFilterQuery]);

  const { label, link } = useGenerateBackLinkFromSource();

  const handleCancelNameEdit = useCallback(() => {
    setIsEditingName(false);
    setName(displayName || topicName);
  }, [displayName, topicName]);
  const isHidden = topicDetailMetricData?.topic?.is_hidden;
  useEffect(() => {
    const targetName = displayName ?? capitalizeFirstLetter(topicName);
    setName(loading ? topicName : targetName);
  }, [displayName, topicName, topicId, loading]);

  const patchTopicNameChange = () => {
    if (!name) {
      return;
    }
    const doesDraftMatchName = topicName === name;
    const draftIsDefaultValue = !displayName && doesDraftMatchName;
    const draftChanged = name !== displayName;
    if (draftChanged && !draftIsDefaultValue) {
      setName(doesDraftMatchName ? topicName : name);
      patchTopicMutation(topicId, {
        topic_display_name: doesDraftMatchName ? null : name,
      });
    }
    setIsEditingName(false);
  };

  const handleDraftKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter' && !topicNameError) {
      patchTopicNameChange();
    }
  };

  const handleBlurDraftRename = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const relatedTargetIsXButton =
      e.relatedTarget &&
      'ariaLabel' in e.relatedTarget &&
      (e.relatedTarget as HTMLElement).ariaLabel === clearTopicRenameFieldLabel;
    const relatedTargetIsCancelButton =
      e.relatedTarget &&
      'textContent' in e.relatedTarget &&
      (e.relatedTarget as HTMLElement).textContent === 'Cancel';

    if (relatedTargetIsXButton || relatedTargetIsCancelButton) {
      return;
    }
    patchTopicNameChange();
  };

  const topicHiddenButtonRender = () => {
    if (isHidden) {
      return (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.colors.slate[500],
            borderRadius: '4px',
            display: 'flex',
            gap: '4px',
            height: '28px',
            marginRight: '8px',
            paddingX: '8px',
          }}
        >
          <img src={hiddenWatchIcon} />
          <Typography color={theme.palette.colors.white} variant='font11'>
            Topic hidden
          </Typography>
        </Box>
      );
    }
  };

  const topicNameRender = () => {
    if (name.length >= MAX_TOPIC_NAME_LENGTH) {
      return (
        <Tooltip tooltipContent={name}>
          <Typography variant='font24'>
            {handleRenderTopicName(name)}
          </Typography>
        </Tooltip>
      );
    }

    return <Typography variant='font24'>{name}</Typography>;
  };

  const handleChartTypeChange = (_event: React.MouseEvent, value: string) => {
    if (!value) {
      return false;
    }
    setChartType(value);
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <LinkContainer to={link}>
          <ArrowRightAltIcon
            sx={{
              color: theme.palette.colors.purple[500],
              transform: 'rotate(180deg)',
            }}
          />
          <Typography
            color={theme.palette.colors.purple[500]}
            variant='font14Bold'
          >
            {label}
          </Typography>
        </LinkContainer>
        {!loading && topicDetailMetricData?.last_update_at && (
          <DiscoverLastUpdatedAt
            lastUpdateAt={topicDetailMetricData.last_update_at}
          />
        )}
      </Box>
      <Box
        alignItems={isLargerScreen ? 'center' : 'flex-start'}
        display='flex'
        flexDirection={isLargerScreen ? 'row' : 'column'}
        justifyContent='space-between'
      >
        <Box
          alignItems='center'
          display='flex'
          // Fix spacing issues with edit mode vs normal
          sx={{ pt: isEditingName ? 0 : '9px' }}
        >
          {topicHiddenButtonRender()}
          <DiscoverNewTopicBadge isNewTopic={isNewTopic} margin='0 9px 0 0' />
          {topicName ? (
            isEditingName ? (
              <Box
                mb={'-22px'}
                sx={{
                  '> .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input':
                    {
                      ...theme.typography.font24,
                      paddingRight: 0,
                    },
                }}
              >
                <TextField
                  aria-label='edit topic name'
                  autoFocus={true}
                  description='Press enter to save'
                  endAdornment={
                    <ClearDraftIconButton
                      aria-label={clearTopicRenameFieldLabel}
                    >
                      <ClearDraftIcon
                        onClick={() => setName('')}
                        src={CloseRoundIcon}
                      />
                    </ClearDraftIconButton>
                  }
                  error={topicNameError}
                  onBlur={handleBlurDraftRename}
                  onChange={e => setName(e.target.value)}
                  onKeyDown={handleDraftKeyDown}
                  value={name}
                />
              </Box>
            ) : (
              topicNameRender()
            )
          ) : (
            <Typography variant='font24'>
              <i>N/A</i>
            </Typography>
          )}
          {!loading && topicDetailMetricData && (
            <>
              {isEditingName ? (
                <Button onClick={handleCancelNameEdit} variant='ghost'>
                  Cancel
                </Button>
              ) : (
                <Box ml={1.25}>
                  <Tooltip tooltipContent='Rename topic'>
                    <Box
                      sx={{
                        '&:hover': {
                          '* > svg': {
                            color: theme.palette.colors.purple[500],
                          },
                        },
                        '* > svg': {
                          color: theme.palette.colors.grey[500],
                        },
                      }}
                    >
                      <IconButton
                        aria-label='rename topic'
                        onClick={() => setIsEditingName(true)}
                        variant='ghost'
                      >
                        <IconPencil size='20px' />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {topicDetailMetricData.topic?.is_automated && (
                <Box alignItems='center' display='flex' ml={1.25}>
                  <DiscoverAutomatedBadge />
                </Box>
              )}
              {taxonomyVersion !== 'V2' && (
                <Box
                  data-appcues-target={getAppCuesId({
                    componentType: 'button',
                    featureName: 'header',
                    pageName: 'topicdetail',
                    subType: 'bookmark',
                  })}
                  ml={1.25}
                >
                  <DiscoverBookmarkIcon
                    isBookmarked={topicDetailMetricData?.topic?.is_bookmarked}
                    onClick={() =>
                      patchTopicMutation(topicId, {
                        is_bookmarked:
                          !topicDetailMetricData.topic?.is_bookmarked,
                      })
                    }
                  />
                </Box>
              )}
              {topicDetailMetricData.topic && (
                <Box ml={1.25}>
                  <DiscoverMenuButton
                    backendFriendlyFilterQuery={backendFriendlyFilterQuery}
                    dateRange={timeFilter.value}
                    topic={topicDetailMetricData?.topic}
                    variant='topic_detail'
                  />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          mt={isLargerScreen ? 0 : 3}
        >
          <DateRangeFilterButton
            data-appcues-target={getAppCuesId({
              componentType: 'button',
              featureName: 'filters',
              pageName: 'topicdetail',
              subType: 'daterange',
            })}
            initialValue={last30DaysTimeRange}
            onChange={handleDateRangeChange}
            options={datePickerRangeOptions}
            value={timeFilter.value}
          />
          <FilterButton
            aria-label='time period filter'
            data-appcues-target={getAppCuesId({
              componentType: 'tabcontainers',
              featureName: 'charts',
              pageName: 'topicdetail',
              subType: '',
            })}
            disabledTooltipMessage='Time period not valid for selected time range'
            initialValue={initialPeriodicalDropdownValue}
            onChange={value => {
              emitTrackingEventCallback('discover-time-comparison-selected', {
                page: 'Topic view',
                'time-comparison': value,
                topic: topicDetailMetricData?.topic?.topic_name,
              });

              handlePeriodicalChange(value as TopicPeriodicalFilter);
            }}
            options={periodicalFilterOptions.map(option => {
              const isDisabled = !isTimePeriodValid(option.value, timeFilter);
              return { ...option, disabled: isDisabled };
            })}
            startAdornment={<FilterButtonIcon dropdownType='time-period' />}
            value={periodicalFilter}
          />
          {shouldShowDataAnalyticFilter(analyticFilterOptions) && (
            <AnalyticsFilter
              filters={queryFilters}
              handleFilterUpdate={setQueryFilters}
              isLoading={isFilterLoading}
              options={analyticFilterOptions ?? []}
              page='topicDetails'
            />
          )}
          <StyledToggleButtonGroup
            aria-label='chart type'
            exclusive
            onChange={handleChartTypeChange}
            size='small'
            value={chartType}
          >
            <Tooltip tooltipContent='Line'>
              <ToggleButton aria-label='line' size='small' value='spline'>
                <IconChartLine size={16} width={26} />
              </ToggleButton>
            </Tooltip>
            <Tooltip tooltipContent='Bar'>
              <ToggleButton aria-label='bar' size='small' value='column'>
                <IconChartBar size={16} width={26} />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export default TopicDetailHeader;

const LinkContainer = styled(Link)`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 25px;
  text-decoration: none;
`;

const ClearDraftIconButton = styled('button')`
  padding: 0;
  height: 24px;
`;

const ClearDraftIcon = styled('img')`
  cursor: pointer;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.colors.slate[100],
  borderRadius: theme.shape.borderRadius,
  columnGap: theme.spacing(0.3),
  display: 'flex',
  height: 32,
  overflow: 'hidden',
  padding: theme.spacing(0.3),
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    height: 28,
    padding: 0,
    width: 40,
    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: 'white',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    },
  },
}));
