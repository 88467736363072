import { type SetOptional } from 'type-fest';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AddActionData,
  ArticleStepData,
  AttachmentAnalysisUploadStepData,
  ButtonsStepData,
  ConditionStepData,
  CsatTriggerPointStepData,
  EmbedStepData,
  FormStepData,
  GoToIntentData,
  GroupInsertionData,
  ImageStepData,
  RestartStepData,
  TextMessageData,
} from './types/dataTypes';
import {
  CanvasWorkflowBuilderState,
  startAddingAction,
} from './workflowBuilderSlice';
import { getSelectedTransitionId } from './workflowBuilderSlice.helpers';
import {
  getActionParentStepId,
  getActualParentStepId,
} from './workflowBuilderSlice.thunks.helpers';
import { createContextVariableID } from 'src/pages/action-builder/context-variables-settings/helpers';
import { getTransitionsFromConfig } from 'src/pages/workflow-builder-edit/conditions/conditionEditorHelpers';
import { CompositeConditionsConfig } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  selectChatOrder,
  selectChatOrderLastStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { getArticleSuggestionActionKeyId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import { dashboardApi } from 'src/services/dashboard-api';
import {
  addActionApi,
  addStepToWorkflow,
  deleteWorkflowActionAPI,
  deleteWorkflowStepAndBelowAPI,
  deleteWorkflowStepAPI,
  discardDraftWorkflowAPI,
  getActionSettingsAPI,
  getDraftWorkflow,
  getHandoffCustomizationAPI,
  getIntentAPI,
  getLatestWorkflowVersionsAPI,
  getOnboardingFlagsAPI,
  getWorkflowVersionAPI,
  insertGroupOfStepsAPI,
  saveWorkflowVersionApi,
  updateActionSettingsAPI,
  updateHandoffCustomizationAPI,
  updateIntentAPI,
  updateOnboardingFlagsAPI,
  updateWorkflowStepAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
import { RootState } from 'src/store/rootReducer';
import { Action } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderState } from 'src/types/actionBuilderReducerTypes';
import {
  ActionSettingsCustomization,
  ButtonOption,
  CsatTriggerPointData,
  FormData,
  MessageStepData,
  Phrase,
  Step,
  UpdateOnboardingFlagsRequest,
  ZendeskHandoffCustomization,
  ZendeskTicketCreationCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { getArticleSuggestionActionId } from 'src/utils/articleSuggestionUtils';
import {
  articleWasHelpfulCase,
  articleWasNotHelpfulCase,
} from 'src/utils/constants';
import { ActionBuilderActionTypes, StepTypes } from 'src/utils/enums';

export const loadDraftWorkflow = createAsyncThunk(
  'canvasWorkflowBuilder/loadDraftWorkflow',
  async (intentWorkflowId: string) => {
    return await getDraftWorkflow(intentWorkflowId);
  },
);

export const getLatestWorkflowVersions = createAsyncThunk(
  'canvasWorkflowBuilder/getLatestWorkflowVersions',
  async (intentWorkflowId: string) => {
    return await getLatestWorkflowVersionsAPI(intentWorkflowId);
  },
);

export const getWorkflowVersion = createAsyncThunk(
  'canvasWorkflowBuilder/getWorkflowVersion',
  async (workflowAndVersion: { intentWorkflowId: string; version: number }) => {
    return await getWorkflowVersionAPI(
      workflowAndVersion.intentWorkflowId,
      workflowAndVersion.version,
    );
  },
);

export const getIntent = createAsyncThunk(
  'canvasWorkflowBuilder/getIntent',
  async (intentId: string) => {
    return await getIntentAPI(intentId);
  },
);

export const addAction = createAsyncThunk(
  'canvasWorkflowBuilder/addAction',
  async (
    action: { data: Omit<AddActionData, 'is_entry_step' | 'parent_step_id'> },
    { getState },
  ) => {
    const state = getState() as RootState;

    const data: AddActionData = {
      ...action.data,
      is_entry_step:
        state.canvasWorkflowBuilder.shouldSqueezeIntoEntry ||
        !selectChatOrder(state).length,
      parent_step_id: state.canvasWorkflowBuilder.isSqueezingStep
        ? state.canvasWorkflowBuilder.squeezeStepParentId
        : selectChatOrderLastStepId(state),
    };

    const lastStepId = selectChatOrderLastStepId(getState() as RootState);

    let parentStepId;
    if (data.is_entry_step) {
      parentStepId = null;
    } else {
      parentStepId = data.parent_step_id || lastStepId;
    }

    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    let parent_transition_id;
    if (
      parentStepId &&
      ((state.canvasWorkflowBuilder.steps[parentStepId]?.transitions?.length ||
        0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvasWorkflowBuilder.canvas_action_id_to_action_component,
        ))
    ) {
      parent_transition_id = getSelectedTransitionId(
        state.canvasWorkflowBuilder,
        parentStepId as string,
      );
    }

    return await addActionApi(
      state.canvasWorkflowBuilder.intent_workflow_id ?? '',
      state.canvasWorkflowBuilder.version,
      {
        ...data,
        parent_step_id: parentStepId,
        parent_transition_id,
      },
    );
  },
);

export const addTextMessage = createAsyncThunk(
  'canvasWorkflowBuilder/addTextMessage',
  async (stepData: MessageStepData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);
    let parentStepId;
    if (stepData.isEntryStep) {
      parentStepId = null;
    } else {
      parentStepId = stepData.parentStepId || lastStepId;
    }
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);
    const data: TextMessageData = {
      is_entry_step: stepData.isEntryStep ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        message: stepData.message,
        show_link_preview: stepData.showLinkPreview,
      },
      step_type: StepTypes.TEXT_MESSAGE,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addButtonsStep = createAsyncThunk(
  'canvasWorkflowBuilder/addButtonsStep',
  async (
    buttonData: {
      hide_free_form_query?: boolean;
      isEntryStep?: boolean;
      options: ButtonOption[];
      parentStepId?: string;
      prompt: string;
    },
    { getState },
  ) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;
    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);
    let parentStepId;
    if (buttonData.isEntryStep) {
      parentStepId = null;
    } else {
      parentStepId = buttonData.parentStepId || lastStepId;
    }
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);
    const outputVariable = createContextVariableID();

    const { hide_free_form_query, options, prompt } = buttonData;

    const data: ButtonsStepData = {
      is_entry_step: buttonData.isEntryStep ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        hide_free_form_query,
        multi_select: false,
        options,
        output_variable: outputVariable,
        prompt,
      },
      step_transitions: options.map(option => ({
        condition_expression: {
          expression_type: 'filter',
          field: outputVariable,
          negate: false,
          operator: '=',
          values: [option.value],
        },
        step_id: null,
      })),
      step_type: StepTypes.BUTTONS,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addAttachmentAnalysisUploadStep = createAsyncThunk(
  'canvasWorkflowBuilder/addAttachmentAnalysisUploadStep',
  async (
    params: {
      allow_skip_attachment: boolean;
      isEntryStep?: boolean;
      num_files: number;
      output_variable: string;
      parentStepId?: string;
      prompt: string;
    },
    { getState },
  ) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;
    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);
    let parentStepId;
    if (params.isEntryStep) {
      parentStepId = null;
    } else {
      parentStepId = params.parentStepId || lastStepId;
    }
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);
    const { allow_skip_attachment, num_files, output_variable, prompt } =
      params;

    const data: AttachmentAnalysisUploadStepData = {
      is_entry_step: params.isEntryStep ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        allow_skip_attachment,
        num_files,
        output_variable,
        prompt,
      },
      step_type: StepTypes.ATTACHMENT_ANALYSIS_UPLOAD,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addFormStep = createAsyncThunk(
  'canvasWorkflowBuilder/addFormStep',
  async (formData: FormData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);

    let parentStepId;
    if (formData.isEntryStep) {
      parentStepId = null;
    } else {
      parentStepId = formData.parentStepId || lastStepId;
    }
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    const { formFields, prompt } = formData;
    const data: FormStepData = {
      is_entry_step: formData.isEntryStep ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        form_fields: formFields,
        hide_prepopulated_fields: formData.shouldHidePrepopulatedFields,
        pre_populate_fields: true,
        prompt,
      },
      step_type: StepTypes.FORM,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addCsatTriggerPointStep = createAsyncThunk(
  'canvasWorkflowBuilder/addCsatTriggerPointStep',
  async (stepData: CsatTriggerPointData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);
    let parentStepId;
    if (stepData.isEntryStep) {
      parentStepId = null;
    } else {
      parentStepId = stepData.parentStepId || lastStepId;
    }
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    const data: CsatTriggerPointStepData = {
      is_entry_step: stepData.isEntryStep ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        delay: stepData.delay,
        negative_rating_routing_intent: stepData.negative_rating_routing_intent,
        should_use_delay: stepData.should_use_delay,
      },
      step_type: StepTypes.CSAT_TRIGGER_POINT,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addCondition = createAsyncThunk(
  'canvasWorkflowBuilder/addCondition',
  async (
    conditionData: {
      compositeConditionsConfig: CompositeConditionsConfig;
      shouldSqueezeIntoEntry?: boolean;
      squeezeStepParentId?: string;
    },
    { getState },
  ) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);
    const transitions = getTransitionsFromConfig(
      conditionData.compositeConditionsConfig,
    );
    const isEntryStep =
      Boolean(conditionData.shouldSqueezeIntoEntry) || !chatOrder.length;
    let parentStepId = null;

    if (!isEntryStep) {
      parentStepId = conditionData.squeezeStepParentId ?? lastStepId;
      // parentStepId could be an entry step to an action, in that case we will need to go into
      // the steps within the action to find out the actual parent step id
      parentStepId = getActualParentStepId(parentStepId);
    }

    if (conditionData.squeezeStepParentId && parentStepId && transitions[0]) {
      // If user is squeezing a condition we want to add the step below to the transition of the first condition.
      transitions[0].step_id = chatOrder[chatOrder.indexOf(parentStepId) + 1];
    }
    let parentTransitionId;
    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      parentTransitionId = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    const data: ConditionStepData = {
      is_entry_step: isEntryStep,
      parent_step_id: parentStepId,
      parent_transition_id: parentTransitionId,
      step_fields: {
        condition_name: conditionData.compositeConditionsConfig.conditionName,
      },
      step_transitions: transitions,
      step_type: StepTypes.CONDITION,
    };

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addGoToIntentStep = createAsyncThunk(
  'canvasWorkflowBuilder/addGoToIntent',
  async (
    intentData: GoToIntentData | RestartStepData,
    { dispatch, getState },
  ) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;
    let parentStepId = intentData.parent_step_id;
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      intentData.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    dispatch(dashboardApi.util.invalidateTags(['IntentUsages']));

    return await addStepToWorkflow(
      state.intent_workflow_id as string,
      state.version,
      {
        ...intentData,
        parent_step_id: parentStepId,
      },
    );
  },
);

export const saveWorkflow = createAsyncThunk(
  'canvasWorkflowBuilder/saveWorkflow',
  async (
    { intentWorkflowId = '' }: { intentWorkflowId?: string },
    { getState },
  ) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    return await saveWorkflowVersionApi({
      intentWorkflowId: state.intent_workflow_id ?? intentWorkflowId,
      version: state.version,
    });
  },
);

export const discardDraftWorkflow = createAsyncThunk(
  'canvasWorkflowBuilder/discardDraftWorkflow',
  async (intentWorkflowId: string, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;
    return await discardDraftWorkflowAPI(
      state.intent_workflow_id ?? intentWorkflowId,
      state.version,
    );
  },
);

export const updateWorkflowStep = createAsyncThunk(
  'canvasWorkflowBuilder/updateWorkflowStep',
  async (
    stepData: {
      condition_name: Step['condition_name'];
      step_fields: Step['step_fields'];
      stepId: string;
      transitions: Step['transitions'];
    },
    { getState },
  ) => {
    const { condition_name, step_fields, stepId, transitions } = stepData;
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    return await updateWorkflowStepAPI({
      data: {
        condition_name,
        step_fields: step_fields,
        transitions: transitions,
      },
      intentWorkflowId: state.intent_workflow_id ?? '',
      stepId: stepId,
      version: state.version,
    });
  },
);

export const insertGroupOfSteps = createAsyncThunk(
  'canvasWorkflowBuilder/insertGroupOfSteps',
  async ({ intentTitle: _, ...rest }: GroupInsertionData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    return await insertGroupOfStepsAPI({
      data: rest,
      intentWorkflowId: state.intent_workflow_id ?? '',
      version: state.version,
    });
  },
);

export const deleteWorkflowStep = createAsyncThunk(
  'canvasWorkflowBuilder/deleteWorkflowStep',
  async (stepId: string, { dispatch, getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    if (state.steps[stepId]?.step_type === StepTypes.GO_TO_INTENT) {
      dispatch(dashboardApi.util.invalidateTags(['IntentUsages']));
    }

    return await deleteWorkflowStepAPI(
      state.intent_workflow_id ?? '',
      state.version,
      stepId,
    );
  },
);

export const deleteWorkflowStepAndBelow = createAsyncThunk(
  'canvasWorkflowBuilder/deleteWorkflowStepAndBelow',
  async (
    { canvasActionId, stepId }: { canvasActionId?: string; stepId?: string },
    { dispatch, getState },
  ) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    if (stepId && state.steps[stepId]?.step_type === StepTypes.GO_TO_INTENT) {
      dispatch(dashboardApi.util.invalidateTags(['IntentUsages']));
    }

    return await deleteWorkflowStepAndBelowAPI(
      state.intent_workflow_id ?? '',
      state.version,
      stepId,
      canvasActionId,
    );
  },
);

export const deleteWorkflowAction = createAsyncThunk(
  'canvasWorkflowBuilder/deleteWorkflowAction',
  async (actionId: string, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    return await deleteWorkflowActionAPI(
      state.intent_workflow_id ?? '',
      state.version,
      actionId,
    );
  },
);

export const getHandoffCustomization = createAsyncThunk(
  'canvasWorkflowBuilder/getHandoffCustomization',
  async (actionType: ActionBuilderActionTypes, { getState }) => {
    const workflowId = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder.intent_workflow_id;

    return await getHandoffCustomizationAPI(workflowId || '', actionType);
  },
);

export const getActionSettings = createAsyncThunk(
  'canvasWorkflowBuilder/getActionSettings',
  async (actionId: string, { getState }) => {
    const canvasWorkflowBuilder = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    return await getActionSettingsAPI(
      canvasWorkflowBuilder.intent_workflow_id ?? '',
      actionId,
      canvasWorkflowBuilder.version,
    );
  },
);

export const updateHandoffCustomization = createAsyncThunk(
  'canvasWorkflowBuilder/updateHandoffCustomization',
  async (
    handoffData:
      | ZendeskHandoffCustomization
      | ZendeskTicketCreationCustomization,
    { getState },
  ) => {
    const workflowId = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder.intent_workflow_id;
    return await updateHandoffCustomizationAPI(handoffData, workflowId || '');
  },
);

export const updateActionSettings = createAsyncThunk(
  'canvasWorkflowBuilder/updateActionSettings',
  async (handoffData: ActionSettingsCustomization, { getState }) => {
    // Canvas state getter
    const canvasWorkflowBuilder = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;
    // Action builder state getter
    const actionBuilder = (getState() as { actionBuilder: ActionBuilderState })
      .actionBuilder;
    const customizableActionId = actionBuilder.customizableActionId;
    // Const
    const chatOrder = selectChatOrder(getState() as RootState);
    const entryStepId =
      canvasWorkflowBuilder.canvas_action_id_to_action_component[
        customizableActionId
      ].entry_step_id;
    const isEntryStep =
      !chatOrder.length ||
      canvasWorkflowBuilder.canvas_action_id_to_action_component[
        customizableActionId
      ].entry_step_id === chatOrder[0];
    let parentStepId = '';

    switch (handoffData.action_type) {
      case ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF:
      case ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2:
      case ActionBuilderActionTypes.ZENDESK_HANDOFF:
      case ActionBuilderActionTypes.ZENDESK_TICKET_CREATION:
      case ActionBuilderActionTypes.FRONT_TICKET_CREATION:
      case ActionBuilderActionTypes.ZAMMAD_TICKET_CREATION:
      case ActionBuilderActionTypes.SALESFORCE_HANDOFF:
      case ActionBuilderActionTypes.SALESFORCE_CASE_CREATION:
      case ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF:
      case ActionBuilderActionTypes.CATCH_ALL_HANDOFF:
      case ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST:
      case ActionBuilderActionTypes.INTERCOM_HANDOFF:
      case ActionBuilderActionTypes.HYPERLINK_REDIRECT:
      case ActionBuilderActionTypes.KUSTOMER_HANDOFF:
      case ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF:
      case ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION:
      case ActionBuilderActionTypes.TRIGGER_EVENT:
      case ActionBuilderActionTypes.FRESHDESK_TICKET_CREATION:
      case ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION:
      case ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR:
      case ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF:
      case ActionBuilderActionTypes.ZENDESK_ATTACHMENT_UPLOAD:
      case ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF:
      case ActionBuilderActionTypes.TALKDESK_CHAT_HANDOFF:
      case ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF:
      case ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF:
      case ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF:
      case ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING:
      case ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF:
      case ActionBuilderActionTypes.PARSE_JWT:
      case ActionBuilderActionTypes.SET_CONTEXT_VARIABLE:
      case ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF:
      case ActionBuilderActionTypes.SUNCO_LIVE_CHAT:
      case ActionBuilderActionTypes.DYNAMIC_CARD:
      case ActionBuilderActionTypes.END_INTERACTIVE_EMAIL_CHAT:
      case ActionBuilderActionTypes.INTERCOM_TICKET_CREATION:
        const chatOrderIdxOfAction = chatOrder.indexOf(entryStepId);

        // If action is first step, then there is no parent step id.
        if (chatOrderIdxOfAction === 0) {
          break;
        }

        // We make a distinction between the chatOrder parent and a step's parent.
        // Example: chat order may show [id_1, id_2]. But id_1 might represent
        // the entry_step of an action, in which case the true parent of id_2
        // depends on the internal steps of the action.
        const chatOrderParentStepId = chatOrder[chatOrderIdxOfAction - 1] ?? '';
        const articleSuggestionActionId = getArticleSuggestionActionKeyId(
          canvasWorkflowBuilder.canvas_action_id_to_action_component,
          actionBuilder.actions,
          chatOrderParentStepId,
        );

        const isParentStepIdArticleAction =
          articleSuggestionActionId.length > 0;

        //If parent step is article suggestion, get correct parent step id according case selected
        if (isParentStepIdArticleAction) {
          const isSuccessCase =
            canvasWorkflowBuilder.actionCaseMap[chatOrderParentStepId];

          const transitionBranches =
            canvasWorkflowBuilder.canvas_action_id_to_action_component[
              articleSuggestionActionId
            ]?.transition_branches;

          parentStepId =
            transitionBranches?.find(transitionBranch =>
              isSuccessCase
                ? transitionBranch.display_name === articleWasHelpfulCase
                : transitionBranch.display_name === articleWasNotHelpfulCase,
            )?.parent_step_id || '';
          // We need to make sure there are no other kinds of actions that can serve as the parent, where
          // the action has multiple internal steps.
          // Once we've ruled out article suggestion: we can deduce the following.
          // 1) Parent action can't be terminal (by definition). So we rule out all the handoff actions
          // 2) If the parent is a hyperlink redirection, it also only has one internal step.
          // 3) All custom API actions configured by the user can only have one step.

          // Note: This is really brittle! The moment we introduce a new action with multiple steps, we would
          // need to add more code logic here.
        } else {
          parentStepId = chatOrderParentStepId;
        }
        break;
      case ActionBuilderActionTypes.ARTICLE_SUGGESTION:
        parentStepId = getActionParentStepId();
        break;
    }

    return await updateActionSettingsAPI(
      canvasWorkflowBuilder.intent_workflow_id ?? '',
      customizableActionId,
      handoffData,
      canvasWorkflowBuilder.version,
      isEntryStep,
      parentStepId,
    );
  },
);

export const updateIntent = createAsyncThunk(
  'canvasWorkflowBuilder/updateIntent',
  async ({
    description,
    intent_id,
    name,
    phrases,
  }: {
    description?: string;
    intent_id: string;
    name: string;
    phrases: Phrase[];
  }) => {
    return await updateIntentAPI({ description, intent_id, name, phrases });
  },
);

export const addArticleStep = createAsyncThunk(
  'canvasWorkflowBuilder/addArticleStep',
  async (stepData: ArticleStepData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);

    let parentStepId = stepData.parent_step_id;
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    const isEntryStep = stepData.is_entry_step ?? !chatOrder.length;

    const data: ArticleStepData = {
      is_entry_step: isEntryStep,
      parent_step_id: isEntryStep ? undefined : parentStepId,
      step_fields: {
        doc_type: stepData.step_fields.doc_type,
        document_id: stepData.step_fields.document_id,
        document_title: stepData.step_fields.document_title,
        read_article_action: stepData.step_fields.read_article_action,
        standardized_document_id: stepData.step_fields.standardized_document_id,
      },
      step_type: stepData.step_type,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const getOnboardingFlags = createAsyncThunk(
  'canvasWorkflowBuilder/getOnboardingFlags',
  async () => {
    return await getOnboardingFlagsAPI();
  },
);

export const updateOnboardingFlags = createAsyncThunk(
  'canvasWorkflowBuilder/updateOnboardingFlags',
  async (onboardingFlags: UpdateOnboardingFlagsRequest) => {
    return await updateOnboardingFlagsAPI(onboardingFlags);
  },
);

export const addImageStep = createAsyncThunk(
  'canvasWorkflowBuilder/addImageStep',
  async (stepData: ImageStepData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);

    let parentStepId;
    if (stepData.is_entry_step) {
      parentStepId = null;
    } else {
      parentStepId = stepData.parent_step_id || lastStepId;
    }

    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    const data: ImageStepData = {
      is_entry_step: stepData.is_entry_step ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        images: stepData.step_fields.images,
      },
      step_type: stepData.step_type,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

export const addEmbedStep = createAsyncThunk(
  'canvasWorkflowBuilder/addEmbedStep',
  async (stepData: EmbedStepData, { getState }) => {
    const state = (
      getState() as { canvasWorkflowBuilder: CanvasWorkflowBuilderState }
    ).canvasWorkflowBuilder;

    const chatOrder = selectChatOrder(getState() as RootState);
    const lastStepId = selectChatOrderLastStepId(getState() as RootState);

    let parentStepId;
    if (stepData.is_entry_step) {
      parentStepId = null;
    } else {
      parentStepId = stepData.parent_step_id || lastStepId;
    }
    // parentStepId could be an entry step to an action, in that case we will need to go into
    // the steps within the action to find out the actual parent step id
    parentStepId = getActualParentStepId(parentStepId);

    const data: EmbedStepData = {
      is_entry_step: stepData.is_entry_step ?? !chatOrder.length,
      parent_step_id: parentStepId,
      step_fields: {
        embed_url: stepData.step_fields.embed_url,
      },
      step_type: stepData.step_type,
    };

    if (
      parentStepId &&
      ((state.steps[parentStepId]?.transitions?.length || 0) > 1 ||
        getArticleSuggestionActionId(
          parentStepId,
          state.canvas_action_id_to_action_component,
        ))
    ) {
      data.parent_transition_id = getSelectedTransitionId(
        state,
        parentStepId as string,
      );
    }

    return await addStepToWorkflow(
      state.intent_workflow_id ?? '',
      state.version,
      data,
    );
  },
);

/**
 * This thunk doesn't do any async work.
 * It is needed only for accessing the root state.
 */
export const startAddingActionThunk = createAsyncThunk(
  'canvasWorkflowBuilder/startAddingActionThunk',
  async (
    data: {
      action: Action;
      data: SetOptional<Omit<AddActionData, 'parent_step_id'>, 'is_entry_step'>;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    dispatch(
      startAddingAction({
        ...data,
        data: {
          ...data.data,
          is_entry_step:
            data.data.is_entry_step ??
            (state.canvasWorkflowBuilder.shouldSqueezeIntoEntry ||
              !selectChatOrder(state).length),
          parent_step_id: state.canvasWorkflowBuilder.isSqueezingStep
            ? state.canvasWorkflowBuilder.squeezeStepParentId
            : selectChatOrderLastStepId(state),
        },
      }),
    );
  },
);
