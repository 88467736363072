import { Box, Dialog, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import removeIcon from 'src/assets/images/close.svg';

interface UnansweredDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const UnansweredDialog = ({ isOpen, onClose }: UnansweredDialogProps) => {
  const { palette } = useTheme();

  return (
    <Dialog hideBackdrop onClose={onClose} open={isOpen}>
      <Box
        display='flex'
        flexDirection='column'
        gap={3}
        height='100%'
        maxWidth='560px'
        padding={3}
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography variant='font16Bold'>No response reason</Typography>
          <img
            onClick={onClose}
            src={removeIcon}
            style={{ cursor: 'pointer', height: '12px', width: '12px' }}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gap={3}
          maxHeight='500px'
          overflow='auto'
        >
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>No response</Typography>
            <Typography variant='font14'>
              The workflow was configured to not send a response.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>Email automation</Typography>
            <Typography variant='font14'>
              A rule within our configuration states we should not respond to
              the ticket/case.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>Non-email Channel</Typography>
            <Typography variant='font14'>
              The ticket/case is from a channel we do not support with Solve
              Email.  We currently only support Email, Form, and API channels.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>Responded to by agent</Typography>
            <Typography variant='font14'>
              An agent responded to the ticket/case before Forethought
              attempted.  This is likely due to an email automation rule that
              delays Forethought&apos;s ability to attempt.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>
              Email response limit exceeded
            </Typography>
            <Typography variant='font14'>
              The system stops responding to users who send more than 5 emails
              within 30 minutes.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>No help article found</Typography>
            <Typography variant='font14'>
              No relevant articles were found to answer the user&apos;s query.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>Ticket closed</Typography>
            <Typography variant='font14'>
              The ticket/case was closed before Forethought was attempted.  This
              is likely due to an email automation rule that delays
              Forethought&apos;s ability to attempt.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>From widget handoff</Typography>
            <Typography variant='font14'>
              Ticket ticket/case was created by Solve Widget.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>No intent predicted</Typography>
            <Typography variant='font14'>
              We could not predict an active intent on your ticket/case, and you
              do not have Knowledge Retrieval (Other Questions) activated.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>Execution error</Typography>
            <Typography variant='font14'>
              An error occurred while executing the workflow or journey map. The
              most common causes are issues with an Action API call or a missing
              Context Variable. Additionally, this error may result from the LLM
              vendor&apos;s performance or a server issue.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>Language not enabled</Typography>
            <Typography variant='font14'>
              The language detected for the user&apos;s request is not selected
              OR is not enabled in the translation configuration.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>No plausible response</Typography>
            <Typography variant='font14'>
              The system checks if there is an article or workflow suitable for
              addressing the user&apos;s query. The plausibility check can be
              modified by the Forethought team.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>
              Paraphrased answer generation failed
            </Typography>
            <Typography variant='font14'>
              OpenAI was unable to generate a paraphrased response.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography variant='font14Bold'>
              Article language mismatch
            </Typography>
            <Typography variant='font14'>
              The articles embedded in the email templates are in a different
              language than the one detected in the email conversation.
            </Typography>
          </Box>
        </Box>
        <Box borderBottom={`1px solid ${palette.colors.slate[300]}`} />
        <Box alignSelf='flex-end' width='103px'>
          <Button fullWidth onClick={onClose} variant='main'>
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default UnansweredDialog;
