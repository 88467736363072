import { TrackingEventType } from '../types';
import {
  BASE_LOOKER_PATH,
  BETA_BADGE_LABEL,
  INTERNAL_BADGE_LABEL,
  LOOKER_TAB,
  NEW_BADGE_LABEL,
} from './constants';
import navData from './navData';
import { NavDataItem, NavDataItemWithChildren } from './types';
import { WidgetMode } from 'src/pages/workflow-builder-edit/constants';
import { EmbeddableLookerDashboard } from 'src/services/apiInterfaces';
import { DashboardPermissions } from 'src/services/dashboard-api/types';
import { Treatments } from 'src/slices/experiments/types';
import { Permission, Routes, UserRoles } from 'src/utils/enums';

type RoutesUnion = `${Routes}`;

export const triageUsageMeterRoutes: RoutesUnion[] = [
  '/triage-insights',
  '/triage-settings',
];

export const solveLiteUsageMeterRoutes: RoutesUnion[] = ['/solve-lite'];

const notificationButtonRoutes: RoutesUnion[] = ['/discover/dashboard'];

export const isNotificationButtonRoute = (path: string) =>
  notificationButtonRoutes.some(route => path.startsWith(route));

export const doesNotificationButtonRouteMeetCondition = (
  path: string,
): boolean => {
  if (path.startsWith(Routes.DISCOVER_DASHBOARD)) {
    return true;
  }

  return false;
};

export const getWorkflowNavbarMessage = ({
  isAutoflowWorkflow,
  mode,
}: {
  isAutoflowWorkflow: boolean;
  mode: WidgetMode;
}) => {
  if (isAutoflowWorkflow && mode === 'classic') {
    return 'This workflow is already published and active with AutoFlow mode';
  }

  if (!isAutoflowWorkflow && mode === 'autoflow') {
    return 'This workflow is already published and active with Classic mode';
  }

  return '';
};

const emitTrackingEventRoutes: RoutesUnion[] = [
  '/triage-insights',
  '/triage-settings',
];

export const isEmitTrackingEventRoute = (path?: string) =>
  emitTrackingEventRoutes.some(route => path?.startsWith(route));

export const trackingEventRouteMapping: Record<string, TrackingEventType> = {
  '/triage-insights': 'triage-clicked-on-insights-tab',
  '/triage-settings': 'triage-clicked-on-models-tab',
};

export const getTabNameFromPath = (pathname: string) => {
  for (const { children, name, route } of navData) {
    if (!children && route?.path === pathname) {
      return name;
    }

    const currentRoute = children?.find(
      ({ route }) => route?.path === pathname,
    );

    if (currentRoute) {
      return currentRoute.name;
    }
  }

  // first word after / and use spaces instead of dashes to improve formatting
  return pathname.split('/')[1]?.replace('-', ' ') ?? '';
};

// To disable/enable a tab based on an experiment assignment, place it here followed by the experiment name
// and the assignment that should result in the tab being visible.
// Ex:   [Routes.DISCOVER_AUTOMATION]: {
//         'discover-recommendation-service-enabled': 'enabled',
//       },
// will only show discover_automation if the experiment has 'enabled' as the assignment.
const experimentRouteMap: Record<string, Treatments> = {};

export const isRouteExperimentEnabled = (
  route: string | undefined,
  treatments: Treatments,
): boolean => {
  // If the route doesn't have a key in the experiment map, let it pass freely.
  if (route && route in experimentRouteMap) {
    const routeExperiments = experimentRouteMap[route];
    let isEnabled = true;
    // If we find that an experiment doesn't have a treatment that matches the desired assignment,
    // mark this route as disabled.
    Object.entries(treatments).forEach(
      ([experimentName, desiredAssignment]) => {
        if (
          routeExperiments[experimentName as keyof Treatments] !==
          desiredAssignment
        ) {
          isEnabled = false;
        }
      },
    );
    return isEnabled;
  }
  return true;
};

export const processBadgedNavItems =
  (activeBadgedRoutes: string[]) => (navItem: NavDataItemWithChildren) => {
    // Check if this nav item or any of its children should have a
    // 'new' badge next to them
    if (navItem.children) {
      navItem.children.forEach(navItemChild => {
        if (
          activeBadgedRoutes.find(route => navItemChild.route?.path === route)
        ) {
          navItemChild.badgeLabel = NEW_BADGE_LABEL;
        }
      });
    } else if (
      activeBadgedRoutes.find(route => navItem?.route?.path === route)
    ) {
      navItem.badgeLabel = NEW_BADGE_LABEL;
    }
    return navItem;
  };

/**
 * Given a list of Looker dashboards, construct links in the format '/analytics-poc/:dashboardId'
 * If there is more that one dashboard in the list, child nav item links will use the 'title' property for link text
 */
const constructAnalyticsTab = (
  embeddedDashboardSettings: EmbeddableLookerDashboard[],
): NavDataItemWithChildren | null => {
  if (!embeddedDashboardSettings.length) {
    return null;
  }

  // There is only one dashboard set in org config, so construct the route for the top-level analytics nav item
  if (embeddedDashboardSettings.length === 1) {
    return {
      ...LOOKER_TAB,
      route: {
        ...LOOKER_TAB.route,
        path:
          BASE_LOOKER_PATH + embeddedDashboardSettings[0].looker_dashboard_id,
      },
    };
  }

  // embeddedDashboardSettings > 1, so add nav links as children to main analytics nav item
  const children = embeddedDashboardSettings.map(
    ({ looker_dashboard_id, title }) => ({
      name: title,
      route: {
        path: BASE_LOOKER_PATH + looker_dashboard_id,
        permissionKey: Permission.LOOKER_ANALYTICS,
      },
    }),
  );

  return {
    badgeLabel: LOOKER_TAB.badgeLabel,
    children,
    icon: LOOKER_TAB.icon,
    name: LOOKER_TAB.name,
  };
};

export const insertDynamicAnalyticsTab = (
  tabs: NavDataItemWithChildren[],
  embeddedDashboardSettings: EmbeddableLookerDashboard[],
) => {
  const analyticsTab = constructAnalyticsTab(embeddedDashboardSettings);

  if (analyticsTab && tabs.length) {
    // Insert analytics tab at second to last index
    return [
      ...tabs.slice(0, tabs.length - 1),
      analyticsTab,
      tabs[tabs.length - 1],
    ].filter(Boolean);
  }

  return tabs;
};

const handleBadgeProcessing = (
  navItem: NavDataItemWithChildren,
  isSuperAdmin: boolean,
  permissions: DashboardPermissions,
) => {
  const currentPermission = navItem.route?.permissionKey || '';
  const permissionData = permissions[currentPermission];

  if (!permissionData) {
    return;
  }

  if (isSuperAdmin && permissionData.is_super_admin_only) {
    navItem.badgeLabel = INTERNAL_BADGE_LABEL;
  } else if (permissionData.is_beta) {
    navItem.badgeLabel = BETA_BADGE_LABEL;
  }
};

export const processBadges =
  (
    permissions: DashboardPermissions,
    role: UserRoles,
    notificationsToNavItem: Record<string, number>,
  ) =>
  (navItem: NavDataItemWithChildren) => {
    const isSuperAdmin = role === UserRoles.ROLE_SUPER_ADMIN;
    if (navItem.children) {
      navItem.children.forEach(navItemChild => {
        handleNotificationBadge(navItemChild, notificationsToNavItem);
        handleBadgeProcessing(navItemChild, isSuperAdmin, permissions);
      });
    } else {
      handleBadgeProcessing(navItem, isSuperAdmin, permissions);
    }
    return navItem;
  };

export const handleNotificationBadge = (
  navItemChild: NavDataItem,
  notificationsToNavItem: Record<string, number>,
) => {
  const tabName = navItemChild.name;
  if (
    tabName in notificationsToNavItem &&
    notificationsToNavItem[tabName] > 0
  ) {
    navItemChild.notificationsLabel =
      notificationsToNavItem[tabName].toString();
  }
};
