import { useCallback, useMemo, useState } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';

import {
  Button,
  InfiniteTable,
  Order,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateCell } from '../audit/DateCell';
import { sortColumnsValidator } from '../error/utils';
import useTabs from 'src/components/dashboard-pages/solve-insights/hooks/useTabs';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import ConversationDrawer from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { openTab } from 'src/pages/intent-conversation-analytics/helpers';
import { useGetConversationQuery } from 'src/services/insights';
import { useGetSlowVoiceConversationsQuery } from 'src/services/latency/latencyApi';
import {
  SlowVoiceConversationRow,
  SortColumn,
  SortDirection,
} from 'src/types/latencyTypes';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const LatencyPage = () => {
  //state
  const [page, setPage] = useState(1);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >('');
  const [sortDirection, setSortDirection] = useStateParams<SortDirection>({
    deserialize: (param: string) => param as SortDirection,
    initialState: 'desc',
    paramsName: 'sort_direction',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortColumn, setSortColumn] = useStateParams<SortColumn>({
    deserialize: (param: string) => param as SortColumn,
    initialState: 'timestamp',
    paramsName: 'sort_column',
    serialize: genericSerializeAndDeserialize,
    validator: sortColumnsValidator(),
  });

  const {
    data: conversationData,
    isFetching: isFetchingConversation,
    isLoading: isLoadingConversation,
  } = useGetConversationQuery(
    { conversation_id: selectedConversationId || '' },
    {
      skip: !selectedConversationId,
    },
  );

  const selectedConversation = useMemo(() => {
    if (!selectedConversationId || !conversationData) {
      return;
    }
    return conversationData;
  }, [conversationData, selectedConversationId]);

  //hooks
  const theme = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  // get the index of the Solve Insights Conversations tab
  const { tabIdToIndexMap } = useTabs();
  const conversationsTabIndex = tabIdToIndexMap.chat;

  const {
    data: slowConversationResponse,
    isError: isError,
    isFetching: isFetching,
    isLoading: isLoading,
  } = useGetSlowVoiceConversationsQuery({
    page,
    page_size: '100',
    sort_column: sortColumn,
    sort_direction: sortDirection,
  });

  const showLoadingSkeleton = isLoading || (page === 1 && isFetching);

  const columnHelper = createMRTColumnHelper<SlowVoiceConversationRow>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('timestamp', {
        Cell: p => {
          const formattedDate = p.cell.getValue().replace(' UTC', '');
          return <DateCell dateString={formattedDate + 'Z'} />;
        },
        grow: false,
        header: 'Timestamp',
        size: 230,
      }),
      columnHelper.accessor('conversation_id', {
        enableSorting: false,
        grow: false,
        header: 'Conversation Id',
        size: 380,
      }),
      columnHelper.accessor('avg_response_time', {
        Cell: p => {
          return <Box>{Math.round(p.cell.getValue() * 100) / 100}s</Box>;
        },
        enableSorting: false,
        grow: true,
        header: 'Avg. AI response time  ',
        minSize: 360,
      }),
      columnHelper.accessor('conversation_id', {
        Cell: p => {
          return (
            <Button
              onClick={e => {
                const url = `${
                  Routes.SOLVE_INSIGHTS_MAIN
                }?tab=${conversationsTabIndex}&product_mode=voice&chats_search=${p.cell.getValue()}`;
                openTab(url);
                e.stopPropagation();
              }}
              variant='ghost'
            >
              Go to insights
            </Button>
          );
        },
        enableSorting: false,
        grow: false,
        header: 'Conversation Id',
        size: 380,
      }),
    ];
  }, [columnHelper, conversationsTabIndex]);

  //handlers
  const handleLoadMore = useCallback(
    () => setPage(prevPage => prevPage + 1),
    [],
  );

  const handleSortCallback = useCallback(
    (property: keyof SlowVoiceConversationRow, order: Order) => {
      setPage(1);
      setSortColumn(property as SortColumn, () => setSortDirection(order));
    },
    [setSortColumn, setSortDirection],
  );

  return (
    <>
      <Box
        sx={{
          background: 'white',
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          gap: '26px',
          padding: '16px 40px',
        }}
      >
        <Box alignItems='center' display='flex' gap='3px'>
          <Typography color={theme.palette.colors.grey[600]} variant='font16'>
            Latency logs are stored for 30 days. If latency exceeds the average
            threshold of 5 seconds, the conversation will be logged for review.
          </Typography>
        </Box>
        <Box flex='1'>
          <InfiniteTable
            hasNextPage={
              !!slowConversationResponse?.metadata.more_records_to_fetch
            }
            initialSorting={[
              {
                desc: sortDirection === 'desc',
                id: sortColumn,
              },
            ]}
            isError={isError}
            isLoadingFirstPage={showLoadingSkeleton}
            isLoadingNextPage={isFetching}
            onLoadMore={handleLoadMore}
            onSortCallback={handleSortCallback}
            stickyHeaderHeight={229}
            tableOptions={{
              columns: columns,
              data: slowConversationResponse?.conversations || [],
              enableColumnActions: false,
              enableColumnFilters: false,
              enableExpanding: false,
              enableFullScreenToggle: false,
              enableGlobalFilter: false,
              enableRowActions: false,
              muiTableBodyRowProps: ({ row }) => ({
                onClick: () => {
                  setSelectedConversationId(row.original.conversation_id);
                },
                sx: {
                  cursor: 'pointer',
                },
              }),
            }}
          />
        </Box>
      </Box>
      <ConversationDrawer
        isLoading={isLoadingConversation || isFetchingConversation}
        onClose={() => {
          setSelectedConversationId(null);
          emitTrackingEventCallback('latency-page', {
            id: selectedConversation?.conversation_id,
          });
        }}
        openEventTrigger={() => {
          emitTrackingEventCallback('latency-page', {
            id: selectedConversation?.conversation_id,
          });
        }}
        selectedConversation={selectedConversation}
      />
    </>
  );
};

export default LatencyPage;
