import { useEffect } from 'react';

import TabLayout from '../tab-layout';
import AssistWrapper from './assist-wrapper';
import { PLAYBOOK_TABS } from './constants';
import GeneratedReply from './generated-reply';
import { getTitle } from './helpers';
import { PlaybookTabKeys } from './types';
import WidgetContent from './WidgetContent';
import WidgetPreview from './WidgetPreview';
import { useStateParams } from 'src/hooks/hooks';
import { getPlaybookConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

const PlaybookTab = () => {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useStateParams<PlaybookTabKeys>({
    deserialize: value => value as PlaybookTabKeys,
    initialState: 'knowledge',
    paramsName: 'tab',
    serialize: value => value,
  });

  useEffect(() => {
    dispatch(getPlaybookConfiguration());
  }, [dispatch]);

  const isToneTab = selectedTab === 'tone-of-voice';
  const isQuickFeedbackTab = selectedTab === 'quick-feedback';
  const isKnowledgeTab = selectedTab === 'knowledge';
  const isInstructionalNoteTab = selectedTab === 'instructional-note';
  const isFallbackTab = selectedTab === 'fallback';

  const title = getTitle(selectedTab);

  const preview = <WidgetPreview widgetType={selectedTab} />;

  return (
    <TabLayout
      configWidget={<WidgetContent widgetType={selectedTab} />}
      navigationItems={PLAYBOOK_TABS}
      onTabChange={setSelectedTab}
      selectedTab={selectedTab}
      title={title}
    >
      {isToneTab && <GeneratedReply preview={preview} />}
      {isQuickFeedbackTab && <AssistWrapper preview={preview} />}
      {isInstructionalNoteTab && <AssistWrapper preview={preview} />}
      {isFallbackTab && <AssistWrapper preview={preview} />}
      {isKnowledgeTab && preview}
    </TabLayout>
  );
};

export default PlaybookTab;
