import { useEffect } from 'react';
import { ToolbarContainer } from '..';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import { VerticalTabBar } from '@forethought-technologies/forethought-elements';
import { SOLVE_VOICE_CONFIG_TABS } from '../constants';
import FallbackWidgetContent from '../fallback-tab-content/FallbackWidgetContent';
import { useGetVoiceTabIndex } from '../hooks/useGetTabIndex';
import KnowledgeWidgetContent from '../knowledge-tab-content/KnowledgeWidgetContent';
import StaticSolveWidget from '../static-solve-widget/StaticSolveWidget';
import { Channel } from '../types';
import { getSolveVoiceConfigTabs } from '../utils';
import { RecordingTabMockContent } from './recording-disclaimer-tab-content/RecordingTabMockContent';
import { VoiceTabMockContent } from './voice-tab-content/VoiceTabMockContent';
import { VoiceLHSMockContent } from './VoiceMockContent';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import {
  getBoughtPhoneNumbers,
  selectIsValidationFailing,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

export default function VoiceTab({ channel }: { channel: Channel }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('tab') ?? '';

  const voiceTabIndex = useGetVoiceTabIndex();
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const isFallbackWorkflowEnabled =
    useIsFeatureFlagEnabled('fallback_workflow');

  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const filteredTabs = getSolveVoiceConfigTabs({ isFallbackWorkflowEnabled });

  useEffect(() => {
    dispatch(getBoughtPhoneNumbers());
  }, [dispatch]);

  const handleTabOnClick = (index: number) => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    const tab = SOLVE_VOICE_CONFIG_TABS[index].id;
    navigate(
      {
        pathname: Routes.SOLVE_CONFIGURATION,
        search: `?channel=${channel}&tab=${tab}`,
      },
      { replace: true },
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.TAB_NAVIGATION, {
      tab: tab,
    });
  };

  const handleRenderMockContentBasedOnTabType = () => {
    switch (query) {
      case 'voice':
        return <VoiceTabMockContent />;
      case 'phone':
        return (
          <VoiceLHSMockContent headerText='Configure phone numbers and voice configurations using the menu on the right' />
        );
      case 'recording_and_disclaimer':
        return <RecordingTabMockContent />;
      case 'knowledge':
        return (
          <StaticSolveWidget type='minimized'>
            <KnowledgeWidgetContent />
          </StaticSolveWidget>
        );
      case 'fallback':
        return (
          <StaticSolveWidget type='minimized'>
            <FallbackWidgetContent />
          </StaticSolveWidget>
        );
      default:
        return <VoiceLHSMockContent headerText='Configure your Solve Voice!' />;
    }
  };

  return (
    <>
      <VoiceConfigContentContainer>
        {handleRenderMockContentBasedOnTabType()}
      </VoiceConfigContentContainer>
      <ToolbarContainer
        isFullScreen={false}
        isTabSelected={typeof voiceTabIndex === 'number'}
      >
        <VerticalTabBar
          setTabIndex={handleTabOnClick}
          tabBodyWidth={520}
          tabIndex={voiceTabIndex}
          tabs={filteredTabs}
        />
      </ToolbarContainer>
    </>
  );
}

const VoiceConfigContentContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.palette.colors.slate[100]};
  padding: 16px;
  gap: 16px;
  align-items: center;
`;
