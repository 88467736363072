import '../chart.scss';

import ReactDOMServer from 'react-dom/server';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

import { ExtendedSeriesPieOptions } from '../types';
import { DonutGraphDataType } from 'src/services/insights/types';

// Numbers will have commas
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

interface DonutGraphProps {
  centerElement?: JSX.Element;
  centerSubText?: string;
  centerText?: string;
  colors?: string[];
  containerProps?: { [key: string]: { [key: string]: string } };
  data: DonutGraphDataType;
  disableTooltip?: boolean;
}

const DonutGraph = ({
  centerElement,
  centerSubText,
  centerText,
  colors,
  containerProps,
  data,
  disableTooltip,
}: DonutGraphProps) => {
  const theme = useTheme();
  const { palette } = theme;

  const constructDonutGraphOptions = (
    transformedData: ExtendedSeriesPieOptions[],
  ) => {
    const getSubtitle = () => {
      const { name, y } = transformedData[0];
      const subtitleComponent = (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <p
            style={{
              color: palette.colors.black,
              fontFamily: theme.typography.fontFamily,
              fontSize: 32,
              fontWeight: 600,
              margin: 0,
              padding: 0,
            }}
          >
            {centerText
              ? centerText
              : y.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  style: 'percent',
                })}
          </p>
          <p
            style={{
              color: palette.colors.black,
              fontFamily: theme.typography.fontFamily,
              fontSize: 14,
              fontWeight: 400,
              margin: 0,
              padding: 0,
              whiteSpace: 'pre-wrap',
            }}
          >
            {centerSubText ? centerSubText : name}
          </p>
          {centerElement && centerElement}
        </div>
      );
      return ReactDOMServer.renderToString(subtitleComponent);
    };

    return {
      chart: {
        type: 'pie',
      },
      colors: colors,
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: { allowPointSelect: false, borderWidth: 0.5 },
        series: {
          borderRadius: 0,
          borderWidth: 0,
          colorByPoint: true,
          dataLabels: {
            enabled: false,
          },
          innerSize: '80%',
          size: '100%',
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          type: 'pie',
        },
      },
      series: [
        {
          data: transformedData,
        },
      ],
      subtitle: {
        floating: true,
        text: getSubtitle(),
        useHTML: true,
        verticalAlign: 'middle',
      },
      title: undefined,
      tooltip: {
        enabled: !disableTooltip,
      },
    };
  };

  const handleDataTransformation = (): ExtendedSeriesPieOptions[] => {
    return data.values.map(value => {
      return { name: value.label, type: 'pie', y: value.value };
    });
  };

  const transformData = handleDataTransformation();
  const options = constructDonutGraphOptions(transformData);

  return (
    <HighchartsReact
      containerProps={containerProps}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default DonutGraph;
