import { IndicatorStep } from './IndicatorStep';
import { GotoWorkflowTranscriptComponent } from './types';

export const GotoWorkflow = ({
  transcriptComponent,
}: {
  transcriptComponent: GotoWorkflowTranscriptComponent;
}) => {
  const title = transcriptComponent.intent_title
    ? transcriptComponent.intent_title
    : transcriptComponent.intent_workflow_id;

  return <IndicatorStep message={`Triggered workflow: ${title}`} />;
};
