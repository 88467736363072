import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Box, useTheme } from '@mui/material';
import {
  IconChevronLeft,
  IconChevronRight,
  IconPhotoCancel,
} from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useSendPreviewApiRequest } from '../hooks';
import { ResponseMessage } from './StyledComponents';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import {
  DynamicCardApiWidgetComponent,
  DynamicCardTranscriptWidgetComponent,
} from 'src/slices/workflow-preview/types';

const forethoughtText = {
  next: 'Next',
  previous: 'Previous',
  select: 'Select',
  view_less: 'View Less',
  view_more: 'View More',
} as const;

type EmblaApi = ReturnType<typeof useEmblaCarousel>[1];

const MAX_IMAGE_COMPONENT_IMAGES = 3;
const IMAGE_HEIGHT = '160px';
const SLIDE_SPACING = 8;
const SLIDE_PERCENT_OF_CONTAINER = '90%';

const OPTIONS = {
  containScroll: false,
  dragFree: true,
  duration: 20,
  startIndex: 0,
} as const;

const usePrevNextButtons = (emblaApi: EmblaApi) => {
  // https://codesandbox.io/p/sandbox/embla-carousel-default-react-5y436f?file=%2Fsrc%2Fjs%2FEmblaCarouselDotButton.jsx
  // documented in https://www.embla-carousel.com/examples/predefined/
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaApi) => {
    setPrevBtnDisabled(!emblaApi?.canScrollPrev());
    setNextBtnDisabled(!emblaApi?.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    nextBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
    prevBtnDisabled,
  };
};

export const ApiDynamicCardStep = ({
  isTheLatestStep,
  isTranscript = false,
  selectedCardValue,
  step,
}: {
  isTheLatestStep: boolean;
  isTranscript?: boolean;
  selectedCardValue?: string;
  step: DynamicCardApiWidgetComponent | DynamicCardTranscriptWidgetComponent;
}) => {
  const selectedCardIndex = step.cards.findIndex(
    card => card.value === selectedCardValue,
  );
  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...OPTIONS,
    startIndex: selectedCardIndex === -1 ? 0 : selectedCardIndex,
  });
  const sendPreviewApiRequest = useSendPreviewApiRequest();

  const { cards, content_rows_limit, message } = step;

  const onSubmit = (e: React.SyntheticEvent, index: number) => {
    if (isTranscript) {
      return;
    } else if ('output_variable' in step) {
      e.preventDefault();

      const card = cards[index];

      const stepContextVariables = {
        [step.output_variable]: card.value,
      };
      sendPreviewApiRequest({ contextVariables: stepContextVariables });
    }
  };

  let messageNode: React.ReactNode = null;
  if (message && !isTranscript) {
    messageNode = (
      <ResponseMessage isPlainText={false}>
        <Typography variant='font14Medium'>
          <HtmlComponent
            content={message}
            linkifyHtmlOptions={solveLinkifyHtmlOptions}
          />
        </Typography>
      </ResponseMessage>
    );
  }

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {messageNode}
      <Box mt={3} ref={emblaRef} width='100%'>
        <Box
          sx={[
            {
              backfaceVisibility: 'hidden',
              display: 'flex',
              marginLeft: `calc(${SLIDE_SPACING}px * -1)`,
              mb: 2,
              touchAction: 'pan-y',
            },
            !message && {
              mt: 2,
            },
          ]}
        >
          {cards.map((card, index) => (
            <Card
              card={card}
              contentRowsLimit={content_rows_limit}
              index={index}
              isSelected={index === selectedCardIndex}
              isSent={!isTheLatestStep}
              key={index}
              numberOfCards={cards.length}
              onSubmit={onSubmit}
            />
          ))}
        </Box>
      </Box>
      {isTheLatestStep && <CarouselControl emblaApi={emblaApi} />}
    </Box>
  );
};

interface CardProps {
  card?: DynamicCardApiWidgetComponent['cards'][number];
  contentRowsLimit: DynamicCardApiWidgetComponent['content_rows_limit'];
  index: number;
  isSelected?: boolean;
  isSent?: boolean;
  numberOfCards: number;
  onSubmit: (e: React.SyntheticEvent, index: number) => void;
}

const Card = ({
  card,
  contentRowsLimit,
  index,
  isSelected,
  isSent = false,
  numberOfCards,
  onSubmit,
}: CardProps) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        flex: `0 0 ${SLIDE_PERCENT_OF_CONTAINER}`,
        minWidth: 0,
        paddingLeft: `${SLIDE_SPACING}px`,
      }}
    >
      <Box
        borderRadius='8px'
        className={index === 0 ? 'js-first-dynamic-card' : ''}
        component='button'
        disabled={isSent}
        display='flex'
        flexDirection='column'
        gap={1}
        onClick={e => onSubmit(e, index)}
        p={2}
        sx={[
          {
            backgroundColor: isSelected ? palette.colors.grey[100] : 'white',
            border: `1px solid ${palette.colors.slate[300]}`,
            boxShadow: '1px 0px 8px 0px rgba(0, 0, 0, 0.08)',
            textAlign: 'left',
            transition: 'all 0.2s ease-in-out',
            width: '100%',
          },
          !isSent && {
            '&:hover': {
              backgroundColor: palette.colors.slate[100],
            },
            cursor: 'pointer',
          },
        ]}
      >
        <Typography color={palette.colors.grey[600]} variant='font14'>
          {index + 1} of {numberOfCards}
        </Typography>
        {card &&
          card.displayable_dynamic_sections.map((section, index) => (
            <Section
              contentRowsLimit={contentRowsLimit}
              key={index}
              section={section}
            />
          ))}
      </Box>
    </Box>
  );
};

interface SectionProps {
  contentRowsLimit: DynamicCardApiWidgetComponent['content_rows_limit'];
  section: DynamicCardApiWidgetComponent['cards'][number]['displayable_dynamic_sections'][number];
}

const Section = ({ contentRowsLimit, section }: SectionProps) => {
  const { palette } = useTheme();
  const [isViewMore, setIsViewMore] = useState(false);

  switch (section.type) {
    case 'content':
      return (
        <Box display='flex' flexDirection='column' gap={0.5}>
          {section.components.map((component, index) => (
            <React.Fragment key={index}>
              {contentRowsLimit &&
              index >= contentRowsLimit &&
              !isViewMore ? null : (
                <Box>
                  {component.label && (
                    <Typography
                      color={palette.colors.grey[600]}
                      variant='font14'
                    >
                      {component.label}
                    </Typography>
                  )}
                  <Typography color={palette.colors.black} variant='font14'>
                    {' '}
                    {component.value}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
          {contentRowsLimit && (
            <Button
              onClick={e => {
                e.stopPropagation();
                setIsViewMore(prev => !prev);
              }}
              size='medium'
              variant='ghost'
            >
              {isViewMore
                ? forethoughtText.view_less
                : forethoughtText.view_more}
            </Button>
          )}
        </Box>
      );
    case 'image':
      return section.components.length ? (
        <Box display='flex' gap={1} width='100%'>
          {section.components
            .slice(0, MAX_IMAGE_COMPONENT_IMAGES)
            .map((section, key) => (
              <Box
                key={key}
                sx={{
                  backgroundColor: palette.colors.white,
                  border: `1px solid ${palette.colors.slate[200]}`,
                  borderRadius: '8px',
                  height: IMAGE_HEIGHT,
                  img: { height: '100%', objectFit: 'contain', width: '100%' },
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                <img alt='' src={section.value} />
              </Box>
            ))}
        </Box>
      ) : (
        <EmptyImage />
      );
    case 'title':
      return (
        <Box display='flex' flexDirection='column' gap={0.5}>
          {section.components.map((component, index) => (
            <Box key={index}>
              <Typography color={palette.colors.black} variant='font16Bold'>
                {component.label} {component.value}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    default:
      return null;
  }
};

interface CarouselControlProps {
  emblaApi: EmblaApi;
}

const CarouselControl = ({ emblaApi }: CarouselControlProps) => {
  const {
    nextBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
    prevBtnDisabled,
  } = usePrevNextButtons(emblaApi);

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      paddingBottom='16px'
    >
      <Button
        disabled={prevBtnDisabled}
        onClick={onPrevButtonClick}
        size='medium'
        startIcon={<IconChevronLeft size={20} />}
        tabIndex={-1}
        variant='ghost'
      >
        {forethoughtText.previous}
      </Button>
      <Button
        disabled={nextBtnDisabled}
        onClick={onNextButtonClick}
        size='medium'
        tabIndex={-1}
        variant='ghost'
      >
        {forethoughtText.next} <IconChevronRight size={20} />
      </Button>
    </Box>
  );
};

const EmptyImage = () => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.slate[200]}
      borderRadius='8px'
      display='flex'
      height={IMAGE_HEIGHT}
      justifyContent='center'
      width='100%'
    >
      <Box alignItems='center' display='flex' flexDirection='column' gap={1.5}>
        <IconPhotoCancel color={palette.colors.grey[600]} size={24} />
        <Typography color={palette.colors.grey[600]} variant='font14'>
          No image available
        </Typography>
      </Box>
    </Box>
  );
};
