import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';

import { Button } from '@forethought-technologies/forethought-elements';
import ConnectConnectorButton from './ConnectConnectorButton';
import { Connector, Credentials } from 'src/services/apiInterfaces';
import {
  selectConnectors,
  setIsEditing,
  setIsEditingSettings,
  setIsUpdateError,
} from 'src/slices/connectors/connectorsSlice';

interface FormButtonsProps {
  formData: Credentials | undefined;
  handleUpdateConnector: () => void;
  isDisplayNameInvalid: boolean;
  isFormValid: boolean;
  multiConnectorsEnabled: boolean;
  resetDisplayName: () => void;
  selectedConnector: Connector;
  setEditableFormData: (formData: Credentials | undefined) => void;
  setup_type: string;
  showEditSettingsButton?: boolean;
}
const FormButtons = ({
  formData,
  handleUpdateConnector,
  isDisplayNameInvalid,
  isFormValid,
  multiConnectorsEnabled,
  resetDisplayName,
  selectedConnector,
  setEditableFormData,
  setup_type,
  showEditSettingsButton,
}: FormButtonsProps) => {
  const dispatch = useDispatch();
  const { isEditing, isEditingSettings, isUpdateLoading } =
    useSelector(selectConnectors);

  if (setup_type === 'OAUTH' && !showEditSettingsButton) {
    return (
      <>
        {selectedConnector.is_active && (
          <ConnectConnectorButton
            connector={selectedConnector}
            disabled={!isFormValid || isDisplayNameInvalid}
          />
        )}
        <Button
          disabled={isUpdateLoading || !isFormValid || isDisplayNameInvalid}
          fullWidth
          isLoading={isUpdateLoading}
          size='large'
          type='submit'
          variant='secondary'
        >
          Restart Auth Flow
        </Button>
      </>
    );
  }

  if (isEditing || isEditingSettings) {
    return (
      <>
        <Button
          disabled={isUpdateLoading || !isFormValid}
          fullWidth
          onClick={e => {
            e.preventDefault();
            if (!showEditSettingsButton) {
              dispatch(setIsEditing(false));
              dispatch(setIsUpdateError(false));
              setEditableFormData(formData);
              resetDisplayName();
            } else {
              dispatch(setIsEditingSettings(false));
              setEditableFormData(formData);
              resetDisplayName();
            }
          }}
          size='large'
          type='button'
          variant='ghost'
        >
          Cancel
        </Button>
        <Button
          disabled={
            isUpdateLoading ||
            !isFormValid ||
            (multiConnectorsEnabled ? isDisplayNameInvalid : false)
          }
          fullWidth
          isLoading={isUpdateLoading}
          onClick={e => {
            e.preventDefault();
            handleUpdateConnector();
            if (showEditSettingsButton) {
              setIsEditingSettings?.(false);
            }
          }}
          size='large'
          type='button'
          variant='secondary'
        >
          Save
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        fullWidth
        onClick={e => {
          e.preventDefault();
          if (!showEditSettingsButton) {
            dispatch(setIsEditing(true));
          } else {
            dispatch(setIsEditingSettings(true));
          }
        }}
        size='large'
        startIcon={<EditIcon />}
        type='button'
        variant='secondary'
      >
        {!showEditSettingsButton ? 'Edit Settings' : 'Edit Connector Settings'}
      </Button>
    </>
  );
};

export default FormButtons;
