import React from 'react';
import {
  createMRTColumnHelper,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import { NavigateFunction, useNavigate } from 'react-router';
import { Box, Palette } from '@mui/material';
import { IconBolt, IconInfoCircle, IconTag } from '@tabler/icons-react';

import {
  Badge as FEBadge,
  Table,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import Badge from '../common/Badge';
import OverflowDropdown from '../common/overflow-dropdown/OverflowDropdown';
import { INSIGHT_TOOLTIP_COPY, TABLE_COLUMN_TO_LABEL_MAP } from '../constants';
import { getReadableNoResponseString } from '../helpers';
import { Scope } from '../types';
import autonomousAgentBadge from 'src/assets/images/autonomous-agent-badge.svg';
import thumbDown from 'src/assets/images/quick-feedback-config-pics/thumb-down-active.svg';
import thumbUp from 'src/assets/images/quick-feedback-config-pics/thumb-up-active.svg';
import { TrackingEventType } from 'src/components/app/types';
import { getDiscoverSentimentEmoji } from 'src/components/discover-sentiment-emoji/helpers';
import PillButton from 'src/components/pill-button/PillButton';
import { DateCell } from 'src/pages/intent-conversation-analytics/components/DateCell';
import { DeflectionInsightCell } from 'src/pages/intent-conversation-analytics/components/DeflectionInsightCell';
import {
  NA,
  SMALL_CELL_WIDTH,
} from 'src/pages/intent-conversation-analytics/constants';
import { formatTimeSpent } from 'src/pages/intent-conversation-analytics/ConversationDrawer/utils';
import ConversationTranscript, {
  CellContainer,
} from 'src/pages/intent-conversation-analytics/ConversationTranscript';
import {
  formatCSAT,
  formatTimestamp,
} from 'src/pages/intent-conversation-analytics/helpers';
import { BreakdownPaletteTooltip } from 'src/pages/workflow-builder/BreakdownPaletteTooltip';
import { HorizontalStackedBar } from 'src/pages/workflow-builder/intent-workflows-table/HorizontalStackedBar';
import AutopilotBadge from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/components/autopilot/AutopilotBadge';
import {
  InsightConversation,
  InsightEmailConversation,
} from 'src/services/insights/types';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { Routes } from 'src/utils/enums';
import { isDeflectionInsightsProcessing } from 'src/utils/solve/deflectionInsightsUtils';

export type View = 'chats' | 'topics' | 'articles' | 'workflows';

export type InsightConversationColumns = React.ComponentProps<
  typeof Table<InsightConversation>
>['columns'];

export const buildConversationColumns = (
  palette: Palette,
  getCSATColor: (score?: number | undefined) => string,
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: Record<string, unknown>,
  ) => void,
  scope: Scope,
  tab: 'topic' | 'article' | 'chat' | 'workflow',
  isDetail?: boolean,
  isDeflectionInsightsEnabled?: boolean,
  shouldShowQuickFeedbackAnalytics?: boolean,
  isVoiceChannel?: boolean,
) => {
  const columnHelper = createMRTColumnHelper<InsightConversation>();
  const relevanceRating = columnHelper.accessor('relevance_rating', {
    Cell: ({ row }: Row) => {
      return (
        <DeflectionInsightCell
          isProcessing={isDeflectionInsightsProcessing({
            hasDeflectionInsights: !!row.original.relevance_rating,
            isDeflected: row.original.is_deflected,
            modifiedDate: row.original.timestamp,
          })}
          metric={row.original.relevance_rating}
        />
      );
    },
    header: 'Relevance',
    Header: () => (
      <Box alignItems='center' display='flex' gap='4px'>
        Relevance
        <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.relevance}>
          <IconInfoCircle color={palette.colors.grey[700]} size={20} />
        </Tooltip>
      </Box>
    ),
    size: SMALL_CELL_WIDTH,
  });

  const dropoffRating = columnHelper.accessor('dropoff_rating', {
    Cell: ({ row }: Row) => {
      return (
        <DeflectionInsightCell
          isProcessing={isDeflectionInsightsProcessing({
            hasDeflectionInsights: !!row.original.relevance_rating,
            isDeflected: row.original.is_deflected,
            modifiedDate: row.original.timestamp,
          })}
          metric={row.original.dropoff_rating}
        />
      );
    },
    Header: () => (
      <Box alignItems='center' display='flex' gap='4px'>
        User engagement
        <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.engagement}>
          <IconInfoCircle color={palette.colors.grey[700]} size={20} />
        </Tooltip>
      </Box>
    ),
    header: 'User engagement',
    size: 200,
  });

  const quickFeedbackItem = columnHelper.accessor('useful_count', {
    Cell: ({ row }: Row) => {
      const not_useful_count = row.original.not_useful_count || 0;
      const useful_count = row.original.useful_count || 0;
      const unanswered_count = row.original.unanswered_count || 0;
      const totalFeedback = not_useful_count + useful_count;

      if (totalFeedback < 1) {
        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      }

      return (
        <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
          <Tooltip
            fullWidth
            maxWidth={320}
            tooltipContent={
              <BreakdownPaletteTooltip
                values={[
                  {
                    color: palette.colors.green[500],
                    label: 'Positive',
                    value: useful_count,
                  },
                  {
                    color: palette.colors.red[500],
                    label: 'Negative',
                    value: not_useful_count,
                  },
                  {
                    color: palette.colors.slate[100],
                    label: 'Not answered',
                    value: unanswered_count,
                  },
                ]}
              />
            }
          >
            <HorizontalStackedBar
              data={[
                {
                  color: palette.colors.green[500],
                  value: useful_count,
                },
                {
                  color: palette.colors.red[500],
                  value: not_useful_count,
                },
              ]}
            />
          </Tooltip>
        </Box>
      );
    },
    header: TABLE_COLUMN_TO_LABEL_MAP.conversation.useful_count,
    Header: () => {
      return (
        <Box alignItems='center' display='flex' gap='4px'>
          {TABLE_COLUMN_TO_LABEL_MAP.conversation.useful_count}
          <Tooltip
            tooltipContent={
              <Box>
                This reflects the Quick Feedback from <i>all</i> articles used
                in this conversation
              </Box>
            }
          >
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        </Box>
      );
    },
    size: 250,
  });

  const voiceColumns = [
    columnHelper.accessor('voice_call_duration', {
      Cell: ({ row }: Row) => {
        const callDuration = row.original.voice_call_duration;

        if (!callDuration) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }

        return (
          <Typography variant='font14'>
            {formatTimeSpent(callDuration)}
          </Typography>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.voice_call_duration,
      size: 150,
    }),
    columnHelper.accessor('voice_sentiment', {
      Cell: ({ row }: Row) => {
        const voiceSentiment = row.original.voice_sentiment;

        if (!voiceSentiment) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }

        return (
          <Box alignItems='center' display='flex' gap='4px'>
            <img src={getDiscoverSentimentEmoji(voiceSentiment, 'medium')} />
            <Typography variant='font14'>
              {capitalizeFirstLetter(voiceSentiment)}
            </Typography>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.voice_sentiment,
      size: 150,
    }),
  ];

  const initialColumns = [
    columnHelper.accessor('timestamp', {
      Cell: ({ row }: Row) => {
        return (
          <Box width={SMALL_CELL_WIDTH}>
            <DateCell
              dateString={formatTimestamp(row.original.timestamp + 'Z')}
            />
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.timestamp,
      size: SMALL_CELL_WIDTH,
    }),
    isVoiceChannel
      ? null
      : columnHelper.accessor('channel', {
          Cell: ({ row }: Row) => {
            const value = row.original.channel
              ? capitalizeFirstLetter(row.original.channel)
              : NA;
            return <Typography variant='font14'>{value}</Typography>;
          },
          header: TABLE_COLUMN_TO_LABEL_MAP.conversation.channel,
          size: SMALL_CELL_WIDTH,
        }),
    columnHelper.accessor('autonomous_agent_used', {
      Cell: ({ row }: Row) => {
        return row.original.autonomous_agent_used ? (
          <img
            alt='knowledge retrieval icon'
            height={24}
            src={autonomousAgentBadge}
            width={24}
          />
        ) : (
          <></>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.autonomous_agent_used,
      size: 40,
    }),

    columnHelper.accessor('chat', {
      Cell: ({ row }: Row) => {
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '8px',
              maxWidth: '540px',
              position: 'relative',
            }}
          >
            <ConversationTranscript transcript={row.original.chat} />
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.chat,
      size: 320,
    }),

    columnHelper.accessor('user_queries', {
      Cell: ({ row }: Row) => {
        const userQueries = row.original.user_queries;

        if (userQueries.length === 1) {
          return (
            <CellContainer width='200px'>
              <i>
                <Typography color={palette.colors.blue[600]} variant='font14'>
                  &quot;{row.original.user_query}&quot;
                </Typography>
              </i>
            </CellContainer>
          );
        }

        if (userQueries.length > 1) {
          return (
            <OverflowDropdown
              data={userQueries}
              menuTitle='User query'
              scope='workflow'
              tab='chat'
              variant='user_queries'
            />
          );
        }

        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.user_queries,
      size: 200,
    }),

    columnHelper.accessor('is_deflected', {
      Cell: ({ row }: Row) => {
        return (
          <Typography variant='font14Medium'>
            {row.original.is_deflected ? 'Yes' : 'No'}
          </Typography>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.is_deflected,
      size: 130,
    }),
  ];

  const nonVoiceColumns = [
    columnHelper.accessor('detected_intents', {
      Cell: ({ row }: Row) => {
        return (
          <OverflowDropdown
            data={row.original.detected_intents}
            menuTitle='Workflows surfaced in this chat'
            scope={scope}
            tab='article'
            variant='workflow'
          />
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_intents,
      Header: () => {
        return (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'chat',
                value: 'detected_intents',
              });
            }}
          >
            {TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_intents}
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_workflows}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),

    columnHelper.accessor('detected_topic', {
      Cell: ({ row }: Row) => {
        const navigate = useNavigate();
        if (row.original.detected_topic) {
          const { cluster_id, cluster_name } = row.original.detected_topic;
          return (
            <Box
              onClick={() => {
                const pathName = Routes.SOLVE_INSIGHTS_TOPIC_DETAIL.replace(
                  ':topicId',
                  cluster_id,
                );
                const queryParams = new URLSearchParams({
                  chats_sort_column: 'timestamp',
                });

                navigate({
                  pathname: pathName,
                  search: `?${queryParams}`,
                });
              }}
              sx={{
                '&:hover': { background: palette.colors.slate[200] },
                background: palette.colors.slate[100],
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                gap: '3px',
                padding: '4px 8px 4px 8px',
              }}
            >
              <Box alignItems='center' display='flex' width='17px'>
                <IconBolt
                  color={palette.colors.green[500]}
                  fill={palette.colors.green[500]}
                  height={17}
                  strokeWidth={1}
                  width={17}
                />
              </Box>
              <Typography color={palette.colors.grey[700]} variant='font12'>
                {cluster_name}
              </Typography>
            </Box>
          );
        }
        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },

      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_topic,
      Header: () => {
        return (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'chat',
                value: 'detected_intents',
              });
            }}
          >
            {TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_topic}
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_detected_topic}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
    }),

    columnHelper.accessor('articles', {
      Cell: ({ row }: Row) => {
        if (isDetail && row.original.articles.length) {
          return (
            <OverflowDropdown
              data={row.original.articles}
              menuTitle='Articles surfaced in this chat'
              scope={scope}
              tab='article'
              variant='article'
            />
          );
        }

        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.articles,
      Header: () => {
        return (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'chat',
                value: 'articles',
              });
            }}
          >
            {TABLE_COLUMN_TO_LABEL_MAP.conversation.articles}
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.chats_surfaced_articles}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),
    shouldShowQuickFeedbackAnalytics ? quickFeedbackItem : null,
    columnHelper.accessor('csat', {
      Cell: ({ row }: Row) => {
        if (!row.original.csat) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        const value = formatCSAT(row.original.csat);
        return (
          <Typography
            color={getCSATColor(Number(value))}
            variant='font14Medium'
          >
            {value}
          </Typography>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.csat,
      size: 100,
    }),
    columnHelper.accessor('workflow_tag', {
      Cell: ({ row }: Row) => {
        const workflowTag = row.original.workflow_tag;
        if (workflowTag) {
          return (
            <PillButton
              maxWidth='100%'
              onClick={() => {
                return;
              }}
              variant='workflowTag'
            >
              <Box
                alignItems='center'
                display='flex'
                sx={{ '& a': { color: 'inherit', textDecoration: 'none' } }}
                width='100%'
              >
                <Box alignItems='center' display='flex' gap='4px'>
                  <IconTag color={palette.colors.blue[500]} size={16} />
                  <Box
                    display='flex'
                    sx={{
                      color: 'inherit',
                      overflow: 'hidden',
                      textDecoration: 'none',
                      textOverflow: 'ellipsis',
                      width: '100%',
                    }}
                  >
                    <Typography
                      color={palette.colors.black}
                      noWrap
                      variant='font14'
                    >
                      {workflowTag}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </PillButton>
          );
        }

        return (
          <Typography color={palette.colors.grey[500]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.workflow_tag,
      size: 160,
    }),
    isDeflectionInsightsEnabled ? relevanceRating : null,
    isDeflectionInsightsEnabled ? dropoffRating : null,
  ];

  const resultColumns = isVoiceChannel
    ? [...initialColumns, ...voiceColumns]
    : [...initialColumns, ...nonVoiceColumns];

  return resultColumns.filter((item): item is Item => item !== null);
};

export const buildEmailConversationColumns = (
  palette: Palette,
  scope: Scope,
  setIsUnansweredCausesDialogOpen: (isOpen: boolean) => void,
  navigate: NavigateFunction,
) => {
  const handleClickLink = (
    e: React.MouseEvent<HTMLDivElement>,
    intentId: string,
    workflowId: string,
  ) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams({
      intentId: intentId,
      mode: 'classic',
      view: 'email',
      workflowId: workflowId,
    });
    const pathName = Routes.WORKFLOW_BUILDER_EDIT;

    navigate({
      pathname: pathName,
      search: searchParams.toString(),
    });
  };

  const columnHelper = createMRTColumnHelper<InsightEmailConversation>();
  const initialColumns = [
    columnHelper.accessor('timestamp', {
      Cell: ({ row }: EmailRow) => {
        return (
          <Box width={SMALL_CELL_WIDTH}>
            <DateCell
              dateString={formatTimestamp(row.original.timestamp + 'Z')}
            />
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.timestamp,
      size: SMALL_CELL_WIDTH,
    }),
    columnHelper.accessor('ticket_id', {
      Cell: ({ row }: EmailRow) => {
        return (
          <Typography variant='font14'>{row.original.ticket_id}</Typography>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.ticket_id,
      size: 175,
    }),
    columnHelper.accessor('ticket_body', {
      Cell: ({ row }: EmailRow) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              maxWidth: 540,
            }}
          >
            <Box display='flex' gap='4px' mb='4px'>
              {row.original.is_interactive_email && (
                <FEBadge label='Interactive' variant='inverse' />
              )}
              {row.original.is_autoflow && <AutopilotBadge />}
            </Box>
            <Box
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              <Typography variant='font14Bold'>
                {row.original.ticket_title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
            >
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {row.original.ticket_body}
              </Typography>
            </Box>
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.ticket_body,
      size: 440,
    }),
    columnHelper.accessor('is_deflected', {
      Cell: ({ row }: EmailRow) => {
        const value = row.original.is_deflected ? 'Yes' : 'No';
        return <Typography variant='font14'>{value}</Typography>;
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.is_deflected,
      size: 150,
    }),
    columnHelper.accessor('is_eligible', {
      Cell: ({ row }: EmailRow) => {
        const value = row.original.is_eligible ? 'Yes' : 'No';
        return <Typography variant='font14'>{value}</Typography>;
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.is_eligible,
      size: 150,
    }),
    columnHelper.accessor('response_sent', {
      Cell: ({ row }: EmailRow) => {
        const value = row.original.response_sent ? 'Yes' : 'No';
        return <Typography variant='font14'>{value}</Typography>;
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.response_sent,
      size: 150,
    }),
    columnHelper.accessor('skip_reason', {
      Cell: ({ row }: EmailRow) => {
        const allSkipReasons = [
          row.original.skip_reason,
          row.original.email_workflow_execution_status ?? 'no_value',
        ]
          .filter(reason => reason !== 'finished')
          .map(reason => getReadableNoResponseString(reason));
        const isEmpty =
          allSkipReasons.length === 0 ||
          (allSkipReasons.length === 1 && allSkipReasons[0] === '');
        if (isEmpty) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return <Typography variant='font14'>{allSkipReasons}</Typography>;
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.skip_reason,
      Header: () => {
        return (
          <Box alignItems='center' display='flex' gap='4px'>
            {TABLE_COLUMN_TO_LABEL_MAP.email_conversation.skip_reason}
            <Box
              alignItems='center'
              display='flex'
              onClick={() => setIsUnansweredCausesDialogOpen(true)}
            >
              <Tooltip tooltipContent={false}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
          </Box>
        );
      },
      size: 260,
    }),
    columnHelper.accessor('detected_intents', {
      Cell: ({ row }: EmailRow) => {
        if (row.original.detected_intents.length) {
          return (
            <Box display='flex' flexDirection='column' gap='4px' width='200px'>
              {row.original.detected_intents.map(workflow => (
                <Badge
                  content={workflow.intent_name}
                  key={workflow.intent_id}
                  onClick={e =>
                    handleClickLink(e, workflow.intent_id, workflow.workflow_id)
                  }
                />
              ))}
            </Box>
          );
        }

        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.detected_intents,
      Header: () => {
        return (
          <Box alignItems='center' display='flex' gap='4px'>
            {TABLE_COLUMN_TO_LABEL_MAP.email_conversation.detected_intents}
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_workflows}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),
    columnHelper.accessor('articles', {
      Cell: ({ row }: EmailRow) => {
        if (row.original.articles.length) {
          return (
            <Box display='flex' flexDirection='column' width='200px'>
              {row.original.articles.map(article => (
                <a
                  href={article.article_link}
                  key={article.article_id}
                  rel='noreferrer'
                  target='_blank'
                >
                  <Tooltip tooltipContent={article.article_title}>
                    <Box
                      sx={{
                        '&:hover': {
                          bgcolor: palette.colors.slate[200],
                          cursor: 'pointer',
                        },
                        overflow: 'hidden',
                        textDecoration: 'underline',
                        textOverflow: 'ellipsis',
                        width: '200px',
                      }}
                    >
                      <Typography
                        color={palette.colors.blue[600]}
                        noWrap
                        variant='font12Medium'
                      >
                        {article.article_title}
                      </Typography>
                    </Box>
                  </Tooltip>
                </a>
              ))}
            </Box>
          );
        }

        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.articles,
      Header: () => {
        return (
          <Box alignItems='center' display='flex' gap='4px'>
            {TABLE_COLUMN_TO_LABEL_MAP.email_conversation.articles}
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.chats_surfaced_articles}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),
    columnHelper.accessor('user_feedback', {
      Cell: ({ row }: EmailRow) => {
        const value = row.original.user_feedback;
        let icon = '';
        if (value === 'positive') {
          icon = thumbUp;
        } else if (value === 'negative') {
          icon = thumbDown;
        }
        return (
          <Box display='flex' justifyContent='center' width='150px'>
            {icon ? (
              <img src={icon} style={{ height: '18px', width: '18px' }} />
            ) : (
              <Typography color={palette.colors.grey[600]} variant='font14'>
                {NA}
              </Typography>
            )}
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.email_conversation.user_feedback,
      size: 175,
    }),
  ];

  return initialColumns;
};

type Item =
  | MRT_ColumnDef<InsightConversation, string>
  | MRT_ColumnDef<InsightConversation, string[]>
  | MRT_ColumnDef<InsightConversation, boolean>;

type Row = { row: MRT_Row<InsightConversation> };
type EmailRow = { row: MRT_Row<InsightEmailConversation> };

export const getTableHeight = (view: View) => {
  let height: number;

  switch (view) {
    case 'chats':
      height = 250;
    case 'topics':
      height = 300;
    case 'articles':
      height = 300;
    case 'workflows':
      height = 300;
    default:
      height = 250;
  }
  return height;
};
