import React, { useMemo } from 'react';

import { useIntents } from '../intent-workflows-table/hooks/useIntents';
import { useSolveMetricsQueryParams } from '../intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { useWidgetAggregateData } from '../intent-workflows-table/hooks/useWidgetAggregateData';
import AggregateMetrics, { AggregateMetric } from './AggregateMetrics';
import {
  formatNumber,
  getLabelForMetric,
  getTooltipForMetric,
} from 'src/components/reusable-components/data-overview-card/helpers';
import { SHARED_METRIC_DEFINITIONS } from 'src/constants/solve';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { MetricAggregateResponse } from 'src/types/workflowBuilderAPITypes';
import { CommonIntentWorkflowType } from 'src/utils/enums';
import { formatNumberWithPercentage } from 'src/utils/solve/deflectionInsightsUtils';

interface FormatMetricsOpts {
  isDifferenceEnabled: boolean;
}

export default function WidgetAggregateMetricsContainer() {
  const { data: data, isLoading } = useWidgetAggregateData();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isDifferenceEnabled = featureFlags.includes(
    'workflow_builder_metrics_difference_enabled',
  );
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const isCSATEnabled = featureFlags.includes('landing_page_csat');

  const { intents, loading: isLoadingFallbackInquires } = useIntents();
  const { channel } = useSolveMetricsQueryParams();

  const fallbackIntent = useMemo(() => {
    return intents.find(
      intent =>
        intent.intent_definition_id === CommonIntentWorkflowType.FALLBACK,
    );
  }, [intents]);

  const fallbackCount = useMemo(() => {
    return fallbackIntent?.inquiry_count_per_channel?.[channel] ?? 0;
  }, [fallbackIntent, channel]);

  const getCsatColor = useGetCSATColor();

  const isFallbackWorkflowEnable = featureFlags.includes('fallback_workflow');

  const formatMetrics = React.useCallback(
    function formatMetrics(
      data: MetricAggregateResponse,
      { isDifferenceEnabled }: FormatMetricsOpts,
    ): AggregateMetric[] {
      if (channel === 'playbook') {
        return [
          {
            difference: isDifferenceEnabled
              ? data.aggregate.user_queries.change
              : undefined,
            label: getLabelForMetric('user_queries', channel),
            tooltip: getTooltipForMetric('user_queries', null, channel),
            value: formatNumber(
              'user_queries',
              data.aggregate.user_queries.user_queries,
            ),
          },
          {
            ...SHARED_METRIC_DEFINITIONS.fallback,
            isLoading: isLoadingFallbackInquires,
            value: formatNumberWithPercentage(
              fallbackCount,
              data.aggregate.user_queries.user_queries,
            ),
          },
        ];
      }
      return [
        {
          difference: isDifferenceEnabled
            ? data.aggregate.user_queries.change
            : undefined,
          label: getLabelForMetric('user_queries'),
          tooltip: getTooltipForMetric('user_queries'),
          value: formatNumber(
            'user_queries',
            data.aggregate.user_queries.user_queries,
          ),
        },
        {
          difference: isDifferenceEnabled
            ? data.aggregate.resolutions.change
            : undefined,
          label: getLabelForMetric('resolutions'),
          tooltip: getTooltipForMetric('resolutions'),
          value: formatNumber(
            'resolutions',
            data.aggregate.resolutions.resolutions,
          ),
        },
        ...(isFallbackWorkflowEnable
          ? [
              {
                ...SHARED_METRIC_DEFINITIONS.fallback,
                isLoading: isLoadingFallbackInquires,
                value: formatNumberWithPercentage(
                  fallbackCount,
                  data.aggregate.user_queries.user_queries,
                ),
              },
            ]
          : []),
        {
          difference: isDifferenceEnabled
            ? data.aggregate.deflection_rate.change
            : undefined,
          label: getLabelForMetric('deflection_rate'),
          tooltip: getTooltipForMetric('deflection_rate'),
          value: formatNumber(
            'deflection_rate',
            data.aggregate.deflection_rate.deflection_rate,
          ),
        },

        ...(channel === 'slack'
          ? []
          : [
              {
                difference: isDifferenceEnabled
                  ? data.aggregate.cost_savings.change
                  : undefined,
                label: getLabelForMetric('cost_savings'),
                tooltip: getTooltipForMetric(
                  'cost_savings',
                  data.aggregate.cost_savings.cost_per_ticket,
                ),
                value: formatNumber(
                  'cost_savings',
                  data.aggregate.cost_savings.cost_savings,
                ),
              },
            ]),
        ...(isCSATEnabled && channel !== 'slack'
          ? [
              {
                color: getCsatColor(data.aggregate.avg_csat),
                label: 'CSAT',
                value: formatCSAT(data.aggregate.avg_csat),
              },
            ]
          : []),
      ];
    },
    [
      channel,
      fallbackCount,
      getCsatColor,
      isCSATEnabled,
      isFallbackWorkflowEnable,
      isLoadingFallbackInquires,
    ],
  );

  const formatMetricsWithDeflectionInsights = React.useCallback(
    function (data: MetricAggregateResponse) {
      return [
        {
          label: getLabelForMetric('user_queries'),
          tooltip: getTooltipForMetric('user_queries'),
          value: formatNumber(
            'user_queries',
            data.aggregate.user_queries.user_queries,
          ),
        },
        {
          label: 'Deflections',
          tooltip: getTooltipForMetric('resolutions'),
          value: formatNumberWithPercentage(
            data.aggregate.resolutions.resolutions,
            data.aggregate.user_queries.user_queries,
          ),
        },
        ...(isFallbackWorkflowEnable
          ? [
              {
                ...SHARED_METRIC_DEFINITIONS.fallback,
                isLoading: isLoadingFallbackInquires,
                value: formatNumberWithPercentage(
                  fallbackCount,
                  data.aggregate.user_queries.user_queries,
                ),
              },
            ]
          : []),
        ...(isCSATEnabled && channel !== 'slack'
          ? [
              {
                color: getCsatColor(data.aggregate.avg_csat),
                label: 'CSAT',
                value: formatCSAT(data.aggregate.avg_csat),
              },
            ]
          : []),
        ...(channel === 'slack'
          ? []
          : [
              {
                label: 'Cost Savings',
                tooltip: getTooltipForMetric(
                  'cost_savings',
                  data.aggregate.cost_savings.cost_per_ticket,
                ),
                value: formatNumber(
                  'cost_savings',
                  data.aggregate.cost_savings.cost_savings,
                ),
              },
            ]),
      ];
    },
    [
      isFallbackWorkflowEnable,
      isLoadingFallbackInquires,
      fallbackCount,
      isCSATEnabled,
      channel,
      getCsatColor,
    ],
  );

  const metrics = React.useMemo(() => {
    if (!data) {
      return null;
    }

    if (isDeflectionInsightsEnabled && channel !== 'playbook') {
      return formatMetricsWithDeflectionInsights(data);
    }

    return formatMetrics(data, { isDifferenceEnabled });
  }, [
    data,
    formatMetricsWithDeflectionInsights,
    isDifferenceEnabled,
    isDeflectionInsightsEnabled,
    formatMetrics,
    channel,
  ]);

  return (
    <AggregateMetrics
      isDeflectionInsightsEnabled={isDeflectionInsightsEnabled}
      metrics={metrics}
      numLoadingSkeletons={
        isLoading ? (isDeflectionInsightsEnabled ? 5 : 6) : 0
      }
    />
  );
}
