import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Checkbox,
  LoadingIndicator,
  TextField,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitVoiceConfigForNonRTE } from '../../hooks/useDebouncedSubmitConfig';
import {
  selectIsSwitchingConfig,
  selectSelectedPhoneNumber,
  selectVoiceConfiguration,
  setVoiceDataByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { VoiceConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

export const RecordingDisclaimerConfig = () => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const voiceConfiguration = useSelector(selectVoiceConfiguration);
  const selectedNumber = useSelector(selectSelectedPhoneNumber);
  const isSwitchingConfig = useSelector(selectIsSwitchingConfig);
  const debouncedSubmitConfig = useDebouncedSubmitVoiceConfigForNonRTE();

  const {
    disclaimer_text: disclaimerText,
    is_call_recording_enabled: isCallRecordingEnabled,
    is_disclaimer_enabled: isDisclaimerEnabled,
  } = voiceConfiguration;

  if (isSwitchingConfig) {
    return (
      <Box mt='30%'>
        <LoadingIndicator color={palette.colors.purple[500]} size='large' />
      </Box>
    );
  }

  if (!selectedNumber) {
    return (
      <Box mt='30%' textAlign='center'>
        <Typography color={palette.colors.grey[600]} variant='font20'>
          Create and manage phone numbers on the Phone tab
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
      }}
    >
      <Section>
        <Typography variant='font16Bold'>Call recording</Typography>
        <Box display='flex' gap='8px'>
          <Toggle
            checked={isCallRecordingEnabled}
            onChange={() => {
              dispatch(
                setVoiceDataByKey({
                  key: 'is_call_recording_enabled',
                  value: !isCallRecordingEnabled,
                }),
              );
              // if call recording is enabled, disclaimer must be enabled too
              dispatch(
                setVoiceDataByKey({
                  key: 'is_disclaimer_enabled',
                  value: true,
                }),
              );

              const updatedConfig: VoiceConfiguration = {
                ...voiceConfiguration,
                is_call_recording_enabled: !isCallRecordingEnabled,
                is_disclaimer_enabled: true,
              };
              debouncedSubmitConfig(
                updatedConfig,
                'is_call_recording_enabled',
                isCallRecordingEnabled ? 'false' : 'true',
              );
            }}
          />
          <Typography variant='font14'>
            Enable automatic recording of all incoming calls
          </Typography>
        </Box>
      </Section>

      <Section gap='4px'>
        <Typography variant='font16Bold'>Disclaimer</Typography>
        <Checkbox
          checked={isDisclaimerEnabled}
          disabled={isCallRecordingEnabled}
          label='Play disclaimer message before call connection'
          onChange={() => {
            dispatch(
              setVoiceDataByKey({
                key: 'is_disclaimer_enabled',
                value: !isDisclaimerEnabled,
              }),
            );

            const updatedConfig: VoiceConfiguration = {
              ...voiceConfiguration,
              is_disclaimer_enabled: !isDisclaimerEnabled,
            };
            debouncedSubmitConfig(
              updatedConfig,
              'is_disclaimer_enabled',
              isDisclaimerEnabled ? 'false' : 'true',
            );
          }}
        />
      </Section>

      {isDisclaimerEnabled && (
        <Box display='flex' flexDirection='column' gap={2} mt='-12px'>
          <Typography variant='font16Bold'>Disclaimer statement</Typography>
          <TextField
            aria-label='Enter disclaimer statement'
            error={
              isDisclaimerEnabled &&
              disclaimerText.trim() === '' &&
              'Disclaimer cannot be empty'
            }
            multiline
            onChange={e => {
              dispatch(
                setVoiceDataByKey({
                  key: 'disclaimer_text',
                  value: e.target.value,
                }),
              );

              const updatedConfig: VoiceConfiguration = {
                ...voiceConfiguration,
                disclaimer_text: e.target.value,
              };
              debouncedSubmitConfig(
                updatedConfig,
                'disclaimer_text',
                e.target.value,
              );
            }}
            rows={4}
            value={disclaimerText}
          />
        </Box>
      )}
    </Box>
  );
};

const Section = styled('div')<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.gap ? props.gap : '16px')};
`;
