import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import ArticleList from 'src/components/dashboard-pages/discover-automation-page/knowledge-gap-tab/ArticleList';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface RelatedArticlesSectionProps {
  articles: KnowledgeGapArticleRecommendation['relevant_articles'];
}

const RelatedArticlesSection = ({ articles }: RelatedArticlesSectionProps) => {
  const { palette } = useTheme();

  if (!articles || !articles.length) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Related articles
      </Typography>
      <ArticleList articles={articles} hideTooltip={false} width='auto' />
    </Box>
  );
};

export default RelatedArticlesSection;
