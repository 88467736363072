import { createTheme } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import { WidgetConfigAssetType } from './types';
import { SettingsInterface } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderSettingsVariablesDict } from 'src/utils/actionBuilder/types';

export const formatImageURL = (URL?: string | null): string | null => {
  if (URL === null || URL === undefined) {
    return null;
  }

  const splitURL = URL.split('/');
  return splitURL[splitURL.length - 1];
};

export const getFileName = (
  data: WidgetConfigAssetType,
): string | undefined => {
  if (typeof data === 'string') {
    return data;
  }

  return data?.file.name;
};

export const entryFlowTheme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: { root: { padding: '5px' } },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px 14px',
        },
      },
    },
  },
  palette: {
    colors: theme.palette.colors,
    error: {
      main: theme.palette.colors.purple[500],
    },
    primary: {
      main: theme.palette.colors.purple[500],
    },
    secondary: {
      main: theme.palette.colors.purple[500],
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: 14,
    },
  },
});

export const deriveInitVariablesState = (
  contextVariables: SettingsInterface['context_variables'],
): ActionBuilderSettingsVariablesDict[] => {
  return contextVariables
    .map(
      ({
        configuration_fields: configurationFields,
        context_variable_id: id,
        context_variable_name: displayName,
        context_variable_type: type,
        created_date: createdDate,
        default_value: defaultValue,
        is_system_context_variable: isSystemContext,
        is_universal_context_variable: isUniversal,
        is_workflow_context: isWorkflowContext,
        list_type_options: listTypeOptions,
      }) => ({
        configurationFields: configurationFields
          ? {
              dynamicListConfig: configurationFields.dynamic_list_config,
              isAlphanumeric: configurationFields.is_alphanumeric,
              maxLength: configurationFields.max_length,
              minLength: configurationFields.min_length,
            }
          : undefined,
        createdDate,
        defaultValue,
        displayName,
        id,
        isSystemContext,
        isUniversal,
        isWorkflowContext,
        listTypeOptions,
        type,
      }),
    )
    .sort((v1, v2) => {
      const bothAreUniversal = v1.isUniversal && v2.isUniversal;
      const neitherAreUniversal = !v1.isUniversal && !v2.isUniversal;

      if (bothAreUniversal || neitherAreUniversal) {
        return v1.displayName.localeCompare(v2.displayName);
      }

      return v1.isUniversal ? -1 : 1;
    });
};
