import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  IconButton,
  SearchBar,
  Table,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import IntentBadge from '../intent-conversation-analytics/IntentBadge';
import Card from './components/Card';
import FeedbackButtonGroup from './components/FeedbackButtonGroup';
import IntentPredictionDrawer from './components/IntentPredictionDrawer';
import IntentTestPieChart from './components/IntentTestPieChart';
import { OTHER_QUESTIONS } from './constants';
import { useFallbackIntentForChannel } from './hooks';
import countBy from 'lodash/fp/countBy';
import debounce from 'lodash/fp/debounce';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import { useStateParams } from 'src/hooks/hooks';
import {
  useGetIntentTestPredictionsQuery,
  useGetIntentTestsQuery,
} from 'src/services/intent-tests/intentTestsApi';
import {
  EmailInquiry,
  IntentPredictionRecord,
  isEmailInquiry,
  WidgetInquiry,
} from 'src/services/intent-tests/types';
import { formatReadableDatetime } from 'src/utils/dateUtils';
import { Routes } from 'src/utils/enums';

/**
 * format a number and shorten with a suffix if 100,000 or greater (e.g. )
 */
function formatNumberWithSuffix(num: number) {
  if (num >= 1000000) {
    return Math.floor(num / 1000000) + 'm';
  } else if (num >= 100000) {
    return Math.floor(num / 1000) + 'k';
  } else {
    return num.toLocaleString();
  }
}

/**
 * group records by intent title, sort and format for pie chart
 */
function formatRecords(records: IntentPredictionRecord[]) {
  const counts = countBy(
    record => record.intent_prediction?.title || OTHER_QUESTIONS,
    records,
  );
  const sorted = Object.entries(counts).sort((a, b) => b[1] - a[1]);
  return sorted.map(([name, count]) => {
    const y = (count / records.length) * 100;
    return { count, name, y };
  });
}

function getInquiryCellData(inquiry: EmailInquiry | WidgetInquiry) {
  let id: string | null = null;
  let title: string | null = null;
  let body: string;
  if (isEmailInquiry(inquiry)) {
    id = inquiry.source_id;
    title = inquiry.title;
    body = inquiry.body;
  } else {
    body = inquiry.user_input;
  }

  return {
    body,
    id,
    title,
  };
}

function InquiryCell({ inquiry }: { inquiry: EmailInquiry | WidgetInquiry }) {
  const { body, id, title } = getInquiryCellData(inquiry);

  return (
    <Box maxWidth='625px'>
      {id && title && (
        <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
          <Typography variant='font14Bold'>
            {id}&nbsp;&nbsp;&bull;&nbsp;&nbsp;{title}
          </Typography>
        </Box>
      )}
      <CellContainer>
        <Typography variant='font14'>{body}</Typography>
      </CellContainer>
    </Box>
  );
}

export default function IntentTestDetailPage() {
  const [searchText, setSearchText] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState<
    IntentPredictionRecord[]
  >([]);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { intentTestId = '' } = useParams();
  const { data: intentTestsResponse } = useGetIntentTestsQuery();
  const { data: intentTestDetailResponse, isLoading } =
    useGetIntentTestPredictionsQuery(intentTestId, {
      skip: !intentTestId,
    });

  const [selectedConversationId, setSelectedConversationId] = useStateParams({
    deserialize: value => value,
    initialState: null,
    paramsName: 'conversationId',
    serialize: value => value ?? '',
  });
  const selectedRecord = useMemo(() => {
    if (!selectedConversationId || !intentTestDetailResponse) {
      return;
    }
    return intentTestDetailResponse.records.find(
      record => record.conversation.conversation_id === selectedConversationId,
    );
  }, [selectedConversationId, intentTestDetailResponse]);
  const pieChartData = useMemo(() => {
    if (!intentTestDetailResponse?.records.length) {
      return null;
    }
    return formatRecords(intentTestDetailResponse.records);
  }, [intentTestDetailResponse]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilteredRecords = useCallback(
    debounce(300, (searchText: string) => {
      const query = searchText.trim().toLowerCase();
      if (!intentTestDetailResponse || !query) {
        setIsFiltering(false);
        return intentTestDetailResponse?.records;
      }
      const records = intentTestDetailResponse.records.filter(record => {
        const { inquiry, intent_prediction } = record;
        const intentTitle = intent_prediction?.title || OTHER_QUESTIONS;
        const matchFields = [intentTitle];

        if (isEmailInquiry(inquiry)) {
          matchFields.push(inquiry.source_id, inquiry.title, inquiry.body);
        } else {
          matchFields.push(inquiry.conversation_id, inquiry.user_input);
        }

        return matchFields.some(str => str?.toLowerCase().includes(query));
      });
      setFilteredRecords(records);
      setIsFiltering(false);
    }),
    [intentTestDetailResponse],
  );

  const selectedTest = useMemo(() => {
    if (!intentTestsResponse) {
      return;
    }
    return intentTestsResponse.intent_tests.find(
      test => test.intent_test_id === intentTestId,
    );
  }, [intentTestsResponse, intentTestId]);

  const fallbackIntentForChannel = useFallbackIntentForChannel(
    selectedTest?.channel,
  );
  const fallbackIntent = selectedTest?.fallback_workflow_id
    ? fallbackIntentForChannel
    : null;

  return (
    <>
      <NavbarV2
        currentTabOverride={selectedTest?.name}
        onGoBack={() => navigate(Routes.PREDICT_INTENTS)}
      />
      <Box
        sx={{
          bgcolor: palette.colors.white,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: 'calc(100vh - 69px)',
          overflow: 'scroll',
        }}
      >
        {pieChartData && (
          <Box bgcolor={palette.colors.white} padding='20px 56px 0'>
            <Box display='flex' gap='24px' pb='24px' pt='12px'>
              <Box flex='1 1 50%'>
                <Card title='Overview'>
                  <IntentTestPieChart
                    data={pieChartData}
                    total={formatNumberWithSuffix(
                      intentTestDetailResponse?.records.length ?? 0,
                    )}
                  />
                </Card>
              </Box>
              <Box flex='1 1 50%' />
            </Box>
          </Box>
        )}
        <Box padding='20px 40px'>
          <Box flex={1} maxWidth='400px'>
            <SearchBar
              onChange={({ target }) => {
                setSearchText(target.value);
                setIsFiltering(true);
                debouncedSetFilteredRecords(target.value);
              }}
              onClear={() => setSearchText('')}
              placeholder='Search'
              size='small'
              value={searchText}
            />
          </Box>
          <Table
            columns={[
              {
                cellRenderer: data => <InquiryCell inquiry={data.inquiry} />,
                comparatorFunction: (a, b) => {
                  if (isEmailInquiry(a.inquiry) && isEmailInquiry(b.inquiry)) {
                    return (
                      parseInt(a.inquiry.source_id) -
                      parseInt(b.inquiry.source_id)
                    );
                  } else if (
                    !isEmailInquiry(a.inquiry) &&
                    !isEmailInquiry(b.inquiry)
                  ) {
                    return a.inquiry.user_input.localeCompare(
                      b.inquiry.user_input,
                    );
                  }
                  throw new Error('Cannot compare email and widget inquiries');
                },
                id: 'inquiry',
                label: 'Inquiry',
                width: '625px',
              },
              {
                cellRenderer: ({ intent_prediction }) => {
                  return (
                    <IntentBadge
                      channel={selectedTest?.channel}
                      intentDefinitionId={
                        intent_prediction?.intent_definition_id ||
                        fallbackIntent?.intent_definition_id
                      }
                      intentName={
                        intent_prediction?.title || fallbackIntent?.intent_name
                      }
                    />
                  );
                },
                comparatorFunction: (a, b) => {
                  const aPrediction =
                    a.intent_prediction?.title ||
                    fallbackIntent?.intent_name ||
                    '';
                  const bPrediction =
                    b.intent_prediction?.title ||
                    fallbackIntent?.intent_name ||
                    '';
                  return aPrediction.localeCompare(bPrediction);
                },
                id: 'intent',
                label: 'Predicted intent',
              },
              {
                cellRenderer: ({ inquiry }) =>
                  formatReadableDatetime(new Date(inquiry.created_date)),
                comparatorFunction: (a, b) => {
                  const aDate = new Date(a.inquiry.created_date);
                  const bDate = new Date(b.inquiry.created_date);
                  return bDate.getTime() - aDate.getTime();
                },
                id: 'inquiry_created_date',
                label: 'Inquiry created',
              },
              {
                align: 'center',
                cellRenderer: row => {
                  const feedback = row.feedback_records?.[0];

                  return (
                    <FeedbackButtonGroup
                      activeSentiment={feedback?.sentiment ?? null}
                      intentDefinitionId={
                        row.intent_prediction?.intent_definition_id ?? null
                      }
                      intentTestId={intentTestId}
                      predictionRecordId={row.record_id}
                      product={
                        selectedTest?.channel === 'email'
                          ? 'solve-email'
                          : 'solve-widget'
                      }
                    />
                  );
                },
                hideSortIcon: true,
                id: 'feedback',
                label: 'Feedback',
              },
              {
                align: 'center',
                cellRenderer: () => {
                  return (
                    <Tooltip tooltipContent='Show details'>
                      <IconButton
                        aria-label='Show prediction details'
                        variant='ghost'
                      >
                        <IconInfoCircle />
                      </IconButton>
                    </Tooltip>
                  );
                },
                hideSortIcon: true,
                id: 'details',
                label: '',
              },
            ]}
            isLoading={isLoading || isFiltering}
            onClickRow={record =>
              setSelectedConversationId(record.conversation.conversation_id)
            }
            rows={
              searchText ? filteredRecords : intentTestDetailResponse?.records
            }
            shouldDisablePagination
            tableLayout='fixed'
          />
        </Box>
      </Box>
      <IntentPredictionDrawer
        feedbackButtons={
          selectedRecord && (
            <FeedbackButtonGroup
              activeSentiment={
                selectedRecord.feedback_records?.[0]?.sentiment ?? null
              }
              intentDefinitionId={
                selectedRecord.intent_prediction?.intent_definition_id ?? null
              }
              intentTestId={intentTestId}
              predictionRecordId={selectedRecord.record_id}
              product={
                selectedTest?.channel === 'email'
                  ? 'solve-email'
                  : 'solve-widget'
              }
            />
          )
        }
        onClose={() => setSelectedConversationId(null)}
        predictionRecord={selectedRecord}
      />
    </>
  );
}
const CellContainer = styled(Box)`
  line-height: 1.6;
  /* ellipsis when overflowing: */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
