import { Box, styled, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { SavingsLine } from '../../solve-insights/common/ChatsSavingsCard';
import {
  dateRangeToTimestamp,
  getTooltipForMetric,
  numbersToStringWithCommas,
  numberToCurrency,
} from '../../solve-insights/helpers';
import { getTooltipForMetric as getWorkflowTooltipForMetric } from 'src/components/dashboard-pages/solve-insights/workflows/helpers';
import { useGetWidgetAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';
import { DateRange } from 'src/types/types';

export const TotalConversationSavingCard = ({
  dateRange,
}: {
  dateRange: DateRange;
}) => {
  const { palette } = useTheme();
  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: data, isLoading } = useGetWidgetAggregateMetricsQuery({
    channel: 'voice',
    from: timestamps.start_timestamp.toString(),
    to: timestamps.end_timestamp.toString(),
  });

  if (isLoading) {
    return (
      <CardContainer>
        <Skeleton variant='text' width={100} />
        <Skeleton variant='text' width={300} />
        <Skeleton variant='text' width={300} />
      </CardContainer>
    );
  }

  const totalConversations = data?.voice_aggregate?.conversations || 0;
  const deflectedConversations = data?.voice_aggregate?.deflected || 0;
  const nonDeflectedConversations = totalConversations - deflectedConversations;

  return (
    <CardContainer>
      <Typography variant='font16Bold'>Conversations</Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          justifyContent: 'start',
        }}
      >
        <ConversationDetails
          color={palette.colors.purple[500]}
          label='deflections'
          number={deflectedConversations || 0}
        />
        <ConversationDetails
          color={palette.colors.red[500]}
          label='non-deflections'
          number={nonDeflectedConversations}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: '1px',
        }}
      >
        <SavingsLine
          tooltipContent={false}
          type='realized'
          widthPercentage={(deflectedConversations / totalConversations) * 100}
        />
        <SavingsLine
          tooltipContent={false}
          type='potential'
          widthPercentage={
            (nonDeflectedConversations / totalConversations) * 100
          }
        />
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box alignItems='center' display='flex' gap='4px'>
          <Typography variant='font14'>
            {numberToCurrency({
              number: deflectedConversations * 15,
            })}
          </Typography>
          <Box display='flex'>
            <Tooltip
              tooltipContent={getTooltipForMetric('realized_savings', 15)}
            >
              <IconInfoCircle size={20} />
            </Tooltip>
          </Box>
        </Box>
        <Box alignItems='center' display='flex' gap='4px'>
          <Typography variant='font14'>
            {numberToCurrency({
              number: nonDeflectedConversations * 15,
            })}
          </Typography>
          <Box display='flex'>
            <Tooltip
              tooltipContent={getWorkflowTooltipForMetric(
                'potential_savings',
                15,
              )}
            >
              <IconInfoCircle size={20} />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </CardContainer>
  );
};

const ConversationDetails = ({
  color,
  label,
  number,
}: {
  color: string;
  label: string;
  number: number;
}) => {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '8px' }}>
      <Box
        sx={{
          alignItems: 'baseline',
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
        }}
      >
        <Typography variant='font24'>
          {numbersToStringWithCommas({
            number,
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          ':before': {
            backgroundColor: color,
            borderRadius: '2px',
            content: '""',
            display: 'block',
            height: '12px',
            width: '12px',
          },
          alignItems: 'center',
          display: 'flex',
          gap: '4px',
        }}
      >
        <Typography variant='font14'>{label}</Typography>
      </Box>
    </Box>
  );
};

const CardContainer = styled('div')`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  background: #fff;
  padding: 24px;
  min-width: 419px;
  gap: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
