import { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { EmptyStateCard } from '../articles/EmptyStateCard';
import { DONUT_SIZE, FEEDBACK_TOOLTIP_COPY } from '../constants';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  percentageValueAsString,
  stringifyDateRange,
} from '../helpers';
import { useGetEmailFiltersFromSearch } from '../hooks/useGetEmailFiltersFromSearch';
import { useGetFiltersFromSearch } from '../hooks/useGetFiltersFromSearch';
import GraphCenterElement from '../overview/GraphCenterElement';
import { OverviewCardInformationSection } from '../overview/OverviewCardInformationSection';
import { InsightsTab, ProductMode } from '../types';
import InsightCard from './InsightCard';
import { BarGraphWrapper } from 'src/components/solve-insights-charts/bar-graph/barGraphUtils';
import DonutGraph from 'src/components/solve-insights-charts/donut-graph.tsx/DonutGraph';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  useGetEmailFeedbackQuery,
  useGetQuickFeedbackQuery,
} from 'src/services/insights';
import { QuickFeedbackResponse } from 'src/services/insights/types';

interface FeedbackCardProps {
  dateRange: DateRange;
  productMode?: ProductMode;
  tab?: InsightsTab;
}

const FeedbackCard = ({
  dateRange,
  productMode = 'widget',
  tab,
}: FeedbackCardProps) => {
  const isTopicsPage = tab === 'topic';
  const isWorkflowPage = tab === 'workflow';
  const isOverviewPage = tab === 'overview';
  const isEmail = productMode === 'email';
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;
  const theme = useTheme();
  const { palette } = theme;
  const colors = [palette.colors.green[500], palette.colors.red[500]];
  const timestamps = dateRangeToTimestamp(dateRange);
  const requestParams = useGetFiltersFromSearch({ dateRange, tab });
  const emailRequestParams = useGetEmailFiltersFromSearch({
    dateRange,
    tab,
  });
  const {
    data: quickFeedbackData,
    isFetching: isQuickFeedbackFetching,
    isLoading: isQuickFeedbackLoading,
  } = useGetQuickFeedbackQuery(
    {
      ...requestParams,
      ...timestamps,
      // TODO - Remove this after tab payload is added
      is_topics_page: isTopicsPage || false,
      // TODO - Remove this after tab payload is added
      is_workflow_page: isWorkflowPage || false,
      to_compare: isOverviewPage,
    },
    { skip: isEmail },
  );
  const {
    data: userFeedbackData,
    isFetching: isUserFeedbackFetching,
    isLoading: isUserFeedbackLoading,
  } = useGetEmailFeedbackQuery(
    {
      ...emailRequestParams,
      end: timestamps.end_timestamp,
      start: timestamps.start_timestamp,
    },
    { skip: !isEmail },
  );

  const data = useMemo(
    () => (isEmail ? userFeedbackData : quickFeedbackData),
    [isEmail, quickFeedbackData, userFeedbackData],
  );
  const isFetching = isQuickFeedbackFetching || isUserFeedbackFetching;
  const isLoading = isQuickFeedbackLoading || isUserFeedbackLoading;

  const { negativeValues, notAnsweredValues, positiveValues } = useMemo(() => {
    return {
      negativeValues: data?.misc?.negative || 0,
      notAnsweredValues: data?.misc?.not_answered || 0,
      positiveValues: data?.misc?.positive || 0,
    };
  }, [data]);

  const feedbackValues = useMemo(
    () => [
      {
        label: 'positive',
        value: positiveValues,
      },
      { label: 'negative', value: negativeValues },
      { label: 'not answered', value: notAnsweredValues },
    ],
    [positiveValues, negativeValues, notAnsweredValues],
  );

  const totalFeedback = positiveValues + negativeValues;

  const isFeedbackDataAvailable = useMemo(
    () => data && totalFeedback > 0,
    [data, totalFeedback],
  );
  const donutValues = useMemo(() => data?.donut.values, [data]);

  const donutGraphValues = useMemo(() => {
    return {
      ...data?.donut,
      values:
        data?.donut?.values
          ?.filter?.(value => value.label !== 'not answered')
          ?.map?.(value => {
            const quickFeedbackData = feedbackValues.find(
              quickFeedbackValue => quickFeedbackValue.label === value.label,
            );
            const quickFeedbackValue = quickFeedbackData?.value || 0;

            return {
              ...value,
              value:
                totalFeedback === 0
                  ? 0
                  : (quickFeedbackValue * 100) / totalFeedback,
            };
          }) || [],
    };
  }, [data, feedbackValues, totalFeedback]);

  if (!shouldShowQuickFeedbackAnalytics) {
    return null;
  }

  return (
    <InsightCard
      headerRightText={
        isOverviewPage ? stringifyDateRange(dateRange) : undefined
      }
      isFetching={isFetching}
      isLoading={isLoading}
      title={data?.misc?.title}
      tooltip={
        <Tooltip
          maxWidth={320}
          tooltipContent={
            isEmail
              ? FEEDBACK_TOOLTIP_COPY.user_feedback
              : FEEDBACK_TOOLTIP_COPY.quick_feedback
          }
        >
          <IconInfoCircle color='currentColor' height={16} width={16} />
        </Tooltip>
      }
    >
      {isFeedbackDataAvailable ? (
        <Box alignItems='center' display='flex'>
          {!isOverviewPage && (
            <Box display='flex' flex={1} flexDirection='column' gap='16px'>
              {donutValues?.map((donutValue, index) => {
                const color = colors[index];
                return (
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap='2px'
                    key={index}
                  >
                    <Typography variant='font16Bold'>
                      {numbersToStringWithCommas({
                        number: feedbackValues[index].value,
                      })}
                    </Typography>
                    <Box
                      sx={{
                        ...(color
                          ? {
                              ':before': {
                                backgroundColor: color,
                                borderRadius: '2px',
                                content: '""',
                                display: 'block',
                                height: '12px',
                                width: '12px',
                              },
                            }
                          : {}),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                      }}
                    >
                      <Typography variant='font14'>
                        {donutValue.label.toLowerCase()}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
          <Box display='flex' flex={isOverviewPage ? 0 : 1}>
            <DonutGraph
              centerElement={
                <GraphCenterElement
                  theme={theme}
                  value={data?.misc?.delta_value}
                />
              }
              centerSubText={data?.misc?.positive_label?.toLowerCase() || ''}
              centerText={numbersToStringWithCommas({
                number:
                  totalFeedback === 0 ? 0 : positiveValues / totalFeedback,
                style: 'percent',
              })}
              colors={colors}
              containerProps={{
                style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
              }}
              data={donutGraphValues}
              disableTooltip
            />
          </Box>
          {isOverviewPage && (
            <Box display='flex' flex={1} flexDirection='column' gap='20px'>
              <Box alignItems='center' display='flex' gap='16px'>
                <Box display='flex' flexDirection='column' gap='16px'>
                  <Typography variant='font14'>Total responses</Typography>
                  <Typography variant='font14'>Not answered</Typography>
                </Box>
                <Box display='flex' flexDirection='column' gap='16px'>
                  <Box alignItems='flex-end' display='flex' gap='16px'>
                    <Typography variant='font16Bold'>
                      {numbersToStringWithCommas({
                        number: totalFeedback,
                      })}
                    </Typography>
                    {typeof data?.misc?.delta_total_response === 'number' && (
                      <Typography
                        color={
                          data?.misc?.delta_total_response >= 0
                            ? palette.colors.green[500]
                            : palette.colors.red[500]
                        }
                        variant='font14Medium'
                      >
                        {percentageValueAsString(
                          data.misc.delta_total_response,
                        )}
                      </Typography>
                    )}
                  </Box>
                  <Typography variant='font16Bold'>
                    {numbersToStringWithCommas({
                      number: notAnsweredValues,
                    })}
                  </Typography>
                </Box>
              </Box>
              <BarGraphWrapper
                data={data as QuickFeedbackResponse}
                isComparing={isOverviewPage}
                palette={palette}
                showTooltip={isOverviewPage}
              />
            </Box>
          )}
        </Box>
      ) : (
        <EmptyStateCard variant='feedback' />
      )}
      {isOverviewPage && <OverviewCardInformationSection />}
    </InsightCard>
  );
};

export default FeedbackCard;
