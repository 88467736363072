import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import {
  TextField,
  theme,
} from '@forethought-technologies/forethought-elements';
import { hasAtLeastOneError } from '../handoff-configuration/helpers';
import NameEmailQuestion from '../handoff-configuration/name-email-question/NameEmailQuestion';
import { Spacer } from '../handoff-configuration/styles';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import {
  initializeFieldTouchedArr,
  validateZendeskOrSalesforceContextVariables,
} from '../handoff-configuration/zendesk/helpers';
import TalkdeskPrechatFields from './TalkdeskPrechatFields';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  TalkdeskChatHandoffActionSettings,
  ZendeskOrSalesforceContextVariables,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const TalkdeskChatHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as TalkdeskChatHandoffActionSettings;
  const initialCustomizationData: TalkdeskChatHandoffActionSettings =
    addingAction
      ? {
          email: '',
          name: '',
          prechat_fields: [],
          question: 'free_form_query',
          transcript_field: null,
        }
      : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<TalkdeskChatHandoffActionSettings>(initialCustomizationData);
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  const errorMessages = validateZendeskOrSalesforceContextVariables({
    customizationData: customizationData,
    shouldAllowEmptyName: true,
  });

  const emailErrorMessage = isFieldTouchedArr[FieldNameToIdx.EMAIL]
    ? errorMessages[FieldNameToIdx.EMAIL]
    : '';
  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Talkdesk Chat Handoff</Title>
        <Spacer height='20px' />
        <NameEmailQuestion
          customizationData={customizationData}
          errorMessages={errorMessages}
          isFieldTouchedArr={isFieldTouchedArr}
          setCustomizationData={
            setCustomizationData as Dispatch<
              SetStateAction<ZendeskOrSalesforceContextVariables>
            >
          }
          setIsFieldTouchedArr={setIsFieldTouchedArr}
          shouldAllowEmptyName
        />
        <Spacer height='28px' />
        <TalkdeskPrechatFields
          customizationData={customizationData}
          setCustomizationData={setCustomizationData}
        />
        <Spacer height='28px' />
        <TextField
          label='Transcript Field'
          onChange={e => {
            setCustomizationData({
              ...customizationData,
              transcript_field: e.target.value,
            });
          }}
          placeholder='e.g. cf_forethought_transcript'
          tooltipContent='The custom conversation property in Talkdesk to pass the transcript to.'
          value={customizationData.transcript_field || ''}
        />
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={
            isPristine ||
            hasWorkflowConflict ||
            Boolean(emailErrorMessage) ||
            Boolean(questionErrorMessage)
          }
          onClick={() => {
            setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));

            if (hasAtLeastOneError(errorMessages)) {
              return;
            }

            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type: ActionBuilderActionTypes.TALKDESK_CHAT_HANDOFF,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.TALKDESK_CHAT_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default TalkdeskChatHandoffCustomizationPanel;

const Title = styled('h1')`
  font-size: var(--font-size-m);
  text-align: left;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  cursor: default;
  margin: 0 10px;
`;
