import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  getEmailEnabledLanguagages,
  getWorkflowBuilderLanguagesEnabledAndTranslations,
  getWorkflowBuilderLanguagesEnabledForStep,
  HTTP_STATUS_NOT_FOUND,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import {
  selectIntentWorkflowId,
  selectStepLevelTranslationsId,
  selectTranslationsTableMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { useLazyGetEmailTranslationsQuery } from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import { updateWorkflowBuilderLanguagesEnabledAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { useAppDispatch } from 'src/store/hooks';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';

export const useGetTranslationChannels = () => {
  const [search] = useSearchParams();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const SolveEmailTranslationEnabled = featureFlags?.includes(
    'solve_email_translation_enabled',
  );
  const isSolveWidgetEnabled = featureFlags?.includes('solve_widget_enabled');
  const view = search.get('view');
  const isSolveWidgetCanvasView = view === 'widget';
  const isSolveEmailCanvasView = view === 'email';
  const isApiCanvasView = view === 'api';
  const isSlackCanvasView = view === 'slack';
  const isSolveEmailEnabled =
    featureFlags?.includes('solve_email_enabled') &&
    SolveEmailTranslationEnabled;
  const isInteractiveEmailEnabled =
    isSolveEmailEnabled && featureFlags?.includes('interactive_email');
  const isSolveApiEnabled = featureFlags.includes('solve_api');
  const isSolveSlackEnabled = featureFlags.includes('solve_slack');
  const isPlaybookEnabled = orgConfigData?.is_assist_gpt_enabled;

  const isEmailTabVisibleInCanvas =
    isSolveEmailEnabled && isSolveEmailCanvasView;

  const isWidgetTabVisibleInCanvas =
    isSolveWidgetEnabled && isSolveWidgetCanvasView;

  return useMemo<Array<TranslationChannel>>(() => {
    if (isWidgetTabVisibleInCanvas) {
      return ['widget'] satisfies Array<TranslationChannel>;
    }

    if (isEmailTabVisibleInCanvas) {
      const channels: Array<TranslationChannel> = ['email'];

      if (isInteractiveEmailEnabled) {
        return [...channels, 'interactive_email'];
      }
      return channels;
    }

    if (isApiCanvasView) {
      return ['api'] satisfies Array<TranslationChannel>;
    }

    if (isSlackCanvasView) {
      return ['slack'] satisfies Array<TranslationChannel>;
    }

    const channels: Array<TranslationChannel> = [
      'widget',
      'email',
      'interactive_email',
      'api',
      'slack',
      'playbook',
    ];

    return channels.filter(channel => {
      if (channel === 'widget') {
        return isSolveWidgetEnabled;
      }
      if (channel === 'email') {
        return isSolveEmailEnabled;
      }
      if (channel === 'interactive_email') {
        return isInteractiveEmailEnabled;
      }

      if (channel === 'slack') {
        return isSolveSlackEnabled;
      }

      if (channel === 'api') {
        return isSolveApiEnabled;
      }

      if (channel === 'playbook') {
        return isPlaybookEnabled;
      }

      return true;
    });
  }, [
    isWidgetTabVisibleInCanvas,
    isEmailTabVisibleInCanvas,
    isApiCanvasView,
    isSlackCanvasView,
    isInteractiveEmailEnabled,
    isSolveWidgetEnabled,
    isSolveEmailEnabled,
    isSolveSlackEnabled,
    isSolveApiEnabled,
    isPlaybookEnabled,
  ]);
};

export const useGetEmailChannelData = () => {
  const dispatch = useAppDispatch();
  const [getEmailTranslationsTriggger] = useLazyGetEmailTranslationsQuery();
  const tableMode = useSelector(selectTranslationsTableMode);

  return useCallback(async () => {
    dispatch(getEmailEnabledLanguagages())
      .then(enabledLanguages => {
        if (enabledLanguages.length > 0) {
          getEmailTranslationsTriggger({
            mode: tableMode,
            targetLanguage: enabledLanguages[0],
          });
        }
      })
      .catch(error => {
        if (error.code == HTTP_STATUS_NOT_FOUND) {
          // Config not found - create it
          updateWorkflowBuilderLanguagesEnabledAPI({
            channel: 'email',
            language_codes: [],
            modified_date: null,
          });
        }
      });
  }, [dispatch, getEmailTranslationsTriggger, tableMode]);
};

export const useGetWidgetChannelData = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const dispatch = useAppDispatch();
  const stepLevelTranslationsId = useSelector(selectStepLevelTranslationsId);
  const currentWorkflowId = useSelector(selectIntentWorkflowId);
  const tableMode = useSelector(selectTranslationsTableMode);

  return useCallback(() => {
    Promise.all([
      // Get draft widget config
      stepLevelTranslationsId
        ? dispatch(
            getWorkflowBuilderLanguagesEnabledForStep(
              currentWorkflowId || '',
              stepLevelTranslationsId,
            ),
          )
        : dispatch(
            getWorkflowBuilderLanguagesEnabledAndTranslations(tableMode),
          ),
    ]).then(() => setIsLoading(false));
  }, [
    dispatch,
    setIsLoading,
    currentWorkflowId,
    stepLevelTranslationsId,
    tableMode,
  ]);
};
