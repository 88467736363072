import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { theme, Tooltip } from '@forethought-technologies/forethought-elements';
import {
  Label,
  Row,
  Section,
  Spacer,
  StyledInput,
} from '../handoff-configuration/styles';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { updateActionSettings } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import type { RootState } from 'src/store/rootReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { TriggerEventCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

type TriggerEventCustomizationPanelProps = {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
};

const TriggerEventCustomizationPanel: FC<
  React.PropsWithChildren<TriggerEventCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const actionSettings = canvasState?.actionSettings;
  // @ts-expect-error legacy code with untyped state
  const triggerEventCustomizationSettings: TriggerEventCustomizationSettings =
    useSelector(
      (state: RootState) =>
        state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
    );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const [customizationData, setCustomizationData] =
    useState<TriggerEventCustomizationSettings>(
      triggerEventCustomizationSettings,
    );
  const isPristine = isEqual(
    customizationData,
    triggerEventCustomizationSettings,
  );

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (triggerEventCustomizationSettings) {
      setCustomizationData({ ...triggerEventCustomizationSettings });
    }
  }, [triggerEventCustomizationSettings]);

  const addContextVariable = () => {
    setCustomizationData(data => {
      return {
        ...data,
        context_variables_to_update: [
          ...customizationData.context_variables_to_update,
          '',
        ],
      };
    });
  };

  const deleteContextVariable = (indexToDelete: number) => {
    setCustomizationData(data => {
      return {
        ...data,
        context_variables_to_update: data.context_variables_to_update.filter(
          (cv, index) => index !== indexToDelete,
        ),
      };
    });
  };

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Trigger Event</Title>
        <Spacer height='20px' />
        <Section>
          <Row>
            <Label>Event Name</Label>
            <Tooltip
              tooltipContent={
                'This is the event name that you can use to distinguish between different trigger event actions you decide to use.'
              }
            >
              <Box component='img' mb={1} ml={0.5} src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          <StyledInput
            onChange={({ target }) => {
              setCustomizationData(data => {
                return {
                  ...data,
                  event_name: target.value,
                };
              });
            }}
            placeholder='Event Name'
            type='text'
            value={customizationData?.event_name}
            width='inherit'
          />
          <Spacer height='20px' />
          <Row>
            <Label>Context Variables to Update</Label>
            <Tooltip
              tooltipContent={
                'This is a list of context variables that your custom code will need to send back to Forethought in order to continue the conversation.'
              }
            >
              <Box component='img' mb={1} ml={0.5} src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          {customizationData.context_variables_to_update.map((cv, index) => {
            return (
              <Box
                key={index}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  pb: 1,
                }}
              >
                <Box sx={{ display: 'block', width: '100%' }}>
                  <ContextVariableSelectDropdown
                    aria-label='Select a Context Variable'
                    id={`select-context-variable-${index}`}
                    onChange={value => {
                      if (value) {
                        const newContextVariables = [
                          ...customizationData.context_variables_to_update,
                        ];
                        newContextVariables.splice(index, 1, value);

                        setCustomizationData(data => {
                          return {
                            ...data,
                            context_variables_to_update: [
                              ...newContextVariables,
                            ],
                          };
                        });
                      }
                    }}
                    value={cv}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '42px',
                    justifyContent: 'flex-end',
                    width: '30px',
                  }}
                >
                  <DeleteButton
                    alt='delete context variable icon'
                    onClick={() => {
                      deleteContextVariable(index);
                    }}
                    role='button'
                    src={xIcon}
                  />
                </Box>
              </Box>
            );
          })}
        </Section>
        <Add
          isDisabled={customizationData.context_variables_to_update.length >= 5}
          onClick={addContextVariable}
        >
          + Add Context Variable
        </Add>
      </Box>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: actionSettings.action_type as string,
                configuration_fields: customizationData,
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.TRIGGER_EVENT,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default TriggerEventCustomizationPanel;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const Add = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 16px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;
