import { AllConversationChannel } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

export const HANDOFF_UNSECCESFUL_BRANCH_MESSAGE =
  'As a result, the handoff process will fail, causing the workflow to follow the unsuccessful handoff branch.';

export const MISSING_CONTEXT_VARIABLE = 'Missing Context Variable';

export const CONTEXT_VARIABLE_TYPE_MISMATCH = 'Context Variable type mismatch';

export const NO_VALID_CONDITION = 'No Valid Condition';

export const ZENDESK_REQUESTER_EMAIL_SUSPENDED =
  'Zendesk: requester email is suspended';

export const ZENDESK_REQUESTER_EMAIL_INVALID =
  'Zendesk: requester email is of invalid format';

export const ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS =
  'Zendesk: requester email is a support address and cannot be used';

export const ZENDESK_VALUE_INVALID_FORMAT =
  'Zendesk: provided value is of invalid format';

export const CANNOT_INSERT_UPDATE_ACTIVATE_ENTITY =
  'Salesforce: case entity operation error for insert or update';

export const INSUFFICIENT_ACCESS_ON_CROSS_REFERENCE_ENTITY =
  'Salesforce: Insufficient Access on Cross Reference Entity';

export const MALFORMED_ID = 'Salesforce: invalid or malformed id';

export const INVALID_CROSS_REFERENCE_KEY = 'Salesforce: invalid reference key';

export const FIELD_INTEGRITY_EXCEPTION =
  'Salesforce: field integrity exception';

export const SALESFORCE_MESSAGING_WIDGET_NOT_FOUND_ERROR =
  'Salesforce Messaging Widget not found';
export const ZENDESK_MESSAGING_WIDGET_NOT_FOUND_ERROR =
  'Zendesk Messaging Widget not found';
export const FRESHCHAT_WIDGET_NOT_FOUND_ERROR = 'Freshchat Widget not found';
export const SALESFORCE_CHAT_BUTTON_NOT_FOUND_ERROR =
  'Salesforce Chat Button not found';
export const SALESFORCE_WIDGET_NOT_FOUND_ERROR = 'Salesforce Widget not found';
export const ZENDESK_WIDGET_NOT_FOUND_ERROR = 'Zendesk Widget not found';
export const KUSTOMER_WIDGET_NOT_FOUND_ERROR = 'Kustomer Widget not found';
export const KUSTOMER_CONVERSATION_ERROR =
  'Failed to create Kustomer conversation';
export const SNAPENGAGE_WIDGET_NOT_FOUND_ERROR = 'SnapEngage Widget not found';

export const getErrorDescriptionByMessage = (errorMessage: string) => {
  switch (errorMessage) {
    case MISSING_CONTEXT_VARIABLE:
      return 'This error occurs when a workflow in the widget encounters a step that contains an undefined Context Variable (CV).';
    case CONTEXT_VARIABLE_TYPE_MISMATCH:
      return 'This error occurs when a parameter does not match the type of its associated Context Variable. Ensure that the parameter type aligns with the variable type in the configuration.';
    case NO_VALID_CONDITION:
      return 'This error occurs when executing a condition step and no valid branch is found. For example, all conditions failed in the condition step.';
    case ZENDESK_REQUESTER_EMAIL_INVALID:
      return 'This error happens when a request is sent to the Zendesk API with an improperly formatted requester email (e.g., ‘test@aa’).';
    case ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS:
      return 'This error occurs when a request is sent to the Zendesk API using a requester’s email that is already used as a support address.';
    case ZENDESK_REQUESTER_EMAIL_SUSPENDED:
      return 'This error occurs when a request is sent to the Zendesk API using a suspended requester email.';
    case ZENDESK_VALUE_INVALID_FORMAT:
      return 'This error happens when sending a request to the Zendesk API with a value that does not match the expected field type (e.g., passing a text for a number field).';
    case CANNOT_INSERT_UPDATE_ACTIVATE_ENTITY:
      return 'This error occurs when Salesforce cannot insert, update, or activate a record due to issues such as validation rule failures, trigger exceptions, or process builder errors.';
    case INSUFFICIENT_ACCESS_ON_CROSS_REFERENCE_ENTITY:
      return 'This error indicates that the user does not have sufficient permissions to access or modify a related record referenced during the operation.';
    case MALFORMED_ID:
      return 'This error occurs when an invalid ID is provided in the request, such as an ID with null value or with incorrect length or format.';
    case INVALID_CROSS_REFERENCE_KEY:
      return 'This error indicates that a foreign key reference (such as a lookup field) is invalid or the user does not have access to the referenced record.';
    case FIELD_INTEGRITY_EXCEPTION:
      return "This error occurs when there's a problem with the data integrity of a field, such as assigning a value that doesn't comply with the field's settings or constraints.";
    case SALESFORCE_MESSAGING_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('Salesforce Messaging Widget');
    case ZENDESK_MESSAGING_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('Zendesk Messaging Widget');
    case FRESHCHAT_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('Freshchat Widget');
    case SALESFORCE_CHAT_BUTTON_NOT_FOUND_ERROR:
    case SALESFORCE_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('Salesforce Widget');
    case ZENDESK_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('Zendesk Widget');
    case KUSTOMER_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('Kustomer Widget');
    case KUSTOMER_CONVERSATION_ERROR:
      return `This error occurs when the system fails to create a conversation during Kustomer handoff exeuction. ${HANDOFF_UNSECCESFUL_BRANCH_MESSAGE}`;
    case SNAPENGAGE_WIDGET_NOT_FOUND_ERROR:
      return getIntegrationWidgetType('SnapEngage Widget');
    default:
      return 'N/A';
  }
};

export const getChatUrl = ({
  channel,
  chatsTabIndex,
  conversationId,
}: {
  channel: AllConversationChannel | null;
  chatsTabIndex: number;
  conversationId: string | null;
}) => {
  if (!conversationId || !channel) {
    return null;
  }

  if ((channel === 'widget' || channel === 'sunshine') && chatsTabIndex >= 0) {
    return `${Routes.SOLVE_INSIGHTS_MAIN}?tab=${chatsTabIndex}&chats_search=${conversationId}`;
  }

  return `${Routes.WORKFLOW_BUILDER}?header=chats&conversationId=${conversationId}&channel=${channel}`;
};

export const sortColumnsValidator = () => (sortColumn: string) => {
  const validColums = ['timestamp', 'status_code'];
  return validColums.includes(sortColumn);
};

export const getIntegrationWidgetType = (integrationWidgetType: string) => {
  return `This error indicates that the script required to load the ${integrationWidgetType} is either missing or has not loaded correctly. ${HANDOFF_UNSECCESFUL_BRANCH_MESSAGE}`;
};

export const getHowToFixHandoffMessage = (integrationWidgetType: string) => {
  return `Check your webpage source code to ensure the ${integrationWidgetType} script is present. If missing, add it and confirm it loads correctly.`;
};
