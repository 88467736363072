import { useCallback, useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { styled } from '@mui/material';
import {
  BotTTSTextData,
  RTVIEvent,
  RTVIMessage,
  TranscriptData,
  TransportState,
} from '@pipecat-ai/client-js';
import {
  useRTVIClient,
  useRTVIClientEvent,
  useRTVIClientTransportState,
} from '@pipecat-ai/client-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { IntentFiltersButton } from '../../IntentFiltersButton';
import { useGetThemeBasedOnMode } from '../../utils';
import { PhoneNumberSelector } from '../PhoneNumberSelector';
import { PreviewTranscriptComponent } from './PreviewTranscriptComponent';
import { VoiceTranscriptPayload } from './type';
import set from 'lodash/fp/set';
import { MANDARIN } from 'src/pages/solve-config/voice/constants';
import { useGetPreviewNumberLanguage } from 'src/pages/solve-config/voice/utils';
import { PreviewVoiceTranscript } from 'src/slices/workflow-preview/types';

export function VoiceInfoConsole() {
  const endRef = useRef<HTMLDivElement>(null);
  const theme = useGetThemeBasedOnMode();
  const rtviClient = useRTVIClient();
  const transportState = useRTVIClientTransportState();
  const isIdle = ['error', 'disconnected', 'disconnecting'].includes(
    transportState,
  );
  const [transcripts, setTranscripts] = useState<PreviewVoiceTranscript[]>([]);
  const previewLanguage = useGetPreviewNumberLanguage();

  useEffect(() => {
    if (endRef?.current) {
      endRef.current.scrollIntoView();
    }
  }, [transcripts]);

  useEffect(() => {
    if (transportState === 'initializing' || transportState === 'connecting') {
      setTranscripts([]);
    }
  }, [transportState]);

  const updateLatestBotTranscript = useCallback(
    (botText: string) => {
      setTranscripts(prevTranscripts => {
        // Don't prefix the text with space if in Mandarin convo
        const spacedText =
          previewLanguage === MANDARIN ? botText : ` ${botText}`;

        const latestTranscript = prevTranscripts[prevTranscripts.length - 1];
        const latestBotTranscriptIndex = prevTranscripts.findLastIndex(
          t => t.type === 'bot',
        );

        if (
          latestTranscript === undefined ||
          latestBotTranscriptIndex === -1 ||
          latestTranscript.type === 'user'
        ) {
          return [
            ...prevTranscripts,
            {
              text: botText,
              type: 'bot',
            },
          ];
        }

        const latestBotTranscript = prevTranscripts[latestBotTranscriptIndex];
        // Latest transcript is not a bot message, use the latest Bot transcript message
        if (latestTranscript.type !== 'bot') {
          if (latestBotTranscript.isFinal) {
            const newLatestTranscript: PreviewVoiceTranscript = {
              isFinal: false,
              text: botText,
              type: 'bot',
            };

            return prevTranscripts.concat([newLatestTranscript]);
          } else {
            const newLatestBotTranscript = {
              ...latestBotTranscript,
              text: latestBotTranscript.text.concat(spacedText),
            };

            const updatedTranscripts: PreviewVoiceTranscript[] = set(
              [latestBotTranscriptIndex],
              newLatestBotTranscript,
              prevTranscripts,
            );

            return updatedTranscripts;
          }
        }

        if (latestTranscript.isFinal) {
          const newLatestTranscript: PreviewVoiceTranscript = {
            isFinal: false,
            text: botText,
            type: 'bot',
          };

          return prevTranscripts.concat([newLatestTranscript]);
        }

        const newLatestTranscript = {
          ...latestTranscript,
          text: latestTranscript.text.concat(spacedText),
        };

        const updatedTranscripts: PreviewVoiceTranscript[] = set(
          [prevTranscripts.length - 1],
          newLatestTranscript,
          prevTranscripts,
        );
        return updatedTranscripts;
      });
    },
    [previewLanguage],
  );

  const updateLatestUserTranscript = useCallback(
    (userText: string, isFinal: boolean) => {
      setTranscripts(prevTranscripts => {
        const latestTranscript = prevTranscripts[prevTranscripts.length - 1];

        if (
          latestTranscript === undefined ||
          latestTranscript.type !== 'user'
        ) {
          return [
            ...prevTranscripts,
            {
              text: userText,
              type: 'user',
            },
          ];
        }

        // if latestTranscript msg is a user transcript and has isFinal true, we should create a new user transcript
        if (latestTranscript.isFinal) {
          const newLatestTranscript: PreviewVoiceTranscript = {
            isFinal: isFinal,
            text: userText,
            type: 'user',
          };

          return prevTranscripts.concat([newLatestTranscript]);
        }

        // prev transcript is not a final user transcript, modify the transcript directly
        const newLatestTranscript = {
          ...latestTranscript,
          isFinal: isFinal,
          text: userText,
        };

        const updatedTranscripts: PreviewVoiceTranscript[] = set(
          [prevTranscripts.length - 1],
          newLatestTranscript,
          prevTranscripts,
        );
        return updatedTranscripts;
      });
    },
    [],
  );

  const addPreviewLogTranscript = useCallback(
    (payload: VoiceTranscriptPayload) => {
      setTranscripts(prevTranscripts => {
        const previewLogTranscript: PreviewVoiceTranscript = {
          payload: payload,
          text: '',
          type: 'preview-log',
        };

        return prevTranscripts.concat(previewLogTranscript);
      });
    },
    [],
  );

  useRTVIClientEvent(
    RTVIEvent.TransportStateChanged,
    useCallback(
      (state: TransportState) => {
        if (state === 'ready') {
          const message: RTVIMessage = {
            data: 'preview-start',
            id: 'preview-start',
            label: 'preview-start',
            type: 'text',
          };

          rtviClient?.sendMessage(message);
        }
      },
      [rtviClient],
    ),
  );

  useRTVIClientEvent(
    RTVIEvent.BotTtsText,
    useCallback(
      (data: BotTTSTextData) => {
        updateLatestBotTranscript(data.text);
      },
      [updateLatestBotTranscript],
    ),
  );

  useRTVIClientEvent(
    RTVIEvent.BotStoppedSpeaking,
    useCallback(() => {
      setTranscripts(prevTranscripts => {
        const latestTranscript = prevTranscripts[prevTranscripts.length - 1];
        const latestBotTranscriptIndex = prevTranscripts.findLastIndex(
          t => t.type === 'bot',
        );
        if (
          !latestTranscript ||
          latestTranscript.type === 'user' ||
          latestBotTranscriptIndex === -1
        ) {
          return prevTranscripts;
        }

        const latestBotTranscript = prevTranscripts[latestBotTranscriptIndex];
        const updatedTranscripts: PreviewVoiceTranscript[] = set(
          [latestBotTranscriptIndex],
          { ...latestBotTranscript, isFinal: true },
          prevTranscripts,
        );

        return updatedTranscripts;
      });
    }, []),
  );

  useRTVIClientEvent(
    RTVIEvent.UserTranscript,
    useCallback(
      (data: TranscriptData) => {
        updateLatestUserTranscript(data.text, data.final);
      },
      [updateLatestUserTranscript],
    ),
  );

  useRTVIClientEvent(
    RTVIEvent.ServerMessage,
    useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => {
        const payload = { ...data.payload, type: data.type };
        addPreviewLogTranscript(payload as VoiceTranscriptPayload);
      },
      [addPreviewLogTranscript],
    ),
  );

  return (
    <VoiceInfoConsoleContainer>
      <ThemeProvider theme={theme}>
        <Typography variant='font16Bold'>Filter intents for preview</Typography>

        <IntentFiltersButton
          disableUpdateFilter={!isIdle}
          hideModeColumn={true}
        />

        <PhoneNumberSelector />

        <TranscriptContainer>
          {transcripts.map((transcript, index) => (
            <PreviewTranscriptComponent key={index} transcript={transcript} />
          ))}
          <div ref={endRef} />
        </TranscriptContainer>
      </ThemeProvider>
    </VoiceInfoConsoleContainer>
  );
}

const VoiceInfoConsoleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  background-color: rgb(55, 55, 55, 0.3);
  color: white;
  padding: 24px;
  gap: 16px;

  box-shadow: ${props => props.theme.shadows[3]};
`;

const TranscriptContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  overflow: auto;
  padding: 0 8px 0 0;
`;
