import { Box, styled } from '@mui/material';

import { ExecutionIndicator } from '../ConversationTranscript/ExecutionIndicator';
import { TranscriptComponent } from './TranscriptComponent';
import { VoiceTranscript } from './types';
import { formatTimestampToDate } from './utils';

export default function VoiceTranscripts({
  transcripts,
}: {
  transcripts: VoiceTranscript[];
}) {
  if (transcripts.length === 0) {
    return null;
  }

  const startTime = transcripts[0].start_timestamp || 0;

  return (
    <DrawerContainer>
      <ExecutionIndicator
        message='Conversation started with bot'
        timestamp={formatTimestampToDate(startTime)}
      />
      {transcripts.map((transcript, index) => {
        return (
          <TranscriptComponent
            key={index}
            startTime={startTime}
            transcript={transcript}
          />
        );
      })}
    </DrawerContainer>
  );
}

export const CellContainer = styled(Box)`
  line-height: 1.6;
  /* ellipsis when overflowing: */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DrawerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
