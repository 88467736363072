import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormProps } from '@rjsf/core';

import { Button, theme } from '@forethought-technologies/forethought-elements';
import {
  createConnector,
  updateConnectorDisplayName,
} from '../../../../../slices/connectors/thunks';
import DrawerSection from '../../DrawerSection';
import ConnectConnectorButton from '../ConnectConnectorButton';
import { useSaveDisplayName } from '../hooks/useSaveDisplayName';
import IntegrationForm from '../IntegrationForm';
import useHandleConnectOAuthSetupType from './hooks/useHandleConnectOAuthSetupType';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';
import { selectConnectors } from 'src/slices/connectors/connectorsSlice';
import { useAppDispatch } from 'src/store/hooks';

export interface CreateIntegrationFormProps extends FormProps<Credentials> {
  connectorDefinition: ConnectorDefinition;
  existingNames?: string[];
  multiConnectorsEnabled: boolean;
  /* When using the create form to re-start oauth flow for an existing oauth connector */
  selectedConnector?: Connector;
}

export default function CreateIntegrationForm({
  connectorDefinition,
  existingNames,
  multiConnectorsEnabled,
  selectedConnector,
  ...rest
}: CreateIntegrationFormProps) {
  const dispatch = useAppDispatch();

  const {
    handleConnectOAuthSetupType,
    handleConnectOAuthSetupTypeForConnector,
  } = useHandleConnectOAuthSetupType({
    connectorDefinition,
    multiConnectorsEnabled,
  });

  const { isUpdateLoading } = useSelector(selectConnectors);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isDisplayNameInvalid, setIsDisplayNameInvalid] = useState(false);
  const [isOAuthFlowStarted, setIsOAuthFlowStarted] = useState(false);

  const { currentDisplayName, setCurrentDisplayName } = useSaveDisplayName({
    connectorId: selectedConnector?.connector_id || '',
    initialDisplayName: selectedConnector?.display_name || '',
  });

  const handleConnectFormSetupType = async (formData: Credentials) => {
    const formDataWithDisplayName = {
      ...formData,
      display_name: currentDisplayName,
    };

    try {
      const resultAction = await dispatch(
        createConnector({
          connectorDefinitionSlug: connectorDefinition.slug,
          connectorFields: formDataWithDisplayName,
          connectorTypes: connectorDefinition.connector_types,
        }),
      );
      const payload = unwrapResult(resultAction);
      return payload;
    } catch (error) {
      console.error('Failed to create connector', error);
      return null;
    }
  };

  const handleConnect = async ({ formData }: { formData?: Credentials }) => {
    if (!formData) {
      return;
    }

    const { setup_type } = connectorDefinition;

    if (selectedConnector?.is_active && multiConnectorsEnabled) {
      // Restart Auth Flow
      handleConnectOAuthSetupTypeForConnector(
        selectedConnector.connector_id,
        formData,
        currentDisplayName,
      );
      return;
    }

    if (setup_type === 'OAUTH') {
      setIsOAuthFlowStarted(true);
      handleConnectOAuthSetupType(formData, currentDisplayName);
    } else if (setup_type === 'FORM') {
      const connectorResponse = await handleConnectFormSetupType(formData);
      if (!connectorResponse) {
        console.error('Connector creation failed');
        return;
      }

      const { connector_definition, connector_id } = connectorResponse;

      const displayNameResponse = await dispatch(
        updateConnectorDisplayName({
          connectorId: connector_id,
          displayName: currentDisplayName,
        }),
      );

      if (!updateConnectorDisplayName.fulfilled.match(displayNameResponse)) {
        console.error('Failed to update display name', displayNameResponse);
        return;
      }

      window.location.href = `/integrations?integrationSlug=${connector_definition.slug}&connectorId=${connector_id}`;
    }
  };

  const formButtons = useMemo(
    () => (
      <>
        {selectedConnector?.is_active && (
          <ConnectConnectorButton
            connector={selectedConnector}
            disabled={
              !isFormValid || isDisplayNameInvalid || isOAuthFlowStarted
            }
          />
        )}
        <Button
          disabled={
            isUpdateLoading ||
            !isFormValid ||
            (multiConnectorsEnabled
              ? isDisplayNameInvalid || !currentDisplayName
              : false) ||
            isOAuthFlowStarted
          }
          fullWidth
          isLoading={isUpdateLoading}
          size='large'
          type='submit'
          variant='secondary'
        >
          {!multiConnectorsEnabled && selectedConnector?.is_active
            ? 'Restart Auth Flow'
            : 'Connect'}
        </Button>
      </>
    ),
    [
      selectedConnector,
      isFormValid,
      isDisplayNameInvalid,
      isUpdateLoading,
      currentDisplayName,
      multiConnectorsEnabled,
      isOAuthFlowStarted,
    ],
  );

  return (
    <>
      {connectorDefinition.setup_instructions && (
        <DrawerSection title='Setup Instructions'>
          <SetupInstructions
            dangerouslySetInnerHTML={{
              __html: connectorDefinition.setup_instructions,
            }}
          />
        </DrawerSection>
      )}
      <IntegrationForm
        displayName={currentDisplayName}
        existingNames={existingNames}
        formButtons={formButtons}
        formData={{}}
        isCreate
        multiConnectorsEnabled={multiConnectorsEnabled}
        onDisplayNameChange={setCurrentDisplayName}
        onSubmit={handleConnect}
        requiresAuthentication={connectorDefinition.setup_type === 'OAUTH'}
        setIsDisplayNameInvalid={setIsDisplayNameInvalid}
        setIsFormValid={setIsFormValid}
        {...rest}
      />
    </>
  );
}

const SetupInstructions = styled('div')`
  color: ${theme.palette.grey[700]};
  font-size: 14px;
  line-height: 24px;

  ol,
  ul {
    padding-inline-start: 16px;
  }
`;
