import QuickFeedbackTabContent from '../csat-tab-content/QuickFeedbackTabContent';
import FallbackTabContent from '../fallback-tab-content/FallbackTabContent';
import InstructionTabContent from '../instruction-tab-content/InstructionTabContent';
import KnowledgeTabContent from '../knowledge-tab-content/KnowledgeTabContent';
import { ToneTabContentContainer } from '../tone-tab-content';
import { PlaybookTabKeys } from './types';

interface WidgetContentProps {
  widgetType: PlaybookTabKeys;
}
const WidgetContent = ({ widgetType }: WidgetContentProps) => {
  if (widgetType === 'tone-of-voice') {
    return <ToneTabContentContainer isPlaybook />;
  }
  if (widgetType === 'quick-feedback') {
    return <QuickFeedbackTabContent isPlaybook />;
  }
  if (widgetType === 'knowledge') {
    return <KnowledgeTabContent />;
  }

  if (widgetType === 'instructional-note') {
    return <InstructionTabContent isPlaybook />;
  }

  if (widgetType === 'fallback') {
    return <FallbackTabContent />;
  }

  return null;
};

export default WidgetContent;
