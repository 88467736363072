import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FALLBACK_INTENT_DEF_ID } from '../constants';
import { useGetIntents } from '../hooks/useGetIntents';
import ConnectIntegration from '../knowledge-tab-content/ConnectIntegration';
import ConnectionList from '../knowledge-tab-content/ConnectionList';
import { BorderLine } from '../knowledge-tab-content/KnowledgeTabContent';
import { Routes } from 'src/utils/enums';

const FallbackTabContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: false });

  const handleNavigate = () => {
    const fallbackIntent = intents.find(
      intent => intent.intent_definition_id === FALLBACK_INTENT_DEF_ID,
    );

    if (fallbackIntent) {
      const urlParams = new URLSearchParams({
        intentId: fallbackIntent.intent_definition_id,
        view: params.get('channel') || 'widget',
        workflowId: fallbackIntent.intent_workflow_id,
      });
      navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${urlParams}`);
    }
  };

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <ConnectIntegration isUsedByHandoffTab />
      <BorderLine />
      <Typography variant='font16Bold'>Fallback</Typography>
      <Box pt={3} />
      <Button fullWidth onClick={handleNavigate} variant='secondary'>
        <Typography variant='font14Bold'>Build Fallback in workflow</Typography>
      </Button>
      <Box pt={3} />
      <Typography color={theme.palette.colors.grey[600]} variant='font12'>
        The fallback activates if the ‘Knowledge Retrieval’ workflow fails,
        providing your predefined response before handing off to an agent.
      </Typography>
      <BorderLine />
      <ConnectionList type='help_desk' />
    </Box>
  );
};

export default FallbackTabContent;
