import { AttachmentAnalysisUploadStepFields } from './types/dataTypes';
import { CanvasWorkflowBuilderState } from './workflowBuilderSlice';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { CompositeConditionsConfig } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { isDynamicArticleSuggestion } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import {
  ButtonOption,
  ButtonsStepFields,
  CsatTriggerPointStepFields,
  EmbedStepFields,
  FormField,
  FormStepFields,
} from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';

export const emptyButtonOption: ButtonOption = {
  display_name: '',
  value: '',
};

const emptyButtonFields: ButtonsStepFields = {
  multi_select: false,
  options: [emptyButtonOption, emptyButtonOption],
  output_variable: '',
  prompt: '',
};

export const emptyForm: FormField = {
  dropdown_options: [],
  input_type: 'SHORT_TEXT',
  output_variable: '',
  placeholder: '',
  prepopulated_field: '',
};

export const emptyAttachmentAnalysisUploadStepFields: AttachmentAnalysisUploadStepFields =
  {
    allow_skip_attachment: false,
    num_files: 3,
    output_variable: '',
    prompt: '',
  };

export const emptyCsatTriggerPoint: CsatTriggerPointStepFields = {
  delay: 0,
  negative_rating_routing_intent: undefined,
  should_use_delay: false,
};

const emptyFormStepFields: FormStepFields = {
  form_fields: [emptyForm],
  hide_prepopulated_fields: false,
  pre_populate_fields: false,
  prompt: '',
};

const emptyEmbedStepFields: EmbedStepFields = {
  embed_url: '',
};

export const emptyCondition = {
  contextVariable: '',
  operator: 'is',
  value: '',
} as const;

export const emptyConditionStepFields: CompositeConditionsConfig = {
  compositeConditions: [{ conditions: [{ ...emptyCondition }] }],
  conditionName: '',
  otherwiseCondition: { isOtherwiseSelected: true, stepId: null },
};

export const transformModeToEmptyFields = (mode: CanvasModes | null) => {
  switch (mode) {
    case CanvasModes.BUTTON:
      return emptyButtonFields;
    case CanvasModes.FORM:
      return emptyFormStepFields;
    case CanvasModes.EMBED:
      return emptyEmbedStepFields;
    case CanvasModes.CONDITION:
      return emptyConditionStepFields;
    case CanvasModes.CSAT_TRIGGER_POINT:
      return emptyCsatTriggerPoint;
    case CanvasModes.ATTACHMENT_ANALYSIS_UPLOAD:
      return emptyAttachmentAnalysisUploadStepFields;
    default:
      return null;
  }
};

/**
 * This function would traverse through the step map with BFS to find a path to the targetStepId.
 * On the way it records all the branch selections that leads to the current step. Branch selections include:
 * 1. outputVariableValuesMap - For button step:
 * 2. selectedConditions - For condition step and article suggestion (step):
 * 3. actionCaseMap - For article suggestion (template) and dynamic article suggestion
 *
 * @returns if a path is found, return a valid set of branch selections that leads to the targetStepId, otherwise returns null
 */
export const findBranchSelectionsToStepId = ({
  canvasData,
  targetActionId,
  targetStepId,
}: {
  canvasData: CanvasWorkflowBuilderState;
  targetActionId: string | null;
  targetStepId: string;
}) => {
  const entryStepId = canvasData.entryStepId || '';
  const branchSelections: {
    actionCaseMap: CanvasWorkflowBuilderState['actionCaseMap'];
    outputVariableValuesMap: CanvasWorkflowBuilderState['outputVariableValuesMap'];
    selectedConditions: CanvasWorkflowBuilderState['selectedConditions'];
  } = {
    actionCaseMap: {},
    outputVariableValuesMap: {},
    selectedConditions: {},
  };
  const queue = [{ branchSelections, stepId: entryStepId }];
  const visited = new Set();

  // BFS to find the path to target step id
  while (true) {
    const nextItem = queue.shift();
    if (!nextItem) {
      break;
    }

    const { branchSelections: branchSelectionsSoFar, stepId: currentStepId } =
      nextItem;

    // visited this step already
    if (visited.has(currentStepId) || currentStepId === '') {
      continue;
    }
    visited.add(currentStepId);

    // found the target step, return the branch selection recorded to get to the current step
    const isEntryStepOfTargetAction =
      targetActionId &&
      canvasData.canvas_action_id_to_action_component[targetActionId]
        ?.entry_step_id === currentStepId;
    if (currentStepId === targetStepId || isEntryStepOfTargetAction) {
      return branchSelectionsSoFar;
    }

    const step = canvasData.steps[currentStepId];

    // handle article suggestion (template) and Dynamic article suggestion separately because of their structure
    // Note that we don't need to do this for article suggestion (step) because it ends with a condition step
    // which can be handled by the generic step handler below
    const articleSuggestionTemplateKey =
      getArticleSuggestionTemplateKeyFromEntryStep({
        canvasData,
        stepId: currentStepId,
      });
    if (articleSuggestionTemplateKey) {
      // push both the yes and no branches into the queue to traverse
      const canvasActions = canvasData.canvas_action_id_to_action_component;
      const transitionBranches =
        canvasActions[articleSuggestionTemplateKey]?.['transition_branches'];

      // push the yes branch
      queue.push({
        branchSelections: {
          ...branchSelectionsSoFar,
          actionCaseMap: getUpdatedActionCaseMap({
            currentActionCaseMap: branchSelectionsSoFar.actionCaseMap,
            stepId: currentStepId,
            value: true,
          }),
        },
        stepId:
          transitionBranches?.find(
            transitionBranch => transitionBranch.display_name === 'Yes',
          )?.parent_step_id || '',
      });

      // push the no branch
      queue.push({
        branchSelections: {
          ...branchSelectionsSoFar,
          actionCaseMap: getUpdatedActionCaseMap({
            currentActionCaseMap: branchSelectionsSoFar.actionCaseMap,
            stepId: currentStepId,
            value: false,
          }),
        },
        stepId:
          transitionBranches?.find(
            transitionBranch => transitionBranch.display_name === 'No',
          )?.parent_step_id || '',
      });
      continue;
    }

    const dynamicArticleSuggestionKey =
      getDynamicArticleSuggestionKeyFromEntryStep({
        canvasData,
        stepId: currentStepId,
      });
    if (dynamicArticleSuggestionKey) {
      const canvasActions = canvasData.canvas_action_id_to_action_component;
      const parentStepId =
        canvasActions[dynamicArticleSuggestionKey]?.['transition_branches']?.[0]
          .parent_step_id;
      const parentStep = canvasData.steps[parentStepId || ''];

      // parent step type determine whether the dynamic article suggestion has feedback enabled or not
      if (parentStep.step_type === StepTypes.BUTTONS) {
        // push the yes branch
        queue.push({
          branchSelections: {
            ...branchSelectionsSoFar,
            actionCaseMap: getUpdatedActionCaseMap({
              currentActionCaseMap: branchSelectionsSoFar.actionCaseMap,
              stepId: currentStepId,
              value: true,
            }),
          },
          stepId:
            parentStep.transitions.find(
              transition =>
                transition.condition_expression?.values?.[0] === 'yes_resolve',
            )?.step_id || '',
        });

        // push the no branch
        queue.push({
          branchSelections: {
            ...branchSelectionsSoFar,
            actionCaseMap: getUpdatedActionCaseMap({
              currentActionCaseMap: branchSelectionsSoFar.actionCaseMap,
              stepId: currentStepId,
              value: false,
            }),
          },
          stepId:
            parentStep.transitions.find(
              transition =>
                transition.condition_expression?.values?.[0] === 'no_resolve',
            )?.step_id || '',
        });
      } else if (parentStep.step_type === StepTypes.CONDITION) {
        // push the second transition branch of the parent step because the first branch
        // is a conditional transition that goes to a GO_TO_INTENT step that redirects to the standard handoff
        queue.push({
          branchSelections: branchSelectionsSoFar,
          stepId: parentStep.transitions[1].step_id || '',
        });
      }
    }

    switch (step.step_type) {
      // handle branching steps
      case StepTypes.BUTTONS:
        // add each branch into the queue with the currentStep recorded in outputVariableValuesMap
        const stepFields = step.step_fields as ButtonsStepFields;

        step.transitions.forEach((transition, index) => {
          queue.push({
            branchSelections: {
              ...branchSelectionsSoFar,
              outputVariableValuesMap: getUpdatedOutputVariableValuesMap({
                currentOutputVariableValuesMap:
                  branchSelectionsSoFar.outputVariableValuesMap,
                currentStepId,
                index,
                stepFields,
              }),
            },
            stepId: transition.step_id || '',
          });
        });

        break;
      case StepTypes.CONDITION:
        // add each branch into the queue with the currentStep recorded in selectedConditions
        step.transitions.forEach((transition, index) => {
          queue.push({
            branchSelections: {
              ...branchSelectionsSoFar,
              selectedConditions: getUpdatedSelectedConditions({
                currentSelectedConditions:
                  branchSelectionsSoFar.selectedConditions,
                currentStepId,
                index,
              }),
            },
            stepId: transition.step_id || '',
          });
        });
        break;
      // Not branching step, just continue along
      default:
        step.transitions.forEach(transition => {
          queue.push({
            branchSelections: branchSelectionsSoFar,
            stepId: transition.step_id || '',
          });
        });
    }
  }

  return null;
};

const getArticleSuggestionTemplateKeyFromEntryStep = ({
  canvasData,
  stepId,
}: {
  canvasData: CanvasWorkflowBuilderState;
  stepId: string;
}) => {
  const actions = canvasData.canvas_action_id_to_action_component;
  const actionKeys = Object.keys(actions);

  for (const key of actionKeys) {
    if (stepId !== actions[key].entry_step_id) {
      continue;
    }

    const transitionBranches = actions[key].transition_branches;
    if (!transitionBranches) {
      continue;
    }

    if (
      transitionBranches.length === 2 &&
      transitionBranches[0].display_name === 'Yes' &&
      transitionBranches[1].display_name === 'No'
    ) {
      return key;
    }
  }

  return null;
};

const getDynamicArticleSuggestionKeyFromEntryStep = ({
  canvasData,
  stepId,
}: {
  canvasData: CanvasWorkflowBuilderState;
  stepId: string;
}) => {
  const actions = canvasData.canvas_action_id_to_action_component;
  const actionKeys = Object.keys(actions);

  for (const key of actionKeys) {
    if (stepId !== actions[key].entry_step_id) {
      continue;
    }

    if (isDynamicArticleSuggestion(canvasData, key)) {
      return key;
    }
  }
  return null;
};

const getUpdatedOutputVariableValuesMap = ({
  currentOutputVariableValuesMap,
  currentStepId,
  index,
  stepFields,
}: {
  currentOutputVariableValuesMap: CanvasWorkflowBuilderState['outputVariableValuesMap'];
  currentStepId: string;
  index: number;
  stepFields: ButtonsStepFields;
}) => {
  const outputVariableName = stepFields.output_variable;
  const newOutputVariableValuesMap = {
    ...currentOutputVariableValuesMap,
  };
  const newOutputMap: Record<string, string> = {};
  newOutputMap[outputVariableName] = stepFields.options[index].value;
  newOutputVariableValuesMap[currentStepId] = newOutputMap;

  return newOutputVariableValuesMap;
};

const getUpdatedSelectedConditions = ({
  currentSelectedConditions,
  currentStepId,
  index,
}: {
  currentSelectedConditions: CanvasWorkflowBuilderState['selectedConditions'];
  currentStepId: string;
  index: number;
}) => {
  const newSelectedConditions = {
    ...currentSelectedConditions,
  };
  newSelectedConditions[currentStepId] = index;

  return newSelectedConditions;
};

const getUpdatedActionCaseMap = ({
  currentActionCaseMap,
  stepId,
  value,
}: {
  currentActionCaseMap: CanvasWorkflowBuilderState['actionCaseMap'];
  stepId: string;
  value: boolean;
}) => {
  const newActionCaseMap = {
    ...currentActionCaseMap,
  };
  newActionCaseMap[stepId] = value;

  return newActionCaseMap;
};
