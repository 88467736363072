import { Divider } from '@mui/material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TableRelatedTopics from 'src/components/dashboard-pages/discover-automation-page/knowledge-gap-tab/TableRelatedTopics';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface TopicSectionProps {
  article: KnowledgeGapArticleRecommendation;
  onDrawerClose?: () => void;
}

const TopicSection = ({ article, onDrawerClose }: TopicSectionProps) => {
  const { palette } = useTheme();
  const hasTopic = Boolean(article.topic_name);
  return (
    <>
      <Box
        alignItems='flex-start'
        display='flex'
        flexDirection='column'
        gap={1}
      >
        <Typography color={palette.colors.grey[600]} variant='font12'>
          Topic
        </Typography>
        {hasTopic ? (
          <TableRelatedTopics onClick={onDrawerClose} rowData={article} />
        ) : (
          <Typography color={palette.colors.grey[500]} variant='font14'>
            {NA}
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default TopicSection;
