import { IntentPredictionTranscript } from './IntentPredictionTranscript';
import { SpeechTranscript } from './SpeechTranscript';
import { VoiceTranscript } from './types';

export const TranscriptComponent = ({
  startTime,
  transcript,
}: {
  startTime: number;
  transcript: VoiceTranscript;
}) => {
  if (transcript.type === 'execution') {
    return <IntentPredictionTranscript transcript={transcript} />;
  }

  return <SpeechTranscript startTime={startTime} transcript={transcript} />;
};
