import { useSelector } from 'react-redux';
import { useRTVIClientTransportState } from '@pipecat-ai/client-react';
import { IconPhone } from '@tabler/icons-react';

import {
  FilterButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useGetThemeBasedOnMode } from '../utils';
import { useGetBoughtNumberOptions } from 'src/pages/solve-config/voice/utils';
import {
  selectPreviewPhoneNumber,
  setVoicePreviewPhoneNumber,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';

export function PhoneNumberSelector() {
  const dispatch = useAppDispatch();
  const theme = useGetThemeBasedOnMode();
  const transportState = useRTVIClientTransportState();
  const boughtNumberOptions = useGetBoughtNumberOptions();
  const previewPhoneNumber = useSelector(selectPreviewPhoneNumber);
  const isIdle = ['error', 'disconnected', 'disconnecting'].includes(
    transportState,
  );

  return (
    <Tooltip
      placement='top'
      tooltipContent={
        !isIdle
          ? 'Cannot update phone number due to an ongoing conversation'
          : ''
      }
    >
      <FilterButton
        aria-label='Preview with phone number'
        disabled={!isIdle}
        initialValue=''
        onChange={e => {
          dispatch(setVoicePreviewPhoneNumber(e));
        }}
        options={boughtNumberOptions}
        startAdornment={
          <IconPhone color={theme.palette.colors.grey[500]} size={20} />
        }
        value={previewPhoneNumber || ''}
      />
    </Tooltip>
  );
}
