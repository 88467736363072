import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  ErrorEventCountResponse,
  ErrorFilterValuesResponse,
  ErrorQueryRequestBody,
  ErrorQueryResponse,
} from 'src/types/ErrorEventTypes';

const errorEventsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getErrorEventCount: builder.query<ErrorEventCountResponse, void>({
      query: () => {
        return SOLVE_V2_BASE_URL + '/error-events/count';
      },
    }),
    getErrorEvents: builder.query<ErrorQueryResponse, ErrorQueryRequestBody>({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.error_events = [...newItems.error_events];
        } else {
          currentCache.error_events = [
            ...currentCache.error_events,
            ...newItems.error_events,
          ];
        }
      },
      query: ({
        channel,
        conversation_id,
        error_id,
        error_message,
        error_payload,
        page,
        page_size,
        product,
        sort_column,
        sort_direction,
      }) => {
        const qParams = new URLSearchParams({
          channel,
          conversation_id,
          error_id,
          error_message,
          error_payload,
          page: page.toString(),
          page_size: page_size ?? '100',
          product,
          sort_column,
          sort_direction,
        });

        return (
          SOLVE_V2_BASE_URL +
          '/workflow-builder/intent-conversations/error-events' +
          '?' +
          qParams
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page_size, sort_column, sort_direction } = queryArgs;
        const qParams = new URLSearchParams({
          page_size: page_size ?? '100',
          sort_column,
          sort_direction,
        });
        return endpointName + '&' + qParams;
      },
    }),
    getErrorFilterValues: builder.query<ErrorFilterValuesResponse, void>({
      query: () => {
        return (
          SOLVE_V2_BASE_URL +
          '/workflow-builder/intent-conversations/error-filter-values'
        );
      },
    }),
  }),
});

export const {
  useGetErrorEventCountQuery,
  useGetErrorEventsQuery,
  useGetErrorFilterValuesQuery,
} = errorEventsApi;
