import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Colors,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { AddIcon } from '../../../customization-panel/article-suggestion-settings-panel';
import IntentEmailFilterQuery from './IntentEmailFilterQuery';
import addPurpleIcon from 'src/assets/images/add-purple-icon.svg';
import disabledTrashIcon from 'src/assets/images/disabled-trash-can.svg';
import trashIcon from 'src/assets/images/trash-icon.svg';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { selectAvailableArticleSources } from 'src/slices/email-builder/emailBuilderSlice';
import { ArticleSource } from 'src/types/workflowBuilderAPITypes';

interface IntentEmailArticleSourceProps {
  addFilterQuery: () => void;
  articleSource: ArticleSource;
  deleteArticleSource: () => void;
  index: number;
  isDeleteButtonDisabled: boolean;
  setArticleSource: (articleSource: ArticleSource) => void;
}

const IntentEmailArticleSource = ({
  addFilterQuery,
  articleSource,
  deleteArticleSource,
  index,
  isDeleteButtonDisabled,
  setArticleSource,
}: IntentEmailArticleSourceProps) => {
  const { palette } = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const solveMultiConnectorUi = featureFlags.includes(
    'solve_multi_connector_ui',
  );
  const availableArticleSources = useSelector(selectAvailableArticleSources);

  const { connector_id, doc_type, filter_queries } = articleSource;

  const availableFilterFields = useMemo(() => {
    return (
      availableArticleSources.find(articleSource =>
        connector_id
          ? articleSource.connector_id === connector_id
          : articleSource.doc_type === doc_type,
      )?.available_article_fields || []
    );
  }, [availableArticleSources, connector_id, doc_type]);

  const articleSourceOptions = availableArticleSources.map(articleSource => ({
    label: solveMultiConnectorUi
      ? articleSource.connector_display_name
      : articleSource.doc_type,
    value: connector_id ? articleSource.connector_id : articleSource.doc_type,
    ...(solveMultiConnectorUi && {
      optionStartAdornment: (
        <Box
          component='img'
          height='20px'
          src={articleSource.emblem_url}
          width='20px'
        />
      ),
    }),
  }));

  const emblem_url =
    availableArticleSources.find(articleSourceOption =>
      articleSource.connector_id
        ? articleSourceOption.connector_id === articleSource.connector_id
        : articleSourceOption.doc_type === articleSource.doc_type,
    )?.emblem_url || '';

  const startAdornmentProps = solveMultiConnectorUi
    ? {
        startAdornment: (
          <Box
            component='img'
            height='20px'
            marginRight='5px'
            src={emblem_url}
            width='20px'
          />
        ),
      }
    : {};

  const handleUpdate = (value: string) => {
    const selectedSource = availableArticleSources.find(
      source => source.connector_id === value || source.doc_type === value,
    );

    setArticleSource({
      ...articleSource,
      connector_id: selectedSource?.connector_id || '',
      doc_type: selectedSource?.doc_type || '',
      filter_queries: articleSource.filter_queries
        ? articleSource.filter_queries?.map(filterQuery => ({
            ...filterQuery,
            field: '',
            operator: 'is',
            value: {
              name: '',
              value: '',
            },
          }))
        : [],
    });
  };

  return (
    <Box
      borderBottom={`1px solid ${palette.colors.slate[200]}`}
      marginBottom='8px'
      paddingBottom='23px'
    >
      <Typography
        color={Colors.ui.text.primary}
        variant='font16Bold'
      >{`Article source ${index + 1}`}</Typography>
      <Box margin='8px 0'>
        <Box display='flex' marginBottom='24px'>
          <SelectDropdown
            error={!doc_type}
            id='article-source-selector'
            onChange={e => {
              handleUpdate(e.target.value);
            }}
            options={articleSourceOptions}
            placeholder='Choose an article source'
            value={connector_id || doc_type || ''}
            {...startAdornmentProps}
          />
          <DeleteIcon
            alt={`remove article source ${index + 1}`}
            aria-disabled={isDeleteButtonDisabled ? 'true' : 'false'}
            isDisabled={isDeleteButtonDisabled}
            onClick={() => {
              if (!isDeleteButtonDisabled) {
                deleteArticleSource();
              }
            }}
            role='button'
            src={isDeleteButtonDisabled ? disabledTrashIcon : trashIcon}
          />
        </Box>
        {filter_queries?.map((filterQuery, index) => (
          <IntentEmailFilterQuery
            fields={availableFilterFields}
            filterQuery={filterQuery}
            index={index}
            key={index}
            onDeleteFilterQuery={() => {
              setArticleSource({
                ...articleSource,
                filter_queries: articleSource.filter_queries.filter(
                  (_, filterQueryIndex) => filterQueryIndex !== index,
                ),
              });
            }}
            onUpdateBooleanOperator={(booleanOperator: string) => {
              setArticleSource({
                ...articleSource,
                filter_queries: articleSource.filter_queries.map(
                  filterQuery => {
                    if (index > 0) {
                      return {
                        ...filterQuery,
                        boolean_operator: booleanOperator,
                      };
                    }
                    return filterQuery;
                  },
                ),
              });
            }}
            onUpdateFilterQuery={updatedFilterQuery =>
              setArticleSource({
                ...articleSource,
                filter_queries: articleSource.filter_queries.map(
                  (filterQuery, filterQueryIndex) => {
                    if (filterQueryIndex === index) {
                      return updatedFilterQuery;
                    }
                    return filterQuery;
                  },
                ),
              })
            }
          />
        ))}
        <Button
          aria-label={`Add filter field for article source ${index + 1}`}
          onClick={() => {
            addFilterQuery();
          }}
          variant='ghost'
        >
          <AddIcon src={addPurpleIcon} />
          Add Filter Field
        </Button>
      </Box>
    </Box>
  );
};

export default IntentEmailArticleSource;

const DeleteIcon = styled('img')<{ isDisabled: boolean }>`
  margin-left: 8px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
