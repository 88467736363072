import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { SearchBar } from '@forethought-technologies/forethought-elements';
import {
  setIsEditing,
  setIsUpdateError,
} from '../../../slices/connectors/connectorsSlice';
import {
  useConnectors,
  useExistingNames,
  useIntegrationSlug,
  useTrackingEventOnSearchQueryChange,
} from './hooks';
import IntegrationDrawer from './integration-drawer';
import IntegrationsHeader from './integrations-page-header';
import IntegrationsCard from './IntegrationsCard';
import IntegrationsEmptyState from './IntegrationsEmptyState';
import IntegrationsLoadingState from './IntegrationsLoadingState';
import { IntegrationSection } from './styles';
import SettingsSection from 'src/components/reusable-components/settings-section';
import SettingsPageLayout from 'src/components/settings-page-layout';
import { ConnectorDefinition } from 'src/services/apiInterfaces';

const AVAILABLE_SECTION_ID = 'available-integrations-section';

export default function CollectionsPage({
  multiConnectorsEnabled,
}: {
  multiConnectorsEnabled: boolean;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const integrationSlug = useIntegrationSlug();
  const {
    availableConnectors,
    createdConnectors,
    filteredAvailableConnectors,
    isLoading,
  } = useConnectors(searchQuery);

  useTrackingEventOnSearchQueryChange(searchQuery);

  const existingNames = useExistingNames(createdConnectors);

  const selectedConnectorDefinition = useMemo(() => {
    const availableConnectorsForDetailView = availableConnectors;
    return [...availableConnectorsForDetailView].find(connector => {
      return connector.slug === integrationSlug;
    });
  }, [availableConnectors, integrationSlug]);

  const selectedConnector = useMemo(() => {
    return availableConnectors.find(
      connector => connector.slug === integrationSlug,
    );
  }, [availableConnectors, integrationSlug]);

  const isEmpty = useMemo(() => {
    return !isLoading && !availableConnectors.length;
  }, [availableConnectors, isLoading]);

  return (
    <SettingsPageLayout>
      <IntegrationsHeader isCollectionsPage />
      {isLoading && <IntegrationsLoadingState />}
      {isEmpty && <IntegrationsEmptyState />}
      <Box alignItems='center' display='flex' mb={4}>
        <Box display='flex' flexGrow={1} gap={1}>
          <Box width='300px'>
            <SearchBar
              onChange={({ target }) => setSearchQuery(target.value)}
              onClear={() => setSearchQuery('')}
              placeholder='Search'
              size='small'
              value={searchQuery}
            />
          </Box>
        </Box>
      </Box>
      {!isLoading && !!availableConnectors.length && (
        <SettingsSection id={AVAILABLE_SECTION_ID} title='Available'>
          <IntegrationSection>
            {filteredAvailableConnectors.map(
              (connectorDefinition: ConnectorDefinition) => {
                return (
                  <IntegrationsCard
                    connectorDefinition={connectorDefinition}
                    key={connectorDefinition.slug}
                    status='disconnected'
                  />
                );
              },
            )}
          </IntegrationSection>
        </SettingsSection>
      )}
      <IntegrationDrawer
        connectorDefinition={selectedConnectorDefinition}
        existingNames={existingNames}
        isCollectionsPage
        multiConnectorsEnabled={multiConnectorsEnabled}
        onClose={() => {
          dispatch(setIsUpdateError(false));
          dispatch(setIsEditing(false));
          navigate(pathname);
        }}
        selectedConnector={selectedConnector}
      />
    </SettingsPageLayout>
  );
}
