import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { EmptyStateCard } from '../articles/EmptyStateCard';
import UnansweredDialog from '../chats/UnansweredDialog';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  percentageValueAsString,
} from '../helpers';
import { useGetEmailFiltersFromSearch } from '../hooks/useGetEmailFiltersFromSearch';
import { InsightsTab } from '../types';
import InsightCard from './InsightCard';
import { BarGraphWrapper } from 'src/components/solve-insights-charts/bar-graph/barGraphUtils';
import { useGetEmailNoResponseQuery } from 'src/services/insights';

interface EmailNoResponseCardProps {
  dateRange: DateRange;
  tab?: InsightsTab;
}

export const EmailNoResponseCard = ({
  dateRange,
  tab = 'chat',
}: EmailNoResponseCardProps) => {
  const theme = useTheme();
  const { end_timestamp, start_timestamp } = dateRangeToTimestamp(dateRange);
  const requestParams = useGetEmailFiltersFromSearch({ dateRange, tab });
  // State
  const [isUnansweredCausesDialogOpen, setIsUnansweredCausesDialogOpen] =
    useState(false);
  // Article tab endpoint
  const { data, isFetching, isLoading } = useGetEmailNoResponseQuery({
    end: end_timestamp,
    start: start_timestamp,
    ...requestParams,
  });
  const isEmailDataAvailable = data && data.misc.total_response !== 0;
  const deltaTotalResponse = data?.misc?.delta_total_response;

  return (
    <>
      <InsightCard
        isFetching={isFetching}
        isLoading={isLoading}
        title={data?.misc.title}
        tooltip={
          <Tooltip
            maxWidth={320}
            onClick={() => setIsUnansweredCausesDialogOpen(true)}
            tooltipContent={false}
          >
            <IconInfoCircle color='currentColor' height={16} width={16} />
          </Tooltip>
        }
      >
        {isEmailDataAvailable ? (
          <Box alignItems='center' display='flex' flex={1}>
            <Box display='flex' flexDirection='column'>
              <Box alignItems='flex-end' display='flex' gap='16px'>
                <Typography variant='font14'>{data.misc.sub_title}</Typography>
                <Typography variant='font16Bold'>
                  {numbersToStringWithCommas({
                    number: data?.misc.total_response,
                  })}
                </Typography>
                {deltaTotalResponse && (
                  <Typography
                    color={
                      data?.misc?.delta_total_response >= 0
                        ? theme.palette.colors.red[500]
                        : theme.palette.colors.green[500]
                    }
                    variant='font14Medium'
                  >
                    {percentageValueAsString(data.misc.delta_total_response)}
                  </Typography>
                )}
              </Box>
              {data && (
                <BarGraphWrapper
                  data={data}
                  isComparing={true}
                  palette={theme.palette}
                  showTooltip={true}
                />
              )}
            </Box>
          </Box>
        ) : (
          <EmptyStateCard variant='csat' />
        )}
      </InsightCard>
      <UnansweredDialog
        isOpen={isUnansweredCausesDialogOpen}
        onClose={() => setIsUnansweredCausesDialogOpen(false)}
      />
    </>
  );
};
