import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import debounce from 'lodash/fp/debounce';
import {
  ALLTIME_END,
  ALLTIME_START,
} from 'src/pages/intent-conversation-analytics/constants';
import {
  useGetErrorEventsQuery,
  useGetErrorFilterValuesQuery,
} from 'src/services/error-events/errorEventsApi';
import {
  ErrorEventSortColumn,
  ErrorEventSortDirection,
  ErrorQueryRequestBody,
} from 'src/types/ErrorEventTypes';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

export const useGetErrorEventsHook = ({
  channel,
  conversationId,
  errorId,
  errorMessage,
  errorPayload,
  page,
  pageSize,
  product,
  sortColumn,
  sortDirection,
}: {
  channel: string;
  conversationId: string;
  errorId: string;
  errorMessage: string;
  errorPayload: string;
  page: number;
  pageSize?: string;
  product: string;
  sortColumn: ErrorEventSortColumn;
  sortDirection: ErrorEventSortDirection;
}) => {
  const baseQuery: ErrorQueryRequestBody = {
    channel,
    conversation_id: conversationId,
    error_id: errorId,
    error_message: errorMessage,
    error_payload: errorPayload,
    page,
    page_size: pageSize,
    product,
    sort_column: sortColumn,
    sort_direction: sortDirection,
  };

  const response = useGetErrorEventsQuery(baseQuery);

  return response;
};

export const useGetErrorPageQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const qParams = new URLSearchParams(search);
    const tabIndex = qParams.get('tabIndex');
    const to = qParams.get('end') ?? ALLTIME_END;
    const from = qParams.get('start') ?? ALLTIME_START;

    return { endDate: to, startDate: from, tabIndex };
  }, [search]);
};

const SEARCH_QUERY_FILTER_DEBOUNCE_DELAY = 500;

export const useGetErrorTableSearchQuery = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}) => {
  // Hooks
  const [uiQuery, setUiQuery] = useState(searchQuery);

  const handleOnChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  // Optimizations on query search
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce(SEARCH_QUERY_FILTER_DEBOUNCE_DELAY, (value: string) => {
        handleOnChange(value);
      }),
    [handleOnChange],
  );

  useEffect(() => {
    if (uiQuery !== searchQuery) {
      debouncedOnChangeHandler(uiQuery);
    }
  }, [debouncedOnChangeHandler, searchQuery, uiQuery]);

  return { setUiQuery, uiQuery };
};

export const useGetErrorFilterOptions = () => {
  const { data: filterOptionsResponse } = useGetErrorFilterValuesQuery();

  const filterOptions = useMemo(() => {
    const channelOptions =
      filterOptionsResponse?.channels.map(channel => ({
        label: capitalizeFirstLetter(channel),
        value: channel,
      })) || [];
    const errorMessageOptions =
      filterOptionsResponse?.error_messages.map(message => ({
        label: message,
        value: message,
      })) || [];

    const productOptions =
      filterOptionsResponse?.products.map(product => ({
        label: capitalizeFirstLetter(product),
        value: product,
      })) || [];

    return [
      {
        label: 'Product',
        options: productOptions,
        value: 'product',
      },
      {
        label: 'Channel',
        options: channelOptions,
        value: 'channel',
      },
      {
        label: 'Error Message',
        options: errorMessageOptions,
        value: 'error_message',
      },
    ];
  }, [
    filterOptionsResponse?.channels,
    filterOptionsResponse?.error_messages,
    filterOptionsResponse?.products,
  ]);

  return filterOptions;
};
