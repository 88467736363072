import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { LaunchButtonWithProactivePrompt } from '../launch-button/LaunchButtonWithProactivePrompt';

const CodeWidgetContent = () => {
  return (
    <Box display='flex' flex={1} flexDirection='column' paddingTop='30px'>
      <Box
        alignSelf='center'
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='end'
        marginBottom='20px'
        sx={{
          '.MuiTypography-root': {
            background:
              'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
            fontStyle: 'italic',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
        }}
        width='365px'
      >
        <Typography variant='font16Medium'>To install the widget:</Typography>
        <Typography variant='font16Medium'>
          1. List the domains where you want the widget to appear. e.g.
          “www.forethought.ai”
        </Typography>
        <Typography variant='font16Medium'>
          2. Copy the code snippet by clicking “Copy code”
        </Typography>
        <Typography variant='font16Medium'>
          3. Insert the code snippet into the appropriate file or section of
          your program, which may include a specific web page or layout file.
          e.g. ”www.forethought.ai/contact”
        </Typography>
      </Box>
      <Box display='flex' flex={1} flexDirection='column' justifyContent='end'>
        <LaunchButtonWithProactivePrompt />
      </Box>
    </Box>
  );
};

export default CodeWidgetContent;
