import Box from '@mui/material/Box';

import KnowledgeWidgetContent from '../knowledge-tab-content/KnowledgeWidgetContent';
import MinimizedWidget from '../static-solve-widget/MinimizedWidget';
import ThemeWidgetContent from '../theme-tab-content/ThemeWidgetContent';
import {
  FALLBACK_PREVIEW_TEXT,
  FEEDBACK_PREVIEW_TEXT,
  INSTRUCTIONAL_NOTE_PREVIEW_TEXT,
  TONE_PREVIEW_TEXT,
} from './constants';
import FallbackPreview from './FallbackPreview';
import GeneratedReplyAction from './GeneratedReplyAction';
import IntegrationHeader from './IntegrationHeader';
import QuickFeedbackPreview from './QuickFeedbackPreview';
import TonePreviewContent from './TonePreviewContent';
import { PlaybookTabKeys } from './types';

interface WidgetPreviewProps {
  widgetType: PlaybookTabKeys;
}
const WidgetPreview = ({ widgetType }: WidgetPreviewProps) => {
  if (widgetType === 'fallback') {
    return (
      <ThemeWidgetContent
        primaryInstruction={FALLBACK_PREVIEW_TEXT}
        WidgetContent={FallbackPreview}
        wrapText
      />
    );
  }

  if (widgetType === 'instructional-note') {
    return (
      <ThemeWidgetContent
        primaryInstruction={INSTRUCTIONAL_NOTE_PREVIEW_TEXT}
        WidgetContent={() => null}
        wrapText
      />
    );
  }
  if (widgetType === 'tone-of-voice') {
    return (
      <ThemeWidgetContent
        primaryInstruction={TONE_PREVIEW_TEXT}
        spaceBetween={false}
        WidgetContent={TonePreviewContent}
      />
    );
  }
  if (widgetType === 'quick-feedback') {
    return (
      <ThemeWidgetContent
        primaryInstruction={FEEDBACK_PREVIEW_TEXT}
        spaceBetween={false}
        WidgetContent={QuickFeedbackPreview}
        wrapText
      />
    );
  }
  if (widgetType === 'knowledge') {
    return (
      <Box height='100%' py={2}>
        <MinimizedWidget>
          <KnowledgeWidgetContent headerBanner={<IntegrationHeader />}>
            <GeneratedReplyAction />
          </KnowledgeWidgetContent>
        </MinimizedWidget>
      </Box>
    );
  }
  return null;
};

export default WidgetPreview;
