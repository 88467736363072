import { useMemo } from 'react';
import { useTheme } from '@mui/material';

import { DateRange } from '../../discover-dashboard-page/types';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
} from '../../solve-insights/helpers';
import PieChartCard from './PieChartCard';
import { useGetWidgetAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';

export const SentimentCard = ({ dateRange }: { dateRange: DateRange }) => {
  const { palette } = useTheme();

  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: data, isLoading } = useGetWidgetAggregateMetricsQuery({
    channel: 'voice',
    from: timestamps.start_timestamp.toString(),
    to: timestamps.end_timestamp.toString(),
  });
  const colors = [
    palette.colors.green[500],
    palette.colors.green[300],
    palette.colors.red[500],
  ];

  const totalConversations = data?.voice_aggregate?.conversations || 0;
  const positiveSentiments = data?.voice_aggregate?.positive_sentiments || 0;
  const negativeSentiments = data?.voice_aggregate?.negative_sentiments || 0;
  const neutralSentiments =
    totalConversations - (positiveSentiments + negativeSentiments);

  const centerText = useMemo(() => {
    return numbersToStringWithCommas({
      number:
        totalConversations === 0
          ? 0
          : (positiveSentiments + neutralSentiments) / totalConversations,
      style: 'percent',
    });
  }, [totalConversations, positiveSentiments, neutralSentiments]);

  const donutData = useMemo(
    () => ({
      values: [
        {
          extended_label: null,
          extended_labels: [],
          label: 'positive',
          type: 'percentage',
          value: positiveSentiments,
        },
        {
          extended_label: null,
          extended_labels: [],
          label: 'neutral',
          type: 'percentage',
          value: neutralSentiments,
        },
        {
          extended_label: null,
          extended_labels: [],
          label: 'negative',
          type: 'percentage',
          value: negativeSentiments,
        },
      ],
    }),
    [positiveSentiments, negativeSentiments, neutralSentiments],
  );

  return (
    <PieChartCard
      centerSubText={'positive \n& neutral'}
      centerText={centerText}
      colors={colors}
      dateRange={dateRange}
      donutData={donutData}
      isLoading={isLoading}
      title='Sentiment'
      tooltip='How the users felt about the call.'
    />
  );
};
