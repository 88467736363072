import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  SelectDropdown,
} from '@forethought-technologies/forethought-elements';
import { FieldNameToIdx } from '../../handoff-configuration/zendesk/constants';
import { formatContextVariableForPersistence } from '../../handoff-configuration/zendesk/helpers';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import TagInputWithContextVariables from 'src/pages/workflow-builder-edit/tag-input-with-context-variables/TagInputWithContextVariables';
import {
  useAddAutoflowToolMutation,
  useUpdateAutoflowToolConfigurationFieldsMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  AutoflowTool,
  ToolDefinitionResponse,
  ZendeskTicketCreationSettings,
} from 'src/types/workflowBuilderAPITypes';

interface EditCreateZendeskTicketToolProps {
  intentWorkflowId: string;
  onClose: () => void;
  tool:
    | (AutoflowTool & { toolDefinition?: ToolDefinitionResponse | undefined })
    | undefined;
}

export const EditCreateZendeskTicketTool = ({
  intentWorkflowId,
  onClose,
  tool,
}: EditCreateZendeskTicketToolProps) => {
  const theme = useTheme();
  const [mutate, { isLoading: isUpdatingFields }] =
    useUpdateAutoflowToolConfigurationFieldsMutation();
  const [addAutoflowTool, { isLoading: isAddingTool }] =
    useAddAutoflowToolMutation();

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const [ticketCreationSettings, setTicketCreationSettings] =
    useState<ZendeskTicketCreationSettings>(
      tool?.toolDefinition?.tool_fields?.zendesk_ticket_creation_settings ?? {
        brand_name: null,
        ticket_id_output_variable: null,
        ticket_status: null,
        ticket_tags: [],
        update_flamethrower_conversation_deflection_status: false,
        visitor_email_variable: null,
        visitor_name_variable: null,
      },
    );

  const availableTicketBrands =
    tool?.configuration_fields.available_ticket_brands || [];
  const availableTicketStatuses =
    tool?.configuration_fields.available_statuses || [];
  const isLoading = isAddingTool || isUpdatingFields;

  const onSave = async (
    ticketCreationFields: ZendeskTicketCreationSettings,
  ) => {
    try {
      if (!tool?.toolDefinition) {
        const toolData = {
          body: {
            tool_id: tool?.tool_id ?? '',
            tool_type: tool?.tool_type ?? 'system_built_in',
          },
          intentWorkflowId,
        };
        await addAutoflowTool(toolData).unwrap();
      }
      await mutate({
        body: {
          configuration_fields: {
            zendesk_ticket_creation_settings: ticketCreationFields,
          },
          tool_id: tool?.tool_id || '',
        },
        intentWorkflowId,
      }).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTagAddition = (tag: string) => {
    setTicketCreationSettings(ticketCreationSettings => ({
      ...ticketCreationSettings,
      ticket_tags: [
        ...ticketCreationSettings.ticket_tags,
        formatContextVariableForPersistence(contextVariables, tag),
      ],
    }));
  };

  const handleTagDeletion = (idx: number) => {
    setTicketCreationSettings(ticketCreationSettings => {
      const ticketTags = ticketCreationSettings.ticket_tags || [];
      return {
        ...ticketCreationSettings,
        ticket_tags: ticketTags
          .slice(0, idx)
          .concat(ticketTags.slice(idx + 1, ticketTags.length)),
      };
    });
  };

  return (
    <Box
      alignItems='space-between'
      bgcolor={theme => theme.palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      p={3}
      width='650px'
    >
      <Box>
        <Typography variant='font20'>Zendesk ticket creation</Typography>
        <Typography
          color={theme.palette.colors.grey[700]}
          ml={1}
          variant='font14'
        >
          All fields are optional
        </Typography>
      </Box>

      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        gap={3}
        overflow='scroll'
      >
        <Checkbox
          checked={
            !!ticketCreationSettings.update_flamethrower_conversation_deflection_status
          }
          label='Mark Conversation as Not Deflected'
          onChange={() => {
            setTicketCreationSettings(ticketCreationSettings => ({
              ...ticketCreationSettings,
              update_flamethrower_conversation_deflection_status:
                !ticketCreationSettings.update_flamethrower_conversation_deflection_status,
            }));
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Name</Typography>
          <ContextVariableSelectDropdown
            aria-label='Ticket requester name'
            isClearable
            onChange={value => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                visitor_name_variable: value,
              }));
            }}
            shouldIncludeSystemContextVariables
            value={ticketCreationSettings.visitor_name_variable || ''}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Email</Typography>
          <ContextVariableSelectDropdown
            aria-label='Ticket requester email'
            isClearable
            onChange={value => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                visitor_email_variable: value,
              }));
            }}
            shouldIncludeSystemContextVariables
            value={ticketCreationSettings.visitor_email_variable || ''}
          />
        </Box>

        <ContextVariableSelectDropdown
          isClearable
          label='Ticket ID Output Context Variable'
          onChange={value => {
            setTicketCreationSettings(ticketCreationSettings => ({
              ...ticketCreationSettings,
              ticket_id_output_variable: value,
            }));
          }}
          value={ticketCreationSettings.ticket_id_output_variable || ''}
        />

        <ContextVariableSelectDropdown
          additionalOptions={availableTicketBrands.map(tb => ({
            category: 'Brands',
            label: tb,
            value: tb,
          }))}
          id={'zendesk-select-ticket-brand'}
          isClearable
          label='Ticket Brand'
          onChange={value => {
            setTicketCreationSettings(ticketCreationSettings => ({
              ...ticketCreationSettings,
              brand_name: value,
            }));
          }}
          shouldIncludeSystemContextVariables
          shouldProvideCVIdFormatting
          value={ticketCreationSettings.brand_name || ''}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Ticket Tags</Typography>
          <TagInputWithContextVariables
            contextVariables={contextVariables}
            errorMessages={[]}
            errorTag={FieldNameToIdx.TICKET_TAGS}
            handleTagAddition={handleTagAddition}
            handleTagDeletion={handleTagDeletion}
            label=''
            tags={ticketCreationSettings.ticket_tags}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Ticket Status</Typography>
          <SelectDropdown
            id='ticket-status-select'
            inputProps={{ 'aria-label': 'Ticket Status' }}
            onChange={e => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                ticket_status: e.target.value,
              }));
            }}
            onClear={() => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                ticket_status: '',
              }));
            }}
            options={availableTicketStatuses}
            placeholder='Select a status'
            value={ticketCreationSettings.ticket_status || ''}
          />
        </Box>

        <Box display='flex' gap={2}>
          <Button onClick={onClose} size='large' variant='secondary'>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            fullWidth
            isLoading={isLoading}
            onClick={() => onSave(ticketCreationSettings)}
            size='large'
            variant='main'
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
