import React, { useCallback, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { FormProps } from '@rjsf/core';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  ButtonGroupWrapper,
  FormButtonGroup,
  FormHeader,
  FormWrapper,
} from './styles';
import JSONSchemaForm from 'src/components/json-schema-form';
import { TextWidgetProps } from 'src/components/json-schema-form/widgets/TextWidget';
import { Credentials } from 'src/services/apiInterfaces';

interface IntegrationFormProps extends FormProps<Credentials> {
  disableDisplayName?: boolean;
  displayName?: string;
  existingNames?: string[];
  formButtons: React.ReactNode;
  formDescription?: string;
  formTitle?: string;
  isCreate: boolean;
  multiConnectorsEnabled: boolean;
  onDisplayNameChange?: (value: string) => void;
  onSaveDisplayName?: () => void;
  requiresAuthentication: boolean;
  resetDisplayName?: () => void;
  setIsDisplayNameInvalid: (isInvalid: boolean) => void;
  setIsFormValid: (hasEmptyRequiredFields: boolean) => void;
}

export default function IntegrationForm({
  disableDisplayName = false,
  displayName: initialDisplayName = '',
  existingNames,
  formButtons,
  formData = {},
  formDescription,
  formTitle = 'General Preferences',
  isCreate,
  multiConnectorsEnabled,
  onDisplayNameChange,
  onSaveDisplayName,
  requiresAuthentication,
  resetDisplayName,
  schema,
  setIsDisplayNameInvalid,
  setIsFormValid,
  ...rest
}: IntegrationFormProps) {
  const { palette } = useTheme();
  const [displayName, setDisplayName] = useState(initialDisplayName);
  const [error, setError] = useState<string | null>(null);
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const [isSavingDisplayName, setIsSavingDisplayName] = useState(false);

  const validateDisplayName = useCallback(
    (value: string) => {
      if (!value.trim()) {
        return 'This is required.';
      }
      if (existingNames?.includes(value.trim())) {
        return 'This display name already exists';
      }
      return null;
    },
    [existingNames],
  );

  useEffect(() => {
    const initialError = validateDisplayName(displayName);
    setError(initialError);
    setIsDisplayNameInvalid(!!initialError);
  }, [displayName, validateDisplayName, setIsDisplayNameInvalid]);

  useEffect(() => {
    if (!initialDisplayName.trim()) {
      setIsEditingDisplayName(true);
    }
  }, [initialDisplayName]);

  useEffect(() => {
    if (!schema?.properties) {
      setIsFormValid?.(true);
      return;
    }

    const requiredFields = schema.required || Object.keys(schema.properties);

    const hasEmptyRequiredFields = requiredFields.some(key => {
      const fieldSchema = schema.properties?.[key];
      const value = formData[key];

      const isRequiredField =
        schema.required?.includes(key) ||
        (fieldSchema as TextWidgetProps)?.is_required_public_dashboard;

      if (isRequiredField) {
        return (
          value === undefined ||
          value === '' ||
          (typeof value === 'string' && value.trim() === '')
        );
      }
      return false;
    });

    setIsFormValid?.(!hasEmptyRequiredFields);
  }, [formData, schema, setIsFormValid]);

  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setDisplayName(e.target.value);
    onDisplayNameChange?.(value);

    const isEmpty = value === '';
    const validationError = validateDisplayName(value);

    setError(validationError);

    if (!validationError) {
      setIsEditingDisplayName(true);
    }

    setIsDisplayNameInvalid(isEmpty || !!validationError);
  };

  const handleSaveDisplayName = async () => {
    if (!onSaveDisplayName) {
      return;
    }

    const validationError = validateDisplayName(displayName);
    setError(validationError);

    if (!validationError) {
      setIsSavingDisplayName(true);
      await onSaveDisplayName();
      setIsSavingDisplayName(false);
      setIsEditingDisplayName(false);
    }
  };

  const handleCancel = () => {
    if (resetDisplayName) {
      resetDisplayName();
    }
    setError(null);
    setIsEditingDisplayName(false);
  };

  const hasFormFields = !!Object.keys(schema?.properties || {}).length;

  return (
    <FormWrapper>
      {hasFormFields && (
        <FormHeader>
          <Box display='flex' flexDirection='column' gap={1}>
            <Typography variant='font16Bold'>{formTitle}</Typography>
            {formDescription && (
              <Typography color={palette.colors.grey[800]} variant='font14'>
                {formDescription}
              </Typography>
            )}
          </Box>
        </FormHeader>
      )}

      {multiConnectorsEnabled && (
        <Box sx={{ marginTop: 2 }}>
          <TextField
            disabled={disableDisplayName || isSavingDisplayName}
            error={!!error}
            fullWidth
            helperText={
              isEditingDisplayName
                ? error || 'Provide a display name for easy reference'
                : ''
            }
            label='Display name'
            onChange={handleDisplayNameChange}
            onFocus={() => setIsEditingDisplayName(true)}
            value={displayName}
          />
        </Box>
      )}

      {!isCreate && isEditingDisplayName && requiresAuthentication && (
        <ButtonGroupWrapper>
          <Button
            disabled={isSavingDisplayName}
            onClick={handleCancel}
            size='medium'
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            disabled={!!error || isSavingDisplayName}
            isLoading={isSavingDisplayName}
            onClick={handleSaveDisplayName}
            size='medium'
            variant='main'
          >
            Save
          </Button>
        </ButtonGroupWrapper>
      )}

      <JSONSchemaForm
        formData={formData}
        isCreate={isCreate}
        liveValidate
        schema={schema}
        {...rest}
      >
        <FormButtonGroup>{formButtons}</FormButtonGroup>
      </JSONSchemaForm>
    </FormWrapper>
  );
}
