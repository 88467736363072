import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { KnowledgeConfigurationPromptTextField } from 'src/components/knowledge-configuration-prompt-text-field';

export const InstructionTabForm = ({
  description,
  onChange,
  value,
}: {
  description: ReactNode;
  onChange: (value: string) => void;
  value: string;
}) => {
  return (
    <Box display='flex' flexDirection='column' gap={2} height='100%'>
      <Box alignItems='center' display='inline-flex'>
        {description}
      </Box>
      <KnowledgeConfigurationPromptTextField
        onChange={onChange}
        value={value}
      />
    </Box>
  );
};
