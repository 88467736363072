import React from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import ContextMention from '../../context-mention-input';
import { stringContextVariableFilterFn } from '../helpers';
import {
  booleanOperators,
  CATEGORY,
  emptyOperatorOptions,
  operatorOptions,
  operatorOptionsForAvailableValues,
  operatorOptionsForStringOnly,
  VANILLA_FORUMS_DISCUSSION,
} from './constants';
import { removeFilterQuery } from './removeFilterQuery';
import { updateArticleSourceList } from './updateArticleSourceList';
import removeIcon from 'src/assets/images/close.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { ArticleField, ArticleSource } from 'src/types/workflowBuilderAPITypes';
import { ContextVariableTypeKeys } from 'src/utils/enums';

interface FilterQueryProps {
  articleFieldValue: { label: string; value: string } | null;
  articleSourceIndex: number;
  articleSources: ArticleSource[];
  availableArticleFields: Array<ArticleField>;
  booleanOperatorValue: { label: string; value: string };
  filterFieldOptions: Array<{ label: string; value: string }>;
  filterQueriesLength: number;
  filterQueryIndex: number;
  filterValue: { label: string; value: string } | null;
  filterValueOptions: Array<{ label: string; value: string }>;
  operator: { label: string; value: string };
  setArticleSources: React.Dispatch<React.SetStateAction<ArticleSource[]>>;
  shouldDisplayBooleanOperator: boolean;
  shouldDisplayTextInput: boolean;
}

const FilterQuery: React.FC<React.PropsWithChildren<FilterQueryProps>> = ({
  articleFieldValue,
  articleSourceIndex,
  articleSources,
  availableArticleFields,
  booleanOperatorValue,
  filterFieldOptions,
  filterQueriesLength,
  filterQueryIndex,
  filterValue,
  filterValueOptions,
  operator,
  setArticleSources,
  shouldDisplayBooleanOperator,
  shouldDisplayTextInput,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const isSubstringOperatorFeatureFlagEnabled = useIsFeatureFlagEnabled(
    'show_substring_operator',
  );

  const handleFilterValueOptions = () => {
    const filterValueOptionsWithCategory = filterValueOptions.map(item => {
      return {
        category: articleFieldValue?.value ?? 'Options',
        label: item.label,
        value: item.value,
      };
    });

    return filterValueOptionsWithCategory;
  };

  const filterFieldValueOptions = handleFilterValueOptions();

  const handleFilterFieldValue = (value: string) => {
    /**
     * There is a case to convert value to int
     * Category - For Vanilla Forums Discussions
     */
    const isNumberValue = !isNaN(Number(value));
    const isVanillaForumsDiscussionDocType = articleSources.length
      ? articleSources[0].doc_type === VANILLA_FORUMS_DISCUSSION
      : false;
    const isFieldValueTypeAInteger =
      articleFieldValue?.value === CATEGORY && isVanillaForumsDiscussionDocType;
    if (isNumberValue && isFieldValueTypeAInteger) {
      return Number(value);
    }

    return value;
  };

  const selectedCvType =
    contextVariables.find(
      cv => `{{${cv.context_variable_id}}}` === filterValue?.value,
    )?.context_variable_type || 'None';

  const isStringOperatorSelected = operatorOptionsForStringOnly.some(
    option => option.value === operator.value,
  );

  const shouldDisplaySubstringOperator = isSubstringOperatorFeatureFlagEnabled
    ? !filterValue ||
      !filterValue.value ||
      selectedCvType === ContextVariableTypeKeys.EMAIL ||
      selectedCvType === ContextVariableTypeKeys.LONG_TEXT ||
      selectedCvType === ContextVariableTypeKeys.SHORT_TEXT ||
      (typeof filterValue?.value === 'string' &&
        filterValue?.value.length &&
        !/^{{.*}}$/.test(filterValue.value))
    : false;

  const isStringField =
    availableArticleFields?.find(
      field => field.field_name === articleFieldValue?.value,
    )?.field_type === 'text';

  return (
    <Box display='flex' gap={1}>
      {!shouldDisplayBooleanOperator && filterQueriesLength > 1 && (
        <Box minWidth='90px' />
      )}
      {shouldDisplayBooleanOperator && (
        <Box minWidth='90px'>
          {filterQueryIndex > 0 && (
            <SelectDropdown
              id='boolean-dropdown'
              onChange={e =>
                setArticleSources([
                  ...updateArticleSourceList({
                    articleSourceIndex,
                    articleSources: articleSources,
                    fieldToUpdate: 'boolean_operator',
                    filterQueryIndex,
                    value: e.target.value,
                  }),
                ])
              }
              options={booleanOperators}
              value={booleanOperatorValue.value}
            />
          )}
        </Box>
      )}

      <SelectDropdown
        id='article-field-dropdown'
        onChange={e =>
          setArticleSources([
            ...updateArticleSourceList({
              articleSourceIndex,
              articleSources: articleSources,
              fieldToUpdate: 'field',
              filterQueryIndex,
              value: e.target.value,
            }),
          ])
        }
        options={filterFieldOptions}
        placeholder='Article Field...'
        value={articleFieldValue?.value ?? ''}
      />

      <SelectDropdown
        id='operator-dropdown'
        onChange={e =>
          setArticleSources([
            ...updateArticleSourceList({
              articleSourceIndex,
              articleSources: articleSources,
              fieldToUpdate: 'operator',
              filterQueryIndex,
              value: e.target.value,
            }),
          ])
        }
        options={[
          ...(shouldDisplayTextInput
            ? operatorOptionsForAvailableValues
            : operatorOptions),
          ...(shouldDisplaySubstringOperator
            ? operatorOptionsForStringOnly
            : []),
          ...(isStringField ? emptyOperatorOptions : []),
        ]}
        value={operator.value}
      />

      {!['is empty', 'is not empty'].includes(operator.value) &&
        (shouldDisplayTextInput ? (
          <Box sx={{ minWidth: '250px' }}>
            <ContextMention
              filterFn={
                isStringOperatorSelected
                  ? stringContextVariableFilterFn
                  : undefined
              }
              isMatchingNewStyle
              onChange={value => {
                const fieldValue = handleFilterFieldValue(value);
                setArticleSources([
                  ...updateArticleSourceList({
                    articleSourceIndex,
                    articleSources: articleSources,
                    fieldToUpdate: 'value',
                    filterQueryIndex,
                    value: {
                      name: '',
                      value: fieldValue,
                    },
                  }),
                ]);
              }}
              placeholder='Filter value'
              value={filterValue?.value || ''}
            />
          </Box>
        ) : (
          <Box
            minWidth='250px'
            sx={{ backgroundColor: theme.palette.colors.white }}
          >
            <ContextVariableSelectDropdown
              additionalOptions={filterFieldValueOptions}
              aria-label='Filter value'
              filterFn={
                isStringOperatorSelected
                  ? stringContextVariableFilterFn
                  : undefined
              }
              id={'filter-field-value'}
              label={undefined}
              onChange={value => {
                const selectedOption = filterFieldValueOptions.find(
                  item => item.value === value,
                );

                const selectedCv = contextVariables.find(
                  cv => `{{${cv.context_variable_id}}}` === value,
                );

                const name =
                  selectedOption?.label ??
                  (selectedCv?.context_variable_name || '');

                const isNumberValue = !isNaN(Number(value));

                const formattedValue = {
                  name,
                  value: isNumberValue ? Number(value) : value,
                };

                setArticleSources([
                  ...updateArticleSourceList({
                    articleSourceIndex,
                    articleSources: articleSources,
                    fieldToUpdate: 'value',
                    filterQueryIndex,
                    value: formattedValue,
                  }),
                ]);
              }}
              placeholder='Filter value'
              shouldIncludeSystemContextVariables
              shouldProvideCVIdFormatting
              value={filterValue?.value || ''}
            />
          </Box>
        ))}
      <RemoveIconContainer
        onClick={() => {
          const updatedArticleSourceList = removeFilterQuery({
            articleSourceIndex,
            articleSources,
            filterQueryIndex,
          });

          setArticleSources(updatedArticleSourceList);
        }}
      >
        <ReactSVG src={removeIcon} />
      </RemoveIconContainer>
    </Box>
  );
};

export default FilterQuery;

export const RemoveIconContainer = styled('div')`
  cursor: pointer;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
    [stroke] {
      stroke: ${theme.palette.colors.purple[500]};
    }
  }
`;
