import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { LaunchButtonWithProactivePrompt } from '../launch-button/LaunchButtonWithProactivePrompt';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';

const PromptWidgetContent = () => {
  const { placement } = useSelector(selectWidgetConfiguration);

  const justifyContent = (() => {
    switch (placement) {
      case 'top':
        return 'flex-start';
      case 'middle':
        return 'center';
      default:
        return 'flex-end';
    }
  })();

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      justifyContent={justifyContent}
    >
      <LaunchButtonWithProactivePrompt />
    </Box>
  );
};

export default PromptWidgetContent;
