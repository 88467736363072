import Skeleton from 'react-loading-skeleton';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { INSIGHT_TOOLTIP_COPY } from '../solve-insights/constants';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { QuickFeedbackResponse } from 'src/services/insights/types';

interface QuickFeedbackCardProps {
  feedbackData?: QuickFeedbackResponse;
  isEmail?: boolean;
  isLoading: boolean;
}

const QuickFeedbackCard = ({
  feedbackData,
  isEmail,
  isLoading,
}: QuickFeedbackCardProps) => {
  const { palette } = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  if (!shouldShowQuickFeedbackAnalytics) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap='4px'>
      <Box alignItems='center' display='flex' gap='4px'>
        <Typography color={palette.colors.grey[800]} variant='font14Bold'>
          {isEmail ? 'User feedback' : 'Quick feedback'}
        </Typography>
        <Box
          display='flex'
          onMouseEnter={() => {
            emitTrackingEventCallback('insight-tooltip-hover', {
              scope: 'detail',
              tab: 'article',
              value: 'csat',
            });
          }}
        >
          <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.articles_user_feedback}>
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        </Box>
      </Box>
      {isLoading ? (
        <Skeleton height={30} />
      ) : (
        <Box display='flex' gap='14px'>
          <Box alignItems='self-end' display='flex' gap='4px'>
            <Typography color={palette.colors.green[500]} variant='font24'>
              {feedbackData?.misc?.positive}
            </Typography>
            <Typography variant='font14Medium'>positive</Typography>
          </Box>
          <Box alignItems='self-end' display='flex' gap='4px'>
            <Typography color={palette.colors.red[500]} variant='font24'>
              {feedbackData?.misc?.negative}
            </Typography>
            <Typography variant='font14Medium'>negative</Typography>
          </Box>
          <Box alignItems='self-end' display='flex' gap='4px'>
            <Typography color={palette.colors.black} variant='font24'>
              {feedbackData?.misc?.not_answered}
            </Typography>
            <Typography variant='font14Medium'>not answered</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default QuickFeedbackCard;
