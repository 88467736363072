import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatTimestamp } from '../helpers';
import { ExecutionTranscriptComponent } from './types';

export const ChatHandoffStatus = ({
  transcriptComponent,
}: {
  transcriptComponent: ExecutionTranscriptComponent;
}) => {
  const theme = useTheme();
  const statusText = transcriptComponent.success
    ? 'Successfully started chat'
    : 'Failed to start chat';

  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Line />
        <Typography variant='font14Bold'>&ensp;{statusText}</Typography>
        <Typography
          color={theme.palette.colors.grey[900]}
          variant='font14Medium'
        >
          , {formatTimestamp(transcriptComponent.timestamp)}&ensp;
        </Typography>
        <Line />
      </Box>
      {!transcriptComponent.success && (
        <Typography
          color={theme.palette.colors.grey[900]}
          variant='font14Medium'
        >
          ({transcriptComponent.message})
        </Typography>
      )}
    </Box>
  );
};

const Line = styled('div')`
  flex: 1;
  height: 1px;
  background-color: ${props => props.theme.palette.colors.slate[200]};
  z-index: 0;
  width: 100%;
  top: 12px;
`;
