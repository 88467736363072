import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';
import { IconSparkles } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import chatIcon from 'src/assets/images/chat-icon.svg';
import {
  selectLegibleButtonOutlineColor,
  selectLegibleElementColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const LaunchButton = ({ className }: { className?: string }) => {
  const theme = useTheme();
  const { help_button_image, launch_style, theme_color } = useSelector(
    selectWidgetConfiguration,
  );
  const legibleButtonOutlineColor = useSelector(
    selectLegibleButtonOutlineColor,
  );
  const legibleElementColor = useSelector(selectLegibleElementColor);

  if (launch_style === 'floating_bar') {
    return (
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '24px',
          boxShadow: `0 0 0 1px ${legibleButtonOutlineColor}, 0 12px 24px rgba(0, 0, 0, 0.08)`,
          display: 'flex',
          gap: '8px',
          padding: '14px 16px',
        }}
      >
        <IconSparkles color={legibleElementColor} size={24} />
        <Typography
          color={theme.palette.colors.grey[400]}
          variant='font16Medium'
        >
          Ask a question...
        </Typography>
      </Box>
    );
  }

  return help_button_image ? (
    <CustomImage alt='' className={className} src={help_button_image} />
  ) : (
    <PreviewButton background={theme_color || ''} className={className}>
      <PreviewImg alt='' src={chatIcon} />
    </PreviewButton>
  );
};

const PreviewImg = styled('img')`
  width: 34px;
  height: 43px;
`;

const PreviewButton = styled('div')<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${props => props.background};
`;

const CustomImage = styled('img')`
  max-height: 60px;
`;
