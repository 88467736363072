import { Box, styled } from '@mui/material';

import { ConfigSelector } from '../ConfigSelector';
import { RecordingDisclaimerConfig } from './RecordingDisclaimerConfig';

export const RecordingDisclaimerTabContent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <ConfigSelector />
      <Separator />
      <RecordingDisclaimerConfig />
    </Box>
  );
};

const Separator = styled('div')`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.palette.colors.grey[100]};
`;
