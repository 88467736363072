import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { FROM_NUMBER, TO_NUMBER } from '../constants';
import { InlineData } from '../ConversationDrawer/InlineData';
import { formatTimeSpent } from '../ConversationDrawer/utils';
import ConversationTranscript from '../ConversationTranscript';
import { TranscriptContainer } from '../ConversationTranscript/RevampConversationTranscript';
import { formatTimestamp } from '../helpers';
import VoiceTranscripts from './VoiceTranscripts';
import {
  AVG_RESPONSE_TIME_FAST,
  AVG_RESPONSE_TIME_SLOW,
  MID_RESPONSE_TIME_COLOR,
} from 'src/components/dashboard-pages/voice-insights/conversations/constants';
import { Conversation } from 'src/services/apiInterfaces';

export const VoiceDrawerBody = ({
  selectedConversation,
}: {
  selectedConversation: Conversation;
}) => {
  const {
    channel: conversationChannel,
    context_variables,
    conversation_id,
    deflected,
    last_executed_intent_user_query,
    start_datetime,
    transcript,
    user_queries,
    voice_metrics: voiceMetrics,
    voice_transcripts,
  } = selectedConversation;

  const { palette } = useTheme();

  const sentiment = voiceMetrics?.sentiment;
  const callDuration = voiceMetrics?.call_duration;
  const avgResponseTime = voiceMetrics?.avg_response_time
    ? Math.round(voiceMetrics?.avg_response_time * 100) / 100
    : undefined;

  const getColorForAvgResponseTime = (avgResponseTime: number | undefined) => {
    if (avgResponseTime === undefined) {
      return undefined;
    }

    if (avgResponseTime < AVG_RESPONSE_TIME_FAST) {
      return palette.colors.green[500];
    }

    if (avgResponseTime > AVG_RESPONSE_TIME_SLOW) {
      return palette.colors.red[500];
    }

    return MID_RESPONSE_TIME_COLOR;
  };

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Conversation</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='30px' py='24px'>
        {/* Info section */}
        <Box display='flex' flexDirection='column' gap='12px'>
          <InlineData label='Chat ID' value={conversation_id} />
          <InlineData label='Deflected' value={deflected ? 'True' : 'False'} />
        </Box>
        {/* Chat section */}
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Conversation</Typography>
          <Card>
            <CardSection>
              <InlineData
                label='Time created'
                value={formatTimestamp(start_datetime + 'Z')}
              />
              <InlineData label='Channel' value={conversationChannel} />
              <InlineData
                label='From number'
                value={context_variables?.[FROM_NUMBER] || 'n/a'}
              />
              <InlineData
                label='To number'
                value={context_variables?.[TO_NUMBER] || 'n/a'}
              />
              <InlineData
                label='Call length'
                value={callDuration ? formatTimeSpent(callDuration) : 'n/a'}
              />
              <InlineData
                color={getColorForAvgResponseTime(avgResponseTime)}
                label='Avg. AI response time'
                value={avgResponseTime ? `${avgResponseTime}sec` : 'n/a'}
              />
              <InlineData label='Sentiment' value={sentiment || 'n/a'} />
            </CardSection>

            <Divider />

            <CardSection>
              <Typography color={palette.colors.grey[600]} variant='font12'>
                User queries
              </Typography>
              {user_queries ? (
                <Box display='flex' flexDirection='column'>
                  {user_queries.map((query, index) => (
                    <i key={index}>
                      <Typography
                        color={palette.colors.blue[600]}
                        variant='font14'
                      >
                        {query}
                      </Typography>
                    </i>
                  ))}
                </Box>
              ) : (
                <i>
                  <Typography color={palette.colors.blue[600]} variant='font14'>
                    &quot;{last_executed_intent_user_query}&quot;
                  </Typography>
                </i>
              )}
            </CardSection>
          </Card>
        </Box>
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Call Transcript</Typography>
          <TranscriptContainer>
            {voice_transcripts ? (
              <VoiceTranscripts transcripts={voice_transcripts} />
            ) : (
              <ConversationTranscript
                transcript={transcript}
                variant='drawer'
              />
            )}
          </TranscriptContainer>
        </Box>
      </Box>
    </Box>
  );
};

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Card = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
`;

const CardSection = styled(Box)<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${props => props.gap && `gap: ${props.gap}`}
`;
