import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';

import { IconButton } from '@forethought-technologies/forethought-elements';
import { ArrayItemProps } from './types';

export const ArrayFieldItem = ({
  children,
  disabled,
  hasMoveDown,
  hasMoveUp,
  hasRemove,
  hasToolbar,
  index,
  onDropIndexClick,
  onReorderClick,
  readonly,
}: ArrayItemProps) => {
  return (
    <Grid alignItems='center' container={true}>
      <Grid item={true} style={{ overflow: 'auto' }} xs>
        <Box>
          <Box>{children}</Box>
        </Box>
      </Grid>

      {hasToolbar && (
        <Grid item={true}>
          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              aria-label='arrow-up'
              disabled={disabled || readonly || !hasMoveUp}
              onClick={onReorderClick(index, index - 1)}
            >
              <ArrowUpwardIcon />
            </IconButton>
          )}

          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              aria-label='arrow-down'
              disabled={disabled || readonly || !hasMoveDown}
              onClick={onReorderClick(index, index + 1)}
            >
              <ArrowDownwardIcon />
            </IconButton>
          )}

          {hasRemove && (
            <IconButton
              aria-label='remove'
              disabled={disabled || readonly}
              onClick={onDropIndexClick(index)}
              variant='ghost'
            >
              <IconTrash size={20} />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};
