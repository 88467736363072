import { Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatDateForTicketCard } from 'src/components/dashboard-pages/discover-topic-detail-page/helpers';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface TargetArticleSectionProps {
  article: KnowledgeGapArticleRecommendation;
}

const TargetArticleSection = ({ article }: TargetArticleSectionProps) => {
  const { palette } = useTheme();
  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{ '&>a': { display: 'flex', textDecoration: 'none' } }}
    >
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Target article
      </Typography>
      {article.updated_article_link && (
        <Box alignItems='end' display='flex' gap={1}>
          <a
            href={article.updated_article_link}
            rel='noreferrer'
            target='_blank'
          >
            <Box
              borderRadius={1}
              px={0.5}
              sx={{
                '&:hover': {
                  bgcolor: palette.colors.slate[200],
                  cursor: 'pointer',
                },
                '&>span': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography
                color={palette.colors.blue[600]}
                variant='font12Medium'
              >
                {article.updated_article_title}
              </Typography>
            </Box>
          </a>
          {article.updated_article_last_modified_date && (
            <Typography color={palette.colors.grey[600]} variant='font12'>
              Last modified{' '}
              {formatDateForTicketCard(
                new Date(article.updated_article_last_modified_date).getTime(),
                true,
              )}
            </Typography>
          )}
        </Box>
      )}
      {!article.updated_article_link && (
        <Typography color={palette.colors.grey[500]} variant='font12'>
          {NA}
        </Typography>
      )}
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default TargetArticleSection;
