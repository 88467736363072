import { IndicatorStep } from './IndicatorStep';
import { IntentTriggeredTranscriptComponent } from './types';

export const IntentTrigger = ({
  transcriptComponent,
}: {
  transcriptComponent: IntentTriggeredTranscriptComponent;
}) => {
  const title = transcriptComponent.intent_title;
  return <IndicatorStep message={`Triggered workflow: ${title}`} />;
};
