import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface AlertedErrorNotFoundDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const AlertedErrorNotFoundDialog = ({
  isOpen,
  onClose,
}: AlertedErrorNotFoundDialogProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      title='The error is over 30 days old'
    >
      <Box display='flex' flexDirection='column' gap='51px' maxWidth='300px'>
        <Typography variant='font14'>
          The error you are looking for is no longer available. Errors are
          stored for 30 days in the Error Log.
        </Typography>
        <Button onClick={onClose} variant='main'>
          Got it
        </Button>
      </Box>
    </Dialog>
  );
};

export default AlertedErrorNotFoundDialog;
