import { Product } from '../apiInterfaces';
import { IntentType } from 'src/pages/workflow-builder/suggested-improvements/constants';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';

export type IntentTestStatus = 'pending' | 'success' | 'failure';

export interface IntentTestResponse {
  channel: ConversationChannel;
  conversation_ids?: string[];
  coverage: number | null;
  created_at: string;
  created_by: string;
  end_datetime: string;
  fallback_workflow_id: 'Knowledge_Article' | null;
  intent_test_id: string;
  labelled_inputs: { query_id: string }[];
  name: string;
  start_datetime: string;
  status: IntentTestStatus;
  ticket_ids: string[];
  volume: number | null;
}

export interface IntentTestsResponse {
  intent_tests: IntentTestResponse[];
  /**
   * Pusher channel to subscribe to for updates
   */
  update_channel: string;
}

export type EmailWorkflowExecutionStatus =
  | 'started'
  | 'workflow_not_found'
  | 'ticket_field_not_found'
  | 'no_matching_transition'
  | 'entry_step_not_found'
  | 'finished'
  | 'no_response'
  | 'execution_error'
  | 'zero_dynamic_articles'
  | 'no_plausible_response'
  | 'test_blocked_by_action_step';

interface EmailWorkflowExecutionError {
  message: string;
  step_id: string;
}

interface EmailConversation {
  conversation_id: string;
  email_response_html: string | null;
  email_workflow_execution_error: EmailWorkflowExecutionError | null;
  email_workflow_execution_status: EmailWorkflowExecutionStatus;
}

export function isEmailConversation(
  conversation: EmailConversation | WidgetConversation,
): conversation is EmailConversation {
  return (conversation as EmailConversation).email_response_html !== undefined;
}

interface WidgetConversation {
  conversation_id: string;
}

export interface EmailInquiry {
  body: string;
  created_date: string;
  source_doc_type: string;
  source_id: string;
  summarizer_output: string | null;
  title: string;
  trimmer_output: string | null;
}

export function isEmailInquiry(
  inquiry: EmailInquiry | WidgetInquiry,
): inquiry is EmailInquiry {
  return (inquiry as EmailInquiry).source_id !== undefined;
}

export interface WidgetInquiry {
  conversation_id: string;
  created_date: string;
  original_intent_definition_id: string | null;
  query_to_predict_on: string | null;
  user_input: string;
}

interface IntentPrediction {
  confidence: number;
  description: string;
  intent_definition_id: string;
  query: string;
  title: string;
}

export type Sentiment = 'positive' | 'negative';

export interface FeedbackRecord {
  correct_intent_id: string | null;
  intent_definition_id: string | null;
  intent_prediction_record_id: string;
  intent_test_id: string;
  notes: string | null;
  product: Product;
  sentiment: Sentiment;
}

export interface IntentPredictionRecord {
  conversation: EmailConversation | WidgetConversation;
  feedback_records: FeedbackRecord[] | null;
  inquiry: EmailInquiry | WidgetInquiry;
  intent_prediction: IntentPrediction | null;
  record_id: string;
}

export interface IntentPredictionRecords {
  records: IntentPredictionRecord[];
}

export interface CreateIntentTestRequest {
  channel: ConversationChannel;
  conversation_ids?: string[];
  eligible_intent_ids: string[];
  end_datetime: string;
  fallback_workflow_id?: 'Knowledge_Article' | null;
  name: string | null;
  start_datetime: string;
  ticket_ids?: string[];
}

export interface IntentTestUpdateEvent {
  coverage: number | null;
  intent_test_id: string;
  status: IntentTestStatus;
  volume: number | null;
}

export interface IntentDefinitionModel {
  description: string;
  intent_id: string;
  intent_type: IntentType;
  title: string;
  workflow_types_enabled: WorkflowTypes[];
}

export interface IntentTestUsage {
  max_test_size: number;
  pending_test_count: number;
  remaining_weekly_conversations: number;
  weekly_conversation_limit: number;
}
