import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { dateRangeToTimestamp } from '../helpers';
import { InsightPeriod, ProductMode, Scope } from '../types';
import {
  renderLineGraphUtils,
  replaceChatsWithConversations,
} from 'src/components/solve-insights-charts/line-graph/lineGraphUtils';
import { NA } from 'src/constants/solve';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import { useGetArticleDetailGraphQuery } from 'src/services/insights';
import { ArticleDetailResponse } from 'src/services/insights/types';

interface ArticlesLineChartProps {
  articleId: string;
  channels: string[];
  dateRange: DateRange;
  periodicalFilter: InsightPeriod;
  productMode: ProductMode;
  scope: Scope;
}

const ArticlesLineChart: React.FC<
  React.PropsWithChildren<ArticlesLineChartProps>
> = ({
  articleId,
  channels,
  dateRange,
  periodicalFilter,
  productMode,
  scope,
}) => {
  const { palette } = useTheme();
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  const {
    data: articleDetailData,
    isFetching,
    isLoading,
  } = useGetArticleDetailGraphQuery({
    article_id: articleId,
    channels: buildQueryFromMultiSelected(channels)?.channels,
    end: backendReadyTimestamps.end_timestamp,
    period: periodicalFilter,
    product: productMode,
    start: backendReadyTimestamps.start_timestamp,
  });

  // replace labels
  const data: ArticleDetailResponse =
    replaceChatsWithConversations(articleDetailData);

  // Support complex tooltip values in line graph
  const transformedData = useMemo(() => {
    if (!data || !data.line || data.line.x_axis.length === 0) {
      return null;
    }

    const transformedValues = data.line.values.map(value => ({
      ...value,
      ...(value.tooltip_values && {
        tooltip_values: [
          {
            tooltip_values: value.tooltip_values,
          },
        ],
      }),
    }));

    return {
      ...data,
      line: {
        ...data.line,
        values: transformedValues,
      },
    };
  }, [data]);

  if (isLoading || isFetching) {
    return <Skeleton height='300px' width='100%' />;
  }

  if (!transformedData) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  return renderLineGraphUtils(palette, transformedData, scope);
};

export default ArticlesLineChart;
