import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export const ExecutionIndicator = ({
  message,
  timestamp,
}: {
  message: string;
  timestamp: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      <TextContainer>
        <Typography variant='font14Bold'>{message}</Typography>
        <Typography
          color={theme.palette.colors.grey[900]}
          variant='font14Medium'
        >
          , {timestamp}
        </Typography>
      </TextContainer>

      <Line />
    </Box>
  );
};

const TextContainer = styled('div')`
  z-index: 5;
  background-color: white;
  padding: 0 8px;
`;

const Line = styled('div')`
  z-index: 0;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.colors.slate[200]};
  position: absolute;
  top: 12px;
`;
