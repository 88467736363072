import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  ColorInput,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  CHAT_HEADER_PLACEHOLDER,
  DEFAULT_INTRO_CONFIG_SUBTITLE,
  DEFAULT_INTRO_CONFIG_TITLE,
  GREETING_LINE_PLACEHOLDER,
} from '../constants';
import { useGetSelectedBrand } from '../hooks';
import {
  useDebouncedSubmitConfigForNonRTE,
  useDebouncedSubmitConfigForRTE,
} from '../hooks/useDebouncedSubmitConfig';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import { LaunchButton } from '../launch-button/LaunchButton';
import { Field, RTEContainer, TabContentContainer } from '../styledComponents';
import { ImageUploadError } from './ImageUploadError';
import IntentOptionsForm from './IntentOptionsForm';
import debounce from 'lodash/fp/debounce';
import agentChatImageDefaultIcon from 'src/assets/images/agent-chat-image-default.svg';
import logoPlaceholderIcon from 'src/assets/images/logo-placeholder.svg';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { isImageASquare } from 'src/services/workflow-builder/utils';
import {
  confirmAssetUploadComplete,
  createAssetUploadLinkAPI,
  deleteAssetAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
import { updateOnboardingFlags } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import {
  selectWidgetConfiguration,
  setFont,
  setGreetingLine,
  setIntroImageEnabled,
  setIntroSubtitleEnabled,
  setIntroSubtitleValue,
  setIntroTitleEnabled,
  setIntroTitleValue,
  setLaunchStyle,
  setPlacement,
  setSize,
  setStartUploadingImage,
  setTabName,
  setThemeColor,
  updateWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';
import {
  SolveConfigTrackingEventTypes,
  SolveConfigurationTypes,
} from 'src/utils/enums';

const fonts = (
  [
    'Arial',
    'Comfortaa',
    'Georgia',
    'Inter',
    'Merriweather',
    'Playfair Display',
    'Plus Jakarta Sans',
    'Roboto',
    'Roboto Mono',
    'Times New Roman',
    'Verdana',
  ] as const
).map(font => ({ label: font, value: font } as const));

const ThemeTabContent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const editorRef = useRef<EditorRef>(null);
  const logoInputRef = useRef<HTMLInputElement>(null);
  const introImageInputRef = useRef<HTMLInputElement>(null);
  const avatarImageInputRef = useRef<HTMLInputElement>(null);
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const showLaunchStyle = useIsFeatureFlagEnabled('launch_style_config_ui');
  const selectedBrand = useGetSelectedBrand();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDeletingImage, setisDeletingImage] = useState<boolean>(false);

  // selectors
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const remirrorContextVariables = useGetRemirrorContextVariables();
  const {
    agent_chat_image: agentChatImage,
    font,
    greeting_line: greetingLine,
    header_image: headerImage,
    help_button_image,
    intro_config,
    launch_style: launchStyle,
    placement,
    size,
    tab_name: tabName,
    theme_color: themeColor,
  } = widgetConfiguration;
  const isGreetingEmpty = greetingLine === '';

  // states
  const [isLogoValid, setIsLogoValid] = useState(true);
  const [isAvatarImageValid, setIsAvatarImageValid] = useState(true);

  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();
  const debouncedSubmitConfigForNonRTE = useDebouncedSubmitConfigForNonRTE();

  const debouncedSetThemeColor = useMemo(
    () => debounce(250, (value: string) => dispatch(setThemeColor(value))),
    [dispatch],
  );

  const handleLaunchStyleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newLaunchStyle = event.target
      .value as WidgetConfiguration['launch_style'];
    const placement: WidgetConfiguration['placement'] =
      newLaunchStyle === 'floating_bar' ? 'bottom' : 'bottom_right';

    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      launch_style: newLaunchStyle,
      placement: placement,
    };

    dispatch(setPlacement(placement));
    dispatch(setLaunchStyle(newLaunchStyle));

    debouncedSubmitConfigForNonRTE(
      updatedConfig,
      'launch_style',
      newLaunchStyle,
    );
    debouncedSubmitConfigForNonRTE(updatedConfig, 'placement', placement);
  };

  const handleSizeChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      size: event.target.value as WidgetConfiguration['size'],
    };
    dispatch(setSize(event.target.value as WidgetConfiguration['size']));
    debouncedSubmitConfigForNonRTE(updatedConfig, 'size', event.target.value);
  };

  const handlePlacementChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      placement: event.target.value as WidgetConfiguration['placement'],
    };
    dispatch(
      setPlacement(event.target.value as WidgetConfiguration['placement']),
    );
    debouncedSubmitConfigForNonRTE(
      updatedConfig,
      'placement',
      event.target.value,
    );
  };

  const handleGreetingLineChange = (html: string) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      greeting_line: html,
    };
    dispatch(setGreetingLine(html));
    debouncedSubmitConfig(updatedConfig, 'greeting_line', html);
  };

  const handleChatHeaderChange = (value: string) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      tab_name: value,
    };
    dispatch(setTabName(value));
    debouncedSubmitConfig(updatedConfig, 'tab_name', value);
  };

  const handleThemeColorChange = (value: string) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      theme_color: value,
    };
    debouncedSetThemeColor(value);
    debouncedSubmitConfig(updatedConfig, 'theme_color', value);
  };

  const handleLogoChange = async () => {
    const file = logoInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();
      const isValidImage = await isImageASquare(file);
      if (!isValidImage) {
        setIsLogoValid(false);
        return;
      }

      // show loading state in header properly
      dispatch(setStartUploadingImage());

      setIsLogoValid(true);
      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            header_image: asset.file_name,
          };

          dispatch(
            updateWidgetConfiguration({
              brandId: selectedBrand?.brand_id,
              isMultibrandEnabled,
              widgetConfiguration: updatedWidgetConfiguration,
            }),
          );
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'header_image',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const handleIntroImageCheckboxChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      intro_config: {
        ...widgetConfiguration.intro_config,
        image: {
          ...widgetConfiguration.intro_config.image,
          enabled: event.target.checked,
        },
      },
    };
    dispatch(setIntroImageEnabled(event.target.checked));
    debouncedSubmitConfigForNonRTE(
      updatedConfig,
      'intro_config.image.enabled',
      event.target.value,
    );
  };

  const handleIntroImageChange = async () => {
    const file = introImageInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();

      // show loading state in header properly
      dispatch(setStartUploadingImage());

      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            intro_config: {
              ...widgetConfiguration.intro_config,
              image: {
                ...widgetConfiguration.intro_config.image,
                value: asset.file_name,
              },
            },
          };

          dispatch(
            updateWidgetConfiguration({
              brandId: selectedBrand?.brand_id,
              isMultibrandEnabled,
              widgetConfiguration: updatedWidgetConfiguration,
            }),
          );
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'intro_config.image.value',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const handleIntroTitleCheckboxChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      intro_config: {
        ...widgetConfiguration.intro_config,
        title: {
          ...widgetConfiguration.intro_config.title,
          enabled: event.target.checked,
        },
      },
    };
    dispatch(setIntroTitleEnabled(event.target.checked));
    debouncedSubmitConfigForNonRTE(
      updatedConfig,
      'intro_config.title.enabled',
      event.target.value,
    );
  };

  const handleIntroTitleValueChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      intro_config: {
        ...widgetConfiguration.intro_config,
        title: {
          ...widgetConfiguration.intro_config.title,
          value: event.target.value,
        },
      },
    };
    dispatch(setIntroTitleValue(event.target.value));
    debouncedSubmitConfig(
      updatedConfig,
      'intro_config.title.value',
      event.target.value,
    );
  };

  const handleIntroSubtitleCheckboxChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      intro_config: {
        ...widgetConfiguration.intro_config,
        subtitle: {
          ...widgetConfiguration.intro_config.subtitle,
          enabled: event.target.checked,
        },
      },
    };
    dispatch(setIntroSubtitleEnabled(event.target.checked));
    debouncedSubmitConfigForNonRTE(
      updatedConfig,
      'intro_config.subtitle.enabled',
      event.target.value,
    );
  };

  const handleIntroSubtitleValueChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      intro_config: {
        ...widgetConfiguration.intro_config,
        subtitle: {
          ...widgetConfiguration.intro_config.subtitle,
          value: event.target.value,
        },
      },
    };
    dispatch(setIntroSubtitleValue(event.target.value));
    debouncedSubmitConfig(
      updatedConfig,
      'intro_config.subtitle.value',
      event.target.value,
    );
  };

  const handleFontChange: ComponentProps<
    typeof SelectDropdown
  >['onChange'] = event => {
    const value = event.target.value as string;
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      font: value,
    };
    dispatch(setFont(value));
    debouncedSubmitConfigForNonRTE(updatedConfig, 'font', value);
  };

  const handleAvatarImageChange = async () => {
    const file = avatarImageInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();

      const isValidImage = await isImageASquare(file);
      if (!isValidImage) {
        setIsAvatarImageValid(false);
        return;
      }

      // show loading state in header properly
      dispatch(setStartUploadingImage());

      setIsAvatarImageValid(true);
      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            agent_chat_image: asset.file_name,
          };

          dispatch(
            updateWidgetConfiguration({
              brandId: selectedBrand?.brand_id,
              isMultibrandEnabled,
              widgetConfiguration: updatedWidgetConfiguration,
            }),
          );
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'agent_chat_image',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const handleLaunchButtonImageChange = async () => {
    // show loading state in header properly
    dispatch(setStartUploadingImage());
    const file = fileInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();

      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          /**
           * *Note* Eventually if we have the ability to revert to default launch button
           * use_help_button_image_as_icon would be false
           */
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            help_button_image: asset.file_name,
            use_help_button_image_as_icon: true,
          };

          dispatch(
            updateWidgetConfiguration({
              brandId: selectedBrand?.brand_id,
              isMultibrandEnabled,
              widgetConfiguration: updatedWidgetConfiguration,
            }),
          );
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'help_button_image',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const handleImageDelete = async () => {
    if (isDeletingImage) {
      return;
    }

    setisDeletingImage(true);
    try {
      await deleteAssetAPI(help_button_image || '');
      const updatedWidgetConfiguration = {
        ...widgetConfiguration,
        help_button_image: null,
        use_help_button_image_as_icon: false,
      };
      dispatch(
        updateWidgetConfiguration({
          brandId: selectedBrand?.brand_id,
          isMultibrandEnabled,
          widgetConfiguration: updatedWidgetConfiguration,
        }),
      );
    } catch (error) {
      console.error('Failed to delete image', error);
    } finally {
      setisDeletingImage(false);
    }
  };

  const scrollToErrorMessage = () => {
    errorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isGreetingEmpty) {
      // needed the setTimeout so we can scroll into the error message after it is rendered
      setTimeout(scrollToErrorMessage, 50);
    }
  }, [isGreetingEmpty]);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isOnboardingExperienceEnabled = featureFlags.includes(
    'onboarding_experience',
  );

  useEffect(() => {
    if (isOnboardingExperienceEnabled) {
      dispatch(updateOnboardingFlags({ is_theme_visited: true }));
    }
  }, [dispatch, isOnboardingExperienceEnabled]);

  return (
    <TabContentContainer>
      {/* Launch Style */}
      {showLaunchStyle && (
        <Field>
          <Typography variant='font16Bold'>Launch style</Typography>
          <Box display='flex' gap='16px'>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='end'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='50%'
                  height='8px'
                  width='8px'
                ></Box>
              </Box>
              Launch icon
              <RadioInput
                checked={launchStyle === 'icon'}
                name='launch-style'
                onChange={handleLaunchStyleChange}
                type='radio'
                value='icon'
              />
            </LabelForRadioInput>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='center'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='10px'
                  height='8px'
                  width='34px'
                ></Box>
              </Box>
              Floating bar
              <RadioInput
                checked={launchStyle === 'floating_bar'}
                name='launch-style'
                onChange={handleLaunchStyleChange}
                type='radio'
                value='floating_bar'
              />
            </LabelForRadioInput>
          </Box>
        </Field>
      )}
      {/* Size */}
      {launchStyle !== 'floating_bar' && (
        <Field>
          <Typography variant='font16Bold'>Size</Typography>
          <Box display='flex' gap='18px' justifyContent='space-between'>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='end'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='4px'
                  height='33px'
                  width='21px'
                ></Box>
              </Box>
              Standard
              <RadioInput
                checked={size === 'standard'}
                name='size'
                onChange={handleSizeChange}
                type='radio'
                value='standard'
              />
            </LabelForRadioInput>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='end'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='4px'
                  height='38px'
                  width='31px'
                ></Box>
              </Box>
              Large
              <RadioInput
                checked={size === 'large'}
                name='size'
                onChange={handleSizeChange}
                type='radio'
                value='large'
              />
            </LabelForRadioInput>
          </Box>
        </Field>
      )}
      {/* Placement */}
      {launchStyle === 'floating_bar' ? (
        <Field>
          <Typography variant='font16Bold'>Placement</Typography>
          <Box display='flex' gap='16px' justifyContent='space-between'>
            <LabelForRadioInput>
              <Box
                alignItems='start'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='center'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='10px'
                  height='8px'
                  width='34px'
                ></Box>
              </Box>
              Top
              <RadioInput
                checked={placement === 'top'}
                name='placement'
                onChange={handlePlacementChange}
                type='radio'
                value='top'
              />
            </LabelForRadioInput>
            <LabelForRadioInput>
              <Box
                alignItems='center'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='center'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='10px'
                  height='8px'
                  width='34px'
                ></Box>
              </Box>
              Middle
              <RadioInput
                checked={placement === 'middle'}
                name='placement'
                onChange={handlePlacementChange}
                type='radio'
                value='middle'
              />
            </LabelForRadioInput>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='center'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='10px'
                  height='8px'
                  width='34px'
                ></Box>
              </Box>
              Bottom
              <RadioInput
                checked={placement === 'bottom'}
                name='placement'
                onChange={handlePlacementChange}
                type='radio'
                value='bottom'
              />
            </LabelForRadioInput>
          </Box>
        </Field>
      ) : (
        <Field>
          <Typography variant='font16Bold'>Placement</Typography>
          <Box display='flex' gap='18px' justifyContent='space-between'>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='end'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='4px'
                  height='33px'
                  width='21px'
                ></Box>
              </Box>
              Bottom right
              <RadioInput
                checked={placement === 'bottom_right'}
                name='placement'
                onChange={handlePlacementChange}
                type='radio'
                value='bottom_right'
              />
            </LabelForRadioInput>
            <LabelForRadioInput>
              <Box
                alignItems='end'
                border={`1.2px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='2px'
                display='flex'
                height='59px'
                justifyContent='start'
                padding='6px'
                width='94px'
              >
                <Box
                  bgcolor={theme.palette.colors.purple[300]}
                  borderRadius='4px'
                  height='33px'
                  width='21px'
                ></Box>
              </Box>
              Bottom left
              <RadioInput
                checked={placement === 'bottom_left'}
                name='placement'
                onChange={handlePlacementChange}
                type='radio'
                value='bottom_left'
              />
            </LabelForRadioInput>
          </Box>
        </Field>
      )}
      {/* Launch icon */}
      {launchStyle === 'icon' && (
        <Field>
          <LaunchButtonTitleContainer>
            <Typography variant='font16Bold'>Launch icon</Typography>
          </LaunchButtonTitleContainer>
          <LaunchButtonContainer>
            <LaunchButton />
          </LaunchButtonContainer>
          <Button onClick={() => fileInputRef.current?.click()} variant='ghost'>
            Change image
          </Button>
          {help_button_image && (
            <Button
              disabled={isDeletingImage}
              onClick={handleImageDelete}
              variant='secondary'
            >
              {isDeletingImage ? 'Resetting...' : 'Reset to default'}
            </Button>
          )}
          <input
            accept='image/*'
            hidden
            onChange={handleLaunchButtonImageChange}
            ref={fileInputRef}
            type='file'
          />
        </Field>
      )}
      {/* Header logo */}
      <Field>
        <Typography variant='font16Bold'>Header logo</Typography>
        {isLogoValid ? (
          <ImageContainer>
            <IconImage
              alt=''
              src={headerImage ? headerImage : logoPlaceholderIcon}
            />
          </ImageContainer>
        ) : (
          <ImageUploadError />
        )}
        {launchStyle === 'floating_bar' && (
          <Box sx={{ marginY: '-12px' }}>
            <Typography color={theme.palette.colors.grey[600]} variant='font12'>
              This header logo will be displayed on mobile device screens.
            </Typography>
          </Box>
        )}
        <Button onClick={() => logoInputRef.current?.click()} variant='ghost'>
          Change image
        </Button>
        <input
          accept='image/*'
          hidden
          onChange={handleLogoChange}
          ref={logoInputRef}
          type='file'
        />
      </Field>
      {/* Chat header */}
      <Field>
        <Typography variant='font16Bold'>Chat header</Typography>
        <TextField
          aria-label='Chat header'
          onChange={e => {
            handleChatHeaderChange(e.target.value);
          }}
          placeholder={CHAT_HEADER_PLACEHOLDER}
          value={tabName}
        />
        {launchStyle === 'floating_bar' && (
          <Box sx={{ marginY: '-12px' }}>
            <Typography color={theme.palette.colors.grey[600]} variant='font12'>
              This chat header will be displayed on mobile device screens.
            </Typography>
          </Box>
        )}
      </Field>
      {/* Font */}
      <Field>
        <Typography variant='font16Bold'>Font</Typography>
        <Box
          sx={{
            '.MuiSelect-select': {
              fontFamily: `${font} !important`,
            },
          }}
        >
          <SelectDropdown
            aria-label='Font'
            id='font'
            onChange={handleFontChange}
            options={fonts}
            value={font}
          />
        </Box>
      </Field>
      {/* Color */}
      <Field>
        <Typography variant='font16Bold'>Color</Typography>
        <ColorInput
          color={themeColor}
          onChange={e => {
            handleThemeColorChange(e.target.value);
          }}
          subLabel='Theme color'
        />
      </Field>
      {/* Avatar image */}
      <Field>
        <Typography variant='font16Bold'>Avatar image</Typography>
        {isAvatarImageValid ? (
          <ImageContainer>
            <IconImage
              alt=''
              src={agentChatImage ? agentChatImage : agentChatImageDefaultIcon}
            />
          </ImageContainer>
        ) : (
          <ImageUploadError />
        )}
        <Button
          onClick={() => avatarImageInputRef.current?.click()}
          variant='ghost'
        >
          Change image
        </Button>
        <input
          accept='image/*'
          hidden
          onChange={handleAvatarImageChange}
          ref={avatarImageInputRef}
          type='file'
        />
      </Field>
      {/* Greeting */}
      <Field>
        <Typography variant='font16Bold'>Greeting</Typography>
        <div>
          <RTEContainer hasError={isGreetingEmpty}>
            <RichTextEditor
              contextVariables={remirrorContextVariables}
              editorRef={editorRef}
              initialContent={greetingLine}
              label='Greeting'
              onChange={handleGreetingLineChange}
              placeholder={GREETING_LINE_PLACEHOLDER}
            />
          </RTEContainer>
          <div ref={errorMessageRef}>
            {isGreetingEmpty && (
              <Typography
                color={theme.palette.colors.red[500]}
                variant='font14Medium'
              >
                Enter a greeting message. Greeting cannot be empty.
              </Typography>
            )}
          </div>
        </div>
      </Field>
      {/* Intro */}
      <Field>
        <Typography variant='font16Bold'>Intro screen</Typography>
        <Checkbox
          checked={intro_config.image.enabled}
          label='Intro image'
          onChange={handleIntroImageCheckboxChange}
        />
        {intro_config.image.enabled && (
          <>
            <ImageContainer>
              <IconImage
                alt=''
                src={intro_config.image.value || logoPlaceholderIcon}
              />
            </ImageContainer>
            <Typography color={theme.palette.colors.grey[600]} variant='font12'>
              You can upload images with any ratio, and we&apos;ll resize them
              proportionally to a maximum height of 64 pixels.
            </Typography>
            <Button
              onClick={() => introImageInputRef.current?.click()}
              variant='ghost'
            >
              Change image
            </Button>
            <input
              accept='image/*'
              hidden
              onChange={handleIntroImageChange}
              ref={introImageInputRef}
              type='file'
            />
          </>
        )}
        <Box>
          <Checkbox
            checked={intro_config.title.enabled}
            label='Intro title'
            onChange={handleIntroTitleCheckboxChange}
          />
          {intro_config.title.enabled && (
            <TextField
              aria-label='Intro title text'
              error={!intro_config.title.value}
              helperText={
                intro_config.title.value
                  ? 'Max 40 characters'
                  : 'Field is required'
              }
              maxLength={40}
              onChange={handleIntroTitleValueChange}
              placeholder={DEFAULT_INTRO_CONFIG_TITLE}
              required
              value={intro_config.title.value}
            />
          )}
        </Box>
        <Box>
          <Checkbox
            checked={intro_config.subtitle.enabled}
            label='Intro subtitle'
            onChange={handleIntroSubtitleCheckboxChange}
          />
          {intro_config.subtitle.enabled && (
            <TextField
              aria-label='Intro subtitle text'
              error={!intro_config.subtitle.value}
              helperText={
                intro_config.subtitle.value
                  ? 'Max 160 characters'
                  : 'Field is required'
              }
              maxLength={160}
              multiline
              onChange={handleIntroSubtitleValueChange}
              placeholder={DEFAULT_INTRO_CONFIG_SUBTITLE}
              required
              value={intro_config.subtitle.value}
            />
          )}
        </Box>
      </Field>
      <IntentOptionsForm />
    </TabContentContainer>
  );
};

const ImageContainer = styled('div')`
  height: 96px;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled('img')`
  height: 60px;
  width: 60px;
`;

const LaunchButtonTitleContainer = styled('div')`
  margin-bottom: 13px;
`;

const LaunchButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  border: 1px solid ${props => props.theme.palette.colors.grey[300]};
  border-radius: 6px;
  padding: 20px;
`;

const LabelForRadioInput = styled('label')`
  cursor: pointer;
  flex: 1;
  height: 97px;
  padding: 5px 16px 5px 16px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.palette.colors.grey[100]};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 4px;
  color: ${props => props.theme.palette.colors.grey[400]};

  :hover {
    background-color: ${props => props.theme.palette.colors.purple[100]};
  }

  :has(:checked) {
    color: ${props => props.theme.palette.text.primary};
    border-color: ${props => props.theme.palette.colors.purple[500]};
  }
`;

const RadioInput = styled('input')`
  appearance: none;
`;

export default ThemeTabContent;
