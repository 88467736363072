import { permissionsArray } from './appRouteMappings';
import { SALESFORCE_CONTACT_EMAIL_KEY } from './constants';
import { Permissions } from './types';
import { getOrgIdAndOrgCode } from 'src/services/api';
import { createSalesforceContact } from 'src/services/apiV1';
import store from 'src/store/store';
import { getUserRole, Routes, UserRoles } from 'src/utils/enums';

interface LocalStorageOrgData {
  org_id: number;
  org_name: string;
}

/*
    callback function to refresh all tabs whenever a super admin
    has selected a new org
 */
export const goToHomePage = async (e: StorageEvent) => {
  const rawLocalStorageOrgData = window.localStorage.getItem('org');
  const userState = store.getState().user;
  const userRole = userState.role;
  const isSuperAdmin = userRole === UserRoles.ROLE_SUPER_ADMIN;
  const isOrgStorageKey = e.key === 'org';
  const shouldReturnEarly =
    !rawLocalStorageOrgData || !isSuperAdmin || !isOrgStorageKey;

  if (shouldReturnEarly) {
    return;
  }

  try {
    const data = await getOrgIdAndOrgCode();
    const orgName = data.organization_id;

    const localStorageOrgData: LocalStorageOrgData = JSON.parse(
      rawLocalStorageOrgData,
    );

    if (orgName && localStorageOrgData.org_name !== orgName) {
      document.location.href = '/';
    }
  } catch {
    return;
  }
};

export const getTabsFromPermissions = (permissionKeys: string[]): string[] => {
  return permissionKeys.map((permKey: string) => {
    return permissionsArray[permKey].tab;
  });
};

export const getPermissionKeys = (permissions: Permissions): string[] => {
  return Object.keys(permissions)
    .filter(permKey => permissions[permKey]) // this removes keys without permissions
    .filter(permKey => permissionsArray[permKey]) // removes invalid permissions
    .sort();
};

export const getIsValidRoute = () => {
  const userLocation = location.pathname as Routes;

  const routes = [
    Routes.ANSWERS,
    Routes.SOLVE,
    Routes.ACTION_BUILDER,
    Routes.ACTION_BUILDER_EDIT,
    Routes.WORKFLOW_BUILDER,
    Routes.WORKFLOW_BUILDER_EDIT,
    Routes.SOLVE_ANALYTICS_V2,
    Routes.SOLVE_LITE,
    Routes.SOLVE_CONFIGURATION,
    Routes.SOLVE_PREVIEW,
    Routes.CUSTOM_ANALYTICS,
    Routes.LOOKER_ANALYTICS,
    Routes.PREDICT_INTENTS,
    Routes.SOLVE_INSIGHTS_MAIN,
    Routes.LOG,
    Routes.SOLVE_API_CONFIGURATION,
    Routes.AGENT_INSIGHTS,
  ];

  return (
    (userLocation.length > 0 && userLocation === Routes.SEARCH) ||
    routes.includes(userLocation)
  );
};

export const forethoughtSupportWidget = (
  role: string | null,
  userLocation: string,
) => {
  const ftScript = document.getElementById('forethought-widget-embed-script');

  if (
    role === 'ROLE_SUPER_ADMIN' &&
    ftScript === null &&
    !userLocation.startsWith('/workflow-builder-preview')
  ) {
    const forethoughtScript = document.createElement('script');
    forethoughtScript.id = 'forethought-widget-embed-script';
    forethoughtScript.setAttribute(
      'src',
      'https://solve-widget.forethought.ai/embed.js',
    );
    forethoughtScript.setAttribute(
      'data-api-key',
      'ffd5cdf9-0ca9-4e8d-b2ca-36500d0197d2',
    );
    forethoughtScript.setAttribute('data-ft-User-Type', 'Admin');
    document.body.appendChild(forethoughtScript);
  }
};

export const rmForethoughtSupportWidget = () => {
  const ftIframe = document.getElementById('forethought-chat');
  const ftScript = document.getElementById('forethought-widget-embed-script');
  const userLocation = location.pathname;
  if (userLocation.startsWith('/workflow-builder-preview')) {
    ftScript?.remove();
    ftIframe?.remove();
  }
};

export const safeConvertToNumber = (str: string | null) => {
  if (str === null) {
    return null;
  }

  const parsedNumber = Number(str);

  if (isNaN(parsedNumber)) {
    return null;
  }

  return parsedNumber;
};

export const getStoredSalesforceContactEmail = (): string | null => {
  return localStorage.getItem(SALESFORCE_CONTACT_EMAIL_KEY);
};

export const setStoredSalesforceContactEmail = (email: string): void => {
  localStorage.setItem(SALESFORCE_CONTACT_EMAIL_KEY, email);
};

export const processAuthenticatedUser = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any,
  userEmail: string,
) => {
  if (
    getUserRole() == UserRoles.ROLE_SUPER_ADMIN ||
    getStoredSalesforceContactEmail() === userEmail
  ) {
    return;
  }

  createSalesforceContact(
    user?.attributes?.given_name,
    user?.attributes?.family_name,
  ).then(response => {
    if (response?.success) {
      setStoredSalesforceContactEmail(userEmail);
    }
  });
};
