import { useSelector } from 'react-redux';

import { Toggle } from '@forethought-technologies/forethought-elements';
import { IntentDataWithTopics } from '../intent-workflows-table/types';
import { setIsInfoBannerVisible } from 'src/actions/workflow-builder/workflowBuilderActions';
import StatusIndicator from 'src/components/status-indicator';
import { useIsWorkflowUsedByWidgetConfiguration } from 'src/hooks/hooks';
import { useGetHasOtherQuestions } from 'src/hooks/solve';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getIntentAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import { useAppDispatch } from 'src/store/hooks';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';
import { minimumPhrasesRequired } from 'src/utils/constants';
import { CommonIntentWorkflowType } from 'src/utils/enums';
import {
  getWorkflowActivationCopy,
  isIntentActiveForChannel,
} from 'src/utils/solve/intentUtils';

interface IntentWorkflowToggleProps {
  channel: TranslationChannel;
  disabled?: boolean;
  intentData: IntentDataWithTopics;
  setSelectedWorkflowId: (workflowId: string) => void;
  showMinPhrasesDialog?: (intentId: string) => void;
  tooltipText?: string;
}

/**
 * Given a channel and intent data, show confirmation dialog and handle workflow activation for channel
 */
export default function IntentWorkflowToggle({
  channel,
  disabled,
  intentData,
  setSelectedWorkflowId,
  showMinPhrasesDialog,
  tooltipText = '',
}: IntentWorkflowToggleProps) {
  const { intent_definition_id: intentDefinitionId, intent_name: intentName } =
    intentData;
  const dispatch = useAppDispatch();

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const { data: handoffConfigurationsResponse } =
    useGetHandoffConfigurationsQuery();

  const hasOtherQuestions = useGetHasOtherQuestions();

  const handoffConfigurations =
    handoffConfigurationsResponse?.configurations ?? [];
  const isGeneralHandoffWorkflow = intentDefinitionId === 'general-handoff';
  const solveEmailOtherQuestionsEnabled = featureFlags.includes(
    'solve_email_other_questions_enabled',
  );
  const isHandoffRevampEnabled = featureFlags.includes('handoff_revamp');
  const isEmailChannel = channel == 'email';

  const canToggleGeneralHandoffForEmail =
    isGeneralHandoffWorkflow &&
    solveEmailOtherQuestionsEnabled &&
    isEmailChannel;

  const isWorkflowUsedByWidgetConfig = useIsWorkflowUsedByWidgetConfiguration();
  const isActive =
    isIntentActiveForChannel(intentData.active_workflow_types, channel) ||
    isWorkflowUsedByWidgetConfig(intentData.intent_workflow_id);

  const userCanActivateWorkflow = useSelector(
    selectUserCan('activate_workflow'),
  );

  const isKnowledgeArticle =
    intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isFallback = intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

  const { label } = getWorkflowActivationCopy({
    channel,
    hasOtherQuestions,
    intentName,
    isActive,
    isKnowledgeArticle,
  });

  if (
    (intentData.intent_definition_id ===
      CommonIntentWorkflowType.GENERAL_HANDOFF &&
      (channel === 'widget' || channel === 'api')) ||
    intentData.is_handoff ||
    isFallback ||
    channel === 'interactive_email'
  ) {
    return (
      <StatusIndicator
        height={8}
        label={isActive ? 'On' : 'Off'}
        status={isActive ? 'active' : 'idle'}
        width={8}
      />
    );
  }

  return (
    <>
      <Toggle
        ariaLabel={label}
        checked={isActive}
        disabled={!userCanActivateWorkflow || disabled}
        onChange={async () => {
          if (isGeneralHandoffWorkflow && !canToggleGeneralHandoffForEmail) {
            dispatch(setIsInfoBannerVisible(true));
          } else if (!isActive && !canToggleGeneralHandoffForEmail) {
            const intent = await getIntentAPI(intentDefinitionId);
            const isDefaultHandoff = Object.values(
              CommonIntentWorkflowType,
            ).includes(intent.intent_id as CommonIntentWorkflowType);

            let isHandoff = false;
            if (isHandoffRevampEnabled) {
              isHandoff = handoffConfigurations.some(
                configuration =>
                  configuration.intent_definition_id === intent.intent_id,
              );
            }

            if (
              !isDefaultHandoff &&
              !isHandoff &&
              intent.phrases.length < minimumPhrasesRequired
            ) {
              return showMinPhrasesDialog?.(intentDefinitionId);
            }
            setSelectedWorkflowId(intentData.intent_workflow_id);
          } else {
            setSelectedWorkflowId(intentData.intent_workflow_id);
          }
        }}
        tooltipText={tooltipText || label}
      />
    </>
  );
}
