import { useEffect, useState } from 'react';

import CollectionsPage from './CollectionsPage';
import ConnectionsPage from './ConnectionsPage';
import IntegrationsPage from './IntegrationsPage';
import DashboardLayout from 'src/components/dashboard-layout/DashboardLayout';
import DashboardTabs from 'src/components/dashboard-tabs';
import * as API from 'src/services/apiV1';

const IntegrationsTabPage = () => {
  const [multiConnectorsEnabled, setMultiConnectorsEnabled] = useState(false);

  useEffect(() => {
    const fetchMultiConnectorsStatus = async () => {
      try {
        const enabled = await API.getIsMultiConnectorsEnabled();
        setMultiConnectorsEnabled(enabled);
      } catch (error) {
        console.error('Error fetching multi-connectors status:', error);
      }
    };

    fetchMultiConnectorsStatus();
  }, []);

  const tabs = [
    {
      Component: <ConnectionsPage multiConnectorsEnabled />,
      label: 'Connections',
    },
    {
      Component: <CollectionsPage multiConnectorsEnabled />,
      label: 'Collections',
    },
  ];

  return (
    <DashboardLayout>
      {multiConnectorsEnabled ? (
        <DashboardTabs
          name='integrations'
          tabParamsName='integrations-tab'
          tabs={tabs}
        />
      ) : (
        <IntegrationsPage multiConnectorsEnabled={false} />
      )}
    </DashboardLayout>
  );
};

export default IntegrationsTabPage;
