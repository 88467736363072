import Box from '@mui/material/Box';

import {
  AutomatedButton,
  AutomationCallToActionButton,
} from '../components/AutomatedCallToActionRenderer';
import { DateRange } from 'src/components/dashboard-pages/discover-dashboard-page/types';
import DiscoverMenuButton from 'src/components/discover-menu-button/DiscoverMenuButton';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { DiscoverBodyFilter } from 'src/services/discover/types';

type CallToActionButtonProps = {
  backendFriendlyFilterQuery: DiscoverBodyFilter;
  dateRange: DateRange;
  isParentTopic: boolean;
  keywordSearch?: string;
  topic: DiscoverTopic | null;
  trackingEventMetadata: Record<string, string | string[]>;
};

const CallToActionButton = ({
  backendFriendlyFilterQuery,
  dateRange,
  isParentTopic,
  keywordSearch,
  topic,
  trackingEventMetadata,
}: CallToActionButtonProps) => {
  if (!topic) {
    return null;
  }
  if (isParentTopic) {
    return (
      <DiscoverMenuButton
        backendFriendlyFilterQuery={backendFriendlyFilterQuery}
        dateRange={dateRange}
        isParentTopic={isParentTopic}
        keywordSearch={keywordSearch}
        topic={topic}
        variant='all_topics'
      />
    );
  }
  const showAutomatedButton = topic?.is_automated;
  const showAutomateButton =
    !showAutomatedButton && Boolean(topic?.summaries?.length);

  return (
    <Box alignItems='center' display='flex'>
      {showAutomatedButton && (
        <AutomatedButton
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
      {showAutomateButton && (
        <AutomationCallToActionButton
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
      <DiscoverMenuButton
        backendFriendlyFilterQuery={backendFriendlyFilterQuery}
        dateRange={dateRange}
        keywordSearch={keywordSearch}
        topic={topic}
        variant='all_topics'
      />
    </Box>
  );
};

export default CallToActionButton;
