import { useEffect } from 'react';

import {
  Button,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  useUpdateArticleMutation,
  useVerifyArticleQuery,
} from 'src/services/discover/discoverApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface UpdateButtonProps {
  articleId: string;
  onClose: () => void;
  onUpdatingChange: (isUpdating: boolean) => void;
}

const UpdateButton = ({
  articleId,
  onClose,
  onUpdatingChange,
}: UpdateButtonProps) => {
  const dispatch = useAppDispatch();
  const {
    data: verifyStatusData,
    isFetching: verifyStatusFetching,
    isLoading: verifyStatusLoading,
    isUninitialized,
    refetch,
  } = useVerifyArticleQuery(
    {
      article_id: articleId || '',
    },
    {
      skip: !articleId,
    },
  );

  const [updateArticle, { isLoading: isUpdating }] = useUpdateArticleMutation();
  const verifyStatus = {
    can_update: verifyStatusData?.[0],
    reason: verifyStatusData?.[1],
  };

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  useEffect(() => {
    onUpdatingChange(isUpdating);
  }, [isUpdating, onUpdatingChange]);

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [isUninitialized, refetch]);

  const handleUpdate = async () => {
    emitTrackingEventCallback('discover-article-update-button-clicked');

    try {
      await updateArticle({ article_id: articleId }).unwrap();
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          subtitle: 'Your article has been updated successfully',
          title: 'Article updated successfully',
          variant: 'main',
        }),
      );
      emitTrackingEventCallback('discover-article-updated');
      onClose();
    } catch (error) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          subtitle: 'Please try again',
          title: 'Failed to update article',
          variant: 'danger',
        }),
      );
    }
  };

  const isLoading = verifyStatusLoading || verifyStatusFetching || isUpdating;
  const isDisabled = !verifyStatus?.can_update || isLoading;

  return (
    <Tooltip tooltipContent={verifyStatus?.reason || ''}>
      <Button
        disabled={isDisabled}
        isLoading={isLoading}
        onClick={handleUpdate}
        variant='secondary'
      >
        Update existing article
      </Button>
    </Tooltip>
  );
};

export default UpdateButton;
