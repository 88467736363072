import { Box, styled, useTheme } from '@mui/material';
import { IconPhone } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { LightNeonCircle } from './LightNeonCircle';

export const VoiceLHSMockContent = ({
  centerText,
  headerText,
}: {
  centerText?: string;
  headerText: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      alignItems='center'
      className='plus-jakarta-sans'
      display='flex'
      flexDirection='column'
      height='100%'
      width='100%'
    >
      <Box
        sx={{
          background: 'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
          fontStyle: 'italic',
          marginTop: '80px',
          maxWidth: '500px',
          textAlign: 'center',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        <Typography variant='font16Medium'>{headerText}</Typography>
      </Box>

      <Box
        alignItems='center'
        display='flex'
        justifyContent='center'
        position='relative'
        width='600px'
      >
        <LightNeonCircle isBlur={centerText !== undefined} />

        {centerText !== undefined && (
          <Box
            left='0'
            marginInline='auto'
            position='absolute'
            right='0'
            width='fit-content'
          >
            <TextBubble>
              <Typography variant='font18'>
                <i>&quot;{centerText}&quot;</i>
              </Typography>
            </TextBubble>
          </Box>
        )}
      </Box>

      <Box
        alignItems='center'
        bgcolor={theme.palette.colors.green[500]}
        borderRadius='50%'
        display='flex'
        height='80px'
        justifyContent='center'
        mt='24px'
        width='80px'
      >
        <IconPhone color='white' size={40} />
      </Box>
    </Box>
  );
};

const TextBubble = styled('div')`
  padding: 24px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.2);
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 40px 0px #0000001a;
  min-width: 300px;
  max-width: 600px;
  text-align: center;
`;
