import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/material';

import { TextDivider } from '../ConversationDrawer';
import { InlineData } from '../ConversationDrawer';
import { formatTimestamp } from '../helpers';
import AutosizeIframe from 'src/components/autosize-iframe/AutosizeIframe';
import Spinner from 'src/components/spinner';
import { useGetEmailTranscriptQuery } from 'src/services/solve-conversations/emailConversationsApi';
import {
  FallbackInitiatedEntry,
  FTReplyEntry,
  IncomingCommentEntry,
} from 'src/services/solve-conversations/types';

export function EmailTranscript({
  conversationId,
}: {
  conversationId: string;
}) {
  const { data, isLoading } = useGetEmailTranscriptQuery({
    conversationId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      {data?.map((entry, idx) => {
        return (
          <React.Fragment key={idx}>
            {(() => {
              switch (entry.entry_type) {
                case 'ft_reply':
                  return <FTReply entry={entry} />;
                case 'incoming_comment':
                  return <IncomingComment entry={entry} />;
                case 'fallback_initiated':
                  return <FallbackInitiated entry={entry} />;
              }
            })()}
          </React.Fragment>
        );
      })}
    </div>
  );
}

function FTReply({ entry }: { entry: FTReplyEntry }) {
  return (
    <Box display='flex' flexDirection='column' pt='16px'>
      <TextDivider size='large' text='Outgoing response' />
      <InlineData label='Timestamp' value={formatTimestamp(entry.timestamp)} />
      <AutosizeIframe padding={20} srcDoc={entry.entry_data.response_email} />
    </Box>
  );
}

function IncomingComment({ entry }: { entry: IncomingCommentEntry }) {
  return (
    <Box display='flex' flexDirection='column' pt='16px'>
      <TextDivider size='large' text='Incoming email' />
      <InlineData label='Timestamp' value={formatTimestamp(entry.timestamp)} />
      <Box
        sx={{
          img: {
            maxWidth: '100%',
          },
          padding: '16px',
        }}
      >
        <ReactMarkdown>{entry.entry_data.comment_body}</ReactMarkdown>
      </Box>
    </Box>
  );
}

function FallbackInitiated({ entry }: { entry: FallbackInitiatedEntry }) {
  return (
    <Box display='flex' flexDirection='column' pt='16px'>
      <TextDivider size='large' text='Fallback initiated' />
      <InlineData label='Timestamp' value={formatTimestamp(entry.timestamp)} />
    </Box>
  );
}
