import { DashStyleValue } from 'highcharts';

import { dashboardApi } from '../dashboard-api';
import { DEFAULT_INSIGHT_PAGE_SIZE, INSIGHTS_ROUTER_BASE } from './constants';
import {
  ArticleContentRequest,
  ArticleCsatResponse,
  ArticleDetailHeaderRequest,
  ArticleDetailResponse,
  ArticleLineGraphRequest,
  ArticlePerformanceRequest,
  ArticlePerformanceResponse,
  ArticleTableRequest,
  ArticleTableResponse,
  ArticleTopMetricRequest,
  ArticleUserFeedbackResponse,
  ChatsSavingsInsightsRequest,
  ChatTableRequest,
  ChatTableResponse,
  CommonCsatResponse,
  CommonTrendBarGraphResponse,
  ConversationDetailResponse,
  ConversationRequest,
  EmailArticleTableRequest,
  EmailArticleTableResponse,
  EmailChatsRequest,
  EmailChatTableRequest,
  EmailChatTableResponse,
  EmailNoResponseResponse,
  GeneratedArticleRequest,
  InsightArticle,
  InsightArticleContent,
  InsightChatsSavingsResponseWithComparison,
  InsightOverviewChatPerformance,
  InsightWorkflow,
  InsightWorkflowDetail,
  InsightWorkflowDetailChart,
  LineGraphLabelType,
  OverallCSATRequest,
  OverviewChatTrendGraphRequest,
  OverviewOvertimeGraphRequest,
  OverviewOvertimeGraphResponse,
  QuickFeedbackInsightsRequest,
  QuickFeedbackResponse,
  RelevanceAndEngagementInsightRequest,
  TimestampRequest,
  TimestampRequest2,
  TopicCardInsightResponse,
  TopicCardRequest,
  TopicCardWorstWorkflowResponse,
  TopicDetailHeaderRequest,
  TopicDetailResponse,
  TopicHeaderResponse,
  TopicInformationRequest,
  TopicInformationResponse,
  TopicLineGraphRequest,
  TopicsEngagementResponse,
  TopicsRelevanceResponse,
  TopicTableRequest,
  WorkflowDetailChartRequest,
  WorkflowDetailHeaderRequest,
  WorkflowTableRequest,
  WorkflowTableResponse,
  WorkflowTopMetricRequest,
} from './types';
import _omit from 'lodash/fp/omit';
import { InsightTopicTableResponse } from 'src/components/dashboard-pages/solve-insights/topics/types';
import { DiscoverArticle } from 'src/reducers/discoverReducer/types';
import { setGeneratedArticles } from 'src/slices/solve-insights/solveInsightsSlice';

const insightsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getArticle: builder.query<InsightArticleContent, ArticleContentRequest>({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/article-content',
      }),
    }),
    getArticleDetailGraph: builder.query<
      ArticleDetailResponse,
      ArticleLineGraphRequest
    >({
      query: params => ({
        params: {
          ...params,
          period: params.period.toLowerCase(),
        },
        url: INSIGHTS_ROUTER_BASE + '/article-detail-chart',
      }),
    }),
    getArticleDetailHeader: builder.query<
      InsightArticle,
      ArticleDetailHeaderRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/article-detail-header',
      }),
    }),
    getArticlePerformance: builder.query<
      ArticlePerformanceResponse,
      ArticlePerformanceRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/article-performance',
      }),
    }),
    getArticleTable: builder.query<ArticleTableResponse, ArticleTableRequest>({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.next_page !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.data = [...newItems.data];
        } else {
          currentCache.data = [...currentCache.data, ...newItems.data];
        }
      },
      query: params => ({
        body: {
          ...params,
          page_size: DEFAULT_INSIGHT_PAGE_SIZE,
        },
        method: 'POST',
        url: INSIGHTS_ROUTER_BASE + '/articles-table',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        // Remove page from cache key so we maintain the same cache when fetching next page
        return _omit(['page'], queryArgs);
      },
    }),
    getArticleUserFeedback: builder.query<
      ArticleUserFeedbackResponse,
      TimestampRequest
    >({
      query: ({ end_timestamp, start_timestamp }) => ({
        params: {
          end_timestamp,
          start_timestamp,
        },
        url: INSIGHTS_ROUTER_BASE + '/article-user-feedback',
      }),
    }),
    getChatsSavings: builder.query<
      InsightChatsSavingsResponseWithComparison,
      ChatsSavingsInsightsRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/chats-savings',
      }),
    }),
    getCommonCSAT: builder.query<CommonCsatResponse, TimestampRequest>({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/conversation-csat',
      }),
    }),
    getConversation: builder.query<
      ConversationDetailResponse,
      ConversationRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/conversation-detail',
      }),
    }),
    getConversationTable: builder.query<ChatTableResponse, ChatTableRequest>({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.next_page !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.data = [...newItems.data];
        } else {
          currentCache.data = [...currentCache.data, ...newItems.data];
        }
      },
      query: params => ({
        body: {
          ...params,
          page_size: DEFAULT_INSIGHT_PAGE_SIZE,
        },
        method: 'POST',
        url: INSIGHTS_ROUTER_BASE + '/chats-table',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        // Remove page from cache key so we maintain the same cache when fetching next page
        return _omit(['page'], queryArgs);
      },
    }),
    getCSAT: builder.query<ArticleCsatResponse, ArticleTopMetricRequest>({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/csat',
      }),
    }),
    getEmailArticleTable: builder.query<
      EmailArticleTableResponse,
      EmailArticleTableRequest
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.next_page !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.data = [...newItems.data];
        } else {
          currentCache.data = [...currentCache.data, ...newItems.data];
        }
      },
      query: params => ({
        body: {
          ...params,
          page_size: DEFAULT_INSIGHT_PAGE_SIZE,
        },
        method: 'POST',
        url: INSIGHTS_ROUTER_BASE + '/email-articles-table',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        // Remove page from cache key so we maintain the same cache when fetching next page
        return _omit(['page'], queryArgs);
      },
    }),
    getEmailChatsSavings: builder.query<
      InsightChatsSavingsResponseWithComparison,
      EmailChatsRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/email-chats-savings',
      }),
    }),
    getEmailConversationTable: builder.query<
      EmailChatTableResponse,
      EmailChatTableRequest
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.next_page !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.data = [...newItems.data];
        } else {
          currentCache.data = [...currentCache.data, ...newItems.data];
        }
      },
      query: params => ({
        body: {
          ...params,
          page_size: DEFAULT_INSIGHT_PAGE_SIZE,
        },
        method: 'POST',
        url: INSIGHTS_ROUTER_BASE + '/email-chats-table',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        // Remove page from cache key so we maintain the same cache when fetching next page
        return _omit(['page'], queryArgs);
      },
    }),
    getEmailFeedback: builder.query<QuickFeedbackResponse, EmailChatsRequest>({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/email-feedback',
      }),
    }),
    getEmailNoResponse: builder.query<
      EmailNoResponseResponse,
      EmailChatsRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/email-no-response-cause',
      }),
    }),
    getGeneratedArticles: builder.mutation<
      { articles: Array<DiscoverArticle> },
      GeneratedArticleRequest
    >({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setGeneratedArticles(data.articles));
        } catch (e) {
          dispatch(setGeneratedArticles([]));
        }
      },
      query: ({ is_parent_topic, topic_id }) => ({
        keepUnusedDataFor: 0,
        method: 'GET',
        params: {
          is_parent_topic,
          topic_id,
        },
        url: API_URL + 'ssel/discover/v1/articles/recommendations',
      }),
    }),
    getKrWorkflowTable: builder.query<
      WorkflowTableResponse,
      WorkflowTableRequest
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      query: params => ({
        body: {
          ...params,
          page_size: DEFAULT_INSIGHT_PAGE_SIZE,
        },
        method: 'POST',
        url: INSIGHTS_ROUTER_BASE + '/kr-workflows-table',
      }),
    }),
    getOverallCSAT: builder.query<CommonCsatResponse, OverallCSATRequest>({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/overall-csat',
      }),
    }),
    getOverviewChatPerformance: builder.query<
      InsightOverviewChatPerformance,
      TimestampRequest2
    >({
      query: ({ end, start }) => ({
        params: {
          end,
          start,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-header',
      }),
    }),
    getOverviewChatsChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-deflected-count-and-savings',
      }),
      transformResponse: (response: CommonTrendBarGraphResponse[]) => {
        if (response.length) {
          response.forEach(chart => {
            if (chart.type === 'overview_deflected_count_bar_chart_insight') {
              const result = { ...chart };
              result.bar.categories = ['Deflections', 'Non-deflections'];
              result.bar.values.forEach(value => {
                // TODO - Backend is returning this response incorrectly - Fix after demo
                if (typeof value.value === 'object') {
                  const valueList = value.value as unknown as number[];
                  const [def, nonDef] = valueList;
                  const deflectionObj = {
                    extended_label: 'Deflections',
                    label: 'Deflections',
                    type: 'number',
                    value: def,
                  };
                  const nonDeflectionObj = {
                    extended_label: 'Non-deflections',
                    label: 'Non-deflections',
                    type: 'number',
                    value: nonDef,
                  };
                  // eslint-disable-next-line
                  // @ts-ignore
                  value.values = [deflectionObj, nonDeflectionObj];
                }
              });
              return result;
            }
          });
        }
        return response[0];
      },
    }),
    getOverviewCsatChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-csat-bar-chart',
      }),
      transformResponse: (response: CommonTrendBarGraphResponse) => {
        const result = { ...response };
        // TODO - make this change on backend
        result.bar.categories = ['CSAT'];
        return result;
      },
    }),
    getOverviewDeflectedChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-deflected-bar-chart',
      }),
      transformResponse: (response: CommonTrendBarGraphResponse) => {
        const result = { ...response };
        // TODO - make this change on backend
        result.bar.categories = ['Deflection rate'];
        result.bar.values.forEach(value => (value.type = 'percentage'));
        return result;
      },
    }),
    getOverviewEngagementChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-engagement-chart',
      }),
    }),
    getOverviewKASurfacedChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-ka-bar-chart',
      }),
      transformResponse: (response: CommonTrendBarGraphResponse) => {
        const result = { ...response };
        // TODO - make this change on backend
        result.bar.categories = ['Knowledge article surfaced'];
        return result;
      },
    }),
    getOverviewOvertimeChart: builder.query<
      OverviewOvertimeGraphResponse,
      OverviewOvertimeGraphRequest
    >({
      query: ({ end, period, start }) => ({
        params: {
          end,
          period,
          start,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-overtime-chart',
      }),
    }),
    getOverviewQuickFeedbackChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-quick-feedback-bar-chart',
      }),
      transformResponse: (response: CommonTrendBarGraphResponse) => {
        const result = { ...response };
        // TODO - make this change on backend
        result.bar.categories = ['Positive', 'Negative', 'Not answered'];
        result.bar.values.forEach(barValue => {
          barValue?.values?.forEach(value => {
            if (value.label === 'Not answered') {
              value.hidden = true;
            }
          });
        });
        return result;
      },
    }),
    getOverviewRelevanceChart: builder.query<
      CommonTrendBarGraphResponse,
      OverviewChatTrendGraphRequest
    >({
      query: ({ period, timestamps }) => ({
        params: {
          period,
          timestamps,
        },
        url: INSIGHTS_ROUTER_BASE + '/overview-relevance-chart',
      }),
    }),
    getQuickFeedback: builder.query<
      QuickFeedbackResponse,
      QuickFeedbackInsightsRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/feedback',
      }),
      transformResponse: (response: QuickFeedbackResponse) => {
        const result = { ...response };
        // TODO - make this change on backend

        const positiveValues = result?.misc?.positive || 0;
        const negativeValues = result?.misc?.negative || 0;
        const notAnsweredValues = result?.misc?.not_answered || 0;
        const totalFeedback =
          positiveValues + negativeValues + notAnsweredValues;

        const currentBarValues = result.bar.values || [];

        result.bar.values = currentBarValues
          .filter(barValue => barValue.label !== 'Not answered')
          .map(barValue => {
            const newBarValues =
              barValue?.values?.map?.(currentBarValue => {
                if (currentBarValue.label === 'Not answered') {
                  return {
                    ...currentBarValue,
                    hidden: true,
                  };
                }
                return currentBarValue;
              }) || [];
            if ((barValue?.tooltip_values?.length || 0) > 1) {
              return {
                ...barValue,
                tooltip_values:
                  barValue?.tooltip_values?.map?.(tooltipValue => {
                    if (tooltipValue.label === 'of overall') {
                      return {
                        ...tooltipValue,
                        value:
                          Math.round(
                            (result?.misc?.[
                              barValue?.label?.toLowerCase?.() === 'positive'
                                ? 'positive'
                                : 'negative'
                            ] /
                              (totalFeedback - notAnsweredValues) || 0) * 100,
                          ) / 100,
                      };
                    }
                    return tooltipValue;
                  }) || [],
                values: newBarValues,
              };
            }
            return {
              ...barValue,
              values: newBarValues,
            };
          });

        return result;
      },
    }),
    getTopicCard: builder.query<
      TopicCardInsightResponse | TopicCardWorstWorkflowResponse,
      TopicCardRequest
    >({
      query: ({ card_type, end, start }) => ({
        params: {
          card_type,
          end,
          start,
        },
        url: INSIGHTS_ROUTER_BASE + '/topics-card',
      }),
    }),
    getTopicDetailGraph: builder.query<
      TopicDetailResponse,
      TopicLineGraphRequest
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        if (_arg.is_parent_topic) {
          const promises = _arg.subTopics.map(topicId =>
            fetchWithBQ(
              `${INSIGHTS_ROUTER_BASE}/topic-detail-chart?end=${
                _arg.end
              }&start=${
                _arg.start
              }&is_parent_topic=false&period=${_arg.period.toLowerCase()}&topic_id=${topicId}`,
            ),
          );
          // Handle transformations
          const promiseResults = (await Promise.all(promises)).map(
            data => data.data,
          ) as TopicDetailResponse[];
          const values: {
            dashStyle: DashStyleValue;
            label: LineGraphLabelType;
            topicId: string;
            value: (number | null)[];
          }[] = [];
          promiseResults.forEach((result, idx) =>
            result.line.values.forEach(value => {
              if (
                value.label === 'Chats' ||
                value.label === 'Non-deflections'
              ) {
                values.push({
                  ...value,
                  dashStyle: value.label === 'Chats' ? 'LongDash' : 'Solid',
                  topicId: _arg.subTopics[idx],
                });
              }
            }),
          );

          const result = { ...promiseResults[0] };
          result.line.values = values;

          return { data: result };
        }
        // Is subtopic
        const result = await fetchWithBQ(
          `${INSIGHTS_ROUTER_BASE}/topic-detail-chart?end=${_arg.end}&start=${
            _arg.start
          }&is_parent_topic=${
            _arg.is_parent_topic
          }&period=${_arg.period.toLowerCase()}&topic_id=${_arg.topic_id}`,
        );
        return { data: result.data as TopicDetailResponse };
      },
    }),
    getTopicHeader: builder.query<
      TopicHeaderResponse,
      TopicDetailHeaderRequest
    >({
      query: params => ({
        params: {
          is_parent_topic: params.is_parent_topic,
          topic_id: params.topic_id,
        },
        url: `${INSIGHTS_ROUTER_BASE}${
          params.scope === 'main'
            ? '/topics-main-page-header'
            : '/topic-detail-page-header'
        }`,
      }),
    }),
    getTopicInformation: builder.query<
      TopicInformationResponse,
      TopicInformationRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/topic-information',
      }),
    }),
    getTopicsEngagement: builder.query<
      TopicsEngagementResponse,
      RelevanceAndEngagementInsightRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/engagement',
      }),
    }),
    getTopicsRelevance: builder.query<
      TopicsRelevanceResponse,
      RelevanceAndEngagementInsightRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/relevance',
      }),
    }),
    getTopicsTable: builder.query<InsightTopicTableResponse, TopicTableRequest>(
      {
        forceRefetch({ currentArg, previousArg }) {
          return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
        },
        providesTags: ['Automations'],
        query: params => ({
          body: {
            ...params,
          },
          method: 'POST',
          url: INSIGHTS_ROUTER_BASE + '/topics-table',
        }),
      },
    ),
    getWorkflowDetailChart: builder.query<
      InsightWorkflowDetailChart,
      WorkflowDetailChartRequest
    >({
      query: ({ end, period, start, workflow_id }) => ({
        params: {
          end,
          period,
          start,
          workflow_id,
        },
        url: INSIGHTS_ROUTER_BASE + '/workflow-detail-chart',
      }),
    }),
    getWorkflowDetailHeader: builder.query<
      InsightWorkflowDetail,
      WorkflowDetailHeaderRequest
    >({
      query: ({ end, start, workflow_id }) => ({
        params: {
          end,
          start,
          workflow_id,
        },
        url: INSIGHTS_ROUTER_BASE + '/workflow-detail-header',
      }),
    }),
    getWorkflowOverallCSAT: builder.query<
      CommonCsatResponse,
      WorkflowTopMetricRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/workflow-overall-csat',
      }),
    }),
    getWorkflowOverallDeflections: builder.query<
      InsightChatsSavingsResponseWithComparison,
      WorkflowTopMetricRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/workflow-overall-deflections',
      }),
    }),
    getWorkflowOverallEngagement: builder.query<
      TopicsEngagementResponse,
      WorkflowTopMetricRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/workflow-overall-engagement',
      }),
    }),
    getWorkflowOverallRelevance: builder.query<
      TopicsRelevanceResponse,
      WorkflowTopMetricRequest
    >({
      query: params => ({
        params,
        url: INSIGHTS_ROUTER_BASE + '/workflow-overall-relevance',
      }),
    }),
    getWorkflowTable: builder.query<
      WorkflowTableResponse,
      WorkflowTableRequest
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.next_page !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.data = [...newItems.data];
        } else {
          currentCache.data = [...currentCache.data, ...newItems.data];
        }
      },
      query: params => ({
        body: {
          ...params,
          page_size: DEFAULT_INSIGHT_PAGE_SIZE,
        },
        method: 'POST',
        url: INSIGHTS_ROUTER_BASE + '/workflows-table',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        // Remove page from cache key so we maintain the same cache when fetching next page
        return _omit(['page'], queryArgs);
      },
      transformResponse: (response: WorkflowTableResponse) => {
        // Add placeholder for KR which comes from a different endpoint
        const data: InsightWorkflow[] = [
          ...response.data,
          {
            avg_csat: null,
            channel: 'n/a',
            chats: 0,
            cost_per_ticket: 0,
            deflected_count: 0,
            dropoff_breakdown: {
              dropoff_count: 0,
              no_dropoff_count: 0,
            },
            engagement_rate: 0,
            feedback_breakdown: {
              negative: 0,
              positive: 0,
              unanswered: 0,
            },
            intent_definition_id: 'Knowledge_Article',
            intent_title: 'Knowledge Retrieval',
            intent_workflow_id: '',
            is_active: false,
            is_autoflow: false,
            is_handoff: false,
            is_loading: true,
            non_deflected_count: 0,
            potential_savings: 0,
            realized_savings: 0,
            relevance_breakdown: {
              irrelevant_count: 0,
              relevant_count: 0,
              somewhat_relevant_count: 0,
            },
            surfaced_articles: [],
          },
        ];
        response.data = data;
        return response;
      },
    }),
  }),
});

export const {
  useGetArticleDetailGraphQuery,
  useGetArticleDetailHeaderQuery,
  useGetArticlePerformanceQuery,
  useGetArticleQuery,
  useGetArticleTableQuery,
  useGetArticleUserFeedbackQuery,
  useGetChatsSavingsQuery,
  useGetCommonCSATQuery,
  useGetConversationQuery,
  useGetConversationTableQuery,
  useGetCSATQuery,
  useGetEmailArticleTableQuery,
  useGetEmailChatsSavingsQuery,
  useGetEmailConversationTableQuery,
  useGetEmailFeedbackQuery,
  useGetEmailNoResponseQuery,
  useGetGeneratedArticlesMutation,
  useGetKrWorkflowTableQuery,
  useGetOverallCSATQuery,
  useGetOverviewChatPerformanceQuery,
  useGetOverviewChatsChartQuery,
  useGetOverviewCsatChartQuery,
  useGetOverviewDeflectedChartQuery,
  useGetOverviewEngagementChartQuery,
  useGetOverviewKASurfacedChartQuery,
  useGetOverviewOvertimeChartQuery,
  useGetOverviewQuickFeedbackChartQuery,
  useGetOverviewRelevanceChartQuery,
  useGetQuickFeedbackQuery,
  useGetTopicCardQuery,
  useGetTopicDetailGraphQuery,
  useGetTopicHeaderQuery,
  useGetTopicInformationQuery,
  useGetTopicsEngagementQuery,
  useGetTopicsRelevanceQuery,
  useGetTopicsTableQuery,
  useGetWorkflowDetailChartQuery,
  useGetWorkflowDetailHeaderQuery,
  useGetWorkflowOverallCSATQuery,
  useGetWorkflowOverallDeflectionsQuery,
  useGetWorkflowOverallEngagementQuery,
  useGetWorkflowOverallRelevanceQuery,
  useGetWorkflowTableQuery,
} = insightsApi;
