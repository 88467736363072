import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  elevations,
  Typography,
} from '@forethought-technologies/forethought-elements';
import RightSidebar, { RightSidebarProps } from './RightSidebar';
interface TabLayoutProps<T> extends RightSidebarProps<T> {
  configWidget: React.ReactNode;
  title: string;
}

const TabLayout = <T extends string>({
  children,
  configWidget,
  navigationItems,
  onTabChange,
  selectedTab,
  title,
}: PropsWithChildren<TabLayoutProps<T>>) => {
  const { palette } = useTheme();

  return (
    <Box
      bgcolor='white'
      display='grid'
      gridTemplateColumns='auto 480px 80px'
      width='100%'
    >
      <Box
        alignItems='center'
        bgcolor={palette.colors.slate[100]}
        display='flex'
        justifyContent='center'
        sx={{ overflowY: 'auto' }}
      >
        {children}
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        gap={3}
        p={3}
        sx={{
          borderRight: `1px solid ${palette.colors.slate[200]}`,
          boxShadow: elevations.z2,
          overflowY: 'auto',
        }}
      >
        <Typography variant='font24'>{title}</Typography>
        {configWidget}
      </Box>
      <RightSidebar
        navigationItems={navigationItems}
        onTabChange={onTabChange}
        selectedTab={selectedTab}
      />
    </Box>
  );
};

export default TabLayout;
