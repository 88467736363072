import { useCallback, useEffect, useMemo, useState } from 'react';
import { JSONSchema7 } from 'json-schema';
import { useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { FormProps } from '@rjsf/core';

import { theme } from '@forethought-technologies/forethought-elements';
import {
  selectConnectors,
  setIsEditing,
} from '../../../../../slices/connectors/connectorsSlice';
import { updateConnector } from '../../../../../slices/connectors/thunks';
import DrawerSection from '../../DrawerSection';
import useHandleConnectOAuthSetupType from '../create-integration-form/hooks/useHandleConnectOAuthSetupType';
import FormButtons from '../FormButtons';
import { useSaveDisplayName } from '../hooks/useSaveDisplayName';
import IntegrationForm from '../IntegrationForm';
import { Connector, Credentials } from 'src/services/apiInterfaces';

export interface UpdateIntegrationFormProps extends FormProps<Credentials> {
  existingNames?: string[];
  fieldsSchema: JSONSchema7 | null;
  formFieldsData: Credentials | null;
  multiConnectorsEnabled: boolean;
  selectedConnector: Connector;
}

export default function UpdateIntegrationForm({
  existingNames,
  fieldsSchema,
  formData,
  formFieldsData,
  multiConnectorsEnabled,
  selectedConnector,
  ...rest
}: UpdateIntegrationFormProps) {
  const [editableFormData, setEditableFormData] = useState(formData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isDisplayNameInvalid, setIsDisplayNameInvalid] = useState(false);

  const dispatch = useDispatch();
  const { isEditing, isEditingSettings, isUpdateLoading } =
    useSelector(selectConnectors);
  const { setup_type } = selectedConnector.connector_definition;
  const [editableFieldsData, setEditableFieldsData] = useState(
    formFieldsData || undefined,
  );

  const {
    handleConnectOAuthSetupType,
    handleConnectOAuthSetupTypeForConnector,
  } = useHandleConnectOAuthSetupType({
    connectorDefinition: selectedConnector.connector_definition,
    multiConnectorsEnabled,
  });

  const {
    currentDisplayName,
    handleSaveDisplayName,
    resetDisplayName,
    setCurrentDisplayName,
  } = useSaveDisplayName({
    connectorId: selectedConnector.connector_id,
    initialDisplayName: selectedConnector.display_name || '',
  });

  useEffect(() => {
    setEditableFormData(formData);
  }, [formData]);

  useBeforeunload(e => {
    if (isEditing) {
      e.preventDefault();
    }
  });

  const handleUpdateConnectorFields = useCallback(async () => {
    await handleSaveDisplayName();

    const fieldsChanged =
      JSON.stringify(editableFieldsData) !== JSON.stringify(formFieldsData);

    if (fieldsChanged) {
      dispatch(
        updateConnector({
          connectorId: selectedConnector.connector_id,
          updateConnectorRequest: {
            connector_fields: editableFieldsData,
            connector_types: selectedConnector.connector_types,
          },
        }),
      );
    }
  }, [
    editableFieldsData,
    formFieldsData,
    dispatch,
    selectedConnector.connector_id,
    selectedConnector.connector_types,
    handleSaveDisplayName,
  ]);
  const fieldsChanged = useMemo(() => {
    return JSON.stringify(editableFormData) !== JSON.stringify(formData);
  }, [editableFormData, formData]);

  const handleUpdateConnector = useCallback(async () => {
    await handleSaveDisplayName();

    const fieldsChanged =
      JSON.stringify(editableFormData) !== JSON.stringify(formData);

    if (fieldsChanged) {
      dispatch(
        updateConnector({
          connectorId: selectedConnector.connector_id,
          updateConnectorRequest: {
            connector_fields: editableFormData,
            connector_types: selectedConnector.connector_types,
          },
        }),
      );
    }

    dispatch(setIsEditing(false));
  }, [
    dispatch,
    editableFormData,
    formData,
    handleSaveDisplayName,
    selectedConnector.connector_id,
    selectedConnector.connector_types,
  ]);

  const handleConnect = async ({ formData }: { formData?: Credentials }) => {
    if (formData === undefined) {
      return;
    }

    // For OAuth connectors, restart the auth flow
    if (setup_type === 'OAUTH') {
      await handleSaveDisplayName();
      if (multiConnectorsEnabled) {
        // Restart Auth Flow
        handleConnectOAuthSetupTypeForConnector(
          selectedConnector.connector_id,
          formData,
          currentDisplayName,
        );
      } else {
        handleConnectOAuthSetupType(
          {
            ...formData,
          },
          currentDisplayName,
        );
      }
    } else if (setup_type === 'FORM') {
      // Handle form-based connector update
      handleUpdateConnector();
    }
  };

  return (
    <>
      {selectedConnector.connector_definition.setup_instructions && (
        <DrawerSection title='Setup Instructions'>
          <SetupInstructions
            dangerouslySetInnerHTML={{
              __html: selectedConnector.connector_definition.setup_instructions,
            }}
          />
        </DrawerSection>
      )}
      {fieldsSchema && (
        <IntegrationForm
          {...rest}
          disabled={!isEditingSettings || isUpdateLoading}
          disableDisplayName={!isEditingSettings}
          displayName={currentDisplayName}
          existingNames={existingNames}
          formButtons={
            <FormButtons
              formData={formFieldsData || undefined}
              handleUpdateConnector={handleUpdateConnectorFields}
              isDisplayNameInvalid={isDisplayNameInvalid}
              isFormValid={isFormValid}
              multiConnectorsEnabled={multiConnectorsEnabled}
              resetDisplayName={resetDisplayName}
              selectedConnector={selectedConnector}
              setEditableFormData={setEditableFieldsData}
              setup_type={setup_type}
              showEditSettingsButton
            />
          }
          formData={editableFieldsData || undefined}
          isCreate={false}
          multiConnectorsEnabled={multiConnectorsEnabled}
          onChange={e => setEditableFieldsData(e.formData)}
          onDisplayNameChange={setCurrentDisplayName}
          onSaveDisplayName={handleSaveDisplayName}
          onSubmit={handleConnect}
          requiresAuthentication={false}
          resetDisplayName={resetDisplayName}
          schema={fieldsSchema}
          setIsDisplayNameInvalid={setIsDisplayNameInvalid}
          setIsFormValid={setIsFormValid}
        />
      )}

      <IntegrationForm
        {...rest}
        disabled={setup_type !== 'OAUTH' && (!isEditing || isUpdateLoading)}
        disableDisplayName={setup_type !== 'OAUTH' && !isEditing}
        displayName={currentDisplayName}
        existingNames={existingNames}
        formButtons={
          <FormButtons
            formData={formData}
            handleUpdateConnector={handleUpdateConnector}
            isDisplayNameInvalid={isDisplayNameInvalid}
            isFormValid={isFormValid}
            multiConnectorsEnabled={multiConnectorsEnabled}
            resetDisplayName={resetDisplayName}
            selectedConnector={selectedConnector}
            setEditableFormData={setEditableFormData}
            setup_type={setup_type}
          />
        }
        formData={editableFormData}
        formDescription={
          setup_type === 'OAUTH' && fieldsChanged
            ? 'To apply changes, you must re-authenticate.'
            : undefined
        }
        formTitle={
          setup_type === 'OAUTH' ? 'Authentication Settings' : undefined
        }
        isCreate={false}
        multiConnectorsEnabled={multiConnectorsEnabled}
        onChange={e => setEditableFormData(e.formData)}
        onDisplayNameChange={setCurrentDisplayName}
        onSaveDisplayName={handleSaveDisplayName}
        onSubmit={handleConnect}
        requiresAuthentication={setup_type === 'OAUTH'}
        resetDisplayName={resetDisplayName}
        setIsDisplayNameInvalid={setIsDisplayNameInvalid}
        setIsFormValid={setIsFormValid}
      />
    </>
  );
}
const SetupInstructions = styled('div')`
  color: ${theme.palette.grey[700]};
  font-size: 14px;
  line-height: 24px;

  ol,
  ul {
    padding-inline-start: 16px;
  }
`;
