import React from 'react';
import { Box, styled } from '@mui/material';

import { InsightsTab, ProductMode } from '../types';
import { ChatsSavingsCard } from './ChatsSavingsCard';
import { EmailNoResponseCard } from './EmailNoResponseCard';
import FeedbackCard from './FeedbackCard';
import { DateRange } from 'src/types/types';

export interface EmailOverallCardsProps {
  dateRange: DateRange;
  productMode: ProductMode;
  tab: InsightsTab;
}

const EmailOverallCards: React.FC<
  React.PropsWithChildren<EmailOverallCardsProps>
> = ({ dateRange, productMode, tab }) => {
  return (
    <Box
      display='flex'
      gap={3}
      justifyContent='space-between'
      minHeight='280px'
      pb={1}
      sx={{
        overflowX: 'scroll',
      }}
    >
      <ChatsSavingsCard
        dateRange={dateRange}
        productMode={productMode}
        tab={tab}
      />
      <FeedbackCard dateRange={dateRange} productMode={productMode} tab={tab} />
      <EmailNoResponseCard dateRange={dateRange} tab={tab} />
    </Box>
  );
};

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  width: 50%;
  padding: 24px;
  gap: 24px;
`;

export default EmailOverallCards;
