import { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { Box } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import {
  emptyOperatorOptions,
  operatorOptions,
  operatorOptionsForAvailableValues,
} from '../../../customization-panel/article-suggestion-settings-panel/constants';
import { RemoveIconContainer } from '../../../customization-panel/article-suggestion-settings-panel/FilterQuery';
import { convertInputToCorrectType } from '../utils';
import removeIcon from 'src/assets/images/close.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';
import {
  ArticleField,
  ArticleSuggestionFilterQuery,
} from 'src/types/workflowBuilderAPITypes';

interface IntentEmailFilterQueryProps {
  fields?: ArticleField[];
  filterQuery: ArticleSuggestionFilterQuery;
  index: number;
  onDeleteFilterQuery: () => void;
  onUpdateBooleanOperator: (booleanOperator: string) => void;
  onUpdateFilterQuery: (filterQuery: ArticleSuggestionFilterQuery) => void;
}

const IntentEmailFilterQuery = ({
  fields,
  filterQuery,
  index,
  onDeleteFilterQuery,
  onUpdateBooleanOperator,
  onUpdateFilterQuery,
}: IntentEmailFilterQueryProps) => {
  const { boolean_operator, field, operator, value } = filterQuery;

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const filterFieldOptions = useMemo(
    () =>
      fields?.map(field => {
        return {
          label: field.field_name,
          value: field.field_name,
        };
      }),
    [fields],
  );

  const availableValues = useMemo(
    () =>
      fields?.find(filterField => filterField.field_name === field)
        ?.available_field_values || [],
    [fields, field],
  );

  const fieldType = useMemo(
    () =>
      fields?.find(filterField => filterField.field_name === field)
        ?.field_type || '',
    [fields, field],
  );

  const formattedAvailableFilterValues = useMemo(
    () =>
      availableValues.map(value => ({
        category: fieldType,
        label: value.name,
        value: value.value.toString(),
      })) || [],
    [availableValues, fieldType],
  );

  const isStringField =
    fields?.find(field => field.field_name === filterQuery.field)
      ?.field_type === 'text';

  return (
    <Box>
      {index > 0 && (
        <Box margin='24px 0' width='calc(100% - 26px)'>
          <SelectDropdown
            disabled={index > 1}
            id='filter-query-boolean-operator-selector'
            onChange={e => onUpdateBooleanOperator(e.target.value)}
            options={[
              {
                label: 'And',
                value: 'and',
              },
              {
                label: 'Or',
                value: 'or',
              },
            ]}
            value={boolean_operator || ''}
          />
        </Box>
      )}
      <Box display='flex' flexDirection='column' gap='8px'>
        <Box display='flex'>
          <SelectDropdown
            error={!field}
            id='filter-query-field-selector'
            onChange={e =>
              onUpdateFilterQuery({
                ...filterQuery,
                field: e.target.value,
                value: { name: '', value: '' },
              })
            }
            options={filterFieldOptions || []}
            placeholder='Choose an article field'
            value={field || ''}
          />
          <RemoveIconContainer onClick={() => onDeleteFilterQuery()}>
            <ReactSVG src={removeIcon} />
          </RemoveIconContainer>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gap='8px'
          marginBottom='24px'
          width={'calc(100% - 26px)'}
        >
          <SelectDropdown
            id='filter-query-operator-selector'
            onChange={e =>
              onUpdateFilterQuery({
                ...filterQuery,
                operator: e.target.value,
                ...(['is empty', 'is not empty'].includes(e.target.value)
                  ? { value: null }
                  : {}),
              })
            }
            options={[
              ...(availableValues.length
                ? operatorOptions
                : operatorOptionsForAvailableValues),
              ...(isStringField ? emptyOperatorOptions : []),
            ]}
            value={operator}
          />
          {!['is empty', 'is not empty'].includes(filterQuery.operator) &&
            (availableValues.length ? (
              <ContextVariableSelectDropdown
                additionalOptions={formattedAvailableFilterValues}
                aria-label='Filter value'
                error={!value || value.value === ''}
                id='filter-query-value-selector'
                label={undefined}
                onChange={value => {
                  const selectedOption = formattedAvailableFilterValues.find(
                    item => item.value === value,
                  );

                  const selectedCv = contextVariables.find(
                    cv => `{{${cv.context_variable_id}}}` === value,
                  );

                  const formattedValue = {
                    name:
                      selectedOption?.label ||
                      selectedCv?.context_variable_name ||
                      '',
                    // if fieldType is not available, then we need to infer and convert the value to the correct type
                    value: fieldType ? value : convertInputToCorrectType(value),
                  };

                  onUpdateFilterQuery({
                    ...filterQuery,
                    value: formattedValue,
                  });
                }}
                placeholder='Select filter value'
                shouldProvideCVIdFormatting
                value={value?.value.toString() || ''}
              />
            ) : (
              <Box height='40px'>
                <ContextMention
                  aria-label='Insert value'
                  disablePortal
                  hasError={!value?.value}
                  onChange={value =>
                    onUpdateFilterQuery({
                      ...filterQuery,
                      value: {
                        name: '',
                        value,
                      },
                    })
                  }
                  placeholder='Enter filter value'
                  value={String((value && value.value) || '')}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default IntentEmailFilterQuery;
