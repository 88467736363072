import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { KnowledgeConfigurationPromptTextField } from 'src/components/knowledge-configuration-prompt-text-field';
import { Routes } from 'src/utils/enums';

interface KnowledgeConfigurationFieldsFieldsProps {
  error?: string | false;
  isEmailChannel?: boolean;
  knowledgeConfigurationPrompt: string;
  onChangeKnowledgeConfigurationPrompt: (value: string) => void;
  onToggleShouldOverrideGlobal: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  shouldOverrideGlobal: boolean;
}

export const KnowledgeConfigurationFields = ({
  error,
  isEmailChannel = false,
  knowledgeConfigurationPrompt,
  onChangeKnowledgeConfigurationPrompt,
  onToggleShouldOverrideGlobal,
  shouldOverrideGlobal,
}: KnowledgeConfigurationFieldsFieldsProps) => {
  const { palette } = useTheme();
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={2}
      sx={{ a: { textDecoration: 'none' } }}
    >
      <Toggle
        checked={shouldOverrideGlobal}
        label={
          <Box alignItems='center' display='inline-flex'>
            Instructional note
          </Box>
        }
        onChange={onToggleShouldOverrideGlobal}
      />
      <Typography color={palette.grey[700]} variant='font14Medium'>
        Enable this option to override the{' '}
        <Link
          to={{
            pathname: Routes.SOLVE_CONFIGURATION,
            search: isEmailChannel
              ? 'channel=email&tab=instruction'
              : 'tab=knowledge',
          }}
        >
          default configuration value
        </Link>{' '}
        and provide a summary of key points to guide{' '}
        {isEmailChannel ? 'Solve Email' : 'the bot'} in generating more
        personalized {isEmailChannel ? 'responses' : 'conversations'} .
      </Typography>
      {shouldOverrideGlobal && (
        <KnowledgeConfigurationPromptTextField
          error={error}
          onChange={onChangeKnowledgeConfigurationPrompt}
          shouldAddContextVariables
          value={knowledgeConfigurationPrompt}
        />
      )}
    </Box>
  );
};
