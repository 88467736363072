import { useSelector } from 'react-redux';

import { VoiceLHSMockContent } from '../VoiceMockContent';
import { selectVoiceConfiguration } from 'src/slices/solve-config/solveConfigSlice';

export const RecordingTabMockContent = () => {
  const voiceConfiguration = useSelector(selectVoiceConfiguration);

  const {
    disclaimer_text: disclaimerText,
    is_disclaimer_enabled: isDisclaimerEnabled,
  } = voiceConfiguration;

  return (
    <VoiceLHSMockContent
      centerText={isDisclaimerEnabled ? disclaimerText : undefined}
      headerText='To comply with legal requirements, we inform callers upfront that their call will be recorded. This helps ensure transparency and keeps everyone informed right from the start.'
    />
  );
};
