import { FormProps, withTheme } from '@rjsf/core';
import { Theme } from '@rjsf/mui';

import CheckboxWidget from './widgets/CheckboxWidget';
import SelectWidget from './widgets/SelectWidget';
import TextWidget from './widgets/TextWidget';
import TitleField from './widgets/TitleField';
import ArrayFieldDescriptionTemplate from './ArrayFieldDescriptionTemplate';
import ArrayFieldTemplate from './ArrayFieldTemplate';
import DescriptionFieldTemplate from './DescriptionFieldTemplate';
import TitleFieldTemplate from './TitleFieldTemplate';

const Form = withTheme({
  ...Theme,
  fields: {
    ...Theme.fields,
    TitleField: TitleField,
  },
  templates: {
    ...Theme.templates,
    ArrayFieldDescriptionTemplate,
    ArrayFieldTemplate,
    DescriptionFieldTemplate,
    TitleFieldTemplate,
  },
  widgets: {
    ...Theme.widgets,
    CheckboxWidget,
    SelectWidget: SelectWidget,
    TextWidget: TextWidget,
  },
});

interface ExtendedFormProps<T> extends FormProps<T> {
  isCreate: boolean;
}

const JSONSchemaForm = <T extends object>({
  children,
  formData,
  onSubmit,
  schema,
  ...rest
}: ExtendedFormProps<T>) => {
  return (
    // @ts-expect-error No idea how to fix it
    <Form
      formData={formData}
      noValidate
      onSubmit={onSubmit}
      schema={{
        ...schema,

        $id: 'https://json-schema.org/draft/2020-12/schema',
        // NOTE: The schema that we use in this form is generated on the backend by pydantic, but pydantic does not specify which json schema version it uses.
        // Because it uses one slightly newer than the rjsf default version, we have to specify it here in order for validation to work as expected.
        $schema: 'https://json-schema.org/draft/2020-12/schema',
        title: '',
      }}
      {...rest}
    >
      {children}
    </Form>
  );
};

export default JSONSchemaForm;
