import cloneDeep from 'lodash/fp/cloneDeep';
import { ArticleSuggestionActionSettings } from 'src/types/workflowBuilderAPITypes';

export const updateDocType = ({
  articleSourceIndex,
  articleSources,
  connector_id,
  doc_type,
}: {
  articleSourceIndex: number;
  articleSources: ArticleSuggestionActionSettings['article_sources'];
  connector_id: string;
  doc_type: string;
}) => {
  const articleSourcesCopy: ArticleSuggestionActionSettings['article_sources'] =
    cloneDeep(articleSources);

  const updatedArticleSources = articleSourcesCopy.map(
    (articleSoruce, index) => {
      if (index == articleSourceIndex) {
        return {
          ...articleSoruce,
          connector_id: connector_id,
          doc_type: doc_type,
        };
      } else {
        return { ...articleSoruce };
      }
    },
  );

  const filterQueries =
    updatedArticleSources[articleSourceIndex]['filter_queries'];

  updatedArticleSources[articleSourceIndex]['filter_queries'] =
    filterQueries.map(filterQuery => ({ ...filterQuery, field: null }));

  return updatedArticleSources;
};
