import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  ListOption,
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import MappingSelectDropdown from './mapping-select-dropdown/MappingSelectDropdown';
import DefaultValueInput from './DefaultValueInput';
import { useGetCvCustomFields } from './helpers';
import { useIsCvHelpdeskFieldsAvailable } from './hooks';
import Checkbox from 'src/components/checkbox';
import * as Styled from 'src/pages/action-builder/context-variables-settings/styles';
import { UpdateFieldMappingParams } from 'src/pages/workflow-builder-config/context-variables/ContextVariablesPage';
import {
  CreateTicketFieldMappingRequest,
  TicketCustomField,
} from 'src/pages/workflow-builder-edit/types';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetTriageModelsQuery } from 'src/services/triage/triageApi';
import { UsageByEntityType } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderSettingsVariablesDict } from 'src/utils/actionBuilder/types';
import { ContextVariableTypes } from 'src/utils/enums';

interface UniversalRowProps {
  customTicketFields: Array<TicketCustomField>;
  defaultValue: unknown;
  deleteTicketFieldMapping: (id: string) => void;
  displayName: string;
  id: string;
  isPersistedCVWorkflowContext: boolean;
  isSystemContext: boolean;
  isWorkflowContext: boolean;
  listTypeOptions: ListOption[] | undefined;
  rowIdx: number;
  shouldShowSkeletons: boolean;
  ticketFieldMappings: CreateTicketFieldMappingRequest[];
  type: keyof typeof ContextVariableTypes;
  updateTicketFieldMappings: (mapping: UpdateFieldMappingParams) => void;
  updateVariables: (
    value: string | boolean,
    idx: number,
    key: keyof ActionBuilderSettingsVariablesDict,
  ) => void;
  usageByEntity: UsageByEntityType | null;
}
const UniversalCVRow: FC<React.PropsWithChildren<UniversalRowProps>> = ({
  customTicketFields,
  defaultValue,
  deleteTicketFieldMapping,
  displayName,
  id,
  isPersistedCVWorkflowContext,
  isSystemContext,
  isWorkflowContext,
  listTypeOptions,
  rowIdx,
  shouldShowSkeletons,
  ticketFieldMappings,
  type,
  updateTicketFieldMappings,
  updateVariables,
  usageByEntity,
}) => {
  const { palette } = useTheme();
  const { data: helpdeskData } = useGetHelpdeskQuery();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const shouldShowHelpdeskMapping = useIsCvHelpdeskFieldsAvailable();
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const isTriageMappingEnabled = featureFlags.includes('triage_model_mapping');

  const isUsedByWidgetWorkflow = !!usageByEntity?.widget_workflow;
  const isUsedByAutoflowWorkflow = !!usageByEntity?.autoflow_workflow;
  const { data: triageModels = [] } = useGetTriageModelsQuery(undefined, {
    skip: !isSolveEmailEnabled || !isTriageMappingEnabled,
  });

  const ticketMappingData = useGetCvCustomFields(id, ticketFieldMappings);

  const isCheckboxDisabled = () => {
    if (isSystemContext) {
      return true;
    }

    if (!isPersistedCVWorkflowContext) {
      return false;
    }

    if (isUsedByWidgetWorkflow || isUsedByAutoflowWorkflow) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
        <Typography color={palette.colors.black[500]} variant='font14'>
          ${displayName}
        </Typography>
        {isSystemContext && (
          <Tooltip tooltipContent='Forethought controlled context variable. Cannot be edited but can be used in certain places throughout workflows.'>
            <IconInfoCircle size={14} />
          </Tooltip>
        )}
      </Box>
      {shouldShowSkeletons ? (
        <Skeleton width='100%' />
      ) : (
        <Typography color={palette.colors.grey[500]} variant='font14'>
          {ContextVariableTypes[type]}
        </Typography>
      )}

      {shouldShowHelpdeskMapping && helpdeskData && (
        <Styled.Cell>
          {shouldShowSkeletons ? (
            <Skeleton width='100%' />
          ) : (
            <MappingSelectDropdown
              contextVariableType={type}
              customTicketFields={customTicketFields}
              deleteTicketFieldMapping={() => deleteTicketFieldMapping(id)}
              helpdesk={helpdeskData.helpdesk}
              isSystemContext={isSystemContext}
              rowIndex={rowIdx}
              ticketMappingData={ticketMappingData}
              triageModels={triageModels}
              updateTicketFieldMappings={selectedValue =>
                updateTicketFieldMappings({
                  context_variable_id: id,
                  selectedValue,
                })
              }
            />
          )}
        </Styled.Cell>
      )}
      <Styled.Cell>
        <Styled.Spacer width='28px' />
        <Checkbox
          aria-label={`Pre-defined ${displayName}`}
          checked={isWorkflowContext}
          data-appcues-target={`ContextVariables${displayName}Checkbox`}
          disabledFlyoutMessage={
            isSystemContext
              ? 'Forethought controlled context variable'
              : 'Unable to deselect this context variable since it is currently used in workflows'
          }
          isDisabled={isCheckboxDisabled()}
          onChange={() =>
            updateVariables(!isWorkflowContext, rowIdx, 'isWorkflowContext')
          }
        />
      </Styled.Cell>
      <Styled.Cell>
        {shouldShowSkeletons ? (
          <Skeleton width='100%' />
        ) : (
          <DefaultValueInput
            isWorkflowContext={isWorkflowContext}
            listTypeOptions={listTypeOptions}
            onChageError={isError =>
              updateVariables(isError, rowIdx, 'isValueError')
            }
            onChange={value =>
              updateVariables(value as string, rowIdx, 'defaultValue')
            }
            type={type}
            value={defaultValue}
          />
        )}
      </Styled.Cell>
    </>
  );
};

export default UniversalCVRow;
