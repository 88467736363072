import { useCallback, useMemo, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import EmailOverallCards from '../common/EmailOverallCards';
import OverallCards from '../common/OverallCards';
import ProductToggleBar from '../common/ProductToggleBar';
import TabHeader from '../common/TabHeader';
import { EMAIL, VOICE, WIDGET } from '../constants';
import { stringifyDateRange } from '../helpers';
import { ProductMode } from '../types';
import ChatsTable from './ChatsTable';
import EmailChatsTable from './EmailChatsTable';
import { OverallCards as VoiceOverallCards } from 'src/components/dashboard-pages/voice-insights/conversations/OverallCards';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';
const SolveInsightsChats = () => {
  const { palette } = useTheme();
  const [scrollTop, setScrollTop] = useState(0);

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: last30DaysTimeRange,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });

  const [productMode, setProductMode] = useStateParams<ProductMode>({
    deserialize: item => {
      switch (item) {
        case 'voice':
          return VOICE;
        case 'email':
          return EMAIL;
        default:
          return WIDGET;
      }
    },
    initialState: WIDGET,
    paramsName: 'product_mode',
    serialize: item => item,
  });

  const isEmail = productMode === EMAIL;
  const isVoice = productMode === VOICE;
  const containerRef = useRef<HTMLDivElement>();

  const scrollToTop = useCallback(
    (yOffset: number, smooth = false) => {
      if (!containerRef.current) {
        return;
      }
      if (containerRef.current.scrollTop > yOffset) {
        containerRef.current.scrollTo({
          behavior: smooth ? 'smooth' : 'auto',
          top: yOffset,
        });
        // TODO - when tab navigation is added this should be updated
        emitTrackingEventCallback('insight-scroll-to-top-clicked', {
          scope: 'main',
          tab: 'chat',
        });
      }
    },
    [emitTrackingEventCallback],
  );

  const selectedChatTable = useMemo(() => {
    if (isEmail) {
      return (
        <EmailChatsTable
          dateRange={dateRange}
          scope='main'
          setDateRange={setDateRange}
        />
      );
    }
    return (
      <ChatsTable
        dateRange={dateRange}
        isDetail
        isVoiceChannel={productMode === VOICE}
        scope='main'
        scrollTop={scrollTop}
        scrollToTop={scrollToTop}
        setDateRange={setDateRange}
        view='chats'
      />
    );
  }, [isEmail, dateRange, setDateRange, productMode, scrollTop, scrollToTop]);

  const selectOverallCards = useMemo(() => {
    if (isEmail) {
      return (
        <EmailOverallCards
          dateRange={dateRange}
          productMode={productMode}
          tab='chat'
        />
      );
    }
    if (isVoice) {
      return <VoiceOverallCards dateRange={dateRange} />;
    }
    return <OverallCards dateRange={dateRange} tab='chat' />;
  }, [isEmail, isVoice, dateRange, productMode]);

  return (
    <Box
      bgcolor={palette.colors.white}
      flex='1'
      height='100%'
      onScroll={e => setScrollTop(e.currentTarget.scrollTop)}
      overflow='auto'
      ref={containerRef}
    >
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flexDirection='column'
        gap={3}
        height='100%'
        padding='24px 40px 0px 40px'
        width='100%'
      >
        <TabHeader
          dateRange={stringifyDateRange(dateRange)}
          productBar={
            <ProductToggleBar
              productMode={productMode}
              setProductMode={setProductMode}
            />
          }
          scope='main'
          tabName='Conversations'
        />
        <>
          {selectOverallCards}
          {selectedChatTable}
        </>
      </Box>
    </Box>
  );
};

export default SolveInsightsChats;
