import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import {
  Badge,
  SelectDropdown,
  Toggle,
} from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitConfigForNonRTE } from '../hooks/useDebouncedSubmitConfig';
import { useGetIntents } from '../hooks/useGetIntents';
import set from 'lodash/fp/set';
import Spinner from 'src/components/spinner';
import {
  selectWidgetConfiguration,
  setSentimentConfigByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { SentimentConfig } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

const badgeOptions = [
  'Highly Negative',
  'Moderately Negative',
  'Neutral',
  'Moderately Positive',
  'Highly Positive',
] as const;

export const SentimentTabContent = () => {
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });
  const debouncedSubmitConfigForNonRTE = useDebouncedSubmitConfigForNonRTE();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const dispatch = useAppDispatch();
  const {
    is_enabled: isEnabled,
    negative_sentiment_routing_intent_workflow_id:
      negativeSentimentRoutingIntentWorkflowId,
  } = widgetConfiguration.sentiment_analysis_config;

  const handleUpdateField = ({
    key,
    val,
  }: {
    key: keyof SentimentConfig;
    val: string | boolean | null;
  }) => {
    const updatedConfig = set(
      ['sentiment_analysis_config', key],
      val,
      widgetConfiguration,
    );

    dispatch(
      setSentimentConfigByKey({
        key: key,
        value: val,
      }),
    );

    debouncedSubmitConfigForNonRTE(
      updatedConfig,
      `sentiment analysis config - ${key}`,
      (val || '').toString(),
    );
  };

  if (!intents.length) {
    return <Spinner />;
  }

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Toggle
        checked={isEnabled}
        label={`Conversation sentiment detection is ${
          isEnabled ? 'on' : 'off'
        }`}
        onChange={e => {
          handleUpdateField({
            key: 'is_enabled',
            val: e.target.checked,
          });
        }}
      />
      {isEnabled && (
        <>
          {' '}
          <Box display='flex' flexDirection='column' gap={1}>
            <Typography variant='font14'>
              Forethought&apos;s sentiment detection model can identify user
              responses sentiment in real time. The model categorizes user
              responses into five levels:
            </Typography>
            <Box display='flex' flexWrap='wrap' gap={0.5}>
              {badgeOptions.map((badge, index) => (
                <Badge
                  key={index}
                  label={<Typography variant='font11'>{badge}</Typography>}
                  variant={
                    badge.includes('Negative')
                      ? 'error'
                      : badge.includes('Positive')
                      ? 'success'
                      : 'macro'
                  }
                />
              ))}
            </Box>
            <Typography variant='font14'>
              You can configure which workflow will be executed when negative
              sentiment is detected.
            </Typography>
          </Box>
          <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.10)', height: '1px' }} />
          <Typography variant='font16Bold'>Reroute</Typography>
          <SelectDropdown
            id=''
            label={
              <Box alignItems='center' display='flex' gap={0.5}>
                Reroute to Workflow for
                <Badge label='Highly Negative' variant='error' />
              </Box>
            }
            onChange={e =>
              handleUpdateField({
                key: 'negative_sentiment_routing_intent_workflow_id',
                val: e.target.value,
              })
            }
            onClear={() =>
              handleUpdateField({
                key: 'negative_sentiment_routing_intent_workflow_id',
                val: null,
              })
            }
            options={intents.map(intent => ({
              label: intent.intent_name,
              value: intent.intent_workflow_id,
            }))}
            placeholder='None (no routing)'
            value={negativeSentimentRoutingIntentWorkflowId ?? ''}
          />
        </>
      )}
    </Box>
  );
};
