import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { Box, styled, useTheme } from '@mui/material';
import { IconStar } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { HeaderLogo } from '../styledComponents';
import PoweredByText from '../theme-tab-content/PoweredByText';
import logoPlaceholderIcon from 'src/assets/images/logo-placeholder.svg';
import {
  selectLegibleButtonOutlineColor,
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';

interface ExpandedWidgetProps {
  children?: ReactNode;
}

const ExpandedWidget = ({ children }: ExpandedWidgetProps) => {
  const { palette } = useTheme();
  const fontColor = useSelector(selectLegibleTextColor);
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const legibleButtonOutlineColor = useSelector(
    selectLegibleButtonOutlineColor,
  );
  const {
    font,
    free_form_intent_detection_enabled: freeFormIntentDetectionEnabled,
    header_image: headerImage,
    launch_style: launchStyle,
    placement,
    size,
    tab_name: tabName,
    theme_color: themeColor,
  } = widgetConfiguration;
  const [params] = useSearchParams();

  const { show_on_header: showOnHeader } = widgetConfiguration.csat_config;
  const isFontColorLegibleOnSelection =
    tinycolor.readability(fontColor, '#BED6FB') >= 4.5;
  const fontColorToUse = isFontColorLegibleOnSelection
    ? fontColor
    : palette.text.primary;

  const containerAlignSelf = (() => {
    if (params.get('tab') !== 'theme') {
      return undefined;
    }

    switch (placement) {
      case 'top':
        return 'flex-start';
      case 'middle':
        return 'center';
      default:
        return 'flex-end';
    }
  })();
  const containerMaxHeight = (() => {
    if (params.get('tab') === 'theme') {
      if (size === 'standard' || launchStyle === 'floating_bar') {
        return '750px';
      }
    }

    return undefined;
  })();
  const containerMaxWidth = (() => {
    if (
      size === 'large' ||
      launchStyle === 'floating_bar' ||
      params.get('tab') !== 'theme'
    ) {
      return '640px';
    }

    return '400px';
  })();

  return (
    <Container
      sx={{
        '*, ::before, ::after': {
          fontFamily: 'inherit !important',
        },
        '.MuiTypography-root': {
          '&::selection': {
            color: fontColorToUse,
          },
        },
        alignSelf: containerAlignSelf,
        boxShadow:
          launchStyle === 'floating_bar'
            ? `0 0 0 1px ${legibleButtonOutlineColor}, 0px 2px 10px 0px #00000040`
            : '0px 2px 10px 0px #00000040',
        fontFamily: `${font} !important`,
        height: '100%',
        maxHeight: containerMaxHeight,
        maxWidth: containerMaxWidth,
      }}
    >
      {/* Header */}
      {launchStyle !== 'floating_bar' && (
        <HeaderContainer backgroundColor={themeColor}>
          <Box
            alignItems='center'
            display='flex'
            gap={1}
            height='64px'
            width='100%'
          >
            <HeaderLogo
              alt='Logo'
              src={headerImage ? headerImage : logoPlaceholderIcon}
            />
            <Box>
              <Typography color={fontColor} noWrap variant='font14Bold'>
                {tabName}
              </Typography>
            </Box>
            {showOnHeader && (
              <Box height='24px' ml='auto'>
                <IconStar color={fontColor} size={24} />
              </Box>
            )}
          </Box>
        </HeaderContainer>
      )}
      {/* Content */}
      <Box display='flex' flex={1} flexDirection='column' overflow='auto'>
        {children}
      </Box>
      {/* Footer */}
      {freeFormIntentDetectionEnabled && (
        <Box
          display='flex'
          flexDirection='column'
          gap='12px'
          marginTop='16px'
          paddingBottom='12px'
        >
          <MessageContainer size={size}>
            <Typography color={palette.colors.grey[400]} variant='font16Medium'>
              Ask a question...
            </Typography>
          </MessageContainer>
          <PoweredByText />
        </Box>
      )}
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 12px;
  background-color: ${props => props.theme.palette.colors.white};
  // 146px is the height of the header + paddings for the static widget
  position: relative;
  flex: 1;
  overflow: hidden;
  width: 80%;
`;

const HeaderContainer = styled('div')<{ backgroundColor: string }>`
  display: flex;
  border-radius: 12px 12px 0px 0px;
  padding: 0px 16px;
  background-color: ${props => props.backgroundColor};
`;

const MessageContainer = styled('div')<{ size: WidgetConfiguration['size'] }>`
  padding: 14px 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  border-color: ${props => props.theme.palette.colors.grey[400]};
  margin: 0px ${props => (props.size === 'large' ? '16px' : '8px')};
  height: 51px;
  display: flex;
`;

export default ExpandedWidget;
