import Skeleton from 'react-loading-skeleton';
import { Box, styled, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  DateRangeFilterButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { formatToDateAndTime } from '@forethought-technologies/forethought-elements';
import { INSIGHT_TOOLTIP_COPY } from '../constants';
import {
  dateRangeToTimestamp,
  getTooltipForMetric,
  numbersToStringWithCommas,
  numberToCurrency,
} from '../helpers';
import ChatPerformanceTableSection from './ChatPerformanceTableSection';
import { NA, TEMP_BLUE } from 'src/constants/solve';
import { useStateParams } from 'src/hooks/hooks';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';
import {
  useGetOverviewChatPerformanceQuery,
  useGetQuickFeedbackQuery,
} from 'src/services/insights';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

const CARD_ROW_LIMIT = 7;

const ChatPerformanceSection = () => {
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: last30DaysTimeRange,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const { breakpoints, palette } = useTheme();
  const getCSATColor = useGetCSATColor();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { data: metadata } = useGetMetadataQuery();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;

  const timeStamps = dateRangeToTimestamp(dateRange);
  const { data, isLoading } = useGetOverviewChatPerformanceQuery({
    end: timeStamps.end_timestamp,
    start: timeStamps.start_timestamp,
  });
  const { data: quickFeedbackData, isLoading: isQuickFeedbackLoading } =
    useGetQuickFeedbackQuery({
      ...timeStamps,
      is_topics_page: false,
      is_workflow_page: false,
    });

  const csatScore = data?.CSAT === 0 ? NA : formatCSAT(data?.CSAT);

  const lastUpdated =
    (data?.last_updated_at && formatToDateAndTime(data.last_updated_at)) ??
    null;

  // Relevance
  const irrelevant_count = data?.relevance_breakdown.irrelevant_count ?? 0;
  const relevant_count = data?.relevance_breakdown.relevant_count ?? 0;
  const somewhat_relevant_count =
    data?.relevance_breakdown.somewhat_relevant_count ?? 0;
  const totalRelevanceCount =
    irrelevant_count + relevant_count + somewhat_relevant_count;
  const relevanceDataIsEmpty = totalRelevanceCount === 0;
  // Engagement
  const dropoffCount = data?.dropoff_breakdown.dropoff_count ?? 0;
  const noDropoffCount = data?.dropoff_breakdown.no_dropoff_count ?? 0;
  const totalEngagementCount = dropoffCount + noDropoffCount;
  const engagementDataIsEmpty = totalEngagementCount === 0;

  const getNumberOfMetrics = () => {
    let defaultValue = 4;
    if (isDeflectionInsightsEnabled) {
      defaultValue += 2;
    }

    if (shouldShowQuickFeedbackAnalytics) {
      defaultValue += 1;
    }
    return defaultValue;
  };

  const metricsLength = getNumberOfMetrics();

  const defaultRepeatCount =
    metricsLength <= CARD_ROW_LIMIT ? metricsLength : CARD_ROW_LIMIT;

  return (
    <Box display='flex' flexDirection='column' gap={3} width='100%'>
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Typography variant='font24'>Overview</Typography>
        <Box display='flex'>
          {isLoading ? (
            <Skeleton height={20} width='200px' />
          ) : (
            <Typography color={palette.colors.grey[600]} variant='font12'>
              {`Last updated at ${lastUpdated}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box alignItems='center' display='flex' gap={2}>
        <Typography variant='font20'>Conversations performance</Typography>
        <DateRangeFilterButton
          explicitLabel
          initialValue={last30DaysTimeRange}
          onChange={setDateRange}
          options={datePickerRangeOptionsRevamp}
          value={dateRange}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${defaultRepeatCount}, 1fr)`,
          marginBottom: '8px',
          width: '100%',
          [breakpoints.down('xl')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
          },
          [breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        }}
      >
        <OverallColumnContainer>
          <Typography
            color={palette.colors.grey[800]}
            noWrap
            variant='font14Bold'
          >
            Total conversations
          </Typography>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography variant='font24'>
              {numbersToStringWithCommas({
                number: data?.total_chats,
              })}
            </Typography>
          </LoadingContainerWrapper>
        </OverallColumnContainer>
        <OverallColumnContainer>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            Deflections
          </Typography>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography noWrap variant='font24'>
              {`${numbersToStringWithCommas({
                number: data?.deflection_chats,
              })} (${numbersToStringWithCommas({
                number: data?.deflection_rate,
                style: 'percent',
              })})`}
            </Typography>
          </LoadingContainerWrapper>
        </OverallColumnContainer>
        <OverallColumnContainer>
          <Box alignItems='center' display='flex' gap='4px'>
            <Typography
              color={palette.colors.grey[800]}
              noWrap
              variant='font14Bold'
            >
              Avg. CSAT
            </Typography>
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.articles_avg_csat}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography
              color={
                csatScore === NA
                  ? palette.colors.grey[600]
                  : getCSATColor(Number(csatScore))
              }
              variant='font24'
            >
              {csatScore}
            </Typography>
          </LoadingContainerWrapper>
        </OverallColumnContainer>
        {isDeflectionInsightsEnabled && (
          <OverallColumnContainer>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Relevance
              </Typography>
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.relevance}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
            <LoadingContainerWrapper isLoading={isLoading}>
              <Box display='flex' flexDirection='row' gap={3}>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color={
                      relevanceDataIsEmpty
                        ? palette.colors.grey[500]
                        : TEMP_BLUE
                    }
                    variant='font24'
                  >
                    {relevanceDataIsEmpty
                      ? NA
                      : numbersToStringWithCommas({
                          number: relevant_count / totalRelevanceCount,
                          style: 'percent',
                        })}
                  </Typography>
                  <Typography variant='font12'>relevant</Typography>
                </Box>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color={
                      relevanceDataIsEmpty
                        ? palette.colors.grey[500]
                        : palette.colors.blue[400]
                    }
                    variant='font24'
                  >
                    {relevanceDataIsEmpty
                      ? NA
                      : numbersToStringWithCommas({
                          number: somewhat_relevant_count / totalRelevanceCount,
                          style: 'percent',
                        })}
                  </Typography>
                  <Box display='flex' flexDirection='column'>
                    <Typography noWrap variant='font12'>
                      somewhat relevant
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color={
                      relevanceDataIsEmpty
                        ? palette.colors.grey[500]
                        : palette.colors.red[500]
                    }
                    variant='font24'
                  >
                    {relevanceDataIsEmpty
                      ? NA
                      : numbersToStringWithCommas({
                          number: irrelevant_count / totalRelevanceCount,
                          style: 'percent',
                        })}
                  </Typography>
                  <Typography variant='font12'>irrelevant</Typography>
                </Box>
              </Box>
            </LoadingContainerWrapper>
          </OverallColumnContainer>
        )}
        {isDeflectionInsightsEnabled && (
          <OverallColumnContainer>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Engagement
              </Typography>
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.engagement}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
            <LoadingContainerWrapper isLoading={isLoading}>
              <Box display='flex' flexDirection='column'>
                <Typography
                  color={
                    engagementDataIsEmpty ? palette.colors.grey[500] : TEMP_BLUE
                  }
                  noWrap
                  variant='font24'
                >
                  {engagementDataIsEmpty
                    ? NA
                    : `${numbersToStringWithCommas({
                        number: noDropoffCount,
                      })} (${numbersToStringWithCommas({
                        number: noDropoffCount / totalEngagementCount,
                        style: 'percent',
                      })})`}
                </Typography>
              </Box>
            </LoadingContainerWrapper>
          </OverallColumnContainer>
        )}
        {shouldShowQuickFeedbackAnalytics && (
          <OverallColumnContainer>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Quick feedback
              </Typography>
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_feedback}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
            <LoadingContainerWrapper isLoading={isQuickFeedbackLoading}>
              <Box display='flex' gap={3}>
                <Box display='flex' flexDirection='column' gap='4px'>
                  <Typography
                    color={palette.colors.green[500]}
                    variant='font24'
                  >
                    {numbersToStringWithCommas({
                      number: quickFeedbackData?.misc?.positive,
                    })}
                  </Typography>
                  <Typography variant='font12'>positive</Typography>
                </Box>
                <Box display='flex' flexDirection='column' gap='4px'>
                  <Typography color={palette.colors.red[500]} variant='font24'>
                    {numbersToStringWithCommas({
                      number: quickFeedbackData?.misc?.negative,
                    })}
                  </Typography>
                  <Typography variant='font12'>negative</Typography>
                </Box>
                <Box display='flex' flexDirection='column' gap='4px'>
                  <Typography color={palette.colors.black} variant='font24'>
                    {numbersToStringWithCommas({
                      number: quickFeedbackData?.misc?.not_answered,
                    })}
                  </Typography>
                  <Typography variant='font12'>not answered</Typography>
                </Box>
              </Box>
            </LoadingContainerWrapper>
          </OverallColumnContainer>
        )}
        <OverallColumnContainer>
          <Box alignItems='center' display='flex' gap='4px'>
            <Typography
              color={palette.colors.grey[800]}
              noWrap
              variant='font14Bold'
            >
              Realized savings
            </Typography>
            <Tooltip
              tooltipContent={getTooltipForMetric(
                'realized_savings',
                metadata?.ticket_cost_info.cost_per_ticket,
              )}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography variant='font24'>
              {numberToCurrency({
                number: data?.realized_saving ?? 0,
                shouldRound: true,
              })}
            </Typography>
          </LoadingContainerWrapper>
        </OverallColumnContainer>
      </Box>
      <ChatPerformanceTableSection timeStamps={timeStamps} />
    </Box>
  );
};

const LoadingContainerWrapper = ({
  children,
  isLoading,
}: {
  children: JSX.Element;
  isLoading: boolean;
}): JSX.Element => {
  if (isLoading) {
    return <Skeleton height='30px' width='100%' />;
  }
  return children;
};

const OverallColumnContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 24px;
`;

export default ChatPerformanceSection;
