import React from 'react';
import { Box } from '@mui/material';

import {
  DateRangeFilterButton,
  IconButton,
  MultiStringInput,
  SelectDropdown,
} from '@forethought-technologies/forethought-elements';
import { initialInsightParams } from '../dashboard-pages/solve-insights/constants';
import { dateRangeToTimestamp } from '../dashboard-pages/solve-insights/helpers';
import { ProductMode } from '../dashboard-pages/solve-insights/types';
import { booleanOperatorOptions } from '../expression-builder/constants';
import { EXPRESSION_TYPE_TO_OPERATOR } from './constants';
import deleteIcon from 'src/assets/images/delete-icon.svg';
import { DeleteIcon } from 'src/pages/workflow-builder-edit/conditions/ConditionEditor';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import { useGetContextVariablesByIdQuery } from 'src/services/dashboard-api';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';
import { DateRange } from 'src/types/types';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

export interface FieldOption {
  category?: string;
  fieldOptions?: Array<{ label: string; value: string }>;
  label: string;
  optionStartAdornment?: React.ReactNode;
  type: QueryExpressionTypes;
  value: string;
}

interface ContextVariableExpressionBuilderProps {
  /** Boolean operator for boolean aggregator expressions */
  booleanOperator?: string;
  /** Query expression to be rendered */
  expression: ConditionExpressions;
  field?: string;
  /** List of options to be rendered in the field dropdown */
  fieldOptions: Array<FieldOption>;
  /** Adornemnt for selected field value */
  fieldStartAdornment?: React.ReactNode;
  /** Type of the field selected */
  fieldType: QueryExpressionTypes;
  /** Index of the query expression */
  index: number;
  /** Determines if the dropdown of the boolean operator selector is disabled */
  isBooleanOperatorDisabled: boolean;
  /** Determines if the delete button is disabled */
  isDeleteButtonDisabled: boolean;
  /** Function to be executed when the delete button is clicked */
  onDeleteButtonClick?: () => void;
  /** Function to be executed when a value for the boolean operator is selected */
  onSelectBooleanOperator?: (value: string) => void;
  /** Function to be executed when a value for the expression field is selected */
  onSelectedField: (fieldValue: string) => void;
  /** Function to be executed when a value for the expression operator is selected */
  onSelectOperator: ({
    negate,
    value,
  }: {
    negate: boolean;
    value: string;
  }) => void;
  /** Function to be executed when a value for the expression value is selected */
  onValueChange: (value: string | number | boolean | Array<string>) => void;
  // Mainly used to fetch cv values that was used based on product - (widget/email)
  product?: ProductMode;
  /** Model of the triage model mapped to the expression */
  triageModel?: string;
  uiDateRange: DateRange;
}

const ContextVariableExpressionBuilder = ({
  booleanOperator,
  expression,
  field,
  fieldOptions,
  fieldStartAdornment,
  fieldType,
  index,
  isBooleanOperatorDisabled,
  isDeleteButtonDisabled,
  onDeleteButtonClick,
  onSelectBooleanOperator,
  onSelectedField,
  onSelectOperator,
  onValueChange,
  product,
  uiDateRange,
}: ContextVariableExpressionBuilderProps) => {
  const isEmail = product === 'email';
  const correspondingOperators = EXPRESSION_TYPE_TO_OPERATOR[fieldType]?.filter(
    item => item.value !== 'contains_one_of',
  );
  const operatorsByType = correspondingOperators || [];

  const operatorOptions = operatorsByType.map(data => ({
    label: data.label,
    value: data.label,
  }));

  const operatorValue =
    operatorsByType.find(
      operator =>
        operator.value === expression.operator &&
        operator.negate === expression.negate,
    )?.label || '';

  const isOperatorValueInOptions = operatorOptions.some(
    options => options.value === operatorValue,
  );

  const hideValueOptions =
    expression.operator === 'empty' || !isOperatorValueInOptions;
  const isDateOperator = expression.operator === 'between';

  const handleExpressionValues = React.useCallback(() => {
    if (isDateOperator) {
      const timestamps = expression.values as Array<string>;
      if (timestamps.length != 2) {
        return initialInsightParams.date;
      }

      // Timestamp is in seconds, so we need to multiply by 1000 to convert it to milliseconds
      return {
        from: new Date(Number(timestamps[0]) * 1000),
        to: new Date(Number(timestamps[1]) * 1000),
      };
    }

    return expression.values;
  }, [expression.values, isDateOperator]);

  const expressionValues = handleExpressionValues();

  const onChangeValueHandlerByInputType = (
    receivedValue: string | Array<string>,
  ) => {
    const value: string | boolean | number | Array<string> = receivedValue;

    onValueChange(value);
  };

  const timestamps = dateRangeToTimestamp(uiDateRange);
  const {
    data: cvOptions,
    isFetching,
    isLoading,
  } = useGetContextVariablesByIdQuery(
    {
      cv_id: field,
      end: timestamps.end_timestamp,
      start: timestamps.start_timestamp,
      ...(isEmail && { channel: 'email' }),
    },
    { skip: !field },
  );

  return (
    <Box display='flex' gap={1}>
      <Box display='flex' flexDirection='column' gap={1} width='100%'>
        {booleanOperator && (
          <Box padding='20px 0px 24px 0px' width='80px'>
            <SelectDropdown
              disabled={isBooleanOperatorDisabled}
              id={`select-boolean-operator-${index}`}
              onChange={e =>
                onSelectBooleanOperator &&
                onSelectBooleanOperator(e.target.value)
              }
              options={booleanOperatorOptions}
              value={booleanOperator || ''}
            />
          </Box>
        )}
        <Box width='100%'>
          <SelectDropdown
            id={`select-query-expression-field-${index}`}
            isMenuSearchable
            onChange={e => onSelectedField(e.target.value)}
            options={fieldOptions}
            startAdornment={fieldStartAdornment}
            value={expression.field || ''}
          />
        </Box>
        <Box width='100%'>
          <SelectDropdown
            id={`select-query-expression-operator-${index}`}
            onChange={e => {
              const operator = operatorsByType.find(
                option => option.label === e.target.value,
              );
              onSelectOperator({
                negate: !!operator?.negate,
                value: operator?.value || '',
              });
            }}
            options={operatorOptions}
            value={operatorValue}
          />
        </Box>
        {!hideValueOptions && (
          <Box width='100%'>
            {isDateOperator ? (
              <DateRangeFilterButton
                explicitLabel={true}
                initialValue={initialInsightParams.date}
                onChange={value => {
                  const valueTimestamps = dateRangeToTimestamp(value);
                  const transformedValue = [
                    valueTimestamps.start_timestamp.toString() ?? '',
                    valueTimestamps.end_timestamp.toString() ?? '',
                  ];
                  onChangeValueHandlerByInputType(transformedValue);
                }}
                options={datePickerRangeOptionsRevamp}
                size='medium'
                value={expressionValues as DateRange}
              />
            ) : (
              <MultiStringInput
                disabled={isLoading || isFetching}
                isVirtualized
                onChange={(_, value) => onChangeValueHandlerByInputType(value)}
                options={cvOptions?.data || []}
                placeholder='Select or enter value'
                value={expressionValues as Array<string>}
              />
            )}
          </Box>
        )}
      </Box>
      <Box display='flex' paddingTop={booleanOperator ? '96px' : '4px'}>
        <IconButton
          aria-label={`delete expression ${index + 1}`}
          disabled={isDeleteButtonDisabled}
          onClick={() => onDeleteButtonClick && onDeleteButtonClick()}
          variant='ghost'
        >
          <DeleteIcon disabled={isDeleteButtonDisabled} src={deleteIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ContextVariableExpressionBuilder;
