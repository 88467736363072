import { useTheme } from '@mui/material/styles';

import { Chip } from '@forethought-technologies/forethought-elements';

interface NotificationBadgeProps {
  label: string;
}

export const NotificationBadge = ({ label }: NotificationBadgeProps) => {
  const { palette } = useTheme();

  return (
    <Chip
      backgroundColor='red'
      color={palette.colors.white}
      fontSize='11px'
      label={label}
      size='small'
      sx={{
        '.MuiChip-label': {
          overflow: 'inherit',
          paddingX: '10px',
        },
        cursor: 'pointer',
        fontWeight: 700,
        height: '20px',
        marginLeft: '8px',
      }}
      variant={'filled'}
    />
  );
};
