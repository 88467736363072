import { Box, styled, useTheme } from '@mui/material';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { VoiceTranscript } from './types';
import { getDuration, getTranscriptTime } from './utils';

export const SpeechTranscript = ({
  startTime,
  transcript,
}: {
  startTime: number;
  transcript: VoiceTranscript;
}) => {
  const { palette } = useTheme();

  const from = transcript.type === 'user' ? 'User' : 'Bot';

  const duration = getDuration(
    transcript.start_timestamp || 0,
    transcript.end_timestamp || 0,
  );
  const durationText = `${from} response length: ${duration} sec`;

  return (
    <Box display='flex' gap={2}>
      <Timestamp>
        <Typography variant='font14'>
          {getTranscriptTime(startTime, transcript.start_timestamp || 0)}
        </Typography>
      </Timestamp>

      <Box display='flex' flexDirection='column' gap={1}>
        <Typography
          color={
            transcript.type === 'user'
              ? palette.colors.blue[500]
              : palette.colors.grey[600]
          }
          variant='font14Bold'
        >
          {from}
        </Typography>

        <Tooltip placement='top' tooltipContent={durationText}>
          <Typography variant='font14'>{transcript.message}</Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

const Timestamp = styled('div')`
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.colors.slate[100]};
  height: fit-content;
  min-width: 52px;
`;
