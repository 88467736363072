import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import KnowledgeGapTable from '../../discover-automation-page/knowledge-gap-tab/KnowledgeGapTable';
import ProductToggleBar from '../common/ProductToggleBar';
import TabHeader from '../common/TabHeader';
import { EMAIL, initialInsightParams, WIDGET } from '../constants';
import { stringifyDateRange } from '../helpers';
import { useGetGeneratedArticles } from '../hooks/useGetGeneratedArticles';
import { ProductMode } from '../types';
import ArticlesCards from './ArticlesCards';
import ArticlesPageLayout from './ArticlesPageLayout';
import ArticlesTable from './ArticlesTable';
import EmailArticlesTable from './EmailArticlesTable';
import InsightArticleDrawer from './InsightArticleDrawer';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { KnowledgeGapArticleRecommendations } from 'src/reducers/discoverReducer/types';
import { useGetPermissionsQuery } from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { selectGeneratedArticles } from 'src/slices/solve-insights/solveInsightsSlice';
import { setGlobalDiscoverArticleOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { getUserRole, Permission } from 'src/utils/enums';

const SolveInsights = () => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  // Hooks
  const userRole = getUserRole();
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const { data: permissions = {} } = useGetPermissionsQuery();
  const isDiscoverEnabled = orgConfigData?.discover_status === 'enabled';
  const isDiscoverPermissionEnabled = Boolean(permissions[Permission.DISCOVER]);

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  //selectors
  const generatedArticles = useSelector(selectGeneratedArticles);
  // state params
  const [activeArticlesTab, setActiveArticlesTab] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'articlesTab',
    serialize: state => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [productMode, setProductMode] = useStateParams<ProductMode>({
    deserialize: item => (item !== 'email' ? WIDGET : EMAIL),
    initialState: WIDGET,
    paramsName: 'product_mode',
    serialize: item => item,
  });
  // end state params
  const isEmail = productMode === 'email';
  const shouldIncludeGeneratedDiscoverArticles =
    isDiscoverEnabled && isDiscoverPermissionEnabled;
  const { isErrorGeneratedArticles, isLoadingGeneratedArticles } =
    useGetGeneratedArticles({ shouldIncludeGeneratedDiscoverArticles });
  const shouldShowBrands = orgConfigData?.is_discover_api_data_filter_enabled;
  const isV2ArticleGeneration =
    orgConfigData?.article_generation_version === 'v2_snippet_articles' &&
    userRole === 'ROLE_SUPER_ADMIN';

  const tabs = useMemo(() => {
    const tabs = ['Knowledge'];
    if (shouldIncludeGeneratedDiscoverArticles) {
      tabs.push('Knowledge gap');
    }
    return tabs;
  }, [shouldIncludeGeneratedDiscoverArticles]);

  const selectedArticleTable = useMemo(() => {
    if (isEmail) {
      return (
        <EmailArticlesTable
          dateRange={dateRange}
          scope='main'
          setDateRange={setDateRange}
        />
      );
    }
    return (
      <ArticlesTable
        dateRange={dateRange}
        scope='main'
        setDateRange={setDateRange}
      />
    );
  }, [isEmail, dateRange, setDateRange]);

  const handleClick = (selectedArticleId: string) => {
    emitTrackingEventCallback('insights-clicked-on-generated-article', {
      page: 'Widget insights',
      'page-area': 'Knowledge gap',
      value: selectedArticleId,
    });
    dispatch(
      setGlobalDiscoverArticleOptions({
        isBrandsEnabled: shouldShowBrands,
        isV2: isV2ArticleGeneration,
        selectedArticleId,
      }),
    );
  };

  return (
    <Box
      flex='1'
      overflow='auto'
      sx={{
        bgcolor: palette.colors.white,
      }}
    >
      <ArticlesPageLayout
        cards={
          <ArticlesCards dateRange={dateRange} productMode={productMode} />
        }
        header={
          <TabHeader
            dateRange={stringifyDateRange(dateRange)}
            productBar={
              <ProductToggleBar
                productMode={productMode}
                setProductMode={setProductMode}
              />
            }
            scope='main'
            tabName='Knowledge'
          />
        }
        tabs={
          <>
            <Box
              sx={{
                alignItems: 'center',
                bgcolor: palette.colors.white,
                borderBottom: `1px solid ${palette.colors.slate[200]}`,
                display: 'flex',
                gap: 1,
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 5,
              }}
            >
              <Tabs
                a11yProps={index => ({
                  'aria-controls': `tabpanel-${index}`,
                  id: `tab-${index}`,
                })}
                aria-label='tabs to pick data table below'
                onChange={(_, tab) => setActiveArticlesTab(tab)}
                tabs={tabs}
                typographyVariant='font18'
                value={activeArticlesTab}
              />
            </Box>
            {activeArticlesTab === 0 ? selectedArticleTable : null}
            {activeArticlesTab === 1 ? (
              <KnowledgeGapTable
                data={
                  generatedArticles as KnowledgeGapArticleRecommendations['articles']
                }
                isBrandsEnabled={shouldShowBrands}
                isError={Boolean(isErrorGeneratedArticles)}
                isV2={isV2ArticleGeneration}
                loading={isLoadingGeneratedArticles}
                onSelect={row => handleClick(row.original.article_id)}
              />
            ) : null}
            <InsightArticleDrawer scope='main' />
          </>
        }
      />
    </Box>
  );
};

export default SolveInsights;
