import Skeleton from 'react-loading-skeleton';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { INSIGHT_TOOLTIP_COPY } from '../solve-insights/constants';
import { numbersToStringWithCommas } from '../solve-insights/helpers';
import { ProductMode } from '../solve-insights/types';
import QuickFeedbackCard from './QuickFeedbackCard';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import {
  InsightArticle,
  QuickFeedbackResponse,
} from 'src/services/insights/types';

interface DetailHeaderProps {
  data?: InsightArticle;
  feedbackData?: QuickFeedbackResponse;
  isLoading: boolean;
  productMode?: ProductMode;
}

const DetailHeader = ({
  data,
  feedbackData,
  isLoading,
  productMode,
}: DetailHeaderProps) => {
  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleDeflectionText = () => {
    const count = numbersToStringWithCommas({
      number: data?.deflected_chats_count,
    });
    let rate = NA;
    if (typeof data?.deflected_rate === 'number') {
      rate = numbersToStringWithCommas({
        number: data.deflected_rate,
        showDecimals: true,
        style: 'percent',
      });
    }
    return `${count} (${rate})`;
  };

  const handleRenderCsatComponent = () => {
    if (!data?.csat) {
      return (
        <Typography color={palette.colors.grey[600]} variant='font24'>
          {NA}
        </Typography>
      );
    }
    return (
      <Typography color={getCSATColor(data?.csat)} variant='font24'>
        {formatCSAT(data?.csat)}
      </Typography>
    );
  };

  // const handleRenderInteractiveEmailComponent = () => {
  //   if (!data?.interactive_email_count || data.chats_count === 0) {
  //     return (
  //       <Typography color={palette.colors.grey[600]} variant='font24'>
  //         {NA}
  //       </Typography>
  //     );
  //   }

  //   const rate = data.interactive_email_count / data.chats_count;
  //   return (
  //     <Typography variant='font24'>{`(${numbersToStringWithCommas({
  //       number: rate,
  //       showDecimals: true,
  //       style: 'percent',
  //     })})`}</Typography>
  //   );
  // };

  const handleClickRateRender = () => {
    if (typeof data?.click_rate === 'number') {
      return (
        <Typography variant='font24'>{`(${numbersToStringWithCommas({
          number: data.click_rate,
          showDecimals: true,
          style: 'percent',
        })})`}</Typography>
      );
    }
    // If time surfaced exists, and rate is na then result should be 0
    const timeSurfacedIsGreaterThanZero =
      data?.times_surfaced && data?.times_surfaced > 0;
    return (
      <Typography variant='font24'>
        (
        <Typography
          color={
            timeSurfacedIsGreaterThanZero
              ? palette.colors.black
              : palette.colors.grey[600]
          }
          variant='font24'
        >
          {timeSurfacedIsGreaterThanZero ? (
            <Typography variant='font24'>
              {numbersToStringWithCommas({
                number: 0,
                showDecimals: true,
                style: 'percent',
              })}
            </Typography>
          ) : (
            NA
          )}
        </Typography>
        )
      </Typography>
    );
  };

  const isEmail = productMode === 'email';

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box display='flex' flexDirection='column' gap='4px'>
        <Box alignItems='center' display='flex' gap='4px'>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            Surfaced
          </Typography>
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope: 'detail',
                tab: 'article',
                value: 'times_surfaced',
              });
            }}
          >
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.article_surfaced}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
        {isLoading ? (
          <Skeleton height={30} />
        ) : (
          <Typography variant='font24'>
            {numbersToStringWithCommas({ number: data?.times_surfaced })}
          </Typography>
        )}
      </Box>
      {/* TODO - When interactive analytics is added */}
      {/* {isEmail &&
        typeof data?.interactive_email_count === 'number' &&
        data.interactive_email_count > 0 && (
          <Box display='flex' flexDirection='column' gap='4px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Engage with interactive email widget
              </Typography>
              <Box display='flex'>
                <Tooltip
                  tooltipContent={INSIGHT_TOOLTIP_COPY.interactive_email}
                >
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box>
            </Box>
            {isLoading ? (
              <Skeleton height={30} />
            ) : (
              <Box display='flex' gap='4px'>
                <Typography variant='font24'>
                  {numbersToStringWithCommas({
                    number: data?.interactive_email_count,
                  })}
                </Typography>
                {handleRenderInteractiveEmailComponent()}
              </Box>
            )}
          </Box>
        )} */}
      {!isEmail && (
        <Box display='flex' flexDirection='column' gap='4px'>
          <Box alignItems='center' display='flex' gap='4px'>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Clicks
            </Typography>
            <Box
              display='flex'
              onMouseEnter={() => {
                emitTrackingEventCallback('insight-tooltip-hover', {
                  scope: 'detail',
                  tab: 'article',
                  value: 'clicks',
                });
              }}
            >
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.article_clicks}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
          </Box>
          {isLoading ? (
            <Skeleton height={30} />
          ) : (
            <Box display='flex' gap='4px'>
              <Typography variant='font24'>
                {numbersToStringWithCommas({
                  number: data?.clicks,
                })}
              </Typography>
              {handleClickRateRender()}
            </Box>
          )}
        </Box>
      )}
      <Box display='flex' flexDirection='column' gap='4px'>
        <Box alignItems='center' display='flex' gap='4px'>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            Deflections
          </Typography>
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope: 'detail',
                tab: 'article',
                value: 'deflected_chats_count',
              });
            }}
          >
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.article_deflected_chats}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
        {isLoading ? (
          <Skeleton height={30} />
        ) : (
          <Typography variant='font24'>{handleDeflectionText()}</Typography>
        )}
      </Box>
      <QuickFeedbackCard
        feedbackData={feedbackData}
        isEmail={isEmail}
        isLoading={isLoading}
      />
      {!isEmail && (
        <Box display='flex' flexDirection='column' gap='4px'>
          <Box alignItems='center' display='flex' gap='4px'>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Avg. CSAT
            </Typography>
            <Box
              display='flex'
              onMouseEnter={() => {
                emitTrackingEventCallback('insight-tooltip-hover', {
                  scope: 'detail',
                  tab: 'article',
                  value: 'csat',
                });
              }}
            >
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.articles_avg_csat}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
          </Box>
          {isLoading ? <Skeleton height={30} /> : handleRenderCsatComponent()}
        </Box>
      )}
    </Box>
  );
};
export default DetailHeader;
