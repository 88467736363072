import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID } from '../constants';
import { IntentData } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

const KnowledgeRetrieval = ({ intents }: { intents: IntentData[] }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const handleNavigate = () => {
    const knowledgeRetrievalIntent = intents.find(
      intent =>
        intent.intent_definition_id === KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID,
    );

    if (knowledgeRetrievalIntent) {
      const channel = params.get('channel');
      const urlParams = new URLSearchParams({
        intentId: knowledgeRetrievalIntent.intent_definition_id,
        view: channel || 'widget',
        workflowId: knowledgeRetrievalIntent.intent_workflow_id,
      });
      if (channel === 'voice') {
        urlParams.set('mode', 'autoflow');
      }

      navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${urlParams}`);
    }
  };

  return (
    <>
      <Typography variant='font16Bold'>Knowledge retrieval</Typography>
      <Box pt={3}>
        <Button fullWidth onClick={handleNavigate} variant='secondary'>
          <Typography variant='font14Bold'>
            {'Build Knowledge Retrieval in workflow'}
          </Typography>
        </Button>
      </Box>
      <Box pt={1}>
        <Typography color={palette.colors.grey[600]} variant='font12'>
          The
        </Typography>
        <Typography color={palette.colors.purple[500]} variant='font12'>
          {' Knowledge retrieval '}
        </Typography>
        <Typography color={palette.colors.grey[600]} variant='font12'>
          feature triggers the connections you have defined in the
        </Typography>
        <Typography color={palette.colors.black[500]} variant='font12'>
          {' Knowledge base, '}
        </Typography>
        <Typography color={palette.colors.grey[600]} variant='font12'>
          allowing relevant articles to be presented for customer queries.
        </Typography>
      </Box>
    </>
  );
};

export default KnowledgeRetrieval;
