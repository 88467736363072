import axios, { AxiosResponse } from 'axios';

import { getCognitoSession } from './authUtils';

interface ValidateEmailResponse {
  // A url where the user can find their company's dashboard
  login_url: string;
}

export const validateAssistEmail = (
  email: string,
): Promise<AxiosResponse<ValidateEmailResponse>> => {
  return axios.post(`${ASSIST_API_URL}/assist-extension/v1/validate-email`, {
    email,
  });
};

/**
 * Pull current session from Amplify and post message to pass to Assist
 * In the case no extension is available, this is fine and this will no-op
 */
export async function sendSessionToAssistExtension() {
  // Noop if chrome is undefined
  if (!window.chrome?.runtime?.sendMessage) {
    return;
  }
  let session = null;
  try {
    session = await getCognitoSession();
  } catch {}
  try {
    await window.chrome.runtime.sendMessage(ASSIST_EXTENSION_ID_PRODUCTION, {
      session,
      type: 'COGNITO_AUTH_EVENT',
    });
  } catch {
    // If this raises an exception it means the extension is not available. This is fine, consider a no-op
  }
  try {
    await window.chrome.runtime.sendMessage(ASSIST_EXTENSION_ID_INTERNAL, {
      session: null,
      type: 'COGNITO_AUTH_EVENT',
    });
  } catch {
    // If this raises an exception it means the internal extension is not available. This is fine, consider a no-op
  }
}
