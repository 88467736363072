import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';

import {
  DatePicker,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { checkboxTypeValueOptions } from './constants';
import DefaultPhoneNumberInput from './DefaultPhoneInput';
import { ListOption } from 'src/components/reusable-components/autocomplete-dropdown/types';
import { isEmailValid } from 'src/pages/solve-analytics/macro-controls-view/utils/handleEmailUtil';
import { componentText } from 'src/pages/workflow-builder-edit/customization-panel/set-context-variable-settings-panel/componentText';
import { ContextVariableConfigurationDict } from 'src/utils/actionBuilder/types';
import { ContextVariableTypeKeys, ContextVariableTypes } from 'src/utils/enums';
import {
  getIsNumberValid,
  isPhoneNumberValid,
  validateTextField,
} from 'src/utils/validators';

interface DefaultValueInputProps {
  configurationFields?: ContextVariableConfigurationDict;
  disabled?: boolean;
  isWorkflowContext: boolean;
  listTypeOptions?: ListOption[];
  onChageError: (isError: boolean) => void;
  onChange: (value: unknown) => void;
  type: keyof typeof ContextVariableTypes;
  value?: unknown;
}

const DefaultValueInput: React.FC<DefaultValueInputProps> = ({
  configurationFields,
  disabled = false,
  isWorkflowContext,
  listTypeOptions,
  onChageError,
  onChange,
  type,
  value,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = useCallback(
    (value: unknown) => {
      let validationError: string | null = null;
      let isError = false;

      switch (type) {
        case ContextVariableTypeKeys.SHORT_TEXT:
        case ContextVariableTypeKeys.LONG_TEXT:
          validationError = validateTextField(
            value as string,
            configurationFields || {},
          );
          isError = !!validationError;
          break;

        case ContextVariableTypeKeys.NUMBER:
          isError =
            value !== undefined &&
            !getIsNumberValid((value as number).toString());
          validationError = isError ? componentText.number_error : null;
          break;

        case ContextVariableTypeKeys.EMAIL:
          isError = !!value && !isEmailValid(value as string);
          validationError = isError ? componentText.email_error : null;
          break;

        case ContextVariableTypeKeys.PHONE:
          isError = !!value && !isPhoneNumberValid(value as string);
          validationError = isError ? 'Invalid phone number' : null;
          break;

        default:
          break;
      }

      setError(validationError);
      onChageError(isError);
      onChange(value);
    },
    [type, configurationFields, onChageError, onChange],
  );

  if (!isWorkflowContext) {
    return <Typography variant='body2'>n/a</Typography>;
  }

  switch (type) {
    case ContextVariableTypeKeys.SHORT_TEXT:
    case ContextVariableTypeKeys.LONG_TEXT:
      return (
        <TextField
          aria-label='aria-label-default-value'
          disabled={disabled}
          error={!!error}
          helperText={error}
          maxRows={2}
          multiline
          onChange={e => handleChange(e.target.value)}
          placeholder='Default text'
          value={value as string}
        />
      );

    case ContextVariableTypeKeys.NUMBER:
      return (
        <TextField
          aria-label='aria-label-default-value'
          error={!!error}
          helperText={error}
          onChange={e => handleChange(parseInt(e.target.value) || '')}
          value={(value as number)?.toString() || ''}
        />
      );

    case ContextVariableTypeKeys.LIST:
      return (
        <SelectDropdown
          aria-label='aria-label-default-dropdown'
          disabled={disabled}
          id='aria-label-default-dropdown'
          onChange={selected => handleChange(selected?.target?.value || '')}
          options={listTypeOptions || []}
          placeholder='Select'
          value={value as string}
        />
      );

    case ContextVariableTypeKeys.EMAIL:
      return (
        <TextField
          aria-label='aria-label-default-email'
          autoCapitalize='none'
          autoCorrect='off'
          disabled={disabled}
          error={!!error}
          helperText={error}
          onChange={e => handleChange(e.target.value)}
          placeholder='Default email'
          type='email'
          value={value as string}
        />
      );

    case ContextVariableTypeKeys.CHECKBOX:
      return (
        <SelectDropdown
          aria-label='default-check-box'
          disabled={disabled}
          id='aria-label-boolean-dropdown'
          onChange={selected => handleChange(selected?.target?.value || '')}
          options={checkboxTypeValueOptions}
          placeholder='Select'
          value={value as string}
        />
      );

    case ContextVariableTypeKeys.PHONE:
      return (
        <DefaultPhoneNumberInput
          onChageError={onChageError}
          onChange={handleChange}
          value={value as string}
        />
      );

    case ContextVariableTypeKeys.MULTI_SELECT_LIST:
      return (
        <SelectDropdown
          id='multiselect-string-default-dropdown'
          multiple
          onChange={e => handleChange(e.target.value)}
          options={listTypeOptions ?? []}
          placeholder='Select'
          value={Array.isArray(value) ? value : []}
        />
      );

    case ContextVariableTypeKeys.DATE:
      return (
        <DatePicker
          onChange={onChange}
          size='medium'
          value={value ? moment(value as string, 'YYYY-MM-DD').toDate() : null}
        />
      );

    default:
      return <Typography variant='body2'>n/a</Typography>;
  }
};

export default DefaultValueInput;
