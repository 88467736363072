import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';

import {
  Checkbox,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  useDebouncedSubmitConfigForNonRTE,
  useDebouncedSubmitConfigForRTE,
} from '../hooks/useDebouncedSubmitConfig';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import { RTEContainer } from '../styledComponents';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import {
  selectIsValidationFailing,
  selectWidgetConfiguration,
  setProactiveChatGreeting,
  setWidgetDataByKey,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

const PromptTabContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);
  const editorRef = useRef<EditorRef>(null);

  const {
    display_proactive_chat,
    display_proactive_chat_intents,
    launch_style,
    manually_selected_top_intents,
    num_top_intents,
    proactive_chat_greeting,
  } = widgetConfiguration;

  const embedHref = `${location.pathname}?tab=theme`;
  const isPromptEmpty = proactive_chat_greeting?.trim() === '';
  const showConfig = display_proactive_chat || launch_style === 'floating_bar';

  const remirrorContextVariables = useGetRemirrorContextVariables();
  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();
  const debouncedSubmitConfigForNonRTE = useDebouncedSubmitConfigForNonRTE();

  const onChangeToggleField = (
    key: 'display_proactive_chat' | 'display_proactive_chat_intents',
    overrideValue?: boolean,
  ) => {
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }

    const value =
      overrideValue !== undefined ? overrideValue : !widgetConfiguration[key];
    dispatch(setWidgetDataByKey({ key, value }));

    const updatedWidgetConfiguration = {
      ...widgetConfiguration,
      [key]: value,
    };

    debouncedSubmitConfig(updatedWidgetConfiguration, key, value.toString());
  };

  const handleFloatingBarToggleChange = (value: boolean) => {
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }

    dispatch(setWidgetDataByKey({ key: 'display_proactive_chat', value }));
    dispatch(
      setWidgetDataByKey({ key: 'display_proactive_chat_intents', value }),
    );

    const updatedConfiguration = {
      ...widgetConfiguration,
      display_proactive_chat: value,
      display_proactive_chat_intents: value,
    };

    debouncedSubmitConfigForNonRTE(
      updatedConfiguration,
      'display_proactive_chat',
      value.toString(),
    );
    debouncedSubmitConfigForNonRTE(
      updatedConfiguration,
      'display_proactive_chat_intents',
      value.toString(),
    );
  };

  const handleProactiveChatGreetingChange = (html: string) => {
    dispatch(setProactiveChatGreeting(html));
    const updatedWidgetConfiguration = {
      ...widgetConfiguration,
      proactive_chat_greeting: html,
    };
    debouncedSubmitConfig(
      updatedWidgetConfiguration,
      'proactive_chat_greeting',
      html,
    );
  };

  const handleEmbedNavigate = () => {
    navigate({
      pathname: isSolveLiteEnabled
        ? Routes.SOLVE_LITE
        : Routes.SOLVE_CONFIGURATION,
      search: '?tab=theme',
    });
  };

  const proactiveChatIntentsEnabled =
    launch_style === 'floating_bar'
      ? display_proactive_chat && display_proactive_chat_intents
      : display_proactive_chat_intents;

  return (
    <Box>
      <ProactivePromptTitleContainer>
        <Typography variant='font16Bold'>
          {launch_style === 'floating_bar'
            ? 'Hover prompt'
            : 'Proactive prompt'}
        </Typography>
      </ProactivePromptTitleContainer>
      {/* only show toggle when not floating bar */}
      {launch_style !== 'floating_bar' && (
        <ToggleContainer>
          <Toggle
            checked={display_proactive_chat}
            label='Display proactive prompt to open chat window'
            onChange={() => onChangeToggleField('display_proactive_chat')}
          />
        </ToggleContainer>
      )}
      {showConfig && (
        <div>
          {/* only show greeting when not floating bar */}
          {launch_style !== 'floating_bar' && (
            <>
              <RTEContainer hasError={isPromptEmpty}>
                <RichTextEditor
                  contextVariables={remirrorContextVariables}
                  editorRef={editorRef}
                  initialContent={proactive_chat_greeting || ''}
                  onChange={handleProactiveChatGreetingChange}
                />
              </RTEContainer>
              {isPromptEmpty && (
                <Typography
                  color={theme.palette.colors.red[500]}
                  variant='font14Medium'
                >
                  Enter a Prompt message. Proactive prompt cannot be empty.
                </Typography>
              )}
            </>
          )}
          {!isSolveLiteEnabled && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                checked={proactiveChatIntentsEnabled}
                label={
                  <Typography variant='font14'>
                    Display Intent options
                  </Typography>
                }
                onChange={e => {
                  if (launch_style === 'floating_bar') {
                    handleFloatingBarToggleChange(e.target.checked);
                  } else {
                    onChangeToggleField('display_proactive_chat_intents');
                  }
                }}
              />
              {display_proactive_chat_intents &&
                manually_selected_top_intents?.length === 0 &&
                num_top_intents === 0 && (
                  <Typography
                    color={theme.palette.colors.grey[600]}
                    variant='font12'
                  >
                    You haven&apos;t selected intent options in the{' '}
                    <Link
                      onClick={handleEmbedNavigate}
                      style={{
                        cursor: 'pointer',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                      }}
                      to={embedHref}
                    >
                      Theme section
                    </Link>
                  </Typography>
                )}
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};

export default PromptTabContent;

const ProactivePromptTitleContainer = styled('div')`
  margin-bottom: 18px;
`;

const ToggleContainer = styled('div')`
  margin-bottom: 20px;
`;
