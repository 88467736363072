import { styled } from '@mui/material';

import logo from 'src/assets/images/forethought-logo-colorful.png';

export const LightNeonCircle = ({ isBlur }: { isBlur: boolean }) => {
  return (
    <Container isBlur={isBlur}>
      <NeonLight />
      <MainCircle>{!isBlur && <img height='72px' src={logo} />}</MainCircle>
    </Container>
  );
};

const Container = styled('div')<{ isBlur: boolean }>`
  position: relative;
  width: 300px;
  height: 300px;
  filter: drop-shadow(0 0 50px rgba(140, 110, 250, 0.3))
    ${props => (props.isBlur ? 'blur(1px)' : '')};

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-40px);
    }
  }
`;

const MainCircle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: ${props => props.theme.palette.colors.slate[100]};

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(140, 110, 250, 0.3), 0 0 20px rgba(190, 132, 249, 0.6);
  transform: translate(-50%, -50%) scale(1);
`;

const NeonLight = styled('div')`
  position: absolute;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 2;
  overflow: hidden;
  animation: rotate 15s linear infinite;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: conic-gradient(
      from 0deg,
      #2b3bfb 15%,
      #cb18f5 30%,
      #4dbbf4 65%,
      #d84a4e 80%,
      #e4bef6 100%
    );
    animation: glow 6s ease-in-out infinite alternate;
    opacity: 0.9;
    filter: blur(20px);
  }

  ::after {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background: ${props => props.theme.palette.colors.slate[100]};
    border-radius: 50%;
    z-index: 1;
    filter: blur(5px);
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes glow {
    0% {
      filter: blur(20px) brightness(1.2);
    }
    100% {
      filter: blur(30px) brightness(1.5);
    }
  }
`;
