import { Option } from 'node_modules/@forethought-technologies/forethought-elements/dist/Components/MultiSelectFilter/types';
import { useGetIsDiscoverEnabledInInsights } from 'src/components/dashboard-pages/solve-insights/hooks/useGetIsDiscoverEnabledInInsights';
import { useGetAllTopicsQuery } from 'src/services/discover/discoverApi';
import { DateRange } from 'src/types/types';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';

export default function useGetTopicOptions({
  dateRange,
  skip,
}: {
  dateRange: DateRange;
  skip?: boolean;
}) {
  const isDiscoverEnabled = useGetIsDiscoverEnabledInInsights();
  const timeFilter = dateRangeToTimeFilter(dateRange);
  const { data } = useGetAllTopicsQuery(
    {
      dataFilterQuery: undefined,
      timeFilter,
    },
    { skip: !isDiscoverEnabled || skip },
  );

  const options: Option[] = [];
  data?.breakdown.forEach(topic => {
    options.push({
      label: topic.topic?.topic_name || '',
      options:
        topic.breakdown?.map(subtopic => ({
          label: subtopic.topic?.topic_name || '',
          value: `topic_ids.${subtopic.id}`,
        })) || [],
      // We don't want to select parent topic id for filter
      value: `parent_topic_ids.${topic.id}`,
    });
  });

  const topicOptions = [
    {
      label: 'Topics',
      options: options,
      value: 'topic_ids',
    },
  ];

  if (isDiscoverEnabled && !skip) {
    return topicOptions;
  }

  return [];
}
