import { captureException, configureScope } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { JSONParser } from '@streamparser/json-whatwg';

import {
  ChannelsInterface,
  EmbeddableLookerDashboard,
  EmbeddableSettings,
  GetOrgDiscoveryResponse,
  IdPDetails,
} from './apiInterfaces';
import defaultHeaders from './defaultHeaders';
import { setOrganizationCode } from 'src/actions/user';
import { EmitTrackingEventArgs } from 'src/components/app/types';
import { SelfSignUpRequest } from 'src/components/authentication-wall/signup/types';
import {
  activeAssertionErrorMessage,
  noWorkflowFoundErrorMessage,
} from 'src/constants/apiErrors';
import {
  HelpdeskResponse,
  SelfServeFormInput,
  TriageModelDetail,
  TriageModelStatus,
  TriageTestPredictionRequest,
  TriageTestPredictionResult,
  TriageTicketFieldsResponse,
} from 'src/reducers/triageSettingsReducer/types';
import { isForethoughtError } from 'src/slices/workflow-preview/types';
import store from 'src/store/store';
import { SolveEmbeddedDashboardResponse } from 'src/types/workflowBuilderAPITypes';
import { getCognitoToken } from 'src/utils/authUtils';
import {
  getSelectedOrganizationForSuperAdmin,
  getUserRole,
  Permission,
  UserRoles,
} from 'src/utils/enums';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';
import { getSentryTraceId, setSentryTraceIdTag } from 'src/utils/sentryTraceId';

const emailBuilderPath = '/email-builder/';
const emailWorkflowPath = '/email-workflow/';
const solveEmailPaths = [emailBuilderPath, emailWorkflowPath];
const networkErrorMessages = [
  'Load failed',
  'Failed to fetch',
  'Network Error',
  'Request aborted',
  'timeout exceeded',
];

function configureSentry(error: any, sentryData: Record<string, any>) {
  configureScope(scope => {
    scope.setFingerprint(error);
    for (const key of Object.keys(sentryData)) {
      scope.setExtra(key, sentryData[key]);
    }
  });
}

function sentryCaptureException({
  error,
  sentryData,
}: {
  error: Error;
  sentryData?: Record<string, any>;
}) {
  if (networkErrorMessages.includes(error.message)) {
    // Ignore network errors to avoid spamming Sentry
    return;
  }

  configureSentry(error.message, sentryData || {});
  captureException(error);
}

function parseError(error: unknown): Error {
  if (error instanceof Error || isForethoughtError(error)) {
    return new Error(error.message, { cause: error });
  } else {
    return new Error(String(error), { cause: error });
  }
}

const sendRequest = (url: string, data?: any, method = 'POST') => {
  const config = {
    credentials: 'include',
    headers: new Headers({
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }),
    method: method,
  };

  if (method !== 'GET' && method !== 'HEAD') {
    (config as any).body = JSON.stringify(data);
  }
  return fetch(url, config as RequestInit).catch(err => {
    sentryCaptureException({
      error: parseError(err),
      sentryData: {
        method,
        payload: data,
        url,
      },
    });
    return {
      error: 'error',
    };
  });
};

export const sendAuthRequest = (
  url: string,
  data?: any,
  method = 'POST',
  extraSentryTags: Record<string, any> = {},
) => {
  return getCognitoToken()
    .then(bearerToken => {
      const config = {
        credentials: 'include',
        headers: new Headers({
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
          'X-access-token': bearerToken,
        }),
        method: method,
      };

      if (getUserRole() === UserRoles.ROLE_SUPER_ADMIN) {
        const selectedOrgId =
          getSelectedOrganizationForSuperAdmin()?.org_id || '';
        config.headers.set('X-ORG-ID', selectedOrgId);
      }

      const editUuid = defaultHeaders.get('edit-session-uuid');
      if (!!editUuid) {
        config.headers.set('edit-session-uuid', editUuid);
      }

      // Update trace id tag and corresponding request header to keep tracing in sync
      setSentryTraceIdTag();
      const sentryTraceId = getSentryTraceId();
      if (!!sentryTraceId) {
        config.headers.set('custom-sentry-trace', sentryTraceId);
      }

      if (method !== 'GET' && method !== 'HEAD') {
        (config as any).body = JSON.stringify(data);
      }

      return fetch(url, config as RequestInit)
        .then(async res => {
          const shouldListen409Errors = solveEmailPaths.some(path =>
            url.includes(path),
          );

          if (res.status !== 200 && !res.ok) {
            // Macro admin controls returns 409 with new payload to update macro so we return the whole response
            if (res.status === 409 && !shouldListen409Errors) {
              return res;
            } else {
              const resJson = await res.json();
              const errorMessage = resJson.message || resJson.error_message;
              const skipSentryErrors = [
                activeAssertionErrorMessage,
                noWorkflowFoundErrorMessage,
              ];
              const shouldSkipSentry =
                skipSentryErrors.includes(errorMessage) || res.status === 403;

              if (!shouldSkipSentry) {
                configureSentry(errorMessage, {
                  method,
                  payload: data,
                  status: res.status,
                  url,
                });
                for (const key of Object.keys(extraSentryTags)) {
                  Sentry.setTag(key, extraSentryTags[key]);
                }
                captureException(
                  new Error(`${errorMessage} (status=${res.status})`),
                );
              }
              return {
                error: errorMessage,
                status: res.status,
              };
            }
          }

          return res;
        })
        .catch(err => {
          sentryCaptureException({
            error: parseError(err),
            sentryData: {
              method,
              payload: data,
              url,
            },
          });
          return {
            error: err,
          };
        });
    })
    .catch(err => {
      for (const key of Object.keys(extraSentryTags)) {
        Sentry.setTag(key, extraSentryTags[key]);
      }
      sentryCaptureException({
        error: parseError(err),
        sentryData: {
          method,
          payload: data,
          url,
        },
      });
      return {
        error: err,
      };
    });
};

export type OnChunkReceived = (param: {
  isFirstResponse: boolean;
  requestId: number;
  value: unknown;
}) => void;

export const sendAuthRequestWithErrorHandling = async (
  url: string,
  data?: any,
  method = 'POST',
  getBearerToken = getCognitoToken,
  {
    isStream = false,
    onChunkReceived,
  }: {
    isStream?: boolean;
    onChunkReceived?: OnChunkReceived;
  } = {},
) => {
  setSentryTraceIdTag();
  const sentryTraceId = getSentryTraceId();

  const bearerToken = await getBearerToken();
  const selectedOrgId = getSelectedOrganizationForSuperAdmin()?.org_id || '';
  const config: RequestInit = {
    credentials: 'include',
    headers: new Headers({
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
      ...(!!sentryTraceId ? { 'custom-sentry-trace': sentryTraceId } : {}),
      ...(getUserRole() === UserRoles.ROLE_SUPER_ADMIN
        ? { 'X-ORG-ID': selectedOrgId }
        : {}),
    }),
    method,
  };

  if (method !== 'GET' && method !== 'HEAD') {
    config.body = JSON.stringify(data);
  }

  try {
    const res = await fetch(url, config);

    if (res.status === 204) {
      return null;
    }

    if (res.status !== 200 && !res.ok) {
      const json = await res.json();
      const errorMessage = json.message ?? json.error_message;
      const shouldSkipSentry = res.status === 403;
      if (!shouldSkipSentry) {
        configureSentry(errorMessage, {
          method,
          payload: data,
          status: res.status,
          url,
        });
        captureException(new Error(`${errorMessage} (status=${res.status})`));
      }

      return Promise.reject(json);
    }

    if (isStream) {
      const requestId = Date.now();
      const parser = new JSONParser({ paths: ['$'], separator: '\n' });

      const reader = res.body?.pipeThrough(parser).getReader();

      if (reader) {
        let isFirstResponse = true;

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            return;
          }

          onChunkReceived?.({ isFirstResponse, requestId, value: value.value });

          isFirstResponse = false;
        }
      }
    } else {
      const json = await res.json();
      return json;
    }
  } catch (err) {
    sentryCaptureException({
      error: parseError(err),
      sentryData: {
        method,
        payload: data,
        url,
      },
    });
    throw err;
  }
};

//fetch to get channels available for solve
export const getSolveChannels = (): Promise<ChannelsInterface> => {
  return sendAuthRequest(
    `${API_URL}solve-analytics/get-channel-names`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json())
    .then((res: ChannelsInterface) => {
      return {
        channels: res.channels,
      };
    });
};

//fetch search table analytics metrics
export const getSearchMetrics = (start_timestamp: any, end_timestamp: any) => {
  const data = {
    end_timestamp: end_timestamp,
    start_timestamp: start_timestamp,
  };
  return sendAuthRequest(`${API_URL}search-analytics/get-aggregated`, data)
    .then((res: any) => normalizeResponseErrors(res))
    .then((res: any) => res.json())
    .then((res: any) => {
      return {
        aggregate: res.aggregate,
        breakdown: res.breakdown,
      };
    });
};

//fetch search chart analytics metrics
export const getChartSearchMetrics = async (
  start_timestamp: any,
  end_timestamp: any,
  breakdownType: string,
) => {
  const data = {
    breakdown_type: breakdownType,
    end_timestamp: end_timestamp,
    start_timestamp: start_timestamp,
  };
  return sendAuthRequest(
    `${API_URL}search-analytics/search-analytics-by-date`,
    data,
  )
    .then((res: any) => normalizeResponseErrors(res))
    .then((res: any) => res.json())
    .then((res: any) => {
      return {
        chartSearchMetrics: res,
        chartSearchMetricsData: res.data,
      };
    });
};

export const getModelsId = async () => {
  return sendAuthRequest(`${API_URL}prediction-metrics/get-model-names`)
    .then((res: any) => normalizeResponseErrors(res))
    .then((res: any) => res.json())
    .then((res: any) => {
      return {
        modelsId: res,
      };
    });
};

export const getPermission = async () => {
  return sendAuthRequest(`${API_URL}dashboard-applications`, {})
    .then(normalizeResponseErrors)
    .then((res: any) => res.json())
    .then(permissions => ({
      ...permissions,
      flamethrower_workflow_builder_edit: permissions.flamethrower,
      [Permission.SOLVE_PREVIEW]:
        permissions[Permission.SOLVE_LITE] ||
        permissions[Permission.WORKFLOW_BUILDER],
    }));
};

export const getIdentityProviderForOrg = async (
  org: string,
): Promise<IdPDetails> => {
  const res = await sendRequest(`${API_URL}user-discovery/organization`, {
    organization: org,
  });

  // keeping this for now, but we should refactor sendRequest to just return response or throw an error
  if ('error' in res) {
    throw Error('Error fetching idp');
  }

  return await res.json();
};

export const getOrgDiscovery = (
  orgName: string,
): Promise<GetOrgDiscoveryResponse> => {
  return sendRequest(
    `${API_URL}ssel/organizations/discovery?org_name=${orgName}`,
    {},
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const sendEvent = async (moduleName: string, event: any) => {
  const moduleIdentity = {
    module: moduleName,
  };
  return sendAuthRequest(
    `${API_URL}tracking-event`,
    Object.assign(moduleIdentity, event, {}),
  );
};

export const getOrgIdAndOrgCode = async (): Promise<{
  account_id: string;
  org_code: string;
  organization_id: string;
}> => {
  return sendAuthRequest(`${API_URL}settings/user/organization`, {}, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: any) => res.json())
    .then(json => {
      store.dispatch(setOrganizationCode(json.org_code));
      return json;
    });
};

// Analytics Settings APIs
export const getTriageModels = async (): Promise<TriageModelDetail[]> => {
  return sendAuthRequest(`${API_URL}ssel/triage/models`, {}, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getTriageModel = async (
  modelName: string,
): Promise<TriageModelDetail> => {
  return sendAuthRequest(`${API_URL}ssel/triage/models/${modelName}`, {}, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateTriageModel = async (
  modelName: string,
  status: TriageModelStatus,
): Promise<TriageModelDetail> => {
  return sendAuthRequest(
    `${API_URL}ssel/triage/models/${modelName}/status`,
    { status: status },
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateTriageModelFieldsToPredict = async (
  modelName: string,
  body: SelfServeFormInput,
): Promise<TriageModelDetail> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/triage/models/${modelName}/fields_to_predict_config`,
    body,
    'POST',
  );
};

export const getTriageModelDemoPrediction = async (
  modelName: string,
  body: TriageTestPredictionRequest,
): Promise<{ predictions: TriageTestPredictionResult[] }> => {
  return sendAuthRequest(
    `${API_URL}ssel/triage/models/${modelName}/demo-prediction`,
    body,
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getHelpdeskApi = async (): Promise<HelpdeskResponse> => {
  return sendAuthRequest(
    `${API_URL}ssel/triage/helpdesk-integrations`,
    {},
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getPredictedFieldsApi = async (
  dataSource: string,
): Promise<TriageTicketFieldsResponse> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/triage/models/data_source/${dataSource}/ticket_fields`,
    {},
    'GET',
  );
};

export const upgradeButtonClickedApi = async (): Promise<void> => {
  return sendAuthRequest(
    `${API_URL}ssel/triage/upgrade-plan-button-clicked`,
    {},
    'GET',
  ).then(normalizeResponseErrors);
};

export const emitTrackingEventApi = async ({
  eventType,
  sessionId,
  userRole,
  ...rest
}: EmitTrackingEventArgs): Promise<{ success: boolean }> => {
  return sendAuthRequest(
    `${API_URL}tracking-event`,
    {
      event_type: eventType,
      product: 'dashboard',
      session_id: sessionId,
      user_role: userRole,
      ...rest,
    },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json())
    .catch(console.error);
};

// embeddable dashboards

export const getEmbeddableDashboardsSettings =
  async (): Promise<EmbeddableSettings> => {
    return sendAuthRequest(`${API_URL}embed/settings`, {}, 'GET')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getEmbeddableDashboardURL = async (
  dashboardID: number,
): Promise<SolveEmbeddedDashboardResponse> => {
  return sendAuthRequest(`${API_URL}embed/dashboard/${dashboardID}`, {}, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getLookerDashboardSettings = async (): Promise<
  EmbeddableLookerDashboard[]
> => {
  return sendAuthRequest(`${API_URL}looker-embed/settings`, {}, 'GET')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getLookerDashboardURL = async (
  dashboardId: string,
  queryTimezone: string,
): Promise<string> => {
  return sendAuthRequest(
    `${API_URL}looker-embed/dashboard/${dashboardId}?query_timezone=${encodeURIComponent(
      queryTimezone,
    )}`,
    {},
    'GET',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const selfSignUp: SelfSignUpRequest = async (
  parameters,
): Promise<void> => {
  const url = `${API_URL}ssel/sign-up/`;
  const method = 'POST';
  const config = {
    body: JSON.stringify(parameters),
    headers: {
      'Content-Type': 'application/json',
    },
    method: method,
  };
  const res = await fetch(url, config);
  if (res.status !== 201) {
    throw Error('Error signing you up');
  }
};
