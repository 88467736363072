import React from 'react';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { StyledComponent } from '@emotion/styled';
import { Box, styled } from '@mui/material';

import {
  Button,
  IconButton,
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import { FilterQueryComponent } from './FilterQueryComponent';
import pullAt from 'lodash/fp/pullAt';
import set from 'lodash/fp/set';
import plusIcon from 'src/assets/images/btn-plus.svg';
import deleteIcon from 'src/assets/images/delete-icon.svg';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  ArticleSource,
  ArticleSourceOption,
  ArticleSuggestionFilterQuery,
} from 'src/types/workflowBuilderAPITypes';

interface ArticleSourceComponentProp {
  areErrorsVisible: boolean;
  articleSource: ArticleSource;
  articleSourceIndex: number;
  availableArticleSources: ArticleSourceOption[];
  handleDeleteArticleSource: (articleSourceIndex: number) => void;
  handleUpdateArticleSource: (
    articleSourceIndex: number,
    updatedArticleSource: ArticleSource,
  ) => void;
  isDeleteDisabled: boolean;
}

export const ArticleSourceComponent: FC<
  React.PropsWithChildren<ArticleSourceComponentProp>
> = ({
  areErrorsVisible,
  articleSource,
  articleSourceIndex,
  availableArticleSources,
  handleDeleteArticleSource,
  handleUpdateArticleSource,
  isDeleteDisabled,
}) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const solveMultiConnectorUi = featureFlags.includes(
    'solve_multi_connector_ui',
  );
  const availableArticleFields =
    availableArticleSources.find(articleSourceOption =>
      articleSource.connector_id
        ? articleSourceOption.connector_id === articleSource.connector_id
        : articleSourceOption.doc_type === articleSource.doc_type,
    )?.available_article_fields || [];
  const availableArticleFieldsOption = availableArticleFields.map(field => ({
    label: field.field_name,
    value: field.field_name,
  }));

  const articleSourceOptions = availableArticleSources.map(
    ({ connector_display_name, connector_id, doc_type, emblem_url }) => ({
      label: solveMultiConnectorUi ? connector_display_name : doc_type,
      value: articleSource.connector_id ? connector_id : doc_type,
      ...(solveMultiConnectorUi && {
        optionStartAdornment: (
          <Box component='img' height='20px' src={emblem_url} width='20px' />
        ),
      }),
    }),
  );

  const emblem_url =
    availableArticleSources.find(articleSourceOption =>
      articleSource.connector_id
        ? articleSourceOption.connector_id === articleSource.connector_id
        : articleSourceOption.doc_type === articleSource.doc_type,
    )?.emblem_url || '';

  const startAdornmentProps = solveMultiConnectorUi
    ? {
        startAdornment: (
          <Box
            component='img'
            height='20px'
            marginRight='5px'
            src={emblem_url}
            width='20px'
          />
        ),
      }
    : {};

  const handleAddFilterQuery = (articleSourceIndex: number) => {
    handleUpdateArticleSource(articleSourceIndex, {
      ...articleSource,
      filter_queries: [
        ...articleSource.filter_queries,
        {
          boolean_operator: 'and',
          field: null,
          operator: 'is',
          value: null,
        },
      ],
    });
  };

  const handleUpdateConnectorIdAndDocType = (value: string) => {
    const selectedSource = availableArticleSources.find(
      source => source.connector_id === value || source.doc_type === value,
    );
    handleUpdateArticleSource(articleSourceIndex, {
      ...articleSource,
      connector_id: selectedSource?.connector_id || '',
      doc_type: selectedSource?.doc_type || '',
    });
  };

  const handleUpdateFilterQuery = (
    filterQueryIndex: number,
    updatedQuery: ArticleSuggestionFilterQuery,
  ) => {
    const updatedArticleSource = set(
      ['filter_queries', filterQueryIndex],
      updatedQuery,
      articleSource,
    );
    handleUpdateArticleSource(articleSourceIndex, updatedArticleSource);
  };

  const handleUpdateFilterQueryBooleanOperator = (booleanOperator: string) => {
    const updatedFilterQueries = articleSource.filter_queries.map(
      filterQuery => ({
        ...filterQuery,
        boolean_operator: booleanOperator,
      }),
    );

    handleUpdateArticleSource(articleSourceIndex, {
      ...articleSource,
      filter_queries: updatedFilterQueries,
    });
  };

  const handleDeleteFilterQuery = (filterQueryIndex: number) => {
    handleUpdateArticleSource(articleSourceIndex, {
      ...articleSource,
      filter_queries: pullAt(filterQueryIndex, articleSource.filter_queries),
    });
  };

  return (
    <Box display='flex' flexDirection={'column'} gap={1}>
      <Box display='flex' gap={1}>
        <SelectDropdown
          error={
            areErrorsVisible &&
            (!articleSource.doc_type ||
              articleSource.doc_type?.trim() === '') &&
            'Article source cannot be empty'
          }
          id={`selectArticleSource${articleSourceIndex + 1}`}
          onChange={e => handleUpdateConnectorIdAndDocType(e.target.value)}
          options={articleSourceOptions}
          placeholder='Select an article source...'
          value={articleSource.connector_id || articleSource.doc_type || ''}
          {...startAdornmentProps}
        />
        <IconButton
          aria-label={`delete article source ${articleSourceIndex + 1}`}
          disabled={isDeleteDisabled}
          onClick={() => handleDeleteArticleSource(articleSourceIndex)}
          variant='ghost'
        >
          <DeleteIcon disabled={isDeleteDisabled} src={deleteIcon} />
        </IconButton>
      </Box>
      {articleSource.filter_queries.map((filterQuery, filterQueryIndex) => {
        return (
          <FilterQueryComponent
            areErrorsVisible={areErrorsVisible}
            articleSourceIndex={articleSourceIndex}
            availableArticleFields={availableArticleFields}
            availableArticleFieldsOption={availableArticleFieldsOption}
            docType={articleSource.doc_type || ''}
            filterQuery={filterQuery}
            filterQueryIndex={filterQueryIndex}
            handleDeleteFilterQuery={handleDeleteFilterQuery}
            handleUpdateFilterQuery={handleUpdateFilterQuery}
            handleUpdateFilterQueryBooleanOperator={
              handleUpdateFilterQueryBooleanOperator
            }
            isBooleanOperatorColumnVisible={
              articleSource.filter_queries.length > 1
            }
            key={filterQueryIndex}
          />
        );
      })}
      <Box alignItems='flex-start' display='flex'>
        <Button
          aria-label={`add composite logic for article source ${
            articleSourceIndex + 1
          }`}
          onClick={() => {
            handleAddFilterQuery(articleSourceIndex);
          }}
          startIcon={<PurpleIcon src={plusIcon} />}
          variant='ghost'
        >
          Add composite logic
        </Button>
      </Box>
    </Box>
  );
};

const DeleteIcon = styled(ReactSVG)<{ disabled: boolean }>`
  height: 20px;
  width: 20px;
  [fill] {
    fill: ${props =>
      props.disabled
        ? theme.palette.colors.grey[400]
        : theme.palette.colors.purple[500]};
  }
  [stroke] {
    stroke: ${props =>
      props.disabled
        ? theme.palette.colors.grey[400]
        : theme.palette.colors.purple[500]};
  }
`;

export const PurpleIcon: StyledComponent<
  // eslint-disable-next-line @typescript-eslint/ban-types -- {} is used by default
  {},
  // eslint-disable-next-line @typescript-eslint/ban-types -- {} is used by default
  {},
  { src: string }
> = styled(ReactSVG)`
  height: 20px;
  width: 20px;
  [fill] {
    fill: ${theme.palette.colors.purple[500]};
  }
  [stroke] {
    stroke: ${theme.palette.colors.purple[500]};
  }
`;
