import { ALL_INTENTS, CSAT_RATINGS_KEY } from '../constants';
import { getKeyValueFromFilterQuery } from 'src/components/dashboard-pages/solve-insights/helpers';
import { conversationChannelToProductMap } from 'src/constants/solve';
import { useSolveMetricsQueryParams } from 'src/pages/workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import {
  useGetConversationsByIntentIdQuery,
  useGetConversationsQuery,
} from 'src/services/solve-conversations';

export const buildQueryFromMultiSelected = (
  multiSelected: string[],
  allowBoolean?: boolean,
) => {
  const query: Record<string, string[]> = {};

  const transformForBackend = (
    query: Record<string, string[]>,
  ): Record<string, string> => {
    const beQuery: Record<string, string> = {};
    for (const [key, value] of Object.entries(query)) {
      const transformedValue = value.join(',');
      const isCsatRating = key.includes(CSAT_RATINGS_KEY);

      if (isCsatRating) {
        beQuery[CSAT_RATINGS_KEY] = transformedValue;
      } else if (allowBoolean) {
        beQuery[key] = transformedValue;
      } else if (
        // Boolean values are exclusive. So if user selected both boolean options,
        // strip them from request.
        transformedValue !== 'true,false' &&
        transformedValue !== 'false,true'
      ) {
        beQuery[key] = value.join(',');
      }
    }
    return beQuery;
  };

  for (const item of multiSelected) {
    const { key, value } = getKeyValueFromFilterQuery({ filterString: item });
    const items = query[key];

    if (items) {
      items.push(value);
    } else {
      query[key] = [value];
    }
  }

  return transformForBackend(query);
};

interface UseConversationsParams {
  autonomousAgentUsed: boolean | null;
  conversationId: string | null;
  endDate: string;
  intentId: string;
  multiSelected: string[];
  page: number;
  sortColumn: string;
  sortDirection: string;
  startDate: string;
}

export default function useConversations({
  autonomousAgentUsed,
  conversationId,
  endDate,
  intentId,
  multiSelected,
  page,
  sortColumn,
  sortDirection,
  startDate,
}: UseConversationsParams) {
  const filterByIntent = intentId !== ALL_INTENTS;
  const { channel } = useSolveMetricsQueryParams();

  const baseQuery = {
    autonomous_agent_used: autonomousAgentUsed,
    conversation_id: conversationId,
    end: endDate,
    page: page,
    product: conversationChannelToProductMap[channel],
    sort_column: sortColumn,
    sort_direction: sortDirection,
    start: startDate,
    ...buildQueryFromMultiSelected(multiSelected),
  };

  const filteredConversationsResponse = useGetConversationsByIntentIdQuery(
    {
      ...baseQuery,
      intentId,
    },
    {
      skip: !filterByIntent || Boolean(multiSelected.length),
    },
  );

  const allConversationsResponse = useGetConversationsQuery(baseQuery, {
    skip: filterByIntent,
  });

  return filterByIntent
    ? filteredConversationsResponse
    : allConversationsResponse;
}
