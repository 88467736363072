import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import { NavigateFunction } from 'react-router';
import { Box, Palette, useTheme } from '@mui/material';
import { IconBulb } from '@tabler/icons-react';

import {
  Button,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { numbersToStringWithCommas, numberToCurrency } from '../helpers';
import { useGetIsDiscoverEnabledInInsights } from '../hooks/useGetIsDiscoverEnabledInInsights';
import useTabs from '../hooks/useTabs';
import { useIntentToDeflectionInsightMap } from './hook';
import ArrowToRightIcon from 'src/assets/images/arrow-to-right.svg';
import NavigationArrowIcon from 'src/assets/images/navigation-arrow.svg';
import { TEMP_BLUE } from 'src/constants/solve';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import {
  formatCSAT,
  getWorkflowBuilderUrl,
  openTab,
} from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { BreakdownPaletteTooltip } from 'src/pages/workflow-builder/BreakdownPaletteTooltip';
import { HorizontalStackedBar } from 'src/pages/workflow-builder/intent-workflows-table/HorizontalStackedBar';
import {
  DropoffBreakdown,
  RelevanceBreakdown,
} from 'src/services/apiInterfaces';
import { useGetTopicCardQuery } from 'src/services/insights';
import {
  InsightCardType,
  TimestampRequest,
  TopicCardInsightResponse,
  TopicCardWorstWorkflowResponse,
} from 'src/services/insights/types';
import { Routes } from 'src/utils/enums';
import { calculateSum } from 'src/utils/solve/deflectionInsightsUtils';
interface ChatPerformanceTableSectionProps {
  timeStamps: TimestampRequest;
}

const ChatPerformanceTableSection = ({
  timeStamps,
}: ChatPerformanceTableSectionProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isDiscoverEnabled = useGetIsDiscoverEnabledInInsights();
  const { tabIdToIndexMap } = useTabs();

  const [selectedCardIdx, setSelectedCardIdx] = useState(0);

  const handleCardOptions = () => {
    const options = ['worst_performing_workflows'];
    if (isDiscoverEnabled) {
      options.push('cost-inefficient topics');
      options.push('unhappy topics');
    }
    return options;
  };
  const cardOptions = handleCardOptions();
  const showNavigation = cardOptions.length > 1;
  const selectedCardType = cardOptions[selectedCardIdx] as InsightCardType;

  const { data, isFetching, isLoading } = useGetTopicCardQuery(
    {
      card_type: selectedCardType,
      end: timeStamps.end_timestamp,
      start: timeStamps.start_timestamp,
    },
    { skip: false },
  );

  const handleTitle = (selectedCardType: InsightCardType) => {
    if (selectedCardType === 'worst_performing_workflows') {
      return 'Worst performing workflow';
    }
    if (selectedCardType === 'cost-inefficient topics') {
      return 'Most cost-inefficient conversation topics';
    }
    if (selectedCardType === 'unhappy topics') {
      return 'Most unhappy topics';
    }
    return '';
  };

  const handleDescription = (selectedCardType: InsightCardType) => {
    if (selectedCardType === 'worst_performing_workflows') {
      return 'We identified the top three workflows with issues. Reviewing where customers frequently drop off and enhancing the workflows can improve both self service rates and customer satisfaction!';
    }
    if (selectedCardType === 'cost-inefficient topics') {
      return 'We found 3 topics with issues. Adding automation or knowledge articles on these conversation topics can increase your savings!';
    }
    if (selectedCardType === 'unhappy topics') {
      return 'Here are topics with lowest CSAT score. Adding automation or knowledge articles on these conversation topics can improve your CSAT!';
    }
    return '';
  };

  const handleViewMoreOnClick = (selectedCardType: InsightCardType) => {
    if (selectedCardType === 'worst_performing_workflows') {
      navigate({
        pathname: Routes.SOLVE_INSIGHTS_MAIN,
        search: `?tab=${tabIdToIndexMap.workflow}&workflow_sort_column=non_deflected_count`,
      });
    }
    if (selectedCardType === 'cost-inefficient topics') {
      navigate({
        pathname: Routes.SOLVE_INSIGHTS_MAIN,
        search: `?tab=${tabIdToIndexMap.topic}&topics_sort_column=potential_saving&is_grouped=false`,
      });
    }
    if (selectedCardType === 'unhappy topics') {
      navigate({
        pathname: Routes.SOLVE_INSIGHTS_MAIN,
        search: `?tab=${tabIdToIndexMap.topic}&topics_sort_column=csat&topic_sort_direction=ASC&is_grouped=false`,
      });
    }

    return;
  };

  return (
    <Box display='flex'>
      <Box
        border={`1px solid ${palette.colors.brand.magenta}`}
        borderRadius='8px'
        display='flex'
        flexDirection='column'
        padding='24px'
        width='100%'
      >
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          paddingBottom='14px'
          width='100%'
        >
          <Box alignItems='center' display='flex' gap={1}>
            <IconBulb color={palette.colors.purple[500]} size='20px' />
            <Typography variant='font20'>
              {handleTitle(selectedCardType)}
            </Typography>
          </Box>
          <Button
            onClick={() => handleViewMoreOnClick(selectedCardType)}
            size='medium'
            variant='ghost'
          >
            <Box alignItems='center' display='flex'>
              <Typography noWrap variant='font14Medium'>
                View more
              </Typography>
              <img src={ArrowToRightIcon} />
            </Box>
          </Button>
        </Box>
        <Typography color={palette.colors.grey[600]} variant='font16'>
          {handleDescription(selectedCardType)}
        </Typography>
        {/* Data content */}
        <ChatPerformanceTable
          isLoading={isLoading || isFetching}
          navigate={navigate}
          palette={palette}
          selectedCardType={selectedCardType}
          tableData={data}
          timeStamps={timeStamps}
        />
        {/* Navigation */}
        {showNavigation && (
          <Box
            alignItems='center'
            display='flex'
            gap={1}
            justifyContent='space-between'
          >
            <Button
              onClick={() => {
                const nextSelectedCardIdx = selectedCardIdx - 1;
                if (nextSelectedCardIdx >= 0) {
                  setSelectedCardIdx(nextSelectedCardIdx);
                }
              }}
              startIcon={<img src={NavigationArrowIcon} />}
              variant='main'
            />
            {cardOptions.map(option => {
              return (
                <Box
                  bgcolor={
                    option === selectedCardType
                      ? palette.colors.purple[500]
                      : palette.colors.purple[200]
                  }
                  borderRadius='3px'
                  flex={1}
                  height='4px'
                  key={option}
                />
              );
            })}
            <Button
              onClick={() => {
                const nextSelectedCardIdx = selectedCardIdx + 1;
                if (nextSelectedCardIdx < cardOptions.length) {
                  setSelectedCardIdx(nextSelectedCardIdx);
                }
              }}
              startIcon={
                <img
                  src={NavigationArrowIcon}
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
              variant='main'
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface ChatPerformanceTableProps {
  isLoading: boolean;
  navigate: NavigateFunction;
  palette: Palette;
  selectedCardType: InsightCardType;
  tableData?: TopicCardInsightResponse | TopicCardWorstWorkflowResponse;
  timeStamps: TimestampRequest;
}

const ChatPerformanceTable = ({
  isLoading,
  navigate,
  palette,
  selectedCardType,
  tableData,
  timeStamps,
}: ChatPerformanceTableProps) => {
  const getCSATColor = useGetCSATColor();
  const {
    isLoading: isLoadingDeflectionsData,
    result: intentToDeflectionInsightsMapping,
  } = useIntentToDeflectionInsightMap(
    timeStamps.start_timestamp.toString(),
    timeStamps.end_timestamp.toString(),
  );
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  if (isLoading || isLoadingDeflectionsData) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        gap='8px'
        padding='16px'
        width='100%'
      >
        <Box display='flex' gap='16px' justifyContent='space-between'>
          <Skeleton height='20px' width='250px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
        </Box>
        <Box display='flex' gap='16px' justifyContent='space-between'>
          <Skeleton height='20px' width='250px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
        </Box>
        <Box display='flex' gap='16px' justifyContent='space-between'>
          <Skeleton height='20px' width='250px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
        </Box>
        <Box display='flex' gap='16px' justifyContent='space-between'>
          <Skeleton height='20px' width='250px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='100px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
          <Skeleton height='20px' width='200px'></Skeleton>
        </Box>
      </Box>
    );
  }

  if (selectedCardType === 'worst_performing_workflows') {
    const dataTyped = tableData as TopicCardWorstWorkflowResponse;
    return (
      <Box
        display='flex'
        justifyContent='space-between'
        pb='30px'
        pt='30px'
        width='100%'
      >
        {/* Workflow */}
        <Box display='flex'>
          <Box display='flex' flexDirection='column' gap='12px'>
            <Box alignItems='center' display='flex' height='32px'>
              <Typography color={palette.colors.grey[700]} variant='font14Bold'>
                Workflow
              </Typography>
            </Box>
            {dataTyped?.worst_workflows?.map((workflow, idx) => {
              return (
                <Box
                  alignItems='center'
                  display='flex'
                  height='32px'
                  key={idx}
                  onClick={() => {
                    navigate(
                      Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
                        ':workflowId',
                        workflow.intent_workflow_id,
                      ),
                    );
                  }}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    color={palette.colors.purple[500]}
                    key={idx}
                    variant='font14Medium'
                  >
                    {workflow.intent_title ?? workflow.intent_definition_id}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        {/* Non-deflections */}
        <Box display='flex'>
          <Box display='flex' flexDirection='column' gap='12px'>
            <Box alignItems='center' display='flex' height='32px'>
              <Typography color={palette.colors.grey[700]} variant='font14Bold'>
                Non-deflections
              </Typography>
            </Box>
            {dataTyped?.worst_workflows?.map((workflow, idx) => {
              return (
                <Box
                  alignItems='center'
                  display='flex'
                  gap='2px'
                  height='32px'
                  key={idx}
                >
                  <Typography variant='font14Medium'>
                    {numbersToStringWithCommas({
                      number: workflow.non_deflected_count,
                    })}
                  </Typography>
                  <Typography
                    color={palette.colors.grey[600]}
                    variant='font14Medium'
                  >
                    {`(${numbersToStringWithCommas({
                      number: workflow.non_deflected_rate,
                      style: 'percent',
                    })})`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        {/* Avg. CSAT */}
        <Box display='flex'>
          <Box display='flex' flexDirection='column' gap='12px'>
            <Box alignItems='center' display='flex' height='32px'>
              <Typography color={palette.colors.grey[700]} variant='font14Bold'>
                Avg. CSAT
              </Typography>
            </Box>
            {dataTyped?.worst_workflows?.map((workflow, idx) => {
              const csatScore =
                workflow.avg_csat === 0 ? NA : formatCSAT(workflow.avg_csat);
              return (
                <Box alignItems='center' display='flex' height='32px' key={idx}>
                  <Typography
                    color={
                      csatScore === NA
                        ? palette.colors.grey[600]
                        : getCSATColor(Number(csatScore))
                    }
                    variant='font14Medium'
                  >
                    {csatScore}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        {/* Potential saving */}
        {/* <Box display='flex'>
          <Box display='flex' flexDirection='column' gap='12px'>
            <Box alignItems='center' display='flex' height='32px'>
              <Typography color={palette.colors.grey[700]} variant='font14Bold'>
                Potential savings
              </Typography>
            </Box>
            {dataTyped?.worst_workflows?.map((workflow, idx) => {
              return (
                <Box alignItems='center' display='flex' height='32px' key={idx}>
                  <Typography variant='font14Medium'>
                    {workflow.potential_saving
                      ? numberToCurrency({
                          number: workflow.potential_saving,
                          shouldRound: true,
                        })
                      : NA}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box> */}
        {isDeflectionInsightsEnabled && (
          <Box display='flex'>
            <Box
              display='flex'
              flexDirection='column'
              gap='12px'
              minWidth='120px'
            >
              <Box alignItems='center' display='flex' height='32px'>
                <Typography
                  color={palette.colors.grey[700]}
                  variant='font14Bold'
                >
                  Relevance
                </Typography>
              </Box>
              {dataTyped?.worst_workflows?.map((workflow, idx) => {
                const breakdown =
                  intentToDeflectionInsightsMapping[
                    workflow.intent_definition_id
                  ];
                return (
                  <Box
                    alignItems='center'
                    display='flex'
                    height='32px'
                    key={idx}
                    paddingTop='22px'
                    width='100%'
                  >
                    <RelevanceBar
                      relevanceBreakdown={breakdown?.relevance_breakdown}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        {isDeflectionInsightsEnabled && (
          <Box display='flex'>
            <Box
              display='flex'
              flexDirection='column'
              gap='12px'
              minWidth='120px'
            >
              <Box alignItems='center' display='flex' height='32px'>
                <Typography
                  color={palette.colors.grey[700]}
                  variant='font14Bold'
                >
                  User Engagement
                </Typography>
              </Box>
              {dataTyped?.worst_workflows?.map((workflow, idx) => {
                const breakdown =
                  intentToDeflectionInsightsMapping[
                    workflow.intent_definition_id
                  ];
                return (
                  <Box
                    alignItems='center'
                    display='flex'
                    height='32px'
                    key={idx}
                    paddingTop='22px'
                    width='100%'
                  >
                    <DropoffBar
                      dropoffBreakdown={breakdown?.dropoff_breakdown}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        {/* Action */}
        <Box display='flex'>
          <Box display='flex' flexDirection='column' gap='12px'>
            <Box height='32px' />
            {dataTyped?.worst_workflows?.map((workflow, idx) => {
              if (workflow.intent_workflow_id) {
                return (
                  <Box alignItems='center' display='flex' key={idx}>
                    <Button
                      fullWidth
                      onClick={() => {
                        const url = getWorkflowBuilderUrl({
                          channel: 'widget',
                          intentDefinitionId: workflow.intent_definition_id,
                          intentWorkflowId: workflow.intent_workflow_id,
                          mode: workflow.is_autoflow ? 'autoflow' : 'classic',
                        });
                        openTab(url);
                      }}
                      size='medium'
                      variant='secondary'
                    >
                      <Typography variant='font14Bold'>
                        View workflow
                      </Typography>
                    </Button>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    );
  }

  const dataTyped = tableData as TopicCardInsightResponse;
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      pb='30px'
      pt='30px'
      width='100%'
    >
      {/* Workflow */}
      <Box display='flex'>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Box alignItems='center' display='flex' height='32px'>
            <Typography color={palette.colors.grey[700]} variant='font14Bold'>
              Topic
            </Typography>
          </Box>
          {dataTyped?.top_topics?.map((topic, idx) => {
            return (
              <Box
                alignItems='center'
                display='flex'
                height='32px'
                key={idx}
                onClick={() => {
                  navigate(
                    Routes.SOLVE_INSIGHTS_TOPIC_DETAIL.replace(
                      ':topicId',
                      topic.cluster_id,
                    ),
                  );
                }}
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  cursor: 'pointer',
                }}
              >
                <Typography
                  color={palette.colors.purple[500]}
                  key={idx}
                  variant='font14Medium'
                >
                  {topic.cluster_name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* CSAT */}
      <Box display='flex'>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Box alignItems='center' display='flex' height='32px'>
            <Typography color={palette.colors.grey[700]} variant='font14Bold'>
              CSAT
            </Typography>
          </Box>
          {dataTyped?.top_topics?.map((topic, idx) => {
            const csatScore = topic.csat === 0 ? NA : formatCSAT(topic.csat);
            return (
              <Box alignItems='center' display='flex' height='32px' key={idx}>
                <Typography
                  color={
                    csatScore === NA
                      ? palette.colors.grey[600]
                      : getCSATColor(Number(csatScore))
                  }
                  variant='font14Medium'
                >
                  {csatScore}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* Non-deflections */}
      <Box display='flex'>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Box alignItems='center' display='flex' height='32px'>
            <Typography color={palette.colors.grey[700]} variant='font14Bold'>
              Non-deflections
            </Typography>
          </Box>
          {dataTyped?.top_topics?.map((topic, idx) => {
            return (
              <Box
                alignItems='center'
                display='flex'
                gap='2px'
                height='32px'
                key={idx}
              >
                <Typography variant='font14Medium'>
                  {numbersToStringWithCommas({
                    number: topic.non_deflected_chats_count,
                  })}
                </Typography>
                <Typography
                  color={palette.colors.grey[600]}
                  variant='font14Medium'
                >
                  {`(${numbersToStringWithCommas({
                    number: topic.non_deflected_rate,
                    style: 'percent',
                  })})`}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* Potential saving */}
      <Box display='flex'>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Box alignItems='center' display='flex' height='32px'>
            <Typography color={palette.colors.grey[700]} variant='font14Bold'>
              Top surfaced knowledge articles
            </Typography>
          </Box>
          {dataTyped?.top_topics?.map((topic, idx) => {
            const topSurfacedArticle = topic.top_surfaced_article;
            if (topSurfacedArticle?.article_id) {
              return (
                <Box
                  alignItems='center'
                  display='flex'
                  height='32px'
                  key={idx}
                  onClick={() => {
                    navigate(
                      Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL.replace(
                        ':articleId',
                        topSurfacedArticle.article_id,
                      ),
                    );
                  }}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    color={palette.colors.purple[500]}
                    maxWidth='500px'
                    noWrap
                    variant='font14Medium'
                  >
                    {topSurfacedArticle?.article_title ?? NA}
                  </Typography>
                </Box>
              );
            }
            return (
              <Box alignItems='center' display='flex' height='32px' key={idx}>
                <Typography
                  color={palette.colors.grey[600]}
                  variant='font14Medium'
                >
                  {topSurfacedArticle?.article_title ?? NA}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* Action */}
      <Box display='flex'>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Box alignItems='center' display='flex' height='32px'>
            <Typography color={palette.colors.grey[700]} variant='font14Bold'>
              Potential savings
            </Typography>
          </Box>
          {dataTyped?.top_topics?.map((topic, idx) => {
            return (
              <Box alignItems='center' display='flex' height='32px' key={idx}>
                <Typography variant='font14Medium'>
                  {topic.potential_saving
                    ? numberToCurrency({
                        number: topic.potential_saving,
                        shouldRound: true,
                      })
                    : NA}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const DropoffBar = ({
  dropoffBreakdown,
}: {
  dropoffBreakdown?: DropoffBreakdown;
}) => {
  const { palette } = useTheme();

  if (
    !dropoffBreakdown ||
    calculateSum(Object.values(dropoffBreakdown)) === 0
  ) {
    return (
      <Typography color={palette.colors.grey[500]} variant='font14Medium'>
        {NA}
      </Typography>
    );
  }

  return (
    <Tooltip
      fullWidth
      maxWidth={320}
      tooltipContent={
        <BreakdownPaletteTooltip
          values={[
            {
              color: TEMP_BLUE,
              label: 'Yes',
              value: dropoffBreakdown.no_dropoff_count,
            },
            {
              color: palette.colors.red[500],
              label: 'No',
              value: dropoffBreakdown.dropoff_count,
            },
          ]}
        />
      }
    >
      <HorizontalStackedBar
        data={[
          {
            color: TEMP_BLUE,
            value: dropoffBreakdown.no_dropoff_count,
          },
          {
            color: palette.colors.red[500],
            value: dropoffBreakdown.dropoff_count,
          },
        ]}
      />
    </Tooltip>
  );
};

const RelevanceBar = ({
  relevanceBreakdown,
}: {
  relevanceBreakdown?: RelevanceBreakdown;
}) => {
  const { palette } = useTheme();

  if (
    !relevanceBreakdown ||
    calculateSum(Object.values(relevanceBreakdown)) === 0
  ) {
    return (
      <Typography color={palette.colors.grey[500]} variant='font14Medium'>
        {NA}
      </Typography>
    );
  }
  return (
    <Tooltip
      fullWidth
      maxWidth={320}
      tooltipContent={
        <BreakdownPaletteTooltip
          values={[
            {
              color: TEMP_BLUE,
              label: 'Relevant',

              value: relevanceBreakdown.relevant_count,
            },
            {
              color: palette.colors.blue[400],
              label: 'Somewhat relevant',
              value: relevanceBreakdown.somewhat_relevant_count,
            },
            {
              color: palette.colors.red[500],
              label: 'Irrelevant',
              value: relevanceBreakdown.irrelevant_count,
            },
          ]}
        />
      }
    >
      <HorizontalStackedBar
        data={[
          {
            color: TEMP_BLUE,
            value: relevanceBreakdown.relevant_count,
          },
          {
            color: palette.colors.blue[400],
            value: relevanceBreakdown.somewhat_relevant_count,
          },
          {
            color: palette.colors.red[500],
            value: relevanceBreakdown.irrelevant_count,
          },
        ]}
      />
    </Tooltip>
  );
};

export default ChatPerformanceTableSection;
