import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ToneOfVoiceLabel } from '../tone-tab-content';
import { DEFAULT_TONE_OF_VOICE_INPUT } from '../tone-tab-content/constants';
import useToneOfVoiceConfig from '../tone-tab-content/useToneOfVoiceConfig';
import { useToneOfVoiceTestQuery } from 'src/services/solve-config/solveConfigApi';
import { selectIsSaving } from 'src/slices/solve-config/solveConfigSlice';

const TonePreviewContent = () => {
  const isSaving = useSelector(selectIsSaving);
  const { toneOfVoiceConfig, toneOfVoiceStatus } = useToneOfVoiceConfig({
    isPlaybook: true,
  });
  const { data, isFetching, isLoading } = useToneOfVoiceTestQuery(
    {
      input: DEFAULT_TONE_OF_VOICE_INPUT,
    },
    { skip: toneOfVoiceStatus === 'default' },
  );
  const loading = isLoading || isFetching || isSaving;

  if (loading) {
    return (
      <Box display='flex' justifyContent='center'>
        <Box display='flex' flexDirection='column' gap={0.5} width='414px'>
          <Skeleton />
          <Skeleton width='75%' />
        </Box>
      </Box>
    );
  }

  return (
    <Box display='flex' justifyContent='center'>
      <Box display='flex' flexDirection='column' gap={2.25} width='414px'>
        <Typography variant='font14Bold'>
          <ToneOfVoiceLabel
            predefinedTones={toneOfVoiceConfig?.predefined_tones}
            toneOfVoiceStatus={toneOfVoiceStatus}
          />
        </Typography>
        <Typography variant='font14'>
          {data?.response && toneOfVoiceStatus !== 'default'
            ? data.response
            : DEFAULT_TONE_OF_VOICE_INPUT}
        </Typography>
      </Box>
    </Box>
  );
};

export default TonePreviewContent;
