import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  SlowVoiceConversationRequestBody,
  SlowVoiceConversationResponse,
} from 'src/types/latencyTypes';

const latencyApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getSlowVoiceConversations: builder.query<
      SlowVoiceConversationResponse,
      SlowVoiceConversationRequestBody
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.conversations = [...newItems.conversations];
        } else {
          currentCache.conversations = [
            ...currentCache.conversations,
            ...newItems.conversations,
          ];
        }
      },
      query: ({ page, page_size, sort_column, sort_direction }) => {
        const qParams = new URLSearchParams({
          page: page.toString(),
          page_size: page_size ?? '100',
          sort_column,
          sort_direction,
        });

        return (
          SOLVE_V2_BASE_URL +
          '/workflow-builder/voice/slow-conversation' +
          '?' +
          qParams
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page_size, sort_column, sort_direction } = queryArgs;
        const qParams = new URLSearchParams({
          page_size: page_size ?? '100',
          sort_column,
          sort_direction,
        });
        return endpointName + '&' + qParams;
      },
    }),
  }),
});

export const { useGetSlowVoiceConversationsQuery } = latencyApi;
