import Box from '@mui/material/Box';
import { RTVIClientAudio } from '@pipecat-ai/client-react';

import { VoiceInfoConsole } from './info-console/VoiceInfoConsole';
import { ControlButtons } from './ControlButtons';
import { StatusDisplay } from './StatusDisplay';

export const PreviewChatBot = () => {
  return (
    <Box display='flex' gap='16px' height='100%' width='100%'>
      <Box
        alignItems='center'
        display='flex'
        flex={1}
        flexDirection='column'
        gap='32px'
        justifyContent='center'
        padding='40px 32px'
      >
        <StatusDisplay />
        <ControlButtons />
      </Box>

      <VoiceInfoConsole />
      <RTVIClientAudio />
    </Box>
  );
};
