import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  ConversationIDMessage,
  CreateZendeskTicketMessage,
  GenericToolMessage,
  IntentPredictionMessage,
  IntentRoutingMessage,
  SearchHelpCenterArticlesMessage,
} from './PreviewExecutionMessage';
import {
  ConversationStartedPayload,
  CreateZendeskTicketPayload,
  GenericToolPayload,
  IntentPredictionPayload,
  IntentRoutingPayload,
  SearchHelpCenterArticlesPayload,
} from './type';
import { AgathaMessageContainer } from 'src/pages/intent-conversation-analytics/ConversationTranscript/StyledComponents';
import { PreviewVoiceTranscript } from 'src/slices/workflow-preview/types';

export const PreviewTextBubble = ({
  transcript,
}: {
  transcript: PreviewVoiceTranscript;
}) => {
  return (
    <AgathaMessageContainer
      alignSelf={transcript.type === 'user' ? 'flex-end' : 'flex-start'}
      backgroundColor={transcript.type === 'user' ? 'black' : 'white'}
      maxWidth='90%'
      minHeight='fit-content'
    >
      <Typography
        color={transcript.type === 'user' ? 'white' : 'black'}
        variant='font16'
      >
        {transcript.text}
      </Typography>
    </AgathaMessageContainer>
  );
};

export const PreviewExecutionMessage = ({ text }: { text: string }) => {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.palette.colors.grey[900]}
      border={`1px solid ${theme.palette.colors.grey[700]}`}
      borderRadius='12px'
      fontFamily='Fira Code !important'
      padding='8px'
    >
      <Typography color={'white'} variant='font14'>
        {text}
      </Typography>
    </Box>
  );
};

export const PreviewTranscriptComponent = ({
  transcript,
}: {
  transcript: PreviewVoiceTranscript;
}) => {
  if (transcript.type === 'bot' || transcript.type === 'user') {
    if (!transcript.text) {
      return null;
    }

    return <PreviewTextBubble transcript={transcript} />;
  }

  if (transcript.type === 'preview-log') {
    const payload = transcript.payload;
    const payloadType = transcript.payload?.type;

    switch (payloadType) {
      case 'conversation_started':
        return (
          <ConversationIDMessage
            payload={payload as ConversationStartedPayload}
          />
        );
      case 'intent_prediction':
        return (
          <IntentPredictionMessage
            payload={payload as IntentPredictionPayload}
          />
        );
      case 'create_zendesk_ticket':
        return (
          <CreateZendeskTicketMessage
            payload={payload as CreateZendeskTicketPayload}
          />
        );
      case 'search_help_center_articles':
        return (
          <SearchHelpCenterArticlesMessage
            payload={payload as SearchHelpCenterArticlesPayload}
          />
        );
      case 'generic_tool':
        return <GenericToolMessage payload={payload as GenericToolPayload} />;
      case 'intent_routing':
        return (
          <IntentRoutingMessage payload={payload as IntentRoutingPayload} />
        );
      default:
        return null;
    }
  }

  return null;
};
