import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';

export const useIsCvHelpdeskFieldsAvailable = () => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const { data: orgConfigData } = useGetOrgConfigQuery();

  return Boolean(isSolveEmailEnabled || orgConfigData?.is_assist_gpt_enabled);
};
