import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { filterConnectorBySearchQuery } from './utils';
import debounce from 'lodash/fp/debounce';
import {
  selectUser,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { emitTrackingEventApi } from 'src/services/api';
import { Connector } from 'src/services/apiInterfaces';
import { useGetConnectorsQuery } from 'src/services/connector/connectorApi';
import { selectNumConnectorChanges } from 'src/slices/connectors/connectorsSlice';

// Helper to pull integration slug from query parameters
export function useIntegrationSlug() {
  const { search } = useLocation();

  const integrationSlug = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('integrationSlug');
  }, [search]);

  return integrationSlug;
}

// Filter connectors by search query and filter connector definitions
export function useConnectors(searchQuery: string) {
  const numConnectorChanges = useSelector(selectNumConnectorChanges);

  // anytime we update state.connectors.numConnectorChanges we want to refetch
  const { data, isLoading } = useGetConnectorsQuery(numConnectorChanges, {
    pollingInterval: 30000,
    refetchOnMountOrArgChange: true,
  });

  const { availableConnectors, createdConnectors } = useMemo(() => {
    if (data) {
      return data;
    }
    return { availableConnectors: [], createdConnectors: [] };
  }, [data]);

  const createdConnectorDefinitions = useMemo(
    () => createdConnectors.map(c => c.connector_definition),
    [createdConnectors],
  );

  const filteredCreatedConnectors = useMemo(() => {
    return createdConnectors
      .filter(connector =>
        filterConnectorBySearchQuery(searchQuery)(
          connector.connector_definition,
          connector.display_name,
        ),
      )
      .map(connector => ({ ...connector })); // Ensure a new object
  }, [createdConnectors, searchQuery]);

  const filteredAvailableConnectors = useMemo(() => {
    return availableConnectors.filter(connector =>
      filterConnectorBySearchQuery(searchQuery)(connector, connector.name),
    );
  }, [availableConnectors, searchQuery]);

  const helpdeskAvailableConnectorDefinitions = useMemo(() => {
    return availableConnectors.filter(connector => connector.is_helpdesk);
  }, [availableConnectors]);

  const helpdeskCreatedConnectorDefinitions = useMemo(() => {
    return createdConnectors
      .filter(connector => connector.is_active)
      .map(connector => connector.connector_definition)
      .filter(connector => connector.is_helpdesk);
  }, [createdConnectors]);

  return {
    availableConnectors,
    createdConnectorDefinitions,
    createdConnectors,
    filteredAvailableConnectors,
    filteredCreatedConnectors,
    helpdeskAvailableConnectorDefinitions,
    helpdeskCreatedConnectorDefinitions,
    isLoading,
  };
}

const debouncedTrackingEventApi = debounce(1000, emitTrackingEventApi);

/**
 * send 'connector-search' tracking event 1s after user stops typing
 */
export function useTrackingEventOnSearchQueryChange(searchQuery: string) {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const { auth_time: sessionId } = user?.user ?? {};

  useEffect(() => {
    if (!searchQuery) {
      // if user clears input, don't send an event
      return debouncedTrackingEventApi.cancel();
    }

    debouncedTrackingEventApi({
      eventType: 'connector-search',
      search_query: searchQuery,
      sessionId,
      userRole,
    });

    return () => debouncedTrackingEventApi.cancel();
  }, [searchQuery, sessionId, userRole]);
}

export function useExistingNames(createdConnectors: Connector[] | undefined) {
  const existingNames = useMemo(
    () =>
      createdConnectors
        ?.map(
          connector =>
            connector.display_name || connector.connector_definition.name,
        )
        .filter(Boolean),
    [createdConnectors],
  );

  return existingNames;
}
