import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { Spacer } from '../ActionStep';
import TicketCustomFields from '../handoff-configuration/intercom/TicketCustomFields';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectHandoffCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { FrontTicketCreationCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface FrontCustomField {
  name: string;
  value: string | number | boolean;
}

interface FrontTicketCreationCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const FrontTicketCreationCustomizationPanel: FC<
  FrontTicketCreationCustomizationPanelProps
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultFrontCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<FrontTicketCreationCustomizationSettings>;
  const defaultFrontTicketCreationCustomizationSettings = useMemo(() => {
    return {
      channel_id: '',
      contact_email_variable: '',
      contact_name_variable: '',
      custom_fields: {},
      ticket_id_output_variable: '',
      ...defaultFrontCustomizationSettings,
    };
  }, [defaultFrontCustomizationSettings]);
  const frontCustomization = addingAction
    ? defaultFrontTicketCreationCustomizationSettings
    : (canvasState.actionSettings
        ?.configuration_fields as FrontTicketCreationCustomizationSettings);
  const isLoadingCustomization = canvasState.isLoadingCustomization;
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const [customizationData, setCustomizationData] =
    useState<FrontTicketCreationCustomizationSettings>(frontCustomization);
  const [customFields, setCustomFields] = useState<FrontCustomField[]>([]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(ActionBuilderActionTypes.FRONT_TICKET_CREATION),
      );
    }
  }, [dispatch, addingAction]);

  useEffect(() => {
    if (!isLoadingCustomization) {
      const merged = addingAction
        ? defaultFrontTicketCreationCustomizationSettings
        : frontCustomization;
      setCustomizationData(merged);
      const cf = merged.custom_fields || {};
      const arrOfCF = Object.keys(cf).map(k => ({ name: k, value: cf[k] }));
      setCustomFields(arrOfCF);
    }
  }, [
    isLoadingCustomization,
    addingAction,
    defaultFrontTicketCreationCustomizationSettings,
    frontCustomization,
  ]);

  const isPristine = isEqual(customizationData, frontCustomization);

  useEffect(() => {
    setAreActionSettingsUnsaved(!isPristine);
  }, [isPristine, setAreActionSettingsUnsaved]);

  if (isLoadingCustomization || !customizationData) {
    return <LoadingSkeleton />;
  }
  const channels = frontCustomization.front_channels || [];
  const rawFieldsList = frontCustomization.front_custom_fields || [];
  const fieldsList = rawFieldsList.map(f => ({
    id: f.id,
    name: f.name,
    type: 'string',
  }));

  const ticketConfig = customizationData || {};
  const disableSave = hasWorkflowConflict || !ticketConfig.channel_id;

  const getCustomFieldsObject = (): Record<
    string,
    string | number | boolean
  > => {
    const obj: Record<string, string | number | boolean> = {};
    customFields.forEach(({ name, value }) => {
      if (name) {
        obj[name] = value;
      }
    });
    return obj;
  };

  const handleSave = () => {
    const finalData = {
      ...customizationData,
      custom_fields: getCustomFieldsObject(),
    };
    if (addingAction) {
      dispatch(
        addAction({
          ...addingAction,
          data: {
            ...addingAction.data,
            configuration_fields: finalData,
          },
        }),
      );
    } else {
      dispatch(
        updateActionSettings({
          action_type: canvasState.actionSettings.action_type as string,
          configuration_fields: finalData,
        }),
      );
      dispatchActionEditedTrackingEvent({
        actionType: canvasState.actionSettings.action_type as string,
      });
    }
    setActionPanelVisibilityParameters('hidden');
    setAreActionSettingsUnsaved(false);
    dispatch(setCustomizableActionId(''));
    onDismiss();
  };

  const channelOptions = channels.map(ch => ({ label: ch.name, value: ch.id }));

  const handleChannelChange = (value: string) => {
    setCustomizationData(prev => ({
      ...prev,
      channel_id: value,
    }));
  };

  return (
    <>
      <TopSection>
        <Title>Front Ticket Creation</Title>
        <Spacer height='16px' />
        <SelectDropdown
          id='channel-dropdown'
          label='Channel'
          onChange={e => handleChannelChange(e.target.value)}
          options={channelOptions}
          placeholder='Select a channel...'
          value={ticketConfig.channel_id || ''}
        />
        <Spacer height='16px' />
        <ContextVariableSelectDropdown
          isClearable
          label='Contact Name'
          onChange={val => {
            setCustomizationData(prev => ({
              ...prev,
              contact_name_variable: val,
            }));
          }}
          value={ticketConfig.contact_name_variable || ''}
        />
        <Spacer height='16px' />
        <ContextVariableSelectDropdown
          isClearable
          label='Contact Email'
          onChange={val => {
            setCustomizationData(prev => ({
              ...prev,
              contact_email_variable: val,
            }));
          }}
          value={ticketConfig.contact_email_variable || ''}
        />
        <Spacer height='16px' />
        <ContextVariableSelectDropdown
          isClearable
          label='Ticket ID Output Variable'
          onChange={val => {
            setCustomizationData(prev => ({
              ...prev,
              ticket_id_output_variable: val,
            }));
          }}
          value={ticketConfig.ticket_id_output_variable || ''}
        />
        <Spacer height='16px' />
        <Box sx={{ mb: 2 }}>
          <TicketCustomFields
            customFieldsAndValues={customFields}
            setCustomFieldsAndValues={newVal => setCustomFields(newVal)}
            ticketCustomFields={fieldsList}
          />
        </Box>
      </TopSection>
      <SaveContainer hasTopBorder>
        <SecondaryButton onClick={onDiscard}>Cancel</SecondaryButton>
        <StyledButton disabled={disableSave} onClick={handleSave}>
          Save
        </StyledButton>
      </SaveContainer>
    </>
  );
};

export default FrontTicketCreationCustomizationPanel;

const TopSection = styled('div')`
  padding: 16px;
`;

const Title = styled('h2')`
  margin: 0;
`;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-top: ${({ hasTopBorder }) =>
    hasTopBorder ? `1px solid ${theme.palette.colors.slate[200]}` : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
