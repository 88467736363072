import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { publishEmailWorkflow } from '../email-workflow/emailWorkflowSlice';
import {
  AddIntentEmailConfigurationComponentParams,
  DeleteIntentEmailConfigurationComponentParams,
  DeleteIntentEmailConfigurationParams,
  GetIntentEmailConfigurationParams,
  IntentEmailConfigurationResponse,
  IntentEmailConfigurationsResponse,
  ObtainIntentEmailConfigurationParams,
  PublishIntentEmailConfigurationParams,
  ReorderIntentEmailConfigurationComponentParams,
  ToggleAutopilotRequest,
  UpdateIntentEmailConfigurationComponentParams,
  UpdateIntentEmailConfigurationNameParams,
} from 'src/pages/workflow-builder-edit/types';
import * as API from 'src/services/email-builder/emailBuilderApi';
import { getWorkflowBuilderWidgetConfigAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import type { RootState } from 'src/store/rootReducer';
import { ArticleSourceOption } from 'src/types/workflowBuilderAPITypes';

export interface SolveEmailBuilderState {
  availableArticleSources: Array<ArticleSourceOption>;
  error: string | null;
  intentEmailConfiguration: IntentEmailConfigurationResponse | null;
  intentEmailConfigurations: IntentEmailConfigurationsResponse;
  isAutopilotLoading: boolean;
  isLoading: boolean;
  isLoadingIntentEmailConfigurations: boolean;
  themeColor: string;
}

const initialState: SolveEmailBuilderState = {
  availableArticleSources: [],
  error: null,
  intentEmailConfiguration: null,
  intentEmailConfigurations: [],
  isAutopilotLoading: false,
  isLoading: false,
  isLoadingIntentEmailConfigurations: false,
  themeColor: '',
};

export const obtainIntentEmailConfiguration = createAsyncThunk(
  'solveEmailBuilder/obtainIntentEmailConfiguration',
  (params: ObtainIntentEmailConfigurationParams) => {
    return API.obtainIntentEmailConfiguration(params);
  },
);

export const getIntentEmailConfiguration = createAsyncThunk(
  'solveEmailBuilder/getIntentEmailConfiguration',
  (params: GetIntentEmailConfigurationParams) => {
    return API.getIntentEmailConfiguration(params);
  },
);

export const createIntentEmailConfiguration = createAsyncThunk(
  'solveEmailBuilder/createIntentEmailConfiguration',
  (params: ObtainIntentEmailConfigurationParams) => {
    return API.createIntentEmailConfiguration(params);
  },
);

export const getEmailConfigurationsPerIntent = createAsyncThunk(
  'solveEmailBuilder/getEmailConfigurationsPerIntent',
  (params: ObtainIntentEmailConfigurationParams) => {
    return API.getEmailConfigurationsPerIntent(params);
  },
);

export const updateIntentEmailConfigurationComponent = createAsyncThunk(
  'solveEmailBuilder/updateIntentEmailConfigurationComponent',
  (params: UpdateIntentEmailConfigurationComponentParams) => {
    return API.updateIntentEmailConfigurationCompononent(params);
  },
);

export const addIntentEmailConfigurationComponent = createAsyncThunk(
  'solveEmailBuilder/addIntentEmailConfigurationComponent',
  (params: AddIntentEmailConfigurationComponentParams) => {
    return API.addIntentEmailConfigurationComponent(params);
  },
);

export const deleteIntentEmailConfigurationComponent = createAsyncThunk(
  'solveEmailBuilder/deleteIntentEmailConfigurationComponent',
  (params: DeleteIntentEmailConfigurationComponentParams) => {
    return API.deleteIntentEmailConfigurationComponent(params);
  },
);

export const reorderIntentEmailConfigurationComponent = createAsyncThunk(
  'solveEmailBuilder/reorderIntentEmailConfigurationComponent',
  (params: ReorderIntentEmailConfigurationComponentParams) => {
    return API.reorderIntentEmailConfigurationComponent(params);
  },
);

const publishIntentEmailConfiguration = createAsyncThunk(
  'solveEmailBuilder/publishIntentEmailConfiguration',
  (params: PublishIntentEmailConfigurationParams) => {
    return API.publishIntentEmailConfiguration(params);
  },
);

export const toggleAutopilot = createAsyncThunk(
  'solveEmailBuilder/toggleAutopilot',
  (params: ToggleAutopilotRequest) => {
    return API.toggleAutopilot(params);
  },
);

export const getThemeColor = createAsyncThunk(
  'solveEmailBuilder/getThemeColor',
  () => {
    return getWorkflowBuilderWidgetConfigAPI();
  },
);

export const getAvailableArticleSources = createAsyncThunk(
  'solveEmailBuilder/getAvailableArticleSources',
  () => {
    return API.getAvailableArticleSources();
  },
);

export const updateIntentEmailConfigurationName = createAsyncThunk(
  'solveEmailBuilder/updateIntentEmailConfigurationName',
  (params: UpdateIntentEmailConfigurationNameParams) => {
    return API.updateIntentEmailConfigurationName(params);
  },
);

export const deleteIntentEmailConfiguration = createAsyncThunk(
  'solveEmailBuilder/deleteIntentEmailConfiguration',
  (params: DeleteIntentEmailConfigurationParams) => {
    return API.deleteIntentEmailConfiguration(params);
  },
);

const emailBuilderSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(
      obtainIntentEmailConfiguration.fulfilled,
      (state, { payload }) => {
        state.intentEmailConfiguration = payload;
        state.isLoading = false;
        state.error = null;

        if (!state.intentEmailConfigurations.length) {
          state.intentEmailConfigurations = [
            {
              autopilot_enabled: payload.autopilot_enabled,
              configuration_id: payload.configuration_id,
              configuration_version: payload.version,
              interactive_email_enabled: false,
              is_in_use: false,
              missing_static_article: false,
              name: payload.name,
              needs_to_be_published: true,
            },
          ];
        }
      },
    );
    builder.addCase(obtainIntentEmailConfiguration.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      obtainIntentEmailConfiguration.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
        state.isLoading = false;
      },
    );

    builder.addCase(
      getIntentEmailConfiguration.fulfilled,
      (state, { payload }) => {
        state.intentEmailConfiguration = payload;
        state.isLoading = false;
        state.error = null;
      },
    );
    builder.addCase(getIntentEmailConfiguration.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(getIntentEmailConfiguration.rejected, (state, action) => {
      state.error = action.error.message || 'Error';
      state.isLoading = false;
    });

    builder.addCase(
      createIntentEmailConfiguration.fulfilled,
      (state, { payload }) => {
        state.intentEmailConfigurations.push({
          autopilot_enabled: payload.autopilot_enabled,
          configuration_id: payload.configuration_id,
          configuration_version: payload.version,
          interactive_email_enabled: false,
          is_in_use: false,
          missing_static_article: false,
          name: payload.name,
          needs_to_be_published: true,
        });
        state.isLoading = false;
        state.error = null;
      },
    );
    builder.addCase(createIntentEmailConfiguration.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      createIntentEmailConfiguration.rejected,
      (state, { error }) => {
        state.error = error.message || 'Error';
        state.isLoading = true;
      },
    );

    builder.addCase(
      getEmailConfigurationsPerIntent.fulfilled,
      (state, { payload }) => {
        if (!!payload.length) {
          state.intentEmailConfigurations = payload;
        }
        state.isLoadingIntentEmailConfigurations = false;
        state.error = null;
      },
    );
    builder.addCase(getEmailConfigurationsPerIntent.pending, state => {
      state.intentEmailConfigurations = [];
      state.isLoadingIntentEmailConfigurations = true;
      state.error = null;
    });

    builder.addCase(
      getEmailConfigurationsPerIntent.rejected,
      (state, { error }) => {
        state.error = error.message || 'Error';
        state.isLoadingIntentEmailConfigurations = false;
      },
    );

    builder.addCase(
      updateIntentEmailConfigurationComponent.fulfilled,
      (state, { meta, payload }) => {
        // Update published state in template list
        state.intentEmailConfigurations = state.intentEmailConfigurations.map(
          config => {
            if (config.configuration_id === meta.arg.configurationId) {
              return {
                ...config,
                needs_to_be_published: true,
              };
            }
            return config;
          },
        );
        state.intentEmailConfiguration = payload;
        state.isLoading = false;
        state.error = null;
      },
    );
    builder.addCase(updateIntentEmailConfigurationComponent.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      updateIntentEmailConfigurationComponent.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
        state.isLoading = false;
      },
    );

    builder.addCase(addIntentEmailConfigurationComponent.pending, state => {
      state.error = null;
    });
    builder.addCase(
      addIntentEmailConfigurationComponent.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
        state.isLoading = false;
      },
    );
    builder.addCase(
      addIntentEmailConfigurationComponent.fulfilled,
      (state, action) => {
        // Update published state in template list
        state.intentEmailConfigurations = state.intentEmailConfigurations.map(
          config => {
            if (config.configuration_id === action.meta.arg.configurationId) {
              return {
                ...config,
                needs_to_be_published: true,
              };
            }
            return config;
          },
        );
        state.error = null;
        state.isLoading = false;
        state.intentEmailConfiguration = action.payload;
      },
    );

    builder.addCase(
      publishIntentEmailConfiguration.fulfilled,
      (state, action) => {
        state.intentEmailConfiguration = action.payload.draft;
        state.error = null;
        state.isLoading = false;
      },
    );

    builder.addCase(publishIntentEmailConfiguration.pending, state => {
      state.error = null;
      state.isLoading = true;
    });

    builder.addCase(
      publishIntentEmailConfiguration.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
        state.isLoading = false;
      },
    );

    builder.addCase(
      deleteIntentEmailConfigurationComponent.fulfilled,
      (state, action) => {
        // Update published state in template list
        state.intentEmailConfigurations = state.intentEmailConfigurations.map(
          config => {
            if (config.configuration_id === action.meta.arg.configurationId) {
              return {
                ...config,
                needs_to_be_published: true,
              };
            }
            return config;
          },
        );
        state.intentEmailConfiguration = action.payload;
      },
    );

    builder.addCase(deleteIntentEmailConfigurationComponent.pending, state => {
      state.error = null;
    });

    builder.addCase(
      deleteIntentEmailConfigurationComponent.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
      },
    );

    builder.addCase(
      reorderIntentEmailConfigurationComponent.fulfilled,
      (state, action) => {
        // Update published state in template list
        state.intentEmailConfigurations = state.intentEmailConfigurations.map(
          config => {
            if (config.configuration_id === action.meta.arg.configurationId) {
              return {
                ...config,
                needs_to_be_published: true,
              };
            }
            return config;
          },
        );
        state.intentEmailConfiguration = action.payload;
      },
    );

    builder.addCase(
      reorderIntentEmailConfigurationComponent.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
      },
    );
    builder.addCase(reorderIntentEmailConfigurationComponent.pending, state => {
      state.error = null;
    });

    builder.addCase(getThemeColor.fulfilled, (state, action) => {
      state.themeColor = action.payload.theme_color || '#7b33fb';
    });

    builder.addCase(getAvailableArticleSources.fulfilled, (state, action) => {
      state.availableArticleSources = action.payload.available_article_sources;
    });
    builder.addCase(
      updateIntentEmailConfigurationName.fulfilled,
      (state, action) => {
        const payload = action.payload;

        state.intentEmailConfigurations = state.intentEmailConfigurations.map(
          configuration => {
            if (
              configuration.configuration_id === action.meta.arg.configurationId
            ) {
              return { ...configuration, name: payload.name };
            } else {
              return configuration;
            }
          },
        );
        if (state.intentEmailConfiguration) {
          state.intentEmailConfiguration = {
            ...state.intentEmailConfiguration,
            last_modified_date: payload.last_modified_date,
            name: payload.name,
          };
        }
        state.error = null;
      },
    );
    builder.addCase(updateIntentEmailConfigurationName.pending, state => {
      state.error = null;
    });
    builder.addCase(
      updateIntentEmailConfigurationName.rejected,
      (state, action) => {
        state.error = action.error.message || 'Error';
      },
    );
    /**
     * publishEmailWorkflow
     * When publishEmailWorkflow succeeds, we know that all email configs are in a published state
     */
    builder.addCase(publishEmailWorkflow.fulfilled, state => {
      state.intentEmailConfigurations = state.intentEmailConfigurations.map(
        config => ({
          ...config,
          needs_to_be_published: false,
        }),
      );
    });
    builder.addCase(publishEmailWorkflow.rejected, (state, { error }) => {
      state.error = error.message || 'Error';
    });
    builder.addCase(publishEmailWorkflow.pending, state => {
      state.error = null;
    });

    builder.addCase(
      deleteIntentEmailConfiguration.fulfilled,
      (state, { payload }) => {
        const updatedConfigurations = state.intentEmailConfigurations.filter(
          config => config.configuration_id !== payload.configuration_id,
        );
        const updatedConfigurationsLength = updatedConfigurations.length;

        state.intentEmailConfigurations = updatedConfigurations;

        if (!updatedConfigurationsLength) {
          state.intentEmailConfiguration = null;
        }
      },
    );

    builder.addCase(
      deleteIntentEmailConfiguration.rejected,
      (state, { error }) => {
        state.error = error.message || 'Error';
      },
    );

    builder.addCase(deleteIntentEmailConfiguration.pending, state => {
      state.error = null;
    });

    builder.addCase(toggleAutopilot.pending, (state, action) => {
      if (!state.intentEmailConfiguration) {
        return;
      }
      state.intentEmailConfiguration = {
        ...state.intentEmailConfiguration,
        autopilot_enabled: action.meta.arg.isAutopilotEnabled,
      };
    });
    builder.addCase(toggleAutopilot.fulfilled, (state, action) => {
      state.intentEmailConfigurations = state.intentEmailConfigurations.map(
        config => {
          if (config.configuration_id === action.meta.arg.configurationId) {
            return {
              ...config,
              needs_to_be_published: true,
            };
          }
          return config;
        },
      );
      state.intentEmailConfiguration = action.payload;
    });
  },
  initialState,
  name: 'solveEmailBuilder',
  reducers: {
    cleanErrors: state => {
      state.error = null;
    },
  },
});

export const { cleanErrors } = emailBuilderSlice.actions;

export const selectEmailBuilderState = (
  state: RootState,
): SolveEmailBuilderState => state.emailBuilder;

export const selectIntentEmailConfiguration = (
  state: RootState,
): SolveEmailBuilderState['intentEmailConfiguration'] =>
  state.emailBuilder.intentEmailConfiguration;

export const selectIntentEmailConfigurations = (
  state: RootState,
): SolveEmailBuilderState['intentEmailConfigurations'] =>
  state.emailBuilder.intentEmailConfigurations;

export const selectThemeColor = (state: RootState): string =>
  state.emailBuilder.themeColor;

export const selectAvailableArticleSources = (
  state: RootState,
): SolveEmailBuilderState['availableArticleSources'] =>
  state.emailBuilder.availableArticleSources;

export const selectEmailBuilderError = (
  state: RootState,
): SolveEmailBuilderState['error'] => state.emailBuilder.error;

export default emailBuilderSlice.reducer;
