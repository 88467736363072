import { useDispatch, useSelector } from 'react-redux';

import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import { KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE } from 'src/components/knowledge-configuration-prompt-text-field/helpers';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  hideWarningModal,
  selectIsWarningModalVisible,
  selectPlaybookWarningModalsVisibility,
  selectWarningModalsVisibility,
} from 'src/slices/solve-config/solveConfigSlice';

export const WarningModals = () => {
  const dispatch = useDispatch();
  const { data } = useGetFeatureFlagsQuery();

  const isWarningModalVisible = useSelector(selectIsWarningModalVisible);

  const [
    isGreetingWarningModalVisible,
    isPromptWarningModalVisible,
    isKnowledgePromptWarningModalVisible,
    isIntroTitleWarningModalVisible,
    isIntroSubtitleWarningModalVisible,
  ] = useSelector(selectWarningModalsVisibility);
  const [isPlaybookKnowledgePromptWarningModalVisible] = useSelector(
    selectPlaybookWarningModalsVisibility,
  );

  return (
    isWarningModalVisible && (
      <>
        <ConfirmModal
          actions={[
            {
              onClick: () => {
                dispatch(hideWarningModal());
              },
              title: 'Got it',
            },
          ]}
          description='Enter a greeting message as the greeting field cannot be left empty.'
          isOpen={isGreetingWarningModalVisible}
          onClose={() => {
            dispatch(hideWarningModal());
          }}
          title='Greeting cannot be empty.'
        />
        <ConfirmModal
          actions={[
            {
              onClick: () => {
                dispatch(hideWarningModal());
              },
              title: 'Got it',
            },
          ]}
          description='Enter a prompt message as the Proactive prompt field cannot be left empty. If you like you can turn off the prompt.'
          isOpen={isPromptWarningModalVisible}
          onClose={() => {
            dispatch(hideWarningModal());
          }}
          title='Proactive Prompt cannot be empty.'
        />
        <ConfirmModal
          actions={[
            {
              onClick: () => {
                dispatch(hideWarningModal());
              },
              title: 'Got it',
            },
          ]}
          description={KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE({
            shouldUseLongerNoteLength: Boolean(
              data?.feature_flags.includes('allow_longer_instructional_note'),
            ),
          })}
          isOpen={
            isKnowledgePromptWarningModalVisible ||
            isPlaybookKnowledgePromptWarningModalVisible
          }
          onClose={() => {
            dispatch(hideWarningModal());
          }}
          title='Knowledge configuration prompt is too long.'
        />
        <ConfirmModal
          actions={[
            {
              onClick: () => {
                dispatch(hideWarningModal());
              },
              title: 'Got it',
            },
          ]}
          description='Enter an intro title as the intro title field cannot be left empty.'
          isOpen={isIntroTitleWarningModalVisible}
          onClose={() => {
            dispatch(hideWarningModal());
          }}
          title='Intro title cannot be empty.'
        />
        <ConfirmModal
          actions={[
            {
              onClick: () => {
                dispatch(hideWarningModal());
              },
              title: 'Got it',
            },
          ]}
          description='Enter an intro subtitle as the intro subtitle field cannot be left empty.'
          isOpen={isIntroSubtitleWarningModalVisible}
          onClose={() => {
            dispatch(hideWarningModal());
          }}
          title='Intro subtitle cannot be empty.'
        />
      </>
    )
  );
};
