import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { VoiceTranscript } from './types';

export const IntentPredictionTranscript = ({
  transcript,
}: {
  transcript: VoiceTranscript;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.colors.slate[100]}`,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '6px 12px',
        width: '100%',
      }}
    >
      <Typography color={theme.palette.colors.grey[600]} variant='font12Medium'>
        {transcript.message}
      </Typography>
    </Box>
  );
};
