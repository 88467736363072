import { memo } from 'react';
import { useLocation } from 'react-router';
import { Box } from '@mui/material';

import { EMAIL, VOICE, WIDGET } from '../constants';
import useTabs from '../hooks/useTabs';
import { ProductMode } from '../types';
import ToggleButtonGroup from 'src/components/toggle-button-group';
import useIsSolveEmailEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsSolveEmailEnabled';
import useIsSolveVoiceEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsSolveVoiceEnabled';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

interface ProductToggleBarProps {
  productMode: ProductMode;
  setProductMode: (
    product: ProductMode,
    batchUpdater?: () => URLSearchParams,
  ) => void;
}

const ProductToggleBar = ({
  productMode,
  setProductMode,
}: ProductToggleBarProps) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab') || '0';

  const { tabIdToIndexMap } = useTabs();
  const isChatTab = tabIdToIndexMap.chat === Number(tab);

  const isEmailEnabled = useIsSolveEmailEnabled();
  const isSolveVoiceEnabled = useIsSolveVoiceEnabled();

  const getOptions = () => {
    const items: [{ label: string; value: ProductMode }] = [
      {
        label: capitalizeFirstLetter(WIDGET),
        value: WIDGET,
      },
    ];

    if (isEmailEnabled) {
      items.push({
        label: capitalizeFirstLetter(EMAIL),
        value: EMAIL,
      });
    }
    // Voice is only available in chat tab
    if (isChatTab && isSolveVoiceEnabled) {
      items.push({
        label: capitalizeFirstLetter(VOICE),
        value: VOICE,
      });
    }

    return items;
  };

  const options = getOptions();

  if (options.length === 1) {
    return null;
  }

  return (
    <Box
      borderBottom={theme => '1px solid ' + theme.palette.colors.slate[100]}
      paddingLeft={3}
    >
      <ToggleButtonGroup
        alignment={productMode}
        items={options}
        onChange={newAlignment => {
          if (!newAlignment) {
            return;
          }
          const isWidget = newAlignment === 'widget';
          setProductMode(
            newAlignment,
            // When navigating back to widget productMode, update article tab to default (widget does not have knowledge gap)
            isWidget
              ? () =>
                  new URLSearchParams({
                    articlesTab: '0',
                    tab,
                  })
              : undefined,
          );
        }}
        toggleWidth='100%'
        width='404px'
      />
    </Box>
  );
};

const MemoizedProductToggleBar = memo(ProductToggleBar);

export default MemoizedProductToggleBar;
