import { type PropsWithChildren } from 'react';
import { RTVIClient, RTVIClientParams } from '@pipecat-ai/client-js';
import { RTVIClientProvider } from '@pipecat-ai/client-react';
import { DailyTransport } from '@pipecat-ai/daily-transport';

import { getVoicePreviewToken } from './voicePreviewApi';
import { sendAuthRequestWithErrorHandling } from 'src/services/api';

const transport = new DailyTransport();

const rtviClient = new RTVIClient({
  customConnectHandler: async (params: RTVIClientParams): Promise<void> => {
    const voicePreviewTokenObj = await getVoicePreviewToken();

    const connectUrl = `${VOICE_API_URL}solve-voice/start-bot-preview`;

    return sendAuthRequestWithErrorHandling(
      connectUrl,
      params.requestData,
      'POST',
      async () => voicePreviewTokenObj.api_key,
    );
  },
  enableCam: false,
  enableMic: true,
  params: {
    // our URLs end with /, need to remove the ending slash for baseUrl here
    baseUrl: VOICE_API_URL?.slice(0, -1) || '',
    endpoints: {
      connect: '/solve-voice/start-bot-preview',
    },
    requestData: {
      intent_filters_for_preview: null,
      to_number: null,
    },
  },
  transport,
});

export const RTVIProvider = ({ children }: PropsWithChildren) => {
  return (
    <RTVIClientProvider client={rtviClient}>{children}</RTVIClientProvider>
  );
};
