import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, styled } from '@mui/material';

import {
  TextField,
  theme,
} from '@forethought-technologies/forethought-elements';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';
import {
  Label,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { TalkdeskChatHandoffActionSettings } from 'src/types/workflowBuilderAPITypes';

type TalkdeskHandoffPrechatField = { name: string; value: string };

type Props = {
  customizationData: TalkdeskChatHandoffActionSettings;
  setCustomizationData: Dispatch<
    SetStateAction<TalkdeskChatHandoffActionSettings>
  >;
};

const emptyAttribute: TalkdeskHandoffPrechatField = {
  name: '',
  value: '',
};

const TalkdeskChatCustomAttributes: FC<React.PropsWithChildren<Props>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const currentlyUsedAttributes = customizationData.prechat_fields?.length
    ? customizationData.prechat_fields
    : [{ ...emptyAttribute }];

  const deleteAttribute = (idx: number) => {
    setCustomizationData(handoffData => {
      const newAttributes = [...(handoffData.prechat_fields || [])];
      newAttributes.splice(idx, 1);

      return {
        ...handoffData,
        prechat_fields: newAttributes,
      };
    });
  };

  const addAttribute = () => {
    setCustomizationData(handoffData => {
      const newAttributes = [...(handoffData.prechat_fields || [])];
      newAttributes.push({ ...emptyAttribute });

      return {
        ...handoffData,
        prechat_fields: newAttributes,
      };
    });
  };

  const onAttributeChange = (newValue: string, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    newAttributes.splice(idx, 1, {
      name: newValue,
      value: currentlyUsedAttributes[idx].value,
    });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        prechat_fields: newAttributes,
      };
    });
  };

  const onAttributeValueChange = (value: string, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    newAttributes.splice(idx, 1, {
      name: currentlyUsedAttributes[idx].name,
      value,
    });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        prechat_fields: newAttributes,
      };
    });
  };

  const getInputComponent = (
    customHandoffAttribute: TalkdeskHandoffPrechatField,
    idx: number,
  ): JSX.Element => {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.colors.white,
          flex: '1 1 50%',
        }}
      >
        <ContextMention
          onChange={value => {
            onAttributeValueChange(value, idx);
          }}
          placeholder='Enter value'
          value={customHandoffAttribute.value.toString()}
        />
      </Box>
    );
  };

  return (
    <>
      <Label>Prechat fields</Label>
      {currentlyUsedAttributes.map((customAttribute, idx) => {
        return (
          <Box
            key={idx}
            sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.colors.white,
                  flex: '1 1 50%',
                }}
              >
                <TextField
                  aria-label={`Name for field ${idx + 1}`}
                  onChange={event => {
                    onAttributeChange(event.target.value, idx);
                  }}
                  value={customAttribute.name}
                />
              </Box>
              <Spacer width='8px' />
              {getInputComponent(customAttribute, idx)}
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '30px',
                }}
              >
                <DeleteButton
                  onClick={() => {
                    deleteAttribute(idx);
                  }}
                  role='button'
                  src={xIcon}
                />
              </Box>
            </Box>
            {idx !== currentlyUsedAttributes.length - 1 && (
              <Spacer height='10px' />
            )}
          </Box>
        );
      })}
      <Add isDisabled={false} onClick={addAttribute}>
        + Add field
      </Add>
    </>
  );
};

export default TalkdeskChatCustomAttributes;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const Add = styled('a')<{ isDisabled: boolean }>(({ isDisabled }) => ({
  boxSizing: 'border-box',
  color: isDisabled
    ? theme.palette.colors.grey[300]
    : theme.palette.colors.purple[500],
  cursor: 'pointer',
  fontSize: '16px',
  fontStyle: isDisabled ? 'italic' : 'normal',
  lineHeight: '16px',
  marginTop: '16px',
  maxWidth: '100%',
  pointerEvents: isDisabled ? 'none' : 'auto',
  textAlign: 'left',
  width: '100%',
}));
