export const CV_LENGTH_VALIDATION_ERROR =
  'Min value cannot be greater than max value';

export const ALLOWED_DISPLAY_NAME_CHARACTER_REGEX = /[^\d a-z_]/i;
export const ALLOWED_DISPLAY_NAME_LENGTH = 50;

export const checkboxTypeValueOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];
