import { Box } from '@mui/material';

import ConnectionsCardSkeleton from './ConnectionsCardSkeleton';
import { IntegrationSection } from './styles';

export default function IntegrationsLoadingState() {
  return (
    <IntegrationSection>
      {Array.from({ length: 6 }).map((_, index) => (
        <Box key={index} width='100%'>
          <ConnectionsCardSkeleton />
        </Box>
      ))}
    </IntegrationSection>
  );
}
