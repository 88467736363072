import {
  ArticleSource,
  ArticleSourceOption,
} from 'src/types/workflowBuilderAPITypes';
import { ElasticSearchDataType } from 'src/utils/enums';

const convertBaseOnType = (
  value: string | number | boolean,
  type: ElasticSearchDataType,
) => {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return value;
  }

  switch (type) {
    case ElasticSearchDataType.BOOLEAN:
      return value.toLowerCase() === 'true';
    case ElasticSearchDataType.LONG:
    case ElasticSearchDataType.INTEGER:
    case ElasticSearchDataType.DOUBLE:
    case ElasticSearchDataType.FLOAT:
    case ElasticSearchDataType.UNSIGNED_LONG:
      return Number(value);
    default:
      return value;
  }
};

export const formatArticleSourceQueries = (
  articleSources: Array<ArticleSource>,
  availableArticleSources: Array<ArticleSourceOption>,
): Array<ArticleSource> => {
  /* in this function, we will go through each article sources and the queires under them. For
    each query, we will get its type from availableArticleSources, and do type conversion for the 
    query value if necessary.
  */
  return articleSources.map(articleSource => {
    const docType = articleSource.doc_type;
    const connectorId = articleSource.connector_id;
    // find available article source that matches the doc type
    const correspondingAvailArticleSource = availableArticleSources.find(
      articleSourceOption =>
        connectorId
          ? articleSourceOption.connector_id === connectorId
          : articleSourceOption.doc_type === docType,
    );
    // no matching available article source
    if (!correspondingAvailArticleSource) {
      return articleSource;
    }

    const availableArticleFields =
      correspondingAvailArticleSource.available_article_fields;

    // generate new queries with values being converted to the correct types
    const newQueries = articleSource.filter_queries.map(query => {
      // find the type of the query
      const correspondingField = availableArticleFields.find(
        ({ field_name }) => field_name === query.field,
      );

      // if type is null, then no conversion is needed
      if (!correspondingField || !correspondingField.field_type) {
        return query;
      }

      // If the query contains CV, we should skip conversion for it
      const isContextVariable = query.value?.value.toString().includes('{{');

      return {
        ...query,
        value:
          query.value === null
            ? null
            : {
                ...query.value,
                value: convertBaseOnType(
                  query.value.value,
                  isContextVariable
                    ? ElasticSearchDataType.TEXT
                    : correspondingField.field_type,
                ),
              },
      };
    });

    return {
      ...articleSource,
      filter_queries: newQueries,
    };
  });
};
