import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  Card as BannerLayoutCard,
  CardMetric,
} from '../../common/banner-layout';
import { Direction, ViewMode, ViewType } from './types';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';

interface CardValueReduction {
  description?: string;
  emptyStateIcon?: React.ReactNode;
  icon?: React.ReactNode;
  tooltip?: string;
  value: string;
}

interface BaseProps {
  description?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  title: string;
  viewMode?: ViewMode;
  viewType: ViewType;
}

interface KnowledgeGapProps extends BaseProps {
  articlesGenerated: CardValueReduction;
  costReduction?: never;
  isKnowledgeGapBanner: true;
  lackingArticleCoverage: CardValueReduction;
  resolutionTimeReduction?: never;
  ticketsCovered: CardValueReduction;
  volumeReduction?: never;
}

interface RecommendationOrRealizedImpactProps extends BaseProps {
  articlesGenerated?: never;
  costReduction: CardValueReduction;
  isKnowledgeGapBanner?: never;
  lackingArticleCoverage?: never;
  resolutionTimeReduction: CardValueReduction;
  ticketsCovered?: never;
  volumeReduction: CardValueReduction;
}

type CardValueProps = (
  | KnowledgeGapProps
  | RecommendationOrRealizedImpactProps
) & { isV2?: boolean };

const CardValue = ({
  articlesGenerated,
  costReduction,
  description,
  icon,
  isKnowledgeGapBanner,
  isV2,
  loading,
  resolutionTimeReduction,
  ticketsCovered,
  title,
  viewMode = 'full',
  viewType,
  volumeReduction,
}: CardValueProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const [bannerLayoutCardDirectionFull, setBannerLayoutCardDirectionFull] =
    useState<Direction>('column');
  const cardMetricDirection =
    isMobile && viewMode === 'full' ? 'column' : 'row';

  useEffect(() => {
    let timeOut: NodeJS.Timeout | null = null;

    if (viewMode === 'full' || isMobile) {
      setBannerLayoutCardDirectionFull('column');
    } else {
      timeOut = setTimeout(() => {
        setBannerLayoutCardDirectionFull('row');
      }, 300);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [viewMode, isMobile]);

  const renderMetrics = () => {
    if (isKnowledgeGapBanner) {
      return (
        <>
          <CardMetric
            direction={cardMetricDirection}
            emptyStateIcon={articlesGenerated.emptyStateIcon}
            icon={articlesGenerated.icon}
            isBottomLabel
            labelDescription={articlesGenerated.description}
            labelTitle={
              isV2
                ? DISCOVER_CARD_TEXT.knowledgeContentsGenerated
                : DISCOVER_CARD_TEXT.articlesGenerated
            }
            loading={loading}
            value={articlesGenerated.value}
            viewMode={viewMode}
            viewType={viewType}
          />
          <CardMetric
            direction={cardMetricDirection}
            emptyStateIcon={ticketsCovered.emptyStateIcon}
            icon={ticketsCovered.icon}
            isBottomLabel
            labelDescription={ticketsCovered.description}
            labelTitle={DISCOVER_CARD_TEXT.ticketsCovered}
            loading={loading}
            value={ticketsCovered.value}
            viewMode={viewMode}
            viewType={viewType}
          />
        </>
      );
    }

    return (
      <>
        <CardMetric
          direction={cardMetricDirection}
          emptyStateIcon={volumeReduction.emptyStateIcon}
          icon={volumeReduction.icon}
          isBottomLabel
          labelDescription={volumeReduction.description}
          labelTitle={DISCOVER_CARD_TEXT.volumeTitle}
          loading={loading}
          value={volumeReduction.value}
          viewMode={viewMode}
          viewType={viewType}
        />
        <CardMetric
          direction={cardMetricDirection}
          emptyStateIcon={resolutionTimeReduction.emptyStateIcon}
          icon={resolutionTimeReduction.icon}
          isBottomLabel
          labelDescription={resolutionTimeReduction.description}
          labelTitle={DISCOVER_CARD_TEXT.resolutionTimeTitle}
          loading={loading}
          value={resolutionTimeReduction.value}
          viewMode={viewMode}
          viewType={viewType}
        />
        <CardMetric
          direction={cardMetricDirection}
          emptyStateIcon={costReduction.emptyStateIcon}
          icon={costReduction.icon}
          isBottomLabel
          labelDescription={costReduction.description}
          labelTitle={DISCOVER_CARD_TEXT.costTitle}
          loading={loading}
          tooltip={costReduction.tooltip}
          value={costReduction.value}
          viewMode={viewMode}
          viewType={viewType}
        />
      </>
    );
  };

  return (
    <BannerLayoutCard
      direction={bannerLayoutCardDirectionFull}
      viewType={viewType}
    >
      <Box
        mb={Number(
          viewMode === 'full' || bannerLayoutCardDirectionFull === 'column',
        )}
      >
        <Box
          alignItems='center'
          display='flex'
          mb={viewMode === 'full' ? 1 : 0}
        >
          {icon && (
            <Box display='flex' mr={0.5}>
              {icon}
            </Box>
          )}
          <Typography variant='font16Bold'>{title}</Typography>
        </Box>
        {description && (
          <Box>
            <Typography variant='font24'>{description}</Typography>
          </Box>
        )}
      </Box>
      <Box
        display='flex'
        flexDirection={cardMetricDirection}
        gap='12px'
        justifyContent='space-between'
        sx={[
          { flex: '1' },
          bannerLayoutCardDirectionFull === 'row' &&
            viewMode === 'minimized' && { marginLeft: 3 },
        ]}
      >
        {renderMetrics()}
      </Box>
    </BannerLayoutCard>
  );
};

export default CardValue;
