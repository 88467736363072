import { operatorOptions } from './constants';
import cloneDeep from 'lodash/fp/cloneDeep';
import {
  ArticleFieldValues,
  ArticleSuggestionActionSettings,
} from 'src/types/workflowBuilderAPITypes';

export const updateArticleSourceList = ({
  articleSourceIndex,
  articleSources,
  fieldToUpdate,
  filterQueryIndex,
  value,
}: {
  articleSourceIndex: number;
  articleSources: ArticleSuggestionActionSettings['article_sources'];
  fieldToUpdate: 'operator' | 'field' | 'value' | 'boolean_operator';
  filterQueryIndex: number;
  value: string | ArticleFieldValues;
}) => {
  const articleSourcesCopy: ArticleSuggestionActionSettings['article_sources'] =
    cloneDeep(articleSources);

  const filterQueries =
    articleSourcesCopy[articleSourceIndex]['filter_queries'];

  const filterQueryToUpdate = filterQueries[filterQueryIndex];

  let updatedValue = {
    ...filterQueryToUpdate,
    [fieldToUpdate]: typeof value === 'object' ? { ...value } : value,
    ...(fieldToUpdate === 'operator' &&
    typeof value === 'string' &&
    ['is empty', 'is not empty'].includes(value)
      ? { value: null }
      : {}),
  };

  if (fieldToUpdate === 'field') {
    // if we're updating `field`, we should also reset `operator` and `value` since they
    // depend on `field`
    updatedValue = {
      ...updatedValue,
      operator: operatorOptions[0].value,
      value: null,
    };
  }

  if (fieldToUpdate === 'boolean_operator') {
    articleSourcesCopy[articleSourceIndex]['filter_queries'] =
      filterQueries.map(filterQuery => ({
        ...filterQuery,
        boolean_operator: value as string,
      }));
  } else {
    articleSourcesCopy[articleSourceIndex]['filter_queries'][filterQueryIndex] =
      updatedValue;
  }

  return articleSourcesCopy;
};
