import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentUser } from 'src/reducers/userReducer/userReducer';
import { useDataExportMutation } from 'src/services/dashboard-api';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

const useExportCSV = <T>({
  exportType,
  requestData,
}: {
  exportType: string;
  requestData: Record<
    string,
    | undefined
    | number
    | string
    | boolean
    | null
    | T
    | (string | number | boolean | null)[]
  >;
}) => {
  const user = useSelector(selectCurrentUser) || {};
  const dispatch = useDispatch();

  const [mutate, { isError, isLoading, isSuccess }] = useDataExportMutation();
  const userEmail = user?.user?.user?.email;

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setGlobalToastOptions({
          title: `Please allow up to 10 minutes for the report to arrive. We will notify you by email (${userEmail}).`,
          variant: 'main',
        }),
      );
    }
  }, [isSuccess, dispatch, userEmail]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 5000,
          title: 'Export to csv failed',
          variant: 'danger',
        }),
      );
    }
  }, [isError, dispatch]);

  const onDownload = () => {
    mutate({
      body: {
        data_export_type: exportType,
        ...requestData,
      },
    });
  };

  return { isLoading, onDownload };
};

export default useExportCSV;
