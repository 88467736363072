import { useState } from 'react';
import { marked } from 'marked';
import ReactMarkdown from 'react-markdown';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconCopy } from '@tabler/icons-react';

import {
  Button,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { DiscoverArticle } from 'src/reducers/discoverReducer/types';

interface GeneratedKnowledgeContentsProps {
  article: DiscoverArticle;
}

const GeneratedKnowledgeContents = ({
  article,
}: GeneratedKnowledgeContentsProps) => {
  const { palette } = useTheme();
  const [copyText, setCopyText] = useState('Copy');

  const handleCopy = () => {
    if (!article.generated_title && !article.generated_body) {
      return;
    }
    const articleContent = `${article.generated_title}\n\n${article.generated_body}`;
    const copyContent = marked
      .parseInline(articleContent)
      .replace(/<[^>]*>/g, '')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      // Handle any other HTML entities (like &#xxxx;)
      .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
      .replace(/&[a-zA-Z]+;/g, ' ');
    navigator.clipboard.writeText(copyContent);
    setCopyText('Copied');
  };

  const handleOnBlur = () => {
    setCopyText('Copy');
  };

  const emptyGeneratedTitle = !article.generated_title;
  const emptyGeneratedBody = !article.generated_body;

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Box
        alignItems='center'
        display='flex'
        gap={1}
        justifyContent='space-between'
      >
        <Typography color={palette.colors.grey[600]} variant='font12'>
          Content block details
        </Typography>
        <Box
          sx={{
            '.MuiButtonBase-root:focus': {
              backgroundColor: palette.colors.purple[100],
              border: `2px solid ${palette.colors.purple[300]}`,
            },
            button: {
              padding: '6px',
            },
          }}
        >
          <Tooltip placement='left' tooltipContent={copyText}>
            <Button
              color='primary'
              onBlur={handleOnBlur}
              onClick={handleCopy}
              variant='ghost'
            >
              <IconCopy size={16} />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography
          color={emptyGeneratedTitle ? palette.colors.grey[500] : undefined}
          variant='font14Medium'
        >
          {article.generated_title || NA}
        </Typography>
        <Box
          sx={{
            color: emptyGeneratedBody ? palette.colors.grey[500] : undefined,
            fontSize: '14px',
          }}
        >
          <ReactMarkdown>{article.generated_body || NA}</ReactMarkdown>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneratedKnowledgeContents;
