import React, { FC, useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { theme } from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { hasAtLeastOneError } from '../handoff-configuration/helpers';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import {
  initializeFieldTouchedArr,
  validateZendeskOrSalesforceContextVariables,
} from '../handoff-configuration/zendesk/helpers';
import isEqual from 'lodash/fp/isEqual';
import pick from 'lodash/fp/pick';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { validationSchema } from 'src/pages/workflow-builder-edit/customization-panel/constants';
import {
  buildSalesforceCaseCreationInitialFormValue,
  cleanSalesforceCaseCreationCustomizationData,
} from 'src/pages/workflow-builder-edit/customization-panel/helpers';
import { SalesforceCaseCreationConfiguration } from 'src/pages/workflow-builder-edit/handoff-configuration';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  SalesforceCaseCreationCustomization,
  SalesforceHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface SalesforceCaseCreationCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SalesforceCaseCreationCustomizationPanel: FC<
  React.PropsWithChildren<SalesforceCaseCreationCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();

  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultSalesforceHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<SalesforceHandoffCustomization>;
  const defaultSalesforceCaseCreationCustomizationSettings = useMemo(
    () => ({
      add_transcript_comment: false,
      case_number_output_variable: null,
      create_email_message: false,
      email: '',
      name: '',
      question: 'free_form_query',
      ticket_creation_settings: {
        ticket_creation_config: {
          fields_and_values: [
            {
              id: '',
              value: '',
            },
          ],
          ticket_fields: [],
          ticket_title: '',
          update_flamethrower_conversation_deflection_status: true,
          ...defaultSalesforceHandoffCustomizationSettings
            .ticket_creation_settings?.ticket_creation_config,
        },
      },
      ticket_id_output_variable: null,
    }),
    [
      defaultSalesforceHandoffCustomizationSettings.ticket_creation_settings
        ?.ticket_creation_config,
    ],
  );
  const handoffCustomization = addingAction
    ? defaultSalesforceCaseCreationCustomizationSettings
    : (canvasState?.actionSettings
        .configuration_fields as SalesforceCaseCreationCustomization);

  const [customizationData, setCustomizationData] =
    useState<SalesforceCaseCreationCustomization>(handoffCustomization);

  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(
        addingAction
          ? defaultSalesforceCaseCreationCustomizationSettings
          : handoffCustomization,
      );
    }
  }, [
    canvasState.isLoadingCustomization,
    handoffCustomization,
    addingAction,
    defaultSalesforceCaseCreationCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.SALESFORCE_CASE_CREATION,
        ),
      );
    }
  }, [dispatch, addingAction]);

  if (
    canvasState.isLoadingCustomization ||
    !handoffCustomization ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Formik<SalesforceCaseCreationCustomization>
      initialValues={buildSalesforceCaseCreationInitialFormValue(
        customizationData,
      )}
      onSubmit={(values, { resetForm }) => {
        const cleanedValues =
          cleanSalesforceCaseCreationCustomizationData(values);

        dispatch(
          addingAction
            ? addAction({
                ...addingAction,
                data: {
                  ...addingAction.data,
                  configuration_fields: cleanedValues,
                },
              })
            : updateActionSettings({
                action_type: canvasState.actionSettings.action_type as string,
                configuration_fields: cleanedValues,
              }),
        );
        dispatchActionEditedTrackingEvent({
          actionType: ActionBuilderActionTypes.SALESFORCE_CASE_CREATION,
        });
        resetForm({
          values: cleanedValues,
        });
        setActionPanelVisibilityParameters('hidden');
        setAreActionSettingsUnsaved(false);
        dispatch(setCustomizableActionId(''));
        onDismiss();
      }}
      validationSchema={validationSchema}
    >
      {props => (
        <Contents
          addingAction={addingAction}
          customizationData={customizationData}
          handoffCustomization={handoffCustomization}
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
          {...props}
        />
      )}
    </Formik>
  );
};

export default SalesforceCaseCreationCustomizationPanel;

const Contents = ({
  addingAction,
  customizationData,
  dirty,
  errors,
  handoffCustomization,
  hasWorkflowConflict,
  onDiscard,
  setAreActionSettingsUnsaved,
  setValues,
  values,
}: FormikProps<SalesforceCaseCreationCustomization> & {
  addingAction: CanvasWorkflowBuilderState['addingAction'];
  customizationData: SalesforceCaseCreationCustomization;
  handoffCustomization: SalesforceCaseCreationCustomization;
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}) => {
  const FormObserver: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setAreActionSettingsUnsaved(dirty);
    }, [dirty]);

    return null;
  };

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  useEffect(() => {
    setValues(buildSalesforceCaseCreationInitialFormValue(customizationData));
  }, [customizationData, setValues]);

  const errorMessages = validateZendeskOrSalesforceContextVariables({
    customizationData: values,
  });

  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  const isPristine = isEqual(
    values,
    pick(Object.keys(values), handoffCustomization),
  );

  return (
    <FormWrapper translate='yes'>
      <FormObserver />
      <Box display='flex' flexDirection='column' height='100%'>
        <SalesforceCaseCreationConfiguration
          customizationData={values}
          errorMessages={errorMessages}
          isFieldTouchedArr={isFieldTouchedArr}
          setCustomizationData={setValues}
          setIsFieldTouchedArr={setIsFieldTouchedArr}
        />
        <SaveContainer hasTopBorder>
          <SecondaryButton
            onClick={() => {
              onDiscard();
            }}
            type='button'
          >
            Cancel
          </SecondaryButton>
          <StyledButton
            disabled={
              Object.values(errors).filter(err => !!err).length > 0 ||
              hasWorkflowConflict ||
              Boolean(questionErrorMessage) ||
              (!addingAction && isPristine && !dirty)
            }
            onClick={() => {
              setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));

              if (hasAtLeastOneError(errorMessages)) {
                return;
              }
            }}
            type='submit'
          >
            Save
          </StyledButton>
        </SaveContainer>
      </Box>
    </FormWrapper>
  );
};

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FormWrapper = styled(Form)`
  height: 100%;
`;
FormWrapper.defaultProps = { noValidate: true };
