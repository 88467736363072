import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitConfigForNonRTE } from '../hooks/useDebouncedSubmitConfig';
import { InstructionTabForm } from './InstructionTabForm';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectPlaybookConfiguration,
  selectWidgetConfiguration,
  setKnowledgeConfigurationPrompt,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

const InstructionTabContent = ({
  isPlaybook = false,
}: {
  isPlaybook?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    knowledge_configuration_prompt: widgetKnowledgeConfigurationPrompt,
    ...widgetConfiguration
  } = useSelector(selectWidgetConfiguration);
  const {
    knowledge_configuration_prompt: playbookKnowledgeConfigurationPrompt,
    ...playbookConfiguration
  } = useSelector(selectPlaybookConfiguration);
  const { data } = useGetFeatureFlagsQuery();
  const debouncedSubmitConfig = useDebouncedSubmitConfigForNonRTE({
    isPlaybook,
  });

  const knowledgeConfigurationPrompt = isPlaybook
    ? playbookKnowledgeConfigurationPrompt
    : widgetKnowledgeConfigurationPrompt;

  const [prompt, setPrompt] = useState(knowledgeConfigurationPrompt);

  const onChange = (value: string) => {
    setPrompt(value);
    dispatch(
      setKnowledgeConfigurationPrompt({
        isPlaybook,
        shouldUseLongerNoteLength: Boolean(
          data?.feature_flags.includes('allow_longer_instructional_note'),
        ),
        value,
      }),
    );
    debouncedSubmitConfig(
      {
        ...(isPlaybook ? playbookConfiguration : widgetConfiguration),
        knowledge_configuration_prompt: value,
      },
      'knowledge_configuration_prompt',
      value,
    );
  };

  const description = isPlaybook ? (
    <Typography variant='font14'>
      The instructional note for Knowledge Retrieval helps the AI deliver
      engaging, relevant, and personalized{' '}
      <b>responses tailored to agents&apos; unique needs,</b> ensuring
      meaningful and effective interactions.
    </Typography>
  ) : (
    <Typography variant='font14'>
      The <b>instructional note for Knowledge Retrieval</b> helps the AI deliver
      engaging, relevant, and personalized responses that address users&apos;
      unique needs, guiding the bot to create meaningful, tailored interactions.
      Summarize key points that will assist the bot in generating more
      personalized conversations and improving user experience.
    </Typography>
  );

  return (
    <InstructionTabForm
      description={description}
      onChange={onChange}
      value={prompt}
    />
  );
};

export default InstructionTabContent;
