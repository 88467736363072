import { css, styled } from '@mui/material';
import Box from '@mui/material/Box';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';

export const Icon = styled('img')`
  margin-right: 24px;
  max-height: 54px;
`;

export const ActionName = styled('h1')`
  margin: 0;
  ${textMediumStyle}
`;

export const ActionDescription = styled('span')`
  ${textRegularStyle({ fontSize: '14px' })}
`;

export const InputsContainer = styled(Box)`
  padding: 0 24px 16px 24px;
  overflow-y: auto;
  max-height: 600px;
`;

export const InputContainer = styled('div')`
  margin-bottom: 8px;
`;

export const Label = styled('label')<{ margin?: string }>`
  ${textMediumStyle({ fontSize: '14px' })};
  text-align: left;
  padding-bottom: 8px;
  display: block;
  ${props => props.margin && `margin: ${props.margin};`};
`;

export const SaveContainer = styled('div')`
  border-top: 1px solid ${theme.palette.colors.slate[100]};
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const RemoveButton = styled('img')<{
  isDisabled?: boolean;
}>`
  height: 30px;
  padding: 6px;
  width: 30px;
  border-radius: var(--border-radius-button);
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
`;

export const FieldContainer = styled('div')`
  display: border-box;
  display: grid;

  grid-template-columns: 1fr 0.75fr 0.25fr;
  img {
    justify-self: end;
    align-self: center;
  }
`;

export const SnowflakeOutputField = styled('div')`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-left: 8px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${Colors.ui.text.tertiary};
    margin: 0;
    font-size: 14px;
  }
`;

const formStyles = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
`;

export const ActionTemplateForm = styled('form')`
  ${formStyles};
`;
