import { ChangeEventHandler, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Checkbox,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FormWithErrors } from '../types';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useGetContextVariablesForTextEditor } from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/hooks';
import { AttachmentAnalysisUploadStepFields } from 'src/slices/canvas-workflow-builder/types/dataTypes';
import {
  selectEditingStepFields,
  setStepFieldValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { isAttachmentUploadAnalysisStepFields } from 'src/utils/solve/stepUtils';

export const AttachmentAnalysisUploadForm = ({
  errorObject,
}: FormWithErrors<AttachmentAnalysisUploadStepFields>) => {
  const remirrorContextVariables = useGetContextVariablesForTextEditor();
  const { palette } = useTheme();
  const [touched, setTouched] = useState(false);

  const editorRef = useRef<EditorRef>(null);

  const dispatch = useAppDispatch();
  const fields = useSelector(selectEditingStepFields);

  const filterFn = useCallback(
    (cv: ContextVariable) => cv.context_variable_type === 'FLAT_LIST',
    [],
  );

  if (!isAttachmentUploadAnalysisStepFields(fields)) {
    return null;
  }

  const { allow_skip_attachment, num_files, output_variable, prompt } = fields;

  const setNumFiles: ChangeEventHandler<HTMLInputElement> = e => {
    setTouched(true);
    dispatch(
      setStepFieldValue({
        field: 'num_files',
        value: e.target.valueAsNumber,
      }),
    );
  };

  const setOutputVariable = (cv: string) => {
    setTouched(true);
    dispatch(
      setStepFieldValue({
        field: 'output_variable',
        value: cv,
      }),
    );
  };

  const setPrompt = (prompt: string) => {
    setTouched(true);
    dispatch(setStepFieldValue({ field: 'prompt', value: prompt }));
  };

  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <Typography color={palette.colors.grey[700]} variant='font14'>
        This feature allows your customers to upload attachments like text files
        or images. We automatically analyze the content and generate responses
        as the files are routed through a Subflow to the predefined Autoflow
        policy. This streamlines the process, saves time, and integrates
        insights seamlessly into your workflows.
      </Typography>
      <Box bgcolor={palette.colors.slate[200]} height='1px' />
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='font14Bold'>
          Attachment uploading message
        </Typography>
        <Box
          border={`1px solid ${
            errorObject?.prompt && touched
              ? palette.colors.red[500]
              : palette.colors.grey[300]
          }`}
          borderRadius='4px'
        >
          <RichTextEditor
            contextVariables={remirrorContextVariables}
            editorRef={editorRef}
            errorMessage={touched ? errorObject?.prompt : undefined}
            initialContent={prompt}
            label='Forms message'
            onChange={setPrompt}
            placeholder='Write an attachment uploading message for your customers...'
          />
        </Box>
      </Box>
      <TextField
        error={touched && (errorObject?.num_files as string | undefined)}
        label='Maximum number of files to upload'
        onChange={setNumFiles}
        placeholder='Enter maximum number value'
        type='number'
        value={String(num_files)}
      />
      <ContextVariableSelectDropdown
        contextVariableTypeOptions={[
          { label: 'File list', value: 'FLAT_LIST' },
        ]}
        error={touched && errorObject?.output_variable}
        filterFn={filterFn}
        label='Attachment output'
        onChange={setOutputVariable}
        value={output_variable}
      />
      <Checkbox
        checked={allow_skip_attachment}
        label='Allow Skip Attachment'
        onChange={e => {
          dispatch(
            setStepFieldValue({
              field: 'allow_skip_attachment',
              value: e.target.checked,
            }),
          );
        }}
      />
    </Box>
  );
};
