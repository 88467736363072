import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';

import {
  elevations,
  VerticalTabBar,
} from '@forethought-technologies/forethought-elements';
import {
  EMAIL_TABS,
  GENERAL_EMAIL_SETTINGS_TAB,
  INSTRUCTIONAL_NOTE_TABS,
} from './constants';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

const EmailConfigurationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userRole = useSelector(selectUserRole);
  const { data: featureFlags } = useGetFeatureFlagsQuery();

  const tabs = useMemo(() => {
    const baseTabs = [...EMAIL_TABS];

    if (
      featureFlags?.feature_flags.includes('solve_email_instructional_note')
    ) {
      baseTabs.push(...INSTRUCTIONAL_NOTE_TABS);
    }

    if (userRole === 'ROLE_SUPER_ADMIN') {
      baseTabs.push(GENERAL_EMAIL_SETTINGS_TAB);
    }
    return baseTabs;
  }, [userRole, featureFlags]);

  const tabIndex = useMemo(() => {
    const initialTabIndex = tabs.findIndex(
      tab => tab.id === searchParams.get('tab'),
    );
    return Math.max(initialTabIndex, 0);
  }, [searchParams, tabs]);

  const handleTabClick = useCallback(
    (index: number) => {
      const tab = tabs[index].id;
      setSearchParams(prevParams => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('tab', tab);
        return newParams;
      });
    },
    [setSearchParams, tabs],
  );

  const isFullWidth = tabs[tabIndex]?.isFullWidth ?? false;

  return (
    <ToolbarContainer
      isFullScreen={isFullWidth}
      isTabSelected={typeof tabIndex === 'number'}
    >
      <VerticalTabBar
        setTabIndex={handleTabClick}
        tabBodyWidth={520}
        tabIndex={tabIndex}
        tabs={tabs}
      />
    </ToolbarContainer>
  );
};

export default EmailConfigurationPage;

const ToolbarContainer = styled('div')<{
  isFullScreen: boolean;
  isTabSelected: boolean;
}>`
  background-color: ${props => props.theme.palette.colors.white};
  box-shadow: ${elevations.z3};
  flex: ${props =>
    props.isTabSelected ? (props.isFullScreen ? 1 : '0 0 480px') : 0};
  position: sticky;
  top: 0;
  height: 100%;
`;
