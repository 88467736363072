import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

export const handleShowUpdateButton = (
  article: KnowledgeGapArticleRecommendation | null,
) => {
  if (
    !article ||
    !article.updated_article_id ||
    !article.updated_article_body ||
    !article.updated_article_title
  ) {
    return false;
  }
  return true;
};
