import { JSONSchema3or4 } from 'to-json-schema';

import { SolveWidgetProduct } from './types';
import { SetContextVariableCustomizationSettings } from './workflowBuilderAPITypes';
import { ActionBuilderTemplate } from 'src/utils/constants';
import {
  ActionBuilderActionTypes,
  APITemplateTypes,
  ContextVariableTypes,
} from 'src/utils/enums';

export interface Action {
  action_description: string;
  action_fields: ActionFields;
  action_id: string;
  action_name: string;
  action_type: `${ActionBuilderActionTypes}`;
  icon_url?: string;
  modified_date?: string;
}

const handoffActions: Readonly<Action['action_type'][]> = [
  ActionBuilderActionTypes.BRAND_EMBASSY_HANDOFF,
  ActionBuilderActionTypes.CATCH_ALL_HANDOFF,
  ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF,
  ActionBuilderActionTypes.FRESHDESK_TICKET_CREATION,
  ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION,
  ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF,
  ActionBuilderActionTypes.TALKDESK_CHAT_HANDOFF,
  ActionBuilderActionTypes.INTERCOM_HANDOFF,
  ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST,
  ActionBuilderActionTypes.KUSTOMER_HANDOFF,
  ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF,
  ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION,
  ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_CASE_CREATION,
  ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF,
  ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2,
  ActionBuilderActionTypes.ZENDESK_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_TICKET_CREATION,
  ActionBuilderActionTypes.FRONT_TICKET_CREATION,
  ActionBuilderActionTypes.ZAMMAD_TICKET_CREATION,
  ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF,
  ActionBuilderActionTypes.SUNCO_LIVE_CHAT,
  ActionBuilderActionTypes.END_INTERACTIVE_EMAIL_CHAT,
] as const;

export function isHandoffActionType(
  actionType: Action['action_type'],
): boolean {
  return handoffActions.includes(actionType);
}

export function isApiActionType(actionType: Action['action_type']): boolean {
  return actionType === ActionBuilderActionTypes.API;
}

export function isOtherActionType(actionType: Action['action_type']): boolean {
  return !isHandoffActionType(actionType) && !isApiActionType(actionType);
}

export type ActionPanelMode = CustomizableActions | 'hidden';

export type CustomizableActions =
  | ActionBuilderActionTypes.ARTICLE_SUGGESTION
  | ActionBuilderActionTypes.INTERCOM_HANDOFF
  | ActionBuilderActionTypes.SALESFORCE_HANDOFF
  | ActionBuilderActionTypes.SALESFORCE_CASE_CREATION
  | ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF
  | ActionBuilderActionTypes.ZENDESK_HANDOFF
  | ActionBuilderActionTypes.CATCH_ALL_HANDOFF
  | ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST
  | ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF
  | ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2
  | ActionBuilderActionTypes.ZENDESK_TICKET_CREATION
  | ActionBuilderActionTypes.FRONT_TICKET_CREATION
  | ActionBuilderActionTypes.ZAMMAD_TICKET_CREATION
  | ActionBuilderActionTypes.HYPERLINK_REDIRECT
  | ActionBuilderActionTypes.KUSTOMER_HANDOFF
  | ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF
  | ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION
  | ActionBuilderActionTypes.TRIGGER_EVENT
  | ActionBuilderActionTypes.FRESHDESK_TICKET_CREATION
  | ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR
  | ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF
  | ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2
  | ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF
  | ActionBuilderActionTypes.TALKDESK_CHAT_HANDOFF
  | ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF
  | ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF
  | ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF
  | ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING
  | ActionBuilderActionTypes.ZENDESK_ATTACHMENT_UPLOAD
  | ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF
  | ActionBuilderActionTypes.PARSE_JWT
  | ActionBuilderActionTypes.SET_CONTEXT_VARIABLE
  | ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF
  | ActionBuilderActionTypes.SUNCO_LIVE_CHAT
  | ActionBuilderActionTypes.DYNAMIC_CARD
  | ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION
  | ActionBuilderActionTypes.INTERCOM_TICKET_CREATION
  | ActionBuilderActionTypes.INTERCOM_LIVE_CHAT;

export type ActionFields =
  | ShopifyActionTemplateFields
  | SnowflakeDataQueryTemplateFields
  | ActionBuilderAPIActionFields
  | SetContextVariableCustomizationSettings
  | TemplateAPIActionFields;

export interface ShopifyActionTemplateFields {
  customer_email: string;
  customer_id: string;
  output_variable?: string;
  password: string;
  url: string;
  username: string;
}

export interface SnowflakeDataQueryTemplateFields {
  account: string;
  data_table_name: string;
  db_name: string;
  output_fields?: {
    kv_list: { key: string; value: string }[];
  };
  password: string;
  query_condition?: string;
  username: string;
}

export interface ShopifySelectedActionTemplate
  extends Omit<Action, 'action_fields' | 'action_id'> {
  action_fields: ShopifyActionTemplateFields;
  action_id?: string;
  action_type: ActionBuilderActionTypes.SHOPIFY_ORDER_STATUS_TEMPLATE;
}

export interface ApiActionSelectedActionTemplate {
  action_description: string;
  action_name: string;
  action_type: APITemplateTypes;
  icon: string;
}

export type SelectedActionTemplate =
  | ShopifySelectedActionTemplate
  | ApiActionSelectedActionTemplate;

export interface ActionBuilderAction {
  actions: Action[];
}

export interface ActionBuilderActionRequest {
  product?: SolveWidgetProduct;
  shouldReturnAll?: boolean;
}

export interface SelectedAction
  extends Omit<Action, 'action_fields' | 'action_id'> {
  action_fields: ActionBuilderReduxActionFields;
  action_id?: string;
}

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export interface ActionBuilderAPIActionFields {
  authorization_config_id?: string | null;
  authorization_integration?: string | null;
  body_parameters?: string | null;
  connector_id?: string | null;
  headers?: {
    [key: string]: string;
  } | null;
  jmespath_query?: string | null;
  method: string;
  natural_language_query?: string | null;
  output_context_variables?: Record<string, string>;
  query_parameters?: {
    [key: string]: string;
  } | null;
  url: string;
}

export interface TemplateAPIActionFields extends ActionBuilderAPIActionFields {
  mock_responses: Record<
    string,
    { response: Record<string, unknown>; status_code: number }
  >;
}

export function areActionBuilderFields(
  actionFields: ActionFields,
): actionFields is ActionBuilderAPIActionFields {
  return 'output_context_variables' in actionFields;
}

export function isTemplateApiActionFields(
  actionFields: ActionFields | Record<string, never> = {},
): actionFields is TemplateAPIActionFields {
  return 'mock_responses' in actionFields;
}

export function areShopifyFields(
  actionFields: ActionFields,
): actionFields is ShopifyActionTemplateFields {
  return 'customer_email' in actionFields && 'customer_id' in actionFields;
}

export function areSnowflakeFields(
  actionFields: ActionFields,
): actionFields is SnowflakeDataQueryTemplateFields {
  return 'query_condition' in actionFields;
}

export interface KeyValuePairsDict {
  name: string;
  value: string;
}

export interface ActionBuilderReduxActionFields
  extends Omit<ActionBuilderAPIActionFields, 'query_parameters' | 'headers'> {
  headers: KeyValuePairsDict[];
  query_parameters: KeyValuePairsDict[];
}

export interface AuthorizationConfig {
  authorization_fields: Record<string, string>;
  authorization_id: string;
  authorization_type: string;
}

export interface SettingsInterface {
  authorization_configs: AuthorizationConfig[];
  available_workflow_tags?: Array<string>;
  context_variables: ContextVariable[];
  template_context_variables?: ContextVariable[];
  usable_system_context_variables?: ContextVariable[];
}

export interface ContextVariableUsagesResponse {
  context_variable_usage_by_entity_id: Record<
    string,
    { context_variable_id: string; entity_id: string }[]
  >;
  context_variable_usage_by_entity_type: ContextVariableUsageByEntityType;
  list_cvs_values_usage_in_expression: ListContextVariableUsageByValues;
}

export interface ContextVariableUsageByEntityType {
  [contextVariableId: string]: {
    context_variable_id: string;
    usage_by_entity_type: UsageByEntityType;
  };
}

export interface ListContextVariableUsageByValues {
  [contextVariableId: string]: string[];
}

export type EntityType =
  | 'action'
  | 'email_configuration'
  | 'email_workflow'
  | 'ticket_field_mapping'
  | 'widget_workflow'
  | 'autoflow_workflow';

export type UsageByEntityType = {
  [key in EntityType]: boolean;
};

interface ListOption {
  label: string;
  value: string;
}

export interface DynamicListOption {
  // id will only exist if this represents a primitive type and can be used as a cv
  context_variable_id: string | null;
  context_variable_name: string;
  // options will only exist if this is a complex type, it will not have an id and cannot be used as a cv
  options: DynamicListOption[] | null;
}

export interface DynamicListConfig {
  context_variables?: DynamicListOption[];
  json_schema: Record<string, unknown>;
}

export interface ContextVariableConfigurationTypes {
  dynamic_list_config?: DynamicListConfig | null;
  is_alphanumeric?: boolean;
  max_length?: number;
  min_length?: number;
}

export interface ContextVariable {
  configuration_fields?: ContextVariableConfigurationTypes;
  context_variable_id: string;
  context_variable_name: string;
  context_variable_type: keyof typeof ContextVariableTypes;
  created_date?: string;
  default_value?: unknown;
  disabled?: boolean;
  is_system_context_variable?: boolean;
  is_universal_context_variable: boolean;
  is_workflow_context?: boolean;
  list_type_options?: ListOption[];
}

export type CreateContextVariableRequest = Omit<
  ContextVariable,
  'context_variable_id' | 'created_date' | 'is_universal_context_variable'
>;

export interface AddOrUpdateActionInterface {
  action_description: string;
  action_fields: ActionFields;
  action_name: string;
  action_type: string;
}

export interface OutputParameterKeysType {
  [key: string]: string | null;
}

export interface AdditionalDetailsModalActionData {
  action_description?: string;
  action_name: string;
}

export interface ActiveIntegrationsInterface {
  integrations: ActiveIntegration[];
}

export interface ActionUsagesResponse {
  action_id_to_intent_ids: Record<string, string[]>;
}
export interface ActiveIntegration {
  connector_display_name: string;
  connector_id: string;
  emblem_url: string;
  integration: string;
}

export interface ProxyTestRequest {
  authorization_integration?: string | null;
  body?: object;
  connector_id?: string | null;
  headers?: object;
  http_method: string;
  query_parameters?: object;
  url: string;
}

export interface ProxyTestResponse {
  body: object;
  status_code: number;
}

export interface KVPairs {
  key: string;
  type?: 'string' | 'number' | 'boolean' | 'object';
  value: string | undefined;
}

export interface DynamicListKVPairs extends KVPairs {
  jsonSchema: JSONSchema3or4;
  newContextVariableName: string;
}

export type OutputValues = KVPairs | DynamicListKVPairs;

export type AuthorizationType = 'bearer' | 'integration';
export type AuthorizationField = {
  connectorId?: string;
  id: string;
  type: AuthorizationType;
} | null;
export type ContentType = 'form-url' | 'json' | 'none';

export interface SelectedActionDetail {
  actionId?: string;
  description: string;
  fields: {
    authorization: AuthorizationField;
    body: string | null;
    contentType: ContentType;
    headers: KVPairs[];
    jmespathQuery: string;
    method: Method;
    params: KVPairs[];
    url: string;
  };
  name: string;
  outputValues: OutputValues[];
  tabId: string;
}

export interface ActionBuilderCollectionTemplates {
  templates: ActionBuilderTemplate[];
}

export interface ValidateJmesPathRequest {
  expression: string;
  test_data: unknown;
}

export interface ValidateJmesPathResponse {
  error: string | null;
  expression: string;
  result: unknown;
  test_data: unknown;
  valid: boolean;
}
