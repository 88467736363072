import React, { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { InsightPeriod } from '../solve-insights/types';
import {
  renderLineGraphUtils,
  replaceChatsWithConversations,
} from 'src/components/solve-insights-charts/line-graph/lineGraphUtils';
import { NA } from 'src/constants/solve';
import { useGetWorkflowDetailChartQuery } from 'src/services/insights';
import {
  InsightWorkflowDetailChart,
  LineGraphLabelType,
} from 'src/services/insights/types';
import { formatReadableDate } from 'src/utils/dateUtils';

interface Props {
  end: number;
  period: InsightPeriod;
  start: number;
  workflowId: string;
}

const defaultVisibleSeries = [
  'Deflections',
  'Deflected rate',
  'Avg. CSAT',
] as const satisfies LineGraphLabelType[];

export const WorkflowDetailLineChart: React.FC<
  React.PropsWithChildren<Props>
> = ({ end, period, start, workflowId }) => {
  const { palette } = useTheme();
  const {
    data: lineData,
    isFetching,
    isLoading,
  } = useGetWorkflowDetailChartQuery({
    end,
    period,
    start,
    workflow_id: workflowId,
  });

  const [visibleSeries, setVisibleSeries] =
    useState<LineGraphLabelType[]>(defaultVisibleSeries);

  const handleToggleVisibility = useCallback((label: LineGraphLabelType) => {
    setVisibleSeries(prev =>
      prev.includes(label)
        ? prev.filter(existingLabel => existingLabel !== label)
        : [...prev, label],
    );
  }, []);

  // replace labels
  const data: InsightWorkflowDetailChart =
    replaceChatsWithConversations(lineData);

  // match OverviewLineChart.tsx but no comparison
  const transformedData = useMemo(
    () =>
      data && data.line && data.line.x_axis.length > 0
        ? {
            ...data,
            line: {
              ...data.line,
              values: data.line.values.map((value, index) => {
                return {
                  ...value,
                  tooltip_values: [
                    {
                      category: `${formatReadableDate(
                        new Date(start * 1000),
                      )} - ${formatReadableDate(new Date(end * 1000))}`,
                      extended_labels: value.extended_labels,
                      index: index,
                      tooltip_values: value.tooltip_values,
                    },
                  ],
                  visible: visibleSeries.includes(value.label),
                } as const;
              }),
            },
          }
        : undefined,
    [data, end, start, visibleSeries],
  );

  if (isLoading || isFetching) {
    return <Skeleton height='275px' width='100%' />;
  }

  if (!transformedData) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  return renderLineGraphUtils(
    palette,
    transformedData,
    'detail',
    handleToggleVisibility,
  );
};
