import { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FormattedAutomation } from '../workflow-builder-config/email/automation-list-item/FormattedAutomation';
import { ArticleLinks } from './components/ArticleLinks';
import { EmailTranscript } from './components/EmailTranscript';
import { InteractiveEmailCard } from './components/InteractiveEmailCard';
import { useEmailBreakdownMetricsRow } from './hooks/useEmailBreakdownMetricsRow';
import { ALLTIME_END, ALLTIME_START } from './constants';
import { Card, Divider, InlineData, TextDivider } from './ConversationDrawer';
import { formatTimestamp } from './helpers';
import IntentBadge from './IntentBadge';
import {
  AutomationEventData,
  EmailConversationTableRow,
} from 'src/services/apiInterfaces';
import { useGetEmailConversationQuery } from 'src/services/workflow-builder-metrics';

export function EmailConversationDrawer({
  onClose,
  selectedConversation,
}: {
  onClose: () => void;
  selectedConversation?: EmailConversationTableRow;
}) {
  const isOpen = !!selectedConversation?.conversation_id;

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      {isOpen && <DrawerBody selectedConversation={selectedConversation} />}
    </Drawer>
  );
}

function DrawerBody({
  selectedConversation,
}: {
  selectedConversation: EmailConversationTableRow;
}) {
  const {
    conversation_id: conversationId,
    created_date: createdDate,
    deflected,
    dynamic_article_suggestion_events: dynamicArticleSuggestionEvents,
    intent_definition_id: intentId,
    intent_title: intentTitle,
    interactive_email_deflection_status: interactiveEmailDeflectionStatus,
    potential_attempt: potentialAttempt,
    response_sent_date: responseSentDate,
    skip_reason: skipReason,
    source_body: sourceBody,
    source_id: sourceId,
    source_title: sourceTitle,
  } = selectedConversation;

  const { palette } = useTheme();

  // fetch breakdown by intent for total inquiries and deflection rate
  const { data: breakdownResponse, isLoading: loadingBreakdown } =
    useEmailBreakdownMetricsRow({
      from: ALLTIME_START,
      intentId,
      to: ALLTIME_END,
    });

  const totalConversations = breakdownResponse?.attempted?.toLocaleString();
  const totalDeflectionRate =
    breakdownResponse?.responded_deflection_rate.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      style: 'percent',
    });

  const flatArticles = dynamicArticleSuggestionEvents.flatMap(
    event => event.articles,
  );

  const { data: conversationData } = useGetEmailConversationQuery({
    conversation_id: conversationId,
  });

  const noResponseAutomation = useMemo(() => {
    if (skipReason !== 'email_automation') {
      return null;
    }

    const executionEvent =
      selectedConversation.email_workflow_execution_events.find(event => {
        return (
          event.event_type === 'automation_triggered' &&
          (event.event_data as AutomationEventData).automation_action_type ===
            'no-response'
        );
      });

    if (!executionEvent) {
      return null;
    }

    const queryExpression = (executionEvent.event_data as AutomationEventData)
      .query_expression;

    return (
      <FormattedAutomation
        automationAction={{
          automation_action_fields: {},
          automation_action_type: 'no-response',
        }}
        queryExpression={queryExpression}
        size='small'
      />
    );
  }, [skipReason, selectedConversation]);

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Conversation Details</Typography>
      </Box>
      <Box py='24px'>
        <Box display='flex' flexDirection='column' gap='30px'>
          <Box>
            {sourceId && <InlineData label='Ticket ID' value={sourceId} />}
            <InlineData label='Conversation ID' value={conversationId} />
            <InlineData
              label='Deflected'
              value={deflected ? 'True' : 'False'}
            />
          </Box>
          {!!potentialAttempt && (
            <Box display='flex' flexDirection='column' gap='10px'>
              <Box alignItems='center' display='flex' gap='4px'>
                <Typography variant='font11'>Intent Detected</Typography>
              </Box>
              <Card>
                <Box mb='8px'>
                  <IntentBadge
                    channel='email'
                    intentDefinitionId={intentId}
                    intentName={intentTitle}
                  />
                </Box>
                <TextDivider size='small' text='All-time metrics' />
                <InlineData
                  isLoading={loadingBreakdown}
                  label='Total # of Responses'
                  value={totalConversations}
                />
                <InlineData
                  isLoading={loadingBreakdown}
                  label='Responded Deflection Rate'
                  value={totalDeflectionRate}
                />
              </Card>
            </Box>
          )}
          <Box display='flex' flexDirection='column' gap='10px'>
            <Typography variant='font11'>Conversation</Typography>
            <Card>
              <InlineData
                label='Time created'
                value={formatTimestamp(createdDate)}
              />
              <InlineData
                label='Eligible'
                value={!!potentialAttempt ? 'True' : 'False'}
              />
              {!potentialAttempt && skipReason && (
                <>
                  <InlineData label='No attempt reason' value={skipReason} />
                  {noResponseAutomation && (
                    <Box display='flex' flexDirection='column' gap='6px'>
                      <Typography
                        color={palette.colors.grey[600]}
                        variant='font12Medium'
                      >
                        Automation:
                      </Typography>
                      <Typography variant='font14Medium'>
                        {noResponseAutomation}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              {sourceTitle && (
                <>
                  <Divider />
                  <Box display='flex' flexDirection='column' gap='6px'>
                    <Typography
                      color={palette.colors.grey[600]}
                      variant='font12Medium'
                    >
                      Subject:
                    </Typography>
                    <Typography variant='font14Medium'>
                      {sourceTitle}
                    </Typography>
                  </Box>
                </>
              )}
              {flatArticles?.length > 0 && (
                <>
                  <Divider />
                  <InlineData
                    label='Total article suggestions'
                    value={flatArticles.length}
                  />
                  <ArticleLinks articles={flatArticles} />
                </>
              )}
              {sourceBody && (
                <Box display='flex' flexDirection='column' pt='16px'>
                  <TextDivider size='large' text='Incoming email' />
                  <Box sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    <Typography
                      color={palette.colors.slate[700]}
                      component='p'
                      variant='font14Medium'
                    >
                      {sourceBody}
                    </Typography>
                  </Box>
                </Box>
              )}

              {responseSentDate && (
                <EmailTranscript conversationId={conversationId} />
              )}
            </Card>
          </Box>
          {interactiveEmailDeflectionStatus !== null && conversationData && (
            <InteractiveEmailCard conversationData={conversationData} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
