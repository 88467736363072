import { useLocation } from 'react-router';

import {
  dateRangeToTimestamp,
  getKeyValueFromFilterQuery,
  isUiQueryError,
} from '../helpers';
import { InsightsTab } from '../types';
import { ALLTIME_START } from 'src/pages/intent-conversation-analytics/constants';
import useIsTopMetricFiltersEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsInsightTopMetricFiltersEnabled';
import { DateRange } from 'src/types/types';

export const useGetFiltersFromSearch = ({
  dateRange,
  tab,
}: {
  dateRange: DateRange;
  tab?: InsightsTab;
}) => {
  const { search } = useLocation();
  const isTopMetricFiltersEnabled = useIsTopMetricFiltersEnabled();
  // If feature flag is not used, it will not use any of the filters
  if (!isTopMetricFiltersEnabled) {
    return {};
  }
  const filters: Record<string, string | string[]> = {};
  const searchParams = new URLSearchParams(search);
  searchParams.forEach((value, key) => {
    /**
     * Needs to be splitted differently for intent_filters
     * item = ["autonomous_agent_used.true", "workflow_id.57fce4cb-be81-4448-8c93-a5eb291ed6bc", "workflow_id.02bdecc9-5e00-4086-a0dc-24310e539808"]
     * converted to
     * {
     *  autonomous_agent_used: ['true'],
     *  workflow_id: ['57fce4cb-be81-4448-8c93-a5eb291ed6bc', '02bdecc9-5e00-4086-a0dc-24310e539808']
     * }
     */
    if (key === `intent_filters_${tab}`) {
      const items = value.split(',');
      items.forEach(item => {
        const { key: newKey, value: newKeyValue } = getKeyValueFromFilterQuery({
          filterString: item,
        });
        filters[newKey] = filters[newKey]
          ? [...filters[newKey], newKeyValue]
          : [newKeyValue];
      });
    } else if (key === `intent_filters_cv_${tab}`) {
      filters[key] = value;
    } else if (value) {
      filters[key] = value.split(',').map(item => item.split('.')[1] || item);
    }
  });

  // General filters that are common between all tabs
  const generalKeysAllowed = [
    'channels',
    'autonomous_agent_used',
    'workflow_id',
    'csat_ratings',
    'relevance_ratings',
    'dropoff_ratings',
    'query_exists',
    'deflection',
    'workflow_tags',
    'topic_ids',
    'intent_id',
  ];

  const filterByTab = (allowedKeys: string[]) => {
    return Object.fromEntries(
      Object.entries(filters).filter(([key]) => allowedKeys.includes(key)),
    );
  };

  const commonFilters = (
    filteredFilters: Record<string, string | string[]>,
  ) => ({
    ...(filteredFilters.autonomous_agent_used?.length === 1 && {
      autonomous_agent_used: filteredFilters.autonomous_agent_used[0],
    }),
    ...(filteredFilters.channels && {
      channels: filteredFilters.channels as string,
    }),
    ...(filteredFilters.workflow_id && {
      workflow_id: filteredFilters.workflow_id,
    }),
    ...(filteredFilters.csat_ratings && {
      csat_ratings: filteredFilters.csat_ratings,
    }),
    ...(filteredFilters.query_exists?.length === 1 && {
      query_exists: filteredFilters.query_exists[0],
    }),
    ...(filteredFilters.relevance_ratings && {
      relevance_ratings: filteredFilters.relevance_ratings,
    }),
    ...(filteredFilters.dropoff_ratings && {
      dropoff_ratings: filteredFilters.dropoff_ratings,
    }),
    ...(filteredFilters.workflow_tags && {
      workflow_tags: filteredFilters.workflow_tags,
    }),
    ...(filteredFilters.deflection && {
      deflection: filteredFilters.deflection,
    }),
    ...(filteredFilters.topic_ids && {
      topic_ids: filteredFilters.topic_ids,
    }),
    ...(filteredFilters.intent_id && {
      intent_id: filteredFilters.intent_id,
    }),
    ...(tab && { tab }),
  });

  // get filters based on tab
  if (tab === 'chat') {
    const allowedKeys = [
      'chats_search',
      'chats_search_mode',
      'intent_filters_cv_chat',
      ...generalKeysAllowed,
    ];
    const filteredFilters = filterByTab(allowedKeys);
    const end = Math.floor(Date.now() / 1000);
    const chatsSearchMode = filteredFilters.chats_search_mode?.[0];
    const uiQueryError = isUiQueryError({
      searchQueryMode: chatsSearchMode as string,
      uiQuery: filteredFilters.uiQuery as string,
    });
    const isSearchingByChatId =
      chatsSearchMode === 'conversation_id' &&
      filteredFilters.uiQuery &&
      !uiQueryError;
    const timestamps = dateRangeToTimestamp(dateRange);

    return {
      ...commonFilters(filteredFilters),
      ...(filteredFilters.intent_filters_cv_chat && {
        cv_filters: filteredFilters.intent_filters_cv_chat,
      }),
      chat:
        chatsSearchMode === 'chat'
          ? (filteredFilters.chats_search as string)
          : '',
      conversation_id:
        isSearchingByChatId &&
        filteredFilters.chats_search?.toString() !== 'null'
          ? (filteredFilters.chats_search as string)
          : '',
      user_query:
        chatsSearchMode === 'user_query'
          ? (filteredFilters.chats_search as string)
          : '',
      ...(isSearchingByChatId
        ? { end_timestamp: end, start_timestamp: Number(ALLTIME_START) }
        : timestamps),
    };
  }

  if (tab === 'workflow') {
    const allowedKeys = [
      'activate',
      'types',
      'workflows_search',
      'intent_filters_cv_workflow',
      ...generalKeysAllowed,
    ];
    const filteredFilters = filterByTab(allowedKeys);

    return {
      ...commonFilters(filteredFilters),
      ...(filteredFilters.activate?.length === 1 && {
        activate: filteredFilters.activate[0],
      }),
      ...(filteredFilters.types && { types: filteredFilters.types }),
      ...(filteredFilters.workflows_search && {
        user_query: filteredFilters.workflows_search as string,
      }),
      ...(filteredFilters.intent_filters_cv_workflow && {
        cv_filters: filteredFilters.intent_filters_cv_workflow,
      }),
    };
  }

  if (tab === 'article') {
    const allowedKeys = [
      'articles_search',
      'intent_filters_cv_article',
      ...generalKeysAllowed,
    ];
    const filteredFilters = filterByTab(allowedKeys);

    return {
      ...commonFilters(filteredFilters),
      ...(filteredFilters.articles_search && {
        user_query: filteredFilters.articles_search as string,
      }),
      ...(filteredFilters.intent_filters_cv_article && {
        cv_filters: filteredFilters.intent_filters_cv_article,
      }),
    };
  }

  if (tab === 'topic') {
    const allowedKeys = ['intent_filters_cv_topic', ...generalKeysAllowed];
    const filteredFilters = filterByTab(allowedKeys);

    return {
      ...commonFilters(filteredFilters),
      ...(filteredFilters.activate?.length === 1 && {
        activate: filteredFilters.activate[0],
      }),
      ...(filteredFilters.intent_filters_cv_topic && {
        cv_filters: filteredFilters.intent_filters_cv_topic,
      }),
    };
  }
};
